import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from 'environments/environment';
import { DatevMetadatenForCreationItem, DatevMetadatenItem, DatevMetadatenForUpdateItem } from 'app/_models/datev-metadaten.model';
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { catchError } from 'rxjs/operators';

const HTTP_OPTIONS = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}

@Injectable({
    providedIn: 'root'
})
export class ApiDatevService {

    constructor(private http: HttpClient) { }

    /**
     * API: Add a new  item.
     */
    addDatevMetadaten(item: DatevMetadatenForCreationItem): Observable<DatevMetadatenItem> {
        return this.http.post<DatevMetadatenItem>(ENVIRONMENT.apiBaseUrl + 'Datev/AddAsync', item, HTTP_OPTIONS);
    }

    /**
    * API: Update a new  item.
    */
    updateDatevMetadaten(id: number, item: DatevMetadatenForUpdateItem): Observable<DatevMetadatenItem> {
        return this.http.put<DatevMetadatenItem>(ENVIRONMENT.apiBaseUrl + 'Datev/UpdateAsync/' + id, item, HTTP_OPTIONS);
    }

    /**
      * API: Delete a  item.
      */
    deleteDatevMetadaten(id: number): Observable<DatevMetadatenItem> {
        return this.http.delete<DatevMetadatenItem>(ENVIRONMENT.apiBaseUrl + 'Datev/DeleteAsync/' + id, HTTP_OPTIONS);
    }



    /**
     * API: Get all DatevMetaden.
     */
    getAllDatevmetadaten(): Observable<DatevMetadatenItem[]> {
        return this.http.get<DatevMetadatenItem[]>(ENVIRONMENT.apiBaseUrl + 'Datev/GetByClientEngagement', HTTP_OPTIONS).pipe(
            catchError(this.handleError<DatevMetadatenItem[]>('getAllDatevmetadaten', []))
        );
    }

    /**
   * API: Get  Datev REferencedaten.
   */
    getDatevReferenceData(): Observable<JobStatisticItem> {
        return this.http.post<JobStatisticItem>(ENVIRONMENT.apiBaseUrl + 'Datev/GetDatevReferencedata', HTTP_OPTIONS);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse): Observable<T> => {
            console.error(error); // Log the error to the console or send it to a logging infrastructure  
            // Return an empty result to keep the app running  
            return of(result as T);
        };
    }

}
