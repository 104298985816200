<div class="stareTopic">
    {{ 'APP.SETTINGS.MAILING_METADATA.TITLE' | translate }}
</div>

<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
            (remove)="onDelete($event)" [loading]="!finishedLoading" (edit)="editHandler(grid.activeRow)"
            (cellClick)="cellClickHandler($event)" [sort]="currentSort" (sortChange)="sortChange($event)"
            (add)="addHandler()" filterable="menu" [selectable]="{ checkboxOnly: false, mode: 'single' }"
            [navigable]="true" [columnMenu]="menuSettings" [sortable]="true">

            <ng-template kendoGridToolbarTemplate>

                <kendo-grid-spacer></kendo-grid-spacer>

                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="Neuen Kommunikationseintrag hinzufügen.">
                </button>
                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>
            </ng-template>
            <kendo-grid-column field="legalEntityKeyForDisplay"
                [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate" [hidden]="false">
            </kendo-grid-column>
            <kendo-grid-column field="mailAddressReviewer"
                [title]=" 'APP.SETTINGS.MAILING_METADATA.TABLE.HEADER.REVIEWER_ADDRESS' | translate "
                [hidden]="false"></kendo-grid-column>
            <kendo-grid-column field="isDEForDisplay"
                [title]="'APP.SETTINGS.MAILING_METADATA.TABLE.HEADER.IS_DE' | translate">

            </kendo-grid-column>
            <kendo-grid-column field="isFormalForDisplay"
                [title]="'APP.SETTINGS.MAILING_METADATA.TABLE.HEADER.IS_FORMAL' | translate">
            </kendo-grid-column>

            <kendo-grid-command-column title=" ">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                    <button kendoGridEditCommand> {{'BUTTON.EDIT' | translate}}</button>

                    <button kendoGridRemoveCommand> {{'BUTTON.DELETE' | translate}}</button>
                </ng-template>
            </kendo-grid-command-column>
            <div *kendoGridDetailTemplate="let dataItem">
                <app-mailmetadata-client-details [mailmetadata]="dataItem"></app-mailmetadata-client-details>
            </div>
            <ng-template kendoGridNoRecordsTemplate>

                <p>{{ 'APP.SETTINGS.MAILING_METADATA.TABLE.NO_RECORDS_MAIN' | translate }}</p>
            </ng-template>
            <kendo-grid-excel fileName="Templates.xlsx"></kendo-grid-excel>
        </kendo-grid>
    </div>
</div>


<kendo-dialog *ngIf="active" [width]="700" [height]="600" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">

        <kendo-formfield>
            <kendo-label [for]="legalEntityKey"
                [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEGAL_ENTITY.LABEL' | translate"></kendo-label>
            <kendo-dropdownlist [valuePrimitive]="true" #legalEntityKey formControlName="legalEntityKey"
                placeholder="legalEntityKey" [textField]="'labelDE'" [valueField]="'value'" required
                [data]="legalEntities"></kendo-dropdownlist>
            <kendo-formerror>{{
                'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEGAL_ENTITY.ERROR.REQUIRED' | translate
                }}</kendo-formerror>
        </kendo-formfield>
        <div class="form-row">
            <kendo-formfield>
                <kendo-label [for]="leftName"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEFT_NAME.LABEL' | translate"></kendo-label>
                <kendo-textbox #leftName formControlName="leftName" required></kendo-textbox>
                <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEFT_NAME.ERROR.REQUIRED' |
                    translate}}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="leftMailAddress"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEFT_ADDRESS.LABEL' | translate"></kendo-label>
                <kendo-textbox #leftMailAddress formControlName="leftMailAddress" required></kendo-textbox>
                <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LEFT_ADDRESS.ERROR.REQUIRED' |
                    translate}}</kendo-formerror>
            </kendo-formfield>
        </div>
        <div class="form-row">
            <kendo-formfield>
                <kendo-label [for]="rightName"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.RIGHT_NAME.LABEL' | translate"></kendo-label>
                <kendo-textbox #rightName formControlName="rightName"></kendo-textbox>
                <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.RIGHT_NAME.ERROR.REQUIRED' |
                    translate}}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="rightMailAddress"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.RIGHT_ADDRESS.LABEL' | translate"></kendo-label>
                <kendo-textbox #rightMailAddress formControlName="rightMailAddress"></kendo-textbox>
                <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.RIGHT_ADDRESS.ERROR.REQUIRED' |
                    translate}}</kendo-formerror>
            </kendo-formfield>
        </div>

        <div class="form-row">

            <kendo-formfield>
                <kendo-label [for]="authorisationReceiveAvailable"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.AUTHORISATION_RECEIVER.LABEL' | translate"></kendo-label>
                <input type="checkbox" kendoCheckBox formControlName="authorisationReceiveAvailable"
                    #authorisationReceiveAvailable />

            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="hasSepa"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SEPA.LABEL' | translate"></kendo-label>
                <kendo-dropdownlist [data]="sepaList" [valuePrimitive]="true" formControlName="hasSepa" #hasSepa
                    [textField]="'labelDE'" [valueField]="'value'" required></kendo-dropdownlist>
                <kendo-formerror>{{
                    'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SEPA.ERROR.REQUIRED' | translate
                    }}</kendo-formerror>

            </kendo-formfield>
        </div>

        <div class="form-row">

            <kendo-formfield>
                <kendo-label [for]="isDE"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LANGUAGE.LABEL' | translate"></kendo-label>
                <kendo-dropdownlist [data]="languageList" [valuePrimitive]="true" formControlName="isDE" #isDE
                    [textField]="'labelDE'" [valueField]="'value'" required></kendo-dropdownlist>
                <kendo-formerror>{{
                    'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LANGUAGE.ERROR.REQUIRED' | translate
                    }}</kendo-formerror>
            </kendo-formfield>

            <kendo-formfield>
                <kendo-label [for]="isFormal"
                    [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.MODE_COMMUNICATION.LABEL' | translate"></kendo-label>
                <kendo-dropdownlist [data]="modeList" [valuePrimitive]="true" formControlName="isFormal" #isFormal
                    [textField]="'labelDE'" [valueField]="'value'" required></kendo-dropdownlist>
                <kendo-formerror>{{
                    'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.MODE_COMMUNICATION.ERROR.REQUIRED' | translate
                    }}</kendo-formerror>

            </kendo-formfield>

        </div>



    </form>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{'BUTTON.SAVE' | translate}}
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{'BUTTON.CANCEL' | translate}}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>