<div class="stareTopic">
    {{ 'APP.SETTINGS.LOG.TITLE' | translate }}
</div>

<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableData" [style.height]="'100%'" [loading]="!finishedLoading"
            [sort]="currentSort" (sortChange)="sortChange($event)"
            [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
            [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true" [reorderable]="true" [resizable]="true" [size]="smallSize">

            <ng-template kendoGridToolbarTemplate>
                <!--
                <kendo-textbox [placeholder]="'APP.SETTINGS.LOG.TABLE.SEARCH' | translate"
                    (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">
                </button>
                -->
                <kendo-numerictextbox [placeholder]="'APP.SETTINGS.LOG.TABLE.SEARCH_ID' | translate"
                    (valueChange)="onFilterID($event)" [value]="idFilter!" [style.width.px]="300" format="##"
                    [spinners]="false" kendoTooltip
                    title="{{ 'APP.SETTINGS.LOG.TABLE.SEARCH_ID' | translate }}"></kendo-numerictextbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">
                </button>
                <button kendoButton (click)="saveFilter()" type="button" [svgIcon]="saveItemsSVG" kendoTooltip
                    title="{{ 'BUTTON.SAVE_FILTER' | translate }}">
                </button>
                <kendo-textbox [placeholder]="'APP.SETTINGS.LOG.TABLE.SEARCH_MESSAGE' | translate"
                    (valueChange)="onFilterMessage($event)" [value]="messageFilter || ''" [style.width.px]="300"
                    kendoTooltip title="{{ 'APP.SETTINGS.LOG.TABLE.SEARCH_MESSAGE' | translate }}"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">
                </button>
                <button kendoButton (click)="saveFilter()" type="button" [svgIcon]="saveItemsSVG" kendoTooltip
                    title="{{ 'BUTTON.SAVE_FILTER' | translate }}">
                </button>

                <kendo-numerictextbox [placeholder]="'APP.SETTINGS.LOG.TABLE.ITEM_CHANGE' | translate"
                    (valueChange)="changeItemNumber($event)" [step]="1000" [style.width.px]="150" format="##" [min]="5"
                    [value]="items" kendoTooltip
                    title="{{ 'APP.SETTINGS.LOG.TABLE.ITEM_CHANGE' | translate }}"></kendo-numerictextbox>
                <button kendoButton (click)="saveItemNumber()" type="button" [svgIcon]="saveItemsSVG" kendoTooltip
                    title="{{ 'BUTTON.SAVE_LOG_ITEMS' | translate }}">
                </button>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>
                <!--
                <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                        title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">
                </button>
-->
            </ng-template>

            <kendo-grid-messages [pagerPage]="'APP.SETTINGS.LOG.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.SETTINGS.LOG.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.SETTINGS.LOG.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="'APP.SETTINGS.LOG.TABLE.PAGER.TASK_PER_PAGE' | translate"
                [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>


            <kendo-grid-column field="id" [width]="60" [title]="'APP.SETTINGS.LOG.TABLE.HEADER.ID' | translate"
                [hidden]="true"></kendo-grid-column>
            <kendo-grid-column [width]="200" field="createdDate" filter="date" format="dd.MM.yyyy, HH:mm:ss"
                [title]="'APP.SETTINGS.LOG.TABLE.HEADER.CREATED' | translate"></kendo-grid-column>
            <kendo-grid-column [width]="150" field="workflowId"
                [title]="'APP.SETTINGS.LOG.TABLE.HEADER.WORKFLOW_ID' | translate"></kendo-grid-column>
            <kendo-grid-column field="message" [title]="'APP.SETTINGS.LOG.TABLE.HEADER.INTERNAL_MESSAGE' | translate">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span> {{formatMessage(dataItem.message, 1)}}</span>
                    <span style="color: gray;"> {{formatMessage(dataItem.message, 2)}}</span>
                </ng-template>
            </kendo-grid-column>


            <ng-template kendoGridNoRecordsTemplate>

                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>

            <!--
            <kendo-grid-excel fileName="OcrQueue.xlsx"></kendo-grid-excel>
-->
        </kendo-grid>
    </div>
</div>