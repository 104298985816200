<div class="stareTopic">
    {{ 'APP.ADMINISTRATION.TAXOFFICE.TITLE' | translate }}
</div>


<!--(cellClick)="cellClickHandler($event)" (dblclick)="editHandler(grid.activeRow)" for Update-->
<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
            filterable="menu" [loading]="!finishedLoading" (add)="addHandler()"
            [selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings" [sort]="currentSort"
            (sortChange)="sortChange($event)" [filter]="currentFilter || {logic: 'and', filters: []}" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" (cellClick)="cellClickHandler($event)" (dblclick)="editHandler(grid.activeRow)"
            [navigable]=" true" [sortable]="true" [resizable]="true">

            <ng-template kendoGridToolbarTemplate>
                <kendo-textbox [placeholder]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.SEARCH' | translate"
                    (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

                </button>
                <kendo-grid-spacer></kendo-grid-spacer>

                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="{{ 'BUTTON.ADD' | translate }}">
                </button>
                &nbsp;|&nbsp;



                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>

                <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                    title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

                </button>

            </ng-template>

            <kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.PAGER.TASK_PER_PAGE' | translate"
                [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>

            <kendo-grid-column field="id" [title]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.HEADER.ID' | translate"
                [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="bufaNumber"
                [title]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.HEADER.BUFA_NUMMER' | translate"></kendo-grid-column>
            <kendo-grid-column field="nameDE"
                [title]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.HEADER.NAME_DE' | translate"></kendo-grid-column>
            <kendo-grid-column field="nameEN" format="dd.MM.yyyy"
                [title]="'APP.ADMINISTRATION.TAXOFFICE.TABLE.HEADER.NAME_EN' | translate"></kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>

                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>


            <kendo-grid-excel fileName="TaxOffices.xlsx" [fetchData]="allData"></kendo-grid-excel>

        </kendo-grid>
    </div>
</div>


<kendo-dialog *ngIf="active" [width]="450" [height]="630" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">

        <kendo-formfield>
            <kendo-label [for]="bufaNumber"
                [text]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.BUFA_NUMMER.LABEL' | translate"></kendo-label>
            <kendo-numerictextbox #bufaNumber formControlName="bufaNumber" [min]="0" [max]="99999999" [format]="'#'"
                [decimals]="0" [spinners]="false"
                [placeholder]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.BUFA_NUMMER.PLACEHOLDER' | translate" required>
            </kendo-numerictextbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.TAXOFFICE.MODAL.BUFA_NUMMER.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="nameDE"
                [text]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_DE.LABEL' | translate"></kendo-label>
            <kendo-textbox #nameDE formControlName="nameDE"
                [placeholder]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_DE.LABEL' | translate" required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_DE.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="nameEN"
                [text]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_EN.LABEL' | translate"></kendo-label>
            <kendo-textbox #nameEN formControlName="nameEN"
                [placeholder]="'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_EN.LABEL' | translate" required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.TAXOFFICE.MODAL.NAME_EN.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

    </form>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
            {{ 'BUTTON.DELETE' | translate }}
        </button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>

        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>