import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';

import { SortDescriptor } from "@progress/kendo-data-query";
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, plusIcon } from '@progress/kendo-svg-icons';

import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { MailTemplateForCreationItem, MailTemplateForUpdateItem, MailTemplateItem } from 'app/_models/mail-template.model';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { ListsService } from 'app/_services/lists.service';
import { ApiMailTemplateService } from 'app/_services/StareApi/mail-template.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-settings-mail-templates',
  templateUrl: './settings-mail-templates.component.html',
  styleUrls: ['./settings-mail-templates.component.scss'],
  standalone: false
})
export class SettingsMailTemplatesComponent implements OnInit {

  /** Displayed (filtered) data */
  public tableData = [] as MailTemplateItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as MailTemplateItem[];
  /** API request is finished */
  public finishedLoading = false;
  public auditResults: DropDownListItem[] = [];


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // ====================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiMailTemplateService: ApiMailTemplateService,
    private notificationService: WrapperNotificationService,
    private listsService: ListsService,
    public tooltipService: TooltipService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.MAIL_TEMPLATES.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.listsService.GetAuditResultsList().subscribe(
      (data) => {
        this.auditResults = data as DropDownListItem[];
      }
    );
    this.loadData();

  }

  // == Resize the Grid =================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load Grid Data ======================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiMailTemplateService.getAllMailTemplates().subscribe({
      next: (res: MailTemplateItem[]) => {
        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });
    this.finishedLoading = true;

  }
  // == Grid Settings =======================================================================================================================================

  // Icon for excel export
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;


  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting 

  public currentSort: SortDescriptor[] = [
    {
      field: "title",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }



  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;


  // == Form =====================================================
  public editForm: FormGroup = new FormGroup({
    title: new FormControl("", Validators.required),
    isDE: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    isFormal: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    isMixedAuditResult: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    auditResult: new FormControl("", Validators.required),
    subject: new FormControl("", Validators.required),
    body: new FormControl("", Validators.required),
  });

  // == Handlers ==========================================================================================
  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
  }

  public closeForm(): void {
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations ===================================================================

  public onSave(e: PointerEvent): void {
    e.preventDefault();


    if (this.isNew) {

      // Map UserAccessForCreationItem from Form
      let templateForCreationItem = {} as MailTemplateForCreationItem;

      templateForCreationItem = {
        title: this.editForm.value.title,
        isDE: this.editForm.value.isDE,
        isFormal: this.editForm.value.isFormal,
        isMixedAuditResult: this.editForm.value.isMixedAuditResult,
        AuditResult: this.editForm.value.auditResult,
        subject: this.editForm.value.subject,
        body: this.editForm.value.body,
      };

      console.log(templateForCreationItem);

      // Send to API
      this.apiMailTemplateService.addMailTemplate(templateForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Mail template wurde erfolgreich angelegt.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Mail template existiert bereits und konnte nicht (erneut) angelegt werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Anlegen eines Mail template.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }
    else {
      // Update
      let templateForUpdateItem = {} as MailTemplateForUpdateItem;
      templateForUpdateItem = {
        title: this.editForm.value.title,
        isDE: this.editForm.value.isDE,
        isFormal: this.editForm.value.isFormal,
        isMixedAuditResult: this.editForm.value.isMixedAuditResult,
        AuditResult: this.editForm.value.auditResult,
        subject: this.editForm.value.subject,
        body: this.editForm.value.body,
      };

      //console.log(userForUpdateItem);

      this.apiMailTemplateService.updateMailTemplate(this.thisId, templateForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Mail template wurde erfolgreich aktualisiert.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Der Mail template existiert bereits und konnte nicht aktualisiert werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Aktualisieren eines Mail template.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Mail template wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });


    }

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiMailTemplateService.deleteMailTemplate(this.thisId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Der Mail template wurde erfolgreich gelöscht.');
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen eines Template.');
        } else if (err.status == 410) {
          this.notificationService.showError('Der Template wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeForm();
  }


}
