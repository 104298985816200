<form [formGroup]="tradeTaxForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="tradeTaxForm1300" overrideLabel="Begründung Rechtsbehelf für Mandant (extern):"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Kassenzeichen">
                    </app-stare-textbox>
                </div>
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_communeAGS"
                        [datasetId]="headerDatasetId" [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="communeAGS">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Veranlagung
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentTradeTaxItem'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 676px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Veranlagung
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_assessmentYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_amount" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_amount_referenceCalculated" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsToBeMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsToBeMade_reference" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-payload-sub-header">
                                <legend class="k-form-legend section-header">
                                    Zahlungen
                                </legend>
                            </div>
                        </div>
                        <div class="row-for-sub-payload">
                            <div class="row">
                                <div class="column-for-sub-payload">
                                    <app-stare-datepicker (onFieldFocus)="
                                            onFieldFocusReceiver()
                                        " xbrlName="document_tradeTax_amountToPaidDate" [datasetId]="
                                            payload.controls['datasetId'].value
                                        " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                    </app-stare-datepicker>
                                </div>
                                <div class="column-for-sub-payload">
                                    <app-stare-numerical (onFieldFocus)="
                                            onFieldFocusReceiver()
                                        " xbrlName="document_tradeTax_amountToPaid" [datasetId]="
                                            payload.controls['datasetId'].value
                                        " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                    </app-stare-numerical>
                                </div>
                            </div>
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentTradeTaxItem'
                                        )
                                    ">
                                    Veranlagung löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'"
                        (click)="addPayload('DocumentTradeTaxItem')">
                        Veranlagung hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Vorauszahlung
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentTradeTaxPrePayment'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 657px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Vorauszahlung
                                </button>
                            </div>
                        </div>
                        <div class="row-for-checkbox">
                            <div class="column">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_assessmentYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                            <div class="column-for-checkbox">
                                <app-stare-checkbox (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_forSubsequentYears" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_amount" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_amount_referenceCalculated" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsToBeMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_paymentsToBeMade_reference" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-payload-sub-header">
                                <legend class="k-form-legend section-header">
                                    Zahlungen
                                </legend>
                            </div>
                        </div>
                        <div formArrayName="documentNestedPayload" *ngFor="
                                let nestedPayload of documentNestedPayloadGetterOnlyControls(
                                    documentPayloadGetter(),
                                    payload.get('crossReference')?.value
                                );
                                let l = index
                            ">
                            <div *ngIf="
                                    nestedPayload.get('dataTypeName')?.value ==
                                    'DocumentTradeTaxPaymentFromPrePayment'
                                " [formGroupName]="l">
                                <div class="row-for-sub-payload">
                                    <div class="row">
                                        <div class="column-for-header">
                                            <button kendoButton rounded="none" style="
                                                    margin-left: 430px;
                                                    margin-top: -25px;
                                                " class="custom-rounded" fillMode="outline" [disabled]="true">
                                                Zahlung (Vorausz.)
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-datepicker (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_amountToPaidDate" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-datepicker>
                                        </div>
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_amountToPaid" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>

                                    <div class="row-for-payload-button">
                                        <div class="column">
                                            <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                                [type]="'button'" (click)="
                                                    deletePayload(
                                                        documentNestedPayloadGetter(
                                                            documentPayloadGetter(),
                                                            payload.get(
                                                                'crossReference'
                                                            )?.value
                                                        ),
                                                        l,
                                                        'DocumentTradeTaxPaymentFromPrePayment'
                                                    )
                                                ">
                                                Zahlung (Vorauszahlung) löschen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton themeColor="primary" [type]="'button'" (click)="
                                        addPayload(
                                            'DocumentTradeTaxPaymentFromPrePayment',
                                            payload.get('crossReference')?.value
                                        )
                                    ">
                                    Zahlung (Vorauszahlung) hinzufügen
                                </button>
                            </div>
                            <!-- <div class="column">
                                <button style="margin-left:-15px; " kendoButton themeColor="primary"
                                    title="Erzeugt alle fehlenden Quartalszahlungen"
                                    (click)="addQuarterPayload('DocumentTradeTaxPaymentFromPrePayment', payload, payload.get('crossReference')?.value)">Quartalszahlungen
                                    hinzufügen</button>
                            </div> -->
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentTradeTaxPrePayment'
                                        )
                                    ">
                                    Vorauszahlung löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'"
                        (click)="addPayload('DocumentTradeTaxPrePayment')">
                        Vorauszahlung hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">Zinsen</legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let k = index
                ">
                <div [formGroupName]="k" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentTradeTaxInterest'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 731px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Zinsen
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-sub-payload">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_assessmentYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_interest_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_interest_paymentsMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_interest_value" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_interest_valueNewLaw" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_interest_valueOldLaw" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_interest_paymentsToBeMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column-for-sub-payload">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_interest_paymentsToBeMade" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column-for-payload-sub-header">
                                <legend class="k-form-legend section-header">
                                    Zinslaufrechner (optional - nur zur
                                    Überprüfung)
                                </legend>
                            </div>
                        </div>
                        <div formArrayName="documentNestedPayload" *ngFor="
                                let nestedPayload of documentNestedPayloadGetterOnlyControls(
                                    documentPayloadGetter(),
                                    payload.get('crossReference')?.value
                                );
                                let m = index
                            ">
                            <div *ngIf="
                                    nestedPayload.get('dataTypeName')?.value ==
                                    'DocumentTradeTaxInterestRun'
                                " [formGroupName]="m">
                                <div class="row-for-sub-payload">
                                    <div class="row">
                                        <div class="column-for-header">
                                            <button kendoButton rounded="none" style="
                                                    margin-left: 450px;
                                                    margin-top: -25px;
                                                " class="custom-rounded" fillMode="outline" [disabled]="true">
                                                Zahlung (Zinsen)
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-datepicker (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_interest_beginDate" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-datepicker>
                                        </div>

                                        <div class="column-for-sub-payload">
                                            <app-stare-datepicker (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_interest_endDate" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-datepicker>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_interest_rate" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>

                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_interest_amountMustBeInterest"
                                                [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_interest_value" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="tradeTax_interest_value" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="true"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>

                                    <div class="row-for-payload-button">
                                        <div class="column">
                                            <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                                [type]="'button'" (click)="
                                                    deletePayload(
                                                        documentNestedPayloadGetter(
                                                            documentPayloadGetter(),
                                                            nestedPayload.get(
                                                                'crossReference'
                                                            )?.value
                                                        ),
                                                        m,
                                                        'DocumentTradeTaxInterestRun'
                                                    )
                                                ">
                                                Zinslauf löschen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column">
                                <button kendoButton themeColor="primary" [type]="'button'" (click)="
                                        addPayload(
                                            'DocumentTradeTaxInterestRun',
                                            payload.get('crossReference')?.value
                                        )
                                    ">
                                    Zinslauf hinzufügen
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column-for-payload-sub-header">
                                <legend class="k-form-legend section-header">
                                    Zahlungen
                                </legend>
                            </div>
                        </div>
                        <div formArrayName="documentNestedPayload" *ngFor="
                                let nestedPayload of documentNestedPayloadGetterOnlyControls(
                                    documentPayloadGetter(),
                                    payload.get('crossReference')?.value
                                );
                                let m = index
                            ">
                            <div *ngIf="
                                    nestedPayload.get('dataTypeName')?.value ==
                                    'DocumentTradeTaxPaymentFromInterest'
                                " [formGroupName]="m">
                                <div class="row-for-sub-payload">
                                    <div class="row">
                                        <div class="column-for-header">
                                            <button kendoButton rounded="none" style="
                                                    margin-left: 532px;
                                                    margin-top: -25px;
                                                " class="custom-rounded" fillMode="outline" [disabled]="true">
                                                Zinsen
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-datepicker (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_amountToPaidDate" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-datepicker>
                                        </div>
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_tradeTax_amountToPaid" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>
                                    <div class="row-for-payload-button">
                                        <div class="column">
                                            <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                                [type]="'button'" (click)="
                                                    deletePayload(
                                                        documentNestedPayloadGetter(
                                                            documentPayloadGetter(),
                                                            payload.get(
                                                                'crossReference'
                                                            )?.value
                                                        ),
                                                        m,
                                                        'DocumentTradeTaxPaymentFromInterest'
                                                    )
                                                ">
                                                Zahlung (Zinsen) löschen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton themeColor="primary" [type]="'button'" (click)="
                                        addPayload(
                                            'DocumentTradeTaxPaymentFromInterest',
                                            payload.get('crossReference')?.value
                                        )
                                    ">
                                    Zahlung (Zinsen) hinzufügen
                                </button>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            k,
                                            'DocumentTradeTaxInterest'
                                        )
                                    ">
                                    Zinsen löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'"
                        (click)="addPayload('DocumentTradeTaxInterest')">
                        Zinsen hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Mahngebühr / Säumniszuschlag
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_reminderFeeAssessmentYear" [datasetId]="headerDatasetId"
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_reminderFeeAmountToPaid" [datasetId]="headerDatasetId"
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_reminderFeeAmountToPaidDate" [datasetId]="headerDatasetId"
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="tradeTaxForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="tradeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId' [form]="tradeTaxForm1300"
                        [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId' [form]="tradeTaxForm1300"
                        [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_auditResult"
                        [datasetId]='headerDatasetId' [form]="tradeTaxForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType' [dataNumberOptionsSource]="auditResults">
                    </app-stare-dropdown>
                </div>

            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="tradeTaxForm1300" [auditResults]="auditResults" [workflowId]="workflowId"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 

<form [formGroup]="tradeTaxForm13001300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 0 },
        { height:0},
        { height: 0},
        { height: 20 },
        { height: 112 },
        { height: 130 },						
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 20 },
        { height: 20 },
        { height: documentTradeTaxItemPayloadLength},
        { height: 53},
        { height: 1 },
        { height: documentTradeTaxPrePaymentPayloadLength + documentTradeTaxPaymentFromPrePaymentPayloadLength},	
        { height: 33},
        { height: 20 },
        { height: documentTradeTaxInterestPayloadLength + documentTradeTaxInterestRunPayloadLength + documentTradeTaxPaymentFromInterestPayloadLength},					
        { height: 33 },
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight}
        ]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">

                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Kassenzeichen</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Kassenzeichen" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_communeAGS-'+headerDatasetId"
                            id="agsLabel">{{getXbrlLabel('id_communeAGS')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_communeAGS-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_communeAGS" [virtual]="{itemHeight: 31}"
                            (filterChange)="handleAGSFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_communeAGS')"
                            [data]="communeAGS"></kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_communeAGS'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend payload-parent-header">
                        Veranlagung</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="3">-->
<!-- DocumentTradeTaxItem Dynamic -->
<!--
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentTradeTaxItem'" [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 },
                                { height: 20 }, 
                                { height: 150 },
                                { height: 50 }]"
                                [cols]="[{ width: 290 }, { width: 170 }, { width: 130 }, { width: 140 }]"
                                class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            id="yearLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [decimals]="0" [maxlength]="4" format="##"
                                            [id]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_assessmentYear')"
                                            placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                            formControlName="id_assessmentYear" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>

                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_amount-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTaxLabel'+i}}">{{getXbrlLabel('document_tradeTax_amount')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_tradeTax_amount-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amount')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_amount')}}"
                                            formControlName="document_tradeTax_amount" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_amount'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_amount_referenceCalculated-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTaxRefLabel'+i}}">{{getXbrlLabel('document_tradeTax_amount_referenceCalculated')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'document_tradeTax_amount_referenceCalculated-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amount_referenceCalculated')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_amount_referenceCalculated')}}"
                                            formControlName="document_tradeTax_amount_referenceCalculated"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_amount_referenceCalculated'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsMade')}}"
                                            formControlName="document_tradeTax_paymentsMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeRefLabel'+i}}">{{getXbrlLabel('tradeTax_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_paymentsMade')"
                                            placeholder="{{getXbrlLabel('tradeTax_paymentsMade')}}"
                                            formControlName="tradeTax_paymentsMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsToBeMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsToBeMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_tradeTax_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsToBeMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsToBeMade')}}"
                                            formControlName="document_tradeTax_paymentsToBeMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsToBeMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsToBeMade_reference-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsToBeMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsToBeMade_reference')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false"
                                            [id]="'document_tradeTax_paymentsToBeMade_reference-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsToBeMade_reference')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsToBeMade_reference')}}"
                                            formControlName="document_tradeTax_paymentsToBeMade_reference"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsToBeMade_reference'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                    <legend class="k-form-legend payload-parent-header">
                                        Nach- und Rückzahlungen</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3" class="payload">
                                    <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                    { height: 100 }]" [cols]="[{ width: 270 }, { width: 270 }]">
                                        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                                <kendo-label
                                                    [for]="'document_tradeTax_amountToPaidDate-'+payload.controls['datasetId'].value"
                                                    id="amountToPaidDateLabel">{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}</kendo-label>
                                                <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                                                    [value]="payload.controls['document_tradeTax_amountToPaidDate'].value"
                                                    [enableMouseWheel]="false"
                                                    [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amountToPaidDate')"
                                                    [id]="'document_tradeTax_amountToPaidDate-'+payload.controls['datasetId'].value"
                                                    placeholder="{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}"
                                                    formControlName="document_tradeTax_amountToPaidDate"
                                                    autocomplete="off">
                                                    <kendo-datepicker-messages today="Heute"
                                                        toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                                </kendo-datepicker>
                                                <app-validation-message-formcontrol
                                                    [datasetIdInput]="payload.controls['datasetId'].value"
                                                    [xbrlNameInput]="'document_tradeTax_amountToPaidDate'"></app-validation-message-formcontrol>
                                            </kendo-formfield>
                                        </kendo-gridlayout-item>
                                        <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                                <kendo-label
                                                    [for]="'document_tradeTax_amountToPaid-'+payload.controls['datasetId'].value"
                                                    id="{{'document_tradeTax_amountToPaidLabel'+i}}">{{getXbrlLabel('document_tradeTax_amountToPaid')}}</kendo-label>
                                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                                    [spinners]="false" [decimals]="2"
                                                    [id]="'document_tradeTax_amountToPaid-'+payload.controls['datasetId'].value"
                                                    [changeValueOnScroll]="false"
                                                    [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amountToPaid')"
                                                    placeholder="{{getXbrlLabel('document_tradeTax_amountToPaid')}}"
                                                    formControlName="document_tradeTax_amountToPaid" autocomplete="off">
                                                </kendo-numerictextbox>
                                                <app-validation-message-formcontrol
                                                    [datasetIdInput]="payload.controls['datasetId'].value"
                                                    [xbrlNameInput]="'document_tradeTax_amountToPaid'"></app-validation-message-formcontrol>
                                            </kendo-formfield>
                                        </kendo-gridlayout-item>
                                    </kendo-gridlayout>
                                </kendo-gridlayout-item>

                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentTradeTaxItem")'>Veranlagung
                                        löschen</button>
                                </kendo-gridlayout-item>

                            </kendo-gridlayout>
                        </div>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentTradeTaxItem")'>Veranlagung
                            hinzufügen</button>
                    </div>
                    <legend class="k-form-legend payload-parent-header">
                        Vorauszahlung</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="3">-->
<!-- DocumentTradeTaxPrePayment Dynamic,
                    containing DocumentTradeTaxPaymentFromPrePayment Dynamic
                    --><!--
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentTradeTaxPrePayment'"
                            [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 120 }, 
                                { height: 20 }, 
                                { height: payload.get('documentNestedPayload')?.value.length * 210}, 
                                { height: 50 },
                                { height: 50 }]"
                                [cols]="[{ width: 290 }, { width: 170 }, { width: 130 }, { width: 140 }]"
                                class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            id="yearLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [decimals]="0" [maxlength]="4" format="##"
                                            [id]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_assessmentYear')"
                                            placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                            formControlName="id_assessmentYear" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_forSubsequentYears-'+payload.controls['datasetId'].value"
                                            id="checkboxLabel">
                                            {{getXbrlLabel('document_tradeTax_forSubsequentYears')}}
                                        </kendo-label>
                                        <input type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
                                            (change)="changeExplanationRowHeight()" (focus)="onFocusInputXbrl()"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(+payload.controls['datasetId'].value,'document_tradeTax_forSubsequentYears')"
                                            formControlName="document_tradeTax_forSubsequentYears"
                                            [id]="'document_tradeTax_forSubsequentYears-'+payload.controls['datasetId'].value" />
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_forSubsequentYears'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_amount-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTaxLabel'+i}}">{{getXbrlLabel('document_tradeTax_amount')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_tradeTax_amount-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amount')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_amount')}}"
                                            formControlName="document_tradeTax_amount" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_amount'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_amount_referenceCalculated-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTaxRefLabel'+i}}">{{getXbrlLabel('document_tradeTax_amount_referenceCalculated')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false"
                                            [id]="'document_tradeTax_amount_referenceCalculated-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_amount_referenceCalculated')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_amount_referenceCalculated')}}"
                                            formControlName="document_tradeTax_amount_referenceCalculated"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_amount_referenceCalculated'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsMade')}}"
                                            formControlName="document_tradeTax_paymentsMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeRefLabel'+i}}">{{getXbrlLabel('tradeTax_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false"
                                            [id]="'tradeTax_paymentsMade-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_paymentsMade')"
                                            placeholder="{{getXbrlLabel('tradeTax_paymentsMade')}}"
                                            formControlName="tradeTax_paymentsMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsToBeMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsToBeMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_tradeTax_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsToBeMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsToBeMade')}}"
                                            formControlName="document_tradeTax_paymentsToBeMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsToBeMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="3">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_paymentsToBeMade_reference-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsToBeMadeLabel'+i}}">{{getXbrlLabel('document_tradeTax_paymentsToBeMade_reference')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false"
                                            [id]="'document_tradeTax_paymentsToBeMade_reference-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_paymentsToBeMade_reference')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_paymentsToBeMade_reference')}}"
                                            formControlName="document_tradeTax_paymentsToBeMade_reference"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_paymentsToBeMade_reference'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">

                                    <legend class="k-form-legend payload-child-header">
                                        Nach- und Rückzahlungen</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">-->
<!--Dynamic Payment Payload (DocumentTradeTaxPaymentFromPrePayment)--><!--
                                    <div formArrayName="documentNestedPayload"
                                        *ngFor="let nestedPayload of documentNestedPayloadGetterOnlyControls(documentPayloadGetter(), payload.get('crossReference')?.value); let l = index">
                                        <div *ngIf="nestedPayload.get('dataTypeName')?.value == 'DocumentTradeTaxPaymentFromPrePayment'"
                                            [formGroupName]="l" class="payload">
                                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                    { height: 100 }, { height: 50 }]" [cols]="[{ width: 290 }, { width: 290 }]">
                                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_amountToPaidDate-'+nestedPayload.controls['datasetId'].value"
                                                            id="amountToPaidDateLabel">{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}</kendo-label>
                                                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                                                            format="dd.MM.yyyy" [enableMouseWheel]="false"
                                                            [value]="nestedPayload.controls['document_tradeTax_amountToPaidDate'].value"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_amountToPaidDate')"
                                                            [id]="'document_tradeTax_amountToPaidDate-'+nestedPayload.controls['datasetId'].value"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}"
                                                            formControlName="document_tradeTax_amountToPaidDate"
                                                            autocomplete="off">
                                                            <kendo-datepicker-messages today="Heute"
                                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                                        </kendo-datepicker>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_amountToPaidDate'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>
                                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_amountToPaid-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'document_tradeTax_amountToPaidLabel'+l}}">{{getXbrlLabel('document_tradeTax_amountToPaid')}}</kendo-label>
                                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                                            type="decimal" [spinners]="false" [decimals]="2"
                                                            [changeValueOnScroll]="false"
                                                            [id]="'document_tradeTax_amountToPaid-'+nestedPayload.controls['datasetId'].value"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_amountToPaid')"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_amountToPaid')}}"
                                                            formControlName="document_tradeTax_amountToPaid"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_amountToPaid'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>-->
<!--Delete for DocumentTradeTaxPaymentFromPrePayment--><!--
                                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                                    <button kendoButton fillMode="outline" themeColor="primary"
                                                        [size]="'small'" [type]="'button'" (click)="deletePayload(documentNestedPayloadGetter(documentPayloadGetter(), payload.get('crossReference')?.value),
                                                        l, 'DocumentTradeTaxPaymentFromPrePayment')">Zahlung
                                                        (Vorauszahlung) löschen</button>
                                                </kendo-gridlayout-item>

                                            </kendo-gridlayout>
                                        </div>
                                    </div>
                                </kendo-gridlayout-item>-->
<!--Add for DocumentTradeTaxPaymentFromPrePayment--><!--
                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <button kendoButton themeColor="primary" [type]="'button'"
                                        (click)="addPayload('DocumentTradeTaxPaymentFromPrePayment', payload.get('crossReference')?.value)">Zahlung
                                        (Vorauszahlung) hinzufügen</button>
                                </kendo-gridlayout-item>-->
<!--Delete for DocumentTradeTaxPrePayment--><!--
                                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)="deletePayload(documentPayloadGetter(), i, 'DocumentTradeTaxPrePayment')">Vorauszahlung
                                        löschen</button>
                                </kendo-gridlayout-item>

                            </kendo-gridlayout>
                        </div>
                    </div>
                </kendo-gridlayout-item>-->
<!--Add for DocumentTradeTaxPrePayment--><!--
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentTradeTaxPrePayment")'>Vorauszahlung
                            hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend payload-parent-header">
                        Zinsen</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="3">-->
<!-- DocumentTradeTaxInterest Dynamic,
                    containing DocumentTradeTaxPaymentFromInterest Dynamic?--><!--
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let k = index">
                        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentTradeTaxInterest'"
                            [formGroupName]="k">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 },
                                { height: 20 },
                                { height: documentNestedPayloadLengthGetter(documentNestedPayloadGetter(documentPayloadGetter(), payload.get('crossReference')?.value), 'DocumentTradeTaxInterestRun') * 655 },
                                { height: 50},
                                { height: 20 },
                                { height: documentNestedPayloadLengthGetter(documentNestedPayloadGetter(documentPayloadGetter(), payload.get('crossReference')?.value), 'DocumentTradeTaxPaymentFromInterest') * 200},
                                { height: 50 },
                                { height: 50 }]"
                                [cols]="[{ width: 190 }, { width: 230 }, { width: 230 }, { width: 100 }]"
                                class="payload">

                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            id="yearInterestLabel">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [decimals]="0" [maxlength]="4" format="##" [changeValueOnScroll]="false"
                                            [id]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_assessmentYear')"
                                            placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                            formControlName="id_assessmentYear" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_interest_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeLabel'+k}}">{{getXbrlLabel('document_tradeTax_interest_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="2"
                                            [id]="'document_tradeTax_interest_paymentsMade-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_interest_paymentsMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_paymentsMade')}}"
                                            formControlName="document_tradeTax_interest_paymentsMade"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_interest_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_interest_paymentsMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsMadeLabel'+k}}">{{getXbrlLabel('tradeTax_interest_paymentsMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="2"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [id]="'tradeTax_interest_paymentsMade-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_interest_paymentsMade')"
                                            placeholder="{{getXbrlLabel('tradeTax_interest_paymentsMade')}}"
                                            formControlName="tradeTax_interest_paymentsMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_interest_paymentsMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>


                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_interest_value-'+payload.controls['datasetId'].value"
                                            id="{{'intValLabel'+k}}">{{getXbrlLabel('document_tradeTax_interest_value')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="2"
                                            [id]="'document_tradeTax_interest_value-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_interest_value')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_value')}}"
                                            formControlName="document_tradeTax_interest_value" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_interest_value'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_interest_valueNewLaw-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTax_interest_valueNewLaw'+k}}">{{getXbrlLabel('tradeTax_interest_valueNewLaw')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                            [id]="'tradeTax_interest_valueNewLaw-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_interest_valueNewLaw')"
                                            placeholder="{{getXbrlLabel('tradeTax_interest_valueNewLaw')}}"
                                            formControlName="tradeTax_interest_valueNewLaw" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_interest_valueNewLaw'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="3" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_interest_valueOldLaw-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTax_interest_valueOldLawLbl'+k}}">{{getXbrlLabel('tradeTax_interest_valueOldLaw')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                            [id]="'tradeTax_interest_valueOldLaw-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_interest_valueOldLaw')"
                                            placeholder="{{getXbrlLabel('tradeTax_interest_valueOldLaw')}}"
                                            formControlName="tradeTax_interest_valueOldLaw" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_interest_valueOldLaw'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_interest_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            id="{{'paymentsToBeMadeLabel'+k}}">{{getXbrlLabel('document_tradeTax_interest_paymentsToBeMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="2"
                                            [id]="'document_tradeTax_interest_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_interest_paymentsToBeMade')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_paymentsToBeMade')}}"
                                            formControlName="document_tradeTax_interest_paymentsToBeMade"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_interest_paymentsToBeMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_interest_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            id="{{'tradeTax_interest_paymentsToBeMadeLabel'+k}}">{{getXbrlLabel('tradeTax_interest_paymentsToBeMade')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                            [id]="'tradeTax_interest_paymentsToBeMade-'+payload.controls['datasetId'].value"
                                            type="decimal" [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_interest_paymentsToBeMade')"
                                            placeholder="{{getXbrlLabel('tradeTax_interest_paymentsToBeMade')}}"
                                            formControlName="tradeTax_interest_paymentsToBeMade" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_interest_paymentsToBeMade'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">

                                    <legend class="k-form-legend payload-child-header">
                                        Zinslaufrechner (optional - nur zur Überprüfung)</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">-->
<!--Dynamic Payment Payload (DocumentTradeTaxInterestRun)--><!--
                                    <div formArrayName="documentNestedPayload"
                                        *ngFor="let nestedPayload of documentNestedPayloadGetterOnlyControls(documentPayloadGetter(), payload.get('crossReference')?.value); let m = index">
                                        <div *ngIf="nestedPayload.get('dataTypeName')?.value == 'DocumentTradeTaxInterestRun'"
                                            [formGroupName]="m" class="payload">
                                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                            { height: 100 },
                                            { height: 100 },
                                            { height: 100 },
                                            { height: 100 },
                                            { height: 100 },
                                             { height: 50 }]" [cols]="[{ width: 290 }, { width: 290 }]">

                                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_interest_beginDate-'+nestedPayload.controls['datasetId'].value"
                                                            id="document_tradeTax_interest_beginDateLabel">{{getXbrlLabel('document_tradeTax_interest_beginDate')}}</kendo-label>
                                                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                                                            format="dd.MM.yyyy" [enableMouseWheel]="false"
                                                            [value]="nestedPayload.controls['document_tradeTax_interest_beginDate'].value"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_interest_beginDate')"
                                                            [id]="'document_tradeTax_interest_beginDate-'+nestedPayload.controls['datasetId'].value"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_beginDate')}}"
                                                            formControlName="document_tradeTax_interest_beginDate"
                                                            autocomplete="off">
                                                            <kendo-datepicker-messages today="Heute"
                                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                                        </kendo-datepicker>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_interest_beginDate'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>

                                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_interest_endDate-'+nestedPayload.controls['datasetId'].value"
                                                            id="document_tradeTax_interest_endDateLabel">{{getXbrlLabel('document_tradeTax_interest_endDate')}}</kendo-label>
                                                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                                                            format="dd.MM.yyyy"
                                                            [value]="nestedPayload.controls['document_tradeTax_interest_endDate'].value"
                                                            [enableMouseWheel]="false"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_interest_endDate')"
                                                            [id]="'document_tradeTax_interest_endDate-'+nestedPayload.controls['datasetId'].value"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_endDate')}}"
                                                            formControlName="document_tradeTax_interest_endDate"
                                                            autocomplete="off">
                                                            <kendo-datepicker-messages today="Heute"
                                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                                        </kendo-datepicker>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_interest_endDate'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>

                                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_interest_rate-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'interestRateLabel'+k}}">{{getXbrlLabel('document_tradeTax_interest_rate')}}</kendo-label>
                                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                                            type="decimal" [spinners]="false"
                                                            [changeValueOnScroll]="false" [decimals]="2"
                                                            [id]="'document_tradeTax_interest_rate-'+nestedPayload.controls['datasetId'].value"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_interest_rate')"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_rate')}}"
                                                            formControlName="document_tradeTax_interest_rate"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_interest_rate'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>

                                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_interest_amountMustBeInterest-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'amountMustBeInterestLabel'+k}}">
                                                            {{getXbrlLabel('document_tradeTax_interest_amountMustBeInterest')}}
                                                        </kendo-label>
                                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                                            [changeValueOnScroll]="false"
                                                            [id]="'document_tradeTax_interest_amountMustBeInterest-'+nestedPayload.controls['datasetId'].value"
                                                            type="decimal" [spinners]="false" [decimals]="2"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_interest_amountMustBeInterest')"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_amountMustBeInterest')}}"
                                                            formControlName="document_tradeTax_interest_amountMustBeInterest"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_interest_amountMustBeInterest'"></app-validation-message-formcontrol>
                                                    </kendo-formfield></kendo-gridlayout-item>

                                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_interest_value-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'interestLabel'+k}}">{{getXbrlLabel('document_tradeTax_interest_value')}}</kendo-label>
                                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                                            [decimals]="2"
                                                            [id]="'document_tradeTax_interest_value-'+nestedPayload.controls['datasetId'].value"
                                                            type="decimal" [spinners]="false"
                                                            [changeValueOnScroll]="false"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_interest_value')"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_interest_value')}}"
                                                            formControlName="document_tradeTax_interest_value"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_interest_value'"></app-validation-message-formcontrol>
                                                    </kendo-formfield></kendo-gridlayout-item>
                                                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'tradeTax_interest_value-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'tradeTax_interest_value'+k}}">{{getXbrlLabel('tradeTax_interest_value')}}</kendo-label>
                                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()"
                                                            [id]="'tradeTax_interest_value-'+nestedPayload.controls['datasetId'].value"
                                                            type="decimal" [spinners]="false" [readonly]="true"
                                                            [tabindex]="-1" class="custom-fill-mode" fillMode="none"
                                                            [changeValueOnScroll]="false"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'tradeTax_interest_value')"
                                                            placeholder="{{getXbrlLabel('tradeTax_interest_value')}}"
                                                            formControlName="tradeTax_interest_value"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'tradeTax_interest_value'"></app-validation-message-formcontrol>
                                                    </kendo-formfield></kendo-gridlayout-item>-->
<!--Delete for DocumentTradeTaxInterestRun--><!--
                                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                                                    <button kendoButton fillMode="outline" themeColor="primary"
                                                        [size]="'small'" [type]="'button'"
                                                        (click)="deletePayload(documentNestedPayloadGetter(documentPayloadGetter(), nestedPayload.get('crossReference')?.value),m, 'DocumentTradeTaxInterestRun')">Zinslauf
                                                        löschen</button>
                                                </kendo-gridlayout-item>
                                            </kendo-gridlayout>
                                        </div>
                                    </div>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <button kendoButton themeColor="primary" [type]="'button'"
                                        (click)="addPayload('DocumentTradeTaxInterestRun', payload.get('crossReference')?.value)">Zinslauf
                                        hinzufügen</button>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="3">

                                    <legend class="k-form-legend payload-child-header">
                                        Nach- und Rückzahlungen</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="3">-->
<!--Dynamic Payment Payload (DocumentTradeTaxPaymentFromInterest)--><!--
                                    <div formArrayName="documentNestedPayload"
                                        *ngFor="let nestedPayload of documentNestedPayloadGetterOnlyControls(documentPayloadGetter(), payload.get('crossReference')?.value); let m = index">
                                        <div *ngIf="nestedPayload.get('dataTypeName')?.value == 'DocumentTradeTaxPaymentFromInterest'"
                                            [formGroupName]="m" class="payload">
                                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                        { height: 100 }, { height: 50 }]" [cols]="[{ width: 290 }, { width: 290 }]">
                                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_amountToPaidDate-'+nestedPayload.controls['datasetId'].value"
                                                            id="amountToPaidDateLabel">{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}</kendo-label>
                                                        <kendo-datepicker format="dd.MM.yyyy"
                                                            (focus)="onFocusInputXbrl()"
                                                            [value]="nestedPayload.controls['document_tradeTax_amountToPaidDate'].value"
                                                            [enableMouseWheel]="false"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_amountToPaidDate')"
                                                            [id]="'document_tradeTax_amountToPaidDate-'+nestedPayload.controls['datasetId'].value"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_amountToPaidDate')}}"
                                                            formControlName="document_tradeTax_amountToPaidDate"
                                                            autocomplete="off">
                                                            <kendo-datepicker-messages today="Heute"
                                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                                        </kendo-datepicker>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_amountToPaidDate'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>
                                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                                    <kendo-formfield showHints="always" showErrors="always"
                                                        class="part">
                                                        <kendo-label
                                                            [for]="'document_tradeTax_amountToPaid-'+nestedPayload.controls['datasetId'].value"
                                                            id="{{'document_tradeTax_amountToPaidLabel'+m}}">{{getXbrlLabel('document_tradeTax_amountToPaid')}}</kendo-label>
                                                        <kendo-numerictextbox type="decimal" [spinners]="false"
                                                            [decimals]="2"
                                                            [id]="'document_tradeTax_amountToPaid-'+nestedPayload.controls['datasetId'].value"
                                                            [changeValueOnScroll]="false" (focus)="onFocusInputXbrl()"
                                                            [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_tradeTax_amountToPaid')"
                                                            placeholder="{{getXbrlLabel('document_tradeTax_amountToPaid')}}"
                                                            formControlName="document_tradeTax_amountToPaid"
                                                            autocomplete="off">
                                                        </kendo-numerictextbox>
                                                        <app-validation-message-formcontrol
                                                            [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                                            [xbrlNameInput]="'document_tradeTax_amountToPaid'"></app-validation-message-formcontrol>
                                                    </kendo-formfield>
                                                </kendo-gridlayout-item>-->
<!--Delete for DocumentTradeTaxPaymentFromInterest--><!--
                                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                                    <button kendoButton fillMode="outline" themeColor="primary"
                                                        [size]="'small'" [type]="'button'"
                                                        (click)="deletePayload(documentNestedPayloadGetter(documentPayloadGetter(), payload.get('crossReference')?.value),m, 'DocumentTradeTaxPaymentFromInterest')">Zahlung
                                                        (Zinsen)
                                                        löschen</button>
                                                </kendo-gridlayout-item>
                                            </kendo-gridlayout>
                                        </div>
                                    </div>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                                    <button kendoButton themeColor="primary" [type]="'button'"
                                        (click)="addPayload('DocumentTradeTaxPaymentFromInterest', payload.get('crossReference')?.value)">Zahlung
                                        (Zinsen) hinzufügen</button>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), k,"DocumentTradeTaxInterest")'>Zinsen
                                        löschen</button>
                                </kendo-gridlayout-item>

                            </kendo-gridlayout>
                        </div>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentTradeTaxInterest")'>Zinsen
                            hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
                            (change)="changeExplanationRowHeight()" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="3"
                    *ngIf="tradeTaxForm13001300.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            type="string" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->