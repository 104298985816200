
import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DataService } from 'app/_services/StareApi/data.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ListsService } from 'app/_services/lists.service';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-tax-ebitda1300',
  templateUrl: './doc-corporate-income-tax-ebitda1300.component.html',
  styleUrl: '../doc-base/doc-base.component.scss',
  standalone: false
})
export class DocCorporateTaxEbitda1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateTaxEbitdaForm1300;
  }





  //#region Form ======================================================================================================

  /** Körperschaftsteuer ebitda, Rechtsmittel-Check, State 1300 */
  public corporateTaxEbitdaForm1300 = new UntypedFormGroup({

    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    id_companyIdentifier: new UntypedFormControl(""),
    reference_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    // id_assessmentYear: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_corporateIncomeTax_interestCarryForward: new UntypedFormControl(""),
    document_corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(""),
    corporateIncomeTax_interestCarryForward: new UntypedFormControl(""),
    corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(""),

    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),

  });


  //#endregion



  addSingularPayload(): void {
    //no payload 
  }




}

/** Körperschaftsteuer Zuwendungsvortrags, Rechtsmittel-Check, State 1300 */