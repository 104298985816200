import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileExtractionContentDto, FileItem } from 'app/_models/file.model';
import { WorkflowDataExport } from 'app/_models/workflow-data-export.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiFileService {


  constructor(
    private http: HttpClient) { }

  /**
* API: Get content for the import template file
*/
  apiFile(fileId: string): Observable<FileExtractionContentDto> {
    return this.http.get<FileExtractionContentDto>(ENVIRONMENT.apiBaseUrl + 'File/GetByIdContent/' + fileId, HTTP_OPTIONS)
  }

  downloadPdf(fileId: string): Observable<{ blob: Blob, filename: string }> {
    return this.http.get(ENVIRONMENT.apiBaseUrl + 'File/GetByIdAsContent/' + fileId, { observe: 'response', responseType: 'blob' }).pipe(
      map((response: HttpResponse<Blob>) => {


        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'download.pdf';

        if (contentDisposition) {
          const parts = contentDisposition.split(';');

          for (const part of parts) {
            const trimmedPart = part.trim();
            if (trimmedPart.startsWith('filename=')) {
              filename = trimmedPart.substring(9).replace(/['"]/g, '');
              break;
            }
          }
        }



        return { blob: response.body as Blob, filename: filename };
      })
    );
  }


  /**
* Get a specific file item by ID.
* @param fileId ID of the requested file.
*/
  apiFileAsPromise(fileId: string): Promise<FileExtractionContentDto> {
    return this.http.get<FileExtractionContentDto>(ENVIRONMENT.apiBaseUrl + 'File/GetByIdContent/' + fileId, HTTP_OPTIONS).toPromise();
  }

  /**
* Get a specific file metadata item by ID.
* @param fileId ID of the requested file.
*/
  getFileMetadataById(fileId: string): Observable<FileItem> {
    return this.http.get<FileItem>(ENVIRONMENT.apiBaseUrl + 'File/GetByIdMetadataAsync/' + fileId, HTTP_OPTIONS);
  }


  /**
* Convert base64 to blob
* @author https://gist.github.com/Marceloalves6/d1f590437f7fe45ac6832e5177c84be6
*/
  base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
* API: Get a list of all File Items
* 
*/
  getAllDataExportsAsync(): Observable<WorkflowDataExport[]> {
    return this.http.get<WorkflowDataExport[]>(ENVIRONMENT.apiBaseUrl + 'File/GetAllDataExportsAsync', HTTP_OPTIONS);
  }
}
