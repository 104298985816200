import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWorkflowMetadata } from 'app/_models/workflow-metadata.model';
import { IWorkflowLockItem } from 'app/_models/workflow-lock.model';
import { WorkflowTrigger } from 'app/_models/workflow-trigger.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { WorkflowBaseUiItem, WorkflowItem } from 'app/_models/workflow.model';
import { XbrlParserService } from '../xbrl-parser.service';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiWorkflowService {

  constructor(
    private http: HttpClient,
    private xbrlParserService: XbrlParserService) { }

  /**
* Get a workflow item.
* @param workflowId ID of the requested workflow.
*/
  getWorkflowMetaDataById(workflowId: number): Observable<IWorkflowMetadata> {
    return this.http.get<IWorkflowMetadata>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetMetaDataByIdAsync/' + workflowId, HTTP_OPTIONS);
  }

  /**
 * Get information about the lock state of a workflow.
 * @param workflowId ID of the requested workflow.
 */
  getWorkflowLockedInfos(workflowId: number): Observable<IWorkflowLockItem> {
    return this.http.get<IWorkflowLockItem>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetLock/' + workflowId, HTTP_OPTIONS);
  }

  /**
* Set lock for a workflow.
* @param workflowId ID of the requested workflow.
*/
  setLocked(workflowId: number): void {


    this.http.put(ENVIRONMENT.apiBaseUrl + 'Workflow/SetLocked/' + workflowId, HTTP_OPTIONS).subscribe({
      next: (data) => {
        console.log('Lock set for workflowId: ' + workflowId);
      }
    });
  }

  /**
* Execute a workflow trigger.
*/
  executeWorkflowTrigger(workflowId: number, acutalStateId: number, triggerId: number): Observable<WorkflowItem> {

    return this.http.put<WorkflowItem>(ENVIRONMENT.apiBaseUrl + 'Workflow/ExecuteWorkflowAsync/' + workflowId + '/' + acutalStateId + '/' + triggerId, HTTP_OPTIONS)
  }

  /**
* Get the next trigger for a workflow.
*/
  getNextTrigger(workflowId: number): Observable<WorkflowTrigger[]> {
    return this.http.get<WorkflowTrigger[]>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetNextTriggersAsync/' + workflowId, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
* Excute a workflow trigger with changeDataTypeName.
*/
  executeChangeDataTypeTrigger(workflowId: number, acutalStateId: number, triggerId: number, name: string, reScan: boolean): Observable<WorkflowItem> {
    return this.http.put<WorkflowItem>(ENVIRONMENT.apiBaseUrl + 'Workflow/ExecuteWorkflowAsync/' + workflowId + '/' + acutalStateId + '/' + triggerId + '?changeDataTypeName=' + name + '&reScan=' + reScan, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
* Excute a workflow trigger with reopened assessment.
*/
  executeReopenTrigger(workflowId: number, acutalStateId: number, triggerId: number): Observable<WorkflowItem> {
    return this.http.put<WorkflowItem>(ENVIRONMENT.apiBaseUrl + 'Workflow/ExecuteWorkflowAsync/' + workflowId + '/' + acutalStateId + '/' + triggerId, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
 * Get a workflow item.
 * @param workflowId ID of the requested workflow.
 * @returns API response as a promise object. See the Swagger documentation for more information about schema and example values.
 */
  getWorkflowById(workflowId: number): Observable<WorkflowBaseUiItem> {
    return this.http.get<WorkflowBaseUiItem>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetBaseUiByIdAsync/' + workflowId, HTTP_OPTIONS).pipe(
      map((data: WorkflowBaseUiItem) => {

        if (data.validationMessageList) {
          for (let validationMessage of data.validationMessageList) {
            validationMessage.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(validationMessage.xbrlName!);
          }


        }
        return data;
      }));
  }

}
