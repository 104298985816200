<div class="stareTopic">
	{{ 'APP.ARCHIVE.TITLE' | translate }}
</div>

<div>
	<ng-template #toolTipGridTemplate let-anchor>
		<span>{{ anchor.nativeElement.innerText }}</span>
	</ng-template>
	<div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
		(mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
		[ngStyle]="{'height.px': pageHeight}">
		<!--<kendo-grid #grid [kendoGridBinding]="tableTasksDataRaw" scrollable="scrollable" [style.height]="'100%'"
		[filterable]="true" [filter]="currentFilter || {logic: 'and', filters: []}" [loading]="!finishedLoading"
		(dblclick)="openFile(grid.activeRow)" (cellClick)="cellClickHandler($event)" [sort]="currentSort"
		[selectable]="{ checkboxOnly: false, mode: 'single' }" (sortChange)="sortChange($event)"
		[columnMenu]="menuSettings" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true" [navigable]="true">
	 -->
		<kendo-grid id="grid" #grid [kendoGridBinding]="tableTasksDataFiltered" scrollable="scrollable"
			[style.height]="'100%'" filterable="menu"
			[filter]="gridSettings.state.filter || {logic: 'and', filters: []}" [columnMenu]="menuSettings"
			[loading]="!finishedLoading" [pageSize]="gridSettings.state.take || 10"
			[skip]="gridSettings.state.skip || 0" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [sortable]="sortSettings" [sort]="gridSettings.state.sort || []"
			[selectable]="{ checkboxOnly: false, mode: 'single' }" [reorderable]="true" [resizable]="true"
			[size]="smallSize" (dataStateChange)="dataStateChange($event, grid)" (dblclick)="openFile(grid.activeRow)"
			(cellClick)="cellClickHandler($event)" (columnReorder)="saveGridSettings(grid)"
			(columnResize)="saveGridSettings(grid)" (columnVisibilityChange)="saveGridSettings(grid)" [navigable]="true"
			(pageChange)="pageChange($event)">
			<ng-template kendoGridToolbarTemplate>
				<kendo-textbox [placeholder]=" 'APP.ARCHIVE.TABLE.SEARCH' | translate " [(ngModel)]="currentFilterVal"
					(valueChange)="onFilter($event, grid)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>

				<button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
					title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

				</button>
				<kendo-grid-spacer></kendo-grid-spacer>


				<button kendoButton (click)="removeSettings()" type="button" [svgIcon]="settingsClearSVG" kendoTooltip
					title="{{ 'BUTTON.RESET_TABLE' | translate }}">

				</button>

				<button kendoButton (click)="loadTasks()" type="button" [svgIcon]="reloadSVG" kendoTooltip
					title="{{ 'BUTTON.UPDATE' | translate }}">
				</button>


			</ng-template>

			<kendo-grid-messages [pagerPage]="'APP.ARCHIVE.TABLE.PAGER.PAGE' | translate"
				[pagerOf]="'APP.ARCHIVE.TABLE.PAGER.OF' | translate"
				[pagerItems]="'APP.ARCHIVE.TABLE.PAGER.ITEM' | translate"
				[pagerItemsPerPage]="'APP.ARCHIVE.TABLE.PAGER.TASK_PER_PAGE' | translate"
				[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
				[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
				[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
				[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
				[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
				[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
				[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
				[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
				[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
				[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
				[filterClearButton]="'BUTTON.CLEAR' | translate"
				[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
				[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
				[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
				[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
				[filterFilterButton]="'BUTTON.FILTER' | translate"
				[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
				[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
				[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
				[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
				[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
				[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
				[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
				[filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
				[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
				[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
				[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
				[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
				[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
				[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
				[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
				[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
				[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
				[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
			</kendo-grid-messages>
			<!--
		<kendo-grid-column field="workflowId" [title]=" 'APP.TASKS.TABLE.HEADER.WORKFLOW_ID' | translate "
			[hidden]="false"></kendo-grid-column>
		<kendo-grid-column field="filename" [title]=" 'APP.TASKS.TABLE.HEADER.FILE_NAME' | translate "
			[hidden]="false"></kendo-grid-column>
		<kendo-grid-column filter="date" field="documentDate"
			[title]=" 'APP.ARCHIVE.TABLE.HEADER.DOCUMENT_DATE' | translate" [hidden]="false" format="dd.MM.yyyy"
			filter="date"></kendo-grid-column>
		<kendo-grid-column field="deadlineNumber" [title]=" 'APP.TASKS.TABLE.HEADER.DEADLINE_NUMBER' | translate "
			[hidden]="false"></kendo-grid-column>
		<kendo-grid-column field="legalRemedyObjectionForDisplay"
			[title]=" 'APP.ARCHIVE.TABLE.HEADER.LEGAL_REMEDY' | translate " [hidden]="false"></kendo-grid-column>
-->
			<kendo-grid-column *ngFor="let col of gridSettings.columnsConfig" [field]="col.field"
				[title]="col.titleToDisplay || ''" [filter]="col.filter || 'text'" [filterable]="col.filterable"
				[format]="col.format" [sortable]="col.sortable" [hidden]="col.hidden || false" [minResizableWidth]="150"
				[width]="col.width || 150">
				<ng-template kendoGridCellTemplate let-dataItem let-displayComments *ngIf="(col.field == 'comment')">
					<div *ngIf="dataItem.hasComments">
						<div kendoTooltip position="right" filter="span" [titleTemplate]="titleTemplate"
							[tooltipTemplate]="template" [tooltipWidth]="400">
							<ng-template #titleTemplate let-anchor>
								{{ 'APP.TASKS.TABLE.HEADER.COMMENT' | translate }}
							</ng-template>
							<!-- <span style="font-size: small;">{{ dataItem.commentsCount }}x&nbsp;</span> -->
							<ng-template #template let-anchor>
								<div [innerHTML]="dataItem.workflowCommentAsString"></div>
							</ng-template>
							<span class="k-icon k-font-icon k-i-comment"></span>

						</div>
					</div>
				</ng-template>
			</kendo-grid-column>
			<!--comment change
		<kendo-grid-column field="comment" [title]=" 'APP.TASKS.TABLE.HEADER.COMMENT' | translate " [hidden]="false"
			[filterable]="false"><ng-template kendoGridCellTemplate let-dataItem let-displayComments>
				<div *ngIf="dataItem.hasComments">
					<div kendoTooltip position="right" filter="span" [titleTemplate]="titleTemplate"
						[tooltipTemplate]="template" [tooltipWidth]="400">
						<ng-template #titleTemplate let-anchor>
							{{ 'APP.TASKS.TABLE.HEADER.COMMENT' | translate }}
						</ng-template>
						<span style="font-size: small;">{{ dataItem.commentsCount }}x&nbsp;</span>
						<ng-template #template let-anchor>
							<pre>{{ dataItem.displayComments }}</pre>
						</ng-template>
						<span class="k-icon k-font-icon k-i-comment"></span>

					</div>
				</div>
			</ng-template></kendo-grid-column>
		<kendo-grid-column field="typeOfTaxAssessment"
			[title]=" 'APP.ARCHIVE.TABLE.HEADER.TYPE_OF_TAX_ASSESSMENT' | translate "
			[hidden]="true"></kendo-grid-column>
		<kendo-grid-column field="legalEntityName" [title]=" 'APP.ARCHIVE.TABLE.HEADER.LEGAL_ENTITY_NAME' | translate "
			[hidden]="true"></kendo-grid-column>

		<kendo-grid-column filter="date" field="createDate" [title]=" 'APP.TASKS.TABLE.HEADER.CREATE_DATE' | translate"
			[hidden]="true" format="dd.MM.yyyy"></kendo-grid-column>
		<kendo-grid-column filter="date" field="updateDate" [title]=" 'APP.TASKS.TABLE.HEADER.UPDATE_DATE' | translate"
			[hidden]="true" format="dd.MM.yyyy"></kendo-grid-column>
		<kendo-grid-column filter="date" field="deadlineDate"
			[title]=" 'APP.TASKS.TABLE.HEADER.DEADLINE_DATE' | translate" [hidden]="true"
			format="dd.MM.yyyy"></kendo-grid-column>
-->
			<ng-template kendoGridNoRecordsTemplate>

				<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
			</ng-template>


		</kendo-grid>
	</div>
</div>