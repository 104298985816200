import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReleaseNotesModel } from 'app/_models/release-note.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiMaintenanceService {

  constructor(
    private http: HttpClient
  ) { }

  /**
 * Get the release notes (as HTML) from the API.
 */
  getReleaseNotes(): Observable<ReleaseNotesModel> {
    return this.http.get<ReleaseNotesModel>(ENVIRONMENT.maintenanceApiBaseUrl + 'Maintenance/GetReleaseNotes', HTTP_OPTIONS);
  }

}