<div class="stareTopic">
    {{ "APP.MAILING.TITLE" | translate }}
</div>

<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div
        kendoTooltip
        showOn="none"
        [tooltipTemplate]="toolTipGridTemplate"
        filter=".k-grid td"
        (mouseover)="
            this.tooltipService.showTooltipHeader($event, this.tooltipDir)
        "
        [ngStyle]="{ 'height.px': pageHeight }"
    >
        <kendo-grid
            id="grid"
            #grid
            [kendoGridBinding]="gridData"
            [selectable]="selectableSettings"
            [loading]="!finishedLoading"
            [columnMenu]="menuSettings"
            filterable="menu"
            [filter]="currentFilter || { logic: 'and', filters: [] }"
            [style.height]="'100%'"
            [navigable]="true"
            [sort]="currentSort"
            (sortChange)="sortChange($event)"
            [pageable]="{
                buttonCount: 7,
                info: true,
                type: 'numeric',
                pageSizes: [20, 30, 50],
                previousNext: true,
                position: 'bottom'
            }"
            [sortable]="true"
            [pageSize]="pageSize"
            kendoGridSelectBy="id"
            [(selectedKeys)]="selectedWorkflowIds"
            (selectionChange)="onCheckboxChange($event)"
            (pageChange)="pageChange($event)"
            [rowClass]="rowCallback"
        >
            <ng-template kendoGridToolbarTemplate>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button
                    kendoButton
                    type="submit"
                    [disabled]="!shouldEnableButton()"
                    (click)="createMail()"
                    kendoTooltip
                    title="E-Mail erstellen,, wenn alle ausgewählten dieselben Steuerpflichtige haben."
                >
                    E-Mail erstellen
                </button>
            </ng-template>

            <kendo-grid-messages
                [pagerPage]="'APP.MAILING.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.MAILING.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.MAILING.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="
                    'APP.MAILING.TABLE.PAGER.TASK_PER_PAGE' | translate
                "
                [columnsApply]="'BUTTON.APPLY' | translate"
                [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate"
                [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="
                    'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                "
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="
                    'TABLE_MENU.BEFORE_OPERATOR' | translate
                "
                [filterBeforeOrEqualOperator]="
                    'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                "
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="
                    'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                "
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="
                    'TABLE_MENU.IS_NOT_EMPTY' | translate
                "
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="
                    'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                "
                [filterNotEqOperator]="
                    'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                "
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="
                    'TABLE_MENU.STARTS_WITH' | translate
                "
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="
                    'TABLE_MENU.SET_COLUMN_POSITION' | translate
                "
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate"
            >
            </kendo-grid-messages>

            <kendo-grid-checkbox-column
                [columnMenu]="false"
                [showSelectAll]="true"
                [width]="65"
            ></kendo-grid-checkbox-column>
            <kendo-grid-column
                field="id"
                [width]="150"
                [title]="'APP.MAILING.TABLE.HEADER.WORKFLOW_ID' | translate"
                [hidden]="false"
            ></kendo-grid-column>
            <kendo-grid-column
                filter="date"
                field="documentHeader.documentDate"
                [title]="'APP.MAILING.TABLE.HEADER.DOCUMENT_DATE' | translate"
                [hidden]="false"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{
                        getDateForDisplay(dataItem.documentHeader.documentDate)
                            | date : "dd.MM.yyyy"
                    }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="legalEntityKeyForDisplay"
                [title]="'APP.MAILING.TABLE.HEADER.LEGAL_ENTITY' | translate"
            ></kendo-grid-column>
            <!--      <kendo-grid-column
                [filterable]="true"
                [title]="'APP.MAILING.TABLE.HEADER.LEGAL_ENTITY' | translate"
            >
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{
                        getlegalentityReferenceNameforDisplay(
                            dataItem.documentHeader.legalEntityKey
                        )
                    }}
                </ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column
                field="filename"
                [title]="'APP.MAILING.TABLE.HEADER.FILE_NAME' | translate"
                [hidden]="false"
            ></kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>
                <p *ngIf="finishedLoading">
                    {{ "TABLE_MENU.NO_RECORD" | translate }}
                </p>
            </ng-template>
            <ng-template
                kendoGridRowClass="{'k-disabled': isRowDisabled($dataItem)}"
            >
            </ng-template>
        </kendo-grid>
    </div>
</div>
