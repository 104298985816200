<div class="stareTopic">
	{{ 'APP.LOGOUT.DESCRIPTION' | translate }}
</div>


<br />
<span class="plainText">{{ 'APP.LOGOUT.MESSAGE' | translate }}</span>
<br />
<br>
<br>

<strong>
	<span class="plainText">{{ 'APP.LOGOUT.MESSAGE2' | translate }}</span>
</strong>
<br>
<br>
<button kendoButton themeColor="primary" (click)="reloadApp()" type="button">
	<span class="plainText">{{ 'BUTTON.START_PAGE' | translate }}</span>
</button>