import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientEngagement, ClientEngagementForCreation, ClientEngagementForUpdate, ClientEngagementItem } from 'app/_models/client-engagement.model';
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { LogItem } from 'app/_models/log-item.model';
import { WbsItem } from 'app/_models/wbs.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiClientEngagementMaintenanceService {

  constructor(
    private http: HttpClient) { }

  /**
   * Add a user client engagement item
   */
  getAddClientEngagement(item: ClientEngagementForCreation): Observable<ClientEngagement> {
    return this.http.post<ClientEngagement>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/AddAsync', item, HTTP_OPTIONS);
  }

  /**
 * Update a client engagement item
 */
  getUpdateClientEngagement(clientEngagementKey: string, item: ClientEngagementForUpdate): Observable<ClientEngagementItem> {
    return this.http.post<ClientEngagementItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/UpdateAsync/' + clientEngagementKey, item, HTTP_OPTIONS);
  }

  /**
   * Delete a client engagement item
   */
  getDeleteClientEngagement(clientEngagementKey: string): Observable<ClientEngagementItem> {
    return this.http.delete<ClientEngagementItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/DeleteAsync/' + clientEngagementKey, HTTP_OPTIONS);
  }

  /**
   * Get all client engagements.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllClientEngagements(): Observable<ClientEngagementItem[]> {
    return this.http.get<ClientEngagementItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetAllAsync', HTTP_OPTIONS);
  }

  /**
  * Get all WBS
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getAllWBS(): Observable<WbsItem[]> {
    return this.http.get<WbsItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetAllWBSAsync', HTTP_OPTIONS);
  }

  /**
 * Get all Log items.
 * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
 */
  getAllLogs(items: number): Observable<LogItem[]> {
    return this.http.get<LogItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetLogAsync?maxItems=' + items, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
  * Request an export to the datalake
  */
  performDatalakeExport(): Observable<JobStatisticItem> {
    return this.http.post<JobStatisticItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/PerformDatalakeExport', HTTP_OPTIONS);
  }

  /**
* Get all Log items.
* @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
*/
  getAllLogsWithFilter(items: number, message: string | null, id: number | null): Observable<LogItem[]> {
    if (message == null && id == null) {
      return this.getAllLogs(items);
    } else if (message != null && id == null) {
      return this.http.get<LogItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetLogAsync?maxItems=' + items + '&messageFilter=' + message.toString(), HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    } else if (message == null && id != null) {
      return this.http.get<LogItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetLogAsync?maxItems=' + items + '&workflowId=' + id, HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    } else {
      return this.http.get<LogItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'ClientEngagement/GetLogAsync?maxItems=' + items + '&messageFilter=' + message!.toString() + '&workflowId=' + id, HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    }
  }
}
