
import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SortDescriptor } from "@progress/kendo-data-query";
import { ColumnMenuSettings, GridComponent, GridSize, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DateTimeService } from 'app/_services/date-time.service';
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { MailSendingService } from 'app/_services/StareApi/mail-sending.service';
import { MailForSendingUIItem } from 'app/_models/mail-for-sending.model';
import { Router } from '@angular/router';
import { TooltipService } from 'app/_services/tooltip.service';

@Component({
  selector: 'app-setting-sent-mail',
  templateUrl: './setting-sent-mail.component.html',
  styleUrl: './setting-sent-mail.component.scss',
  standalone: false
})
export class SettingSentMailComponent {

  public smallSize: GridSize = "small";
  /** Displayed (filtered) data */
  public tableData = [] as MailForSendingUIItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as MailForSendingUIItem[];
  /** API request is finished */
  public finishedLoading = false;


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // =========================================================
  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private dateTimeService: DateTimeService,
    private mailSendingService: MailSendingService,
    private router: Router,
    public tooltipService: TooltipService
  ) { this.allData = this.allData.bind(this); }


  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.SENT_MAILS.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }

  // == Resize Listener =============================================================================================================
  public pageHeight: number = window.innerHeight - 125;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.mailSendingService.getAllForUI().subscribe({
      next: (res: MailForSendingUIItem[]) => {

        // Descripbe the jobCodeLabelDE from the jobCode
        if (res != null) {

          res.forEach((item: MailForSendingUIItem) => {
            if (item.createdDate) {
              //console.log(item.createdDate);
              item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
              console.log(item.createdDate.toString());
            }
            if (item.sentDate) {
              item.sentDate = this.dateTimeService.convertUTCTimeToLocalTime(item.sentDate);
            }
          });

        }


        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });


  }

  openFile(event: NavigationRow): void {
    if (event != null && !event.dataItem.isHidden) {

      this.router.navigate(
        ['/mail-editor-view'],
        {
          queryParams: {
            'w': event.dataItem.id
          }
        }
      );
    }

  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;




  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;


  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    //this.loadData();
  }


  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });

      filter.filters.push({
        field: "to",
        operator: "contains",
        value: value,
      });

      filter.filters.push({
        field: "subject",
        operator: "contains",
        value: value,
      });

      filter.filters.push({
        field: "errorMessage",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }




}
