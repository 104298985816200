
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLocal } from 'app/_models/user.model';
import { ENVIRONMENT } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ClientMailItem, ClientMailItemForUpdateItem } from 'app/_models/client-mail.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import * as pdfjsLib from 'pdfjs-dist';
// pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.mjs';
//pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;
pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { FileExtractionContentDto, FileItem } from 'app/_models/file.model';
import * as FileSaver from 'file-saver';
import { CellClickEvent, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { IWorkflowMetadata } from 'app/_models/workflow-metadata.model';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ApiFileService } from 'app/_services/StareApi/file.service';
import { ApiClientMailingService } from 'app/_services/StareApi/client-mailing.service';
import { SVGIcon, zoomInIcon, zoomOutIcon, zoomBestFitIcon, zoomActualSizeIcon, undoIcon, downloadIcon, saveIcon } from '@progress/kendo-svg-icons';

import { TextLayer } from 'pdfjs-dist';
import { ButtonComponent } from '@progress/kendo-angular-buttons';


@Component({
  selector: 'app-mail-editor',
  templateUrl: './mail-editor.component.html',
  styleUrls: ['./mail-editor.component.scss'],
  standalone: false
})
export class MailEditorComponent implements OnInit {

  downloadSuccess: boolean = true;
  public finishedLoading = false;
  public isLoadingAttachments = true;
  public attachments: FileItem[] = [];
  public clientMail?: ClientMailItem;
  public workflowId: number = 0;
  public actualStateId: number = 0;
  public clientEngagementKey: string = '';
  public currentUser: UserLocal = {} as UserLocal;
  public isFormLoading: boolean = false;
  public isEditable: boolean = false;

  public splitDisabled: boolean = false;

  public buttonText: string = "Absenden";

  // ========================================================================
  constructor(

    private titleService: Title,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private apiClientMailingService: ApiClientMailingService,
    private notificationService: WrapperNotificationService,
    private apiWorkflowService: ApiWorkflowService,
    private apiAuthService: ApiAuthentificationService,
    private fileservice: ApiFileService

  ) { }


  ngOnInit(): void {

    // console.log('loading');
    this.isFormLoading = true;

    setTimeout(() => {
      this.isFormLoading = false;
    }, 8000); // 8 seconds timeout for loading
    this.translateService.get('APP.MAIL_EDITOR.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    this.route.queryParams.subscribe(params => {
      this.workflowId = params.w ?? 0;
      this.actualStateId = params.s ?? 0;
      this.clientEngagementKey = params.c ?? '';

      //#region Safety checks

      // If no workflowId is given, redirect to tasks
      if (this.workflowId == 0) {
        this.router.navigate(['/tasks']);
      }
      if (this.actualStateId == 0) {
        this.router.navigate(['/tasks']);
      }

      if (this.actualStateId == 8000) {
        this.buttonText = "Löschen";
      }
      if (this.actualStateId == 9000) {
        this.splitDisabled = true;
      }

      // console.log(this.splitDisabled);
      //this.clientEngagementKey  muss mit dem aktuellen User übereinstimmen, sonst Fehlermeldung und zurück zu Tasks  
      const jsonStr = localStorage.getItem('user') || '';
      if (jsonStr.length > 0) {
        const jsonObj = JSON.parse(jsonStr);
        const tmpUser = jsonObj as UserLocal;

        if (this.clientEngagementKey != tmpUser.clientEngagementKey) {
          //this.router.navigate(['/tasks']);
          //save url and redirect to login --bug 22457
          let url = this.router.url;
          url = ENVIRONMENT.primaryDomain + url;
          this.notificationService.showSuccess('Sie werden umgeleitet. Bitte warten Sie.');
          this.apiAuthService.setRedirection(url, this.clientEngagementKey);
          this.router.navigate(['/login']);
          return;
        }

        this.currentUser = tmpUser;
      }

      //#endregion

      this.apiWorkflowService.getWorkflowMetaDataById(this.workflowId).subscribe((response: IWorkflowMetadata) => {
        if (this.actualStateId != response.actualStateId) {
          this.router.navigate(['/tasks']);
        }
        this.isEditable = response.isEditable;
        this.ButtonDisable(!response.isEditable);
      });

      this.apiClientMailingService.getClientMailByWorkflowId(this.workflowId)
        .subscribe({
          next: (res: ClientMailItem) => {

            this.clientMail = res as ClientMailItem;
            this.attachments = res.attachements ?? [];
            if (this.attachments.length != 0) {
              this.sort(); // desc order of filename
              this.openFile(this.attachments[0].fileId);
              //this.focusRow(this.attachments[0].fileId);
            }
            this.editorForm.patchValue({
              to: res.to,
              cc: res.cc,
              subject: res.subject,
              body: res.body
            });
            this.isFormLoading = false;
            this.isLoadingAttachments = false;
          },
          error: (err: HttpErrorResponse) => {
            console.error(err.message);
            this.finishedLoading = true;
            this.isFormLoading = false;
            this.isLoadingAttachments = false;
          },
          complete: () => {
            this.isFormLoading = false;
            this.finishedLoading = true;
            this.isLoadingAttachments = false;
          }
        });
      // this.buildWorkflowTriggerList(this.workflowId);
    });



  }



  // == Resizing the grid ==================================================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Helpers ==================================================================

  commaSepEmailTo = (control: AbstractControl): { [key: string]: { value: string } } | null => {
    const emails = control.value?.split(',') || [];
    const forbidden = emails.some((email: string) => Validators.email(new FormControl(email?.trim())));
    return forbidden ? { 'to': { value: control.value } } : null;
  };

  commaSepEmailCc = (control: AbstractControl): { [key: string]: { value: string } } | null => {
    if (control.value == '' || control.value == null)
      return null;
    const emails = control.value?.split(',');
    const forbidden = emails.some((email: string) => Validators.email(new FormControl(email?.trim())));
    return forbidden ? { 'cc': { value: control.value } } : null;
  };


  // == Forms =========================================================================
  public editorForm: FormGroup = new FormGroup({
    to: new FormControl("", [Validators.required, this.commaSepEmailTo]),
    cc: new FormControl("", [this.commaSepEmailCc]),
    body: new FormControl("", Validators.required),
    subject: new FormControl("", Validators.required),
  });



  // == E-Mail Settings ============================================

  public customColors: string[] = [
    "#000000", "#333333", "#666666", "#999999", "#cccccc", "#ffffff",
    "#ff0000", "#ff9900", "#ffff00", "#00ff00", "#00ffff", "#0000ff",
    "#9900ff", "#ff00ff"
    // Add more custom colors as needed  
  ];

  public fontData = [
    { text: "7 pt", size: "7pt" },
    { text: "8 pt", size: "8pt" },
    { text: "9 pt", size: "9pt" },
    { text: "10 pt", size: "10pt" },
    { text: "11 pt", size: "11pt" },
    { text: "12 pt", size: "12pt" },
    { text: "14 pt", size: "14pt" },
    { text: "16 pt", size: "16pt" },
  ];

  public resizable: boolean | EditorResizableOptions = {
    minWidth: 900,
    minHeight: 640,
    maxWidth: 1500,
    maxHeight: 650
  };


  //#region == Execute Workflow ==========================================================================================================================

  public backSVG: SVGIcon = undoIcon;
  public saveSVG: SVGIcon = saveIcon;
  private saveAndNextWorkflowStep: boolean = false;

  @ViewChild('btnSave', { static: false })
  btnSave!: ElementRef<ButtonComponent>;





  // == Handlers ================================

  public ButtonDisable(disable: boolean = true): void {
    this.btnSave.nativeElement.disabled = disable;

  }



  onSubmit(): void {

    // stop here if form is invalid
    if (this.editorForm.invalid || this.clientMail == undefined) {
      this.notificationService.showError('Es ist ein Fehler aufgetreten.');
      return;
    }

    this.isFormLoading = true;
    // Update
    let itemForUpdateItem = {} as ClientMailItemForUpdateItem;
    itemForUpdateItem = {
      to: this.editorForm.value.to,
      cc: this.editorForm.value.cc,
      body: this.editorForm.value.body,
      subject: this.editorForm.value.subject
    };

    // console.log(itemForUpdateItem);

    this.apiClientMailingService.updateClientMailById(this.clientMail.id, itemForUpdateItem).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Mail wurde erfolgreich gespeichert.');

      },
      error: (err: HttpErrorResponse) => {
        this.isFormLoading = false;
        console.error(err);
        if (err.status == 400) {
          this.notificationService.showError('Die Daten konnten nicht verarbeitet werden.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        this.isFormLoading = false;
      }
    });
  }


  /** Back to Task list */
  public onClickCancelButton(): void {
    if (this.actualStateId == 9000) {
      this.router.navigate(['/archive']);
    } else {
      this.router.navigate(['/tasks']);
    }

  }

  onAction(action: boolean): void {
    console.log(`Action: ${action}`);
    this.isFormLoading = action;
  }




  //removed for new splitbutton

  /*  execWorkflowButtonData = [
     { name: "Text neu erstellen", disabled: false, optionValue: 2, isDefault: false, click: (): void => { this.onReset(); } }]; */

  /**
   * Execute the worklow (sending the mail)
   */
  onExecute(tiggerPassed: number): void {

    if (this.clientMail == undefined) {
      this.notificationService.showError('Es ist ein Fehler bei onExecute aufgetreten.');
      return;
    }

    if (this.editorForm.invalid && this.actualStateId != 8000) {
      this.notificationService.showError('Bitte alle Felder ausfüllen.');
      return;
    }

    if (this.actualStateId == 8000) {
      this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, tiggerPassed).subscribe({
        next: () => {
          this.notificationService.showSuccess('Gelöscht!');
          this.router.navigate(['/tasks']);
        }, error: () => {
          this.router.navigate(['/tasks']);
        }
      });

      return;
    }

    this.isFormLoading = true;

    // First: Save current form
    let itemForUpdateItem = {} as ClientMailItemForUpdateItem;
    itemForUpdateItem = {
      to: this.editorForm.value.to,
      cc: this.editorForm.value.cc,
      body: this.editorForm.value.body,
      subject: this.editorForm.value.subject
    };

    this.apiClientMailingService.updateClientMailById(this.clientMail.id, itemForUpdateItem).subscribe({
      next: () => {

        // Absenden
        // console.log("sending");
        // let triggerId = trigger; // Send Mail to Review
        this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, tiggerPassed).subscribe({
          next: () => {
            this.router.navigate(['/tasks']);
          },
          error: (err: HttpErrorResponse) => {
            this.isFormLoading = false;
            if (err.status == 400) {
              this.notificationService.showError('Fehler beim Absenden: ' + err.message);
            } else if (err.status == 405) {
              this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
            } else if (err.status == 406) {
              this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
            } else if (err.status == 409) {
              this.notificationService.showError('Es liegen Fehler vor.');
              //this.eventSaved.emit();
            } else if (err.status == 410) {
              this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
            } else if (err.status == 416) {
              this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
            } else if (err.status == 422) {
              this.notificationService.showError('Interner Fehler beim Speichern der Zahlungen');
            } else if (err.status == 424) {
              this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
            } else {
              this.notificationService.showError('Interner Fehler beim Absenden: ' + err.message);
            }

          },
          complete: () => {
            this.isFormLoading = false;
          }

        });



      },
      error: (err: HttpErrorResponse) => {
        this.isFormLoading = false;
        console.error(err);
        if (err.status == 400) {
          this.notificationService.showError('Die Daten konnten nicht verarbeitet werden.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        this.isFormLoading = false;
      }
    });

  }
  /**
   * to reset client mail body text
   * @returns 
   */
  onReset(): void {
    if (this.clientMail == undefined || this.clientMail == null) {
      this.notificationService.showError('Es ist ein Fehler aufgetreten.Kein Data gefunden');
      return;
    }

    this.isFormLoading = true;


    this.apiClientMailingService.resetMailText(this.workflowId).subscribe({
      next: (res: ClientMailItem) => {
        this.clientMail = res;
        this.editorForm.patchValue({
          to: res.to,
          cc: res.cc,
          subject: res.subject,
          body: res.body
        });

        this.editorForm.controls.body.reset();
        this.editorForm.get('body')?.setValue(res.body);
      },
      error: (err: HttpErrorResponse) => {
        this.isFormLoading = false;
        console.error(err.message);

        if (err.status == 409) {
          this.notificationService.showError('Der Bescheid ist bereits mit einer anderen E-Mail Erstellung verknüpft.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }

      },
      complete: () => {
        this.isFormLoading = false;
      }
    });
  }

  // == Attachments Grid ============================================

  public downloadSVG: SVGIcon = downloadIcon;
  public sort(): void {
    // descending order for filename in attcahmnets grid
    this.attachments.sort((a: FileItem, b: FileItem) => {
      const nameA = a.filename.toLowerCase();
      const nameB = b.filename.toLowerCase();

      if (nameA > nameB) {
        return -1;
      } else if (nameA < nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  public cellClickHandler(args: CellClickEvent): void {
    const gridElement: HTMLElement = this.grid.wrapper.nativeElement;
    const focusedElements = gridElement.querySelectorAll('tr.k-master-row.k-table-row.k-focus.k-selected'); //remove the initial focus on the row item
    if (focusedElements.length != 0)
      focusedElements.forEach(element => {
        (element as HTMLElement).classList.remove('k-focus');
        (element as HTMLElement).classList.remove('k-selected');
      });

    args.sender.focus();
    const fileItem = args.dataItem as FileItem;
    this.openFile(fileItem.fileId);
  }

  onClickEvent(item: NavigationRow): void {

    const fileItem = item.dataItem as FileItem;

    const f = fileItem.fileId;

    // Open new window to the download component
    // TO DO :here we do not need to open new component_ download can start automatically:bug 22623

    // Send file to client
    this.fileservice.downloadPdf(f).subscribe(res => {

      this.downloadSuccess = true;

      //let fileAsBlob = this.fileservice.base64toBlob(res.contentAsBase64!, res.mimeType!);

      FileSaver.saveAs(res.blob, res.filename);
      this.notificationService.showSuccess('Die Datei wurde erfolgreich heruntergeladen');

    }
      //   ,
      //   error: (err: HttpErrorResponse) => {
      //     console.error(err);

      //     this.notificationService.showError('Unbekannter Fehler');
      //     this.downloadSuccess = false;

      //     if (err.status == 400) {
      //       this.notificationService.showError('Die Datei wurde nicht gefunden.');
      //     }

      //     if (err.status == 410) {
      //       this.notificationService.showError('Die Datei wurde nicht gefunden.');
      //     }

      //   }
      // }
    );

  }

  focusRow(id: string): void {
    const rowIndex = this.attachments.findIndex((item: { fileId: string; }) => item.fileId === id);
    if (rowIndex !== -1) {
      this.focusRowByIndex(rowIndex);
    }
  }

  private focusRowByIndex(rowIndex: number): void {

    const gridElement = this.grid.wrapper.nativeElement; // Get the native element of the grid  
    const rows = gridElement.querySelectorAll('tbody tr');// Select all rows in the grid

    const row = rows[rowIndex];
    if (row) {
      row.focus(); // Focus the row  
      row.classList.add('k-focus');
      row.classList.add('k-selected');
    }

  }

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    //if (element.firstElementChild?.nodeName != 'BUTTON')
    //this.tooltipDir.hide();
    if ((element.nodeName === 'TD' || element.className === 'k-column-title')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }



  //#region == PDF Handler ==========================================================================================================================



  public zoomInSVG: SVGIcon = zoomInIcon;
  public zoomOutSVG: SVGIcon = zoomOutIcon;
  public zoomBestFitSVG: SVGIcon = zoomBestFitIcon;
  public zoom100SVG: SVGIcon = zoomActualSizeIcon;
  public pageCountText: string = '';
  public filename: string = '';
  public fileSourceURL: string = '';
  /** Guid of displayed pdf file */
  private fileId: string = '';
  /** From Abbyy, with textlayer */
  public fileItem: FileExtractionContentDto = {} as FileExtractionContentDto;

  // ** From Abbyy, with textlayer
  public fileItemMetadata: FileItem = {} as FileItem;

  /** From DMS */
  public fileOriginalItem: FileItem = {} as FileItem;

  public scale: number = 1.0;
  public isScaleBestFit: boolean = true;
  public pdfDoc: pdfjsLib.PDFDocumentProxy | null = null;
  private pdfLayoutResizerActive: boolean = false;

  public pdfLayputSizeChange(): void {
    this.pdfLayoutResizerActive = true;
  }

  /** Received file from backend and display on UI */
  @ViewChild("grid", { static: false })
  grid!: GridComponent;

  private async openFile(fileid: string) {

    if (this.fileId == fileid) { //PDF file already open bug 22772
      this.isFormLoading = false;
      this.isLoadingAttachments = false;
      return;
    }

    this.filename = '';
    this.fileId = '';

    this.fileservice.getFileMetadataById(fileid).subscribe((response2: FileItem) => {
      this.fileItemMetadata = response2;
    });


    const pdfData = await this.fileservice.apiFileAsPromise(fileid)
      .then((response: FileExtractionContentDto) => {
        this.fileItem = response;

        this.filename = this.fileItem.filename;
        this.fileId = this.fileItem.fileId;
        this.focusRow(this.fileId);

        // Set the page count text
        if (this.fileItemMetadata.pageCount != null) {
          if (this.fileItemMetadata.pageCount == 1) {
            this.pageCountText = '1 Seite';
          }
          else {
            this.pageCountText = this.fileItemMetadata.pageCount + ' Seiten';
          }
        }

        if (response.contentAsBase64 == null) {
          console.error('No PDF content available.');
          this.filename = 'No PDF available'
          this.isFormLoading = false;
          return; // pdfdata = void??
        }

        return atob(response.contentAsBase64);
      }).catch((err: HttpErrorResponse) => {
        console.log(err);
        this.isFormLoading = false;
        return undefined;//  otherwise void returned
      });

    // Using DocumentInitParameters object to load binary data.
    const loadingTask = pdfjsLib.getDocument({ data: pdfData });
    loadingTask.promise.then((pdf) => {
      this.pdfDoc = pdf;
      this.pdfRender().then(() => {
        console.log('pdf render resolved');
        //this.isFormLoading = false;
      }).catch(err => {
        console.log(err);
        this.isFormLoading = false;
      });

    }, (reason) => {
      // PDF loading error
      console.error(reason);
      this.isFormLoading = false;
    }).catch(err => {
      console.log(err);
      this.isFormLoading = false;
    });
  }

  /** Render the PDF file */
  private async pdfRender() {
    const pdfWrapper = <HTMLElement>document.getElementById('pdf-wrapper');
    const pdfLayoutPanel = <HTMLElement>document.getElementById('pdf-layout-panel');

    const pdf = this.pdfDoc;



    if (!pdf) {
      console.error('No PDF loaded. Use openFile() first.');
      return;
    }

    // Fetch all pages
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      const lastPageNum = pdf.numPages + 1;
      pdf.getPage(pageNumber).then((page) => {
        //let scale = 1;

        if (this.isScaleBestFit) {
          const viewport = page.getViewport({ scale: 1 });
          const pdfWrapperWidth = pdfLayoutPanel.getBoundingClientRect().width;
          const scale = pdfWrapperWidth / viewport.width;
          this.scale = scale;
        }



        const viewport = page.getViewport({ scale: this.scale });
        const pageIndex = page._pageIndex + 1;



        // Try to get child element of pdfwrapper with id 'page-<pageIndex>'
        let pageWrapper = <HTMLElement>document.getElementById('page-' + pageIndex);
        if (!pageWrapper) {

          pageWrapper = <HTMLElement>document.createElement('div');
          pageWrapper.id = 'page-' + pageIndex;
          pageWrapper.className = 'page-wrapper';
          pageWrapper.setAttribute('style', 'position: relative; margin-bottom: 0rem;');

          // Create Page Number Div
          if (pageIndex > 1) {
            const pageNumberDiv = <HTMLElement>document.createElement('div');
            pageNumberDiv.id = 'page-number-' + pageIndex;
            pageNumberDiv.className = 'page-number';
            pageNumberDiv.setAttribute('style', '    border-bottom-width: 3px; border-bottom-color: #fce3c0; border-bottom-style: dashed; border-left-color: #fce3c0; border-left-style: solid; border-left-width: 3px; padding-left: 5px; font-weight: bold;');
            pageNumberDiv.innerHTML = 'Seite ' + pageIndex;  //+ ' von ' + pdf.numPages;
            pdfWrapper.appendChild(pageNumberDiv);
          }


          pdfWrapper.appendChild(pageWrapper);
        }

        // try to get child element of pageWrapper with id 'canvas-<pageIndex>'
        let canvas = <HTMLCanvasElement>document.getElementById('canvas-' + pageIndex);
        if (!canvas) {

          // Create a new Canvas element
          canvas = <HTMLCanvasElement>document.createElement('canvas');
          canvas.id = 'canvas-' + pageIndex;
          pageWrapper.appendChild(canvas);
        }

        // Prepare canvas using PDF page dimensions
        const context = <CanvasRenderingContext2D>canvas.getContext('2d', { willReadFrequently: true });
        canvas.height = viewport.height;
        //canvas.width = 500;  //viewport.width;

        // Get the width of the pdfWrapper and set the canvas width accordingly
        let pdfWrapperWidth = pdfLayoutPanel.getBoundingClientRect().width;

        // console.log('Viewport width: ' + viewport.width);
        // console.log('PDF-Wrapper width: ' + pdfWrapperWidth);
        // console.log('Scale: ' + this.scale);

        // if 0 then
        if (pdfWrapperWidth == 0) {

          let strWidth = "400px";

          // remove 'px' from the string
          strWidth = strWidth.substring(0, strWidth.length - 2);

          // convert to number
          pdfWrapperWidth = +strWidth;


        }

        canvas.width = pdfWrapperWidth;

        // PDF DPI
        // console.log("Page Info");
        // console.log(page._pageInfo);



        //console.log('width: ' + canvas.width + ' height: ' + canvas.height);

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext).promise.then(() => {
          this.finishedLoading = true;
          // Returns a promise, on resolving it will return text contents of the page
          return page.getTextContent();
        }).then(async (textContent) => {

          // Get html element "text-layer" under the page-wrapper element
          let textLayer = this.findChild('page-' + pageIndex, 'text-layer');
          if (!textLayer) {

            // Assign CSS to the text-layer element
            textLayer = <HTMLElement>document.createElement('div');
            textLayer.id = 'text-layer';
            pageWrapper.appendChild(textLayer);
          }
          textLayer.style.left = canvas.offsetLeft + 'px';
          textLayer.style.top = canvas.offsetTop + 'px';
          textLayer.style.height = canvas.offsetHeight + 'px';
          textLayer.style.width = canvas.offsetWidth + 'px';


          // let dpiX = this.fileItemMetadata.dpiX ?? 300;
          // let dpiY = this.fileItemMetadata.dpiY ?? 300;

          // let abbyyDpiX = this.scale * (canvas.width / ((canvas.width / 72) * dpiX));  // width in pt / 72 = inch * 300 dpi
          // let abbyyDpiY = this.scale * (canvas.width / ((canvas.width / 72) * dpiY));  // width in pt / 72 = inch * 300 dpi


          // Pass the data to the method for rendering of text over the pdf canvas
          // pdfjsLib.renderTextLayer({
          //   textContent: textContent,
          //   container: textLayer,
          //   viewport: viewport,
          //   textDivs: []
          // });

          const textLayerForRender = new TextLayer({
            textContentSource: textContent,
            viewport: viewport,
            container: textLayer,
          });
          await textLayerForRender.render();

        });
      }).then(() => {
        console.log('pdf page load' + this.finishedLoading);
        //this.finishedLoading is false
        if ((pageNumber == lastPageNum) && (this.finishedLoading))
          this.isFormLoading = false;
      }).catch(err => {
        console.log(err);
        this.isFormLoading = false;
      });
    }

    return true;
  }

  private findChild(idOfElement: string, idOfChild: string): HTMLElement | null {
    const element = document.getElementById(idOfElement);
    if (element == null) {
      return null;
    }
    const result = element.querySelector('[id=' + idOfChild + ']');
    if (result == null) {
      return null;
    }
    return result as HTMLElement;
  }

  public pdfZoomIn(): void {
    this.isScaleBestFit = false;
    this.scale = this.scale + 0.3;
    this.pdfRender();
  }

  public pdfZoomOut(): void {
    this.isScaleBestFit = false;
    this.scale = this.scale - 0.3;

    if (this.scale < 0.3) {
      this.scale = 0.3;
    }
    this.pdfRender();
  }

  public pdfZoom100(): void {
    this.isScaleBestFit = false;
    this.scale = 1;


    this.pdfRender();
  }

  public pdfZoomBestFit(): void {
    this.isScaleBestFit = true;
    this.scale = 1;


    this.pdfRender();
  }



}

