
import { Injectable } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';


@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor() { }

  public showTooltipHeader(e: MouseEvent, tooltipDir: TooltipDirective): void {

    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.className === 'k-column-title')
      && element.offsetWidth < element.scrollWidth) {
      tooltipDir.toggle(element);
    } else {
      tooltipDir.hide();
    }
  }


  public showTooltipInner(e: MouseEvent, tooltip: TooltipDirective): void {



    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TD' || element.nodeName === 'TD' || element.className === 'k-column-title')
      && element.offsetWidth < element.scrollWidth && element.textContent != "") {

      tooltip.toggle(element, true);
    } else {

      tooltip.hide();
    }
  }

  public showTooltipForm(e: MouseEvent, tooltip: TooltipDirective, label: string): void {

    // console.log(label);
    // console.log(label.length);
    const element = e.target as HTMLElement;
    // console.log(element.nodeName);
    // console.log(element.className);

    // console.log(element.offsetWidth);
    if (label === "Begründung zur Deaktivierung der Referenzdatenprüfung (für interne Dokumentation)") {
      tooltip.toggle(element, true);
      return;
    }



    if (element.offsetWidth <= 200) {
      if ((element.nodeName === 'DIV' || element.nodeName === 'INPUT' || element.nodeName === 'KENDO-DATEPICKER' || element.className === 'k-input-inner' || element.className === 'k-input-value-text')
        && element.offsetWidth <= element.scrollWidth && label.length >= 25) {

        tooltip.toggle(element, true);
      } else {

        tooltip.hide();
      }
    } else {
      if ((element.nodeName === 'DIV' || element.nodeName === 'INPUT' || element.nodeName === 'KENDO-DATEPICKER' || element.className === 'k-input-inner' || element.className === 'k-input-value-text')
        && element.offsetWidth <= element.scrollWidth && label.length >= 43) {

        tooltip.toggle(element, true);
      } else {

        tooltip.hide();
      }
    }
  }

  // public showTooltipDropdown(e: MouseEvent, tooltip: TooltipDirective, label: string): void {

  //   console.log(label);
  //   console.log(label.length);
  //   const element = e.target as HTMLElement;

  //   console.log(element);
  //   console.log(element.className);
  //   console.log(element.nodeName);
  //   console.log(element.offsetWidth);

  // if (element.className === 'templateForDropdown') {
  //   tooltip.hide();
  //   return;
  // }
  // if (element.offsetWidth < 120) {

  // if (element.className === 'dropdownItem' && element.offsetWidth <= element.scrollWidth) {
  //   console.log("label");
  //   tooltip.toggle(element, true);
  // } else {
  //   console.log("label2");
  //   tooltip.hide();
  // }
  // } 
  // else {
  //   if (label.length > 50) {
  //     console.log("label3");
  //     tooltip.toggle(element, true);
  //   } else {
  //     console.log("label4");
  //     tooltip.toggle(element, false);
  //   }
  // }

  // }
}