import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLocal } from '../_models/user.model';


@Injectable({
    providedIn: 'root'
})
export class AdminGuard {

    public myuser: UserLocal = {} as UserLocal;

    constructor() { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let jsonStr = localStorage.getItem('user') || '';
        if (jsonStr.length > 0) {
            let jsonObj = JSON.parse(jsonStr);
            this.myuser = jsonObj as UserLocal;

            if (this.myuser.isAdmin) {
                return true;
            }
        }
        return false;
        //TODO: Muss noch mal implementiert werden. Wenn überhaupt noch notwendig, da Http Interceptor die Prüfung auch vornimmt.
        //return this.apiService.user.isAdmin;
    }

}