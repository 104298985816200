import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListsService } from 'app/_services/lists.service';
import {
    ReferenceDisabledListForControl,
    ValidationErrorListForControl,
    ValidationInfoListForControl,
} from 'app/_models/validation-list.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldItem } from 'app/_models/form-field.model';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { ValidationMessageItem } from 'app/_models/validation-message.model';
import {
    AbstractControl,
    FormArray,
    FormGroup,
    UntypedFormArray,
    UntypedFormGroup,
} from '@angular/forms';
import { FormFieldSaveModel } from 'app/_models/form-field-save.model';
import { DatasetItem } from 'app/_models/dataset.model';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { INotificationModel } from 'app/_models/notification.model';
import { DataService } from 'app/_services/StareApi/data.service';
import { WorkflowBaseUiItem } from 'app/_models/workflow.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import {
    ValidationDeactivationForCreationItem,
    ValidationDeactivationItem,
    ValidationDeactivationTemporaryItem,
} from 'app/_models/validation-deactivation.model';
import { SaveRequestItem } from 'app/_models/save-request-item.model';
import { TaxNoticeNameItem } from 'app/_models/tax-notice-name.model';


@Component({
    selector: 'app-doc-base',
    templateUrl: './doc-base.component.html',
    styleUrls: ['./doc-base.component.scss'],
    standalone: false,
})
export abstract class DocBaseComponent implements OnInit {
    @Output() buttonsDisable = new EventEmitter();
    @Output() notificationMessage = new EventEmitter();
    @Output() validationMessageList = new EventEmitter();
    @Output() referenceMessageList = new EventEmitter();
    @Output() rectangleFieldList = new EventEmitter();
    @Output() savedSuccessfully = new EventEmitter();
    @Output() formLoadedSuccessfully = new EventEmitter();

    /** User will save the form (sending values to the backend) */
    @Input() onButtonSaveClick = new EventEmitter();
    /** To loading the workflow */
    @Input() workflowIdInput: number = 0;
    /** Safty check: Don't open an old workflow */
    @Input() acutalStateIdInput: number = 0;
    /** To get type 11 for sdc workflow */
    @Input() workflowTypeInput: number = 0;

    private updateSubscription: Subscription = EMPTY.subscribe();

    constructor(
        private listsService: ListsService,
        private apiUIViewerService: UIViewerService,
        private apidataService: DataService,
        private dateTimeService: DateTimeService,
        private apiDatasetService: ApiDatasetService,
        private apiWorkflowService: ApiWorkflowService
    ) { }

    ngOnInit(): void {
        //this.isFormLoading = true;

        this.isDebug = localStorage.getItem('debug') == '1' ? true : false;

        // Load lists
        this.listsService.GetTaxDeterminationList().subscribe((data) => {
            this.taxdetList = data as DropDownListItem[];
        });
        this.loadEntities();
        // to load the list of legal entities after adding legal entitty
        this.updateSubscription = this.apidataService.update$.subscribe(() => {
            // console.log('update legal entities');
            this.loadEntities();
        });
        this.listsService
            .GetChamberOfCommerceAndIndustryList()
            .subscribe((data) => {
                // Order data by labelDE. Ignore case and german umlauts
                data.sort((a, b) =>
                    a.labelDE.localeCompare(b.labelDE, 'de', {
                        sensitivity: 'base',
                    })
                );

                this.chamberOfCommerceAndIndustrySource =
                    data as DropDownStringItem[];
                this.chamberOfCommerceAndIndustryList =
                    this.chamberOfCommerceAndIndustrySource.slice();
            });
        this.listsService.GetCommuneAGSList().subscribe((data) => {
            // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
            data.forEach((item: DropDownStringItem) => {
                if (this.acutalStateIdInput != 9000) {
                    data = data.filter(
                        (item: DropDownStringItem) => item.isDeleted != true
                    );
                } else {
                    if (item.isDeleted) {
                        item.labelDE = item.labelDE + ' (gelöscht)';
                    }
                }
            });

            this.communeAGSSource = data as DropDownStringItem[];

            // Order by labelDE. Ignore case and german umlauts
            this.communeAGSSource.sort((a, b) =>
                a.labelDE.localeCompare(b.labelDE, 'de', {
                    sensitivity: 'base',
                })
            );
            this.communeAGS = this.communeAGSSource.slice();
        });
        this.listsService.GetAuditResultsList().subscribe((data) => {
            // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
            data.forEach((item: DropDownListItem) => {
                if (this.acutalStateIdInput != 9000) {
                    data = data.filter(
                        (item: DropDownListItem) => item.isDeleted != true
                    );
                } else {
                    if (item.isDeleted) {
                        item.labelDE = item.labelDE + ' (gelöscht)';
                    }
                }
            });

            this.auditResults = data as DropDownListItem[];
        });

        this.listsService.GetTypesOfParticipation().subscribe((data) => {
            // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
            data.forEach((item: DropDownListItem) => {
                if (this.acutalStateIdInput != 9000) {
                    data = data.filter(
                        (item: DropDownListItem) => item.isDeleted != true
                    );
                } else {
                    if (item.isDeleted) {
                        item.labelDE = item.labelDE + ' (gelöscht)';
                    }
                }
            });

            this.typeOfParticipationSource = data as DropDownListItem[];
            this.typeOfParticipationSource.sort((a, b) =>
                a.labelDE.localeCompare(b.labelDE, 'de', {
                    sensitivity: 'base',
                })
            );
            this.typeOfParticipation = this.typeOfParticipationSource.slice();
        });
        this.listsService.GetAssessmentDeterminationList().subscribe((data) => {
            this.assessmentDetermination = data as DropDownListItem[];
        });
        // this.listsService.GetDocumentNamesAll();
        this.listsService.GetLegalRemedyResultList().subscribe((data) => {
            this.legalRemedyResult = data as DropDownListItem[];
        });
        this.listsService.GetLegalRemedyObjectionList().subscribe((data) => {
            this.legalRemedyObjection = data as DropDownListItem[];
        });
        this.listsService.GetCorporateIncomeTaxCopyFor().subscribe((data) => {
            this.corporateIncomeTaxCopyForOptions = data as DropDownListItem[];
        });
        this.listsService.GetVdNList().subscribe((data) => {
            this.vdnOptions = data as DropDownListItem[];
        });

        this.listsService.GetTaxationBases().subscribe((data) => {
            // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
            data.forEach((item: DropDownListItem) => {
                if (this.acutalStateIdInput != 9000) {
                    data = data.filter(
                        (item: DropDownListItem) => item.isDeleted != true
                    );
                } else {
                    if (item.isDeleted) {
                        item.labelDE = item.labelDE + ' (gelöscht)';
                    }
                }
            });

            this.taxbaseOptions = data as DropDownListItem[];
        });

        this.subscribeOnSave();

        this._actualStateId = this.acutalStateIdInput;
        this._workflowId = this.workflowIdInput;
        this._workflowType = this.workflowTypeInput;

        this.listsService
            .GetReferenceYears(this._workflowId)
            .subscribe((data) => {
                this.referenceYearsForPrepayment = data as DropDownStringItem[];
                // this.legalEntities = this.legalEntitiesSource.slice();
            });

        // this.loadCorporatePrePaymentYears(this._workflowId);

        // this.apidataService.getReferenceDeactivatedValidations(this._workflowId).subscribe((data) => {
        //     this._referenceDisabledMessages = data;
        //     this._saveReferenceDisabledItems = this._referenceDisabledMessages;
        //     this.referenceDisabledCollection =
        //         this.apidataService.UpdateReferenceDeactivatedList(
        //             this._referenceDisabledMessages
        //         );
        // });

        // console.log(this._referenceDisabledMessages);
        this.Load(this._workflowId, this._actualStateId);
        // this.changeSDCFieldsRowHeight();
    }

    public loadEntities(): void {
        this.listsService.GetLegalEntitiesList().subscribe((data) => {
            // Order data by labelDE. Ignore case and german umlauts
            data.sort((a, b) =>
                a.labelDE.localeCompare(b.labelDE, 'de', {
                    sensitivity: 'base',
                })
            );
            // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
            if (this.acutalStateIdInput != 9000) {
                data = data.filter(
                    (item: DropDownStringItem) => item.isDeleted != true
                );
            } else {
                data.forEach((item: DropDownStringItem) => {
                    if (item.isDeleted) {
                        item.labelDE = item.labelDE + ' (gelöscht)';
                    }
                });
            }

            this.legalEntitiesSource = data as DropDownStringItem[];
            this.legalEntities = this.legalEntitiesSource.slice();
        });
    }

    public loadCorporatePrePaymentYears(id: number): void {
        this.listsService.GetReferenceYears(id).subscribe((data) => {
            this.referenceYearsForPrepayment = data as DropDownStringItem[];
            // this.legalEntities = this.legalEntitiesSource.slice();
        });
    }

    public isDebug: boolean = false;

    protected _formGroup: UntypedFormGroup = new UntypedFormGroup({});

    /** Contains the HTML Id of the focused PDF rectangle */
    private _focusedFieldId: string = '';

    /** Items will send to backend */

    private _saveitems: FormFieldSaveModel[] = [];
    private _saveReferenceDisabledItems: ValidationDeactivationForCreationItem[] =
        [];
    // private _saveRequest: SaveRequestItem = {
    //     dataForCreationOrUpdateSimple: this._saveitems,
    //     validationDeactivationForCreationOrUpdate: this._saveReferenceDisabledItems
    // };

    /** Item displayed in the UI form */
    private _fields: FormFieldItem[] = [];
    private _fieldsForRectangles: FormFieldItem[] = [];

    private _workflowId: number = 0;
    private _workflowType: number = 0;
    private _actualStateId: number = 0;
    private _datatypeName: string = '';
    private _actualStateName: string = '';
    private _headerDatasetId: number = 0;
    private _documentTypeLabelDE: string = '';

    private _referenceMessages: ValidationMessageItem[] = [];
    private _validationMessages: ValidationMessageItem[] = [];
    private _referenceDisabledMessages: ValidationDeactivationItem[] = [];

    //public isFormLoading: boolean = false;

    /** These entries are not transmitted to the backend.  */
    private _ignoreXbrlBySaving: string[] = ['id_legalEntityKeyReference'];

    /** Nested and normal payloads */
    public documentPayloadArrayWithDatasetId: UntypedFormArray =
        new UntypedFormArray([]);

    //TODO: Prüfen, ob diese Variable nicht in die lokale Load() Methode gehört
    /** Kommen vom Backend, einmalig beim erstmaligen Laden der Seite*/
    public documentPayloadResultArray: DatasetItem[] = [];

    /** Normales Handling */
    public documentPayloadDatasetIdArray: number[] = [];

    //   public get documentPayloadDatasetIdArray(): number[]
    //   {
    //     // Extract id from documentPayloadResultArray
    //     let result = this.documentPayloadResultArray.map((x: DatasetItem) => x.id);
    // return result;
    //   }

    //#region Some getter

    /** Get current FormGroup */
    public get formGroup(): UntypedFormGroup {
        return this._formGroup;
    }

    /** Get all fields of this workflow datatype */
    public get workflowFields(): FormFieldItem[] {
        return this._fields;
    }

    public get rectangleFields(): FormFieldItem[] {
        return this._fieldsForRectangles;
    }

    public get saveItems(): FormFieldSaveModel[] {
        return this._saveitems;
    }

    public get workflowId(): number {
        return this._workflowId;
    }
    public get actualStateId(): number {
        return this._actualStateId;
    }
    public get workflowType(): number {
        return this._workflowType;
    }

    public get datatypeName(): string {
        return this._datatypeName;
    }
    //TODO: Ansgar: Rename to dataTypeNameLabelDE
    public get documentTypeLabelDE(): string {
        return this._documentTypeLabelDE;
    }

    //TODO: Ansgar: Rename to actualStateNameLabelDE
    public get actualStateLabelDE(): string {
        return this._actualStateName;
    }
    public get referenceMessages(): ValidationMessageItem[] {
        return this._referenceMessages;
    }
    public get validationMessages(): ValidationMessageItem[] {
        return this._validationMessages;
    }

    public get headerDatasetId(): number {
        return this._headerDatasetId;
    }

    //#endregion

    public taxdetList: DropDownListItem[] = [];
    public legalEntities: DropDownStringItem[] = [];

    public typeOfParticipation: DropDownListItem[] = [];

    public typeOfParticipationSource: DropDownListItem[] = [];
    public chamberOfCommerceAndIndustryList: DropDownStringItem[] = [];
    public chamberOfCommerceAndIndustrySource: DropDownStringItem[] = [];
    public communeAGS: DropDownStringItem[] = [];
    public communeAGSSource: DropDownStringItem[] = [];
    public legalEntitiesSource: DropDownStringItem[] = [];
    public auditResults: DropDownListItem[] = [];
    public assessmentDetermination: DropDownListItem[] = [];
    public legalRemedyResult: DropDownListItem[] = [];
    public legalRemedyObjection: DropDownListItem[] = [];
    public corporateIncomeTaxCopyForOptions: DropDownListItem[] = [];
    public valueAddedTypeOptions: DropDownListItem[] = [
        {
            labelEN: 'Bescheid',
            labelDE: 'Bescheid',
            value: 1,
            isDefault: false,
            isDeleted: false,
        },
        {
            labelEN: 'Mitteilung',
            labelDE: 'Mitteilung',
            value: 2,
            isDefault: false,
            isDeleted: false,
        },
    ];
    public vdnOptions: DropDownListItem[] = [];
    public taxbaseOptions: DropDownListItem[] = [];
    public withoutReferenceValidationExplanationRowHeight: number = 0;
    public sdcFieldsRowHeight: number = 0;
    public referenceYearsForPrepayment: DropDownStringItem[] = [];
    public defaultItem: DropDownListItem = {
        labelEN: 'select item',
        labelDE: 'Bitte auswählen...',
        isDefault: false,
        isDeleted: false,
    };
    //Original Items containing all atributes
    public documentTypeNameDropdownItems = [] as TaxNoticeNameItem[];
    //Items for the dropdown list if no Concretisation is needed with id as value and nameDE as label
    public documentTypeNameDropdownList = [] as TaxNoticeNameItem[];
    //Items for the dropdown list if Concretisation is set to true with id as value and nameDE as label
    public documentTypeNameDropdownListTrueOption = [] as TaxNoticeNameItem[];
    //Items for the dropdown list if Concretisation is set to false with id as value and nameDE as label
    public documentTypeNameDropdownListFalseOption = [] as TaxNoticeNameItem[];

    private addTextToComment(text: string): void {
        const comment =
            this._formGroup.controls['document_mail_reasonNotAccepted'].value;
        const newcomment = comment ? comment + ' ' + text : text;
        this._formGroup.patchValue({
            ['document_mail_reasonNotAccepted']: newcomment,
        });
    }

    public Load(workflowId: number, actualStateId: number): void {
        this._workflowId = workflowId;
        this._actualStateId = actualStateId;
        this._headerDatasetId = this.headerDatasetId;
        this._workflowType = this.workflowType;
        this.apiWorkflowService.getWorkflowById(workflowId).subscribe({
            next: (res: WorkflowBaseUiItem) => {
                this._actualStateId = res.actualStateId;
                this._workflowId = res.id;
                this._datatypeName = res.documentHeaderDatatypeName;
                this._headerDatasetId = res.documentHeaderId;

                this.apidataService.SetHeaderDatasetID(this._headerDatasetId);
                this._documentTypeLabelDE =
                    this.listsService.dataTypeItemList.find(
                        (x) => x.name == this._datatypeName
                    )?.labelMiddleDE ??
                    'Document type not found for name: ' + this._datatypeName;

                //res does not contain workflowType
                if (this._workflowType == 1) {
                    this._actualStateName =
                        this.listsService.workflowStateListForDeadline.find(
                            (x) => x.id == this._actualStateId
                        )?.displayName ??
                        'State label not found for id: ' + this._actualStateId;
                } else {
                    this._actualStateName =
                        this.listsService.workflowStateList.find(
                            (x) => x.id == this._actualStateId
                        )?.displayName ??
                        'State label not found for id: ' + this._actualStateId;
                }

                // Remove from this._validationMessage all items with isInternal == true
                res.validationMessageList = res.validationMessageList.filter(
                    (item: ValidationMessageItem) => item.isInternal == false
                );

                this._validationMessages = res.validationMessageList.filter(
                    (item: ValidationMessageItem) =>
                        item.isReferenceDataMessage == false
                );
                this._referenceMessages = res.validationMessageList.filter(
                    (item: ValidationMessageItem) =>
                        item.isReferenceDataMessage == true
                );

                if (this.isDebug) {
                    this._validationMessages.forEach(
                        (item: ValidationMessageItem) => {
                            item.messageDE =
                                item.messageDE +
                                ' (Debug: ' +
                                item.xbrlName +
                                ' DatasetId:' +
                                item.datasetId +
                                ')';
                        }
                    );
                    this._referenceMessages.forEach(
                        (item: ValidationMessageItem) => {
                            item.messageDE =
                                item.messageDE +
                                ' (Debug: ' +
                                item.xbrlName +
                                ' DatasetId:' +
                                item.datasetId +
                                ')';
                        }
                    );
                }

                this.buttonsDisable.emit(!res.isEditable);
                this.validationMessageList.emit(this._validationMessages);
                this.referenceMessageList.emit(this._referenceMessages);
                //console.log(this._validationMessages);
                this.fieldErrorValidations =
                    this.apidataService.UpdateValidationList(
                        this._validationMessages
                    );
                this.fieldInfoValidations =
                    this.apidataService.GetValidationInfoListForFields();
                this.apidataService
                    .getReferenceDeactivatedValidations(this._workflowId)
                    .subscribe((data) => {
                        this._referenceDisabledMessages = data;
                        this._saveReferenceDisabledItems =
                            this._referenceDisabledMessages;
                        this.referenceDisabledCollection =
                            this.apidataService.UpdateReferenceDeactivatedList(
                                this._referenceDisabledMessages
                            );
                    });

                // Filter this.auditResults on res.workflowType 3 and 11 (see Ticket #22404)
                // Possible Values for workflowType 3 (TaxAssessment): 2, 4, 8
                // Possible Values for workflowType 11 (Sdc): 2, 9, 10
                if (this._workflowType == 3) {
                    this.auditResults = this.auditResults.filter(
                        (item: DropDownListItem) =>
                            item.value == 2 ||
                            item.value == 4 ||
                            item.value == 8
                    );
                }
                if (this._workflowType == 11) {
                    this.auditResults = this.auditResults.filter(
                        (item: DropDownListItem) =>
                            item.value == 2 ||
                            item.value == 9 ||
                            item.value == 10
                    );
                }

                // console.log('AuditResults');
                // console.log(this.auditResults);
                // console.log('WorkflowType');
                // console.log(this._workflowType);

                // reset
                this.documentPayloadResultArray = [];

                if (
                    res.documentPayload != null &&
                    res.documentPayload != undefined
                ) {
                    this.documentPayloadResultArray =
                        res.documentPayload.filter((x) => x.isDeleted == false);
                    this.documentPayloadResultArray.forEach(
                        (obj: DatasetItem) => {
                            this.documentPayloadDatasetIdArray.push(obj.id);
                        }
                    );
                    this.addAllPayloads(this.documentPayloadResultArray);
                }

                this.loadFields();
            },
            error: (err: HttpErrorResponse) => {
                console.error(err.message);
            },
        });
    }

    //#region Validation Messages ===================================================================================================

    disableOverlay(): void {
        const overlaydoc = document.getElementById('overlay');
        if (overlaydoc != undefined) overlaydoc.style.display = 'none';
    }

    //#region Validation Messages ===================================================================================================
    public fieldErrorValidations = ValidationErrorListForControl;
    public fieldInfoValidations = ValidationInfoListForControl;

    public referenceDisabledCollection = ReferenceDisabledListForControl;

    //#endregion

    //#region Save =============================================================================================================

    subscribeOnSave(): void {
        this.onButtonSaveClick.subscribe(
            (itemsToSave: FormFieldSaveModel[]) => {
                this.saveAndValidate(itemsToSave);
            }
        );
    }

    /** Collect field items for saving */
    collectItemsToSave(): void {
        this._saveitems = [];

        console.log("Collecting items to save ... ");
        // console.log(this._formGroup);

        for (const item in this._formGroup.controls) {

            console.log(item);
            if (item == 'documentPayload') {
                //documentPayload FormArray
                const currentPayload = <FormArray>this.documentPayloadGetter();
                for (const payloadObjectIndex in currentPayload.controls) {
                    //FormGroup, corresponding to one payload each
                    const currentPayloadForm = <FormGroup>(
                        currentPayload.controls[payloadObjectIndex]
                    );
                    for (const payloadItem in currentPayloadForm.controls) {
                        /* if (payloadItem == 'datasetId' || payloadItem == 'crossReference' || payloadItem == 'dataTypeName')
              return; */
                        if (payloadItem == 'documentNestedPayload') {
                            const currentNestedPayload = <FormArray>(
                                currentPayloadForm.controls[
                                'documentNestedPayload'
                                ]
                            );
                            for (const payloadNestedObjectIndex in currentNestedPayload.controls) {
                                const currentNestedPayloadForm = <FormGroup>(
                                    currentNestedPayload.controls[
                                    payloadNestedObjectIndex
                                    ]
                                );
                                for (const payloadNestedItem in currentNestedPayloadForm.controls) {
                                    /*  if (payloadNestedItem == 'datasetId' || payloadNestedItem == 'crossReference' || payloadNestedItem == 'dataTypeName')
                     return; */
                                    let val: string | null;
                                    const field = this._fields.find(
                                        (i) => i.xbrlName == payloadNestedItem
                                    );
                                    if (field != null && field != undefined) {
                                        if (
                                            currentNestedPayloadForm.controls[
                                                payloadNestedItem
                                            ].value == null ||
                                            currentNestedPayloadForm.controls[
                                                payloadNestedItem
                                            ].value == undefined
                                        ) {
                                            val = null;
                                        } else {
                                            val =
                                                currentNestedPayloadForm.controls[
                                                    payloadNestedItem
                                                ].value?.toString();
                                            //console.log(val);
                                            if (field.type == 'DateTime') {
                                                val = this.getSaveDateValue(
                                                    currentNestedPayloadForm
                                                        .controls[
                                                        payloadNestedItem
                                                    ].value
                                                );
                                            }
                                            if (
                                                field.xbrlName ==
                                                'document_taxDetermination'
                                            ) {
                                                // type dropdown multiselect
                                                val = this.getSaveDropdownValue(
                                                    currentNestedPayloadForm
                                                        .controls[
                                                        payloadNestedItem
                                                    ].value
                                                );
                                            }
                                        }
                                        const id =
                                            currentNestedPayloadForm.get(
                                                'datasetId'
                                            )?.value;
                                        if (id != null && id != undefined) {
                                            const saveitem: FormFieldSaveModel = {
                                                xbrlName: payloadNestedItem,
                                                value: val,
                                                datasetId: id,
                                            };

                                            this._saveitems.push(saveitem);
                                        }
                                    } else {
                                        //nested control not found in getmyfieldsAsync result list
                                        //console.log('nested control not in fields from backend');
                                        if (
                                            currentNestedPayloadForm.controls[
                                                payloadNestedItem
                                            ].value == null ||
                                            currentNestedPayloadForm.controls[
                                                payloadNestedItem
                                            ].value == undefined
                                        ) {
                                            val = null;
                                        }
                                        if (
                                            currentNestedPayloadForm.controls[
                                                payloadNestedItem
                                            ].value instanceof Date
                                        ) {
                                            val = this.getSaveDateValue(
                                                currentNestedPayloadForm
                                                    .controls[payloadNestedItem]
                                                    .value
                                            );
                                        } else {
                                            val =
                                                currentNestedPayloadForm.controls[
                                                    payloadNestedItem
                                                ].value?.toString();
                                        }
                                        const id =
                                            currentNestedPayloadForm.get(
                                                'datasetId'
                                            )?.value;
                                        if (
                                            id != null &&
                                            id != undefined &&
                                            payloadNestedItem != 'datasetId' &&
                                            payloadNestedItem !=
                                            'crossReference' &&
                                            payloadNestedItem != 'dataTypeName'
                                        ) {
                                            const saveitem: FormFieldSaveModel = {
                                                xbrlName: payloadNestedItem,
                                                value: val,
                                                datasetId: id,
                                            };
                                            this._saveitems.push(saveitem);
                                        }
                                    }
                                }
                            }
                        } else {
                            let val: string | null;
                            const field = this._fields.find(
                                (i) => i.xbrlName == payloadItem
                            );
                            if (field != null && field != undefined) {
                                if (
                                    currentPayloadForm.controls[payloadItem]
                                        .value == null ||
                                    currentPayloadForm.controls[payloadItem]
                                        .value == undefined
                                ) {
                                    val = null;
                                } else {
                                    val =
                                        currentPayloadForm.controls[
                                            payloadItem
                                        ].value?.toString();
                                    //console.log(val);
                                    if (field.type == 'DateTime') {
                                        val = this.getSaveDateValue(
                                            currentPayloadForm.controls[
                                                payloadItem
                                            ].value
                                        );
                                    }
                                    if (
                                        field.xbrlName ==
                                        'document_taxDetermination'
                                    ) {
                                        // type dropdown multiselect
                                        val = this.getSaveDropdownValue(
                                            currentPayloadForm.controls[
                                                payloadItem
                                            ].value
                                        );
                                    }
                                }
                                const id =
                                    currentPayloadForm.get('datasetId')?.value;
                                if (id != null && id != undefined) {
                                    const saveitem: FormFieldSaveModel = {
                                        xbrlName: payloadItem,
                                        value: val,
                                        datasetId: id,
                                    };
                                    this._saveitems.push(saveitem);
                                }
                            } else {
                                //payload control not found in getmyfieldsAsync result list
                                //console.log('parent control not in fields from backend');
                                if (
                                    currentPayloadForm.controls[payloadItem]
                                        .value == null ||
                                    currentPayloadForm.controls[payloadItem]
                                        .value == undefined
                                ) {
                                    val = null;
                                }
                                if (
                                    currentPayloadForm.controls[payloadItem]
                                        .value instanceof Date
                                ) {
                                    val = this.getSaveDateValue(
                                        currentPayloadForm.controls[payloadItem]
                                            .value
                                    );
                                } else {
                                    val =
                                        currentPayloadForm.controls[
                                            payloadItem
                                        ].value?.toString();
                                }
                                const id =
                                    currentPayloadForm.get('datasetId')?.value;
                                if (
                                    id != null &&
                                    id != undefined &&
                                    payloadItem != 'datasetId' &&
                                    payloadItem != 'crossReference' &&
                                    payloadItem != 'dataTypeName'
                                ) {
                                    const saveitem: FormFieldSaveModel = {
                                        xbrlName: payloadItem,
                                        value: val,
                                        datasetId: id,
                                    };
                                    this._saveitems.push(saveitem);
                                }
                            }
                        }
                    }
                }
            } else {
                let val: string | null;

                const field = this._fields.find((i) => i.xbrlName == item);
                if (field != null && field != undefined) {
                    if (
                        this._formGroup.controls[item].value == null ||
                        this._formGroup.controls[item].value == undefined
                    ) {
                        val = null;
                    } else {
                        val = this._formGroup.controls[item].value?.toString();

                        if (field.type == 'DateTime') {
                            val = this.getSaveDateValue(
                                this._formGroup.controls[item].value
                            );
                        }

                        if (field.xbrlName == 'document_taxDetermination') {
                            // type dropdown multiselect
                            val = this.getSaveDropdownValue(
                                this._formGroup.controls[item].value
                            );
                        }
                    }
                    const id = field.datasetId;
                    if (id != null && id != undefined) {
                        const saveitem: FormFieldSaveModel = {
                            xbrlName: item,
                            value: val,
                            datasetId: id,
                        };

                        this._saveitems.push(saveitem);
                    } else {
                        console.error(
                            'DatasetId is null or undefined for field ' + item
                        );
                        console.error(field);
                    }
                } else {
                    if (this._formGroup.controls[item].value == undefined) {
                        val = null;
                    } else if (
                        this._formGroup.controls[item].value instanceof Date
                    ) {
                        val = this.getSaveDateValue(
                            this._formGroup.controls[item].value
                        );
                    } else {
                        val = this._formGroup.controls[item].value?.toString();
                    }
                    const id = this.headerDatasetId;
                    if (id != null && id != undefined && val != undefined) {
                        // null or undefined values for fields not in the fields list are not sent to backend

                        const saveitem: FormFieldSaveModel = {
                            xbrlName: item,
                            value: val,
                            datasetId: id,
                        };
                        this._saveitems.push(saveitem);
                    }
                }
            }
        }

        //console.log("Items collected.");
    }

    getSaveDateValue(s: Date | string): string | null {
        if (s == null || s == undefined || s == '') {
            return null;
        }
        const month = ((s as Date).getMonth() + 1).toString();
        const day = (s as Date).getDate().toString();
        const year = (s as Date).getFullYear().toString();
        const datestring =
            year.padStart(4, '0') +
            '-' +
            month.padStart(2, '0') +
            '-' +
            day.padStart(2, '0');
        return datestring;
    }

    getSaveDropdownValue(s: AbstractControl<string | number, string | number>[]): string {
        // Input ist ein array! any => AbstractControl<any, any>[]
        return this.apiUIViewerService.formValueToNumber(s);
    }

    saveAndValidate(itemsToSave: FormFieldSaveModel[]): void {
        //this.isFormLoading = true;
        // itemsToSave.forEach((item: FormFieldSaveModel) => {
        //     // this._formGroup.controls[item.xbrlName].setValue(item.value);
        //     // console.log(this._formGroup);
        // });
        // console.log(itemsToSave);
        this.saveAndValidateItems(itemsToSave, true);
    }

    saveAndValidateItems(
        itemsToSave: FormFieldSaveModel[],
        withNotification: boolean = true
    ): void {
        this.buttonsDisable.emit(true);

        // Get items from current form
        this.collectItemsToSave();
        // console.log("Items to save collected.");
        // console.log(this._saveitems);
        itemsToSave.forEach((item: FormFieldSaveModel) => {
            this._saveitems.push(item);
        });
        // Send items to backend
        const _saveRequest: SaveRequestItem = {
            dataForCreationOrUpdateSimple: this._saveitems,
            validationDeactivationForCreationOrUpdate:
                this._saveReferenceDisabledItems,
        };

        this.apidataService
            .updateData(this._workflowId, this._actualStateId, _saveRequest)
            .subscribe({
                next: (res: ValidationMessageItem[]) => {
                    this._referenceMessages = res.filter(
                        (item: ValidationMessageItem) =>
                            item.isReferenceDataMessage == true
                    );
                    this.referenceMessageList.emit(this._referenceMessages);

                    // Only get items with not isReferenceDataMessage
                    res = res.filter(
                        (item: ValidationMessageItem) =>
                            item.isReferenceDataMessage == false
                    );

                    // console.log('Saved form' + res);
                    //this.saveitems = [];  why empty the save items on UpdateData?

                    // Has res isError Items?
                    let messages = '';
                    let isError = false;
                    let isWarning = false;
                    res.forEach((item: ValidationMessageItem) => {
                        if (item.isError) {
                            isError = true;
                            messages += item.messageDE + '\n ';
                        }
                        if (item.isWarning) {
                            isWarning = true;
                            messages += item.messageDE + '\n ';
                        }
                    });

                    console.log('add Update api call : ' + isError);
                    if (isError) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Speicherung fehlgeschlagen: ' + messages,
                            })
                        );
                        this.buttonsDisable.emit(false);
                        console.log(
                            'validation error exists--saved successfully event emit FALSE'
                        );
                        this.savedSuccessfully.emit(false);
                    }
                    if (withNotification && isWarning) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isWarning: true,
                                message:
                                    'Speicherung mit Warnungen: ' + messages,
                            })
                        );
                    }
                    if (withNotification && !isWarning && !isError) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isSuccess: true,
                                message: 'Die Daten wurden gespeichert.',
                            })
                        );
                    }

                    // Validate
                    if (!isError) {
                        this.apidataService
                            .ValidateAsync(
                                this._workflowId,
                                this._actualStateId,
                                this._saveitems
                            )
                            .subscribe({
                                next: (res: ValidationMessageItem[]) => {
                                    // Remove items with flag isInternal
                                    res = res.filter(
                                        (item: ValidationMessageItem) =>
                                            item.isInternal == false
                                    );

                                    let hasErrorMessages = false;

                                    // Contains res isError Items?
                                    res.forEach(
                                        (item: ValidationMessageItem) => {
                                            if (item.isError) {
                                                hasErrorMessages = true;
                                            }
                                        }
                                    );

                                    this._referenceMessages = res.filter(
                                        (item: ValidationMessageItem) =>
                                            item.isReferenceDataMessage == true
                                    );
                                    this.referenceMessageList.emit(
                                        this._referenceMessages
                                    );

                                    // Only get items with not isReferenceDataMessage
                                    res = res.filter(
                                        (item: ValidationMessageItem) =>
                                            item.isReferenceDataMessage == false
                                    );

                                    this._validationMessages = res;
                                    this.fieldErrorValidations =
                                        this.apidataService.UpdateValidationList(
                                            this._validationMessages
                                        );
                                    this.fieldErrorValidations =
                                        this.apidataService.UpdateValidationList(
                                            this._validationMessages
                                        );
                                    this.fieldInfoValidations =
                                        this.apidataService.GetValidationInfoListForFields();

                                    this.validationMessageList.emit(
                                        this._validationMessages
                                    );

                                    if (withNotification) {
                                        if (
                                            this._validationMessages.length == 0
                                        ) {
                                            this.notificationMessage.emit(
                                                Object.assign(
                                                    new INotificationModel(),
                                                    {
                                                        isSuccess: true,
                                                        message:
                                                            'Das Regelwerk wurde überprüft. Es liegen keine Validierungsmeldungen vor.',
                                                    }
                                                )
                                            );
                                        } else if (
                                            this._validationMessages.length == 1
                                        ) {
                                            if (hasErrorMessages) {
                                                this.notificationMessage.emit(
                                                    Object.assign(
                                                        new INotificationModel(),
                                                        {
                                                            isError: true,
                                                            message:
                                                                'Das Regelwerk wurde überprüft. Es wurde eine kritische Validierungsmeldung erkannt.',
                                                        }
                                                    )
                                                );
                                            } else {
                                                this.notificationMessage.emit(
                                                    Object.assign(
                                                        new INotificationModel(),
                                                        {
                                                            isSuccess: true,
                                                            message:
                                                                'Das Regelwerk wurde überprüft. Es wurde eine unkritische Validierungsmeldung erkannt.',
                                                        }
                                                    )
                                                );
                                            }
                                        } else {
                                            if (hasErrorMessages) {
                                                const numberOfErrors =
                                                    this._validationMessages.filter(
                                                        (
                                                            item: ValidationMessageItem
                                                        ) =>
                                                            item.isError == true
                                                    ).length;
                                                this.notificationMessage.emit(
                                                    Object.assign(
                                                        new INotificationModel(),
                                                        {
                                                            isError: true,
                                                            message:
                                                                'Das Regelwerk wurde überprüft. Es wurden ' +
                                                                this
                                                                    ._validationMessages
                                                                    .length +
                                                                ' Validierungsmeldungen (davon ' +
                                                                numberOfErrors +
                                                                ' Kritische) erkannt.',
                                                        }
                                                    )
                                                );
                                            } else {
                                                this.notificationMessage.emit(
                                                    Object.assign(
                                                        new INotificationModel(),
                                                        {
                                                            isSuccess: true,
                                                            message:
                                                                'Das Regelwerk wurde überprüft. Es wurden ' +
                                                                this
                                                                    ._validationMessages
                                                                    .length +
                                                                ' unkritische Validierungsmeldungen erkannt.',
                                                        }
                                                    )
                                                );
                                            }
                                        }
                                    }

                                    console.log(
                                        'validate async api call : ' +
                                        hasErrorMessages
                                    );

                                    //Reload fields
                                    this.loadFields();
                                    this.buttonsDisable.emit(false);
                                    this.savedSuccessfully.emit(
                                        !hasErrorMessages
                                    );
                                },
                                error: (err: HttpErrorResponse) => {
                                    this.notificationMessage.emit(
                                        Object.assign(
                                            new INotificationModel(),
                                            {
                                                isError: true,
                                                message:
                                                    'Interner Fehler beim Validieren: ' +
                                                    err.message,
                                            }
                                        )
                                    );
                                    console.error(err.message);
                                    this.buttonsDisable.emit(false);
                                    this.savedSuccessfully.emit(false);
                                },
                            });
                        this.apidataService
                            .getReferenceDeactivatedValidations(
                                this._workflowId
                            )
                            .subscribe((data) => {
                                this._referenceDisabledMessages = data;
                                this._saveReferenceDisabledItems =
                                    this._referenceDisabledMessages;
                                this.referenceDisabledCollection =
                                    this.apidataService.UpdateReferenceDeactivatedList(
                                        this._referenceDisabledMessages
                                    );
                            });
                    }
                },
                error: (err: HttpErrorResponse) => {
                    if (err.status == 400) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Speichern nicht möglich, da Validierungsfehler (Fehler in der Datenintegrität) vorliegen.',
                            })
                        );
                    } else if (err.status == 405) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Speichern nicht möglich, da sich der Workflowstatus zwischenzeitlich geändert hat. Bitte das Dokument erneut öffnen.',
                            })
                        );
                    } else if (err.status == 422) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Interner Fehler beim Speichern der Zahlungen',
                            })
                        );
                    } else if (err.status == 410) {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Speichern nicht möglich, da der Workflow (Bescheid) nicht gefunden wurde.',
                            })
                        );
                    } else {
                        this.notificationMessage.emit(
                            Object.assign(new INotificationModel(), {
                                isError: true,
                                message:
                                    'Interner Fehler beim Speichern: ' +
                                    err.message,
                            })
                        );
                    }
                    this.buttonsDisable.emit(false);
                    this.savedSuccessfully.emit(false);
                },
            });
    }

    //#endregion

    //#region Loading ======================================================================================================

    /** Get field from backend and store into the forms */
    loadFields(): void {
        // console.log('loadFields and reset form');

        this._formGroup.reset();
        this._fieldsForRectangles = [];

        this.getMyFields(this._workflowId, this._actualStateId).subscribe({
            next: (res: FormFieldItem[]) => {
                this._fields = res;
                this.apidataService.myfields = res;
                let vdnTradeVal: undefined; //initial value undefined
                this._fields.forEach((field: FormFieldItem) => {
                    //set default values for boolean field
                    if (field.type == 'Boolean') {
                        field.valueOfBoolean =
                            field.value == null || field.value == undefined
                                ? false
                                : this.getBoolean(field.valueOfBoolean);
                    }
                    if (
                        field.xbrlName == 'document_tradeTax_vdn' &&
                        this._actualStateId == 9000
                    ) {
                        if (field.value != undefined) {
                            console.log(
                                'value for document_trade tax _vdn---' +
                                field.value
                            );
                            vdnTradeVal = field.value;
                        }
                    }
                    this.fillFormData(field);
                });
                //Task 24246: Archiv state in case the VDN dropdown has no value then map value form old checkbox
                // test archiv
                if (this._actualStateId == 9000) {
                    let currentForm: UntypedFormGroup | undefined = undefined;
                    currentForm = this._formGroup;

                    // Contains the field name for new VDN dropdown the currentForm?
                    if (
                        currentForm.contains(
                            'document_corporateIncomeTax_vdn'
                        ) &&
                        vdnTradeVal != undefined
                    ) {
                        const currentVal =
                            currentForm.controls[
                                'document_corporateIncomeTax_vdn'
                            ].value;
                        console.log('dropdown current val---' + currentVal);
                        if (currentVal == null || currentVal == undefined) {
                            const newVal =
                                this.getVdnValueFromCheckbox(vdnTradeVal);
                            currentForm.patchValue({
                                ['document_corporateIncomeTax_vdn']: newVal,
                            });
                            console.log(
                                'dropdown gets new value from old checkbox---' +
                                newVal
                            );
                        }
                    }
                }

                this.rectangleFieldList.emit(this._fieldsForRectangles);
                //console.log('load success event emit ');
                this.formLoadedSuccessfully.emit(true);
                this.apidataService.updateFormLoadedStatus(true);
                // this.apidataService.updatereferenceDisableButtonState();
                let selectedForms = document.querySelectorAll(
                    '.shrink-border-active'
                );
                selectedForms.forEach((form) => {
                    form.classList.replace(
                        'shrink-border-active',
                        'shrink-border'
                    );
                });
                selectedForms = document.querySelectorAll(
                    '.shrink-border-active-warning'
                );
                selectedForms.forEach((form) => {
                    form.classList.replace(
                        'shrink-border-active-warning',
                        'shrink-border'
                    );
                });
                selectedForms = document.querySelectorAll(
                    '.shrink-border-active-info'
                );
                selectedForms.forEach((form) => {
                    form.classList.replace(
                        'shrink-border-active-info',
                        'shrink-border'
                    );
                });
            },
            error: (err: HttpErrorResponse) => {
                this.notificationMessage.emit(
                    Object.assign(new INotificationModel(), {
                        isError: true,
                        message:
                            'Interner Fehler beim Laden der Felder: ' +
                            err.message,
                    })
                );
                console.error(err.message);
                this.formLoadedSuccessfully.emit(false);
            },
        });
    }

    fillFormData(field: FormFieldItem): void {
        let currentForm: UntypedFormGroup | undefined = undefined;

        // Console datasetid and _headerDatasetId)
        // console.log('datasetId: ' + field.datasetId);
        // console.log('_headerDatasetId: ' + this._headerDatasetId);

        if (field.datasetId == this._headerDatasetId) {
            currentForm = this._formGroup;
        } else if (
            this.documentPayloadDatasetIdArray.some((x) => x == field.datasetId)
        ) {
            //get correct object from the array by comparing datasetId
            // console.log('datasetId is in a payload within documentPayloadDatasetIdArray');
            if (this.documentPayloadGetter()?.controls != null) {
                for (
                    let i = 0;
                    i < this.documentPayloadGetter().controls.length;
                    i++
                ) {
                    const payloadToCheck = <FormGroup>(
                        (<FormArray>this._formGroup.get('documentPayload'))
                            ?.controls[i]
                    );
                    if (
                        payloadToCheck?.controls['datasetId'].value ==
                        field.datasetId
                    ) {
                        currentForm = payloadToCheck;
                        break;
                    } else if (
                        payloadToCheck?.controls['datasetId']?.value !=
                        field.datasetId &&
                        (payloadToCheck?.controls['dataTypeName']?.value ==
                            'DocumentPropertyTaxItem' ||
                            payloadToCheck?.controls['dataTypeName']?.value ==
                            'DocumentTradeTaxPrePayment' ||
                            payloadToCheck?.controls['dataTypeName']?.value ==
                            'DocumentTradeTaxInterest')
                    ) {
                        //If the id is in the Payload Id Array, but not in the "Parent" Form, then it is in the child form
                        const nestedPayloadsToCheck = <UntypedFormArray>(
                            payloadToCheck.controls['documentNestedPayload']
                        );
                        for (
                            let l = 0;
                            l < nestedPayloadsToCheck.controls.length;
                            l++
                        ) {
                            const newPayloadToCheck = <FormGroup>(
                                nestedPayloadsToCheck?.controls[l]
                            );

                            if (
                                newPayloadToCheck?.controls['datasetId']
                                    .value == field.datasetId
                            ) {
                                currentForm = newPayloadToCheck;
                                break;
                            }
                        }
                    }
                }
            } else {
                // console.error('documentPayloadGetter() is null. Dataset id:' + field.datasetId + 'and field name: ' + field.xbrlName + ' Header Id: ' + this._headerDatasetId);
                return;
            }
        }

        if (currentForm == undefined) {
            console.error(
                'FormGroup undefined. Dataset id:' +
                field.datasetId +
                'and field name: ' +
                field.xbrlName
            );
            return;
        }

        // And now... fill the form with data
        // Contains the field name the currentForm?
        if (currentForm.contains(field.xbrlName)) {
            this._fieldsForRectangles.push(field);
            // console.error(`Field "${field.xbrlName}" not found in form "${this.getCurrentForm().uniqueName}"`);
            // return;
        }

        if (field.type == 'Boolean') {
            currentForm.patchValue({
                [field.xbrlName]:
                    field.value == null || field.value == undefined
                        ? false
                        : this.getBoolean(field.valueOfBoolean),
            });
            if (field.xbrlName == 'document_withoutReferenceValidation') {
                if (field.value == true) {
                    this.withoutReferenceValidationExplanationRowHeight = 120;
                } else {
                    this.withoutReferenceValidationExplanationRowHeight = 0;
                }
            }
        } else if (field.type == 'Decimal') {
            currentForm.patchValue({
                [field.xbrlName]:
                    field.value == null || field.value == undefined
                        ? ''
                        : field.valueOfDecimal,
            });
        } else if (field.type == 'String') {
            // if (field.xbrlName == 'corporateIncomePrePayment_lastYearsWithReferenceData') {
            //   this.getReferenceYearsForPrepayment(field.valueOfString ?? "");
            // }
            // else {
            currentForm.patchValue({
                [field.xbrlName]:
                    field.value == null || field.value == undefined
                        ? ''
                        : this.getString(field.valueOfString),
            });
            // }
            //console.log(field.xbrlName);
        } else if (field.type == 'Int32') {
            if (field.xbrlName == 'document_taxDetermination') {
                //multiselect
                currentForm.patchValue({
                    [field.xbrlName]:
                        field.value == null || field.value == undefined
                            ? 0
                            : this.fillDropdownValueTaxDetermination(
                                field.valueOfInteger
                            ) ??
                            this.fillDropdownValueTaxDetermination(
                                field.value
                            ),
                });
            } else {
                currentForm.patchValue({
                    [field.xbrlName]:
                        field.value == null || field.value == undefined
                            ? ''
                            : field.valueOfInteger,
                });
            }
            //console.log(field.xbrlName);
        } else if (field.type == 'Guid') {
            currentForm.patchValue({
                [field.xbrlName]:
                    field.value == null || field.value == undefined
                        ? ''
                        : field.valueOfGuid,
            });
        } else if (field.type == 'DateTime') {
            //console.log('fill data in form type dateTime');
            currentForm.patchValue({
                [field.xbrlName]:
                    field.value == null || field.value == undefined
                        ? ''
                        : this.getDate(field.valueOfDateTime),
            });
        } else {
            currentForm.patchValue({
                [field.xbrlName]: field.value,
            });
        }
        //console.log(currentForm);
    }

    // Helper functions
    getDate(s: Date | null): Date | string {
        if (s == null) {
            return '';
        }
        return this.dateTimeService.convertUTCTimeToLocalTime(s)!;
    }

    getString(s: boolean | string | number | Date | null): string {
        if (s == null || s == undefined) {
            return '';
        }
        return s.toString();
    }

    getBoolean(s: boolean | string | number | Date | null): boolean {
        if (s == true || s == 1 || s == 'true' || s == '1') {
            return true;
        }
        return false;
    }
    fillDropdownValueTaxDetermination(s: number | null): DropDownListItem[] {
        if (s == null || s == undefined) {
            return [];
        }
        return this.apiUIViewerService.numberToFormValue(s, this.taxdetList);
    }

    getlegalentityReferenceNamefromGuid(guid: string): string {
        const legalentityName = this.legalEntities.find(
            (item) => item.value == guid
        );
        if (legalentityName) {
            return legalentityName?.labelDE;
        } else {
            return '';
        }
    }

    public requestedByAdmin(): boolean {
        return this.apidataService.requestedByAdmin();
    }

    public isUnlocked(): boolean {
        return this.apidataService.GetAdminArchivUnlockFlag();
    }

    // getReferenceYearsForPrepayment(years: string): any {
    //   if (years != null) {
    //     console.log(years);
    //     this.referenceYearsForPrepayment = years.split(',').filter(x => x.trim() !== "").map(Number).filter(x => !isNaN(x));
    //   }
    // }

    getVdnValueFromCheckbox(oldVal: boolean | null): number | null {
        if (oldVal === true) {
            return 2;
        } else if (oldVal === false) {
            return 8;
        } else {
            return null;
        }
    }

    //#endregion

    //#region Payload Handling ======================================================================================================

    /** returns Payload UntypedFormArray of the current document, given the field name "documentPayload" */
    public documentPayloadGetter(): UntypedFormArray {
        const result = <UntypedFormArray>this._formGroup.get('documentPayload');
        return result;
    }

    /** Test für KSt Separate and Uniform */
    public get documentPayloadGetterOnlyControls(): FormGroup[] {
        return <FormGroup[]>this.documentPayloadGetter().controls;
    }

    //TODO: GroupToCheck in konkrete Klasse
    /** returns Nested Payload UntypedFormArray of the current payloadGroup based on the crossReference, given the field name "documentNestedPayload" */
    public documentNestedPayloadGetter(
        payloadGroup: UntypedFormArray,
        crossReference?: string
    ): UntypedFormArray | null {
        if (crossReference != undefined || crossReference != null) {
            let currentForm: FormGroup | null = null;
            for (let i = 0; i < payloadGroup.controls.length; i++) {
                const groupToCheck = <FormGroup>payloadGroup.controls[i];
                const groupToCheckDataType =
                    groupToCheck?.controls['dataTypeName']?.value;

                if (
                    groupToCheckDataType !=
                    'DocumentTradeTaxPaymentFromInterest' &&
                    groupToCheckDataType != 'DocumentTradeTaxInterestRun' &&
                    groupToCheckDataType !=
                    'DocumentTradeTaxPaymentFromPrePayment' &&
                    groupToCheckDataType != 'DocumentPropertyTaxItemPayment' &&
                    groupToCheck?.controls['crossReference']?.value ==
                    crossReference
                ) {
                    currentForm = <FormGroup>groupToCheck;

                    break;
                }
            }

            if (currentForm) {
                const result = <UntypedFormArray>(
                    currentForm.get('documentNestedPayload')
                );
                return result;
            } else {
                console.error(
                    'documentNestedPayloadGetter: No FormGroup found for crossReference: ' +
                    crossReference
                );
                return null;
            }
        } else {
            return payloadGroup;
        }
    }

    /** Test für eingebette FormGroup-Array */
    public documentNestedPayloadGetterOnlyControls(
        payloadGroup: UntypedFormArray,
        crossReference?: string
    ): FormGroup[] {
        let results = this.documentNestedPayloadGetter(
            payloadGroup,
            crossReference
        );
        // Task 21586: Sort Quartalszahlungen functionality for sorting the nested payload of type DocumentTradeTaxPaymentFromPrePayment
        if (results != null) {
            // Call the sorting function and conditionally assign the result
            const sortedArray = this.sortByPrePaymentDate(results);
            if (Array.isArray(sortedArray) && sortedArray.length > 0) {
                results = <UntypedFormArray>sortedArray;
            }
        }

        if (results != null) {
            return <FormGroup[]>results.controls;
        } else {
            return [];
        }
    }

    /**
     *
     * @param res the Form Array that consists of FormGroups.
     * If FormGroup is of type DocumentTradeTaxPaymentFromPrePayment, sorting is done for the controls within that FormGroup based on control
     * @returns
     */
    public sortByPrePaymentDate(res: UntypedFormArray): number | null | UntypedFormArray {
        // Assuming 'A' is your FormArray
        const formArray: FormArray = res; // replace with your FormArray instance

        // Extract the array of FormGroup controls
        const formGroups = formArray.controls as FormGroup[];

        // Separate the FormGroup controls to be sorted and those to remain in original order
        const groupsToSort: FormGroup[] = [];
        const remainingGroups: FormGroup[] = [];

        formGroups.forEach((formGroup) => {
            if (
                formGroup.controls['dataTypeName']?.value ===
                'DocumentTradeTaxPaymentFromPrePayment'
            ) {
                groupsToSort.push(formGroup);
            } else {
                remainingGroups.push(formGroup);
            }
        });

        //no sorting if only the FormGroup contains only one item
        if (groupsToSort.length <= 1) return null;

        // Sort the filtered FormGroup controls by document_tradeTax_amountToPaidDate
        const sortedGroups = groupsToSort.sort((a, b) => {
            // Check if both dates are invalid
            if (
                a.controls['document_tradeTax_amountToPaidDate']?.valid ==
                false ||
                b.controls['document_tradeTax_amountToPaidDate']?.valid == false
            )
                return 0; //order remains unchanged if date is invalid.

            const dateA = new Date(
                a.controls['document_tradeTax_amountToPaidDate']?.value
            );
            const dateB = new Date(
                b.controls['document_tradeTax_amountToPaidDate']?.value
            );

            return dateA.getTime() - dateB.getTime();
        });
        /*  const sortedGroups = groupsToSort.sort((a, b) => {
            const dateAValue =
                a.controls['document_tradeTax_amountToPaidDate']?.value;
            const dateBValue =
                b.controls['document_tradeTax_amountToPaidDate']?.value;
            const dateAValid =
                a.controls['document_tradeTax_amountToPaidDate']?.valid;
            const dateBValid =
                b.controls['document_tradeTax_amountToPaidDate']?.valid;

            const dateA = dateAValue ? new Date(dateAValue) : null;
            const dateB = dateBValue ? new Date(dateBValue) : null;

            // Check if both dates are invalid (or empty)
            if ((!dateAValid || !dateAValue) && (!dateBValid || !dateBValue)) {
                return 0; // Order remains unchanged if both dates are invalid or empty.
            }

            // Check if dateA is invalid (or empty) and dateB is valid
            if (!dateAValid || !dateAValue) {
                return 1; // Push dateA to the end
            }

            // Check if dateB is invalid (or empty) and dateA is valid
            if (!dateBValid || !dateBValue) {
                return -1; // Push dateB to the end
            }
            // Both dates are valid, so compare them
            if (dateA && dateB) return dateA.getTime() - dateB.getTime();
            //all possible cases are covered above.
            else return 0;
        }); */

        // Clear the FormArray
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }

        // Reassign the sorted and remaining FormGroup controls back to the FormArray
        [...remainingGroups, ...sortedGroups].forEach((formGroup) =>
            formArray.push(formGroup)
        );
        return formArray as UntypedFormArray;
    }

    /** get no. of nested payloads based on type (InterestRun and paymentfromInterest ) to calculate row height in html*/
    public documentNestedPayloadLengthGetter(
        payloadGroup: UntypedFormArray | null,
        Datatype?: string
    ): number {
        if (payloadGroup == null || payloadGroup == undefined) {
            console.error('payloadGroup is null or undefined');
            return 0;
        }

        let length: number = 0;
        for (let i = 0; i < payloadGroup.controls.length; i++) {
            const groupToCheck = <FormGroup>payloadGroup.controls[i];
            const groupToCheckDataType =
                groupToCheck?.controls['dataTypeName']?.value;
            if (groupToCheckDataType == Datatype) length++;
        }
        return length;
    }

    // == Add (Payload) =============================================================================================================================
    /** Add one Payload item */
    abstract addSingularPayload(payload?: DatasetItem): void;

    addAllPayloads(payloadResult: DatasetItem[]): void {
        this.documentPayloadArrayWithDatasetId = new UntypedFormArray([]);

        //adding the "Parent" payloads first
        payloadResult.forEach((x: DatasetItem) => {
            // console.log(x.dataTypeName);
            if (
                x.dataTypeName == 'DocumentPropertyTaxItem' ||
                x.dataTypeName == 'DocumentTradeTaxItem' ||
                x.dataTypeName == 'DocumentTradeTaxPrePayment' ||
                x.dataTypeName == 'DocumentTradeTaxInterest' ||
                x.dataTypeName == 'DocumentTradeTaxPaymentFromOther' ||
                x.dataTypeName ==
                'DocumentTradeTaxLossCarryForwardPartnership' ||
                x.dataTypeName ==
                'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany' ||
                x.dataTypeName ==
                'DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome' ||
                x.dataTypeName ==
                'DocumentChamberOfCommerceAndIndustryDueDatePayment' ||
                x.dataTypeName ==
                'DocumentChamberOfCommerceAndIndustryCalculationOfContribution' ||
                x.dataTypeName == 'DocumentPropertyDueDatePayment'
            ) {
                this.addSingularPayload(x);
            }
        });

        //adding the "Children" payloads second
        payloadResult.forEach((x: DatasetItem) => {
            // console.log(x.dataTypeName);
            if (
                x.dataTypeName != 'DocumentPropertyTaxItem' &&
                x.dataTypeName != 'DocumentTradeTaxItem' &&
                x.dataTypeName != 'DocumentTradeTaxPrePayment' &&
                x.dataTypeName != 'DocumentTradeTaxInterest' &&
                x.dataTypeName != 'DocumentTradeTaxPaymentFromOther' &&
                x.dataTypeName !=
                'DocumentTradeTaxLossCarryForwardPartnership' &&
                x.dataTypeName !=
                'DocumentCorporateIncomeTaxSeparateAndUniformDeterminationCompany' &&
                x.dataTypeName !=
                'DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome' &&
                x.dataTypeName !=
                'DocumentChamberOfCommerceAndIndustryDueDatePayment' &&
                x.dataTypeName !=
                'DocumentChamberOfCommerceAndIndustryCalculationOfContribution' &&
                x.dataTypeName != 'DocumentPropertyDueDatePayment'
            ) {
                this.addSingularPayload(x);
            }
        });

        //console.log(this.documentPayloadGetter());
        //console.log(this.getCurrentForm().formGroup);

        // Get formGroup "Documentpayload" from _formGroup
        const currentPayload = <UntypedFormArray>this.documentPayloadGetter();

        if (currentPayload) {
            //console.log(this._formGroup);
            // currentPayload.patchValue({
            //   'documentPayload': this.documentPayloadArrayWithDatasetId
            // });
        }

        //console.log('after adding all payloads and patyhing to form');
        //console.log(this.documentPayloadGetter());
    }

    /**
     * Add Payload Dataset, dataType is the type of payload
     */
    public addPayloadAsync(
        dataType: string,
        workflowId: number,
        crossRef?: string
    ): Observable<FormFieldItem[]> {
        return this.apiDatasetService.addPayloadAsync(
            dataType,
            workflowId,
            crossRef
        );
    }

    // == Delete (Payload) =========================================================================================================

    //currentPayload == payload from wich i have to delete the set, can be documentPayload or documentNestedPayload
    //id == index of the object to delete in the array
    //(array is documentPayload if parent is being deleted, array is documentNestedPayload if child is being deleted)

    deletePayload(
        currentPayload: UntypedFormArray | null,
        id: number,
        dataType: string
    ): void {
        if (currentPayload == null || currentPayload == undefined) {
            console.error('currentPayload is null or undefined');
            return;
        }
        //console.log('delete payload');
        const datasetToDelete: number = currentPayload
            .get(id.toString())
            ?.get('datasetId')?.value;
        console.log('Try to delete datasetID: ' + datasetToDelete);
        if (datasetToDelete) {
            if (
                dataType == 'DocumentPropertyTaxItem' ||
                dataType == 'DocumentTradeTaxPrePayment' ||
                dataType == 'DocumentTradeTaxInterest'
            ) {
                const currentNestedPayloadForm = <UntypedFormArray>(
                    currentPayload
                        .get(id.toString())
                        ?.get('documentNestedPayload')
                );
                for (const nested in currentNestedPayloadForm.controls) {
                    // console.log("NESTED DATATYPE");
                    // console.log(currentNestedPayloadForm.get(nested.toString())?.get('dataTypeName')?.value);
                    // console.log(currentNestedPayloadForm);
                    this.deletePayload(
                        currentNestedPayloadForm,
                        parseInt(nested),
                        currentNestedPayloadForm
                            .get(nested.toString())
                            ?.get('dataTypeName')?.value
                    );
                    //TODO ACHTUNG: REKURSION (siehe API Call!)
                }
            }
            this.deletePayloadAsync(datasetToDelete).subscribe({
                next: () => {
                    this.notificationMessage.emit(
                        Object.assign(new INotificationModel(), {
                            isSuccess: true,
                            message: 'Eintrag entfernt.',
                        })
                    );
                    currentPayload.removeAt(id);
                    this.documentPayloadDatasetIdArray.splice(
                        this.documentPayloadDatasetIdArray.indexOf(
                            datasetToDelete
                        ),
                        1
                    );
                    // this.removePayloadHeight(dataType);
                },
                error: (err: HttpErrorResponse) => {
                    this.notificationMessage.emit(
                        Object.assign(new INotificationModel(), {
                            isError: true,
                            message: 'Fehler beim Löschen: ' + err.message,
                        })
                    );
                    console.error(err.message);
                    return;
                },
            });
        }
    }

    // abstract removePayloadHeight(dataType: string): void;

    // == API Calls (Payload) ======================================================================================================

    /**
     * mark payload as isDeleted
     * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
     */
    public deletePayloadAsync(datasetId: number): Observable<FormFieldItem[]> {
        return this.apiDatasetService.deletePayloadAsync(datasetId);
    }

    /**
     * Get a dataset
     * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
     */
    public getDataSetByIdAsync(datasetId: number): Observable<DatasetItem> {
        return this.apiDatasetService.getDataSetByIdAsync(datasetId);
    }

    //#endregion

    //#region UI Helper ======================================================================================================

    handleLegalEntitiesFilter(value: string): void {
        this.legalEntities = this.legalEntitiesSource.filter(
            (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    handleTypeOfParticipationFilter(value: string): void {
        this.typeOfParticipation = this.typeOfParticipationSource.filter(
            (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    handleChamperOfCommerceFilter(value: string): void {
        this.chamberOfCommerceAndIndustryList =
            this.chamberOfCommerceAndIndustrySource.filter(
                (s) =>
                    s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
    }

    handleAGSFilter(value: string): void {
        this.communeAGS = this.communeAGSSource.filter(
            (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    assignClassByDatasetIDandXbrlName(datasetId: number, xbrl: string): string {
        const msg = this.fieldErrorValidations
            .values()
            .find(
                (item) => item.datasetId == datasetId && item.xbrlName == xbrl
            );

        if (msg != undefined) return 'red-border-class';
        else return ' ';
    }

    changeExplanationRowHeight(): void {
        if (
            this._formGroup.controls['document_withoutReferenceValidation']
                .value
        )
            this.withoutReferenceValidationExplanationRowHeight = 120;
        else this.withoutReferenceValidationExplanationRowHeight = 0;
    }

    changeSDCFieldsRowHeight(): void {
        if (this.workflowType == 11) this.sdcFieldsRowHeight = 130;
        else this.sdcFieldsRowHeight = 0;
    }

    //#endregion

    /** Return the german label of a xbrl name */
    public getXbrlLabel(xbrlName: string): string {
        const xbrlType = this.listsService.xbrlTypes.find(
            (x) => x.name == xbrlName
        );
        if (xbrlType) {
            return xbrlType.labelDE ?? 'Empty labelDE: ' + xbrlType.name;
        }
        return 'Not found: ' + xbrlName;
    }

    //#region Workflow and Fields

    /**
     * Delivers a list of data values (with regions) to be displayed in the UI.
     */
    private getMyFields(
        workflowId: number,
        acutalStateId: number
    ): Observable<FormFieldItem[]> {
        return this.apiUIViewerService.getMyFields(workflowId, acutalStateId);
    }

    //#endregion

    public onFocus(): void {
        console.error('old Function!. Use onFocusInputXbrl() instead.');
        this.onFocusInputXbrl();
    }

    /** Triggered when the form fields are clicked on and should be focused on
     */
    public onFieldFocusReceiver(): void {
        this.onFocusInputXbrl();
    }

    /** Triggered when the form fields are clicked on and should be focused on
     */
    public onReferenceDisableReceiver(
        fieldForDisable: ValidationDeactivationTemporaryItem
    ): void {
        //activation is true -> button activated -> reference check deactivated
        if (fieldForDisable.activation) {
            this._saveReferenceDisabledItems.push({
                workflowId: this._workflowId,
                datasetId: fieldForDisable.datasetId,
                xbrl: fieldForDisable.xbrl,
            });
        } else {
            // console.log(this._saveReferenceDisabledItems);
            this._saveReferenceDisabledItems =
                this._saveReferenceDisabledItems.filter(
                    (item) =>
                        item.datasetId != fieldForDisable.datasetId ||
                        item.xbrl != fieldForDisable.xbrl
                );
        }
        // console.log(this._saveReferenceDisabledItems);
    }

    /** User click on input field --> highlight PDF red rectangle */
    public onFocusInputXbrl(): void {
        if (document != null) {
            let fieldIdwithDataset = document.activeElement?.parentElement?.id;

            // console.log('Element is focused: ' + fieldIdwithDataset);

            // idStr contains _ and - ? (also a XBRL from STARE)
            if (
                fieldIdwithDataset != null &&
                fieldIdwithDataset != undefined &&
                fieldIdwithDataset.includes('_') &&
                fieldIdwithDataset.includes('-')
            ) {
                // console.log('parent ');
                // console.log(document.activeElement!.parentElement!);
                // document.activeElement!.parentElement!.parentElement!.style.border = "";
                this.apidataService.currentFormfieldId = fieldIdwithDataset;
            } else {
                fieldIdwithDataset = '';
                // console.log("Element is not XBRL");

                fieldIdwithDataset = document.activeElement?.parentElement?.id;
                //   console.log("parent element is focused: " + fieldIdwithDataset);    // e.g. id_companyIdentifier-2341

                if (
                    fieldIdwithDataset == null ||
                    fieldIdwithDataset == undefined ||
                    !(
                        fieldIdwithDataset.includes('_') &&
                        fieldIdwithDataset.includes('-')
                    )
                ) {
                    fieldIdwithDataset =
                        document.activeElement?.parentElement?.parentElement
                            ?.id; // Because Kendo DatePicker

                    // document.activeElement!.parentElement!.parentElement!.parentElement!.style.border = "";
                    //  console.log("parent+parent element is focused: " + fieldIdwithDataset);    // e.g. id_companyIdentifier-2341
                }

                if (
                    fieldIdwithDataset == null ||
                    fieldIdwithDataset == undefined ||
                    fieldIdwithDataset == ''
                ) {
                    const tagNameMultiselect =
                        document.activeElement?.parentElement?.tagName;
                    if (tagNameMultiselect?.includes('SEARCHBAR'))
                        fieldIdwithDataset =
                            document.activeElement?.parentElement?.parentElement
                                ?.parentElement?.id; // Because Kendo multiselect
                }
                //  console.log(document.activeElement?.parentElement?.parentElement);  // id = Datepicker-1
            }
            this.apidataService.currentFormfieldId =
                fieldIdwithDataset == undefined ? '' : fieldIdwithDataset;
            // Normalize current rectangle id
            if (this._focusedFieldId.length > 0) {
                const currentRectangle = document.getElementById(
                    'pdf-' + this._focusedFieldId
                );
                if (currentRectangle) {
                    currentRectangle.style.border = '2px solid red';
                    currentRectangle.style.background = '';
                    currentRectangle.style.opacity = '1';
                }
            }

            if (fieldIdwithDataset) {
                // Highlight new rectangle
                const newRectangle = document.getElementById(
                    'pdf-' + fieldIdwithDataset
                ); // See method pdfRender

                if (newRectangle) {
                    //  console.log('green border');
                    newRectangle.style.border = '4px solid green';
                    newRectangle.style.background = '#fffdd3';
                    newRectangle.style.opacity = '0.5';

                    this._focusedFieldId = fieldIdwithDataset;

                    // console.log('setting form and pdf field');
                    // this.apidataService.currentPDFfieldId = 'pdf-' + fieldIdwithDataset;
                }
            }
            // console.log('parent ');
            // console.log(document.activeElement!.parentElement!);
            // document.activeElement!.parentElement!.parentElement!.style.border = "";
        } else {
            console.warn('Document is null on field focus.');
        }
    }

    ngOnDestroy(): void {
        this.updateSubscription.unsubscribe();
    }
}
