<form [formGroup]="rateableValueForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Einheitswertnummer">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_communeAGS"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="communeAGS">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities"
                        overrideLabel="Zurechnung lt. Bescheid">
                    </app-stare-dropdown-string>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_legalEntityKeyReference" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm1300" [isReadOnly]="true" [workflowType]="workflowType"
                        [dataOptionsSource]="legalEntities" overrideLabel="Zurechnung lt. Referenz">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_cutOffDate"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="realEstate_effectiveDate"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="true"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>

                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutEffectiveDateRule" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-multiselect (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_taxDetermination"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="taxdetList">
                    </app-stare-multiselect>
                </div>
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_isLeaseHold"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_rateableValue" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="realEstate_rateableValue"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="true"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_hasThresholdLimit"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="rateableValueForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="rateableValueForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="rateableValueForm1300" [auditResults]="auditResults"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 
<div class="grid-layout-container">
    <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 0 },
        { height: 0},
        { height:0},
        { height: 20 },
        { height: 112 },
        { height: 130 },
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 20 },
        { height: 112 },
        { height: 90 },						
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight},
        { height: sdcFieldsRowHeight },
        { height: sdcFieldsRowHeight }]" [cols]="[{ width: 250 }, { width: 250 }, { width:250 }]">

        <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">

            <legend class="k-form-legend section-header">
                Kommentare</legend>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_comment-'+headerDatasetId"
                    id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId" type="string"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                    placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                    autocomplete="off" [rows]="3" resizable="vertical">
                </kendo-textarea>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                    id="document_legalRemedyClientDecisionLabel">{{getXbrlLabel('document_legalRemedyClientDecision')}}</kendo-label>
                <kendo-textarea (focus)="onFocusInputXbrl()"
                    [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                    placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                    formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                    resizable="vertical">
                </kendo-textarea>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="3">
            <legend class="k-form-legend section-header">
                Bescheididentität
            </legend>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always">
                <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                    id="companyIdentifierLabel">Einheitswertnummer</kendo-label>
                <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId" type="text"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                    placeholder="Einheitswertnummer" formControlName="id_companyIdentifier" autocomplete="off">
                </kendo-textbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="1">
            <kendo-formfield showErrors="always" showHints="always" class="part">
                <kendo-label [for]="'id_communeAGS-'+headerDatasetId"
                    id="agsLabel">{{getXbrlLabel('id_communeAGS')}}</kendo-label>
                <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                    [id]="'id_communeAGS-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                    [defaultItem]="defaultItem" formControlName="id_communeAGS" (filterChange)="handleAGSFilter($event)"
                    [virtual]="{itemHeight: 31}"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_communeAGS')"
                    [data]="communeAGS"></kendo-dropdownlist>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'id_communeAGS'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="8" [col]="3" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                    id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                    [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                    [value]="formGroup.controls['id_notificationDate'].value"
                    placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                    autocomplete="off">
                    <kendo-datepicker-messages today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
                </kendo-datepicker>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">
            <kendo-formfield showErrors="always" showHints="always" class="part">
                <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId" id="taxDeterminationLabel">Zurechnung
                    lt.
                    Bescheid</kendo-label>
                <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                    [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                    [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                    (filterChange)="handleLegalEntitiesFilter($event)"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                    [data]="legalEntities"
                    [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                <app-legalentity-add-button></app-legalentity-add-button>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="9" [col]="3" [colSpan]="1">
            <kendo-formfield showErrors="always" showHints="always" class="part">
                <kendo-label [for]="'id_legalEntityKeyReference-'+headerDatasetId" id="refKeyLabel">Zurechnung
                    lt. Referenz</kendo-label>
                <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                    [id]="'id_legalEntityKeyReference-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                    [defaultItem]="defaultItem" [tabindex]="-1" formControlName="id_legalEntityKeyReference"
                    [readonly]="true" class="custom-fill-mode" (filterChange)="handleLegalEntitiesFilter($event)"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKeyReference')"
                    [data]="legalEntities"></kendo-dropdownlist>

                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'id_legalEntityKeyReference'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_cutOffDate-'+headerDatasetId"
                    id="cutoffDateLabel">{{getXbrlLabel('document_cutOffDate')}}</kendo-label>
                <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                    [id]="'document_cutOffDate-'+headerDatasetId" placeholder="Bitte auswählen..."
                    formControlName="document_cutOffDate" autocomplete="off" [enableMouseWheel]="false"
                    [value]="formGroup.controls['document_cutOffDate'].value"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_cutOffDate')">
                    <kendo-datepicker-messages today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
                </kendo-datepicker>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_cutOffDate'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1">

            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'realEstate_effectiveDate-'+headerDatasetId"
                    id="effectiveDateLabel">{{getXbrlLabel('realEstate_effectiveDate')}}</kendo-label>
                <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy" [readonly]="true"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'realEstate_effectiveDate')"
                    [id]="'realEstate_effectiveDate-'+headerDatasetId" class="custom-fill-mode"
                    [value]="formGroup.controls['realEstate_effectiveDate'].value" [tabindex]="-1"
                    placeholder="{{getXbrlLabel('realEstate_effectiveDate')}}"
                    formControlName="realEstate_effectiveDate" autocomplete="off"></kendo-datepicker>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'realEstate_effectiveDate'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="10" [col]="3" [colSpan]="1">
            <kendo-formfield class="part">

                <kendo-label [for]="'document_withoutEffectiveDateRule-'+headerDatasetId" id="effectiveDateRuleLabel">
                    {{getXbrlLabel('document_withoutEffectiveDateRule')}}
                </kendo-label>
                <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                    style="margin-left: 5px ;margin-top: 8px ;" formControlName="document_withoutEffectiveDateRule"
                    [id]="'document_withoutEffectiveDateRule-'+headerDatasetId" />
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_withoutEffectiveDateRule'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="2">
            <kendo-formfield showErrors="always" showHints="always" class="part">
                <kendo-label [for]="'document_taxDetermination-'+headerDatasetId"
                    id="taxDeterminationLabel">{{getXbrlLabel('document_taxDetermination')}}</kendo-label>
                <kendo-multiselect (focus)="onFocusInputXbrl()" formControlName="document_taxDetermination"
                    [data]="taxdetList" [textField]="'labelDE'" [valueField]="'value'"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_taxDetermination')"
                    placeholder="Bitte auswählen..."
                    [id]="'document_taxDetermination-'+headerDatasetId"></kendo-multiselect>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_taxDetermination'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="11" [col]="3" [colSpan]="1">
            <kendo-formfield class="part">

                <kendo-label [for]="'document_isLeaseHold-'+headerDatasetId" id="effectiveDateRuleLabel">
                    {{getXbrlLabel('document_isLeaseHold')}}
                </kendo-label>
                <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                    style="margin-left: 5px ;margin-top: 8px ;" formControlName="document_isLeaseHold"
                    [id]="'document_isLeaseHold-'+headerDatasetId" />
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_isLeaseHold'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="3">

            <legend class="k-form-legend section-header">
                Bewegungsdaten</legend>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_rateableValue-'+headerDatasetId"
                    id="rateableValueLabel">{{getXbrlLabel('document_realEstate_rateableValue')}}</kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [changeValueOnScroll]="false" [id]="'document_realEstate_rateableValue-'+headerDatasetId"
                    placeholder="{{getXbrlLabel('document_realEstate_rateableValue')}}" [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_rateableValue')"
                    formControlName="document_realEstate_rateableValue" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_rateableValue'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="1">

            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'realEstate_rateableValue-'+headerDatasetId"
                    id="rateableValueLabel">{{getXbrlLabel('realEstate_rateableValue')}}</kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false" [readonly]="true"
                    class="custom-fill-mode" [id]="'realEstate_rateableValue-'+headerDatasetId"
                    [changeValueOnScroll]="false" placeholder="{{getXbrlLabel('realEstate_rateableValue')}}"
                    formControlName="realEstate_rateableValue" [tabindex]="-1"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'realEstate_rateableValue')"
                    autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'realEstate_rateableValue'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="initial" showErrors="initial" class="part">

                <kendo-label [for]="'document_hasThresholdLimit-'+headerDatasetId" id="effectiveDateRuleLabel">
                    {{getXbrlLabel('document_hasThresholdLimit')}}
                </kendo-label>
                <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                    style="margin-left: 5px ;margin-top: 8px ;" formControlName="document_hasThresholdLimit"
                    [id]="'document_hasThresholdLimit-'+headerDatasetId" />
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_hasThresholdLimit'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="3">
            <legend class="k-form-legend section-header">
                Prüfungsergebnis</legend>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="2">
            <kendo-formfield showHints="initial" showErrors="initial" class="part">

                <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                    {{getXbrlLabel('document_withoutReferenceValidation')}}
                </kendo-label>
                <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                    style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                    formControlName="document_withoutReferenceValidation"
                    [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="3"
            *ngIf="rateableValueForm1300.controls['document_withoutReferenceValidation'].value==true">

            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                    id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                <kendo-textarea (focus)="onFocusInputXbrl()"
                    [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                    placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                    formControlName="document_withoutReferenceValidationExplanation" autocomplete="off" [rows]="2"
                    resizable="vertical">
                </kendo-textarea>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                    id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                    (focus)="onFocusInputXbrl()"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                    placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                    formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                </kendo-textarea>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                    id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                    (focus)="onFocusInputXbrl()"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                    placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                    formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                </kendo-textarea>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
    </kendo-gridlayout>
</div>

</fieldset>
<br>
</form> -->