
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { SortDescriptor } from "@progress/kendo-data-query";
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow, RemoveEvent } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, plusIcon } from '@progress/kendo-svg-icons';

import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { DropDownBooleanItem, DropDownListItem } from 'app/_models/dropdown-item.model';
import { ListsService } from 'app/_services/lists.service';
import { MailMetadataForCreationItem, MailMetadataForUpdateItem, MailMetadataItem } from 'app/_models/mail-metadata.model';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { ApiLegalentityService } from 'app/_services/StareApi/legalentity.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-setting-mailmetadata',
  templateUrl: './setting-mailmetadata.component.html',
  styleUrls: ['./setting-mailmetadata.component.scss'],
  standalone: false
})
export class SettingMailmetadataComponent implements OnInit {

  public languageList: DropDownBooleanItem[] = [
    { labelDE: 'Englisch', value: false, labelEN: 'English', isDefault: false, isDeleted: false },
    { labelDE: 'Deutsch', value: true, labelEN: 'German', isDefault: true, isDeleted: false }

  ];
  public modeList: DropDownBooleanItem[] = [
    { labelDE: 'Formell', value: true, labelEN: 'Formal', isDefault: false, isDeleted: false },
    { labelDE: 'Informell', value: false, labelEN: 'Informal', isDefault: true, isDeleted: false }

  ];

  public sepaList: DropDownBooleanItem[] = [
    { labelDE: 'SEPA liegt vor', value: true, labelEN: 'SEPA is available', isDefault: false, isDeleted: false },
    { labelDE: 'Kein SEPA Mandat', value: false, labelEN: 'SEPA is not available', isDefault: true, isDeleted: false }

  ];

  /** Displayed (filtered) data */
  public tableData = [] as MailMetadataItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as MailMetadataItem[];
  /** API request is finished */
  public finishedLoading = false;


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // ==========================================================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private notificationService: WrapperNotificationService,
    private listsService: ListsService,
    private apiLegalEntityService: ApiLegalentityService,
    public tooltipService: TooltipService
  ) { }



  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.MAILING_METADATA.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.listsService.GetAuditResultsList().subscribe(
      (data) => {
        this.auditResults = data as DropDownListItem[];
      }
    );
    this.listsService.GetLegalEntitiesList().subscribe(
      (data) => {



        // Order data by labelDE. Ignore case and german umlauts
        data.sort((a, b) => a.labelDE.localeCompare(b.labelDE, 'de', { sensitivity: 'base' }));

        this.legalEntitiesSource = data as DropDownStringItem[];

        // Add on "labelDE" the string " (gelöscht)" if isDeleted == true
        this.legalEntitiesSource.forEach((item: DropDownStringItem) => {
          if (item.isDeleted) {
            item.labelDE = item.labelDE + ' (gelöscht)';
          }
        });

        this.legalEntities = this.legalEntitiesSource.slice();
      }
    );
    this.loadData();

  }

  // == Resizing of the Grid =====================================================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load Grid Data =======================================================================
  loadData(): void {
    this.finishedLoading = false;

    this.apiLegalEntityService.getAllMailTemplates().subscribe({
      next: (res: MailMetadataItem[]) => {

        res.forEach((item: MailMetadataItem) => {
          if (item.legalEntityKey) {
            item.legalEntityKeyForDisplay = this.getlegalentityNamefromKey(item.legalEntityKey);
          }
          item.isDEForDisplay = item.isDE ? "Deutsch" : "Englisch";
          item.isFormalForDisplay = item.isFormal ? "Formal" : "Informal";

        });
        this.tableData = res;
        console.log(this.tableData);
        this.tableDataOriginal = this.tableData;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });
    this.finishedLoading = true;

  }
  getlegalentityNamefromKey(key: string): string {
    const legalentityName = this.legalEntities.find((item) => (item.value == key));
    if (legalentityName) {
      if (legalentityName.isDeleted) {
        return legalentityName.labelDE + ' (gelöscht)';
      }
      return legalentityName?.labelDE;
    }
    else {
      return "";
    }
  }



  // == Grid Settings =======================================================================================================================================

  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;


  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "legalEntityKey",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }


  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;


  // TODO

  // == Helpers ==========================================================================================
  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: { value: string } } | null => {
      const emptyOrEmailRegex = /^(|\w+([-+.']\w+)*@(pwc.de|pwc.com|pwcinternal.com))$/;

      if (control.value == null || control.value == "") {
        return null;
      }

      const valid = emptyOrEmailRegex.test(control.value);
      return valid ? null : { 'invalidEmail': { value: (control.value as string) } };
    };
  }

  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;
  public auditResults: DropDownListItem[] = [];
  public legalEntities: DropDownStringItem[] = [];
  public legalEntitiesSource: DropDownStringItem[] = [];
  //any charachter before single @ Validators.pattern("^[^@]+@[^@]+\.[^@]+$")
  //Validators.pattern("^[^@\s]+@[^@\s]+\.[^@\s]+$")

  // == Forms =====================================================================
  public editForm: FormGroup = new FormGroup({
    leftName: new FormControl("", Validators.required),
    leftMailAddress: new FormControl("", [Validators.required, Validators.pattern("^\\w+([-+.']\\w+)*@(pwc.de|pwc.com|pwcinternal.com)$")]),
    rightName: new FormControl(),
    rightMailAddress: new FormControl(null, [this.customEmailValidator()]),
    legalEntityKey: new FormControl("", Validators.required),
    authorisationReceiveAvailable: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    isDE: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    isFormal: new FormControl<boolean>(false, {
      nonNullable: true
    }),
    hasSepa: new FormControl<boolean>(false, {
      nonNullable: true
    }),
  });

  // == Handlers =========================================================================

  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      console.log('master edit form event');
      console.log(args.dataItem);
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
  }

  public closeForm(): void {
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations ============================================================================
  public onSave(e: PointerEvent): void {
    e.preventDefault();

    console.log("Try to save.");
    if (this.isNew) {

      // Map UserAccessForCreationItem from Form
      let templateForCreationItem = {} as MailMetadataForCreationItem;

      templateForCreationItem = {
        legalEntityKey: this.editForm.value.legalEntityKey,
        leftName: this.editForm.value.leftName,
        leftMailAddress: this.editForm.value.leftMailAddress,
        rightName: this.editForm.value.rightName?.trim() ? this.editForm.value.rightName.trim() : null,
        rightMailAddress: this.editForm.value.rightMailAddress?.trim() ? this.editForm.value.rightMailAddress.trim() : null,
        authorisationReceiveAvailable: this.editForm.value.authorisationReceiveAvailable,
        isDE: this.editForm.value.isDE,
        isFormal: this.editForm.value.isFormal,
        mailAddressReviewer: "de_sdc_bescheidprufung_review@pwc.com",    // MailAddressReviewer per default = de_sdc_bescheidprufung_review@pwc.com (see #20795). Wird im Backend ebenfalls fest definiert.
        hasSepa: this.editForm.value.hasSepa,
      };

      console.log(templateForCreationItem);

      // Send to API
      this.apiLegalEntityService.addMailMetadata(templateForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Eintrag wurde erfolgreich angelegt.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Felder entsprechend nicht dem erwarteten Format.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Steuerpflichtige existiert nicht.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }
    else {
      // Update
      console.log("Try to update.");
      let templateForUpdateItem = {} as MailMetadataForUpdateItem;
      templateForUpdateItem = {
        legalEntityKey: this.editForm.value.legalEntityKey,
        leftName: this.editForm.value.leftName,
        leftMailAddress: this.editForm.value.leftMailAddress,
        rightName: this.editForm.value.rightName?.trim() ? this.editForm.value.rightName.trim() : null,
        rightMailAddress: this.editForm.value.rightMailAddress?.trim() ? this.editForm.value.rightMailAddress.trim() : null,
        authorisationReceiveAvailable: this.editForm.value.authorisationReceiveAvailable,
        isDE: this.editForm.value.isDE,
        isFormal: this.editForm.value.isFormal,
        mailAddressReviewer: "de_sdc_bescheidprufung_review@pwc.com",    // MailAddressReviewer per default = de_sdc_bescheidprufung_review@pwc.com (see #20795). Wird im Backend ebenfalls fest definiert.
        hasSepa: this.editForm.value.hasSepa,
      };

      console.log(templateForUpdateItem);

      this.apiLegalEntityService.updateMailMetadata(this.thisId, templateForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Der Eintrag wurde erfolgreich aktualisiert.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Felder entsprechend nicht dem erwarteten Format.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Aktualisieren eines Eintrags.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });


    }

    this.closeForm();
  }


  public onDelete(e: RemoveEvent): void {
    //e.preventDefault();
    if (e.dataItem?.id == undefined) {
      return;
    }

    this.apiLegalEntityService.deleteMailMetadata(e.dataItem.id).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Mail Kommunikationsdaten wurde erfolgreich gelöscht.');
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen diesen Eintrags.');
        } else if (err.status == 410) {
          this.notificationService.showError('Der item wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeForm();
  }

}

