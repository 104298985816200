import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WbsForCreationItem, WbsForUpdateItem, WbsItem } from 'app/_models/wbs.model';
import { WbsLegalEntityItem, WbsLegalEntityForCreation } from 'app/_models/wbs-legal-entity-item.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiWbsService {


  constructor(
    private http: HttpClient) { }

  /**
  * Get all WBS Codes.
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getAllWBS(): Observable<WbsItem[]> {
    return this.http.get<WbsItem[]>(ENVIRONMENT.apiBaseUrl + 'Wbs/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
  * Bind WBS Code to selected Legal Entity
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  assignToLegalEntityAsync(item: WbsLegalEntityForCreation): Observable<WbsLegalEntityItem> {
    return this.http.post<WbsLegalEntityItem>(ENVIRONMENT.apiBaseUrl + 'Wbs/AssignToLegalEntityAsync', item, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
  * Get a list of already assigned WBS Codes 
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getAssignedWBSAsync(): Observable<WbsLegalEntityItem[]> {
    return this.http.post<WbsLegalEntityItem[]>(ENVIRONMENT.apiBaseUrl + 'Wbs/GetAssignedWbsAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
  * Delete connection between legal entity and wbs code.
  * @param wbsLegalEntityId ID of the connection.
  * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
  */
  removeFromLegalEntityAsync(wbsLegalEntityId: number): Observable<WbsLegalEntityItem> {
    return this.http.delete<WbsLegalEntityItem>(`${ENVIRONMENT.apiBaseUrl}Wbs/RemoveFromLegalEntityAsync/?wbsLegalEntityId=${wbsLegalEntityId}`, HTTP_OPTIONS);
  }

  /**
 * API: Add a new wbs item.
 */
  addWBSAsync(item: WbsForCreationItem): Observable<WbsItem> {
    return this.http.post<WbsItem>(ENVIRONMENT.apiBaseUrl + 'Wbs/AddWbsCodeAsync', item, HTTP_OPTIONS);
  }

  /**
  * API: Update a new wbs item.
  */
  updateWBSAsync(itemId: number, item: WbsForUpdateItem): Observable<WbsItem> {
    return this.http.put<WbsItem>(ENVIRONMENT.apiBaseUrl + 'Wbs/UpdateWbsCodeAsync/' + itemId, item, HTTP_OPTIONS);
  }

  /**
    * API: Delete a wbs item.
    */
  deleteWBSAsync(itemId: number): Observable<WbsItem> {
    return this.http.delete<WbsItem>(ENVIRONMENT.apiBaseUrl + 'Wbs/DeleteWbsCodeAsync/' + itemId, HTTP_OPTIONS);
  }


}
