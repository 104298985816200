export interface DropDownStringItem {
    labelEN: string;
    labelDE: string;
    value?: string;
    isDefault: boolean;
    isDeleted: boolean;
}

export interface DropDownStringWithDisabledItem {
    labelEN: string;
    labelDE: string;
    value: string;
    isDefault: boolean;
    isDeleted: boolean;
    isDisabled: boolean;
    tooltip: string;
}

export class DropDownStringItemClass {
    // map to IData
    public static NewInstance(data: DropDownStringItem): DropDownStringItem {
        // if (!data) {
        //     return null;
        // }
        return {
            value: data.value,
            labelDE: data.labelDE,
            labelEN: data.labelEN,
            isDefault: data.isDefault,
            isDeleted: data.isDeleted,
        };
    }
    public static NewInstances(data: DropDownStringItem[]): DropDownStringItem[] {
        return data.map(DropDownStringItemClass.NewInstance);
    }
}