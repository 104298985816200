import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { ValidationDeactivationTemporaryItem } from 'app/_models/validation-deactivation.model';


@Component({
    selector: 'app-stare-integer',
    templateUrl: './stare-integer.component.html',
    styleUrl: './stare-integer.component.scss',
    standalone: false
})

export class StareIntegerComponent {
  @Output() onFieldFocus = new EventEmitter();
  //Sends value to disable to doc-base for saving
  @Output() onReferenceDisable = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlName: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;
  @Input() isArchiv: boolean = false;
  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  //ex. Kassenzeicht und Steuernummer
  @Input() overrideLabel: string = '';
  public label: string = '';
  public class: string = '';
  public fieldClass: string = '';

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;



  constructor(
    private dataService: DataService,
    public stareInputFieldsService: StareInputFieldsService,
    public tooltipService: TooltipService

  ) {

  }

  ngOnInit(): void {
    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        // The form has loaded successfully
        this.label = this.overrideLabel ? this.overrideLabel : this.stareInputFieldsService.getXbrlLabel(this.xbrlName);

      }
    });
    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    this.fieldClass = this.isReadOnly ? 'field-disabled' : 'field-enabled';

  }

  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }
  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }
  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }

  /** Triggered when the form fields are clicked on and should be focused on
 */
  public onReferenceDisableReciever(activation: boolean): void {
    this.onReferenceDisable.emit({ datasetId: this.datasetId, xbrl: this.xbrlName, activation: activation } as ValidationDeactivationTemporaryItem);
  }
}
