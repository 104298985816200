import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { OcrQueueItem } from 'app/_models/ocr-queue-item.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiOCRService {

  constructor(
    private http: HttpClient
  ) { }
  /**
   * Get all jobs.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllJobs(max: number = 5000): Observable<JobStatisticItem[]> {
    return this.http.get<JobStatisticItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'Ocr/JobsGetAllAsync/' + max, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
 * Get all Ocr items.
 * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
 */
  getAllOcrJobs(): Observable<OcrQueueItem[]> {
    return this.http.get<OcrQueueItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'Ocr/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

}