<div class="card-row">
    <div class="card-column">

        <div class="component-container">
            Bist du dir sicher, dass du den Bescheid im State Finished anpassen möchtest?
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="dialog.close()">{{ 'BUTTON.CANCEL' | translate }}</button>
        <button kendoButton (click)="submit()" themeColor="primary">{{ 'BUTTON.OK' |
            translate }}
        </button>
    </kendo-dialog-actions>
</div>