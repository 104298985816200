import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

// Localization
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEnDe from '@angular/common/locales/en-DE';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeEnDe);
registerLocaleData(localeDe);
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/');
}

// Services
//import { ThemeService } from './theme.service';
//import { CookieService } from 'ngx-cookie-service';

// Components
// import { ReferenceDataComponent } from './reference-data/reference-data.component';

import { AppComponent } from './app.component';
import { TasksComponent } from './tasks/tasks.component';
// import { ReferenceDataComponent } from './reference-data/reference-data.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QualityCheckComponent } from './quality-check/quality-check.component';
import { AdministrationComponent } from './administration/administration.component';
import { AdminImportCommuneComponent } from './admin-import-commune/admin-import-commune.component';
import { AdminImportRateComponent } from './admin-import-rate/admin-import-rate.component';
import { AdminCommuneComponent } from './admin-commune/admin-commune.component';
import { ArchiveComponent } from './archive/archive.component';
// import { TaxRealEstateReferenceComponent } from './reference-data/tax-real-estate-reference/tax-real-estate-reference.component';
// import { TaxTradeReferenceComponent } from './reference-data/tax-trade-reference/tax-trade-reference.component';
// import { ChamberOfCommerceReferenceComponent } from './reference-data/chamber-of-commerce-reference/chamber-of-commerce-reference.component';
// import { CorporateIncomeTaxReferenceComponent } from './reference-data/corporate-income-tax-reference/corporate-income-tax-reference.component';
import { SettingsComponent } from './settings/settings.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';

import { AdminTaxOfficeComponent } from './admin-taxoffice/admin-taxoffice.component';
import { LogoutComponent } from './logout/logout.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AssessmentReviewComponent } from './assessment-review/assessment-review.component';
import { SettingsMailTemplatesComponent } from './settings-mail-templates/settings-mail-templates.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

// Third Party
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

// Helpers
//import { httpInterceptorProviders } from './_helpers/http.interceptor';
import { JwtInterceptor } from './_helpers/http.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoginComponent } from './login/login.component';
import { UserpreferencesComponent } from './userpreferences/userpreferences.component';

import { NgApexchartsModule } from 'ng-apexcharts';
import { SettingWbsComponent } from './setting-wbs/setting-wbs.component';
import { SettingWorkflowComponent } from './setting-workflow/setting-workflow.component';
import { SettingTestdataComponent } from './setting-testdata/setting-testdata.component';
import { AdminClientEngagementComponent } from './admin-clientengagement/admin-clientengagement.component';
import { SettingLegalentitiesComponent } from './setting-legalentities/setting-legalentities.component';
import { SettingImportComponent } from './setting-import/setting-import.component';
import { SettingExportComponent } from './setting-export/setting-export.component';
import { DownloadComponent } from './download/download.component';
import { TypeSelectComponent } from './modal/type-select/type-select.component';

//kendo modules
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { StatePersistingService } from './_services/state-persisting.service';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { DateTimeService } from './_services/date-time.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { IconsModule } from '@progress/kendo-angular-icons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FaqComponent } from './faq/faq.component';
import { AdminUseraccessComponent } from './admin-useraccess/admin-useraccess.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { IntlModule } from '@progress/kendo-angular-intl';
import { EditorModule } from '@progress/kendo-angular-editor';
import '@progress/kendo-angular-intl/locales/de/all';
import { AdminJobsComponent } from './admin-jobs/admin-jobs.component';
import { AdminExportScopeComponent } from './admin-export-scope/admin-export-scope.component';
import { AdminOcrqueueComponent } from './admin-ocrqueue/admin-ocrqueue.component';
import { SettingsLogComponent } from './settings-log/settings-log.component';
import { DocUndefineComponent } from './assessment-review/doc-undefine/doc-undefine.component';
import { ListsService } from './_services/lists.service';
import { DocRateableValueComponent } from './assessment-review/doc-rateable-value/doc-rateable-value.component';
import { DocRateableValue1300Component } from './assessment-review/doc-rateable-value1300/doc-rateable-value1300.component';
import { DocRateableValue1400Component } from './assessment-review/doc-rateable-value1400/doc-rateable-value1400.component';
import { DocPropertyTaxBaseComponent } from './assessment-review/doc-property-tax-base/doc-property-tax-base.component';
import { DocPropertyTaxBase1300Component } from './assessment-review/doc-property-tax-base1300/doc-property-tax-base1300.component';
import { DocPropertyTaxBase1400Component } from './assessment-review/doc-property-tax-base1400/doc-property-tax-base1400.component';
import { DocCombiRateableValueAndPropertyTaxBaseComponent } from './assessment-review/doc-combi-rateable-value-and-property-tax-base/doc-combi-rateable-value-and-property-tax-base.component';
import { DocCombiRateableValueAndPropertyTaxBase1300Component } from './assessment-review/doc-combi-rateable-value-and-property-tax-base1300/doc-combi-rateable-value-and-property-tax-base1300.component';
import { DocCombiRateableValueAndPropertyTaxBase1400Component } from './assessment-review/doc-combi-rateable-value-and-property-tax-base1400/doc-combi-rateable-value-and-property-tax-base1400.component';
import { DocCorporateIncomeTaxComponent } from './assessment-review/doc-corporate-income-tax/doc-corporate-income-tax.component';
import { DocCorporateIncomeTax1300Component } from './assessment-review/doc-corporate-income-tax1300/doc-corporate-income-tax1300.component';
import { DocCorporateIncomeTax1400Component } from './assessment-review/doc-corporate-income-tax1400/doc-corporate-income-tax1400.component';
import { DocCorporateIncomeTaxPrePaymentComponent } from './assessment-review/doc-corporate-income-tax-pre-payment/doc-corporate-income-tax-pre-payment.component';
import { DocCorporateIncomeTaxPrePayment1300Component } from './assessment-review/doc-corporate-income-tax-pre-payment1300/doc-corporate-income-tax-pre-payment1300.component';
import { DocCorporateIncomeTaxPrePayment1400Component } from './assessment-review/doc-corporate-income-tax-pre-payment1400/doc-corporate-income-tax-pre-payment1400.component';
import { DocCorporateIncomeTaxAddCarryForwardComponent } from './assessment-review/doc-corporate-income-tax-add-carry-forward/doc-corporate-income-tax-add-carry-forward.component';
import { DocCorporateIncomeTaxAddCarryForward1300Component } from './assessment-review/doc-corporate-income-tax-add-carry-forward1300/doc-corporate-income-tax-add-carry-forward1300.component';
import { DocCorporateIncomeTaxAddCarryForward1400Component } from './assessment-review/doc-corporate-income-tax-add-carry-forward1400/doc-corporate-income-tax-add-carry-forward1400.component';
import { DocCorporateIncomeTaxSeparateAndUniformDeterminationComponent } from './assessment-review/doc-corporate-income-tax-separate-and-uniform-determination/doc-corporate-income-tax-separate-and-uniform-determination.component';
import { DocCorporateIncomeTaxSeparateAndUniformDetermination1300Component } from './assessment-review/doc-corporate-income-tax-separate-and-uniform-determination1300/doc-corporate-income-tax-separate-and-uniform-determination1300.component';
import { DocCorporateIncomeTaxSeparateAndUniformDetermination1400Component } from './assessment-review/doc-corporate-income-tax-separate-and-uniform-determination1400/doc-corporate-income-tax-separate-and-uniform-determination1400.component';
import { DocCorporateIncomeTaxSaUControllingCompanyComponent } from './assessment-review/doc-corporate-income-tax-sa-ucontrolling-company/doc-corporate-income-tax-sa-ucontrolling-company.component';
import { DocCorporateIncomeTaxSaUControllingCompany1300Component } from './assessment-review/doc-corporate-income-tax-sa-ucontrolling-company1300/doc-corporate-income-tax-sa-ucontrolling-company1300.component';
import { DocCorporateIncomeTaxSaUControllingCompany1400Component } from './assessment-review/doc-corporate-income-tax-sa-ucontrolling-company1400/doc-corporate-income-tax-sa-ucontrolling-company1400.component';
import { DocTradeTaxBaseComponent } from './assessment-review/doc-trade-tax-base/doc-trade-tax-base.component';
import { DocTradeTaxBase1300Component } from './assessment-review/doc-trade-tax-base1300/doc-trade-tax-base1300.component';
import { DocTradeTaxBase1400Component } from './assessment-review/doc-trade-tax-base1400/doc-trade-tax-base1400.component';
import { DocTradeTaxLossCarryForwardComponent } from './assessment-review/doc-trade-tax-loss-carry-forward/doc-trade-tax-loss-carry-forward.component';
import { DocTradeTaxLossCarryForward1300Component } from './assessment-review/doc-trade-tax-loss-carry-forward1300/doc-trade-tax-loss-carry-forward1300.component';
import { DocTradeTaxLossCarryForward1400Component } from './assessment-review/doc-trade-tax-loss-carry-forward1400/doc-trade-tax-loss-carry-forward1400.component';
import { DocTradeTaxBreakdownComponent } from './assessment-review/doc-trade-tax-breakdown/doc-trade-tax-breakdown.component';
import { DocTradeTaxBreakdown1300Component } from './assessment-review/doc-trade-tax-breakdown1300/doc-trade-tax-breakdown1300.component';
import { DocTradeTaxBreakdown1400Component } from './assessment-review/doc-trade-tax-breakdown1400/doc-trade-tax-breakdown1400.component';
import { DocTradeTaxComponent } from './assessment-review/doc-trade-tax/doc-trade-tax.component';
import { DocTradeTax1300Component } from './assessment-review/doc-trade-tax1300/doc-trade-tax1300.component';
import { DocTradeTax1400Component } from './assessment-review/doc-trade-tax1400/doc-trade-tax1400.component';
import { DocPropertyTaxComponent } from './assessment-review/doc-property-tax/doc-property-tax.component';
import { DocPropertyTax1300Component } from './assessment-review/doc-property-tax1300/doc-property-tax1300.component';
import { DocPropertyTax1400Component } from './assessment-review/doc-property-tax1400/doc-property-tax1400.component';
import { DocDeadlineComponent } from './assessment-review/doc-deadline/doc-deadline.component';
import { DocDeadline1200Component } from './assessment-review/doc-deadline1200/doc-deadline1200.component';
import { DocDeadline3100Component } from './assessment-review/doc-deadline3100/doc-deadline3100.component';
import { DocDeletionComponent } from './assessment-review/doc-deletion/doc-deletion.component';
import { DocExceptionComponent } from './assessment-review/doc-exception/doc-exception.component';
import { DocCorporateIncomeTaxSeparateAssessmentP27Component } from './assessment-review/doc-corporate-income-tax-separate-assessment-p27/doc-corporate-income-tax-separate-assessment-p27.component';
import { DocCorporateIncomeTaxSeparateAssessmentP271300Component } from './assessment-review/doc-corporate-income-tax-separate-assessment-p271300/doc-corporate-income-tax-separate-assessment-p271300.component';
import { DocCorporateIncomeTaxSeparateAssessmentP271400Component } from './assessment-review/doc-corporate-income-tax-separate-assessment-p271400/doc-corporate-income-tax-separate-assessment-p271400.component';
import { DocCorporateIncomeTaxLossCarryForwardComponent } from './assessment-review/doc-corporate-income-tax-loss-carry-forward/doc-corporate-income-tax-loss-carry-forward.component';
import { DocCorporateIncomeTaxLossCarryForward1300Component } from './assessment-review/doc-corporate-income-tax-loss-carry-forward1300/doc-corporate-income-tax-loss-carry-forward1300.component';
import { DocCorporateIncomeTaxLossCarryForward1400Component } from './assessment-review/doc-corporate-income-tax-loss-carry-forward1400/doc-corporate-income-tax-loss-carry-forward1400.component';
import { ValidationMessageFormcontrolComponent } from './assessment-review/validation-message-formcontrol/validation-message-formcontrol.component';
import { DocChamberOfCommerceAndIndustryComponent } from './assessment-review/doc-chamber-of-commerce-and-industry/doc-chamber-of-commerce-and-industry.component';
import { DocChamberOfCommerceAndIndustry1300Component } from './assessment-review/doc-chamber-of-commerce-and-industry1300/doc-chamber-of-commerce-and-industry1300.component';
import { DocChamberOfCommerceAndIndustry1400Component } from './assessment-review/doc-chamber-of-commerce-and-industry1400/doc-chamber-of-commerce-and-industry1400.component';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { LegalAidDialogComponent } from './modal/legal-aid-dialog/legal-aid-dialog.component';
import { SettingDatevmetadatenComponent } from './setting-datevmetadaten/setting-datevmetadaten.component';
import { SettingMailmetadataComponent } from './setting-mailmetadata/setting-mailmetadata.component';
import { MailSelectionComponent } from './mail-selection/mail-selection.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { MailEditorComponent } from './mail-editor/mail-editor.component';
import { MailmetadataClientDetailsComponent } from './mailmetadata-client-details/mailmetadata-client-details.component';
import { AdminSdcUsersComponent } from './admin-sdc-users/admin-sdc-users.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ArchiveReopenDialogComponent } from './modal/archive-reopen-dialog/archive-reopen-dialog.component';
import { LegalentityAddDialogComponent } from './modal/legalentity-add-dialog/legalentity-add-dialog.component';
import { SettingSentMailComponent } from './setting-sent-mail/setting-sent-mail.component';
import { StareTextboxComponent } from './_input-components/stare-textbox/stare-textbox.component';
import { MailEditorViewComponent } from './_components/mail-editor-view/mail-editor-view.component';
import { TasksAllComponent } from './tasks-all/tasks-all.component';

import { WorkflowSplitButtonComponent } from './_components/shared/workflow-split-button/workflow-split-button.component';

import { DocCorporateTaxEbitdaComponent } from './assessment-review/doc-corporate-income-tax-ebitda/doc-corporate-income-tax-ebitda.component';
import { DocCorporateTaxEbitda1400Component } from './assessment-review/doc-corporate-income-tax-ebitda1400/doc-corporate-income-tax-ebitda1400.component';
import { DocCorporateTaxEbitda1300Component } from './assessment-review/doc-corporate-income-tax-ebitda1300/doc-corporate-income-tax-ebitda1300.component';
import { StareNumericalComponent } from './_input-components/stare-numerical/stare-numerical.component';
import { StareDropdownComponent } from './_input-components/stare-dropdown/stare-dropdown.component';
import { StareDatepickerComponent } from './_input-components/stare-datepicker/stare-datepicker.component';
import { StareCheckboxComponent } from './_input-components/stare-checkbox/stare-checkbox.component';
import { StareMultiselectComponent } from './_input-components/stare-multiselect/stare-multiselect.component';
import { StareTextareaComponent } from './_input-components/stare-textarea/stare-textarea.component';
import { StareIntegerComponent } from './_input-components/stare-integer/stare-integer.component';
import { StareDropdownStringComponent } from './_input-components/stare-dropdown-string/stare-dropdown-string.component';

import { LegalentityAddButtonComponent } from './_components/shared/legalentity-add-button/legalentity-add-button.component';

import { SettingsCommuneComponent } from './settings-commune/settings-commune.component';
import { DocTradeTaxAddCarryForward1300Component } from './assessment-review/doc-trade-tax-add-carry-forward1300/doc-trade-tax-add-carry-forward1300.component';
import { DocTradeTaxAddCarryForward1400Component } from './assessment-review/doc-trade-tax-add-carry-forward1400/doc-trade-tax-add-carry-forward1400.component';
import { DocTradeTaxAddCarryForwardComponent } from './assessment-review/doc-trade-tax-add-carry-forward/doc-trade-tax-add-carry-forward.component';
import { AssessmentResultSectionComponent } from './assessment-review/assessment-result-section/assessment-result-section.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DocIncomeTaxComponent } from './assessment-review/doc-income-tax/doc-income-tax.component';
import { DocValueAddedTaxComponent } from './assessment-review/doc-value-added-tax/doc-value-added-tax.component';
import { DocCorporateTaxInterestComponent } from './assessment-review/doc-corporate-tax-interest/doc-corporate-tax-interest.component';
import { ReferenceDisableButtonComponent } from './_components/shared/reference-disable-button/reference-disable-button.component';
import { AdminArchiveComponent } from './admin-archive/admin-archive.component';
import { StareRadioButtonComponent } from './_input-components/stare-radio-button/stare-radio-button.component';
import { StareDropdownNoticeNameComponent } from './_input-components/stare-dropdown-notice-name/stare-dropdown-notice-name.component';
import { StareToggleDropdownNoticeNameComponent } from './_input-components/stare-toggle-dropdown-notice-name/stare-toggle-dropdown-notice-name.component';
import { AdminArchiveEditDialogComponent } from './modal/admin-archive-edit-dialog/admin-archive-edit-dialog.component';

export function appInit(appConfigService: ListsService): () => void {
    return () => appConfigService.load();
}

@NgModule({
    declarations: [
        AppComponent,
        TasksComponent,
        DashboardComponent,
        QualityCheckComponent,
        // ReferenceDataComponent,
        // TaxRealEstateReferenceComponent,
        // TaxTradeReferenceComponent,
        // CorporateIncomeTaxReferenceComponent,
        UserManagementComponent,
        // ChamberOfCommerceReferenceComponent,
        AdministrationComponent,
        ArchiveComponent,
        SettingsComponent,
        AdminUsersComponent,
        AdminSdcUsersComponent,
        AdminTaxOfficeComponent,
        LogoutComponent,
        AccessDeniedComponent,
        AdminCommuneComponent,
        PageNotFoundComponent,
        AssessmentReviewComponent,
        LoginComponent,
        UserpreferencesComponent,
        SettingWbsComponent,
        SettingWorkflowComponent,
        SettingLegalentitiesComponent,
        SettingImportComponent,
        SettingExportComponent,
        SettingTestdataComponent,
        DownloadComponent,
        AdminImportCommuneComponent,
        AdminImportRateComponent,
        TypeSelectComponent,
        FaqComponent,
        AdminClientEngagementComponent,
        AdminUseraccessComponent,
        SettingsMailTemplatesComponent,
        AdminJobsComponent,
        AdminExportScopeComponent,
        AdminOcrqueueComponent,
        SettingsLogComponent,
        DocUndefineComponent,
        //DocBaseComponent,
        DocRateableValueComponent,
        DocRateableValue1300Component,
        DocRateableValue1400Component,
        DocPropertyTaxBaseComponent,
        DocPropertyTaxBase1300Component,
        DocPropertyTaxBase1400Component,
        DocCombiRateableValueAndPropertyTaxBaseComponent,
        DocCombiRateableValueAndPropertyTaxBase1300Component,
        DocCombiRateableValueAndPropertyTaxBase1400Component,
        DocCorporateIncomeTaxComponent,
        DocCorporateIncomeTax1300Component,
        DocCorporateIncomeTax1400Component,
        DocCorporateIncomeTaxPrePaymentComponent,
        DocCorporateIncomeTaxPrePayment1300Component,
        DocCorporateIncomeTaxPrePayment1400Component,
        DocCorporateIncomeTaxAddCarryForwardComponent,
        DocCorporateIncomeTaxAddCarryForward1300Component,
        DocCorporateIncomeTaxAddCarryForward1400Component,
        DocCorporateIncomeTaxSeparateAndUniformDeterminationComponent,
        DocCorporateIncomeTaxSeparateAndUniformDetermination1300Component,
        DocCorporateIncomeTaxSeparateAndUniformDetermination1400Component,
        DocCorporateIncomeTaxSaUControllingCompanyComponent,
        DocCorporateIncomeTaxSaUControllingCompany1300Component,
        DocCorporateIncomeTaxSaUControllingCompany1400Component,
        DocTradeTaxBaseComponent,
        DocTradeTaxBase1300Component,
        DocTradeTaxBase1400Component,
        DocTradeTaxLossCarryForwardComponent,
        DocTradeTaxLossCarryForward1300Component,
        DocTradeTaxLossCarryForward1400Component,
        DocTradeTaxBreakdownComponent,
        DocTradeTaxBreakdown1300Component,
        DocTradeTaxBreakdown1400Component,
        DocTradeTaxComponent,
        DocTradeTax1300Component,
        DocTradeTax1400Component,
        DocPropertyTaxComponent,
        DocPropertyTax1300Component,
        DocPropertyTax1400Component,
        DocDeadlineComponent,
        DocDeadline1200Component,
        DocDeadline3100Component,
        DocDeletionComponent,
        DocExceptionComponent,
        DocCorporateIncomeTaxSeparateAssessmentP27Component,
        DocCorporateIncomeTaxSeparateAssessmentP271300Component,
        DocCorporateIncomeTaxSeparateAssessmentP271400Component,
        DocCorporateIncomeTaxLossCarryForwardComponent,
        DocCorporateIncomeTaxLossCarryForward1300Component,
        DocCorporateIncomeTaxLossCarryForward1400Component,
        ValidationMessageFormcontrolComponent,
        DocChamberOfCommerceAndIndustryComponent,
        DocChamberOfCommerceAndIndustry1300Component,
        DocChamberOfCommerceAndIndustry1400Component,
        DocIncomeTaxComponent,
        DocValueAddedTaxComponent,
        DocCorporateTaxInterestComponent,
        LegalAidDialogComponent,
        ArchiveReopenDialogComponent,
        SettingDatevmetadatenComponent,
        SettingMailmetadataComponent,
        MailmetadataClientDetailsComponent,
        MailSelectionComponent,
        MailEditorComponent,
        LegalentityAddDialogComponent,
        SettingSentMailComponent,
        StareTextboxComponent,
        StareNumericalComponent,
        StareDropdownComponent,
        StareDropdownStringComponent,
        StareDatepickerComponent,
        StareCheckboxComponent,
        StareMultiselectComponent,
        StareIntegerComponent,
        StareTextareaComponent,
        MailEditorViewComponent,
        TasksAllComponent,
        WorkflowSplitButtonComponent,
        DocCorporateTaxEbitdaComponent,
        DocCorporateTaxEbitda1400Component,
        DocCorporateTaxEbitda1300Component,
        LegalentityAddButtonComponent,
        SettingsCommuneComponent,
        DocTradeTaxAddCarryForwardComponent,
        DocTradeTaxAddCarryForward1300Component,
        DocTradeTaxAddCarryForward1400Component,
        AssessmentResultSectionComponent,
        AdminDashboardComponent,
        ReferenceDisableButtonComponent,
        AdminArchiveComponent,
        StareRadioButtonComponent,
        StareDropdownNoticeNameComponent,
        StareToggleDropdownNoticeNameComponent,
        AdminArchiveEditDialogComponent
    ],
    bootstrap: [AppComponent], imports: [InputsModule,
        BrowserAnimationsModule,
        PopupModule,
        EditorModule,
        ChatModule,
        NgApexchartsModule,
        BrowserModule,
        NotificationModule,
        NavigationModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TooltipsModule,
        GridModule,
        ContextMenuModule,
        ButtonsModule,
        LabelModule,
        DropDownsModule,
        ExcelExportModule,
        ExcelModule,
        IndicatorsModule,
        IconsModule,
        DialogsModule,
        DateInputsModule,
        LayoutModule,
        ListBoxModule,
        ListViewModule,
        TreeViewModule,
        IntlModule,
        ToolBarModule,
        UploadsModule], providers: [
            Title,
            StatePersistingService,
            DateTimeService,
            [DatePipe],
            // ApiService,
            //ThemeService,
            // CookieService,
            {
                provide: LOCALE_ID,
                useValue: 'de-DE',
            },
            {
                provide: ErrorHandler,
                useClass: ApplicationinsightsAngularpluginErrorService,
            },
            ListsService,
            {
                provide: APP_INITIALIZER,
                useFactory: appInit,
                multi: true,
                deps: [ListsService],
            },
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
