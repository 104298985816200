import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KpiClientModel, KpiForAdminModel } from 'app/_models/kpi-client.models';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiKPIService {


  constructor(
    private http: HttpClient) { }

  /**
 * Get all KPIs from the API.
 */
  getAllKpis(): Observable<KpiClientModel[]> {
    return this.http.get<KpiClientModel[]>(ENVIRONMENT.apiBaseUrl + 'KPI/GetAllByClientAsync', HTTP_OPTIONS)
    // .pipe(
    // 	retry(1)
    //)
  }

  /**
* Get all KPIs from the API.
*/
  getAllAdminKpis(): Observable<KpiForAdminModel[]> {
    return this.http.get<KpiForAdminModel[]>(ENVIRONMENT.apiBaseUrl + 'KPI/GetAllByAppAsync', HTTP_OPTIONS)
    // .pipe(
    // 	retry(1)
    //)
  }
}
