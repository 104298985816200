<div class="stareTopic">
	{{ 'APP.ADMINISTRATION.USER_MANAGEMENT_MENU.TITLE' | translate }}
</div>

<br />
<br />

<div class="grid-layout-container">
	<kendo-gridlayout [gap]="{ rows: 6, cols: 10 }" [rows]="layoutRows" [cols]="[{ width: 700 }]">
		<kendo-gridlayout-item *ngFor="let option of settingOptionsData" [row]="option.id" [col]="option.col"
			[colSpan]="1">
			<div class="settingBox" style="background-color: #F3F3F3">
				<a [routerLink]="option.link">
					<span class="settingTitle">{{
						option.titleToDisplay }}</span>
					<br />
					<br />
					<div class="settingTooltip">
						{{option.tooltipToDisplay}}</div>
				</a>
				<a [routerLink]="option.link">
					<kendo-svgicon [id]="'arrow-'+option.col" size="xxlarge" [icon]="arrowRightIcon"
						style="color: #415385"></kendo-svgicon>
				</a>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>