import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from 'environments/environment';

import { map } from 'rxjs/operators';
import { FormFieldSaveModel } from 'app/_models/form-field-save.model';
import { AbstractControl } from '@angular/forms';
import { ValidationMessageItem } from 'app/_models/validation-message.model';
import { Collection, ReferenceDisabledListForControl, ValidationErrorListForControl, ValidationInfoListForControl, ValidationWarningListForControl } from 'app/_models/validation-list.model';
import { DataImportTemplateItem } from 'app/_models/data-exchange.model';
import { FileItem } from 'app/_models/file.model';
import { DataModel } from 'app/_models/data.model';
import { ExtractionRegionForCreationItem } from 'app/_models/extraction-region-creation.model';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { XbrlParserService } from '../xbrl-parser.service';
import { ValidationDeactivationItem } from 'app/_models/validation-deactivation.model';
import { SaveRequestItem } from 'app/_models/save-request-item.model';
import { UserLocal } from 'app/_models/user.model';

const HTTP_OPTIONS = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public ValidationErrorList = ValidationErrorListForControl;
    public ValidationWarnList = ValidationWarningListForControl;
    public ValidationInfoList = ValidationInfoListForControl;
    public ReferenceDisabledList = ReferenceDisabledListForControl;
    public myfields: FormFieldItem[] = [];
    private headerDatasetId: number = 0;
    public extractionRegion: ExtractionRegionForCreationItem | undefined;
    private lastCallTime: number | null = null;
    private readonly throttleTime: number = 2000;// to repeat api calls with delay of 2 seconds

    private _user: UserLocal = JSON.parse(localStorage.getItem('user')!) as UserLocal;

    private formLoadedSource = new BehaviorSubject<boolean>(false);
    formLoaded$ = this.formLoadedSource.asObservable();

    private referenceDisableButtonStateSource = new Subject<boolean>();
    referenceDisableButtonState$ = this.referenceDisableButtonStateSource.asObservable();

    // private adminArchivUnlockSource = new Subject<boolean>();
    // adminArchivUnlock$ = this.adminArchivUnlockSource.asObservable();
    private adminArchivUnlockFlag: boolean = false;

    constructor(private http: HttpClient, private xbrlParserService: XbrlParserService) { }

    /** Returns if the current user is admin */
    public requestedByAdmin(): boolean {
        return this._user.isAdmin;
    }

    SetHeaderDatasetID(id: number) {
        this.headerDatasetId = id;
    }
    GetHeaderDatasetID(): number {
        return this.headerDatasetId;
    }

    GetAdminArchivUnlockFlag(): boolean {
        return this.adminArchivUnlockFlag;
    }

    updateFormLoadedStatus(status: boolean) {
        this.formLoadedSource.next(status);
    }

    updateAdminArchivUnlockStatus(status: boolean) {
        // this.adminArchivUnlockSource.next(true);
        this.adminArchivUnlockFlag = status;
    }

    updatereferenceDisableButtonState() {
        this.referenceDisableButtonStateSource.next(true);
    }

    ValidateAsync(workflowId: number, acutalStateId: number, saveitems: FormFieldSaveModel[]): Observable<ValidationMessageItem[]> {
        saveitems.forEach((item) => {
            item.xbrlName = this.xbrlParserService.xbrlUIParseToAPISingle(item.xbrlName);
        });
        return this.http.post<ValidationMessageItem[]>(ENVIRONMENT.apiBaseUrl + 'Data/ValidateAsync/' + workflowId + '/' + acutalStateId, saveitems, HTTP_OPTIONS)
            .pipe(
                map((data: ValidationMessageItem[]) => {
                    for (let item of data) {
                        item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
                    }
                    return data;
                }));
    }
    GetValidationErrorListForFields(): Collection<ValidationMessageItem> {

        return this.ValidationErrorList;
    }
    GetValidationWarnListForFields(): Collection<ValidationMessageItem> {

        return this.ValidationWarnList;
    }
    GetValidationInfoListForFields(): Collection<ValidationMessageItem> {

        return this.ValidationInfoList;
    }

    ClearValidationList() {
        this.ValidationErrorList = new Collection<ValidationMessageItem>();
        this.ValidationWarnList = new Collection<ValidationMessageItem>();
        this.ValidationInfoList = new Collection<ValidationMessageItem>();
    }

    UpdateValidationList(messages: ValidationMessageItem[]): Collection<ValidationMessageItem> {
        // console.log('validtion msgs from API');
        // console.log(messages as ValidationMessageItem[]);
        messages.forEach((msg: ValidationMessageItem) => {
            msg.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(msg.xbrlName!);
        });
        // Remove reference data messages from validation list
        messages = messages.filter((s) => s.isReferenceDataMessage == false);

        // Key define as: xbrlName+_+datasetId

        this.ClearValidationList();
        messages.forEach((msg: ValidationMessageItem) => {
            let key = msg.xbrlName + "_" + msg.datasetId;

            if (msg.isError) {

                if (this.ValidationErrorList.containsKey(key)) {
                    let item = this.ValidationErrorList.item(key);
                    item.datasetId = msg.datasetId;
                    item.isComprehensiveError = msg.isComprehensiveError;
                    item.isError = msg.isError;
                    item.isWarning = msg.isWarning;
                    item.isInfo = msg.isInfo;
                    item.isInternal = msg.isInternal;
                    if (item.messageDE == '' || item.messageDE == null)
                        item.messageDE = msg.messageDE + ' ' + '\n';
                    else if (!(item.messageDE.includes(msg.messageDE))) {
                        item.messageDE = item.messageDE + ' ' + '\n' + msg.messageDE + ' ' + '\n';
                        // console.log(msg.datasetId);
                    }

                }
                else {
                    //console.log(msg.datasetId);
                    this.ValidationErrorList.add(key, {
                        workflowId: msg.workflowId,
                        datasetId: msg.datasetId,
                        xbrlName: msg.xbrlName,
                        isComprehensiveError: msg.isComprehensiveError,
                        isError: msg.isError,
                        isWarning: msg.isWarning,
                        isInfo: msg.isInfo,
                        isInternal: msg.isInternal,
                        messageDE: msg.messageDE,
                        messageEN: msg.messageEN,
                        isReferenceDataMessage: msg.isReferenceDataMessage
                    })
                    // console.log(this.ValidationErrorList.values());
                }
            }
            else if (msg.isWarning || msg.isComprehensiveError) {

                if (this.ValidationWarnList.containsKey(key)) {
                    //let item = this.ValidationErrorList.item(key);
                    let item = this.ValidationWarnList.item(key);
                    item.datasetId = msg.datasetId;
                    item.isComprehensiveError = msg.isComprehensiveError;
                    item.isError = msg.isError;
                    item.isWarning = msg.isWarning;
                    item.isInternal = msg.isInternal;
                    item.isInfo = msg.isInfo;
                    if (item.messageDE == '' || item.messageDE == null)
                        item.messageDE = msg.messageDE + ' ' + '\n';
                    else if (!(item.messageDE.includes(msg.messageDE)))
                        item.messageDE = item.messageDE + msg.messageDE + ' ' + '\n';
                }
                else {
                    this.ValidationWarnList.add(key, {
                        workflowId: msg.workflowId,
                        datasetId: msg.datasetId,
                        xbrlName: msg.xbrlName,
                        isComprehensiveError: msg.isComprehensiveError,
                        isError: msg.isError,
                        isWarning: msg.isWarning,
                        isInfo: msg.isInfo,
                        isInternal: msg.isInternal,
                        messageDE: msg.messageDE,
                        messageEN: msg.messageEN,
                        isReferenceDataMessage: msg.isReferenceDataMessage
                    })
                }
            }
            else if (msg.isInfo) {

                if (this.ValidationInfoList.containsKey(key)) {
                    let item = this.ValidationInfoList.item(key);
                    item.datasetId = msg.datasetId;
                    item.isComprehensiveError = msg.isComprehensiveError;
                    item.isError = msg.isError;
                    item.isWarning = msg.isWarning;
                    item.isInternal = msg.isInternal;
                    item.isInfo = msg.isInfo;
                    if (item.messageDE == '' || item.messageDE == null)
                        item.messageDE = msg.messageDE + ' ' + '\n';
                    else if (!(item.messageDE.includes(msg.messageDE)))
                        item.messageDE = item.messageDE + msg.messageDE + ' ' + '\n';
                }
                else {
                    this.ValidationInfoList.add(key, {
                        workflowId: msg.workflowId,
                        datasetId: msg.datasetId,
                        xbrlName: msg.xbrlName,
                        isComprehensiveError: msg.isComprehensiveError,
                        isError: msg.isError,
                        isWarning: msg.isWarning,
                        isInfo: msg.isInfo,
                        isInternal: msg.isInternal,
                        messageDE: msg.messageDE,
                        messageEN: msg.messageEN,
                        isReferenceDataMessage: msg.isReferenceDataMessage
                    })
                }
            }

        });
        //console.log('validation msgs saved in Updated Error list of service');
        //console.log(this.ValidationErrorList.values());
        // console.log('validation msgs saved in Updated Info list of service');
        // console.log(this.ValidationInfoList.values().filter((s) => s.isError == false));
        return this.ValidationErrorList;
    }


    getReferenceDeactivatedValidations(workflowId: number): Observable<ValidationDeactivationItem[]> {

        return this.http.post<ValidationDeactivationItem[]>(ENVIRONMENT.apiBaseUrl + 'Data/GetDeactivatedValidations/' + workflowId, HTTP_OPTIONS).pipe(
            map((data: ValidationDeactivationItem[]) => {
                for (let item of data) {
                    item.xbrl = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrl!);
                }
                return data;
            }));;
    }

    ClearReferenceDeactivatedList() {
        this.ReferenceDisabledList = new Collection<ValidationDeactivationItem>();
    }

    UpdateReferenceDeactivatedList(messages: ValidationDeactivationItem[]): Collection<ValidationDeactivationItem> {
        // console.log('validtion msgs from API');
        // console.log(messages as ValidationMessageItem[]);
        messages.forEach((msg: ValidationDeactivationItem) => {
            msg.xbrl = this.xbrlParserService.xbrlAPIParseToUISingle(msg.xbrl!);
        });

        // Key define as: xbrlName+_+datasetId

        this.ClearReferenceDeactivatedList();
        messages.forEach((msg: ValidationDeactivationItem) => {
            let key = msg.xbrl + "_" + msg.datasetId;

            if (!this.ReferenceDisabledList.containsKey(key)) {
                this.ReferenceDisabledList.add(key, {
                    id: msg.id,
                    workflowId: msg.workflowId,
                    datasetId: msg.datasetId,
                    xbrl: msg.xbrl
                })
            }

        });
        this.updatereferenceDisableButtonState();
        return this.ReferenceDisabledList;
    }

    GetReferenceDisabledListForFields(): Collection<ValidationDeactivationItem> {

        return this.ReferenceDisabledList;
    }



    /**
   * Update xbrl values for Dataset
   * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
    updateData(workflowId: number, actualStateId: number, saveitems: SaveRequestItem): Observable<ValidationMessageItem[]> {
        saveitems.dataForCreationOrUpdateSimple.forEach((item) => {
            item.xbrlName = this.xbrlParserService.xbrlUIParseToAPISingle(item.xbrlName);
        });
        saveitems.validationDeactivationForCreationOrUpdate.forEach((item) => {
            item.xbrl = this.xbrlParserService.xbrlUIParseToAPISingle(item.xbrl);
        });
        return this.http.post<ValidationMessageItem[]>(ENVIRONMENT.apiBaseUrl + 'Data/AddOrUpdateAsync/' + workflowId + '/' + actualStateId, saveitems, HTTP_OPTIONS).pipe(
            map((data: ValidationMessageItem[]) => {
                for (let item of data) {
                    item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
                }
                return data;
            }));;
    }

    ForbiddenValue(controlName: string, forbiddenValue: number) {
        return (group: AbstractControl) => {
            const control = group.get(controlName);
            //const matchingControl = group.get(matchingControlName);

            if (!control) {
                return null;
            }

            // set error on matchingControl if validation fails
            if (control.value == 0) {
                control.setErrors({ forbiddenValue: true });
            } else {
                control.setErrors(null);
            }
            return null;
        }
    }

    /**
 * API: Get content for the import template file
 */
    getBulkImportTemplateFileType2(): Observable<DataImportTemplateItem> {
        return this.http.get<DataImportTemplateItem>(ENVIRONMENT.apiBaseUrl + 'Data/GetBulkImportTemplateFileAsync?dataType=2', HTTP_OPTIONS)
    }

    /**
* API: Get content for the import template file
*/
    getBulkImportTemplateFileType4(): Observable<DataImportTemplateItem> {
        return this.http.get<DataImportTemplateItem>(ENVIRONMENT.apiBaseUrl + 'Data/GetBulkImportTemplateFileAsync?dataType=4', HTTP_OPTIONS)
    }

    /**
    * API: Get content for the import template file
    */
    getBulkImportTemplateFileAsync(): Observable<FileItem> {
        return this.http.get<FileItem>(ENVIRONMENT.apiBaseUrl + 'Data/GetBulkImportTemplateFileAsync', HTTP_OPTIONS)
    }

    //  to retreive field that user selects in form and provide field data for new rectangles on pdf text layer
    public currentFormfieldId: string = '';
    public currentPDFfieldId: string = '';


    /**
   * Returns true if user has not selected a form field
   */
    public isCurrentFormFieldEmpty(): boolean {
        // console.log('check form frld---' + this.currentFormfieldId);
        return this.currentFormfieldId == '' ? true : false;
    }

    /**
   * Returns true if a rectangle on the PDF is not highlighted in green
   */
    public isCurrentPDFFieldEmpty(): boolean {
        // console.log('check pdf frld---' + this.currentPDFfieldId);
        return this.currentPDFfieldId == '' ? true : false;
    }
    /**
      * Use a regular expression to extract digits from the string 
      */
    public extractNumberFromPixel(input: string): number {
        // console.log('extract coordinates form html rectangle element--' + input);
        // Check if the string ends with 'px'  
        if (input.endsWith('px')) {
            // Use a regular expression to extract digits from the string ending with 'px'  
            const match = input.match(/^(\d+)px$/);

            if (match) {
                // If a match is found, return the first captured group as a number  
                return parseInt(match[1], 10);
            } else {
                // Return 0 if the input string is not in the expected '123px' format  
                return 0;
            }
        } else {
            // Attempt to parse the string directly as a number  
            const number = parseInt(input, 10);

            if (isNaN(number)) {
                // Return 0 if the input string is not a valid number  
                return 0;
            }

            return number;
        }
    }

    public extractDecimalFromPx(input: string): number {
        //console.log('Extract coordinates from HTML rectangle element: ' + input);

        let numberPart: string;

        // Check if the input ends with 'px'
        if (input.toLowerCase().endsWith('px')) {
            // Remove the 'px' part
            numberPart = input.slice(0, -2);
        } else {
            if (input.length == 0) {
                numberPart = '0';
            } else {
                numberPart = input;
            }
        }

        // Parse the remaining part as a float
        const decimalNumber = parseFloat(numberPart);

        // Check if the parsed number is a valid number
        if (isNaN(decimalNumber)) {
            throw new Error('The extracted value is not a valid number. Input was: ' + input);
        }

        return decimalNumber;
    }


    private extractNumberFromString(input: string): number {
        // Use a regular expression to find the first sequence of digits in the string  
        const match = input.match(/\d+/);

        if (match) {
            // If a match is found, return the matched sequence as a number  
            return parseInt(match[0], 10);
        } else {
            // Return 0 if no digits are found in the string  
            return 0;
        }
    }

    /**
    * CReates a new extraction region
    */

    public getDataItemofFormField(fieldId: string): FormFieldItem | undefined {

        return this.myfields.find((item) => item.xbrlName === fieldId.split('-')[0]);
    }

    /**
    * Adds a new extraction region-- does not work woth position
    */

    // public addNewRectangle2(id: number, page: number, topVal: number, bottomVal: number, leftVal: number, rightVal: number): Observable<DataModel> | null {
    //     const currentTime = Date.now();

    //     if (this.lastCallTime && (currentTime - this.lastCallTime < this.throttleTime)) {
    //         console.log('API call ignored to avoid throttling');
    //         return null; // Return null or handle appropriately  
    //     }

    //     topVal = Math.round(topVal);
    //     rightVal = Math.round(rightVal);
    //     bottomVal = Math.round(bottomVal);
    //     leftVal = Math.round(leftVal);
    //     this.extractionRegion = {
    //         top: topVal,
    //         right: rightVal,
    //         left: leftVal,
    //         bottom: bottomVal,
    //         pageNumber: page
    //     };
    //     return this.http.put<DataModel>(`${ENVIRONMENT.apiBaseUrl}Data/AddRectangle/${id}`, this.extractionRegion, HTTP_OPTIONS);
    // }

    /**
  * Adds a new extraction region-- needs to be modified further
  */

    public addNewRectangleRecalculated(id: number, page: number, topVal: number, leftVal: number, bottomVal: number, rightVal: number,): Observable<DataModel> | null {
        const currentTime = Date.now();

        if (this.lastCallTime && (currentTime - this.lastCallTime < this.throttleTime)) {
            console.log('API call ignored to avoid throttling');
            return null; // Return null or handle appropriately  
        }

        topVal = Math.round(topVal);
        rightVal = Math.round(rightVal);
        bottomVal = Math.round(bottomVal);
        leftVal = Math.round(leftVal);

        this.extractionRegion = {
            top: topVal,
            right: rightVal,
            left: leftVal,
            bottom: bottomVal,
            pageNumber: page
        };
        return this.http.put<DataModel>(`${ENVIRONMENT.apiBaseUrl}Data/AddRectangle/${id}`, this.extractionRegion, HTTP_OPTIONS).pipe(
            map((data: DataModel) => {
                data.XbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(data.XbrlName);
                return data;
            }));
    }

    /**
    * Removes a extraction region
    */
    public deleteRectangle(id: number): Observable<DataModel> {
        return this.http.delete<DataModel>(ENVIRONMENT.apiBaseUrl + 'Data/DeleteRectangle/' + id, HTTP_OPTIONS).pipe(
            map((data: DataModel) => {
                data.XbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(data.XbrlName);
                return data;
            }));
    }







    private updateLegalEntitySource = new Subject<DropDownStringItem[]>();

    update$ = this.updateLegalEntitySource.asObservable();

    triggerUpdate() {
        this.updateLegalEntitySource.next();
    }




}
