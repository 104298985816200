<div class="card-row">
    <div class="card-column">

        <div class="component-container">
            <kendo-label id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
            <kendo-textarea [(ngModel)]="commentValue" type="string" placeholder="{{getXbrlLabel('document_comment')}}"
                autocomplete="off" [rows]="3" resizable="vertical">
            </kendo-textarea>
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="dialog.close()">{{ 'BUTTON.CANCEL' | translate }}</button>
        <button kendoButton (click)="submit()" themeColor="primary">Zur Rechtsmittelprüfung
        </button>
    </kendo-dialog-actions>
</div>