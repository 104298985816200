import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { FormFieldItem } from 'app/_models/form-field.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-doc-corporate-income-tax',
    templateUrl: './doc-corporate-income-tax.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocCorporateIncomeTaxComponent extends DocBaseComponent {
    constructor(
        listsService: ListsService,
        apiUIViewerService: UIViewerService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.corporateIncomeTaxForm;
    }

    //#region Form ======================================================================================================

    public corporateIncomeTaxForm = new UntypedFormGroup({
        id_companyIdentifier: new UntypedFormControl(""),
        reference_companyIdentifier: new UntypedFormControl(""),
        id_notificationDate: new UntypedFormControl(""),
        id_legalEntityKey: new UntypedFormControl(""),
        id_assessmentYear: new UntypedFormControl(""),
        document_auditResult: new UntypedFormControl(""),
        document_corporateIncomeTax_taxationBases: new UntypedFormControl(""), //ebitda dropdown
        document_corporateIncomeTax_vdn: new UntypedFormControl(""),
        document_corporateIncomeTax_establishedAmount: new UntypedFormControl(
            ""
        ), // Festgestellt werden (Körperschaftsteuer)
        document_corporateIncomeTax_chargeableAmount: new UntypedFormControl(
            ""
        ), // Anzurechnende Kapitalertragsteuer (Körperschaftsteuer)
        document_corporateIncomeTax_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (Körperschaftsteuer)
        document_corporateIncomeTax_billedAmount: new UntypedFormControl(""), // Abzurechnen sind (Körperschaftsteuer)
        document_corporateIncomeTax_alreadyPaidAmount: new UntypedFormControl(
            ""
        ), //  Bereits gezahlt (Körperschaftsteuer)
        document_corporateIncomeTax_differenceBilledPaid:
            new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Körperschaftsteuer)
        document_corporateIncomeTax_toPaid: new UntypedFormControl(""), // Fälligkeit (Körperschaftsteuer)

        document_corporateIncomeTax_solidarity_establishedAmount:
            new UntypedFormControl(""), // Festgestellt werden (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_chargeableAmount:
            new UntypedFormControl(""), // Anzurechnende Kapitalertragsteuer (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_remainingAmount:
            new UntypedFormControl(""), // Verbleibende Beträge (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_billedAmount:
            new UntypedFormControl(""), //  Abzurechnen sind (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_alreadyPaidAmount:
            new UntypedFormControl(""), //  Bereits gezahlt (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_differenceBilledPaid:
            new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Solidaritätszuschlag)
        document_corporateIncomeTax_solidarity_toPaid: new UntypedFormControl(
            ""
        ), //Fälligkeit (Solidaritätszuschlag)

        document_corporateIncomeTax_delay_establishedAmount:
            new UntypedFormControl(""), // Festgestellt werden (Verspätungszuschlag)
        document_corporateIncomeTax_delay_remainingAmount:
            new UntypedFormControl(""), // Verbleibende Beträge (Verspätungszuschlag)
        document_corporateIncomeTax_delay_billedAmount: new UntypedFormControl(
            ""
        ), //  Abzurechnen sind (Verspätungszuschlag)
        document_corporateIncomeTax_delay_alreadyPaidAmount:
            new UntypedFormControl(""), //  Bereits gezahlt (Verspätungszuschlag)
        document_corporateIncomeTax_delay_differenceBilledPaid:
            new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Verspätungszuschlag)
        document_corporateIncomeTax_delay_toPaid: new UntypedFormControl(""),

        document_corporateIncomeTax_interest_establishedAmount:
            new UntypedFormControl(""), // Festgestellt werden (Zinsen)
        document_corporateIncomeTax_interest_remainingAmount:
            new UntypedFormControl(""), // Verbleibende Beträge (Zinsen)
        document_corporateIncomeTax_interest_billedAmount:
            new UntypedFormControl(""), //  Abzurechnen sind (Zinsen)
        document_corporateIncomeTax_interest_alreadyPaidAmount:
            new UntypedFormControl(""), //  Bereits gezahlt (Zinsen)
        document_corporateIncomeTax_interest_differenceBilledPaid:
            new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Zinsen)
        document_corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),
        document_corporateIncomeTax_sum_remainingAmount: new UntypedFormControl(
            ""
        ), // Verbleibende Beträge (insgesamt)
        document_corporateIncomeTax_sum_billedAmount: new UntypedFormControl(
            ""
        ), //  Abzurechnen sind (insgesamt)
        document_corporateIncomeTax_sum_alreadyPaidAmount:
            new UntypedFormControl(""), //  Bereits gezahlt (insgesamt)
        document_corporateIncomeTax_sum_differenceBilledPaid:
            new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (insgesamt)
        document_corporateIncomeTax_sum_toPaid: new UntypedFormControl(""), //Fälligkeit (insgesamt)
        document_corporateIncomeTax_statusDate: new UntypedFormControl(""), // Stand der Abrechnung

        document_corporateIncome_taxableNetProfitLoss: new UntypedFormControl(
            ""
        ), // Steuerlicher Jahresüberschuss/ -fehlbetrag
        document_corporateIncome_taxableIncome: new UntypedFormControl(""), // Zu versteuerndes Einkommen
        document_corporateIncomeTax_dueDate: new UntypedFormControl(""), // Fälligkeit

        document_corporateIncomeTax_remainingCarryForward:
            new UntypedFormControl(""),
        document_corporateIncomeTax_interestCarryForward:
            new UntypedFormControl(""),
        document_corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(
            ""
        ),
        document_corporateIncomeTax_foreignTaxesIncluding12AStG:
            new UntypedFormControl(""),
        document_corporateIncomeTax_taxOffset: new UntypedFormControl(""),
        document_corporateIncomeTax_solidarity_taxOffset: new UntypedFormControl(""),
        document_corporateIncomeTax_delay_taxOffset: new UntypedFormControl(""),
        document_corporateIncomeTax_interest_taxOffset: new UntypedFormControl(""),

        document_corporateIncomeTax_interest_beginDate: new UntypedFormControl(
            ""
        ),
        document_corporateIncomeTax_interest_endDate: new UntypedFormControl(
            ""
        ),
        // document_corporateIncomeTax_interest_rate: new UntypedFormControl(""), Task 25959
        document_corporateIncomeTax_interest_bearingAmount:
            new UntypedFormControl(""),
        document_corporateIncomeTax_interest_calculated: new UntypedFormControl(
            ""
        ),

        corporateIncomeTax_remainingCarryForward: new UntypedFormControl(""),
        corporateIncomeTax_interestCarryForward: new UntypedFormControl(""),
        corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(""),
        corporateIncomeTax_foreignTaxesIncluding12AStG: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_interest_beginDate: new UntypedFormControl(""),
        corporateIncomeTax_interest_endDate: new UntypedFormControl(""),
        // corporateIncomeTax_interest_rate: new UntypedFormControl(""), Task 25959
        corporateIncomeTax_interest_bearingAmount: new UntypedFormControl(""),
        corporateIncomeTax_interest_calculated: new UntypedFormControl(""),

        corporateIncomeTax_toPaid: new UntypedFormControl(""),
        corporateIncomeTax_establishedAmount: new UntypedFormControl(""), //reference fields
        corporateIncomeTax_chargeableAmount: new UntypedFormControl(""),
        corporateIncomeTax_alreadyPaidAmount: new UntypedFormControl(""),
        corporateIncomeTax_solidarity_establishedAmount: new UntypedFormControl(
            ""
        ), //reference fields
        corporateIncomeTax_solidarity_chargeableAmount: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_solidarity_alreadyPaidAmount: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_delay_alreadyPaidAmount: new UntypedFormControl(""),
        corporateIncomeTax_delay_toPaid: new UntypedFormControl(""),
        corporateIncomeTax_interest_establishedAmount: new UntypedFormControl(
            ""
        ), //reference fields
        corporateIncomeTax_interest_alreadyPaidAmount: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_statusDate: new UntypedFormControl(""), //reference fields
        corporateIncomeTax_taxableNetProfitLoss: new UntypedFormControl(""),
        corporateIncomeTax_taxableIncome: new UntypedFormControl(""),
        corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),
        corporateIncomeTax_solidarity_toPaid: new UntypedFormControl(""),
        document_withoutReferenceValidation: new UntypedFormControl(""),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ""
        ),
        document_mail_requestedDocuments: new UntypedFormControl(""),
        document_mail_reasonNotAccepted: new UntypedFormControl(""),

        document_missingBankAccountDetails: new UntypedFormControl(""),
        documentPayload: new UntypedFormArray([]),
        id_notificationName: new UntypedFormControl(""),
    });

    //creates initial payloads for tax breakdown commune and sets the datasetId
    createDocumentDynamicFieldsPayload(datasetId: number): UntypedFormGroup {
        const documentDynamicFieldsPayload = new UntypedFormGroup({
            document_dynamic_name: new UntypedFormControl(""),
            document_dynamic_value: new UntypedFormControl(""),
            document_dynamic_referenceValue: new UntypedFormControl(""),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            })
        });
        return documentDynamicFieldsPayload;
    }

    //#endregion

    addSingularPayload(payload: DatasetItem): void {
        const payloadArray = this.documentPayloadGetter();

        this.documentPayloadArrayWithDatasetId.push(
            this.createDocumentDynamicFieldsPayload(payload.id)
        );
        //this.documentPayloadGetter().push(this.createtradeTaxBreakdownPayload(payload.id));
        payloadArray.push(this.createDocumentDynamicFieldsPayload(payload.id));
        // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
        // console.info("Add" + payload.dataTypeName);

    }

    addPayload(dataType: string): void {
        const payloadArray = this.documentPayloadGetter();

        // switch (dataType) {
        //   case "DocumentTradeTaxBreakdownCommune": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
            next: (data) => {
                const newPayload = data as FormFieldItem[];
                const newDatasetID = newPayload[0].datasetId;
                if (newDatasetID != null) {
                    payloadArray.push(
                        this.createDocumentDynamicFieldsPayload(newDatasetID)
                    );
                    //this.showSuccess('Neuen Eintrag eingefügt (interne Id: ' + newDatasetID + ').');
                    this.documentPayloadDatasetIdArray.push(newDatasetID);
                    // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
                }
            },
            error: (err: HttpErrorResponse) => {
                //this.showError('Fehler beim Hinzufügen: ' + err.message)
                console.error(err);
            },
        });
    }


}
