import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import {
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
    selector: 'app-doc-trade-tax-breakdown1300',
    templateUrl: './doc-trade-tax-breakdown1300.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocTradeTaxBreakdown1300Component extends DocBaseComponent {
    constructor(
        listsService: ListsService,

        apiUIViewerService: UIViewerService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.tradeTaxBreakdownForm1300;
    }

    // public documentTradeTaxBreakdownPayloadLength: number = 0;

    //#region Form ======================================================================================================

    /** Gewerbesteuer Zerlegung, Rechtsmittel-Check, State 1300 */
    public tradeTaxBreakdownForm1300 = new UntypedFormGroup({
        id_companyIdentifier: new UntypedFormControl(""),
        id_notificationDate: new UntypedFormControl(""),
        id_assessmentYear: new UntypedFormControl(""),
        document_legalRemedyClientDecision: new UntypedFormControl(""),
        id_legalEntityKey: new UntypedFormControl(""),
        document_comment: new UntypedFormControl(""),
        tradeTax_base: new UntypedFormControl(""), //reference
        tradeTax_salaries: new UntypedFormControl(""), //reference
        document_tradeTax_lossCarryForward: new UntypedFormControl(""),
        document_tradeTax_p165: new UntypedFormControl(""),
        document_auditResult: new UntypedFormControl(""),
        documentPayload: new UntypedFormArray([]),
        document_assessmentDetermination: new UntypedFormControl(""),
        document_tradeTax_base: new UntypedFormControl(""), // Messbetrag
        document_tradeTax_salaries: new UntypedFormControl(""),
        document_hasThresholdLimit: new UntypedFormControl(""),
        document_withoutReferenceValidation: new UntypedFormControl(""),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ""
        ),
        document_mail_requestedDocuments: new UntypedFormControl(""),
        document_mail_reasonNotAccepted: new UntypedFormControl(""),
        document_corporateIncomeTax_vdn: new UntypedFormControl(""),
        document_tradeTax_yearBasedOn: new UntypedFormControl(""),

        document_missingBankAccountDetails: new UntypedFormControl(""),
    });
    //#endregion

    //creates initial payloads for tax breakdown commune and sets the datasetId
    createtradeTaxBreakdownPayload(datasetId: number): UntypedFormGroup {
        const communePayload = new UntypedFormGroup({
            document_tradeTax_commune_name: new UntypedFormControl(""), //Steuernummer
            document_tradeTax_commune_salaries: new UntypedFormControl(""), //Unternehmen
            document_tradeTax_commune_fixedShare: new UntypedFormControl(""), //Zerlegung

            tradeTax_commune_fixedShare: new UntypedFormControl(""), //reference
            tradeTax_commune_salaries: new UntypedFormControl(""), //reference

            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            id_communeAGS: new UntypedFormControl(""),
            id_assessmentYear: new UntypedFormControl(""),
        });

        //(<UntypedFormArray>this.tradeTaxBreakdownForm.controls['documentPayload']).push(communePayload);
        // console.log("Adding non empty payload");
        // console.log(datasetId);
        return communePayload;
    }

    //#endregion

    addSingularPayload(payload: DatasetItem): void {
        const payloadArray = this.documentPayloadGetter();

        this.documentPayloadArrayWithDatasetId.push(
            this.createtradeTaxBreakdownPayload(payload.id)
        );
        //this.documentPayloadGetter().push(this.createtradeTaxBreakdownPayload(payload.id));
        payloadArray.push(this.createtradeTaxBreakdownPayload(payload.id));
        // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
        // console.info("Add" + payload.dataTypeName);
    }

    addPayload(dataType: string): void {
        const payloadArray = this.documentPayloadGetter();

        // switch (dataType) {
        //   case "DocumentTradeTaxBreakdownCommune": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
            next: (data) => {
                const newPayload = data as FormFieldItem[];
                const newDatasetID = newPayload[0].datasetId;
                if (newDatasetID != null) {
                    payloadArray.push(
                        this.createtradeTaxBreakdownPayload(newDatasetID)
                    );
                    //this.showSuccess('Neuen Eintrag eingefügt (interne Id: ' + newDatasetID + ').');
                    this.documentPayloadDatasetIdArray.push(newDatasetID);
                    // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
                }
            },
            error: (err: HttpErrorResponse) => {
                //this.showError('Fehler beim Hinzufügen: ' + err.message)
                console.error(err);
            },
        });
    }


}
