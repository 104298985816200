import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-income-tax1300',
  templateUrl: './doc-corporate-income-tax1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTax1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxForm1300;
  }




  //#region Form ======================================================================================================

  /** Körperschaftsteuer, Rechtsmittel-Check, State 1300 */
  public corporateIncomeTaxForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    id_assessmentYear: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    document_corporateIncomeTax_p165: new UntypedFormControl(""),
    document_corporateIncomeTax_taxationBases: new UntypedFormControl(""), //ebitda dropdown
    document_corporateIncomeTaxP27_taxDepositAccount: new UntypedFormControl(""),
    document_corporateIncomeTaxP27_nominalCapitalConversionOfTaxReserves: new UntypedFormControl("",),
    document_corporateIncomeTaxP27_distributableNetProfit: new UntypedFormControl(""),
    document_corporateIncomeTaxP27_stockAccordingToSection27: new UntypedFormControl("",),
    document_corporateIncomeTaxP27_stockAccordingToSection28: new UntypedFormControl(""),
    corporateIncomeTaxP27_taxDepositAccount: new UntypedFormControl(""),
    corporateIncomeTaxP27_nominalCapitalConversionOfTaxReserves: new UntypedFormControl(""),
    corporateIncomeTaxP27_distributableNetProfit: new UntypedFormControl(""),
    corporateIncomeTaxP27_stockAccordingToSection27: new UntypedFormControl("",),
    corporateIncomeTaxP27_stockAccordingToSection28: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_corporateIncomeTax_establishedAmount: new UntypedFormControl(""), // Festgestellt werden (Körperschaftsteuer)
    document_corporateIncomeTax_chargeableAmount: new UntypedFormControl(""), // Anzurechnende Kapitalertragsteuer (Körperschaftsteuer)
    document_corporateIncomeTax_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (Körperschaftsteuer)
    document_corporateIncomeTax_billedAmount: new UntypedFormControl(""), // Abzurechnen sind (Körperschaftsteuer)
    document_corporateIncomeTax_alreadyPaidAmount: new UntypedFormControl(""), //  Bereits gezahlt (Körperschaftsteuer)
    document_corporateIncomeTax_differenceBilledPaid: new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Körperschaftsteuer)
    document_corporateIncomeTax_toPaid: new UntypedFormControl(""), // Fälligkeit (Körperschaftsteuer)

    document_corporateIncomeTax_solidarity_establishedAmount: new UntypedFormControl(""), // Festgestellt werden (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_chargeableAmount: new UntypedFormControl(""), // Anzurechnende Kapitalertragsteuer (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_billedAmount: new UntypedFormControl(""), //  Abzurechnen sind (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_alreadyPaidAmount: new UntypedFormControl(""), //  Bereits gezahlt (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_differenceBilledPaid: new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Solidaritätszuschlag)
    document_corporateIncomeTax_solidarity_toPaid: new UntypedFormControl(""), //Fälligkeit (Solidaritätszuschlag)

    document_corporateIncomeTax_delay_establishedAmount: new UntypedFormControl(""), // Festgestellt werden (Verspätungszuschlag)    
    document_corporateIncomeTax_delay_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (Verspätungszuschlag)
    document_corporateIncomeTax_delay_billedAmount: new UntypedFormControl(""), //  Abzurechnen sind (Verspätungszuschlag)
    document_corporateIncomeTax_delay_alreadyPaidAmount: new UntypedFormControl(""), //  Bereits gezahlt (Verspätungszuschlag)
    document_corporateIncomeTax_delay_differenceBilledPaid: new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Verspätungszuschlag)
    document_corporateIncomeTax_delay_toPaid: new UntypedFormControl(""),

    document_corporateIncomeTax_interest_establishedAmount: new UntypedFormControl(""), // Festgestellt werden (Zinsen)    
    document_corporateIncomeTax_interest_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (Zinsen)
    document_corporateIncomeTax_interest_billedAmount: new UntypedFormControl(""), //  Abzurechnen sind (Zinsen)
    document_corporateIncomeTax_interest_alreadyPaidAmount: new UntypedFormControl(""), //  Bereits gezahlt (Zinsen)
    document_corporateIncomeTax_interest_differenceBilledPaid: new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (Zinsen)
    document_corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),
    document_corporateIncomeTax_sum_remainingAmount: new UntypedFormControl(""), // Verbleibende Beträge (insgesamt)
    document_corporateIncomeTax_sum_billedAmount: new UntypedFormControl(""), //  Abzurechnen sind (insgesamt)
    document_corporateIncomeTax_sum_alreadyPaidAmount: new UntypedFormControl(""), //  Bereits gezahlt (insgesamt)
    document_corporateIncomeTax_sum_differenceBilledPaid: new UntypedFormControl(""), //  Differenz (Abzurechnen vs bereits gezahlt) (insgesamt)
    document_corporateIncomeTax_sum_toPaid: new UntypedFormControl(""), //Fälligkeit (insgesamt)
    document_corporateIncomeTax_statusDate: new UntypedFormControl(""), // Stand der Abrechnung

    document_corporateIncome_taxableNetProfitLoss: new UntypedFormControl(""), // Steuerlicher Jahresüberschuss/ -fehlbetrag
    document_corporateIncome_taxableIncome: new UntypedFormControl(""), // Zu versteuerndes Einkommen
    document_corporateIncomeTax_dueDate: new UntypedFormControl(""),// Fälligkeit 

    document_corporateIncomeTax_remainingCarryForward: new UntypedFormControl(""),
    document_corporateIncomeTax_interestCarryForward: new UntypedFormControl(""),
    document_corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(""),
    document_corporateIncomeTax_foreignTaxesIncluding12AStG: new UntypedFormControl(""),

    document_corporateIncomeTax_interest_beginDate: new UntypedFormControl(""),
    document_corporateIncomeTax_interest_endDate: new UntypedFormControl(""),
    //  document_corporateIncomeTax_interest_rate : new UntypedFormControl(""), Task 25959
    document_corporateIncomeTax_interest_bearingAmount: new UntypedFormControl(""),
    document_corporateIncomeTax_interest_calculated: new UntypedFormControl(""),

    corporateIncomeTax_remainingCarryForward: new UntypedFormControl(""),
    corporateIncomeTax_interestCarryForward: new UntypedFormControl(""),
    corporateIncomeTax_ebitdaCarryForward: new UntypedFormControl(""),
    corporateIncomeTax_foreignTaxesIncluding12AStG: new UntypedFormControl(""),
    corporateIncomeTax_interest_beginDate: new UntypedFormControl(""),
    corporateIncomeTax_interest_endDate: new UntypedFormControl(""),
    //  corporateIncomeTax_interest_rate : new UntypedFormControl(""),  Task 25959
    corporateIncomeTax_interest_bearingAmount: new UntypedFormControl(""),
    corporateIncomeTax_interest_calculated: new UntypedFormControl(""),

    corporateIncomeTax_toPaid: new UntypedFormControl(""),
    corporateIncomeTax_establishedAmount: new UntypedFormControl(""),//reference fields
    corporateIncomeTax_chargeableAmount: new UntypedFormControl(""),
    corporateIncomeTax_alreadyPaidAmount: new UntypedFormControl(""),
    corporateIncomeTax_solidarity_establishedAmount: new UntypedFormControl(""),//reference fields
    corporateIncomeTax_solidarity_chargeableAmount: new UntypedFormControl(""),
    corporateIncomeTax_solidarity_alreadyPaidAmount: new UntypedFormControl(""),
    corporateIncomeTax_delay_alreadyPaidAmount: new UntypedFormControl(""),
    corporateIncomeTax_delay_toPaid: new UntypedFormControl(""),
    corporateIncomeTax_interest_establishedAmount: new UntypedFormControl(""),//reference fields    
    corporateIncomeTax_interest_alreadyPaidAmount: new UntypedFormControl(""),
    corporateIncomeTax_statusDate: new UntypedFormControl(""),//reference fields
    corporateIncomeTax_taxableNetProfitLoss: new UntypedFormControl(""),
    corporateIncomeTax_taxableIncome: new UntypedFormControl(""),
    corporateIncomeTax_sum_toPaid: new UntypedFormControl(""), //Fälligkeit (insgesamt)
    corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),
    corporateIncomeTax_solidarity_toPaid: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }



}
