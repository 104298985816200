<div class="card-row">
    <div class="card-column">

        <div class="component-container">
            {{ 'APP.ASSESSMENT_REVIEW.TYPE_CHANGE_MODAL.MESSAGE1' |
            translate }} <br /> {{ 'APP.ASSESSMENT_REVIEW.TYPE_CHANGE_MODAL.MESSAGE2' |
            translate }}
            <br /><br />
            <kendo-dropdownlist [(ngModel)]="selectedValue" [textField]="'labelMiddleDE'" [valueField]="'id'"
                [defaultItem]="selectedValue" [data]="types" [filterable]="true" (filterChange)="handleFilter($event)">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    {{ dataItem.labelMiddleDE }} ({{ dataItem.labelShortDE }})
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                    {{ dataItem.labelMiddleDE }} ({{ dataItem.labelShortDE }})
                </ng-template>
            </kendo-dropdownlist>
            <br /> <br />
            <input type="checkbox" kendoCheckBox id="chkReScan" checked="true" #chkReScan><label [labelClass]="false"
                class="k-checkbox-label" for="chkReScan">Den neuen Bescheidtyp per OCR
                auslesen.</label>
            <br />

            <br />
            <br />
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="dialog.close()">{{ 'BUTTON.CANCEL' | translate }}</button>
        <button kendoButton [disabled]="(this.selectedValue.id === 0) ? true : false" (click)="submit()"
            themeColor="primary">{{ 'BUTTON.OK' |
            translate }}
        </button>
    </kendo-dialog-actions>
</div>