import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { SortDescriptor } from "@progress/kendo-data-query";
import { ColumnMenuSettings, GridComponent, GridSize } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { OcrQueueItem } from 'app/_models/ocr-queue-item.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiOCRService } from 'app/_services/StareApiMaintenance/ocr.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-admin-ocrqueue',
  templateUrl: './admin-ocrqueue.component.html',
  styleUrls: ['./admin-ocrqueue.component.scss'],
  standalone: false
})
export class AdminOcrqueueComponent {

  public smallSize: GridSize = "small";
  /** Displayed (filtered) data */
  public tableData = [] as OcrQueueItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as OcrQueueItem[];
  /** API request is finished */
  public finishedLoading = false;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // ===============================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiOCRService: ApiOCRService,
    private dateTimeService: DateTimeService,
    public tooltipService: TooltipService
  ) {
    this.allData = this.allData.bind(this);
  }


  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.OCR.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }


  // == Resize Listener =============================================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiOCRService.getAllOcrJobs().subscribe({
      next: (res: OcrQueueItem[]) => {

        // Descripbe the jobCodeLabelDE from the jobCode
        if (res != null) {

          res.forEach((item: OcrQueueItem) => {
            if (item.createdDate) {
              item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
            }
            if (item.updateDate) {
              item.updateDate = this.dateTimeService.convertUTCTimeToLocalTime(item.updateDate);
            }
            if (item.inProgressDate) {
              item.inProgressDate = this.dateTimeService.convertUTCTimeToLocalTime(item.inProgressDate);
            }
            if (item.finishedDate) {
              item.finishedDate = this.dateTimeService.convertUTCTimeToLocalTime(item.finishedDate);
            }
          });


          // res.forEach((item: OcrQueueItem) => {
          //   if (item.jobCode == 0) {
          //     item.jobCodeLabelDE = "NA";
          //   } else if (item.jobCode == 1) {
          //     item.jobCodeLabelDE = "Fristmanagement";
          //   } else if (item.jobCode == 2) {
          //     item.jobCodeLabelDE = "PTA";
          //   } else if (item.jobCode == 3) {
          //     item.jobCodeLabelDE = "Fristmanagement + PTA";
          //   } else if (item.jobCode == 4) {
          //     item.jobCodeLabelDE = "Bescheidprüfung";
          //   } else if (item.jobCode == 5) {
          //     item.jobCodeLabelDE = "Fristmanagement + Bescheidprüfung";
          //   } else if (item.jobCode == 6) {
          //     item.jobCodeLabelDE = "PTA + Bescheidprüfung";
          //   } else if (item.jobCode == 7) {
          //     item.jobCodeLabelDE = "Fristmanagement + PTA + Bescheidprüfung";
          //   } else if (item.jobCode == 8) {
          //     item.jobCodeLabelDE = "Daten-Import";
          //   } else if (item.jobCode == 16) {
          //     item.jobCodeLabelDE = "Daten-Import (Hintergrund)";
          //   } else if (item.jobCode == 32) {
          //     item.jobCodeLabelDE = "Daten-Export (Hintergrund)";
          //   }
          // });
        }


        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });


  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;



  // Icons 
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;


  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false }) grid: any;



  /** Filter all displayed columns */
  public onFilter(value: string): void {

    let filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {

      // Get all displayed columns from kendo grid
      let columns = [];
      columns = this.grid.columns.toArray();

      // Convert columns to filter object
      columns.forEach((column: { field: any; }) => {
        if ((column.field != undefined)) {

          // Exclude isAdmin (boolean) column from global filter
          if ((column.field != "createdDate") &&

            (column.field != "inProgressDate") &&
            (column.field != "finishedDate")) {

            filter.filters.push({
              field: column.field,
              operator: "contains",
              value: value,
            });
          }
        }
      });
    }

    this.currentFilter = filter;
  }


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }


}
