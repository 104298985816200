import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { DateTimeService } from 'app/_services/date-time.service';
import { ListsService } from 'app/_services/lists.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { DataService } from 'app/_services/StareApi/data.service';

@Component({
    selector: 'app-doc-corporate-tax-interest',
    templateUrl: './doc-corporate-tax-interest.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocCorporateTaxInterestComponent extends DocBaseComponent {
    constructor(
        listsService: ListsService,
        apiUIViewerService: UIViewerService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.corporateTaxInterestForm;
    }

    //#region Form ======================================================================================================

    public corporateTaxInterestForm = new UntypedFormGroup({
        id_companyIdentifier: new UntypedFormControl(""),
        reference_companyIdentifier: new UntypedFormControl(""),
        id_notificationDate: new UntypedFormControl(""),
        id_legalEntityKey: new UntypedFormControl(""),
        id_assessmentYear: new UntypedFormControl(""),
        document_auditResult: new UntypedFormControl(""),

        document_corporateIncomeTax_interest_establishedAmount:
            new UntypedFormControl(""),
        document_corporateIncomeTax_interest_alreadyPaidAmount:
            new UntypedFormControl(""),
        document_corporateIncomeTax_interest_taxOffset: new UntypedFormControl(
            ""
        ),
        document_corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),
        document_corporateIncomeTax_dueDate: new UntypedFormControl(""),
        corporateIncomeTax_interest_establishedAmount: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_interest_alreadyPaidAmount: new UntypedFormControl(
            ""
        ),
        corporateIncomeTax_interest_toPaid: new UntypedFormControl(""),

        document_withoutReferenceValidation: new UntypedFormControl(""),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ""
        ),

        document_mail_requestedDocuments: new UntypedFormControl(""),
        document_mail_reasonNotAccepted: new UntypedFormControl(""),

        document_corporateIncomeTax_interest_beginDate: new UntypedFormControl(""),
        corporateIncomeTax_interest_beginDate: new UntypedFormControl(""),
        document_corporateIncomeTax_interest_endDate: new UntypedFormControl(""),
        corporateIncomeTax_interest_endDate: new UntypedFormControl(""),
        document_corporateIncomeTax_interest_bearingAmount: new UntypedFormControl(""),
        corporateIncomeTax_interest_bearingAmount: new UntypedFormControl(""),
        document_corporateIncomeTax_interest_calculated: new UntypedFormControl(""),
        corporateIncomeTax_interest_calculated: new UntypedFormControl(""),

        document_missingBankAccountDetails: new UntypedFormControl(""),
        id_notificationName: new UntypedFormControl(""),
    });

    //#endregion

    addSingularPayload(): void {
        //no payload
    }


}
