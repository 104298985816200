import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'app/_services/StareApi/data.service';

@Component({
  selector: 'app-validation-message-formcontrol',
  templateUrl: './validation-message-formcontrol.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class ValidationMessageFormcontrolComponent implements OnInit {
  /** datasetId hedaer or payload */
  @Input() datasetIdInput: number = 0;
  /** formcontrol name*/
  @Input() xbrlNameInput: string = '';

  constructor(
    private dataService: DataService,

  ) {

  }

  private isDebug: boolean = false;
  public compactErrorMsg: string = '';
  public compactWarnMsg: string = '';
  public compactInfoMsg: string = '';


  ngOnInit(): void {

    this.isDebug = localStorage.getItem('debug') == '1' ? true : false;
  }


  getErrorMessageByDatasetIDandXbrlName(datasetId: number, xbrl: string): string {
    const msg = this.dataService.GetValidationErrorListForFields().values().find((item) => (item.datasetId == datasetId && item.xbrlName == xbrl));
    if (msg != undefined) {
      this.setCompactErrorMessageByDatasetIDandXbrlName(msg.messageDE);
      return msg.messageDE;
    }
    else {
      return this.isDebug ? "ERROR: " + datasetId + " " + xbrl : "";
    }
  }
  getWarnMessageByDatasetIDandXbrlName(datasetId: number, xbrl: string): string {
    const msg = this.dataService.GetValidationWarnListForFields().values().find((item) => (item.datasetId == datasetId && item.xbrlName == xbrl));
    if (msg != undefined) {
      this.setCompactWarnMessageByDatasetIDandXbrlName(msg.messageDE);
      return msg.messageDE;
    }
    else {
      return this.isDebug ? "WARN: " + datasetId + " " + xbrl : "";
    }
  }
  getInfoMessageByDatasetIDandXbrlName(datasetId: number, xbrl: string): string {
    const msg = this.dataService.GetValidationInfoListForFields().values().find((item) => (item.datasetId == datasetId && item.xbrlName == xbrl));
    if (msg != undefined) {
      this.setCompactInfoMessageByDatasetIDandXbrlName(msg.messageDE);
      return msg.messageDE;
    }
    else {
      return this.isDebug ? "INFO: " + datasetId + " " + xbrl : "";
    }
  }

  setCompactErrorMessageByDatasetIDandXbrlName(msg: string): void {
    // if (msg.length > 50)
    //   msg = msg.substring(0, 49) + '....';
    this.compactErrorMsg = msg + '  ';
  }
  setCompactWarnMessageByDatasetIDandXbrlName(msg: string): void {
    // if (msg.length > 50)
    //   msg = msg.substring(0, 49) + '....';
    this.compactWarnMsg = msg + '  ';
  }
  setCompactInfoMessageByDatasetIDandXbrlName(msg: string): void {
    // if (msg.length > 50)
    //   msg = msg.substring(0, 49) + '....';
    this.compactInfoMsg = msg + '  ';
  }

}
