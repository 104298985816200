<form [formGroup]="propertyTaxBaseAssessmentForm1400" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="true"
                        [workflowType]='workflowType' overrideLabel="Einheitswertnummer">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="true"
                        [workflowType]='workflowType' [dataOptionsSource]="legalEntities" overrideLabel="Zurechnung
                        lt.
                        Bescheid">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="true"
                        [workflowType]='workflowType'>
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]='headerDatasetId'
                        [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_legalRemedyResult"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="false"
                        [workflowType]='workflowType' [dataNumberOptionsSource]="legalRemedyResult">
                    </app-stare-dropdown>
                </div>

            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_legalRemedyObjection"
                        [datasetId]='headerDatasetId' [form]="propertyTaxBaseAssessmentForm1400" [isReadOnly]="false"
                        [workflowType]='workflowType' [dataNumberOptionsSource]="legalRemedyObjection">
                    </app-stare-dropdown>
                </div>

            </div>
        </div>

    </fieldset>
    <br>
</form>

<!-- 
<form [formGroup]="propertyTaxBaseAssessmentForm1400" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
						{ height: 20 },
						{ height: 112 },
						{ height: 112 },
						{ height: 20 },
						{ height: 150 },
						{ height: 150 },
						{ height: 80 },						
						{ height: 20 },
						{ height: 112 }]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Einheitswertnummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            [readonly]="true" type="text" class="custom-fill-mode" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Einheitswertnummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId" id="taxDeterminationLabel">Zurechnung
                            lt.
                            Bescheid</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [readonly]="true" [filterable]="true"
                            [tabindex]="-1" [valuePrimitive]="true" [defaultItem]="defaultItem"
                            formControlName="id_legalEntityKey" (filterChange)="handleLegalEntitiesFilter($event)"
                            class="custom-fill-mode"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"></kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()" class="custom-fill-mode"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [readonly]="true" [enableMouseWheel]="false" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">{{getXbrlLabel('document_legalRemedyClientDecision')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyResult-'+headerDatasetId"
                            id="legalRemedyResultLabel">{{getXbrlLabel('document_legalRemedyResult')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_legalRemedyResult-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyResult')"
                            [defaultItem]="defaultItem" formControlName="document_legalRemedyResult"
                            [data]="legalRemedyResult">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyResult'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyObjection-'+headerDatasetId"
                            id="legalRemedyObjectionLabel">{{getXbrlLabel('document_legalRemedyObjection')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_legalRemedyObjection-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyObjection')"
                            [defaultItem]="defaultItem" formControlName="document_legalRemedyObjection"
                            [data]="legalRemedyObjection">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyObjection'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->