import { Component, ElementRef, EventEmitter, HostListener, OnInit, VERSION, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ENVIRONMENT } from '../../environments/environment';
import { Router } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { tap, switchMap, takeUntil, pairwise } from 'rxjs/operators';
import * as pdfjsLib from 'pdfjs-dist';
import { DataTypeItem } from 'app/_models/data-type.model';
import { FormFieldItem } from 'app/_models/form-field.model';
import { UserLocal } from '../_models/user.model';
import {
	DialogService,
	DialogRef,
} from "@progress/kendo-angular-dialog";

import { TypeSelectComponent } from 'app/modal/type-select/type-select.component';
import { DatePipe } from '@angular/common';
import { FormFieldSaveModel } from 'app/_models/form-field-save.model';
import { DataService } from 'app/_services/StareApi/data.service';
import { ValidationMessageItem } from 'app/_models/validation-message.model';
import { WorkflowComment, WorkflowCommentForCreationDto } from 'app/_models/workflow-comment.model';
import { formatDate } from '@angular/common';
//import { Collision } from '@progress/kendo-angular-tooltip';
import { Message, User, SendMessageEvent } from "@progress/kendo-angular-conversational-ui";

import { SVGIcon, saveIcon, undoIcon, zoomActualSizeIcon, zoomBestFitIcon, zoomInIcon, zoomOutIcon, trashIcon, lockIcon, unlockIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { FileExtractionContentDto, FileItem } from 'app/_models/file.model';
import { ListsService } from 'app/_services/lists.service';
import { INotificationModel } from 'app/_models/notification.model';
import { IWorkflowMetadata } from 'app/_models/workflow-metadata.model';
import { ITradeTaxInfoDto, ITradeTaxInfoInterestCalculatedDto, ITradeTaxInfoInterestDto, ITradeTaxInfoInterestRunDto, ITradeTaxInfoMaturityDto, ITradeTaxInfoPaymentDto } from 'app/_models/trade-tax-info.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { LegalAidDialogComponent } from 'app/modal/legal-aid-dialog/legal-aid-dialog.component';
import { WorkflowJournalForUI } from 'app/_models/workflow-journal.model';
import { IWorkflowLockItem } from 'app/_models/workflow-lock.model';
import { ITypeChange } from 'app/modal/type-select/ITypeChange';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { DateFormatOptions } from '@progress/kendo-angular-intl';
pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
//pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`;
import { ArchiveReopenDialogComponent } from 'app/modal/archive-reopen-dialog/archive-reopen-dialog.component';
import { ExtractRegionModel } from 'app/_models/data.model';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { Collision } from '@progress/kendo-angular-popup';
import { ApiFileService } from 'app/_services/StareApi/file.service';
import { ApiWorkflowCommentService } from 'app/_services/StareApi/workflow-comment.service';
import { ValidationDeactivationItem } from 'app/_models/validation-deactivation.model';
import { AdminArchiveEditDialogComponent } from 'app/modal/admin-archive-edit-dialog/admin-archive-edit-dialog.component';
import { TextLayer } from 'pdfjs-dist';
import { ButtonComponent, ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { ExtractionRegionForCreationItem } from 'app/_models/extraction-region-creation.model';



@Component({
	selector: 'app-assessment-review',
	templateUrl: './assessment-review.component.html',
	styleUrls: ['./assessment-review.component.scss'],
	standalone: false
})


export class AssessmentReviewComponent implements OnInit {

	// For resizing the grid
	@HostListener("window:resize", ["$event"])
	onResize(): void {
		this.pageHeight = window.innerHeight - 125;

	}

	@HostListener('window:keydown.control.s', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		event.preventDefault();
		this.saveAndValidate();
	}

	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private route: ActivatedRoute,
		private dateTimeService: DateTimeService,
		private dialogService: DialogService,
		private datePipe: DatePipe,
		private dataService: DataService,
		private router: Router,
		private notificationService: WrapperNotificationService,
		private listsService: ListsService,
		private apiUIViewerService: UIViewerService,

		private apiWorkflowService: ApiWorkflowService,
		private apiAuthService: ApiAuthentificationService,
		private apiFileService: ApiFileService,
		private apiWorkflowCommentService: ApiWorkflowCommentService

		// @Inject(DOCUMENT) document: Document,
		// private ngZone: NgZone,


	) {

		window.addEventListener('keydown', this.onKeyDown.bind(this));
	}

	public cx!: CanvasRenderingContext2D;
	public currentUser: UserLocal = {} as UserLocal;

	public pageHeight: number = window.innerHeight - 125;
	// End of resizing the grid	
	public collision: Collision = { horizontal: "flip", vertical: "fit" };

	/**  Validation messages from backend check */
	public validationMessages = [] as ValidationMessageItem[];
	/**  Validation disabled messages from backend check */
	public referenceDisabledMessages = [] as ValidationDeactivationItem[];

	/** Reference message from backend check */
	public referenceMessages = [] as ValidationMessageItem[];


	/** If true, display some additional informations */
	public isTradeTaxDocument: boolean = false;
	public isUnlockedArchiv: boolean = false;

	/** Guid of displayed pdf file */
	private fileId: string = '';

	/** datasetTypeName of workflow header dataset */
	public documentTypeName: string = '';
	public documentTypeNameLabelDE: string = '';

	/** Switch ngIf in HTML. Is set on loading */
	public currentDocumentTypeAndStateId: string = '';

	public buttonFromReview: boolean = false;
	public workflowType: number = 0;
	public workflowId: number = 0;
	public actualStateId: number = 0;
	public clientEngagementKey: string = '';

	public messages: ValidationMessageItem[] = [];
	private fieldsForRectangles: FormFieldItem[] = [];

	public finishedLoading: boolean = false;
	public isFormLoading: boolean = false;
	public isFormFilled: boolean = false;

	public workflowJournalAsText = '';

	eventSaved = new EventEmitter();

	/** From Abbyy, with textlayer */
	public fileItem: FileExtractionContentDto = {} as FileExtractionContentDto;

	// ** From Abbyy, with textlayer
	public fileItemMetadata: FileItem = {} as FileItem;

	/** From DMS */
	public fileOriginalItem: FileItem = {} as FileItem;

	public changeModalOpen = false;
	public changedDataType: DataTypeItem = {
		id: 0, name: 'Bitte auswählen...', labelShortDE: '', labelMiddleDE: '', labelDEWithName: '',
		labelDE: '', labelEN: '', documentDefinitionName: '', workflowSettingAllowed: false
	};


	ngOnInit(): void {
		this.dataService.updateAdminArchivUnlockStatus(false);
		this.isFormLoading = true;
		setTimeout(() => {
			this.isFormLoading = false;
			console.log('Timeout: Form loading set to false');
		}, 8000); // 8 seconds timeout for loading

		this.pdfDoc = null;		// reset

		this.translateService.get('APP.ASSESSMENT_REVIEW.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});
		this.route.queryParams.subscribe(async params => {
			this.workflowId = params.w ?? 0;
			this.actualStateId = params.s ?? 0;
			this.clientEngagementKey = params.c ?? '';

			//#region Safety checks

			// If no workflowId is given, redirect to tasks
			if (this.workflowId == 0) {
				this.router.navigate(['/tasks']);
			}
			if (this.actualStateId == 0) {
				this.router.navigate(['/tasks']);
			}

			//this.clientEngagementKey  muss mit dem aktuellen User übereinstimmen, sonst Fehlermeldung und zurück zu Tasks
			const jsonStr = localStorage.getItem('user') || '';
			if (jsonStr.length > 0) {
				const jsonObj = JSON.parse(jsonStr);
				const tmpUser = jsonObj as UserLocal;

				if (this.clientEngagementKey != tmpUser.clientEngagementKey) {
					//if user has access to new client ,save url and redirect to login
					let url = this.router.url;
					url = ENVIRONMENT.primaryDomain + url;

					this.apiAuthService.setRedirection(url, this.clientEngagementKey.toLocaleLowerCase());
					//window.location.href = ENVIRONMENT.apiBaseUrl + 'Auth/Login';
					this.router.navigate(['/login']);
					return;
				}
				else {
					this.currentUser = tmpUser;
				}
			}


			//#endregion



			this.apiWorkflowService.getWorkflowMetaDataById(this.workflowId).subscribe((response: IWorkflowMetadata) => {
				if (this.actualStateId != response.actualStateId) {
					this.router.navigate(['/tasks']);
				}

				this.fileId = response.fileIdWithTextLayer ?? response.fileId;
				this.documentTypeName = response.documentHeader.dataTypeName ?? 'Undefined';
				this.actualStateId = response.actualStateId;
				this.workflowType = response.workflowType;
				this.ButtonDisable(!response.isEditable);

				this.currentDocumentTypeAndStateId = this.GetCurrentFormName(this.actualStateId, this.documentTypeName, response.documentHeader.deletedDate);

				this.apiFileService.getFileMetadataById(response.fileId).subscribe((response2: FileItem) => {
					this.fileItemMetadata = response2;
				});

				this.apiFileService.getFileMetadataById(this.fileId).subscribe((response2: FileItem) => {
					this.fileOriginalItem = response2;
				});

				if (this.documentTypeName == 'DocumentTradeTax') {
					this.isTradeTaxDocument = true;
					this.loadTradeTaxInfo();
				}
				else {
					this.isTradeTaxDocument = false;
				}

				// Is this workflow locked?
				if (response.lockedUserId != null && response.lockedUserId != this.currentUser.userId) {
					this.apiWorkflowService.getWorkflowLockedInfos(this.workflowId).subscribe((lockResponse: IWorkflowLockItem) => {


						if (lockResponse.lockedDate != null) {

							const diffInMinutes = lockResponse.waitingForUnlock.toFixed(1);

							this.notificationService.showWarning('Das Dokument wird derzeit durch ' + lockResponse.lockedUserName + ' ebenfalls verwendet. Timeout in ' + diffInMinutes + ' Minuten.', 10);
						}

					});

				}



				if (!response.lockedUserId) {
					// Set lock
					this.apiWorkflowService.setLocked(this.workflowId);
				}


			});


			this.apiWorkflowCommentService.getAllComments(this.workflowId).subscribe((data: WorkflowComment[]) => {
				this.allComments = data;
				this.fillCommentSection();
			});

			this.apiUIViewerService.getJournalByWorkflowId(this.workflowId).subscribe((data: WorkflowJournalForUI[]) => {

				// Convert date to Date
				data.forEach(element => {
					if (element.actualStateDate != null) {
						element.actualStateDate = this.dateTimeService.convertUTCTimeToLocalTime(element.actualStateDate)!;
					}
				});

				// In State Review (1200): Get Preparer from last stateId 1100
				// get Preparer. OldStateId must be 1100. Order by actualStateDate desc
				const dataPreparer = data.filter(x => x.oldStateId == 1100).sort((a, b) => b.actualStateDate.getTime() - a.actualStateDate.getTime());
				if (dataPreparer.length > 0) {
					this.workflowJournalAsText = dataPreparer[0].oldStateName + ': ' + dataPreparer[0].updatedUsername;
				}
				else {
					this.workflowJournalAsText = 'Erstprüfer nicht vorhanden.';
				}

				// Add line break
				this.workflowJournalAsText += '\n\n';

				// max lentgh of element.oldStateName 
				let maxLength = 0;
				data.forEach(element => {
					if (element.oldStateName.length > maxLength) {
						maxLength = element.oldStateName.length;
					}
				});

				// Add all other journal entries
				data.forEach(element => {

					// Fill oldStateName with spaces to get a nice column
					let spaces = '';
					for (let i = 0; i < maxLength - element.oldStateName.length; i++) {
						spaces += ' ';
					}

					// Convert actualStateDate to local time





					this.workflowJournalAsText += element.oldStateName + spaces + '  ' + this.datePipe.transform(this.convertUTCDateToLocalDate(element.actualStateDate), 'dd.MM.yyyy HH:mm') + ': ' + element.updatedUsername + '\n';
				});
			});

			this.buildWorkflowTriggerList(this.workflowId);
		});


	}

	@ViewChild("canvas", { read: ElementRef }) public canvas: ElementRef | undefined;


	ngAfterViewInit(): void {
		const canvasEl: HTMLCanvasElement = this.canvas?.nativeElement;

		this.captureEvents(canvasEl);
		this.addTextSelectionListener();

		// window.addEventListener('click', (event: MouseEvent) => {
		// 	const contextMenu = document.querySelector('.context-menu');
		// 	if (contextMenu && !contextMenu.contains(event.target as Node)) {
		// 		this.contextMenuVisible = false;
		// 	}
		// });
	}

	convertUTCDateToLocalDate(date: Date): Date {
		const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

		const offset = date.getTimezoneOffset() / 60;
		const hours = date.getHours();

		newDate.setHours(hours - offset);

		return newDate;
	}





	format(charge_Desc: string): string {

		// Replace \n with <br>
		return charge_Desc.replace(/\n/g, '<br>');



	}

	/** Determine the current form for display */
	private GetCurrentFormName(stateId: number, datatypeName: string, deletedDate: Date | null): string {
		// console.log(stateId);
		if (stateId == 1000) {
			return '1000';
		}

		if (stateId == 9000) {
			if (deletedDate != null)
				return '8000';
			else
				return datatypeName + '_' + 1100;
		}

		if (stateId == 8000) {
			return '8000';
		}

		if (datatypeName != 'DocumentDeadline' && datatypeName != 'Undefine') {
			if (stateId == 1200) {											  // Review
				return datatypeName + '_' + 1100;
			}

			if (stateId == 1125) {											  // CIT Team
				return datatypeName + '_' + 1100;
			}

			if (stateId == 2000) {
				// Digitalisierung
				return datatypeName + '_' + 1100;

			}

			if (stateId == 1500) {
				return datatypeName + '_' + 1400;
			}

		}

		if (datatypeName == 'Undefine') {
			return 'Undefine';
		}

		return datatypeName + '_' + stateId;
	}


	//#region == Execute Workflow ==========================================================================================================================

	public backSVG: SVGIcon = undoIcon;
	public saveSVG: SVGIcon = saveIcon;
	private saveAndNextWorkflowStep: boolean = false;
	private saveAndRemedy: boolean = false;

	@ViewChild('btnSave2', { static: false })
	btnSave2!: ElementRef<ButtonComponent>;
	@ViewChild('btnSplit', { static: false })
	btnSplit!: ElementRef<ButtonComponent>;

	execWorkflowButtonData = [
		{
			name: "Default", disabled: false, optionValue: -1, isDefault: false, click: (): void => { }
		}];

	/** Build the "Next possible Trigger" */
	private buildWorkflowTriggerList(id: number): void {

		this.apiWorkflowService.getNextTrigger(id).subscribe({
			next: (data) => {
				this.execWorkflowButtonData = data.map((x) => {
					return {
						optionValue: x.triggerId, name: x.description, disabled: false, isDefault: x.default, click: (): void => {

							// console.log("Trigger : " + x.triggerId + " Name: " + x.description);

							if (x.triggerId == -40) {
								this.openWindow();
							}
							else if (x.triggerId == -70) {


								this.saveAndRemedy = true;
								this.openWindowRechtsmittel()
									.then(() => {

										// Execute trigger (without saving and without validation)
										// console.log("Trigger : " + x.triggerId);
										this.execWorkflowTrigger(x.triggerId);
									})
									.catch((error) => {
										console.error('Promise rejected with error: ' + error);
									});


							} else if (x.triggerId == -51) {
								this.openWindowForReopen();

							} else {
								// Execute trigger (without saving and without validation)
								// console.log("Trigger : " + x.triggerId + " Name: " + x.description);
								this.execWorkflowTrigger(x.triggerId);

							}


						}
					}
				});
			}
		});
	}
	execWorkflowTrigger(triggerId: number): void {
		this.isFormLoading = true;

		// Execute trigger (without saving and without validation)
		this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, triggerId).subscribe({
			next: () => {
				this.isFormLoading = false;
				this.router.navigate(['/tasks']);
			},
			error: (err: HttpErrorResponse) => {
				this.isFormLoading = false;
				if (err.status == 400) {
					this.notificationService.showError('Fehler beim Ausführen des Workflows: ' + err.message);
				} else if (err.status == 403) {
					this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument ist mit einer Mail verknüpft, die abgesendet wurde oder noch bearbeitet wird.');
				} else if (err.status == 405) {
					this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
				} else if (err.status == 406) {
					this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
				} else if (err.status == 409) {
					this.notificationService.showError('Es liegen Validierungsfehler vor. Diese müssen zuerst behoben werden.');
				} else if (err.status == 410) {
					this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
				} else if (err.status == 411) {
					this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument wurde bereits per E-Mail versendet.');
				} else if (err.status == 416) {
					this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
				} else if (err.status == 422) {
					this.notificationService.showError('Interner Fehler beim Speichern der Zahlungen');
				} else if (err.status == 424) {
					this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
				} else {
					this.notificationService.showError('Interner Fehler beim Ausführen des Workflows: ' + err.message);
				}

			}

		});
	}

	public onSplitButtonClick(): void {
        // BUG 24281-aria-hidden, Remove focus from the button  
        if (this.btnSplit) {  
        this.btnSplit.nativeElement.blur();  
        } 
      
		if (this.actualStateId == 9000) {
			this.openWindowForReopen();
		} else {
			this.saveAndNextWorkflowStep = true;
			this.isFormLoading = true;
			this.eventSaved.emit([]);
		}
		//this.saveAndNextWorkflowStep = true;
		//this.eventSaved.emit();

	}

	/** Triggered when the form fields are loaded from backend and the values are saved /patched to the respective
	 * form controls in UI
	 */
	public formLoadedSuccessfullyReceiver(): void {
		this.isFormFilled = true;
		//after form loaded, wait for pdf pages to render
	}

	/** Wird ausgelöst, wenn die Base das Speichern erfolgreich durchgeführt hat.
	 * Dadurch ist es möglich, die Validierungsmeldungen zu prüfen und ggf. den Workflow auszuführen.
	 */
	public savedSuccessfullyReceiver(eventResult: boolean): void {
		// console.log('next tsep flag--' + this.saveAndNextWorkflowStep);

		this.isFormLoading = false;
		//For trade tax: rebuild info box
		if (this.isTradeTaxDocument) {
			this.loadTradeTaxInfo();
		}
		if (!eventResult) {
			// Error while saving next workflow step is not executed
			this.saveAndNextWorkflowStep = false;

			return;
		}


		if (!this.saveAndNextWorkflowStep) {
			// Save was successful, but no workflow execution

			if (this.saveAndRemedy) {
				this.saveAndRemedy = false;
				this.execWorkflowTrigger(-70);
			}
			return;
		}

		// exists error messages?
		const errorLst = this.validationMessages.filter((item: ValidationMessageItem) => item.isReferenceDataMessage == false && item.isError == true);

		if (errorLst.length > 0) {
			//this.showError('Es liegen Validierungsfehler vor. Diese müssen zuerst behoben werden.');
			return;
		}



		// No errors => execute workflow trigger
		// Try to find default value in thsi.execWorkflowButtonData
		const findData = this.execWorkflowButtonData.find(x => x.isDefault == true);
		if (findData) {
			this.isFormLoading = true;
			this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, findData.optionValue).subscribe({
				next: () => {
					this.isFormLoading = false;
					this.router.navigate(['/tasks']);

				},
				error: (err: HttpErrorResponse) => {

					this.isFormLoading = false;
					if (err.status == 400) {
						this.notificationService.showError('Fehler beim Ausführen des Workflows: ' + err.message);
					} else if (err.status == 403) {
						this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument ist mit einer Mail verknüpft, die abgesendet wurde oder noch bearbeitet wird.');
					} else if (err.status == 405) {
						this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
					} else if (err.status == 406) {
						this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
					} else if (err.status == 409) {
						this.notificationService.showError('Es liegen Validierungsfehler vor. Diese müssen zuerst behoben werden.');
					} else if (err.status == 410) {
						this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
					} else if (err.status == 411) {
						this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument wurde bereits per E-Mail versendet.');
					} else if (err.status == 416) {
						this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
					} else if (err.status == 422) {
						this.notificationService.showError('Interner Fehler beim Speichern der Zahlungen');
					} else if (err.status == 424) {
						this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
					} else {
						this.notificationService.showError('Interner Fehler beim Ausführen des Workflows: ' + err.message);
					}
					this.ButtonDisable(false);
				}
			});
		} else {
			this.isFormLoading = false;
			// console.log("Formloaded Error Button Status: " + this.isFormLoading);
			this.notificationService.showError('Weiter-Button: Status nicht gefunden');
		}
	}

	/** Back to Task list */
	public onClickCancelButton(): void {
		if (this.actualStateId == 9000) {
			this.saveAndNextWorkflowStep = false;
			this.router.navigate(['/archive']);
		} else {
			this.saveAndNextWorkflowStep = false;
			this.router.navigate(['/tasks']);
		}
	}

	public ButtonDisable(disable: boolean = true): void {

		if (!this.currentUser.isAdmin) {
			this.btnSave2.nativeElement.disabled = disable;
			//&& this.currentAccessRights.length == 0
			//[disabled]="buttonFromReview"
			if (this.actualStateId == 9000 && !this.currentUser.canReopenWorkflows) {
				this.btnSplit.nativeElement.disabled = disable;
			}
		}
	}



	//#endregion

	//#region == User interaction =================================================================================================================================

	/** Send as event to save the data into the child form */
	saveAndValidate(): void {
		if ((this.actualStateId == 9000 && !this.currentUser.isAdmin)) {
			this.notificationService.showWarning("Im Archiv können keine Änderungen vorgenommen werden.");
		}
		else if ((this.actualStateId == 9000 && this.currentUser.isAdmin && !this.isUnlocked())) {
			this.notificationService.showWarning("Im Archiv können Änderungen nur nach Entsperrung vorgenommen werden.");
		}
		// } else if (this.actualStateId == 9000 && this.currentUser.isAdmin) {
		// 	this.openWindowAdminEditConfirm();
		// }
		else {
			this.apiWorkflowService.setLocked(this.workflowId);		// Renew lock
			this.isFormLoading = true;
			this.eventSaved.emit([]);
		}
	}

	onClickUnlockButton(): void {
		this.openWindowAdminEditConfirm();
		this.isUnlockedArchiv = true;
	}

	/** Window / Dialog: Change the dataType of this document */
	openWindow(): void {
		const dialog: DialogRef = this.dialogService.open({
			title: "Änderung der Bescheidart",
			content: TypeSelectComponent
		});

		dialog.content.instance.submitClick.subscribe({
			next: (data: ITypeChange) => {
				this.changedDataType = data.datatypeitem;
				this.apiWorkflowService.executeChangeDataTypeTrigger(this.workflowId, this.actualStateId, -40, data.datatypeitem.name, data.withRescan).subscribe({
					next: () => {
						this.router.navigate(['/tasks']);
					},
					error: (error) => {
						if (error instanceof HttpErrorResponse && error.status == 416) {
							this.notificationService.showError('Die Bescheidart kann nur in der Erstprüfung geändert werden.');
						}
						this.notificationService.showError('Fehler: ' + error.message);
					}
				});
			}
		});
	}

	/** Window / Dialog: Reopen the Assessment */
	openWindowForReopen(): void {
		const dialog: DialogRef = this.dialogService.open({
			title: "Zur Wiedereröffnung",
			content: ArchiveReopenDialogComponent
		});

		dialog.content.instance.submitClick.subscribe({
			next: () => {
				//this.action.emit(true);  // start loading form
				this.apiWorkflowService.executeReopenTrigger(this.workflowId, this.actualStateId, -51).subscribe({
					next: () => {
						//this.action.emit(false); // stop loading form
						this.notificationService.showSuccess('Die ausgewählte Option ist abgeschlossen. Sie werden zur Übersicht weitergeleitet');
						this.router.navigate(['/tasks']);
					},
					error: (err: HttpErrorResponse) => {
						//this.action.emit(false); // stop loading form
						if (err.status == 400) {
							this.notificationService.showError('Fehler beim Ausführen des Workflows: ' + err.message);
						} else if (err.status == 403) {
							this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument ist mit einer Mail verknüpft, die abgesendet wurde oder noch bearbeitet wird.');
						} else if (err.status == 405) {
							this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
						} else if (err.status == 406) {
							this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
						} else if (err.status == 409) {
							this.notificationService.showError('Fehler im Prozess. Validierungsfehler prüfen.');
						} else if (err.status == 410) {
							this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
						} else if (err.status == 411) {
							this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument wurde bereits per E-Mail versendet.');
						} else if (err.status == 416) {
							this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
						} else if (err.status == 424) {
							this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
						} else {
							this.notificationService.showError('Interner Fehler beim Ausführen des Workflows: ' + err.message);
						}
					},
					complete: () => {
						//this.action.emit(false); 
					}
				});
			}
		});

	}

	openWindowRechtsmittel(): Promise<ValidationMessageItem[]> {

		const dialog: DialogRef = this.dialogService.open({
			title: "Begründung zur Rechtsmittelprüfung",
			content: LegalAidDialogComponent
		});
		return new Promise(() => {
			dialog.content.instance.submitClick.subscribe({
				next: (data: string) => {
					const _saveitems: FormFieldSaveModel[] = [];
					const id = this.dataService.GetHeaderDatasetID();
					if (id != null && id != undefined && id != 0) {
						const saveitem: FormFieldSaveModel = {
							xbrlName: 'document_comment',
							value: data,
							datasetId: id
						}
						_saveitems.push(saveitem);

						this.apiWorkflowService.setLocked(this.workflowId);		// Renew lock
						// this.isFormLoading = true;
						this.eventSaved.emit(_saveitems);
						// this.dataService.updateData(this.workflowId, this.actualStateId, _saveitems).subscribe({
						// 	next: (res) => {
						// 		// console.log(res);
						// 		resolve(res);
						// 	}
						// 	,
						// 	error: (err: HttpErrorResponse) => {
						// 		reject(err.error);
						// 	}
						// });
					}

				}
			});
		});
	}

	openWindowAdminEditConfirm(): void {

		const dialog: DialogRef = this.dialogService.open({
			title: "Speichern und Validieren",
			content: AdminArchiveEditDialogComponent
		});
		dialog.content.instance.submitClick.subscribe({
			next: () => {
				//this.action.emit(true);  // start loading form
				this.apiWorkflowService.setLocked(this.workflowId);		// Renew lock
				this.dataService.updateAdminArchivUnlockStatus(true);
				this.selectedLockSVG = this.unlockSVG;
			}
		});
	}


	//#region == PDF Handler ==========================================================================================================================

	public updateRectangleFields(fields: FormFieldItem[]): void {
		this.fieldsForRectangles = fields;
		this.openFile();
	}

	public zoomInSVG: SVGIcon = zoomInIcon;
	public zoomOutSVG: SVGIcon = zoomOutIcon;
	public zoomBestFitSVG: SVGIcon = zoomBestFitIcon;
	public zoom100SVG: SVGIcon = zoomActualSizeIcon;
	public lockSVG: SVGIcon = lockIcon;
	public unlockSVG: SVGIcon = unlockIcon;
	public selectedLockSVG: SVGIcon = this.lockSVG;
	public pageCountText: string = '';
	public filename: string = '';
	public fileSourceURL: string = '';

	public scale: number = 1.0;
	public isScaleBestFit: boolean = true;
	public pdfDoc: pdfjsLib.PDFDocumentProxy | null = null;
	private pdfLayoutResizerActive: boolean = false;

	public pdfLayputSizeChange(): void {
		this.pdfLayoutResizerActive = true;
	}

	/** Received file from backend and display on UI */
	private async openFile() {
		if (this.filename != '') { //PDF file already open bug 22772
			//this.isFormLoading = false;
			// console.log("Formloaded Filename exists: " + this.isFormLoading);
			return;
		}
		this.pageCountText = '';
		this.filename = '';


		const pdfData = await this.apiFileService.apiFileAsPromise(this.fileId)
			.then((response: FileExtractionContentDto) => {
				this.fileItem = response;

				this.filename = this.fileOriginalItem.filename ?? 'Unbekannt';

				if (this.fileItemMetadata.sourceUrl != null && this.fileItemMetadata.sourceUrl.toLocaleLowerCase() != 'localhost') {
					this.fileSourceURL = this.fileItemMetadata.sourceUrl + "?web=1";			   // to display without checkout
				}

				// Set the page count text
				if (this.fileOriginalItem.pageCount != null) {
					if (this.fileItemMetadata.pageCount == 1) {
						this.pageCountText = '1 Seite';
					}
					else {
						this.pageCountText = this.fileItemMetadata.pageCount + ' Seiten';
					}
				}

				if (response.contentAsBase64 == null) {
					console.error('No PDF content available.');
					this.isFormLoading = false;
					// console.log("Formloaded 2: " + this.isFormLoading);
					return; // pdfdata = void??
				}

				return atob(response.contentAsBase64);

			}).catch(err => {
				console.error(err);
				this.isFormLoading = false;
				// console.log("Formloaded 3: " + this.isFormLoading);
				return undefined;//  otherwise void returned
			});

		// Using DocumentInitParameters object to load binary data.
		const loadingTask = pdfjsLib.getDocument({ data: pdfData });
		loadingTask.promise.then((pdf) => {
			this.pdfDoc = pdf;
			this.pdfRender().then(() => {
				// console.log('pdf render resolved');
				//this.isFormLoading = false;
			}).catch(err => {
				console.error(err);
				this.isFormLoading = false;
			});

		}, (reason) => {
			// PDF loading error
			console.error(reason);
			this.isFormLoading = false;
		}).catch(err => {
			console.error(err);
			this.isFormLoading = false;
		});
	}

	public suppressMouseEvents: boolean = false;

	/** Render the PDF file */
	private async pdfRender() {
		const pdfWrapper = <HTMLElement>document.getElementById('pdf-wrapper');
		const pdfLayoutPanel = <HTMLElement>document.getElementById('pdf-layout-panel');

		const pdf = this.pdfDoc;



		if (!pdf) {
			console.error('No PDF loaded. Use openFile() first.');
			return;
		}

		// Fetch all pages
		for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
			const lastPageNum = pdf.numPages + 1;
			pdf.getPage(pageNumber).then((page) => {
				//var scale = 1;

				if (this.isScaleBestFit) {
					const viewport = page.getViewport({ scale: 1 });
					const pdfWrapperWidth = pdfLayoutPanel.getBoundingClientRect().width;
					const scale = pdfWrapperWidth / viewport.width;
					this.scale = scale;
				}

				const viewport = page.getViewport({ scale: this.scale });
				const pageIndex = page._pageIndex + 1;


				// Try to get child element of pdfwrapper with id 'page-<pageIndex>'
				let pageWrapper = <HTMLElement>document.getElementById('page-' + pageIndex);
				if (!pageWrapper) {

					pageWrapper = <HTMLElement>document.createElement('div');
					pageWrapper.id = 'page-' + pageIndex;
					pageWrapper.className = 'page-wrapper';
					pageWrapper.setAttribute('style', 'position: relative; margin-bottom: 0rem;');

					// Create Page Number Div
					if (pageIndex > 1) {
						const pageNumberDiv = <HTMLElement>document.createElement('div');
						pageNumberDiv.id = 'page-number-' + pageIndex;
						pageNumberDiv.className = 'page-number';
						pageNumberDiv.setAttribute('style', '    border-bottom-width: 3px; border-bottom-color: #fce3c0; border-bottom-style: dashed; border-left-color: #fce3c0; border-left-style: solid; border-left-width: 3px; padding-left: 5px; font-weight: bold;');
						pageNumberDiv.innerHTML = 'Seite ' + pageIndex;  //+ ' von ' + pdf.numPages;
						pdfWrapper.appendChild(pageNumberDiv);
					}


					pdfWrapper.appendChild(pageWrapper);
				}

				// try to get child element of pageWrapper with id 'canvas-<pageIndex>'
				let canvas = <HTMLCanvasElement>document.getElementById('canvas-' + pageIndex);
				if (!canvas) {

					// Create a new Canvas element
					canvas = <HTMLCanvasElement>document.createElement('canvas');
					canvas.id = 'canvas-' + pageIndex;
					pageWrapper.appendChild(canvas);
				}

				// Prepare canvas using PDF page dimensions
				const context = <CanvasRenderingContext2D>canvas.getContext('2d', { willReadFrequently: true });
				canvas.height = viewport.height;
				//canvas.width = 500;  //viewport.width;

				// Get the width of the pdfWrapper and set the canvas width accordingly
				let pdfWrapperWidth = pdfLayoutPanel.getBoundingClientRect().width;

				// console.log('Viewport width: ' + viewport.width);
				// console.log('PDF-Wrapper width: ' + pdfWrapperWidth);
				// console.log('Scale: ' + this.scale);

				// if 0 then
				if (pdfWrapperWidth == 0) {

					let strWidth = "400px";

					// remove 'px' from the string
					strWidth = strWidth.substring(0, strWidth.length - 2);

					// convert to number
					pdfWrapperWidth = +strWidth;


				}

				canvas.width = pdfWrapperWidth;

				// PDF DPI
				// console.log("Page Info");
				// console.log(page._pageInfo);

				//capture events on the canvas-draw red border 
				// const cx: CanvasRenderingContext2D = context;
				this.captureEvents(canvas);



				//console.log('width: ' + canvas.width + ' height: ' + canvas.height);

				// Render PDF page into canvas context
				const renderContext = {
					canvasContext: context,
					viewport: viewport
				};
				page.render(renderContext).promise.then(() => {
					this.finishedLoading = true;
					// Returns a promise, on resolving it will return text contents of the page
					return page.getTextContent();
				}).then(async (textContent) => {

					// Get html element "text-layer" under the page-wrapper element
					let textLayer = this.findChild('page-' + pageIndex, 'text-layer');
					if (!textLayer) {

						// Assign CSS to the text-layer element
						textLayer = <HTMLElement>document.createElement('div');
						textLayer.id = 'text-layer';
						pageWrapper.appendChild(textLayer);
					}
					textLayer.style.left = canvas.offsetLeft + 'px';
					textLayer.style.top = canvas.offsetTop + 'px';
					textLayer.style.height = canvas.offsetHeight + 'px';
					textLayer.style.width = canvas.offsetWidth + 'px';


					const dpiX = this.fileItemMetadata.dpiX ?? 300;
					const dpiY = this.fileItemMetadata.dpiY ?? 300;
					const abbyyDpiX = this.scale * (canvas.width / ((canvas.width / 72) * dpiX));  // width in pt / 72 = inch * 300 dpi
					const abbyyDpiY = this.scale * (canvas.height / ((canvas.height / 72) * dpiY));  // width in pt / 72 = inch * 300 dpi

					this.fieldsForRectangles.forEach((field: FormFieldItem) => {
						field.extractRegionList?.forEach((extractRegion: ExtractRegionModel) => {

							// Try to get child element of textLayer with id 'dataItem-<dataItemId>'
							let rectangle = <HTMLSpanElement>document.getElementById('pdf-' + field.xbrlName + '-' + field.datasetId);
							if (!rectangle) {

								const xbrlLabel = this.listsService.xbrlTypes.find(x => x.name == field.xbrlName)?.labelDE ?? "Unbekannt";

								rectangle = <HTMLSpanElement>document.createElement('span');
								rectangle.id = 'pdf-' + field.xbrlName + '-' + field.datasetId;		// See also: onFocusInputXbrl
								rectangle.setAttribute('role', 'extraction');
								rectangle.classList.add('pdf-rectangle');

								rectangle.onclick = () => {
									this.setFieldFocus(field.xbrlName, field.datasetId);
								};
								rectangle.oncontextmenu = () => {
									//  temporary deactivate menu option with: event: MouseEvent
									//this.openContextMenu(event, field.xbrlName, field.datasetId);
								};
								rectangle.onmousedown = (event: MouseEvent) => {


									this.onMouseDown(event);
								};

								rectangle.title = xbrlLabel;



								const br = document.createElement('div');
								br.title = xbrlLabel;
								br.className = 'resize-handle br';
								br.style.position = 'absolute';
								br.style.width = '6px';
								br.style.height = '6px';
								br.style.background = 'red';
								br.style.opacity = '0.5'; /* 50% Transparenz */
								br.style.bottom = '-4px';
								br.style.right = '-4px';
								br.style.cursor = 'nwse-resize';
								br.style.zIndex = '10';
								br.onmousedown = (event: MouseEvent) => {
									event.stopPropagation();
									this.onMouseDown(event);
								};
								rectangle.appendChild(br);

								const img = document.createElement('div');  //red x to delete rectangle
								img.className = 'delete-rectangle';
								img.style.position = 'absolute';
								//img.innerHTML = '&#x274c';
								img.innerHTML = '&times;';
								img.style.textAlign = 'center';
								img.title = 'Löschen';
								img.style.color = '#d40101e5';
								img.style.height = '17px';
								img.style.width = '17px';
								img.style.opacity = '1.8'; /* 50% Transparenz */
								img.style.top = '-14px';
								img.style.right = '-11px';
								img.style.zIndex = '12';
								img.style.backgroundColor = '#f0e0e0';
								img.style.borderTopLeftRadius = '25px';
								img.style.borderTopRightRadius = '25px';
								img.style.borderBottomLeftRadius = '25px';
								img.style.borderBottomRightRadius = '25px';
								img.onclick = () => {
									// Deactivate mousedown and mouseup events  
									this.suppressMouseEvents = true;
									//console.log('delete rectangle clicked---' + field.xbrlName, field.datasetId);
									this.deleteRectangle(field.xbrlName, rectangle.id);
								};
								rectangle.appendChild(img);
							}

							extractRegion.ExtractRegionAreaList.forEach((extractRegionArea: ExtractionRegionForCreationItem) => {
								if (extractRegionArea.pageNumber == pageIndex) {
									rectangle.style.left = extractRegionArea.left * abbyyDpiX + 'px';
									rectangle.style.top = extractRegionArea.top * abbyyDpiY + 'px';
									const fixValueX = (extractRegionArea.right - extractRegionArea.left) * abbyyDpiX;
									const fixValueY = (extractRegionArea.bottom - extractRegionArea.top) * abbyyDpiY;

									rectangle.style.height = fixValueY + (extractRegionArea.bottom - extractRegionArea.top) * abbyyDpiY + 'px';
									rectangle.style.width = fixValueX + (extractRegionArea.right - extractRegionArea.left) * abbyyDpiX + 'px';
									rectangle.style.border = '2px solid red';			// See also: onFocusInputXbrl

									if (textLayer) {
										textLayer.appendChild(rectangle);
									}
									else {
										console.error('textLayer not found');
									}
								}
							})
						})
					})

					// Pass the data to the method for rendering of text over the pdf canvas
					// pdfjsLib.renderTextLayer({
					// 	textContent: textContent,
					// 	container: textLayer,
					// 	viewport: viewport,
					// 	textDivs: []
					// });
					const textLayerForRender = new TextLayer({
						textContentSource: textContent,
						viewport: viewport,
						container: textLayer,
					});
					await textLayerForRender.render();
				});
			}).then(() => {
				//console.log('pdf page load' + this.isFormFilled + this.finishedLoading);
				//this.finishedLoading is false
				if ((pageNumber == lastPageNum) && (this.isFormFilled)) {
					this.isFormLoading = false;
				}
			}).catch(err => {
				console.error(err);
				this.isFormLoading = false;
			});
		}

		return true;
	}

	private extractNumberFromId(id: string): number | null {
		// Regular expression to match the numeric part of the ID  
		const regex = /page-(\d+)/;
		const match = id.match(regex);

		if (match && match[1]) {
			// Convert the matched string to a number  
			return parseInt(match[1], 10);
		}

		// Return null if no match is found  
		return null;
	}

	// Function to find the nearest ancestor with the specified ID  
	private findNearestElementWithId(element: HTMLElement | null, id: string): HTMLElement | null {
		while (element) {
			if (element.id === id) {
				return element;
			}
			element = element.parentElement;
		}
		return null;
	}

	private findChild(idOfElement: string, idOfChild: string): HTMLElement | null {
		const element = document.getElementById(idOfElement);
		if (element == null) {
			return null;
		}
		const result = element.querySelector('[id=' + idOfChild + ']');
		if (result == null) {
			return null;
		}
		return result as HTMLElement;
	}

	public pdfZoomIn(): void {
		this.isScaleBestFit = false;
		this.scale = this.scale + 0.3;
		this.pdfRender();
	}

	public pdfZoomOut(): void {
		this.isScaleBestFit = false;
		this.scale = this.scale - 0.3;

		if (this.scale < 0.3) {
			this.scale = 0.3;
		}
		this.pdfRender();
	}

	public pdfZoom100(): void {
		this.isScaleBestFit = false;
		this.scale = 1;


		this.pdfRender();
	}

	public pdfZoomBestFit(): void {
		this.isScaleBestFit = true;
		this.scale = 1;


		this.pdfRender();
	}




	// == Text extraktor ================================

	public selectedText = '';

	private addTextSelectionListener(): void {
		const pdfWrapper = document.getElementById('pdf-wrapper');
		if (pdfWrapper) {
			pdfWrapper.addEventListener('mouseup', (event: MouseEvent) => this.onTextMouseUp(event));
		}
	}

	private onTextMouseUp(event: MouseEvent): void {
		const selectedText = this.getSelectedText();
		if (selectedText) {
			this.selectedText = selectedText;
			this.addNewRectangle(event);
			//setTimeout(() => this.openTextContextMenu(event, selectedText), 0);
		} else {
			this.contextMenuVisible = false;
		}
	}

	private getSelectedText(): string {
		let selectedText = '';
		console.log(window.getSelection);
		// if (window.getSelection) {
		selectedText = window.getSelection()?.toString() || '';
		// } else if ((document as any).selection && (document as any).selection.type !== 'Control') {
		// 	console.log(typeof document);
		// 	selectedText = (document as any).selection.createRange().text;
		// }
		return selectedText;
	}

	private openTextContextMenu(event: MouseEvent, text: string): void {
		//  temporary deactivate menu option 
		return;
		event.preventDefault();
		const { x, y } = this.getRelativeMousePosition(event);
		this.contextMenuPosition = { x, y };
		this.contextMenuData = { xbrlName: '', datasetId: null };
		this.selectedText = text;
		this.contextMenuVisible = true;
		// console.log(`Selected text: ${text}`);
		// console.log(`Context menu position: ${x}, ${y}`);
	}

	private deleteRectangle(xbrl: string, rectId: string): void {
		const item = this.dataService.getDataItemofFormField(xbrl);
		if (item != undefined)
			this.dataService.deleteRectangle(item.id).subscribe({
				next: () => {
					// console.log('finding element to delete---' + rectId);
					// Type assertion to ensure TypeScript knows we are dealing with an HTMLElement  
					const element = document.getElementById(rectId) as HTMLSpanElement | null;

					if (element) {
						// Removing the content of the element 
						element.remove(); // or element.textContent = '';  
					} else {
						console.error('Element with id  not found for deletion');
					}

					//this.notificationService.showSuccess('Die Region wurde gelöscht.');

				},
				error: (err: HttpErrorResponse) => {
					console.error(err);
				},
				complete: () => {
					// Nothing to do here
				}
			});
	}



	// == Context Menü =================================

	public trashSVG: SVGIcon = trashIcon;
	public contextMenuVisible: boolean = false;
	public contextMenuPosition = { x: 0, y: 0 };
	public contextMenuData = { xbrlName: '', datasetId: null as number | null };

	//TODO @Maria: Kendo Context Menu benutzen.

	public contextMenuAction(): void {			//TODO: Umschreiben der Parameter-Übergabe - kein String übergeben, ggf. eine ID
		this.contextMenuVisible = false;
		// console.log(`Context menu action: ${action}`);
		// console.log(`xbrlName: ${this.contextMenuData.xbrlName}, datasetId: ${this.contextMenuData.datasetId}`);
		// console.log(`Selected text: ${this.selectedText}`);

		//TODO @Maria: Hier weitere Aktionen und API Calls möglich


	}

	private openContextMenu(event: MouseEvent, xbrlName: string, datasetId: number | null): void {
		event.preventDefault();
		const { x, y } = this.getRelativeMousePosition(event);
		this.contextMenuPosition = { x, y };
		this.contextMenuData = { xbrlName, datasetId };
		this.contextMenuVisible = true;

		//console.log(`Context menu opened at ${x}, ${y}`);

	}


	@HostListener("document:click", ["$event"])
	public documentClick(event: MouseEvent): void {

		if (!this.contextMenuVisible) {
			return;
		}
		const contextMenu = document.querySelector('.context-menu');
		if (contextMenu && !contextMenu.contains(event.target as Node)) {
			this.contextMenuVisible = false;
		}
	}

	@HostListener("document:keydown", ["$event"])
	public keydown(event: KeyboardEvent): void {
		if (event.code === "Escape") {
			this.showValidationMessagesPopup = false;
			this.showReferenceMessagesPopup = false;
			this.showTradeTaxPopup = false;
			this.showCommentPopup = false;
		}
	}






	// == Mouse move ====================
	private isDragging = false;
	private isResizing = false;
	private dragStartX = 0;
	private dragStartY = 0;
	private dragOffsetX = 0;
	private dragOffsetY = 0;
	private resizeHandle: HTMLElement | null = null;
	private dragElement: HTMLElement | null = null;
	private dragTimeout: NodeJS.Timeout | null = null;

	private getRelativeMousePosition(event: MouseEvent): { x: number, y: number } {
		const splitterPane = document.getElementById('pdf-wrapper');
		//console.log(splitterPane);
		if (splitterPane) {
			const rect = splitterPane.getBoundingClientRect();
			return {
				x: event.clientX - rect.left,
				y: event.clientY - rect.top
			};
		}
		return { x: event.clientX, y: event.clientY };
	}

	private mouseDownTime: number = 0;

	private onMouseDown(event: MouseEvent): void {
		this.mouseDownTime = Date.now();

		const target = event.target as HTMLElement;
		if (target.classList.contains('delete-rectangle')) {
			return;
		}
		if (target.classList.contains('resize-handle')) {
			this.resizeHandle = target;
			this.dragElement = target.parentElement as HTMLElement;
			this.isResizing = true;
			this.selectedText = '';
			document.body.style.cursor = window.getComputedStyle(this.resizeHandle).cursor; // Set cursor when resizing  

		} else if (target.classList.contains('pdf-rectangle')) {
			const { x, y } = this.getRelativeMousePosition(event);
			this.dragStartX = x;
			this.dragStartY = y;
			this.dragElement = target;
			this.selectedText = '';
			const rect = target.getBoundingClientRect();
			const wrapperRect = document.getElementById('pdf-wrapper')?.getBoundingClientRect();
			if (wrapperRect) {
				this.dragOffsetX = this.dragStartX - (rect.left - wrapperRect.left);
				this.dragOffsetY = this.dragStartY - (rect.top - wrapperRect.top);
			}
			this.dragTimeout = setTimeout(() => {
				this.isDragging = true;
				target.classList.add('dragging');
			}, 100);
		}

		window.addEventListener('mousemove', this.onMouseMove.bind(this));
		window.addEventListener('mouseup', this.onMouseUp.bind(this));

		event.preventDefault();
	}

	private onMouseMove(event: MouseEvent): void {
		const { x, y } = this.getRelativeMousePosition(event);

		if (this.isDragging && this.dragElement) {
			this.dragElement.style.left = `${x - this.dragOffsetX}px`;
			this.dragElement.style.top = `${y - this.dragOffsetY}px`;
		} else if (this.isResizing && this.resizeHandle && this.dragElement) {
			const rect = this.dragElement.getBoundingClientRect();
			const wrapperRect = document.getElementById('pdf-wrapper')?.getBoundingClientRect();
			const offsetX = x - (rect.left - (wrapperRect ? wrapperRect.left : 0));
			const offsetY = y - (rect.top - (wrapperRect ? wrapperRect.top : 0));

			if (this.resizeHandle.classList.contains('br')) {
				this.dragElement.style.width = `${offsetX}px`;
				this.dragElement.style.height = `${offsetY}px`;
				//console.log('A');
			} else if (this.resizeHandle.classList.contains('tr')) {
				this.dragElement.style.width = `${offsetX}px`;
				this.dragElement.style.height = `${rect.height - offsetY}px`;
				this.dragElement.style.top = `${y}px`;
			} else if (this.resizeHandle.classList.contains('bl')) {
				this.dragElement.style.width = `${rect.width - offsetX}px`;
				this.dragElement.style.height = `${offsetY}px`;
				this.dragElement.style.left = `${x}px`;
			} else if (this.resizeHandle.classList.contains('tl')) {
				this.dragElement.style.width = `${rect.width - offsetX}px`;
				this.dragElement.style.height = `${rect.height - offsetY}px`;
				this.dragElement.style.top = `${y}px`;
				this.dragElement.style.left = `${x}px`;
			}
		}
	}

	private onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			this.endDraggingAndResizing();
		}
	}

	private endDraggingAndResizing(): void {
		//if (this.isDragging || this.isResizing) {
		if (this.dragElement) {
			this.dragElement.classList.remove('dragging');
		}
		this.isDragging = false;
		this.isResizing = false;
		this.dragElement = null;
		this.resizeHandle = null;
		window.removeEventListener('mousemove', this.onMouseMove.bind(this));
		window.removeEventListener('mouseup', this.onMouseUp.bind(this));

		// Reset cursor  
		document.body.style.cursor = '';

		clearTimeout(this.dragTimeout!);

	}

	private onMouseUp(event: MouseEvent): void {

		const timeElapsed = Date.now() - this.mouseDownTime;

		if (timeElapsed < 400) {
			//console.warn('TimeOut mouse up');
			this.endDraggingAndResizing();

			return;
		}


		//	console.log('isDragging: ' + this.isDragging + '| isResizing: ' + this.isResizing);



		let targetElement = event.target as HTMLElement;
		if (targetElement.classList.contains('delete-rectangle')) {
			return;
		}
		if (this.isDragging == false && this.isResizing == false) {
			return;
		}
		// console.log(targetElement.parentElement);
		// console.log('Target Element: ' + targetElement.id);
		// console.log('dragElement: ' + this.dragElement?.id);
		// console.log('resizeElement: ' + this.resizeHandle?.id);

		if (targetElement.id == '' && targetElement.parentElement != null) {
			targetElement = targetElement.parentElement as HTMLElement;
		}

		this.endDraggingAndResizing();

		let pageNumber: number | null;
		let currentfield = this.dataService.currentFormfieldId;

		event.preventDefault();


		// Find the nearest element with ID 'text-layer'  
		const childElement = this.findNearestElementWithId(targetElement, 'text-layer');

		if (childElement && childElement.parentElement) {
			const parentElement = childElement.parentElement;
			const parentId = parentElement.id;
			pageNumber = this.extractNumberFromId(parentId);

		} else {
			//	console.warn('The parent element could not be found. NO page Number');
			return;
		}

		if (pageNumber == null) {
			console.error('Page number could not be extracted.');
			return;
		}
		const canvas = <HTMLCanvasElement>document.getElementById('canvas-' + pageNumber);

		// window.removeEventListener('mousemove', this.onMouseMove.bind(this));
		// window.removeEventListener('mouseup', this.onMouseUp.bind(this));


		this.isDragging = false;
		this.isResizing = false;
		this.dragElement = null;
		this.resizeHandle = null;

		const dpiX = this.fileItemMetadata.dpiX ?? 300;
		const dpiY = this.fileItemMetadata.dpiY ?? 300;

		const abbyyDpiX = this.scale * (canvas.width / ((canvas.width / 72) * dpiX));  // width in pt / 72 = inch * 300 dpi
		const abbyyDpiY = this.scale * (canvas.height / ((canvas.height / 72) * dpiY));

		const top = this.dataService.extractDecimalFromPx(targetElement.style.top) / abbyyDpiY;
		const left = this.dataService.extractDecimalFromPx(targetElement.style.left) / abbyyDpiX;
		const bottom = (this.dataService.extractDecimalFromPx(targetElement.style.top) + (this.dataService.extractDecimalFromPx(targetElement.style.height) / 2)) / abbyyDpiY;
		const right = (this.dataService.extractDecimalFromPx(targetElement.style.left) + (this.dataService.extractDecimalFromPx(targetElement.style.width) / 2)) / abbyyDpiX;

		if (currentfield == '') {
			currentfield = targetElement.id.split('-')[1];
			// console.log('CurrentField Target: ' + targetElement.id);
			// console.log('CurrentField: ' + currentfield);

		}

		const formfield = this.dataService.getDataItemofFormField(currentfield);

		if (formfield != undefined) {

			this.dataService.addNewRectangleRecalculated(formfield.id, pageNumber, top, left, bottom, right)?.subscribe({
				next: () => {
					// this.notificationService.showSuccess('Die Region wurde gespeichert---' + currentfield.split('-')[0]);
				},
				error: (err: HttpErrorResponse) => {
					console.error(err);
				},
				complete: () => {

				}
			});
		}
	}


	private addNewRectangle(event: MouseEvent): void {
		let pageNumber: number | null;
		const currentfield = this.dataService.currentFormfieldId;


		// currentfield strcuture: xbrlName-datasetId
		const xbrlName = currentfield.split('-')[0];
		const datasetId = parseInt(currentfield.split('-')[1]);

		if (this.dataService.isCurrentFormFieldEmpty()) {
			this.notificationService.showWarning('Bitte wähle zuerst ein Feld im Formular aus, um eine Region zuzuweisen.');
			return;
		}
		const newRectangle = document.getElementById('pdf-' + currentfield);
		if (newRectangle) {
			//this.notificationService.showWarning('Zu diesem Feld existiert bereits eine Markierung auf dem PDF.');
			return;
		}
		const targetElement = event.target as HTMLElement;

		event.preventDefault();
		// Find the nearest element with ID 'text-layer'  
		const childElement = this.findNearestElementWithId(targetElement, 'text-layer');


		if (childElement && childElement.parentElement) {
			const parentElement = childElement.parentElement;
			const parentId = parentElement.id;
			// console.log(parentId);
			pageNumber = this.extractNumberFromId(parentId); //get page number from parent id format
		} else {
			console.warn('The parent element could not be found. No page number');
			return;
		}

		if (pageNumber == null)
			return;
		const canvas = <HTMLCanvasElement>document.getElementById('canvas-' + pageNumber) as HTMLCanvasElement;
		const { x, y } = this.getRelativeMousePosition(event);
		// console.log('add rectangle x y from add rectangle event' + x + '---' + y);
		// console.log('canvas width---' + canvas.width + '---');

		const rectWidth = 100; // Width of the initial rectangle  
		const rectHeight = 100; // Height of the initial rectangle  

		const topLeftX = x - rectWidth;
		const topLeftY = y - rectHeight;
		// Get html element "text-layer" under the page-wrapper element
		const textLayer = this.findChild('page-' + pageNumber, 'text-layer'); //pageindex 1
		const xbrlLabel = this.listsService.xbrlTypes.find(x => x.name == xbrlName)?.labelDE ?? "Unbekannt";

		const rectangle = <HTMLSpanElement>document.createElement('span');
		rectangle.id = 'pdf-' + currentfield;		// See also: onFocusInputXbrl
		rectangle.setAttribute('role', 'extraction');
		rectangle.classList.add('pdf-rectangle');
		rectangle.style.top = topLeftY + 'px';
		rectangle.style.left = topLeftX + 'px';

		rectangle.style.height = 100 + 'px';
		rectangle.style.width = 100 + 'px';

		rectangle.style.border = '2px solid red';
		rectangle.style.opacity = '1';	// See also: onFocusInputXbrl

		rectangle.onmousedown = (event: MouseEvent) => {
			this.onMouseDown(event);
		};

		rectangle.onclick = () => {
			this.setFieldFocus(xbrlName, datasetId);
		};

		rectangle.title = xbrlLabel;

		const br = document.createElement('div');
		br.className = 'resize-handle br';
		br.style.position = 'absolute';
		br.style.width = '6px';
		br.style.height = '6px';
		br.style.background = 'red';
		br.style.opacity = '0.5'; /* 50% Transparenz */
		br.style.bottom = '-4px';
		br.style.right = '-4px';
		br.style.cursor = 'nwse-resize';
		br.style.zIndex = '10';
		br.onmousedown = (event: MouseEvent) => {
			event.stopPropagation();
			this.onMouseDown(event);
		};
		rectangle.appendChild(br);
		const img = document.createElement('div');  //red x to delete rectangle
		img.className = 'delete-rectangle';
		img.style.position = 'absolute';
		//img.innerHTML = '&#x274c';
		img.innerHTML = '&times;';
		img.style.textAlign = 'center';
		img.title = 'Löschen';
		img.style.color = '#d40101e5';
		img.style.height = '17px';
		img.style.width = '17px';
		img.style.opacity = '1.8'; /* 50% Transparenz */
		img.style.top = '-14px';
		img.style.right = '-11px';
		img.style.zIndex = '12';
		img.style.backgroundColor = '#f0e0e0';
		img.style.borderTopLeftRadius = '25px';
		img.style.borderTopRightRadius = '25px';
		img.style.borderBottomLeftRadius = '25px';
		img.style.borderBottomRightRadius = '25px';
		img.onclick = () => {
			// Deactivate mousedown and mouseup events  
			this.suppressMouseEvents = true;
			//console.log('delete rectangle clicked---' + currentfield);
			this.deleteRectangle(currentfield.split('-')[0], rectangle.id);
		};
		rectangle.appendChild(img);
		if (textLayer) {
			textLayer.appendChild(rectangle);
			const formfield = this.dataService.getDataItemofFormField(currentfield);

			const dpiX = this.fileItemMetadata.dpiX ?? 300;
			const dpiY = this.fileItemMetadata.dpiY ?? 300;
			const abbyyDpiX = this.scale * (canvas.width / ((canvas.width / 72) * dpiX));  // width in pt / 72 = inch * 300 dpi
			const abbyyDpiY = this.scale * (canvas.width / ((canvas.width / 72) * dpiY));

			const top = this.dataService.extractDecimalFromPx(rectangle.style.top) / abbyyDpiY;
			const left = this.dataService.extractDecimalFromPx(rectangle.style.left) / abbyyDpiX;
			const bottom = (this.dataService.extractDecimalFromPx(targetElement.style.top) + (this.dataService.extractDecimalFromPx(targetElement.style.height) / 2)) / abbyyDpiY;
			const right = (this.dataService.extractDecimalFromPx(targetElement.style.left) + (this.dataService.extractDecimalFromPx(targetElement.style.width) / 2)) / abbyyDpiX;

			if (formfield != undefined) {
				this.dataService.addNewRectangleRecalculated(formfield.id, pageNumber, top, left, bottom, right)?.subscribe({
					next: () => {
						//this.notificationService.showSuccess('Die Region wurde hinzugefügt');
					},
					error: (err: HttpErrorResponse) => {
						console.error(err);
					},
					complete: () => {
						// Nothing to do here
					}
				});
			}
		}
		else {
			console.error('textLayer not found');
		}
	}



	//#endregion == End of PDF Handler ==========================================================================================================================


	//Red Border drawing Section ==========================================================================================================================


	public drawingSubscription: Subscription = new Subscription;

	name = 'Angular ' + VERSION.major;
	public coordinates: number[] = [];
	public captureEvents(canvasEl: HTMLCanvasElement): void {

		//let cx: CanvasRenderingContext2D | null = canvasEl.getContext('2d');

		/* this.cx.strokeStyle = '#f00';
		this.cx.lineWidth = 2; */

		let startX: number | null;
		let startY: number | null;
		let mouseX;
		let mouseY;
		// this will capture all mousedown events from teh canvas element
		this.drawingSubscription = fromEvent(canvasEl, 'mousedown')
			.pipe(
				tap(() => {
					startX = null;
					startY = null;
				}),
				switchMap(() => {
					// after a mouse down, we'll record all mouse moves
					return fromEvent<MouseEvent>(canvasEl, 'mousemove').pipe(
						// we'll stop (and unsubscribe) once the user releases the mouse
						// this will trigger a 'mouseup' event
						takeUntil(fromEvent(canvasEl, 'mouseup')),
						// we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
						takeUntil(fromEvent(canvasEl, 'mouseleave')),
						// pairwise lets us get the previous value to draw a line from
						// the previous point to the current point
						pairwise()
					);
				})
			)
			.subscribe((res: [MouseEvent, MouseEvent]) => {
				const rect = canvasEl.getBoundingClientRect();
				// console.log((res[1] as MouseEvent).target);
				// previous and current position with the offset
				if (startX == null) {
					startX = res[0].clientX - rect.left;
					startY = res[0].clientY - rect.top;
					// console.log(startX, startY);
				}

				mouseX = res[0].clientX - rect.left;
				mouseY = res[0].clientY - rect.top;

				if (!this.cx) {
					// console.log('null cx');
					//return;
				}

				//this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
				startY = (startY == null) ? 0 : startY
				const width = mouseX - startX;
				const height = mouseY - startY;

				//this.cx.strokeRect(startX, startY, width, height);
				// console.log(startX, startY, width, height);
				this.coordinates = [startX, startY, width, height];
				// this method we'll implement soon to do the actual drawing
				//this.drawOnCanvas({mouseX,height},{startY,height});

			});

	}

	drawOnCanvas(
		prevPos: { x: number; y: number },
		currentPos: { x: number; y: number }
	): void {
		// incase the context is not set
		if (!this.cx) {
			return;
		}

		// start our drawing path
		this.cx.beginPath();

		// we're drawing lines so we need a previous position
		if (prevPos) {
			// sets the start point
			this.cx.moveTo(prevPos.x, prevPos.y); // from
			// draws a line from the start pos until the current position
			this.cx.lineTo(currentPos.x, currentPos.y);

			// strokes the current path with the styles we set earlier
			this.cx.stroke();
		}
	}
	showCoordi(): void {
		//console.log(this.coordinates);
	}
	ngOnDestroy(): void {
		// this will remove event lister when this component is destroyed
		this.drawingSubscription.unsubscribe();
	}


	//#endregion == End of Red Border Drawing SEction==========================================================================================================================

	// == Comment Section ======================================================================================================

	private allComments: WorkflowComment[] = [];
	//public collision: Collision = { horizontal: "flip", vertical: "fit" };
	public commentToolTip: string = "";
	public commentCounts: number = 0;
	public showCommentPopup: boolean = false;
	public commentMessages: Message[] = [];

	/**
	 * Create a tooltip for the comment section.
	 */
	public fillCommentSection(): void {
		this.commentCounts = this.allComments?.length ?? 0;

		if (this.allComments == null) {
			this.commentToolTip = "";
			return;
		}

		this.commentToolTip = this.createDisplayComment();
	}

	/**
	 * Default user object.
	 */
	public user: User = {
		id: this.currentUser.userId,
		name: this.currentUser.firstname + ' ' + this.currentUser.lastname,
	};

	/**
	 * Build the tooltip for the comment section (same in task.ts)
	 */
	public createDisplayComment(): string {
		let displayComments = "";

		// filter the comments

		this.allComments = this.allComments.filter((x) => x.isDeleted === false);

		// sort the comments
		this.allComments.sort((a, b) => (a.displayDate < b.displayDate) ? 1 : -1)

		const maxComments = 3;

		// compound the comments
		for (let i = 0; i < maxComments; i++) {
			if (this.allComments[i] != null) {
				//displayComments = displayComments + this.allComments[i].displayUsername + " - " + formatDate(this.allComments[i].displayDate, 'dd.MM.yyyy, HH:mm', 'de') + "" + ":\n\r" + this.allComments[i].comment + "\n\r\n\r";
				const date = this.dateTimeService.convertUTCTimeToLocalTime(this.allComments[i].displayDate)
				displayComments = displayComments + this.allComments[i].displayUsername + " - " + formatDate(date == null ? "" : date, 'dd.MM.yyyy, HH:mm', 'de') + "" + ":\n\r" + this.allComments[i].comment + "\n\r\n\r";

			}
		}

		// add the number of hidden comments
		if (this.allComments.length > maxComments) {
			if (this.allComments.length - maxComments === 1) {
				displayComments = displayComments + "... und " + (this.allComments.length - maxComments) + " weiterer Kommentar.";
			}
			else {
				displayComments = displayComments + "... und " + (this.allComments.length - maxComments) + " weitere Kommentare.";
			}
		}

		return displayComments; //.slice(0, -4);
	}

	// For clicking outside the popup (close the popup)
	// @HostListener("document:click", ["$event"])
	// public documentClick(event: KeyboardEvent): void {
	// 	if (!this.contains(event.target)) {
	// 		this.showCommentPopup = false;
	// 	}
	// 	if (!this.containsValidationMessages(event.target)) {
	// 		this.showValidationMessagesPopup = false;

	// 		// Workaround
	// 		if (this.pdfLayoutResizerActive) {
	// 			this.pdfLayoutResizerActive = false;
	// 			this.pdfRender();
	// 		}
	// 		console.log("Close Validation Messages");
	// 	}
	// }

	private contains(target: EventTarget | null): boolean {
		return (
			this.anchorComment?.nativeElement.contains(target) ||
			(this.popupComment ? this.popupComment.nativeElement.contains(target) : false)
		);
	}

	@ViewChild("anchorPopupComment", { read: ElementRef }) public anchorComment: ElementRef | undefined;
	@ViewChild("popupComment", { read: ElementRef }) public popupComment: ElementRef | undefined;


	public showComments(): void {
		if (this.showCommentPopup) {
			this.showCommentPopup = false;
			return;
		}

		this.showCommentPopup = true;

		if (this.showCommentPopup) {
			// Load the comments
			this.apiWorkflowCommentService.getAllComments(this.workflowId).subscribe({
				next: (workflowCommentList: WorkflowComment[]) => {
					this.allComments = workflowCommentList;

					this.fillCommentSection();
					//console.log(workflowCommentList);
					// build the comment messages
					this.commentMessages = [];

					// Extract Author (as User) from workflowCommentList
					const authors: User[] = []
					for (let i = 0; i < workflowCommentList.length; i++) {
						const author = [{ id: workflowCommentList[i].displayUserId, name: workflowCommentList[i].displayUsername } as User];

						// push author to authors, if id not contains in authors
						if (authors.find(x => x.id === author[0].id) == null) {
							authors.push(author[0]);
						}
					}


					for (let i = 0; i < workflowCommentList.length; i++) {
						// create new Message object
						const message: Message = {
							author: authors.find(x => x.id === workflowCommentList[i].displayUserId) ?? { id: 0, name: "Unknown" } as User,
							text: workflowCommentList[i].comment,
							//	timestamp: convertUTCTimeToLocalTime(new Date(workflowCommentList[i].displayDate)) as Date
							timestamp: new Date(workflowCommentList[i].displayDate)
						};

						// COnvert timestamp to local time
						message.timestamp = this.dateTimeService.convertUTCTimeToLocalTime(message.timestamp as Date) as Date;

						// Push the message to the array
						this.commentMessages.push(message);
					}

					this.commentCounts = workflowCommentList.length;

					// The user to current UserId
					this.commentMessages.forEach((message: Message) => {
						if (message.author.id === this.currentUser.userId) {
							this.user = { id: this.currentUser.userId, name: this.currentUser.firstname + ' ' + this.currentUser.lastname } as User;
						}
					});

					// console.log("Comment Messages")
					// console.log(this.commentMessages);


				},
				error: (err: HttpErrorResponse) => {
					console.error(err);
				}
			});

		}
	}

	public formatLocalTime(date: Date): string {
		const options = {
			hour: '2-digit',
			minute: '2-digit',
			//second: '2-digit',
			hour12: false
		} as DateFormatOptions;
		return date.toLocaleTimeString([], options);
	}

	public isCurrentUser(authorId: number): boolean {
		return authorId === this.currentUser.userId;
	}

	public sendMessage(e: SendMessageEvent): void {
		//console.log(e);
		if (e.message.text === "") {
			return;
		}

		// Assign message to WorkflowCommentForCreationDto object
		const workflowCommentForCreationDto = {} as WorkflowCommentForCreationDto;

		workflowCommentForCreationDto.workflowId = this.workflowId;
		workflowCommentForCreationDto.comment = e.message.text || "";



		this.apiWorkflowCommentService.addComment(workflowCommentForCreationDto).subscribe({
			next: (workflowComment: WorkflowComment) => {
				//this.commentMessages.push({
				this.commentCounts++;
				this.allComments.push(workflowComment);
				this.showCommentPopup = false;

				// Reload the comments
				this.fillCommentSection();
			},
			error: (err: HttpErrorResponse) => {
				console.error(err);
				this.showCommentPopup = false;
			}

		});

	}

	public closePopup(): void {
		this.showCommentPopup = false;
	}



	// == End of Comment Section ===============================================================================================

	/** Clicked on PDF rectangle -> focus input field */
	public setFieldFocus(fieldXbrlName: string, datasetId: number | null): void {

		let selectedForms = document.querySelectorAll('.shrink-border-active');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active', 'shrink-border');
		});
		selectedForms = document.querySelectorAll('.shrink-border-active-warning');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active-warning', 'shrink-border');
		});
		selectedForms = document.querySelectorAll('.shrink-border-active-info');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active-info', 'shrink-border');
		});

		// console.log('setFieldFocus: ' + fieldXbrlName + ' - ' + datasetId);
		if (fieldXbrlName == null || fieldXbrlName == '') {
			return;
		}

		// querySelector for an element, see Telerik Ticketnumber 1631776

		// Check exists xbrl name
		const xbrl = this.listsService.xbrlTypes.find((x) => x.name == fieldXbrlName);
		if (xbrl == null) {
			console.error('XBRL not found: ' + fieldXbrlName);
			return;
		}

		// Create the fieldId
		let fieldId = fieldXbrlName + '-' + datasetId;
		if (datasetId == null) {
			fieldId = fieldXbrlName;
		}

		//console.log("Searching for: " + fieldId);

		const isTaxDetermination = xbrl.name == 'document_taxDetermination';
		// Is it a dropdown or Checkbox (but not TaxDetermination)
		const fieldDropDown = this.listsService.dropdownLists.find((x) => x.name == fieldXbrlName);
		if (!isTaxDetermination && (fieldDropDown || xbrl.isBoolean)) {

			// this.showValidationMessagesPopup = false;
			const element = <HTMLElement>document.getElementById(fieldId);

			element?.focus();

			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			return;
		}

		// Datepicker + Numeric + Textbox
		const element = <HTMLElement>document.getElementById(fieldId)?.getElementsByClassName('k-input-inner')[0];
		if (element) {
			element?.focus();

			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
			return;
		}
		else {
			console.error('Element not found: ' + fieldId);
		}

	}

	/** Apply effects to a field when a validation message is clicked */
	public setFieldFocusAfterMessageClick(fieldXbrlName: string, type: string, datasetId: number | null): void {
		//Remove existng effects on other fields
		let selectedForms = document.querySelectorAll('.shrink-border-active');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active', 'shrink-border');
		});
		selectedForms = document.querySelectorAll('.shrink-border-active-warning');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active-warning', 'shrink-border');
		});
		selectedForms = document.querySelectorAll('.shrink-border-active-info');
		selectedForms.forEach((form) => {
			form.classList.replace('shrink-border-active-info', 'shrink-border');
		});
		// console.log('setFieldFocus: ' + fieldXbrlName + ' - ' + datasetId);
		if (fieldXbrlName == null || fieldXbrlName == '') {
			return;
		}

		// querySelector for an element, see Telerik Ticketnumber 1631776

		// Check exists xbrl name
		const xbrl = this.listsService.xbrlTypes.find((x) => x.name == fieldXbrlName);
		if (xbrl == null) {
			console.error('XBRL not found: ' + fieldXbrlName);
			return;
		}

		// Create the fieldId
		let fieldId = fieldXbrlName + '-' + datasetId;
		if (datasetId == null) {
			fieldId = fieldXbrlName;
		}

		//console.log("Searching for: " + fieldId);

		const isTaxDetermination = xbrl.name == 'document_taxDetermination';
		const isRadioButton = (xbrl.name == 'document_corporateIncomeTax_taxationBases') ||
			(xbrl.name == '⁠document_corporateIncomeTaxSaUControllingCompany_copyFor') ||
			(xbrl.name == 'document_assessmentDetermination') ||
			(xbrl.name == 'document_notificationType');
		// Is it a dropdown or Checkbox (but not TaxDetermination)
		const fieldDropDown = this.listsService.dropdownLists.find((x) => x.name == fieldXbrlName);
		// if (!isTaxDetermination && (fieldDropDown || xbrl.isBoolean)) {
		if (!isTaxDetermination && !isRadioButton && (fieldDropDown)) {
			// this.showValidationMessagesPopup = false;
			const element = <HTMLElement>document.getElementById(fieldId);

			// element?.focus();
			// element?.parentElement?.focus();

			this.elementHighlight(<HTMLElement>element.parentElement!.parentElement!.nextElementSibling!, type);
			return;
		}
		if ((!isTaxDetermination && (xbrl.isBoolean)) || isRadioButton) {

			const element = <HTMLElement>document.getElementById(fieldId);

			this.elementHighlight(<HTMLElement>element.nextElementSibling!.nextElementSibling!.nextElementSibling!, type);
			return;
		}

		// Datepicker + Numeric + Textbox
		const element = <HTMLElement>document.getElementById(fieldId)?.getElementsByClassName('k-input-inner')[0];
		if (element) {
			// element.focus();

			if (xbrl.isDateTime || isTaxDetermination) {

				// this.elementHighlight(element.parentElement!.parentElement!.parentElement!);
				this.elementHighlight(<HTMLElement>element.parentElement!.parentElement!.parentElement!.parentElement!.nextElementSibling!, type);
			} else {

				this.elementHighlight(<HTMLElement>element.parentElement!.parentElement!.parentElement!.nextElementSibling!, type);
			}
			return;
		}
		else {
			console.error('Element not found: ' + fieldId);
		}

	}

	/** Highlight an element after a validation message click*/
	public elementHighlight(element: HTMLElement, type: string): void {
		// console.log(element.firstElementChild!);
		//a placeholder, change for desired design
		//set new class


		// console.log(type);
		// console.log(newClass);
		// newClass = 'shrink-border-active ' + newClass;
		// element.firstElementChild!.setAttribute('class', newClass);
		if (type == 'warning') {

			element.classList.replace('shrink-border', 'shrink-border-active-warning');
		} else if (type == 'info') {

			element.classList.replace('shrink-border', 'shrink-border-active-info');
		} else {

			element.classList.replace('shrink-border', 'shrink-border-active');
		}
		// console.log(newClass);
		element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		// document.getElementById('wrapper' + datasetId + '-' + xbrl)!.firstElementChild!.setAttribute('class', newClass);
		//element.firstElementChild!.setAttribute('class', 'shrink-border-active');
		// element.style.border = "1px solid red";

	}


	// == Badget Validation Messages Section ===================================================================================

	// info: Variable with the validation messages: this.fieldValidations

	public validationMessagesCounts: number = 0;
	public validationMessagesToolTip: string = "";
	public validationMessagesBgColor: ButtonThemeColor = "success";
	public showValidationMessagesPopup: boolean = false;

	public updateValidationMessagesBadget(validationMessages: ValidationMessageItem[]): void {

		this.validationMessagesCounts = validationMessages?.length ?? 0;
		this.validationMessages = validationMessages;	// All messages

		this.validationMessages.sort((a, b) => (a.isError < b.isError) ? 1 : (a.isError === b.isError) ? ((a.isWarning < b.isWarning) ? 1 : (a.isWarning === b.isWarning) ? ((a.isInfo < b.isInfo) ? 1 : -1) : -1) : -1);
		this.setBackgroundColorValidation();
		this.createToolTipForValidationMessages();
	}

	public showValidationMessages(): void {

		if (this.showValidationMessagesPopup) {
			this.showValidationMessagesPopup = false;
		} else if (this.validationMessagesCounts != 0) {
			this.showValidationMessagesPopup = true;
		}

		// Close other popup
		if (this.showValidationMessagesPopup) {
			this.showReferenceMessagesPopup = false;
		}
	}

	public closePopupValidationMessages(): void {
		this.showValidationMessagesPopup = false;
	}


	@ViewChild("anchorValidationMessages", { read: ElementRef }) public anchorValidationMessages: ElementRef | undefined;
	@ViewChild("popupValidationMessages", { read: ElementRef }) public popupValidationMessages: ElementRef | undefined;


	/** Set the backgroundcolor in depend of the items */
	public setBackgroundColorValidation(): void {

		// contains validationMessagesBgColor items from type "error" => set to "error"
		if (this.validationMessages.some((x) => x.isError === true)) {
			this.validationMessagesBgColor = "error";
		} else if
			// contains validationMessagesBgColor items from type "warning" => set to "warning"
			(this.validationMessages.some((x) => x.isWarning === true)) {
			this.validationMessagesBgColor = "warning";
		} else if
			// contains validationMessagesBgColor items from type "info" => set to "info"
			(this.validationMessages.some((x) => x.isInfo === true)) {
			this.validationMessagesBgColor = "light";
		} else {
			this.validationMessagesBgColor = "success";		// grün
		}

		// this.validationMessagesBgColor = "success";		// grün
		// this.validationMessagesBgColor = "info";			// grau
		// this.validationMessagesBgColor = "warning";		// gelb, organge
		// this.validationMessagesBgColor = "error";		// rot
		// this.validationMessagesBgColor = "secondary";	// hell-grau
		// this.validationMessagesBgColor = "dark";			// grau
		// this.validationMessagesBgColor = "light";		// hell hell grau
		// this.validationMessagesBgColor = "inverse";		// transparent

	}

	public requestedByAdmin(): boolean {
		return this.dataService.requestedByAdmin();
	}

	public isUnlocked(): boolean {
		return this.dataService.GetAdminArchivUnlockFlag();
	}

	/** Build the content of the tooltip */
	public createToolTipForValidationMessages(): void {

		this.validationMessagesToolTip = "";
		return;

		// Show first 3 validation messages 
		// let maxValidationMessages = 3;

		// // compound the validation messages
		// for (let i = 0; i < maxValidationMessages; i++) {
		// 	if (this.validationMessages[i] != null) {

		// 		let preText = "";
		// 		if (this.validationMessages[i].isError) {
		// 			preText = "Fehler: ";
		// 		}
		// 		else if (this.validationMessages[i].isWarning) {
		// 			preText = "Warnung: ";
		// 		}
		// 		else if (this.validationMessages[i].isInfo) {
		// 			preText = "Info: ";
		// 		}

		// 		this.validationMessagesToolTip = this.validationMessagesToolTip + preText + this.validationMessages[i].messageDE + "\n\r\n\r";
		// 	}
		// }

		// // add the number of hidden validation messages
		// if (this.validationMessages.length > maxValidationMessages) {
		// 	if (this.validationMessages.length - maxValidationMessages === 1) {
		// 		this.validationMessagesToolTip = this.validationMessagesToolTip + "... und " + (this.validationMessages.length - maxValidationMessages) + " weitere Meldung.";
		// 	}
		// 	else {
		// 		this.validationMessagesToolTip = this.validationMessagesToolTip + "... und " + (this.validationMessages.length - maxValidationMessages) + " weitere Meldungen.";
		// 	}
		// }

	}




	// == End of Badget Validation Messages Section ===========================================================================





	// == Badget Validation Messages Section ===================================================================================

	// info: Variable with the validation messages: this.fieldValidations

	public referenceMessagesCounts: number = 0;
	public referenceMessagesToolTip: string = "";
	public referenceMessagesBgColor: ButtonThemeColor = "success";
	public showReferenceMessagesPopup: boolean = false;

	public updateReferenceMessagesBadget(referenceMessages: ValidationMessageItem[]): void {

		this.referenceMessagesCounts = referenceMessages?.length ?? 0;
		this.referenceMessages = referenceMessages;	// All messages
		this.referenceMessages.sort((a, b) => (a.isError < b.isError) ? 1 : (a.isError === b.isError) ? ((a.isWarning < b.isWarning) ? 1 : (a.isWarning === b.isWarning) ? ((a.isInfo < b.isInfo) ? 1 : -1) : -1) : -1);
		this.setBackgroundColorReference();
		this.createToolTipForReferenceMessages();
	}

	public showReferenceMessages(): void {

		if (this.showReferenceMessagesPopup) {
			this.showReferenceMessagesPopup = false;
		} else if (this.referenceMessagesCounts != 0) {
			this.showReferenceMessagesPopup = true;
		}

		// Close other popup
		if (this.showReferenceMessagesPopup) {
			this.showValidationMessagesPopup = false;
		}
	}

	public closePopupReferenceMessages(): void {
		this.showReferenceMessagesPopup = false;
	}


	@ViewChild("anchorReferenceMessages", { read: ElementRef }) public anchorReferenceMessages: ElementRef | undefined;
	@ViewChild("popupReferenceMessages", { read: ElementRef }) public popupReferenceessages: ElementRef | undefined;


	/** Set the backgroundcolor in depend of the items */
	public setBackgroundColorReference(): void {

		// contains referenceMessagesBgColor items from type "error" => set to "error"
		if (this.referenceMessages.some((x) => x.isError === true)) {
			this.referenceMessagesBgColor = "error";
		} else if
			// contains referenceMessagesBgColor items from type "warning" => set to "warning"
			(this.referenceMessages.some((x) => x.isWarning === true)) {
			this.referenceMessagesBgColor = "warning";
		} else if
			// contains referenceMessagesBgColor items from type "info" => set to "info"
			(this.referenceMessages.some((x) => x.isInfo === true)) {
			this.referenceMessagesBgColor = "light";
		} else {
			this.referenceMessagesBgColor = "success";		// grün
		}

		// this.referenceMessagesBgColor = "success";		// grün
		// this.referenceMessagesBgColor = "info";			// grau
		// this.referenceMessagesBgColor = "warning";		// gelb, organge
		// this.referenceMessagesBgColor = "error";		// rot
		// this.referenceMessagesBgColor = "secondary";	// hell-grau
		// this.referenceMessagesBgColor = "dark";			// grau
		// this.referenceMessagesBgColor = "light";		// hell hell grau
		// this.referenceMessagesBgColor = "inverse";		// transparent

	}

	/** Build the content of the tooltip */
	public createToolTipForReferenceMessages(): void {

		this.referenceMessagesToolTip = "";
		return;

		// Show first 3 reference messages 
		// let maxReferenceMessages = 3;

		// // compound the validation messages
		// for (let i = 0; i < maxReferenceMessages; i++) {
		// 	if (this.referenceMessages[i] != null) {

		// 		let preText = "";
		// 		if (this.referenceMessages[i].isError) {
		// 			preText = "Fehler: ";
		// 		}
		// 		else if (this.referenceMessages[i].isWarning) {
		// 			preText = "Warnung: ";
		// 		}
		// 		else if (this.referenceMessages[i].isInfo) {
		// 			preText = "Info: ";
		// 		}

		// 		this.referenceMessagesToolTip = this.referenceMessagesToolTip + preText + this.referenceMessages[i].messageDE + "\n\r\n\r";
		// 	}
		// }

		// // add the number of hidden reference messages
		// if (this.referenceMessages.length > maxReferenceMessages) {
		// 	if (this.referenceMessages.length - maxReferenceMessages === 1) {
		// 		this.referenceMessagesToolTip = this.referenceMessagesToolTip + "... und " + (this.referenceMessages.length - maxReferenceMessages) + " weitere Meldung.";
		// 	}
		// 	else {
		// 		this.referenceMessagesToolTip = this.referenceMessagesToolTip + "... und " + (this.referenceMessages.length - maxReferenceMessages) + " weitere Meldungen.";
		// 	}
		// }

	}




	// == End of Badget Reference Messages Section ===========================================================================	










	// == Badget Trade Tax Info Section ======================================================================================

	public tradeTaxAssessmentYears: string[] = [];
	private tradeTaxAssessmentYearFilter: number | null = null;

	public tradeTaxPayments: ITradeTaxInfoPaymentDto[] = [];
	public tradeTaxMaturity: ITradeTaxInfoMaturityDto[] = [];
	public tradeTaxInterests: ITradeTaxInfoInterestDto[] = [];
	public tradeTaxInterestsCalculation: ITradeTaxInfoInterestCalculatedDto[] = [];
	public interestRunsOldLaws: ITradeTaxInfoInterestRunDto[] = [];
	public interestRunsNewLaws: ITradeTaxInfoInterestRunDto[] = [];

	public showTradeTaxPopup: boolean = false;
	public tradeTaxPanelIdDisplay: number = 2;


	loadTradeTaxInfo(): void {
		this.apiUIViewerService.getAllTradeTaxInfo(this.workflowId).subscribe({
			next: (tradeTaxInfo: ITradeTaxInfoDto) => {
				this.tradeTaxPayments = tradeTaxInfo.paymentList;
				this.tradeTaxMaturity = tradeTaxInfo.maturityList;
				this.tradeTaxInterests = tradeTaxInfo.interestList;
				this.tradeTaxInterestsCalculation = tradeTaxInfo.interestCalculatedList;
				this.interestRunsOldLaws = tradeTaxInfo.interestRunsOldLawList;
				this.interestRunsNewLaws = tradeTaxInfo.interestRunsNewLawList;

				// Convert date properties
				this.tradeTaxPayments.forEach((x) => {
					if (x.notificationDate) {
						x.notificationDate = this.dateTimeService.convertUTCTimeToLocalTime(x.notificationDate);
					}

					if (x.tradeTaxAmountToPaidDate) {
						x.tradeTaxAmountToPaidDate = this.dateTimeService.convertUTCTimeToLocalTime(x.tradeTaxAmountToPaidDate);
					}
				});

				this.tradeTaxMaturity.forEach((x) => {
					if (x.notificationDate) {
						x.notificationDate = this.dateTimeService.convertUTCTimeToLocalTime(x.notificationDate);
					}

					if (x.tradeTaxAmountToPaidDate) {
						x.tradeTaxAmountToPaidDate = this.dateTimeService.convertUTCTimeToLocalTime(x.tradeTaxAmountToPaidDate);
					}
				});

				this.tradeTaxInterests.forEach((x) => {
					if (x.notificationDate) {
						x.notificationDate = this.dateTimeService.convertUTCTimeToLocalTime(x.notificationDate);
					}

					// if (x.begin) {
					// 	x.begin = this.dateTimeService.convertUTCTimeToLocalTime(x.begin);
					// }

					// if (x.end) {
					// 	x.end = this.dateTimeService.convertUTCTimeToLocalTime(x.end);
					// }
				});

				this.interestRunsOldLaws.forEach((x) => {
					if (x.assessmentDocumentDate) {
						x.assessmentDocumentDate = this.dateTimeService.convertUTCTimeToLocalTime(x.assessmentDocumentDate)!;
					}

					if (x.beginDate) {
						x.beginDate = this.dateTimeService.convertUTCTimeToLocalTime(x.beginDate)!;
					}
					if (x.endDate) {
						x.endDate = this.dateTimeService.convertUTCTimeToLocalTime(x.endDate)!;
					}
				});

				this.interestRunsNewLaws.forEach((x) => {
					if (x.assessmentDocumentDate) {
						x.assessmentDocumentDate = this.dateTimeService.convertUTCTimeToLocalTime(x.assessmentDocumentDate)!;
					}

					if (x.beginDate) {
						x.beginDate = this.dateTimeService.convertUTCTimeToLocalTime(x.beginDate)!;
					}
					if (x.endDate) {
						x.endDate = this.dateTimeService.convertUTCTimeToLocalTime(x.endDate)!;
					}
				});


				// Aggregate the years of tradeTaxPayments, tradeTaxMaturity and tradeTaxInterests
				this.tradeTaxAssessmentYears = [];
				this.tradeTaxPayments.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});
				this.tradeTaxMaturity.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});
				this.tradeTaxInterests.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});
				this.tradeTaxInterestsCalculation?.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});
				this.interestRunsOldLaws?.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});
				this.interestRunsNewLaws?.forEach((x) => {
					if (x.assessmentYear) {
						this.tradeTaxAssessmentYears.push(x.assessmentYear.toString());
					}
				});

				// Remove duplicates in tradeTaxAssessmentYears
				this.tradeTaxAssessmentYears = this.tradeTaxAssessmentYears.filter((x, i, a) => a.indexOf(x) == i);

				// Sort tradeTaxAssessmentYears
				this.tradeTaxAssessmentYears.sort((a, b) => (a < b) ? 1 : -1);

				// Add null Value
				this.tradeTaxAssessmentYears.unshift("Zurücksetzen");


				if (this.tradeTaxAssessmentYearFilter != null) {
					// Renove all items from tradeTaxPayments, tradeTaxMaturity and tradeTaxInterests where assessmentYear != tradeTaxAssessmentYearFilter
					this.tradeTaxPayments = this.tradeTaxPayments.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);
					this.tradeTaxMaturity = this.tradeTaxMaturity.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);
					this.tradeTaxInterests = this.tradeTaxInterests.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);
					this.tradeTaxInterestsCalculation = this.tradeTaxInterestsCalculation.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);
					this.interestRunsOldLaws = this.interestRunsOldLaws.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);
					this.interestRunsNewLaws = this.interestRunsNewLaws.filter((x) => x.assessmentYear == this.tradeTaxAssessmentYearFilter);

				}

				// Sort tradeTaxPayments, tradeTaxMaturity and tradeTaxInterests by notificationDate, if notificationDate not null
				this.tradeTaxPayments.sort((a, b) => (a.notificationDate && b.notificationDate) ? ((a.notificationDate < b.notificationDate) ? 1 : -1) : 0);
				this.tradeTaxMaturity.sort((a, b) => (a.notificationDate && b.notificationDate) ? ((a.notificationDate < b.notificationDate) ? 1 : -1) : 0);
				this.tradeTaxInterests.sort((a, b) => (a.notificationDate && b.notificationDate) ? ((a.notificationDate < b.notificationDate) ? 1 : -1) : 0);

				// Sort tradeTaxInterestsCalculation by assessmentYear, if assessmentYear not null
				this.tradeTaxInterestsCalculation.sort((a, b) => (a.assessmentYear && b.assessmentYear) ? ((a.assessmentYear < b.assessmentYear) ? 1 : -1) : 0);
				this.interestRunsOldLaws.sort((a, b) => (a.assessmentYear && b.assessmentYear) ? ((a.assessmentYear < b.assessmentYear) ? 1 : -1) : 0);
				this.interestRunsNewLaws.sort((a, b) => (a.assessmentYear && b.assessmentYear) ? ((a.assessmentYear < b.assessmentYear) ? 1 : -1) : 0);




			},
			error: (err: HttpErrorResponse) => {
				console.error(err);
			}
		});

	}


	public showTradeTax(): void {
		this.showTradeTaxPopup = !this.showTradeTaxPopup;


	}

	public closePopupTradeTax(): void {
		this.showTradeTaxPopup = false;
	}

	public onTradeTaxPanel(panelId: number): void {
		this.tradeTaxPanelIdDisplay = panelId;
	}

	public onTradeTaxAssessmentYearsChange(e: string): void {
		// Check ist e a number
		if (isNaN(parseInt(e))) {
			this.tradeTaxAssessmentYearFilter = null;

		} else {

			this.tradeTaxAssessmentYearFilter = parseInt(e);
		}
		this.loadTradeTaxInfo();

	}

	transformDate(date: Date | null): string | null {
		if (date == null) {
			return '';
		}
		return this.datePipe.transform(date, 'dd.MM.yyyy');
	}

	@ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

	public showTooltip(e: MouseEvent): void {
		const element = e.target as HTMLElement;
		if ((element.nodeName === 'TD' || element.className === 'k-column-title')
			&& element.offsetWidth < element.scrollWidth) {
			this.tooltipDir.toggle(element);
		} else {
			this.tooltipDir.hide();
		}
	}




	// == End of Badget Trade Tax Info Section ===============================================================================

	// == Notification ===================================================================

	public displayNotification(notification: INotificationModel): void {
		if (notification.isSuccess) {
			this.notificationService.showSuccess(notification.message);
		} else if (notification.isWarning) {
			this.notificationService.showWarning(notification.message);
		} else {
			this.notificationService.showError(notification.message);
		}
	}

}



