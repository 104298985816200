<div class="stareTopic">
	{{ 'APP.ADMINISTRATION.USER_MANAGEMENT.TITLE' | translate }}
</div>



<div>
	<ng-template #toolTipGridTemplate let-anchor>
		<span>{{ anchor.nativeElement.innerText }}</span>
	</ng-template>
	<div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
		(mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
		[ngStyle]="{'height.px': pageHeight}">
		<kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
			filterable="menu" [loading]="!finishedLoading" (dblclick)="editHandler(grid.activeRow)"
			(cellClick)="cellClickHandler($event)" (add)="addHandler()" [sort]="currentSort"
			(sortChange)="sortChange($event)" [filter]="currentFilter || {logic: 'and', filters: []}"
			[selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
			[pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true">

			<ng-template kendoGridToolbarTemplate>
				<kendo-textbox [placeholder]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.SEARCH' | translate"
					(valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
				<button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
					title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

				</button>
				<kendo-grid-spacer></kendo-grid-spacer>
				<button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
					title="{{ 'BUTTON.ADD' | translate }}">
				</button>
				&nbsp;|&nbsp;



				<button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
					title="{{ 'BUTTON.UPDATE' | translate }}">
				</button>
				<button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
					title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

				</button>

			</ng-template>

			<kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.PAGER.PAGE' | translate"
				[pagerOf]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.PAGER.OF' | translate"
				[pagerItems]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.PAGER.ITEM' | translate"
				[pagerItemsPerPage]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.PAGER.TASK_PER_PAGE' | translate"
				[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
				[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
				[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
				[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
				[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
				[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
				[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
				[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
				[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
				[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
				[filterClearButton]="'BUTTON.CLEAR' | translate"
				[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
				[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
				[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
				[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
				[filterFilterButton]="'BUTTON.FILTER' | translate"
				[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
				[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
				[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
				[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
				[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
				[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
				[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
				[filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
				[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
				[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
				[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
				[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
				[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
				[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
				[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
				[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
				[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
				[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
			</kendo-grid-messages>



			<kendo-grid-column field="id" [title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.ID' | translate"
				[hidden]="true"></kendo-grid-column>
			<kendo-grid-column field="employeeNumber"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.EMPLOYEE_NUMBER' | translate"></kendo-grid-column>
			<kendo-grid-column field="mail"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.MAIL' | translate"></kendo-grid-column>
			<kendo-grid-column field="firstname"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.FIRSTNAME' | translate"></kendo-grid-column>
			<kendo-grid-column field="lastname"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.LASTNAME' | translate"></kendo-grid-column>
			<kendo-grid-column field="isAdmin" filter="boolean" type="boolean"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.IS_ADMIN' | translate"><ng-template
					kendoGridCellTemplate let-dataItem>{{ dataItem.isAdmin ? "ja" : "nein"
					}}</ng-template></kendo-grid-column>
			<kendo-grid-column field="canManageCommuneTaxRates" filter="boolean" type="boolean"
				[title]="'APP.ADMINISTRATION.USER_MANAGEMENT.TABLE.HEADER.CAN_MANAGECOMMUNETAXRATES' | translate"><ng-template
					kendoGridCellTemplate let-dataItem>{{ dataItem.canManageCommuneTaxRates ? "ja" : "nein"
					}}</ng-template></kendo-grid-column>



			<ng-template kendoGridNoRecordsTemplate>

				<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
			</ng-template>

			<kendo-grid-excel fileName="Benutzer.xlsx" [fetchData]="allData"></kendo-grid-excel>

		</kendo-grid>
	</div>
</div>


<kendo-dialog *ngIf="active" [width]="500" [height]="500" (close)="closeForm()">
	<kendo-dialog-titlebar *ngIf="isNew">
		{{'BUTTON.ADD' | translate }}
	</kendo-dialog-titlebar>

	<kendo-dialog-titlebar *ngIf="!isNew">
		{{'BUTTON.EDIT' | translate}}
	</kendo-dialog-titlebar>

	<form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
		<kendo-formfield>
			<kendo-label [for]="employeeNumber"
				[text]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.EMPLOYEE_NUMBER.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #employeeNumber formControlName="employeeNumber" [min]="111111" [max]="9999999999"
				[format]="'#'" [decimals]="0" [spinners]="false"
				[placeholder]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.EMPLOYEE_NUMBER.LABEL' | translate"
				[readonly]="!isNew" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.EMPLOYEE_NUMBER.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="mail"
				[text]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.MAIL.LABEL' | translate"></kendo-label>
			<kendo-textbox #mail formControlName="mail"
				[placeholder]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.MAIL.LABEL' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.MAIL.ERROR.PATTERN' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<div class="form-row">
			<kendo-formfield>
				<kendo-label [for]="firstname"
					[text]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.FIRSTNAME.LABEL' | translate"></kendo-label>
				<kendo-textbox #firstname formControlName="firstname"
					[placeholder]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.FIRSTNAME.LABEL' | translate"
					required></kendo-textbox>
				<kendo-formerror>{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.FIRSTNAME.ERROR.REQUIRED' |
					translate}}</kendo-formerror>
			</kendo-formfield>

			<kendo-formfield>
				<kendo-label [for]="lastname"
					[text]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.LASTNAME.LABEL' | translate"></kendo-label>
				<kendo-textbox #lastname formControlName="lastname"
					[placeholder]="'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.LASTNAME.LABEL' | translate"
					required></kendo-textbox>
				<kendo-formerror>{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.LASTNAME.ERROR.REQUIRED' |
					translate}}</kendo-formerror>
			</kendo-formfield>
		</div>
		<div class="form-row">
			<kendo-formfield>
				<ng-container>
					<span class="k-checkbox-wrap">
						<input id="isAdmin" type="checkbox" kendoCheckBox formControlName="isAdmin" />
					</span>

					<label [labelClass]="false" class="k-checkbox-label"
						for="isAdmin">{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.IS_ADMIN.LABEL' |
						translate}}</label>
				</ng-container>
			</kendo-formfield>
			<kendo-formfield>
				<ng-container>
					<span class="k-checkbox-wrap">
						<input id="canManageCommuneTaxRates" type="checkbox" kendoCheckBox
							formControlName="canManageCommuneTaxRates" />
					</span>

					<label [labelClass]="false" class="k-checkbox-label"
						for="canManageCommuneTaxRates">{{'APP.ADMINISTRATION.USER_MANAGEMENT.MODAL.ADD_USER.CAN_MANAGECOMMUNETAXRATES.LABEL'
						|
						translate}}</label>
				</ng-container>
			</kendo-formfield>
		</div>
	</form>

	<kendo-dialog-actions layout="start">
		<button kendoButton themeColor="primary" [disabled]="isNew" (click)="onResetClientEngagement($event)"
			[svgIcon]="reloadSVG" kendoTooltip
			title="Setzt das zuletzt gewählte Engagement zurück, so dass bei einem erneuten Login das erste zugriffsberechtigte Engagement gewählt wird. Dadurch können Login-Probleme von bereits gelöschten Engagements umgangen werden.">

		</button>
		&nbsp;|&nbsp;
		<button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
			{{ 'BUTTON.DELETE' | translate }}
		</button>
		<button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
			{{ 'BUTTON.SAVE' | translate }}
		</button>
		<button kendoButton (click)="onCancel($event)">
			{{ 'BUTTON.CANCEL' | translate }}
		</button>
	</kendo-dialog-actions>
</kendo-dialog>