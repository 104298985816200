import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ENVIRONMENT } from '../../environments/environment';
import { Router } from '@angular/router';
import { UserLocal } from '../_models/user.model';
import * as FileSaver from 'file-saver';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { ApiFileService } from 'app/_services/StareApi/file.service';

/*
 TEST:
https://localhost:4200/download?c=77793B05-41F8-43F1-8AA8-2E98B8B5C880&f=b76b8c52-6fde-4624-a8d6-08fd73f02a4b

Process:
Wenn requestClientEngagement identisch mit dem User-LocalStorage ist, dann wird der Download direkt gestartet.
Ansonsten wird ein neuer Token angefordert und der Download gestartet.

*/

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  standalone: false
})
export class DownloadComponent implements OnInit {

  public requestClientEngagement: string = '';
  public requestFileId: string = '';
  public myuser: UserLocal = {} as UserLocal;
  downloadSuccess: boolean = true;
  errorMessage: string = '';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiAuthService: ApiAuthentificationService,
    private notificationService: WrapperNotificationService,
    private fileservice: ApiFileService
  ) { }

  ngOnInit(): void {

    const jsonStr = localStorage.getItem('user') || '';
    const jsonObj = JSON.parse(jsonStr);
    this.myuser = jsonObj as UserLocal;

    this.route.queryParams.subscribe(async params => {
      this.requestClientEngagement = params['c'];
      this.requestFileId = params['f'];

      console.info(this.requestClientEngagement + "---" + this.requestFileId);

      if (this.requestClientEngagement.toLocaleLowerCase() == this.myuser.clientEngagementKey.toLocaleLowerCase()) {
        // Send file to client

        this.fileservice.downloadPdf(this.requestFileId).subscribe(res => {

          FileSaver.saveAs(res.blob, res.filename);
          this.notificationService.showSuccess('Die Datei wurde erfolgreich heruntergeladen');

        });
      }
      /*  else {
         // Save old values
         let oldToken = localStorage.getItem('stare_token') || '';
         let oldUser = this.getUser();
 
 
 
         // Get new token
         this.getNewToken(this.requestClientEngagement).subscribe({
           next: (res: UserToken) => {
             localStorage.setItem('stare_token', res.token);
 
             // Get File
             // Send file to client
             this.apiFile(this.requestFileId).subscribe({
               next: (res: FileItem) => {
                 var fileAsBlob = this.base64toBlob(res.contentAsBase64!, res.mimeType!);
                 this.errorMessage = '';
                 this.downloadSuccess = true;
 
                 FileSaver.saveAs(fileAsBlob, res.filename);
                 localStorage.setItem('stare_token', oldToken);
 
                 if (oldUser != null) {
                   this.getNewToken(oldUser.clientEngagementKey);
                   // TODO: Hier auf den neuen Token warten und dann erst das Fenster schließen
                 }
                 window.self.close();
               }
               ,
               error: (err: HttpErrorResponse) => {
                 console.error(err);
                 localStorage.setItem('stare_token', oldToken);
 
                 this.errorMessage = 'Unbekannter Fehler';
                 this.downloadSuccess = false;
 
                 if (err.status == 400 || err.status == 410) {
                   this.errorMessage = 'Die Datei wurde nicht gefunden.';
                 }
               }
             });
 
 
 
 
           },
           error: (err: HttpErrorResponse) => {
             console.error(err);
             localStorage.setItem('stare_token', oldToken);
           },
         })
       }  */
      else {
        //this.router.navigate(['/tasks']);
        //save url and redirect to login
        let url = this.router.url;
        url = ENVIRONMENT.primaryDomain + url;
        console.log('url:' + url);

        this.notificationService.showSuccess('Sie werden umgeleitet. Bitte warten Sie.');
        this.apiAuthService.setRedirection(url, this.requestClientEngagement.toLocaleLowerCase());
        //window.location.href = ENVIRONMENT.apiBaseUrl + 'Auth/Login';

        this.router.navigate(['/login']);
        return;
      }// from else

    });

  }

  getUser(): UserLocal | null {

    const jsonStr = localStorage.getItem('user') || null;
    if (jsonStr == null) {
      return null;
    }
    const jsonObj = JSON.parse(jsonStr);
    const myuser = jsonObj as UserLocal;

    return myuser;
  }


}
