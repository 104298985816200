import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-trade-tax-loss-carry-forward1300',
  templateUrl: './doc-trade-tax-loss-carry-forward1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocTradeTaxLossCarryForward1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.tradeTaxLossCarryForwardForm1300;
  }

  // public documentTradeTaxMitunternehmendePayloadLength: number = 0;




  //#region Form ======================================================================================================


  /** Gewerbesteuer Verlustvortrag, Rechtsmittel-Check, State 1300 */
  public tradeTaxLossCarryForwardForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    tradeTax_lossCarryForward: new UntypedFormControl(""), //reference 
    document_tradeTax_lossCarryForward: new UntypedFormControl(""),
    document_tradeTax_p165: new UntypedFormControl(""),
    document_tradeTax_lossCarryForwardAmountLinkedToContinuation: new UntypedFormControl(""),
    tradeTax_lossCarryForwardAmountLinkedToContinuation: new UntypedFormControl(""),
    document_tradeTax_lossCarryForwardPartnerAmountSum: new UntypedFormControl(""),
    documentPayload: new UntypedFormArray([]),

    document_auditResult: new UntypedFormControl(""),
    //  document_hasThresholdLimit : new UntypedFormControl(""), //Task 26418
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    document_missingBankAccountDetails: new UntypedFormControl(""),
  });
  //#endregion

  /** creates payloads for GewStVV and sets the datasetId */
  createMitunternehmendePayload(datasetId: number): UntypedFormGroup {

    const verlustPayload = new UntypedFormGroup({

      id_companyIdentifier: new UntypedFormControl(""),
      document_tradeTax_lossCarryForwardPartnerAmount: new UntypedFormControl(""),
      tradeTax_lossCarryForwardPartnerAmount: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true })

    });
    console.log("Adding non empty payload");
    console.log(datasetId);
    return verlustPayload;
  }



  //initializes the payload form array
  //switch case for diff datatypes
  addSingularPayload(payload: DatasetItem): void {
    const payloadArray = this.documentPayloadGetter();

    this.documentPayloadArrayWithDatasetId.push(this.createMitunternehmendePayload(payload.id));
    //this.documentPayloadGetter().push(this.createGUEPayload(payload.id));
    payloadArray.push(this.createMitunternehmendePayload(payload.id));
    // console.info("Add" + payload.dataTypeName);
    // this.documentTradeTaxMitunternehmendePayloadLength = this.documentTradeTaxMitunternehmendePayloadLength + 330;


  }


  addPayload(dataType: string): void {
    const payloadArray = this.documentPayloadGetter();

    this.addPayloadAsync(dataType, this.workflowId).subscribe({
      next: (data) => {
        const newPayload = data as FormFieldItem[];
        const newDatasetID = newPayload[0].datasetId;
        if (newDatasetID != null) {
          payloadArray.push(this.createMitunternehmendePayload(newDatasetID));
          // this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
          this.documentPayloadDatasetIdArray.push(newDatasetID);
          // this.documentTradeTaxMitunternehmendePayloadLength = this.documentTradeTaxMitunternehmendePayloadLength + 330;
        }
      },
      error: (err: HttpErrorResponse) => {
        // this.showError('Fehler bei Hinzufügen: ' + err.message)
        console.error(err);
      }

    });

  }



}
