<div class="stareTopic">
	{{ 'APP.QUALITY_CHECK.TITLE' | translate }}
</div>

<ng-template #toolTipGridTemplate let-anchor>
	<span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
	(mouseover)="showTooltip($event)" [ngStyle]="{'height.px': pageHeight}">
	<kendo-grid #grid [kendoGridBinding]="tableTasksDataFiltered" scrollable="scrollable" [style.height]="'100%'"
		filterable="menu" [filter]="currentFilter || {logic: 'and', filters: []}" [loading]="!finishedLoading"
		[selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true" [navigable]="true" [reorderable]="true" [resizable]="true"
		[size]="smallSize">

		<ng-template kendoGridToolbarTemplate>
			<kendo-textbox [placeholder]=" 'APP.QUALITY_CHECK.TABLE.SEARCH' | translate "
				(valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
			<button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
				title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

			</button>
			<kendo-grid-spacer></kendo-grid-spacer>

			<button kendoButton (click)="requestNewQualityCheck()" type="button" [svgIcon]="updateSVG" kendoTooltip
				title="{{ 'BUTTON.QUALITY_CHECK_UPDATE' | translate }}">
			</button>
			&nbsp;|&nbsp;


			<button kendoButton (click)="loadTasks()" type="button" [svgIcon]="reloadSVG" kendoTooltip
				title="{{ 'BUTTON.UPDATE' | translate }}">
			</button>


		</ng-template>

		<kendo-grid-messages [pagerPage]="'APP.QUALITY_CHECK.TABLE.PAGER.PAGE' | translate"
			[pagerOf]="'APP.QUALITY_CHECK.TABLE.PAGER.OF' | translate"
			[pagerItems]="'APP.QUALITY_CHECK.TABLE.PAGER.ITEM' | translate"
			[pagerItemsPerPage]="'APP.QUALITY_CHECK.TABLE.PAGER.TASK_PER_PAGE' | translate"
			[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
			[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
			[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
			[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
			[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
			[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
			[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
			[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
			[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
			[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate" [filterClearButton]="'BUTTON.CLEAR' | translate"
			[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
			[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
			[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
			[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
			[filterFilterButton]="'BUTTON.FILTER' | translate"
			[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
			[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
			[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
			[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
			[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
			[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
			[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate" [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
			[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
			[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
			[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
			[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
			[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
			[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
			[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
			[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
			[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
			[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
		</kendo-grid-messages>

		<kendo-grid-column field="id" [title]=" 'APP.QUALITY_CHECK.TABLE.HEADER.ID' | translate "
			[hidden]="false"></kendo-grid-column>
		<kendo-grid-column field="createdDate" filter="date"
			[title]=" 'APP.QUALITY_CHECK.TABLE.HEADER.CREATE_DATE' | translate" [hidden]="false"
			format="dd.MM.yyyy"></kendo-grid-column>

		<kendo-grid-column field="qualityCheckTypeDisplayName"
			[title]=" 'APP.QUALITY_CHECK.TABLE.HEADER.QUALITY_CHECK_TYPE' | translate ">
		</kendo-grid-column>
		<kendo-grid-column field="qualityCheckResult" [filterable]="false"
			[title]=" 'APP.QUALITY_CHECK.TABLE.HEADER.QUALITY_CHECK_RESULT' | translate ">
			<ng-template kendoGridCellTemplate let-dataItem let-qualityCheckResult>
				<button kendoButton size="small" [svgIcon]="getQualityCheckButtonSVG(dataItem.qualityCheckResult)"
					[themeColor]="getQualityCheckButtonColor(dataItem.qualityCheckResult)" type="button" rounded=full
					kendoTooltip position="right" [title]="getQualityCheckTooltip(dataItem.qualityCheckResult)">
				</button></ng-template>
		</kendo-grid-column>
		<kendo-grid-column field="message" [title]=" 'APP.QUALITY_CHECK.TABLE.HEADER.MESSAGE' | translate "
			[hidden]="false"></kendo-grid-column>


		<ng-template kendoGridNoRecordsTemplate>

			<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
		</ng-template>


	</kendo-grid>
</div>