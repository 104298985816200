export interface DropDownListItem {
    labelEN: string;
    labelDE: string;
    value?: number;
    isDefault: boolean;
    isDeleted: boolean;
}

export interface DropDownListItemWithDisabledItem {
    labelEN: string;
    labelDE: string;
    value: number;
    isDisabled: boolean;
}

export interface KendoDropDownItem {
    text: string;
    value: number;
    employeeNumber: string;

}

export interface DropDownBooleanItem {
    labelEN: string;
    labelDE: string;
    value: boolean;
    isDefault: boolean;
    isDeleted: boolean;
}

export class DropDownListItemClass {
    // map to IData
    public static NewInstance(data: DropDownListItem): DropDownListItem {
        // if (!data) {
        //     return null;
        // }
        return {
            value: data.value,
            labelDE: data.labelDE,
            labelEN: data.labelEN,
            isDefault: data.isDefault,
            isDeleted: data.isDeleted,
        };
    }
    public static NewInstances(data: DropDownListItem[]): DropDownListItem[] {
        return data.map(DropDownListItemClass.NewInstance);
    }
}