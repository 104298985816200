import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
	standalone: false
})
export class PageNotFoundComponent implements OnInit {

	//Variables for Translation
	public pageNotFoundTitle: string = "Seite nicht gefunden";
	public backButton: string = "Benutzer Verwaltung";
	//Variables for Translation

	constructor(private titleService: Title, private translateService: TranslateService, private location: Location) { }

	ngOnInit(): void {
		this.translateService.get('ERROR.PAGE-NOT-FOUND.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});

	}

	previousPage(): void {
		this.location.back();
	}

}
