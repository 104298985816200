import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Collision } from '@progress/kendo-angular-popup';
import { WorkflowTriggerDropdownItem } from 'app/_models/workflow-trigger.model';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';

@Component({
  selector: 'app-workflow-split-button',
  templateUrl: './workflow-split-button.component.html',
  standalone: false
})
export class WorkflowSplitButtonComponent implements OnInit {
  @Input() workflowId: number = 0;
  @Input() actualStateId: number = 0;

  @Input() buttonText: string = '';
  @Input() disabled: boolean = false;
  // emit boolean to parent component for form loading
  @Output() action = new EventEmitter<boolean>();

  @Output() onMainButtonClick = new EventEmitter();

  execWorkflowButtonData: WorkflowTriggerDropdownItem[] = [
    {
      name: "Default", disabled: false, triggerId: -1, default: false, click: (): void => { }
    }];

  public collision: Collision = { horizontal: "flip", vertical: "fit" };

  public mainBUttonText = '';


  constructor(
    private notificationService: WrapperNotificationService,
    private apiWorkflowService: ApiWorkflowService,
    private router: Router,
  ) { }
  ngOnInit(): void {

    this.mainBUttonText = this.buttonText;

    this.buildWorkflowTriggerList(this.workflowId);
  }
  /** Build the "Next possible Trigger" */
  private buildWorkflowTriggerList(id: number): void {

    this.apiWorkflowService.getNextTrigger(id).subscribe({
      next: (data) => {
        this.execWorkflowButtonData = data.map((x) => {
          if (x.triggerId == 120 && this.actualStateId == 8000) {
            return {
              triggerId: x.triggerId, name: "Löschen", disabled: false, default: x.default, click: (): void => {

                // console.log("Trigger : " + x.triggerId + " Name: " + x.description);

                // Execute trigger (without saving and without validation)
                this.execWorkflowTrigger(x.triggerId);

              }
            }
          } else {
            return {
              triggerId: x.triggerId, name: x.description, disabled: false, default: x.default, click: (): void => {

                // console.log("Trigger : " + x.triggerId + " Name: " + x.description);

                // Execute trigger (without saving and without validation)
                this.execWorkflowTrigger(x.triggerId);

              }
            }
          }
        });


        // console.log(this.execWorkflowButtonData);
      }
    });

  }
  execWorkflowTrigger(triggerId: number): void {
    this.action.emit(true);  // start loading form
    // Execute trigger (without saving and without validation)
    this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, triggerId).subscribe({
      next: (data) => {
        this.action.emit(false); // stop loading form
        this.notificationService.showSuccess('Die ausgewählte Option ist abgeschlossen.Sie werden zur Übersicht weitergeleitet');
        this.router.navigate(['/tasks']);
      },
      error: (err: HttpErrorResponse) => {

        this.action.emit(false); // stop loading form
        if (err.status == 400) {
          this.notificationService.showError('Fehler beim Ausführen des Workflows: ' + err.message);
        } else if (err.status == 403) {
          this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument ist mit einer Mail verknüpft, die abgesendet wurde oder noch bearbeitet wird.');
        } else if (err.status == 405) {
          this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
        } else if (err.status == 406) {
          this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
        } else if (err.status == 409) {
          this.notificationService.showError('Fehler im Prozess. Validierungsfehler prüfen.');
        } else if (err.status == 410) {
          this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
        } else if (err.status == 411) {
          this.notificationService.showError('Wiedereröffnung nicht möglich: Dokument wurde bereits per E-Mail versendet.');
        } else if (err.status == 416) {
          this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
        } else if (err.status == 422) {
          this.notificationService.showError('Interner Fehler beim Speichern der Zahlungen');
        } else if (err.status == 424) {
          this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
        } else {
          this.notificationService.showError('Interner Fehler beim Ausführen des Workflows: ' + err.message);
        }

      },
      complete: () => {
        //this.action.emit(false); 
      }

    });
  }

  onButtonClick(): void {
    let trigger = this.execWorkflowButtonData.find(x => x.default == true)?.triggerId;
    this.onMainButtonClick.emit(trigger);
  }

  onItemClick(item: any): void {
    this.action.emit(item);
  }
}
