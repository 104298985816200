import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { TaxOfficeItem, TaxOfficeItemForCreation } from 'app/_models/tax-office.model';
import { ApiTaxOfficeService } from 'app/_services/StareApiMaintenance/tax-office.service';

import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';

@Component({
  selector: 'app-admin-taxoffice',
  templateUrl: './admin-taxoffice.component.html',
  styleUrls: ['./admin-taxoffice.component.scss'],
  standalone: false
})


export class AdminTaxOfficeComponent {

  /** Displayed (filtered) data */
  public tableData = [] as TaxOfficeItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as TaxOfficeItem[];
  /** API request is finished */
  public finishedLoading = false;

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;


  // ==============================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiTaxOfficeService: ApiTaxOfficeService,
    private notificationService: WrapperNotificationService,
    public tooltipService: TooltipService
  ) { this.allData = this.allData.bind(this); }


  ngOnInit(): void {
    this.translateService.get('APP.ADMINISTRATION.TAXOFFICE.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    this.loadData();
  }

  // == Resize Listener =============================================================================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load grid data ===================================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiTaxOfficeService.getAllTaxOffices().subscribe({
      next: (res: TaxOfficeItem[]) => {
        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });

  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;


  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "bufaNumber",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // == Excel Export ==============================================================================
  // Compounding data for Export to Excel
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.tableData, {
        filter: this.currentFilter,
        sort: this.currentSort,
      }).data,
    };

    return result;
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false }) grid: any;


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    let filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {

      // Get all displayed columns from kendo grid
      let columns = [];
      columns = this.grid.columns.toArray();


      // Convert columns to filter object
      columns.forEach((column: { field: any; }) => {
        if ((column.field != undefined)) {

          // Exclude isAdmin (boolean) column from global filter
          if ((column.field != "createdDate") &&
            (column.field != "updatedDate")) {

            filter.filters.push({
              field: column.field,
              operator: "contains",
              value: value,
            });
          }
        }
      });
    }

    this.currentFilter = filter;
  }

  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }

  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog: For adding */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  public currentTaxOfficeId: number = -1;

  // == Forms ================================================================================

  /** For add */
  public editForm: FormGroup = new FormGroup({
    bufaNumber: new FormControl(-1),
    nameDE: new FormControl(""),
    nameEN: new FormControl("")
  });

  /** For Update */
  public updateForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    tenantSource: new FormControl(1, Validators.required),
    exportToDatalake: new FormControl(false),
  });

  // == Handlers ==========================================================================================

  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.currentTaxOfficeId = args.dataItem.id;
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.currentTaxOfficeId = -1;
  }

  public closeForm(): void {
    this.active = false;

  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations ======================================================================


  public onSave(e: PointerEvent): void {
    e.preventDefault();
    if (this.isNew) {

      // Map clientEngagementForCreationItem from form
      let taxOfficeForCreationItem = {} as TaxOfficeItemForCreation;

      taxOfficeForCreationItem = {
        bufaNumber: this.editForm.value.bufaNumber,
        nameDE: this.editForm.value.nameDE,
        nameEN: this.editForm.value.nameEN
      };

      //console.log(userAccessForCreationItem);

      this.apiTaxOfficeService.addTaxOffice(taxOfficeForCreationItem).subscribe({
        next: (res: TaxOfficeItem) => {
          this.notificationService.showSuccess('Das Finanzamt wurde erfolgreich eingetragen.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Das Finanzamt existiert bereits und konnte nicht (erneut) angelegt werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Anlegen eines Finanzamtes.');
          } else if (err.status == 410) {
            this.notificationService.showError('Das Finanzamt konnte nicht gefunden werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });
    } else {
      // Update
      let taxOfficeForUpdateItem = {} as TaxOfficeItemForCreation;

      taxOfficeForUpdateItem = {
        bufaNumber: this.editForm.value.bufaNumber,
        nameDE: this.editForm.value.nameDE,
        nameEN: this.editForm.value.nameEN
      };
      // console.log(taxOfficeForUpdateItem);

      // console.log(this.currentTaxOfficeId);
      this.apiTaxOfficeService.updateTaxOffice(this.currentTaxOfficeId, taxOfficeForUpdateItem).subscribe({
        next: (res: TaxOfficeItem) => {
          this.notificationService.showSuccess('Das Finanzamt wurde erfolgreich aktualisiert.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Das Finanzamt kann nicht geändert werden.');
          } else if (err.status == 403) {
            this.notificationService.showError('Es bestehen keine Rechte zum Ändern eines Finanzamtes.');
          } else if (err.status == 410) {
            this.notificationService.showError('Der Anwender oder das Engagement konnte nicht gefunden werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.currentTaxOfficeId == -1) {
      return;
    }

    this.apiTaxOfficeService.deleteTaxOffice(this.currentTaxOfficeId).subscribe({
      next: (res: TaxOfficeItem) => {
        this.notificationService.showSuccess('Die Löschung wurde beauftragt und dauert einige Minuten. Bitte prüfe die Liste in Kürze.');
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 403) {
          this.notificationService.showError('Es bestehen keine Rechte zum Löschen eines Finanzamtes.');
        } else if (err.status == 406) {
          this.notificationService.showError('Das zu löschendes Finanzamt darf nicht das Aktive sein.');
        } else if (err.status == 410) {
          this.notificationService.showError('Das Finanzamt wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde es bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeForm();
  }

}
