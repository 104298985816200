
import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class WrapperNotificationService {

  constructor(private notificationService: NotificationService) { }

  public showSuccess(msg: string): void {
    this.notificationService.show({
      content: msg,
      hideAfter: 4000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "success", icon: true },
    });
  }
  public showError(errorMsg: string): void {
    this.notificationService.show({
      content: errorMsg,
      hideAfter: 8000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "error", icon: true },
    });
  }

  public showWarning(errorMsg: string, hideSeconds: number = 3): void {
    this.notificationService.show({
      content: errorMsg,
      hideAfter: hideSeconds * 1000,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 400 },
      type: { style: "warning", icon: true },
    });
  }



}
