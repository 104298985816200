import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
    selector: 'app-doc-trade-tax-base1300',
    templateUrl: './doc-trade-tax-base1300.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocTradeTaxBase1300Component extends DocBaseComponent {
    constructor(
        listsService: ListsService,

        apiUIViewerService: UIViewerService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.tradeTaxBaseAssessmentForm1300;
    }

    //#region Form ======================================================================================================

    /** Gewerbesteuermessbetrag, Rechtsmittel-Check, State 1300 */
    public tradeTaxBaseAssessmentForm1300 = new UntypedFormGroup({
        id_companyIdentifier: new UntypedFormControl(""),
        id_notificationDate: new UntypedFormControl(""),
        document_legalRemedyClientDecision: new UntypedFormControl(""),
        id_legalEntityKey: new UntypedFormControl(""),
        document_comment: new UntypedFormControl(""),
        id_assessmentYear: new UntypedFormControl(""),
        document_corporateIncomeTax_vdn: new UntypedFormControl(""),
        document_assessmentDetermination: new UntypedFormControl(""),

        tradeTax_base: new UntypedFormControl(""), //reference
        document_tradeTax_base: new UntypedFormControl(""),
        document_tradeTax_p165: new UntypedFormControl(""),

        document_auditResult: new UntypedFormControl(""),
        document_withoutReferenceValidation: new UntypedFormControl(""),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ""
        ),
        document_mail_requestedDocuments: new UntypedFormControl(""),
        document_mail_reasonNotAccepted: new UntypedFormControl(""),
        document_tradeTax_yearBasedOn: new UntypedFormControl(""),

        document_missingBankAccountDetails: new UntypedFormControl(""),
    });

    //#endregion

    addSingularPayload(): void {
        //no payload
    }


}
