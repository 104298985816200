import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-income-tax-loss-carry-forward1300',
  templateUrl: './doc-corporate-income-tax-loss-carry-forward1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxLossCarryForward1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxLossCarryForwardForm1300;
  }






  //#region Form ======================================================================================================

  public corporateIncomeTaxLossCarryForwardForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),

    document_corporateIncomeTaxLossCarryForward_amount: new UntypedFormControl(""),
    document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount: new UntypedFormControl(""),
    document_corporateIncomeTaxLossCarryForward_remaining_p31Amount: new UntypedFormControl(""),
    corporateIncomeTaxLossCarryForward_amount: new UntypedFormControl(""), //reference 
    corporateIncomeTaxLossCarryForward_remaining_previousYearAmount: new UntypedFormControl(""),
    corporateIncomeTaxLossCarryForward_remaining_p31Amount: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }


}
