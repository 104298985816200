<div class="stareTopic">
    {{ 'APP.SETTINGS.IMPORT.TITLE' | translate }}
</div>




<p class="plainText"> {{ 'APP.SETTINGS.IMPORT.BODY1' | translate: {'mail': getUserMailAddress()} }}
    <button kendoButton (click)="getTemplateFile()" type="button" size="small" style="display: inline;">
        <span class="plainText">{{ 'APP.SETTINGS.IMPORT.TEMPLATE_NAME' | translate }}</span>
    </button>
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.BODY2' | translate }}</span>
</p>

<p>
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.DETAILS' | translate }}
        <ul>
            <li>{{ 'APP.SETTINGS.IMPORT.DESCRIPTION1' | translate }}</li>
            <li>{{ 'APP.SETTINGS.IMPORT.DESCRIPTION2' | translate }}</li>
            <li>{{ 'APP.SETTINGS.IMPORT.DESCRIPTION3' | translate }}</li>
            <li>{{ 'APP.SETTINGS.IMPORT.DESCRIPTION4' | translate }}</li>
        </ul>
        <span [innerHTML]="'APP.SETTINGS.IMPORT.DESCRIPTION5' | translate"></span>
    </span>
</p>

<p>
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.UPLOAD.TITLE' | translate }}
    </span>
    <br>
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.UPLOAD.INSTRUCTIONS' | translate }}
    </span>
</p>
<div class="column-for-upload">
    <kendo-upload [saveUrl]="uploadSaveUrl" saveMethod="POST" [withCredentials]="true" [restrictions]="myRestrictions"
        [autoUpload]="false" [multiple]="false" (error)="uploadFailed($event)" (success)="uploadSuccess()">
        <kendo-upload-messages [dropFilesHere]="'UPLOADER_MENU.DROP_FILES' | translate"
            [select]="'UPLOADER_MENU.SELECT' | translate" [uploadSelectedFiles]="'UPLOADER_MENU.UPLOAD' | translate"
            [clearSelectedFiles]="'UPLOADER_MENU.CLEAR' | translate"
            [invalidFileExtension]="'UPLOADER_MENU.INVALID_TYPE' | translate"
            [fileStatusFailed]="'UPLOADER_MENU.STATUS_FAILED' | translate"
            [fileStatusUploaded]="'UPLOADER_MENU.STATUS_UPLOADED' | translate"
            [headerStatusUploaded]="'UPLOADER_MENU.HEADER_STATUS_UPLOADED' | translate">
        </kendo-upload-messages>
    </kendo-upload>
</div>
<br>
<br>
<br>
<div *ngIf="hasValidationErrors">
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.ERROR.TITLE' | translate }}
    </span>
    <div class="plainText">
        {{ 'APP.SETTINGS.IMPORT.ERROR.DESCRIPTION' | translate }}

        <ul>
            <li *ngFor="let item of validationErrors; let i=index" style="color: red">{{item}}</li>
        </ul>

    </div>
</div>
<br>

<h2 class="plainText">Upload myTR XML Datei</h2>

<div class="row">
    <div class="column-for-upload">

        <kendo-upload [saveUrl]="uploadSaveUrlForXML" saveMethod="POST" [withCredentials]="true"
            [restrictions]="myRestrictionsForXML" [autoUpload]="false" [multiple]="false"
            (error)="uploadFailedXML($event)" (success)="uploadSuccessXML()" (valueChange)="onValueChangeXMLFile()"
            (select)="onSelectXMLFile($event)" (remove)="onRemoveXMLFile()" (clear)="onRemoveXMLFile()"
            [disabled]="isLoading">
            <kendo-upload-messages [dropFilesHere]="'UPLOADER_MENU.DROP_FILES' | translate"
                [select]="'UPLOADER_MENU.SELECT' | translate" [uploadSelectedFiles]="'UPLOADER_MENU.UPLOAD' | translate"
                [clearSelectedFiles]="'UPLOADER_MENU.CLEAR' | translate"
                [invalidFileExtension]="'UPLOADER_MENU.INVALID_TYPE' | translate"
                [fileStatusFailed]="'UPLOADER_MENU.STATUS_FAILED' | translate"
                [fileStatusUploaded]="'UPLOADER_MENU.STATUS_UPLOADED' | translate"
                [headerStatusUploaded]="'UPLOADER_MENU.HEADER_STATUS_UPLOADED' | translate">
            </kendo-upload-messages>
        </kendo-upload>
    </div>
</div>
<br>
<br>
<div class="row">

    <kendo-dropdownlist style="width: 1150px;" *ngIf="fileSelected && !isLoading" [filterable]="true"
        [valuePrimitive]="true" [textField]="'labelDE'" [valueField]="'value'" (filterChange)="handleFilter($event)"
        [data]="legalEntities" [defaultItem]="defaultItem" (valueChange)="valueLegalEntityChange($event)">

    </kendo-dropdownlist>


    <app-legalentity-add-button *ngIf="fileSelected" style="padding-left: 10px;"></app-legalentity-add-button>

</div>

<br>
<div *ngIf="hasValidationErrorsXML">
    <span class="plainText">{{ 'APP.SETTINGS.IMPORT.ERROR.TITLE' | translate }}
    </span>
    <div class="plainText">
        {{ 'APP.SETTINGS.IMPORT.ERROR.DESCRIPTION' | translate }}

        <ul>
            <li *ngFor="let item of validationErrorsXML; let i=index" style="color: red">{{item}}</li>
        </ul>

    </div>
</div>
<br>