import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';


@Component({
  selector: 'app-doc-corporate-income-tax-pre-payment1300',
  templateUrl: './doc-corporate-income-tax-pre-payment1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxPrePayment1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxPrePaymentForm1300;
  }





  //#region Form ======================================================================================================
  /** Körperschaftsteuer Vorauszahlung, Rechtsmittel-Check, State 1300 */
  public corporateIncomeTaxPrePaymentForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_assessmentYear: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),

    document_sepa_iban: new UntypedFormControl(""),
    document_sepa_referenceNumber: new UntypedFormControl("",),
    document_sepa_creditorId: new UntypedFormControl("",),
    document_corporateIncomePrePayment_0310: new UntypedFormControl(""),
    document_corporateIncomePrePayment_0610: new UntypedFormControl(""),
    document_corporateIncomePrePayment_0910: new UntypedFormControl(""),
    document_corporateIncomePrePayment_1210: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_0310: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_0610: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_0910: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_1210: new UntypedFormControl(""),
    document_corporateIncomePrePayment_taxableIncome: new UntypedFormControl(""),
    document_corporateIncomePrePayment_taxableNetProfitLoss: new UntypedFormControl(""),
    document_corporateIncomePrePayment_nextYear_solidarity: new UntypedFormControl(""),
    document_corporateIncomePrePayment_nextYear_tax: new UntypedFormControl(""),
    document_corporateIncomePrePayment_yearOfFurtherPrepayments: new UntypedFormControl(""),
    corporateIncomePrePayment_0310: new UntypedFormControl(""),
    corporateIncomePrePayment_0610: new UntypedFormControl(""),
    corporateIncomePrePayment_0910: new UntypedFormControl(""),
    corporateIncomePrePayment_1210: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_0310: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_0610: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_0910: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_1210: new UntypedFormControl(""),
    corporateIncomePrePayment_taxableIncome: new UntypedFormControl(""),
    corporateIncomePrePayment_taxableNetProfitLoss: new UntypedFormControl(""),
    corporateIncomePrePayment_nextYear_solidarity: new UntypedFormControl(""),
    corporateIncomePrePayment_nextYear_tax: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_corporateIncomePrePayment_yearBasedOn: new UntypedFormControl(""),
    document_corporateIncomePrePayment_dueDate: new UntypedFormControl(""),
    corporateIncomePrePayment_toPaid: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_toPaid: new UntypedFormControl(""),
    document_corporateIncomePrePayment_toPaid: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_toPaid: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_corporateIncomePrePayment_sum: new UntypedFormControl(""),
    corporateIncomePrePayment_sum: new UntypedFormControl(""),
    document_corporateIncomePrePayment_solidarity_sum: new UntypedFormControl(""),
    corporateIncomePrePayment_solidarity_sum: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }



}
