import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ENVIRONMENT } from '../../../environments/environment';
import { UserForCreationItem, UserForUpdateItem, UserItem } from '../../_models/user.model';
import { Observable, of } from 'rxjs';
import { retry } from 'rxjs/operators';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiUserService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Add a new user.
   * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  addUser(item: UserForCreationItem): Observable<UserItem> {
    return this.http.post<UserItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserManagement/AddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Update a user.
   * @param userId ID of the user.
   * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  updateUser(userId: number, item: UserForUpdateItem): Observable<UserItem> {
    return this.http.put<UserItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}UserManagement/UpdateAsync/${userId}`, item, HTTP_OPTIONS);
  }

  /**
   * Delete a user.
   * @param userId ID of the user.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteUser(userId: number): Observable<UserItem> {
    return this.http.delete<UserItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}UserManagement/DeleteAsync/${userId}`, HTTP_OPTIONS);
  }


  /**
   * Get all  users.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllUsers(): Observable<UserItem[]> {
    return this.http.get<UserItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserManagement/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }


  resetClientEngagementByUser(userId: number): Observable<UserItem> {
    return this.http.put<UserItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}UserManagement/ResetLastClientEngagement/${userId}`, HTTP_OPTIONS);
  }



}