
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { SortDescriptor } from "@progress/kendo-data-query";
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { LegalEntityItem, LegalEntityForUpdateItem } from '../_models/legal-entity.model';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from "@progress/kendo-angular-grid";
import { WbsItem } from '../_models/wbs.model';
import { WbsLegalEntityItem, WbsLegalEntityForCreation } from 'app/_models/wbs-legal-entity-item.model';
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { ListsService } from 'app/_services/lists.service';
import { ApiLegalentityService } from 'app/_services/StareApi/legalentity.service';
import { ApiWbsService } from 'app/_services/StareApi/wbs.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { DropDownListItemWithDisabledItem } from 'app/_models/dropdown-item.model';



@Component({
  selector: 'app-setting-legalentities',
  templateUrl: './setting-legalentities.component.html',
  styleUrls: ['./setting-legalentities.component.scss'],
  standalone: false
})
export class SettingLegalentitiesComponent implements OnInit {

  public tableSearch: string = '';
  public tableLegalEntitiesDataRaw = [] as unknown as LegalEntityItem[];
  public tableLegalEntitiesDataFiltered = [] as unknown as LegalEntityItem[];
  public finishedLoading: boolean = false;

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  // =====================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private notificationService: WrapperNotificationService,
    private listsService: ListsService,
    private apiLegalEntityService: ApiLegalentityService,

    private apiWbsService: ApiWbsService,
    public tooltipService: TooltipService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.LEGAL_ITEMS.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);

    });
    this.loadWBSDropdown();
    this.loadLegalEntities();


  }

  // == Resize Grid ===============================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Load Grid Data ========================================================


  loadLegalEntities(): void {
    this.finishedLoading = false;
    this.tableSearch = '';
    this.apiLegalEntityService.getAllLegalEntities()
      .subscribe((response: LegalEntityItem[]) => {
        //console.log(response);  // Debug
        this.tableLegalEntitiesDataRaw = response.filter((x) => x.isDeleted === false);
        this.tableLegalEntitiesDataFiltered = this.tableLegalEntitiesDataRaw;
        this.mapWBSConnections();
        this.tableLegalEntitiesDataFiltered.forEach((element: LegalEntityItem) => {
          element.wbsForDisplay = "";
          element.wbsLegalEntityList.forEach((wbsItem: WbsLegalEntityItem) => {

            element.wbsForDisplay = element.wbsForDisplay + wbsItem.wbs + ", ";

          });

          element.wbsForDisplay = element.wbsForDisplay.slice(0, -2);
        });


        this.finishedLoading = true;

      },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
  }

  // == Settings for the Grid ================================================

  // Pagination 

  public pageSize = 20;



  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "name",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadLegalEntities();
  }

  // Excel Export
  // public exportToExcel(grid: GridComponent): void {
  //   grid.saveAsExcel();
  // }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;



  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }
  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "name",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "wbsForDisplay",
        operator: "contains",
        value: value,
      });

    }

    //this.tableLegalEntitiesDataFiltered = filterBy(this.tableLegalEntitiesDataRaw, filter);
    this.currentFilter = filter;
  }



  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }



  // == Helpers =======================================================================================================================================

  mapWBSConnections(): void {
    this.loadWBSDropdown();
    this.listOfAssignedWBS.forEach((wbsConnectionItem: WbsLegalEntityItem) => {
      const item = this.tableLegalEntitiesDataFiltered.filter(e => e.legalEntityKey === wbsConnectionItem.legalEntityKey);
      item[0].wbsLegalEntityList.push(wbsConnectionItem);



    });
  }

  // == Mail Templates ==============================================================================================

  // == Mail templates drop down
  listMailTemplates = [] as Array<{ text: string; value: number; itemDisabled: boolean }>;

  //TODO
  loadMailTemplateDropdown(): void { }


  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisLegalEntityKey: string = "";


  // == Forms ================================================================
  public editForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    userCode: new FormControl(""),
    userNumber: new FormControl(""),
    clientContact: new FormControl(""),
    mailTemplate: new FormControl(""),
    wbs: new FormControl([]),
    inScope: new FormControl(true)
  });


  // == Handlers =================================================================
  public editHandler(activeRow: NavigationRow): void {
    if (activeRow?.dataItem) {
      this.editForm.reset(activeRow.dataItem);
      this.isNew = false;
      this.active = true;
      //set currently chosen legal item
      this.thisLegalEntityKey = activeRow.dataItem.legalEntityKey;
      // parse list of already assigned wbs codes (to selected legal item)
      this.listOfSelectedWBS = activeRow.dataItem.wbsLegalEntityList.map((item: DropDownListItemWithDisabledItem) => { return ({ labelEN: item.labelEN, labelDE: item.labelDE, value: item.value, isDisabled: false }) });
      this.listWBS = this.listWBS.map(item => {
        // set assigned to this item wbs codes as not diabled (allowes edit, ex. deletion)
        if (this.listOfSelectedWBS.some(e => e.value === item.value)) {

          return { labelEN: item.labelEN, labelDE: item.labelDE, value: item.value, isDisabled: false };
        } else {
          return item
        }
      });
      console.log(this.listOfSelectedWBS); //debug
    }
  }

  public addHandler(): void {
    this.loadWBSDropdown();


    // Reset form fields
    this.editForm.reset();
    this.editForm.patchValue({
      inScope: true
    })
    this.isNew = true;
    this.active = true;
    this.thisLegalEntityKey = "";
    this.listOfSelectedWBS = []
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public closeForm(): void {
    //reset disabled wbs codes 
    this.loadWBSDropdown();
    this.loadLegalEntities();
    this.active = false;

  }



  public onCancel(e: PointerEvent): void {
    //reset disabled wbs codes
    this.loadWBSDropdown();
    e.preventDefault();
    this.loadLegalEntities();
    this.closeForm();

  }



  // == CRUD Operations ========================================================================
  public onSave(e: PointerEvent): void {
    e.preventDefault();

    if (this.isNew) {
      // Map Legal Entity from form
      let addLegalEntity = {} as LegalEntityForUpdateItem;

      addLegalEntity = {
        name: this.editForm.value.name,
        prId: this.editForm.value.prId,
        inScope: this.editForm.value.inScope ? true : false,
        //clientContact: this.editForm.value.clientContact,
        userNumber: this.editForm.value.userNumber,
        userCode: this.editForm.value.userCode,
      };

      this.apiLegalEntityService.addLegalEntity(addLegalEntity).subscribe({
        next: (res: LegalEntityItem) => {
          this.thisLegalEntityKey = res.legalEntityKey;

        },
        error: (err: HttpErrorResponse) => {
          console.error(err);

          if (err.status == 400) {
            this.notificationService.showError('Das Unternehmen existiert bereits und konnte nicht (erneut) angelegt werden.');
          }

          if (err.status == 403) {
            this.notificationService.showError('Die Rechte zum Hinzufügen sind nicht ausreichend.');
          }
        },
        complete: () => {

          //if legal item is new, just need to parse currently selected wbs codes 
          this.mapWBStoAssign(this.thisLegalEntityKey);

          this.wbsForAssign.forEach((element: WbsLegalEntityForCreation) => {
            if (element) {
              console.log(element);
              this.apiWbsService.assignToLegalEntityAsync(element).subscribe({
                next: () => {

                  this.loadLegalEntities();

                },
                error: (err: HttpErrorResponse) => {
                  console.error(err);

                  if (err.status == 400) {
                    this.notificationService.showError('Validation Error');
                  }

                  if (err.status == 406) {
                    this.notificationService.showError('Validation Error');
                  }


                  if (err.status == 410) {
                    this.notificationService.showError('WBS or legal entity not found.');
                  }

                  if (err.status == 409) {
                    this.notificationService.showError('WBS id does not matched with client engagement key.');
                  }
                },
                complete: () => {
                  // Nothing to do here
                  this.loadWBSDropdown();
                }
              });
            }
          });

          this.notificationService.showSuccess(`Unternehmen ${addLegalEntity.name} hinzugefügt.`);
          this.listsService.ResetCacheLegalEntities();
        }
      });

    } else {


      let updateLegalEntityObject = {} as LegalEntityForUpdateItem;
      updateLegalEntityObject = {
        name: this.editForm.value.name,
        prId: this.editForm.value.prId,
        userNumber: this.editForm.value.userNumber,
        userCode: this.editForm.value.userCode,
        inScope: this.editForm.value.inScope ? true : false
      };
      console.log("name" + this.editForm.value.name)
      if (this.listOfSelectedWBS.length == 0) {
        this.listOfAssignedWBS.forEach(item => {
          if (item.legalEntityKey === this.thisLegalEntityKey) {
            this.apiWbsService.removeFromLegalEntityAsync(item.id).subscribe({
              error: (err: HttpErrorResponse) => {
                console.error(err);

                if (err.status == 400) {
                  this.notificationService.showError('Das WBS Verbindung existiert bereits und konnte nicht (erneut) angelegt werden.');
                }

                if (err.status == 410) {
                  this.notificationService.showError('Das zu aktualisierende Verbindung wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
                }
              },
              complete: () => {
                // Nothing to do here
              }
            });
          }
        })
      }
      //if legal item already exists, just edit
      this.listOfSelectedWBS.forEach(element => {

        if (!this.listOfAssignedWBS.some(e => e.id === element.value && e.legalEntityKey === this.thisLegalEntityKey)) {
          this.wbsForAssign.push({
            wbsCustomerId: element.value,
            legalEntityKey: this.thisLegalEntityKey
          })
          //add to wbs to assign
        }
        if (this.listOfAssignedWBS.some(e => e.legalEntityKey === this.thisLegalEntityKey && e.id != element.value)) {
          //search for all to delete
          this.listOfAssignedWBS.forEach(item => {
            if (this.listOfSelectedWBS.some(wbs => item.legalEntityKey === this.thisLegalEntityKey && wbs.value === item.id)) {
              //do nothing
            } else if (item.legalEntityKey === this.thisLegalEntityKey) {
              console.log("delete??" + item.wbsCustomerId);
              this.apiWbsService.removeFromLegalEntityAsync(item.id).subscribe({
                error: (err: HttpErrorResponse) => {
                  console.error(err);

                  if (err.status == 400) {
                    this.notificationService.showError('Das WBS Verbindung existiert bereits und konnte nicht (erneut) angelegt werden.');
                  }

                  if (err.status == 410) {
                    this.notificationService.showError('Das zu aktualisierende Verbindung wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
                  }
                },
                complete: () => {
                  this.listsService.ResetCacheLegalEntities();

                }
              });
            }
          })
        }
      });

      this.apiLegalEntityService.updateLegalEntity(this.thisLegalEntityKey, updateLegalEntityObject).subscribe({
        next: () => {

        },
        error: (err: HttpErrorResponse) => {
          console.error(err);

          if (err.status == 400) {
            this.notificationService.showError('Das Unternehmen existiert bereits und konnte nicht (erneut) angelegt werden.');
          }

          if (err.status == 410) {
            this.notificationService.showError('Das zu aktualisierende Unternehmen wurde nicht gefunden und konnte deswegen nicht aktualisiert werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
          }
        },
        complete: () => {
          this.listsService.ResetCacheLegalEntities();
        }
      });
      console.log("!!!" + this.wbsForAssign);
      this.wbsForAssign.forEach((element: WbsLegalEntityForCreation) => {
        if (element) {
          this.apiWbsService.assignToLegalEntityAsync(element).subscribe({
            next: () => {


            },
            error: (err: HttpErrorResponse) => {
              console.error(err);

              if (err.status == 400) {
                this.notificationService.showError('Validation Error');
              }

              if (err.status == 406) {
                this.notificationService.showError('Validation Error');
              }


              if (err.status == 410) {
                this.notificationService.showError('WBS or legal entity not found.');
              }

              if (err.status == 409) {
                this.notificationService.showError('WBS id does not matched with client engagement key.');
              }
            },
            complete: () => {
              // Nothing to do here

            }

          });
        }
      });
      this.notificationService.showSuccess(`Unternehmen ${updateLegalEntityObject.name} aktualisiert.`);
      this.listsService.ResetCacheLegalEntities();
    }

    this.wbsForAssign = [];
    this.loadWBSDropdown();
    this.loadLegalEntities();
    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisLegalEntityKey == "") {
      return;
    }
    //TODO edit
    this.connectionsToDelete = this.listOfAssignedWBS;
    this.connectionsToDelete.filter(e => e.legalEntityKey === this.thisLegalEntityKey).forEach(element => {

      this.apiWbsService.removeFromLegalEntityAsync(element.id).subscribe({
        error: (err: HttpErrorResponse) => {
          if (err.status == 409) {
            this.notificationService.showError('WBS connection can not be deleted');
          }
        }
      })
    });

    this.apiLegalEntityService.deleteLegalEntity(this.thisLegalEntityKey).subscribe({
      next: () => {
        this.notificationService.showSuccess('Das Unternehmen wurde erfolgreich gelöscht.');
        //this.loadLegalEntities();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);

        if (err.status == 403) {
          this.notificationService.showError('Die Rechte zum löschen sind nicht ausreichend.');
        }

        if (err.status == 410) {
          this.notificationService.showError('Das zu löschende Unternehmen wurde nicht gefunden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        }

      },
      complete: () => {
        this.loadWBSDropdown();
        this.loadLegalEntities();
        this.listsService.ResetCacheLegalEntities();

      }
    }
    );

    this.closeForm();
  }



  // == WBS Dropdown==========================================================================================
  //list of all existing wbs codes
  listWBS = [] as DropDownListItemWithDisabledItem[];
  //list of wbs codes that are already assigned to some legal entity
  listOfAssignedWBS = [] as WbsLegalEntityItem[];
  //list of wbs codes that are selected for new assignment
  wbsForAssign: WbsLegalEntityForCreation[] = [] as WbsLegalEntityForCreation[];
  //list of wbs codes that need to be deleted
  connectionsToDelete = [] as WbsLegalEntityItem[];

  //list of wbs codes that are selected in current update/create session
  listOfSelectedWBS = [] as DropDownListItemWithDisabledItem[];

  loadWBSDropdown(): void {
    this.apiWbsService.getAssignedWBSAsync().subscribe((response: WbsLegalEntityItem[]) => {
      this.listOfAssignedWBS = response;
      //this.connectionsToDelete = this.listOfAssignedWBS;

      this.apiWbsService.getAllWBS()
        .subscribe((response: WbsItem[]) => {

          this.listWBS = response.filter(item => item.isDeactivated === false).map(item => {

            if (this.listOfAssignedWBS.some(e => e.wbsCustomerId === item.id)) {

              return { labelEN: item.wbs, labelDE: item.wbs, value: item.id, isDisabled: true };
            } else {

              return { labelEN: item.wbs, labelDE: item.wbs, value: item.id, isDisabled: false };
            }
          });

        },
          (error: HttpErrorResponse) => {
            console.log(error);
          });
    }, (error: HttpErrorResponse) => {
      console.log(error);
    });

  }

  public itemDisabled(itemArgs: { dataItem: DropDownListItemWithDisabledItem; index: number }): boolean {
    return itemArgs.dataItem.isDisabled;
  }

  public mapWBStoAssign(legalEntityKey: string): void {

    this.listOfSelectedWBS.forEach((element: DropDownListItemWithDisabledItem) => {
      this.wbsForAssign.push({
        wbsCustomerId: element.value,
        legalEntityKey: legalEntityKey
      })
    });

  }


}
