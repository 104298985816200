import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LegalEntityForCreationItem, LegalEntityItem, LegalEntityForUpdateItem } from 'app/_models/legal-entity.model';
import { MailMetadataClientItem } from 'app/_models/mail-metadata-client.model';
import { MailMetadataForCreationItem, MailMetadataForUpdateItem, MailMetadataItem } from 'app/_models/mail-metadata.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiLegalentityService {

  constructor(
    private http: HttpClient) { }

  /**
 * Add Clientmailing Metadata.
 * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
 * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
*/
  addMailMetadata(item: MailMetadataForCreationItem): Observable<MailMetadataItem> {
    return this.http.post<MailMetadataItem>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/MailMetadataAddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Update Client mailing metadata.
   * @param templateId ID of the template.
   * @param item  item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
*/
  updateMailMetadata(id: number, item: MailMetadataForUpdateItem): Observable<MailMetadataItem> {
    return this.http.put<MailMetadataItem>(`${ENVIRONMENT.apiBaseUrl}LegalEntity/MailMetadataUpdateAsync/${id}`, item, HTTP_OPTIONS);
  }

  /**
   * Delete client mailing.
   * @param templateId ID of the template.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteMailMetadata(id: number): Observable<MailMetadataItem> {
    return this.http.delete<MailMetadataItem>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/MailMetadataDeleteAsync/' + id, HTTP_OPTIONS);
  }


  /**
   * Get all mail metadaten.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllMailTemplates(): Observable<MailMetadataItem[]> {
    return this.http.get<MailMetadataItem[]>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/MailMetadataGetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }
  /**
 * API: Get Mailmetadata Client details  by mailmetadat ID.
 */
  getMailMetadatClientDetailsById(id: number): Observable<MailMetadataClientItem[]> {
    return this.http.get<MailMetadataClientItem[]>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/MailMetadataClientGetAllAsync/' + id, HTTP_OPTIONS);
  }

  /**
 * Add Clientmailing Metadata ClientItem contact.
 * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
 * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
*/
  addMailMetadataClientItem(item: MailMetadataClientItem): Observable<MailMetadataClientItem> {
    return this.http.post<MailMetadataClientItem>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/MailMetadataClientAddAsync/' + item.mailMetadataId, item, HTTP_OPTIONS);
  }

  /**
   * Update Client contact mailing metadata.
   * @param templateId ID of the template.
   * @param item  item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
  */
  updateMailMetadataClientItem(id: number, item: MailMetadataClientItem): Observable<MailMetadataClientItem> {
    return this.http.put<MailMetadataClientItem>(`${ENVIRONMENT.apiBaseUrl}LegalEntity/MailMetadataClientUpdateAsync/${id}`, item, HTTP_OPTIONS);
  }

  /**
   * Delete client mailing contact .
   * @param templateId ID of the template.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteMailMetadataClientItem(id: number): Observable<MailMetadataClientItem> {
    return this.http.delete<MailMetadataClientItem>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/DeleteClientContactAsync/' + id, HTTP_OPTIONS);
  }


  /**
   * Add a new legal entity.
   * @param item Legal entity item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  addLegalEntity(item: LegalEntityForCreationItem): Observable<LegalEntityItem> {
    return this.http.post<LegalEntityItem>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/AddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Update legal entity.
   * @param legalEntityKey key of the legal item.
   * @param item Legal Item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  updateLegalEntity(legalEntityKey: string, item: LegalEntityForUpdateItem): Observable<LegalEntityItem> {
    return this.http.put<LegalEntityItem>(`${ENVIRONMENT.apiBaseUrl}LegalEntity/UpdateAsync/${legalEntityKey}`, item, HTTP_OPTIONS);
  }

  /**
   * Delete legal entity.
   * @param userId ID of the user.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteLegalEntity(legalEntityKey: string): Observable<LegalEntityItem> {
    return this.http.delete<LegalEntityItem>(`${ENVIRONMENT.apiBaseUrl}LegalEntity/DeleteAsync/${legalEntityKey}`, HTTP_OPTIONS);
  }


  /**
   * Get all users.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllLegalEntities(): Observable<LegalEntityItem[]> {
    return this.http.get<LegalEntityItem[]>(ENVIRONMENT.apiBaseUrl + 'LegalEntity/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

}
