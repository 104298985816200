<kendo-dialog [width]="400" [height]="500" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>
    <div class="grid-layout-container">
        <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
            <kendo-gridlayout [gap]="{ rows: 6, cols: 15 }" [cols]="[{ width: 250 }, { width: 250 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                    <kendo-formfield>
                        <kendo-label [for]="name"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate"></kendo-label>
                        <kendo-textbox #name formControlName="name"
                            [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate"
                            required></kendo-textbox>
                        <kendo-formerror>{{
                            'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.NAME.ERROR.REQUIRED' |
                            translate }}</kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield>
                        <kendo-label [for]="userCode"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.USERCODE' | translate"></kendo-label>
                        <kendo-textbox #userCode formControlName="userCode"
                            [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.USERCODE' | translate"></kendo-textbox>

                    </kendo-formfield>

                    <kendo-formfield>
                        <ng-container>
                            <div kendoTooltip position="right">
                                <span class="k-checkbox-wrap">
                                    <input id="inScope" type="checkbox" kendoCheckBox formControlName="inScope" />
                                </span>
                                <label [labelClass]="false" class="k-checkbox-label" for="inScope"
                                    [title]="'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.IN_SCOPE.TOOLTIP' | translate">
                                    {{
                                    'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.IN_SCOPE.LABEL' |
                                    translate }}</label>
                            </div>
                        </ng-container>
                    </kendo-formfield>

                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </form>

    </div>
    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="submit()">
            {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button kendoButton (click)="closeForm()">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>