import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { WbsItem } from '../_models/wbs.model';
import { TestWorkflowForCreation, TestWorkflowItem } from '../_models/test-workflow.model';
import { AssessmentType } from '../_models/assessment-type.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { ApiWbsService } from 'app/_services/StareApi/wbs.service';
import { ApiTestDataService } from 'app/_services/StareApiMaintenance/test-data.service';


@Component({
  selector: 'app-setting-testdata',
  templateUrl: './setting-testdata.component.html',
  styleUrls: ['./setting-testdata.component.scss'],
  standalone: false
})

export class SettingTestdataComponent implements OnInit {

  //Variables for Translation
  public testDataSettingsTitle: string = "Unternehmen Verwaltung";
  public documentDefDropDown: string = "Unternahmen durchsuchen";
  public wbsDefDropDown: string = "Neues Unternehmen anlegen";
  public submitButton: string = "Test-Bescheid erzeugen";
  //Variables for Translation

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiTestDataService: ApiTestDataService,
    private apiWbsService: ApiWbsService,
    private notificationService: WrapperNotificationService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.TESTDATA.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);

    });

    this.loadDocuDefDropdown();
    this.loadWBSDropdown();
  }

  // == Form ========================================================================================================================================


  public editForm: FormGroup = new FormGroup({
    documentDefinition: new FormControl("", Validators.required),
    wbs: new FormControl("", Validators.required),
    abbyy: new FormControl(false)
  });


  //===Dropdown-Document-Definition==============================================================

  listDocumentDefinition = [] as AssessmentType[];
  listWBS = [] as WbsItem[];

  loadDocuDefDropdown(): void {
    this.apiTestDataService.getAssessmentTypes()
      .subscribe((response: AssessmentType[]) => {
        console.log(response);  // Debug
        this.listDocumentDefinition = response; //.map(item => ({ value: item.id.toString(), label: item.title }));

        // Remove "isPublic" = false items
        this.listDocumentDefinition = this.listDocumentDefinition.filter(item => item.isPublic === true);

        // order by title
        this.listDocumentDefinition = this.listDocumentDefinition.sort((a, b) => (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()) ? 1 : -1);

      },
        (error: HttpErrorResponse) => {
          console.log(error);
        });
  }

  loadWBSDropdown(): void {
    this.apiWbsService.getAllWBS()
      .subscribe((response: WbsItem[]) => {
        //console.log(response);  // Debug
        this.listWBS = response.filter(item => item.isDeactivated === false); //.map(item => ({ value: item.id.toString(), label: item.wbs }));

        // Order by WBS
        this.listWBS = this.listWBS.sort((a, b) => (a.wbs > b.wbs) ? 1 : -1);

      },
        (error: HttpErrorResponse) => {
          console.log(error);
        });

  }




  //== Submit and create new test workflow ================

  buttonDisabled = false;

  onClickSubmitButton(): void {
    this.buttonDisabled = true;

    const workflowToAdd = {
      assessmentTypeId: this.editForm.get('documentDefinition')?.value,
      withOcrResult: this.editForm.get('abbyy')?.value ? false : true,
      wbsId: this.editForm.get('wbs')?.value
    } as TestWorkflowForCreation;

    this.apiTestDataService.addExampleWorkflow(workflowToAdd).subscribe({
      next: (res: TestWorkflowItem) => {
        this.notificationService.showSuccess(`Workflow ${res.id} hinzugefügt.`);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.notificationService.showError(`Es ist ein ${err.status} Fehler aufgetreten (${err.statusText})`);
        this.buttonDisabled = false;
      },
      complete: () => {
        // Nothing to do here
        this.buttonDisabled = false;
      }
    });


  }



}

