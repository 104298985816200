import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DatasetItem } from 'app/_models/dataset.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldItem } from 'app/_models/form-field.model';

@Component({
    selector: 'app-doc-trade-tax-base',
    templateUrl: './doc-trade-tax-base.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocTradeTaxBaseComponent extends DocBaseComponent {
    constructor(
        listsService: ListsService,

        apiUIViewerService: UIViewerService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.tradeTaxBaseAssessmentForm;
    }

    //#region Form ======================================================================================================

    public tradeTaxBaseAssessmentForm = new UntypedFormGroup({
        id_notificationDate: new UntypedFormControl(""),
        id_companyIdentifier: new UntypedFormControl(""),
        reference_companyIdentifier: new UntypedFormControl(""),
        id_assessmentYear: new UntypedFormControl(""),
        id_legalEntityKey: new UntypedFormControl(""),
        document_assessmentDetermination: new UntypedFormControl(""),
        document_corporateIncomeTax_vdn: new UntypedFormControl(""),

        tradeTax_base: new UntypedFormControl(""), //reference
        document_tradeTax_base: new UntypedFormControl(""),
        document_tradeTax_p165: new UntypedFormControl(""),

        document_auditResult: new UntypedFormControl(""),
        document_withoutReferenceValidation: new UntypedFormControl(""),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ""
        ),
        document_mail_requestedDocuments: new UntypedFormControl(""),
        document_mail_reasonNotAccepted: new UntypedFormControl(""),
        document_tradeTax_yearBasedOn: new UntypedFormControl(""),

        document_missingBankAccountDetails: new UntypedFormControl(""),
        documentPayload: new UntypedFormArray([]),
        id_notificationName: new UntypedFormControl(""),
    });

    //creates initial payloads for tax breakdown commune and sets the datasetId
    createDocumentDynamicFieldsPayload(datasetId: number): UntypedFormGroup {
        const documentDynamicFieldsPayload = new UntypedFormGroup({
            document_dynamic_name: new UntypedFormControl(""),
            document_dynamic_value: new UntypedFormControl(""),
            document_dynamic_referenceValue: new UntypedFormControl(""),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            })
        });
        return documentDynamicFieldsPayload;
    }

    //#endregion

    addSingularPayload(payload: DatasetItem): void {
        const payloadArray = this.documentPayloadGetter();

        this.documentPayloadArrayWithDatasetId.push(
            this.createDocumentDynamicFieldsPayload(payload.id)
        );
        //this.documentPayloadGetter().push(this.createtradeTaxBreakdownPayload(payload.id));
        payloadArray.push(this.createDocumentDynamicFieldsPayload(payload.id));
        // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
        // console.info("Add" + payload.dataTypeName);
    }

    addPayload(dataType: string): void {
        const payloadArray = this.documentPayloadGetter();

        // switch (dataType) {
        //   case "DocumentTradeTaxBreakdownCommune": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
            next: (data) => {
                const newPayload = data as FormFieldItem[];
                const newDatasetID = newPayload[0].datasetId;
                if (newDatasetID != null) {
                    payloadArray.push(
                        this.createDocumentDynamicFieldsPayload(newDatasetID)
                    );
                    //this.showSuccess('Neuen Eintrag eingefügt (interne Id: ' + newDatasetID + ').');
                    this.documentPayloadDatasetIdArray.push(newDatasetID);
                    // this.documentTradeTaxBreakdownPayloadLength = this.documentTradeTaxBreakdownPayloadLength + 415;
                }
            },
            error: (err: HttpErrorResponse) => {
                //this.showError('Fehler beim Hinzufügen: ' + err.message)
                console.error(err);
            },
        });
    }
}
