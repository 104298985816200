<div class="stareTopic">
    {{ 'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TITLE' | translate }}
</div>


<!--(cellClick)="cellClickHandler($event)" (dblclick)="editHandler(grid.activeRow)" for Update-->
<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
            filterable="menu" [loading]="!finishedLoading" [sort]="currentSort" (sortChange)="sortChange($event)"
            (add)="addHandler()" [selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings"
            [filter]="currentFilter || {logic: 'and', filters: []}" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" (cellClick)="cellClickHandler($event)" (dblclick)="editHandler(grid.activeRow)"
            [navigable]=" true" [sortable]="true" [resizable]="true">
            <!--Toolbar-->
            <ng-template kendoGridToolbarTemplate>
                <kendo-textbox [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.SEARCH' | translate"
                    (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

                </button>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="{{ 'BUTTON.ADD' | translate }}">
                </button>
                &nbsp;|&nbsp;


                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>

                <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                    title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

                </button>

            </ng-template>
            <!--End Toolbar-->
            <!--Translation Service for Grid-->
            <kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.PAGER.TASK_PER_PAGE' | translate"
                [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>
            <!--End Translation Service for Grid-->
            <!--Grid Columns-->
            <kendo-grid-column field="clientEngagementKey"
                [title]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.HEADER.CLIENT_ENGAGEMENT_KEY' | translate"></kendo-grid-column>

            <kendo-grid-column field="name"
                [title]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.HEADER.NAME' | translate"></kendo-grid-column>

            <kendo-grid-column field="wbsListAsString" [title]="'WBS'"></kendo-grid-column>

            <kendo-grid-column field="createdDate" filter="date" format="dd.MM.yyyy"
                [title]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TABLE.HEADER.CREATE_DATE' | translate"></kendo-grid-column>

            <kendo-grid-column field="tenantSourceAsString" [title]="'Art'">

            </kendo-grid-column>

            <kendo-grid-column field="exportToDatalake" filter="boolean" kendoTooltip
                [title]="'Export Datalake'"><ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.exportToDatalake
                    ?
                    "ja" : "nein"
                    }}</ng-template></kendo-grid-column>
            <!--End Grid Columns-->
            <ng-template kendoGridNoRecordsTemplate>
                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>

            <!--Excel export-->
            <kendo-grid-excel fileName="ClientEngagements.xlsx" [fetchData]="allData"></kendo-grid-excel>

        </kendo-grid>
    </div>
</div>

<!--Modal for add-->
<kendo-dialog *ngIf="active" [width]="450" [height]="730" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
        <kendo-formfield>
            <kendo-label [for]="name"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.LABEL' | translate"></kendo-label>
            <kendo-textbox #name formControlName="name"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.LABEL' | translate"
                required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="tenantSource"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.TYPE.LABEL' | translate"></kendo-label>

            <kendo-dropdownlist #tenantSource [popupSettings]="{ width: 400, height: 100 }" [data]="tenantSourceData"
                textField="name" valueField="id" [valuePrimitive]="true" formControlName="tenantSource">
            </kendo-dropdownlist>

            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.TYPE.ERROR.REQUIRED' |
                translate}}</kendo-formerror>

        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="exportToDatalake" type="checkbox" kendoCheckBox formControlName="exportToDatalake" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label"
                    for="exportToDatalake">{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.IS_DATALAKE_EXPORT.LABEL' |
                    translate}}</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="manualUploadAllowed" type="checkbox" kendoCheckBox
                        formControlName="manualUploadAllowed" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="manualUploadAllowed">Manueller PDF Upload
                    erlaubt</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="wbs"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.WBS.LABEL' | translate"></kendo-label>
            <kendo-textbox #wbs formControlName="wbs" placeholder="WBS" required>
            </kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.WBS.ERROR.REQUIRED' |
                translate}}</kendo-formerror>

        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="engagementManagerMail"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.MANAGER_MAIL.LABEL' | translate"></kendo-label>
            <kendo-textbox #engagementManagerMail formControlName="engagementManagerMail"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.MANAGER_MAIL.LABEL' | translate"
                required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.MANAGER_MAIL.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="legalEntityName"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.LEGAL_ENTITY_NAME.LABEL' | translate"></kendo-label>
            <kendo-textbox #legalEntityName formControlName="legalEntityName"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.LEGAL_ENTITY_NAME.LABEL' | translate"
                required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.LEGAL_ENTITY_NAME.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="datalakeUrlForImport"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_IMPORT.LABEL' | translate"></kendo-label>
            <kendo-textbox #datalakeUrlForImport formControlName="datalakeUrlForImport"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_IMPORT.LABEL' | translate"
                [disabled]="!editForm.get('exportToDatalake')?.value"></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_IMPORT.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="datalakeUrlForExport"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_EXPORT.LABEL' | translate"></kendo-label>
            <kendo-textbox #datalakeUrlForExport formControlName="datalakeUrlForExport"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_EXPORT.LABEL' | translate"
                [disabled]="!editForm.get('exportToDatalake')?.value"></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.DATALAKE_EXPORT.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

    </form>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>

        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
<!--End Modal for add-->

<!--Modal for update-->
<kendo-dialog *ngIf="activeUpdate" [width]="450" [height]="730" (close)="closeForm()">

    <kendo-dialog-titlebar>
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="updateForm">
        <kendo-formfield>
            <kendo-label [for]="name"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.LABEL' | translate"></kendo-label>
            <kendo-textbox #name formControlName="name"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.LABEL' | translate"
                required></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.NAME.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="isNew">
            <kendo-label [for]="tenantSource"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.TYPE.LABEL' | translate"></kendo-label>

            <kendo-dropdownlist #tenantSource [popupSettings]="{ width: 400, height: 100 }" [data]="tenantSourceData"
                textField="name" valueField="id" [valuePrimitive]="true" formControlName="tenantSource">
            </kendo-dropdownlist>

            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.TYPE.ERROR.REQUIRED' |
                translate}}</kendo-formerror>

        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="exportToDatalake" type="checkbox" kendoCheckBox formControlName="exportToDatalake" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label"
                    for="exportToDatalake">{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.IS_DATALAKE_EXPORT.LABEL' |
                    translate}}</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="manualUploadAllowed" type="checkbox" kendoCheckBox
                        formControlName="manualUploadAllowed" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="manualUploadAllowed">Manueller PDF Upload
                    erlaubt</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="blobName"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_NAME.LABEL' | translate"></kendo-label>
            <kendo-textbox #blobName formControlName="blobName"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_NAME.LABEL' | translate"
                [disabled]="!updateForm.get('exportToDatalake')?.value"></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_NAME.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="blobContainerName"
                [text]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_CONTAINER_NAME.LABEL' | translate"></kendo-label>
            <kendo-textbox #blobContainerName formControlName="blobContainerName"
                [placeholder]="'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_CONTAINER_NAME.LABEL' | translate"
                [disabled]="!updateForm.get('exportToDatalake')?.value"></kendo-textbox>
            <kendo-formerror>{{'APP.ADMINISTRATION.CLIENT_ENGAGEMENT.MODAL.BLOB_CONTAINER_NAME.ERROR.REQUIRED' |
                translate}}</kendo-formerror>
        </kendo-formfield>

    </form>
    <br>
    <br>
    <strong>WBS</strong>

    <kendo-listview [data]="wbsDataEngagement" containerClass="k-d-flex k-flex-col k-flex-nowrap">
        <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-index="index" let-isFirst="isFirst"
            let-isLast="isLast">

            <div class="valMessages" [class.border-bottom]="!isLast">
                {{dataItem.wbs}}
            </div>
        </ng-template>
    </kendo-listview>
    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
            {{ 'BUTTON.DELETE' | translate }}
        </button>

        <button kendoButton themeColor="primary" [disabled]="!updateForm.valid" (click)="onSave($event)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>

        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>
<!--End Modal for update-->