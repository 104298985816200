<form [formGroup]="corporateIncomeTaxPrePaymentForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        overrideLabel="Begründung Rechtsbehelf für Mandant (extern):">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_assessmentYear"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_yearBasedOn" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Aktueller VZ KSt
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_0310" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_0310" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_0610" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_0610" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_0910" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_0910" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_1210" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_1210" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_sum" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_sum" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Aktueller VZ KSt
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_0310" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_0310" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_0610" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_0610" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_0910" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_0910" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_1210" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_1210" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_solidarity_sum" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_solidarity_sum" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Nachträgliche Anpassung
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_dueDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Nächste Vorauszahlungen
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_yearOfFurtherPrepayments"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_nextYear_tax" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_nextYear_tax" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_nextYear_solidarity" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_nextYear_solidarity" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_taxableNetProfitLoss" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_taxableNetProfitLoss" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomePrePayment_taxableIncome" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomePrePayment_taxableIncome" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>

            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="corporateIncomeTaxPrePaymentForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxPrePaymentForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->

            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="corporateIncomeTaxPrePaymentForm1300" [auditResults]="auditResults" [workflowId]="workflowId"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 
<form [formGroup]="corporateIncomeTaxPrePaymentForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 120 },
        { height: 130 },
        { height: 20 },						
        { height: 112 },
						{ height: 112 },
						{ height: 1 },
						{ height: 112 },
						{ height: 25 },
						{ height:15 },
						{ height: 112 },
						{ height: 112 },	
						{ height: 112 },	
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },										
						{ height: 15 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },	
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 15 },
						{ height: 112 },
						{ height: 15 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 20 },
		                { height: 90 },
		                { height: withoutReferenceValidationExplanationRowHeight},
                        { height: sdcFieldsRowHeight },
                        { height: sdcFieldsRowHeight },]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">

                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox [id]="'id_companyIdentifier-'+headerDatasetId" type="text"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            (focus)="onFocusInputXbrl()" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}
                        </kendo-label>
                        <kendo-dropdownlist [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">

                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">

                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_assessmentYear-'+headerDatasetId"
                            id="yLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                        <kendo-numerictextbox [spinners]="false" [decimals]="0" [maxlength]="4"
                            [changeValueOnScroll]="false" format="##" [id]="'id_assessmentYear-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_assessmentYear')"
                            placeholder="{{getXbrlLabel('id_assessmentYear')}}" formControlName="id_assessmentYear"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'document_corporateIncomePrePayment_yearBasedOn-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('document_corporateIncomePrePayment_yearBasedOn')}}
                        </kendo-label>
                        <kendo-dropdownlist [id]="'document_corporateIncomePrePayment_yearBasedOn-'+headerDatasetId"
                            [valuePrimitive]="true"
                            [defaultItem]="referenceYearsForPrepayment.length > 0?referenceYearsForPrepayment[0]:''"
                            formControlName="document_corporateIncomePrePayment_yearBasedOn"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_yearBasedOn')"
                            [data]="referenceYearsForPrepayment"></kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_yearBasedOn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="2">
                    <kendo-label style="font-size: 17px; font-weight: bold;" id="KstVZLbl">Aktueller VZ
                        KSt</kendo-label>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_0310-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_0310')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_0310-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_0310')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_0310')}}"
                            formControlName="document_corporateIncomePrePayment_0310" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_0310'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_0310-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_0310')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_0310-'+headerDatasetId" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_0310')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_0310')}}"
                            formControlName="corporateIncomePrePayment_0310" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_0310'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_0610-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_0610')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [decimals]="2"
                            [id]="'document_corporateIncomePrePayment_0610-'+headerDatasetId"
                            [changeValueOnScroll]="false" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_0610')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_0610')}}"
                            formControlName="document_corporateIncomePrePayment_0610" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_0610'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_0610-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_0610')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_0610-'+headerDatasetId" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_0610')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_0610')}}"
                            formControlName="corporateIncomePrePayment_0610" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_0610'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_0910-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_0910')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_0910-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_0910')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_0910')}}"
                            formControlName="document_corporateIncomePrePayment_0910" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_0910'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_0910-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_0910')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_0910-'+headerDatasetId" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_0910')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_0910')}}"
                            formControlName="corporateIncomePrePayment_0910" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_0910'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_1210-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_1210')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_1210-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_1210')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_1210')}}"
                            formControlName="document_corporateIncomePrePayment_1210" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_1210'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_1210-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_1210')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_1210-'+headerDatasetId" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_1210')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_1210')}}"
                            formControlName="corporateIncomePrePayment_1210" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_1210'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>


                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_toPaid-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_toPaid')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_toPaid-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_toPaid')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_toPaid')}}"
                            formControlName="document_corporateIncomePrePayment_toPaid" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_toPaid'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_toPaid-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_toPaid')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_toPaid-'+headerDatasetId" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_toPaid')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_toPaid')}}"
                            formControlName="corporateIncomePrePayment_toPaid" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_toPaid'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_sum-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_sum')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_sum-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_sum')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_sum')}}"
                            formControlName="document_corporateIncomePrePayment_sum" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_sum'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="2" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_sum-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_sum')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_sum-'+headerDatasetId" [readonly]="true" [tabindex]="-1"
                            class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_sum')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_sum')}}"
                            formControlName="corporateIncomePrePayment_sum" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_sum'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="2">
                    <kendo-label style="font-size: 17px; font-weight: bold;" id="KstVZLbl">Aktueller VZ
                        Soli</kendo-label>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_0310-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0310')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_0310-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_0310')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0310')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_0310" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_0310'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_0310-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_0310')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_0310-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_0310')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_0310')}}"
                            formControlName="corporateIncomePrePayment_solidarity_0310" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_0310'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_0610-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0610')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_0610-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_0610')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0610')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_0610" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_0610'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_0610-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_0610')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_0610-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_0610')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_0610')}}"
                            formControlName="corporateIncomePrePayment_solidarity_0610" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_0610'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_0910-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0910')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_0910-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_0910')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_0910')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_0910" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_0910'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_0910-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_0910')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_0910-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_0910')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_0910')}}"
                            formControlName="corporateIncomePrePayment_solidarity_0910" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_0910'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_1210-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_1210')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_1210-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_1210')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_1210')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_1210" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_1210'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_1210-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_1210')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_1210-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_1210')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_1210')}}"
                            formControlName="corporateIncomePrePayment_solidarity_1210" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_1210'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_toPaid-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_toPaid')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_toPaid-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_toPaid')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_toPaid')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_toPaid" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_toPaid'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_toPaid-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_toPaid')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_toPaid-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_toPaid')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_toPaid')}}"
                            formControlName="corporateIncomePrePayment_solidarity_toPaid" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_toPaid'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="23" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_solidarity_sum-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_sum')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_solidarity_sum-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_solidarity_sum')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_solidarity_sum')}}"
                            formControlName="document_corporateIncomePrePayment_solidarity_sum" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_solidarity_sum'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="23" [col]="2" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_solidarity_sum-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_solidarity_sum')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_solidarity_sum-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_solidarity_sum')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_solidarity_sum')}}"
                            formControlName="corporateIncomePrePayment_solidarity_sum" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_solidarity_sum'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="24" [col]="1" [colSpan]="2">
                    <kendo-label style="font-size: 17px; font-weight: bold;" id="KstVZLbl">Nachträgliche
                        Anpassung</kendo-label>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="25" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_corporateIncomePrePayment_dueDate-'+headerDatasetId"
                            id="dueDateLabel">{{getXbrlLabel('document_corporateIncomePrePayment_dueDate')}}</kendo-label>
                        <kendo-datepicker
                            [value]="formGroup.controls['document_corporateIncomePrePayment_dueDate'].value"
                            format="dd.MM.yyyy" (focus)="onFocusInputXbrl()" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_dueDate')"
                            [id]="'document_corporateIncomePrePayment_dueDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_dueDate')}}"
                            formControlName="document_corporateIncomePrePayment_dueDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_dueDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="26" [col]="1" [colSpan]="2">
                    <kendo-label style="font-size: 17px; font-weight: bold;" id="KstVZLbl">Nächste
                        Vorauszahlungen</kendo-label>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="27" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_yearOfFurtherPrepayments-'+headerDatasetId"
                            id="furtherPayYearLbl">{{getXbrlLabel('document_corporateIncomePrePayment_yearOfFurtherPrepayments')}}</kendo-label>
                        <kendo-numerictextbox [spinners]="false" [decimals]="0" [maxlength]="4"
                            [changeValueOnScroll]="false" format="##"
                            [id]="'document_corporateIncomePrePayment_yearOfFurtherPrepayments-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_yearOfFurtherPrepayments')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_yearOfFurtherPrepayments')}}"
                            formControlName="document_corporateIncomePrePayment_yearOfFurtherPrepayments"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_yearOfFurtherPrepayments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="28" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_nextYear_tax-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_nextYear_tax')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_nextYear_tax-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_nextYear_tax')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_nextYear_tax')}}"
                            formControlName="document_corporateIncomePrePayment_nextYear_tax" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_nextYear_tax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="28" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_nextYear_tax-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_nextYear_tax')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_nextYear_tax-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_nextYear_tax')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_nextYear_tax')}}"
                            formControlName="corporateIncomePrePayment_nextYear_tax" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_nextYear_tax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="29" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_nextYear_solidarity-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_nextYear_solidarity')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_nextYear_solidarity-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_nextYear_solidarity')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_nextYear_solidarity')}}"
                            formControlName="document_corporateIncomePrePayment_nextYear_solidarity" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_nextYear_solidarity'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="29" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_nextYear_solidarity-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_nextYear_solidarity')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_nextYear_solidarity-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_nextYear_solidarity')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_nextYear_solidarity')}}"
                            formControlName="corporateIncomePrePayment_nextYear_solidarity" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_nextYear_solidarity'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="30" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_taxableNetProfitLoss-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_taxableNetProfitLoss')}}</kendo-label>

                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_taxableNetProfitLoss-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_taxableNetProfitLoss')}}"
                            formControlName="document_corporateIncomePrePayment_taxableNetProfitLoss"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="30" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_taxableNetProfitLoss-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_taxableNetProfitLoss')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_taxableNetProfitLoss-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_taxableNetProfitLoss')}}"
                            formControlName="corporateIncomePrePayment_taxableNetProfitLoss" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="31" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomePrePayment_taxableIncome-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomePrePayment_taxableIncome')}}</kendo-label>

                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'document_corporateIncomePrePayment_taxableIncome-'+headerDatasetId"
                            (focus)="onFocusInputXbrl()" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomePrePayment_taxableIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomePrePayment_taxableIncome')}}"
                            formControlName="document_corporateIncomePrePayment_taxableIncome" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomePrePayment_taxableIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="31" [col]="2" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomePrePayment_taxableIncome-'+headerDatasetId">{{getXbrlLabel('corporateIncomePrePayment_taxableIncome')}}</kendo-label>
                        <kendo-numerictextbox type="decimal" [spinners]="false" [changeValueOnScroll]="false"
                            [id]="'corporateIncomePrePayment_taxableIncome-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1" class="custom-fill-mode" fillMode="none" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomePrePayment_taxableIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomePrePayment_taxableIncome')}}"
                            formControlName="corporateIncomePrePayment_taxableIncome" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomePrePayment_taxableIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="32" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="33" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
                            (change)="changeExplanationRowHeight()" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="34" [col]="1" [colSpan]="2"
                    *ngIf="formGroup.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            type="string" (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="35" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="36" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->