<!-- <form [formGroup]="form">

    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"
            style=" color: #646262 !important"><ng-template #toolTipGridTemplate let-anchor>
                <span>{{ label }}</span>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">
                <kendo-textbox (focus)="onFocusInputXbrl()" [id]="xbrlName+'-'+datasetId" type="text"
                    [formControlName]="xbrlName" autocomplete="off" [class]="fieldClass" [readonly]="isReadOnly"
                    [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                    [inert]="(isReadOnly || isArchiv) || (isReadOnly && isArchiv)" [selectOnFocus]="true">
                </kendo-textbox>
            </div>
        </kendo-floatinglabel>

        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">
            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>
    </kendo-formfield>

</form> -->
<div class="row">
    <div [class]='(isReadOnly  && !(stareInputFieldsService.isBlocked(xbrlName)) ) ? "column-for-with-plus" : "column"'>

        <form [formGroup]="form">

            <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

                <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"
                    style=" color: #646262 !important"><ng-template #toolTipGridTemplate let-anchor>
                        <span>{{ label }}</span>
                    </ng-template>
                    <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                        (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">
                        <kendo-textbox (focus)="onFocusInputXbrl()" [maxlength]=255 [id]="xbrlName+'-'+datasetId"
                            type="text" [formControlName]="xbrlName" autocomplete="off" [class]="fieldClass"
                            [readonly]="isReadOnly"
                            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                            [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)"
                            [selectOnFocus]="true">
                        </kendo-textbox>
                    </div>
                </kendo-floatinglabel>

                <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class"
                    style="padding-left: 10px; padding-right: 10px;">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
                </div>
            </kendo-formfield>

        </form>
    </div>
    <div class="column-for-plus" *ngIf="isReadOnly   && !(stareInputFieldsService.isBlocked(xbrlName))">
        <app-reference-disable-button (onReferenceDisable)="onReferenceDisableReciever($event)"
            [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlName"
            [disabled]="isArchiv"></app-reference-disable-button>
    </div>

</div>