<!-- <div kendoTooltip class="bottom" position="bottom"> -->
<!-- <kendo-formhint class="custom-info-message"
    title="{{getInfoMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)}}"
    *ngIf="getInfoMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' ">
    {{compactInfoMsg}}&nbsp;
</kendo-formhint>
<kendo-formhint class="custom-warning-message"
    title="{{getWarnMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)}}"
    *ngIf="getWarnMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' && getErrorMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)==''">
    {{compactWarnMsg}}&nbsp;
</kendo-formhint>
<kendo-formerror title="{{getErrorMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)}}"
    *ngIf="getErrorMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' ">
    {{compactErrorMsg}}&nbsp;

</kendo-formerror> -->
<!-- </div> -->

<!-- Removed tooltip -->
<kendo-formhint class="custom-info-message"
    *ngIf="getInfoMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' ">
    {{compactInfoMsg}}&nbsp;
</kendo-formhint>
<kendo-formhint class="custom-warning-message"
    *ngIf="getWarnMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' && getErrorMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)==''">
    {{compactWarnMsg}}&nbsp;
</kendo-formhint>
<kendo-formerror *ngIf="getErrorMessageByDatasetIDandXbrlName(datasetIdInput,xbrlNameInput)!='' ">
    {{compactErrorMsg}}&nbsp;
</kendo-formerror>