<!-- <form [formGroup]="form"
    *ngIf="form.controls['document_withoutReferenceValidation'].value!=true || (form.controls['document_withoutReferenceValidation'].value==true && !isReadOnly)"> -->
<!-- <form [formGroup]="form">

    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                #toolTipGridTemplate let-anchor>
                <span>{{ label }}</span>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">


                <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [id]="xbrlName+'-'+datasetId"
                    type="text" type="decimal" [decimals]="2" [formControlName]="xbrlName" [changeValueOnScroll]="false"
                    [class]="fieldClass" [format]="formatOptions" autocomplete="off" [readonly]="isReadOnly"
                    [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                    [inert]="(isReadOnly || isArchiv) || (isReadOnly && isArchiv)">
                </kendo-numerictextbox>
            </div>
        </kendo-floatinglabel>

        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">
            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>

    </kendo-formfield>

</form> -->
<div class="row">
    <div [class]='(isReadOnly  && !(stareInputFieldsService.isBlocked(xbrlName)) ) ? "column-for-with-plus" : "column"'>

        <form [formGroup]="form">

            <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

                <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                        #toolTipGridTemplate let-anchor>
                        <span>{{ label }}</span>
                    </ng-template>
                    <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                        (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">


                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                            [id]="xbrlName+'-'+datasetId" type="text" type="decimal" [decimals]="2"
                            [formControlName]="xbrlName" [changeValueOnScroll]="false" [class]="fieldClass"
                            [format]="formatOptions" autocomplete="off" [readonly]="isReadOnly"
                            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                            [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)">
                        </kendo-numerictextbox>
                    </div>
                </kendo-floatinglabel>

                <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class"
                    style="padding-left: 10px; padding-right: 10px;">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
                </div>

            </kendo-formfield>

        </form>
    </div>
    <div class="column-for-plus" *ngIf="isReadOnly   && !(stareInputFieldsService.isBlocked(xbrlName)) ">
        <app-reference-disable-button (onReferenceDisable)="onReferenceDisableReciever($event)"
            [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlName"
            [disabled]="isArchiv"></app-reference-disable-button>
    </div>

</div>