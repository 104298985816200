import { Component, ViewChild, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexDataLabels,
	ApexStroke,
	ApexMarkers,
	ApexYAxis,
	ApexGrid,
	ApexFill,
	ApexTitleSubtitle,
	ApexTooltip,
	ApexLegend
} from "ng-apexcharts";
import { KpiForAdminModel } from '../_models/kpi-client.models';
import { ListsService } from 'app/_services/lists.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiKPIService } from 'app/_services/StareApi/kpi.service';

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	xaxis: ApexXAxis;
	stroke: ApexStroke;
	dataLabels: ApexDataLabels;
	markers: ApexMarkers;
	colors: string[];
	yaxis: ApexYAxis;
	grid: ApexGrid;
	fill: ApexFill;
	legend: ApexLegend;
	title: ApexTitleSubtitle;
	tooltip: ApexTooltip;
};


@Component({
	selector: 'app-admin-dashboard',
	templateUrl: './admin-dashboard.component.html',
	styleUrls: ['./admin-dashboard.component.scss'],
	standalone: false
})



export class AdminDashboardComponent implements OnInit {



	// Charts from: https://apexcharts.com/angular-chart-demos/line-charts/line-with-data-labels/

	// Later: https://stackoverflow.com/questions/66726285/apex-chart-not-working-properly-with-angular

	@ViewChild("chartObj") chart: ChartComponent = {} as ChartComponent;
	@ViewChild("chartObj2") chart2: ChartComponent = {} as ChartComponent;
	@ViewChild("chartObj3") chart3: ChartComponent = {} as ChartComponent;

	@ViewChild("chartObj4") chart4: ChartComponent = {} as ChartComponent;

	public chartOptions: Partial<any> = {} as Partial<any>;
	public chartOptions2: Partial<any> = {} as Partial<any>;

	public chartOptions3: Partial<any> = {} as Partial<any>;

	public chartOptions4: Partial<any> = {} as Partial<any>;
	//public chartOptions: Partial<ChartOptions> = {} as Partial<ChartOptions>;

	public countTotalWorkflows: number = 0;
	public countCurrentWorkflows: number = 0;
	public countTotalPages: number = 0;
	public countTotalRateableValue: number = 0;
	public countTotalPropertyTaxBaseAssessment: number = 0;
	public countTotalCombiRateableValuePropertyTaxBaseAssessment: number = 0;
	public countTotalTradeTaxBaseAssessment: number = 0;
	public countTotalTradeTaxLossCarryForward: number = 0;
	public countTotalTradeTaxBreakdown: number = 0;
	public countTotalPropertyTax: number = 0;
	public countTotalTradeTax: number = 0;
	public countTotalCorporateIncomeTax: number = 0;
	public countTotalCorporateIncomeTaxEBITDA: number = 0;
	public countTotalCorporateIncomeTaxAddCarryForward: number = 0;
	public countTotalCorporateIncomeTaxPrePayment: number = 0;
	public countTotalCorporateIncomeTaxLossCarryForward: number = 0;
	public countTotalCorporateIncomeTaxSeparateAssessmentP27: number = 0;
	public countTotalCorporateIncomeTaxSeparateAndUniformDetermination: number = 0;
	public countTotalCorporateIncomeTaxSaUControllingCompany: number = 0;
	public countTotalValueAddedTax: number = 0;
	public countTotalChamberOfCommerceAndIndustry: number = 0;
	public countTotalTradeTaxAddCarryForward: number = 0;

	public initialCheck: string = "";
	public review: string = "";
	public appealInitialCheck: string = "";
	public appealReview: string = "";
	public appeal: string = "";
	public date: string = "";
	public numberOf: string = "";
	public inEachStep: string = "";
	public inSystem: string = "";
	public inProgress: string = "";
	public finished: string = "";
	public addedNew: string = "";
	public minDuration: string = "";
	public average: string = "";
	public maxDuration: string = "";

	public nameOfRateableValue: string = '';
	public nameOfPropertyTaxBaseAssessment: string = '';
	public nameOfCombiRateablePropertyTaxBaseAssessment: string = '';
	public nameOfTradeTaxLossCarryForward: string = '';
	public nameOfTradeTaxBaseAmount: string = '';
	public nameOfTradeTaxBreakdown: string = '';
	public nameOfPropertyTax: string = '';
	public nameOfTradeTax: string = '';
	public nameOfCorporateIncomeTax: string = '';
	public nameOfCorporateIncomeTaxEBITDA: string = '';
	public nameOfCorporateIncomeTaxAddCarryForward: string = '';
	public nameOfCorporateIncomeTaxPrePayment: string = '';
	public nameOfCorporateIncomeTaxLossCarryForward: string = '';
	public nameOfCorporateIncomeTaxSeparateAssessmentP27: string = '';
	public nameOfCorporateIncomeTaxSeparateAndUniformDetermination: string = '';
	public nameOfCorporateIncomeTaxSaUControllingCompany: string = '';
	public nameOfValueAddedTax: string = '';
	public nameOfChamberOfCommerceAndIndustry: string = '';
	public nameOfTradeTaxAddCarryForward: string = '';



	public commonOptions: Partial<any> = {
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: "smooth"
		},
		toolbar: {
			tools: {
				selection: false
			}
		},
		markers: {
			size: 1,
			hover: {
				size: 4
			}
		},
		tooltip: {
			followCursor: false,
			theme: "dark",
			x: {
				show: false
			},
			marker: {
				show: false,
			},
			y: {
				title: {
					formatter: function () {
						return "";
					}
				}
			}
		},
		grid: {
			borderColor: "#e7e7e7",
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5
			}
		},
		xaxis: {
			type: "datetime"
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
			floating: true,
			offsetY: 0,
			offsetX: 15
		}
	}


	constructor(
		private translateService: TranslateService,
		private titleService: Title,
		private dateTimeService: DateTimeService,
		private apiKPIService: ApiKPIService,
		private listsService: ListsService
	) {


		this.chartOptions = {
			series: [
				{
					name: "High - 2013",
					data: [28, 29, 33, 36, 32, 32, 33]
				},
				{
					name: "Low - 2013",
					data: [12, 11, 14, 18, 17, 13, 13]
				}
			],
			chart: {
				id: "yt",
				height: 350,
				type: "line",
				group: "social",
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2
				},
				toolbar: {
					show: true
				}
			},
			colors: ["#132043", "#415385", "#62719A", "#9AA4BE", "#D2D7E2"],

			title: {
				text: this.inEachStep,
				align: "left"
			},
			yaxis: {
				title: {
					text: this.numberOf
				},
				min: 0
			},

		};

		this.chartOptions2 = {
			series: [
				{
					name: "High - 2013",
					data: [28, 29, 33, 36, 32, 32, 33]
				},
				{
					name: "Low - 2013",
					data: [12, 11, 14, 18, 17, 13, 13]
				}
			],
			chart: {
				id: "jj",
				height: 350,
				group: "social",
				type: "line",
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2
				},
				toolbar: {
					show: true
				}
			},
			colors: ["#132043", "#415385", "#62719A"],
			title: {
				text: this.inSystem,
				align: "left"
			},
			yaxis: {
				title: {
					text: this.numberOf
				},
				min: 0
			},

		};
		this.chartOptions3 = {
			series: [
				{
					name: "High - 2013",
					data: [28, 29, 33, 36, 32, 32, 33]
				},
				{
					name: "Low - 2013",
					data: [12, 11, 14, 18, 17, 13, 13]
				}
			],
			chart: {
				id: "yt",
				height: 350,
				type: "line",
				group: "social",
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2
				},
				toolbar: {
					show: true
				}
			},
			colors: ["#132043", "#415385", "#62719A", "#9AA4BE", "#D2D7E2"],

			title: {
				text: this.inEachStep,
				align: "left"
			},
			yaxis: {
				title: {
					text: this.numberOf
				},
				min: 0
			},

		};
		this.chartOptions4 = {
			series: [
				{
					name: "High - 2013",
					data: [28, 29, 33, 36, 32, 32, 33]
				},
				{
					name: "Low - 2013",
					data: [12, 11, 14, 18, 17, 13, 13]
				}
			],
			chart: {
				id: "jj",
				height: 350,
				group: "social",
				type: "line",
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2
				},
				toolbar: {
					show: true
				}
			},
			colors: ["#132043", "#415385", "#62719A"],
			title: {
				text: this.inSystem,
				align: "left"
			},
			yaxis: {
				title: {
					text: this.numberOf
				},
				min: 0
			},

		};
	}




	ngOnInit(): void {

		this.nameOfRateableValue = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentRateableValue')?.labelMiddleDE ?? 'NA';
		this.nameOfPropertyTaxBaseAssessment = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentPropertyTaxBaseAssessment')?.labelMiddleDE ?? 'NA';
		this.nameOfCombiRateablePropertyTaxBaseAssessment = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCombiRateableValueAndPropertyTaxBaseAssessment')?.labelMiddleDE ?? 'NA';
		this.nameOfTradeTaxLossCarryForward = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentTradeTaxLossCarryForward')?.labelMiddleDE ?? 'NA';
		this.nameOfTradeTaxBaseAmount = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentTradeTaxBaseAssessment')?.labelMiddleDE ?? 'NA';
		this.nameOfTradeTaxBreakdown = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentTradeTaxBreakdown')?.labelMiddleDE ?? 'NA';
		this.nameOfPropertyTax = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentPropertyTax')?.labelMiddleDE ?? 'NA';
		this.nameOfTradeTax = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentTradeTax')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTax = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTax')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxEBITDA = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxEBITDA')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxAddCarryForward = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxAddCarryForward')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxPrePayment = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxPrePayment')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxLossCarryForward = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxLossCarryForward')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxSeparateAssessmentP27 = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxSeparateAssessmentP27')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxSeparateAndUniformDetermination = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxSeparateAndUniformDetermination')?.labelMiddleDE ?? 'NA';
		this.nameOfCorporateIncomeTaxSaUControllingCompany = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentCorporateIncomeTaxSaUControllingCompany')?.labelMiddleDE ?? 'NA';
		this.nameOfValueAddedTax = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentValueAddedTax')?.labelMiddleDE ?? 'NA';
		this.nameOfChamberOfCommerceAndIndustry = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentChamberOfCommerceAndIndustry')?.labelMiddleDE ?? 'NA';
		this.nameOfTradeTaxAddCarryForward = this.listsService.dataTypeItemList.find(x => x.name == 'DocumentTradeTaxAddCarryForward')?.labelMiddleDE ?? 'NA';



		this.translateService.get('APP.DASHBOARD.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});
		this.translateService.get('APP.DASHBOARD.CHART.INITIAL_CHECK').subscribe((title: string) => {
			this.initialCheck = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.REVIEW').subscribe((title: string) => {
			this.review = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.APPEAL_INITIAL').subscribe((title: string) => {
			this.appealInitialCheck = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.APPEAL_REVIEW').subscribe((title: string) => {
			this.appealReview = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.APPEAL').subscribe((title: string) => {
			this.appeal = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.DATE').subscribe((title: string) => {
			this.date = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.NUMBER_OF').subscribe((title: string) => {
			this.numberOf = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.IN_EACH_STEP').subscribe((title: string) => {
			this.inEachStep = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.IN_SYSTEM').subscribe((title: string) => {
			this.inSystem = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.IN_PROGRESS').subscribe((title: string) => {
			this.inProgress = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.FINISHED').subscribe((title: string) => {
			this.finished = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.ADDED_NEW').subscribe((title: string) => {
			this.addedNew = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.MIN_DURATION').subscribe((title: string) => {
			this.minDuration = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.AVERAGE').subscribe((title: string) => {
			this.average = title;
		});
		this.translateService.get('APP.DASHBOARD.CHART.MAX_DURATION').subscribe((title: string) => {
			this.maxDuration = title;
		});



		this.loadDashboard();
	}

	// == Values for SDC

	public countTotalWorkflowsSDC: number = 0;
	public countCurrentWorkflowsSDC: number = 0;
	public countTotalPagesSDC: number = 0;
	public countTotalRateableValueSDC: number = 0;
	public countTotalPropertyTaxBaseAssessmentSDC: number = 0;
	public countTotalCombiRateableValuePropertyTaxBaseAssessmentSDC: number = 0;
	public countTotalTradeTaxBaseAssessmentSDC: number = 0;
	public countTotalTradeTaxLossCarryForwardSDC: number = 0;
	public countTotalTradeTaxBreakdownSDC: number = 0;
	public countTotalPropertyTaxSDC: number = 0;
	public countTotalTradeTaxSDC: number = 0;
	public countTotalCorporateIncomeTaxSDC: number = 0;
	public countTotalCorporateIncomeTaxEBITDASDC: number = 0;
	public countTotalCorporateIncomeTaxAddCarryForwardSDC: number = 0;
	public countTotalCorporateIncomeTaxPrePaymentSDC: number = 0;
	public countTotalCorporateIncomeTaxLossCarryForwardSDC: number = 0;
	public countTotalCorporateIncomeTaxSeparateAssessmentP27SDC: number = 0;
	public countTotalCorporateIncomeTaxSeparateAndUniformDeterminationSDC: number = 0;
	public countTotalCorporateIncomeTaxSaUControllingCompanySDC: number = 0;
	public countTotalValueAddedTaxSDC: number = 0;
	public countTotalChamberOfCommerceAndIndustrySDC: number = 0;
	public countTotalTradeTaxAddCarryForwardSDC: number = 0;

	// == Build Dashboards ================================================================================================================================



	/**
	 * Load the dashboard from API.
	 */
	loadDashboard(): void {
		this.apiKPIService.getAllAdminKpis().subscribe((data: KpiForAdminModel[]) => {
			console.info("data", data);

			let seriesState = Array<any>();
			seriesState.push({
				name: this.initialCheck,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsInPreparation)
			});
			seriesState.push({
				name: this.review,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsInReview)
			});
			seriesState.push({
				name: this.appealInitialCheck,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsInLegalRemedyCheck)
			});
			seriesState.push({
				name: this.appealReview,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsInLegalRemedy)
			});
			seriesState.push({
				name: this.appeal,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsInLegalRemedyReview)
			});

			data.map((kpi: KpiForAdminModel) => {
				//console.info("kpi", kpi.jobsTotal);
			});

			this.chartOptions.series = seriesState;

			this.commonOptions.xaxis = {
				categories: data.map((kpi: KpiForAdminModel) => (this.dateTimeService.convertUTCTimeToLocalTime(kpi.date))!.setHours(3)),
				//min: new Date(data[1].date).getTime(),
				title: {
					text: this.date
				},
				tickAmount: 10,
				type: "datetime"
			}

			// Further KPIs
			// Get the last entrie of the array
			// this.countTotalWorkflows = data[data.length - 1].workflowsTotal;
			this.countCurrentWorkflows = data[data.length - 1].workflowsActive;
			this.countTotalRateableValue = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfRateableValue, 0);
			this.countTotalPropertyTaxBaseAssessment = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfPropertyTaxBaseAssessment, 0);
			this.countTotalCombiRateableValuePropertyTaxBaseAssessment = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCombiRateablePropertyTaxBaseAssessment, 0);
			this.countTotalTradeTaxBaseAssessment = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfTradeTaxBaseAssessment, 0);
			this.countTotalTradeTaxLossCarryForward = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfTradeTaxLossCarryForward, 0);
			this.countTotalTradeTaxBreakdown = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfTradeTaxBreakdown, 0);
			this.countTotalPropertyTax = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfPropertyTax, 0);
			this.countTotalTradeTax = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfTradeTax, 0);
			this.countTotalCorporateIncomeTax = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTax, 0);
			this.countTotalCorporateIncomeTaxEBITDA = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxEBITDA, 0);
			this.countTotalCorporateIncomeTaxAddCarryForward = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxAddCarryForward, 0);
			this.countTotalCorporateIncomeTaxPrePayment = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxPrePayment, 0);
			this.countTotalCorporateIncomeTaxLossCarryForward = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxLossCarryForward, 0);
			this.countTotalCorporateIncomeTaxSeparateAssessmentP27 = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxSeparateAssessmentP27, 0);
			this.countTotalCorporateIncomeTaxSeparateAndUniformDetermination = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxSeparateAndUniformDetermination, 0);
			this.countTotalCorporateIncomeTaxSaUControllingCompany = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfCorporateIncomeTaxSaUControllingCompany, 0);
			this.countTotalValueAddedTax = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfValueAddedTax, 0);
			this.countTotalChamberOfCommerceAndIndustry = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfChamberOfCommerceAndIndustry, 0);
			this.countTotalTradeTaxAddCarryForward = data.reduce((sum, kpi) => sum + kpi.workflowsFinishedOfTradeTaxAddCarryForward, 0);

			//For SDC
			this.countCurrentWorkflowsSDC = data[data.length - 1].sdcWorkflowsActive;
			this.countTotalRateableValueSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfRateableValue, 0);
			this.countTotalPropertyTaxBaseAssessmentSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfPropertyTaxBaseAssessment, 0);
			this.countTotalCombiRateableValuePropertyTaxBaseAssessmentSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCombiRateablePropertyTaxBaseAssessment, 0);
			this.countTotalTradeTaxBaseAssessmentSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfTradeTaxBaseAssessment, 0);
			this.countTotalTradeTaxLossCarryForwardSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfTradeTaxLossCarryForward, 0);
			this.countTotalTradeTaxBreakdownSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfTradeTaxBreakdown, 0);
			this.countTotalPropertyTaxSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfPropertyTax, 0);
			this.countTotalTradeTaxSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfTradeTax, 0);
			this.countTotalCorporateIncomeTaxSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTax, 0);
			this.countTotalCorporateIncomeTaxEBITDASDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxEBITDA, 0);
			this.countTotalCorporateIncomeTaxAddCarryForwardSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxAddCarryForward, 0);
			this.countTotalCorporateIncomeTaxPrePaymentSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxPrePayment, 0);
			this.countTotalCorporateIncomeTaxLossCarryForwardSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxLossCarryForward, 0);
			this.countTotalCorporateIncomeTaxSeparateAssessmentP27SDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxSeparateAssessmentP27, 0);
			this.countTotalCorporateIncomeTaxSeparateAndUniformDeterminationSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxSeparateAndUniformDetermination, 0);
			this.countTotalCorporateIncomeTaxSaUControllingCompanySDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfCorporateIncomeTaxSaUControllingCompany, 0);
			this.countTotalValueAddedTaxSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfValueAddedTax, 0);
			this.countTotalChamberOfCommerceAndIndustrySDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfChamberOfCommerceAndIndustry, 0);
			this.countTotalTradeTaxAddCarryForwardSDC = data.reduce((sum, kpi) => sum + kpi.sdcWorkflowsFinishedOfTradeTaxAddCarryForward, 0);

			this.countTotalWorkflowsSDC = this.countTotalRateableValueSDC +
				this.countTotalPropertyTaxBaseAssessmentSDC +
				this.countTotalCombiRateableValuePropertyTaxBaseAssessmentSDC +
				this.countTotalTradeTaxBaseAssessmentSDC +
				this.countTotalTradeTaxLossCarryForwardSDC +
				this.countTotalTradeTaxBreakdownSDC +
				this.countTotalPropertyTaxSDC +
				this.countTotalTradeTaxSDC +
				this.countTotalCorporateIncomeTaxSDC +
				this.countTotalCorporateIncomeTaxEBITDASDC +
				this.countTotalCorporateIncomeTaxAddCarryForwardSDC +
				this.countTotalCorporateIncomeTaxPrePaymentSDC +
				this.countTotalCorporateIncomeTaxLossCarryForwardSDC +
				this.countTotalCorporateIncomeTaxSeparateAssessmentP27SDC +
				this.countTotalCorporateIncomeTaxSeparateAndUniformDeterminationSDC +
				this.countTotalCorporateIncomeTaxSaUControllingCompanySDC +
				this.countTotalValueAddedTaxSDC +
				this.countTotalChamberOfCommerceAndIndustrySDC +
				this.countTotalTradeTaxAddCarryForwardSDC;

			let seriesState3 = Array<any>();
			seriesState3.push({
				name: this.initialCheck,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsInPreparation)
			});
			seriesState3.push({
				name: this.review,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsInReview)
			});
			seriesState3.push({
				name: this.appealInitialCheck,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsInLegalRemedyCheck)
			});
			seriesState3.push({
				name: this.appealReview,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsInLegalRemedy)
			});
			seriesState3.push({
				name: this.appeal,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsInLegalRemedyReview)
			});

			// data.map((kpi: KpiForAdminModel) => {
			// 	//console.info("kpi", kpi.jobsTotal);
			// });

			this.chartOptions3.series = seriesState3;

			let seriesState4 = Array<any>();
			seriesState4.push({
				name: this.inProgress,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsActive)
			});
			seriesState4.push({
				name: this.finished,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsFinished)
			});
			seriesState4.push({
				name: this.addedNew,
				data: data.map((kpi: KpiForAdminModel) => kpi.sdcWorkflowsCreated)
			});

			this.chartOptions4.series = seriesState4;

			// ======================


			this.countTotalWorkflows = this.countTotalRateableValue +
				this.countTotalPropertyTaxBaseAssessment +
				this.countTotalCombiRateableValuePropertyTaxBaseAssessment +
				this.countTotalTradeTaxBaseAssessment +
				this.countTotalTradeTaxLossCarryForward +
				this.countTotalTradeTaxBreakdown +
				this.countTotalPropertyTax +
				this.countTotalTradeTax +
				this.countTotalCorporateIncomeTax +
				this.countTotalCorporateIncomeTaxEBITDA +
				this.countTotalCorporateIncomeTaxAddCarryForward +
				this.countTotalCorporateIncomeTaxPrePayment +
				this.countTotalCorporateIncomeTaxLossCarryForward +
				this.countTotalCorporateIncomeTaxSeparateAssessmentP27 +
				this.countTotalCorporateIncomeTaxSeparateAndUniformDetermination +
				this.countTotalCorporateIncomeTaxSaUControllingCompany +
				this.countTotalValueAddedTax +
				this.countTotalChamberOfCommerceAndIndustry +
				this.countTotalTradeTaxAddCarryForward;

			// Bescheide im System
			let seriesState2 = Array<any>();
			seriesState2.push({
				name: this.inProgress,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsActive)
			});
			seriesState2.push({
				name: this.finished,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsFinished)
			});
			seriesState2.push({
				name: this.addedNew,
				data: data.map((kpi: KpiForAdminModel) => kpi.workflowsCreated)
			});

			this.chartOptions2.series = seriesState2;


			// // Verarbeitungsdauer
			// let seriesState3 = Array<any>();
			// seriesState3.push({
			// 	name: this.minDuration,
			// 	data: data.map((kpi: KpiForAdminModel) => (kpi.workflowsDurationToFinishedMin / 3600).toFixed(2))
			// });
			// seriesState3.push({
			// 	name: this.average,
			// 	data: data.map((kpi: KpiForAdminModel) => (kpi.workflowsDurationToFinished / 3600).toFixed(2))
			// });

			// seriesState3.push({
			// 	name: this.maxDuration,
			// 	data: data.map((kpi: KpiForAdminModel) => (kpi.workflowsDurationToFinishedMax / 3600).toFixed(2))
			// });


			// this.chartOptions3.series = seriesState3;



		});

	}

	public formatNumber(value: number): string {
		return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
	}



	// goToYoda() {
	// 	window.open("https://yoda.pwc.de/pages/stare", "_blank");
	// }

}
