<div class="stareTopic">
    {{ 'APP.MAIL_EDITOR.TITLE' | translate }}
</div>
<br>
<div style="display: flex; justify-content:space-between;">
    <div style="float: left;">

        <!-- Some PDF functions -->
        <button kendoButton (click)=" pdfZoomIn()" type="button" [svgIcon]="zoomInSVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.ZOOM_IN' | translate }}">
        </button> &nbsp;

        <button kendoButton (click)=" pdfZoomOut()" type="button" [svgIcon]="zoomOutSVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.ZOOM_OUT' | translate }}">
        </button>&nbsp;

        <button kendoButton (click)=" pdfZoom100()" type="button" [svgIcon]="zoom100SVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.STANDARD' | translate }}">
        </button>&nbsp;

        <button kendoButton (click)=" pdfZoomBestFit()" [toggleable]="true" type="button" [svgIcon]="zoomBestFitSVG"
            kendoTooltip title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.TO_PAGE_SIZE' | translate }}">
        </button>&nbsp;&nbsp;|&nbsp;&nbsp;
        <span class="plainText">{{pageCountText}},<span>{{filename}}</span></span>
    </div>

    <div style=" text-align: center;">
        <span></span>
    </div>

    <div>
        <!--Buttons  Return, Save, Next stage -->

        <button kendoButton type="submit" (click)="onClickCancelButton()" [svgIcon]="backSVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.BACK_TO_TASKS' | translate }}">
        </button> &nbsp;&nbsp;|&nbsp;&nbsp;

        <button kendoButton type="submit" [disabled]="!editorForm.valid && isEditable" (click)="onSubmit()" kendoTooltip
            #btnSave title="Speichert den aktuellen Zustand der E-Mail.">
            Speichern
        </button> &nbsp;
        <app-workflow-split-button kendoTooltip class="plainText" (action)="onAction($event)" [workflowId]="workflowId"
            #btnWorkflowSplit [actualStateId]="actualStateId" (onMainButtonClick)="onExecute($event)"
            [buttonText]="buttonText" [title]="buttonText" [disabled]="splitDisabled">
        </app-workflow-split-button>

    </div>
</div>

<br>
<br />
<kendo-splitter>
    <kendo-splitter-pane id="pdf-layout-panel" size="50%" min="200px" (sizeChange)="pdfLayputSizeChange()"
        style="overflow-y:auto;" [ngStyle]="{'overflow-y':'auto', 'height.px': pageHeight}">

        <div style="width: 100%" #canvas id="pdf-wrapper"></div>

    </kendo-splitter-pane>


    <kendo-splitter-pane style="overflow-y:auto;" [ngStyle]="{'overflow-y':'auto', 'height.px': pageHeight}">
        <div class="k-overlay" *ngIf="isFormLoading "></div>
        <div *ngIf="isFormLoading" class="k-i-loading"></div>



        <div>
            <form class="k-form k-form-md k-form-horizontal" style="width: 800px" [formGroup]="editorForm"
                style="padding-left: 8px;">
                <fieldset class="fieldset">
                    <legend>&nbsp;(E-Mail: {{workflowId}})&nbsp;
                    </legend>

                    <kendo-formfield orientation="horizontal">
                        <kendo-label class="plainText" labelCssClass="k-form-label" for="to" id="toLabel"
                            [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.TO.LABEL' | translate"></kendo-label>
                        <kendo-textbox type="text" id="to" formControlName="to" autocomplete="off">
                        </kendo-textbox>
                        <kendo-formerror>
                            {{
                            'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.TO.ERROR.REQUIRED' |
                            translate
                            }}
                        </kendo-formerror>
                    </kendo-formfield>
                    <kendo-formfield orientation="horizontal">
                        <kendo-label class="plainText" labelCssClass="k-form-label" for="cc"
                            [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.CC.LABEL' | translate"></kendo-label>
                        <kendo-textbox id="cc" formControlName="cc" type="text" autocomplete="off"></kendo-textbox>
                        <kendo-formerror>
                            {{
                            'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.CC.ERROR.REQUIRED' |
                            translate
                            }}
                        </kendo-formerror>
                    </kendo-formfield>
                    <kendo-formfield orientation="horizontal">
                        <kendo-label class="plainText" labelCssClass="k-form-label" id="subLabel" for="subject"
                            [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SUBJECT.LABEL' | translate"></kendo-label>
                        <kendo-textbox type="text" id="subject" formControlName="subject"
                            autocomplete="off"></kendo-textbox>
                        <kendo-formerror>
                            {{
                            'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.SUBJECT.ERROR.REQUIRED' |
                            translate
                            }}
                        </kendo-formerror>
                    </kendo-formfield>
                    <kendo-formfield orientation="horizontal">

                        <kendo-editor id="body" class="payload" formControlName="body" [resizable]="resizable"
                            [iframe]="false">
                            <kendo-toolbar #toolbar [overflow]="true">
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                <kendo-toolbar-dropdownlist kendoEditorFontSize
                                    [data]="fontData"></kendo-toolbar-dropdownlist>
                                <kendo-toolbar-buttongroup>
                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                                </kendo-toolbar-buttongroup>
                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                <kendo-toolbar-buttongroup>



                                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>

                                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>

                                </kendo-toolbar-buttongroup>

                            </kendo-toolbar>

                        </kendo-editor>
                    </kendo-formfield>

                    <br>
                    <br />
                    <kendo-formfield showHints="always" showErrors="always" orientation="horizontal">

                        <ng-template #toolTipGridTemplate let-anchor>
                            <span>{{(anchor.nativeElement.innerText=='') ? 'Datei herunterladen' :
                                anchor.nativeElement.innerText}}
                            </span>

                        </ng-template>
                        <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
                            style="width: 900px" (mouseover)="showTooltip($event)"
                            [ngStyle]="{'height.px': pageHeight}">
                            <kendo-grid #grid [kendoGridBinding]="attachments" scrollable="none" [hideHeader]="true"
                                [selectable]="true" kendoGridFocusable (cellClick)="cellClickHandler($event)"
                                [navigable]="true">
                                <kendo-grid-messages [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                                    [noRecords]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.NO_ATTACHMENTS.LABEL' | translate">
                                </kendo-grid-messages>
                                <kendo-grid-column field="filename" [width]="200">

                                </kendo-grid-column>
                                <kendo-grid-column [width]="100">
                                    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                                        <button kendoButton type="button" [svgIcon]="downloadSVG"
                                            (click)="onClickEvent(grid.activeRow)">
                                        </button>
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </kendo-formfield>
                    <br>
                    <br>
                </fieldset>
                <br>
            </form>
            <br>
            <br>
            <br>
        </div>
    </kendo-splitter-pane>
</kendo-splitter>