
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLocal } from 'app/_models/user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';


import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { FileItem } from 'app/_models/file.model';
import * as FileSaver from 'file-saver';
import { CellClickEvent, NavigationRow } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ApiFileService } from 'app/_services/StareApi/file.service';
import { SVGIcon, undoIcon } from '@progress/kendo-svg-icons';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';
import { MailSendingService } from 'app/_services/StareApi/mail-sending.service';
import { MailForSendingItem } from 'app/_models/mail-for-sending.model';
import { PaletteSettings } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'app-mail-editor-view',
  templateUrl: './mail-editor-view.component.html',
  styleUrl: './mail-editor-view.component.scss',
  standalone: false
})
export class MailEditorViewComponent implements OnInit {

  downloadSuccess: boolean = true;
  public finishedLoading = false;
  public attachments: FileItem[] = [];
  public clientMail?: MailForSendingItem;
  public mailSendingId: number = 0;
  public actualStateId: number = 0;
  public clientEngagementKey: string = '';
  public currentUser: UserLocal = {} as UserLocal;
  public isFormLoading: boolean = false;
  public isEditable: boolean = false;
  public paletteSettings: PaletteSettings = { palette: "basic" };

  // ========================================================================
  constructor(

    private titleService: Title,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private apiMailSendingSErvice: MailSendingService,
    private notificationService: WrapperNotificationService,
    private fileservice: ApiFileService

  ) { }


  ngOnInit(): void {

    this.translateService.get('APP.MAIL_EDITOR.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    this.route.queryParams.subscribe(params => {
      this.mailSendingId = params.w ?? 0;

      //#region Safety checks

      // If no workflowId is given, redirect to tasks
      if (this.mailSendingId == 0) {
        this.router.navigate(['/tasks']);
      }


      //#endregion

      // this.apiWorkflowService.getWorkflowMetaDataById(this.mailSendingId).subscribe((response: IWorkflowMetadata) => {
      //   if (this.actualStateId != response.actualStateId) {
      //     this.router.navigate(['/tasks']);
      //   }
      //   this.isEditable = response.isEditable;

      //   this.ButtonDisable(!response.isEditable);
      // });


      this.apiMailSendingSErvice.getClientMailById(this.mailSendingId).subscribe({
        next: (res: MailForSendingItem) => {
          this.clientMail = res as MailForSendingItem;
          this.editorForm.patchValue({
            to: res.to,
            cc: res.cc,
            subject: res.subject,
            body: res.body
          });
          this.attachments = res.attachements ? res.attachements : [];
        },
        error: (err: HttpErrorResponse) => {
          console.error(err.message);
          this.finishedLoading = true;
        },
        complete: () => {

          this.finishedLoading = true;
        }
      });
    });

  }

  // == Resizing the grid ==================================================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Helpers ==================================================================

  commaSepEmailTo = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value?.split(',') || [];;
    const forbidden = emails.some((email: any) => Validators.email(new FormControl(email?.trim())));
    return forbidden ? { 'to': { value: control.value } } : null;
  };

  commaSepEmailCc = (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == '' || control.value == null)
      return null;
    // console.log(control.value);
    const emails = control.value?.split(',');
    const forbidden = emails.some((email: any) => Validators.email(new FormControl(email?.trim())));
    return forbidden ? { 'cc': { value: control.value } } : null;
  };


  // == Forms =========================================================================
  public editorForm: FormGroup = new FormGroup({
    to: new FormControl("", [Validators.required, this.commaSepEmailTo]),
    cc: new FormControl("", [this.commaSepEmailCc]),
    body: new FormControl("", Validators.required),
    subject: new FormControl("", Validators.required),
  });



  // == E-Mail Settings ============================================

  // public fontData = [
  //   { text: "24px", size: "24px" },
  //   { text: "2rem", size: "2rem" },
  //   { text: "1em", size: "1em" },
  //   { text: "150%", size: "150%" },
  // ];

  public fontData = [
    { text: "7 pt", size: "7pt" },
    { text: "8 pt", size: "8pt" },
    { text: "9 pt", size: "9pt" },
    { text: "10 pt", size: "10pt" },
    { text: "11 pt", size: "11pt" },
    { text: "12 pt", size: "12pt" },
    { text: "14 pt", size: "14pt" },
    { text: "16 pt", size: "16pt" },
  ];

  public resizable: boolean | EditorResizableOptions = {
    minWidth: 900,
    minHeight: 640,
    maxWidth: 1500,
    maxHeight: 650,
  };


  // == Handlers ================================

  @ViewChild('btnSave', { static: false }) btnSave: any;
  @ViewChild('btnExecute', { static: false }) btnExecute: any;
  @ViewChild('btnReset', { static: false }) btnReset: any;

  public backSVG: SVGIcon = undoIcon;

  public ButtonDisable(disable: boolean = true): void {
    this.btnSave.disabled = disable;
    this.btnExecute.disabled = disable;
    this.btnReset.disabled = disable;
  }

  public onClickCancelButton(event: MouseEvent): void {
    this.router.navigate(['/setting-sent-mail']);
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  // onSubmit() {


  //   // stop here if form is invalid
  //   if (this.editorForm.invalid || this.clientMail == undefined) {
  //     this.notificationService.showError('Es ist ein Fehler aufgetreten.');
  //     return;
  //   }

  //   this.isFormLoading = true;
  //   // Update
  //   let itemForUpdateItem = {} as ClientMailItemForUpdateItem;
  //   itemForUpdateItem = {
  //     to: this.editorForm.value.to,
  //     cc: this.editorForm.value.cc,
  //     body: this.editorForm.value.body,
  //     subject: this.editorForm.value.subject
  //   };

  //   console.log(itemForUpdateItem);

  //   this.apiClientMailingService.updateClientMailById(this.clientMail.id, itemForUpdateItem).subscribe({
  //     next: () => {
  //       this.notificationService.showSuccess('Die Mail wurde erfolgreich gespeichert.');

  //     },
  //     error: (err: HttpErrorResponse) => {
  //       this.isFormLoading = false;
  //       console.error(err);
  //       if (err.status == 400) {
  //         this.notificationService.showError('Die Daten konnten nicht verarbeitet werden.');
  //       } else {
  //         this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
  //       }
  //     },
  //     complete: () => {
  //       this.isFormLoading = false;
  //     }
  //   });
  // }


  /**
   * Execute the worklow (sending the mail)
   */
  onExecute(): void {

    if (this.clientMail) {
      this.isFormLoading = true;
      this.apiMailSendingSErvice.resendMail(this.clientMail.id).subscribe({
        next: () => {
          this.notificationService.showSuccess('Die Mail wird zum Versenden vorbereitet.');
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Mail konnte nicht versendet werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          this.isFormLoading = false;
        }
      });

    }

    // if (this.editorForm.invalid || this.clientMail == undefined) {
    //   this.notificationService.showError('Es ist ein Fehler aufgetreten.');
    //   return;
    // }




    // // First: Save current form
    // let itemForUpdateItem = {} as ClientMailItemForUpdateItem;
    // itemForUpdateItem = {
    //   to: this.editorForm.value.to,
    //   cc: this.editorForm.value.cc,
    //   body: this.editorForm.value.body,
    //   subject: this.editorForm.value.subject
    // };

    // this.apiClientMailingService.updateClientMailById(this.clientMail.id, itemForUpdateItem).subscribe({
    //   next: () => {

    //     // Absenden
    //     let triggerId = -60; // Send Mail to Review
    //     this.apiWorkflowService.executeWorkflowTrigger(this.workflowId, this.actualStateId, triggerId).subscribe({
    //       next: () => {
    //         this.router.navigate(['/tasks']);
    //       },
    //       error: (err: HttpErrorResponse) => {
    //         this.isFormLoading = false;
    //         if (err.status == 400) {
    //           this.notificationService.showError('Fehler beim Absenden: ' + err.message);
    //         } else if (err.status == 405) {
    //           this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) geändert. Bitte das Dokument erneut öffnen.');
    //         } else if (err.status == 406) {
    //           this.notificationService.showError('Es bestehen keine Rechte zur Durchführung der Aktion.');
    //         } else if (err.status == 409) {
    //           this.notificationService.showError('Es liegen Fehler vor.');
    //           //this.eventSaved.emit();
    //         } else if (err.status == 410) {
    //           this.notificationService.showError('Das Dokument wurde zwischenzeitlich in einem anderen Browserfenster (oder durch einen anderen Benutzer) gelöscht.');
    //         } else if (err.status == 416) {
    //           this.notificationService.showError('Der Type des Dokuments kann nur in der Erstprüfung verändert werden.');
    //         } else if (err.status == 424) {
    //           this.notificationService.showError('Für die Rechtsmittelprüfung muss das Bescheiddatum, Steuerpflichter und die Steuernummer (o.ä.) angegeben werden.');
    //         } else {
    //           this.notificationService.showError('Interner Fehler beim Absenden: ' + err.message);
    //         }

    //       },
    //       complete: () => {
    //         this.isFormLoading = false;
    //       }

    //     });



    //   },
    //   error: (err: HttpErrorResponse) => {
    //     this.isFormLoading = false;
    //     console.error(err);
    //     if (err.status == 400) {
    //       this.notificationService.showError('Die Daten konnten nicht verarbeitet werden.');
    //     } else {
    //       this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
    //     }
    //   },
    //   complete: () => {
    //     this.isFormLoading = false;
    //   }
    // });

  }

  // onReset() {
  //   if (this.clientMail == undefined || this.clientMail == null) {
  //     this.notificationService.showError('Es ist ein Fehler aufgetreten.Kein Data gefunden');
  //     return;
  //   }

  //   this.isFormLoading = true;
  //   this.apiClientMailingService.resetMailText(this.mailSendingId).subscribe({
  //     next: (res: ClientMailItem) => {
  //       //this.clientMail = res;
  //       this.editorForm.patchValue({
  //         to: res.to,
  //         cc: res.cc,
  //         subject: res.subject,
  //         body: res.body
  //       });
  //     },
  //     error: (err: HttpErrorResponse) => {
  //       this.isFormLoading = false;
  //       console.error(err.message);

  //       if (err.status == 409) {
  //         this.notificationService.showError('Der Bescheid ist bereits mit einer anderen E-Mail Erstellung verknüpft.');
  //       } else {
  //         this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
  //       }

  //     },
  //     complete: () => {
  //       this.isFormLoading = false;
  //     }
  //   });
  // }

  onClickEvent(item: NavigationRow): void {


    const fileItem = item.dataItem as FileItem;
    const c = this.currentUser.clientEngagementKey
    const f = fileItem.fileId;

    // Open new window to the download component
    // TO DO :here we do not need to open new component_ download can start automatically:bug 22623

    // Send file to client

    this.fileservice.downloadPdf(f).subscribe(res => {

      FileSaver.saveAs(res.blob, res.filename);
      this.notificationService.showSuccess('Die Datei wurde erfolgreich heruntergeladen');

    });

  }

  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

  //TODO: use Tooltip service 
  public showTooltip(e: MouseEvent): void {

    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.className === 'k-column-title')
      && element.offsetWidth < element.scrollWidth) {


      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
