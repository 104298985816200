import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { TaxOfficeItemForCreation, TaxOfficeItem } from 'app/_models/tax-office.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiTaxOfficeService {

  constructor(
    private http: HttpClient
  ) { }
  /**
   * Add a tax office item
   */
  addTaxOffice(item: TaxOfficeItemForCreation): Observable<TaxOfficeItem> {
    return this.http.post<TaxOfficeItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'TaxOffice/AddAsync', item, HTTP_OPTIONS);
  }

  /**
  * Update a tax office item
  * https://localhost:7051/TaxOffice/UpdateAsync?id=2
  */
  updateTaxOffice(taxOfficeId: number, item: TaxOfficeItemForCreation): Observable<TaxOfficeItem> {
    return this.http.put<TaxOfficeItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'TaxOffice/UpdateAsync?id=' + taxOfficeId, item, HTTP_OPTIONS);
  }

  /**
   * Delete a tax office item
   */
  deleteTaxOffice(taxOfficeId: number): Observable<TaxOfficeItem> {
    return this.http.delete<TaxOfficeItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'TaxOffice/DeleteAsync/' + taxOfficeId, HTTP_OPTIONS);
  }




  /**
   * Get all tax offices.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllTaxOffices(): Observable<TaxOfficeItem[]> {
    return this.http.get<TaxOfficeItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'TaxOffice/GetAllAsync', HTTP_OPTIONS);
  }



}