import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from 'environments/environment';
import { DatasetItemForRequest, DatasetItem } from 'app/_models/dataset.model';
import { DataTypeItem } from 'app/_models/data-type.model';
import { FormFieldItem } from 'app/_models/form-field.model';
import { map } from 'rxjs/operators';
import { XbrlParserService } from '../xbrl-parser.service';

const HTTP_OPTIONS = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
}

@Injectable({
    providedIn: 'root'
})
export class ApiDatasetService {

    constructor(private http: HttpClient, private xbrlParserService: XbrlParserService
    ) { }

    /**
    * API: Search reference data set
    */
    //NOT IN USE
    searchingAsync(item: DatasetItemForRequest): Observable<DatasetItem[]> {
        return this.http.post<DatasetItem[]>(ENVIRONMENT.apiBaseUrl + 'Dataset/SearchingAsync', item, HTTP_OPTIONS);
    }

    /**
 * API: Get all data types.
 */
    getAllDataTypes(): Observable<DataTypeItem[]> {
        return this.http.get<DataTypeItem[]>(ENVIRONMENT.apiBaseUrl + 'Dataset/GetTypes?forRescan=true', HTTP_OPTIONS);
    }

    /**
* API: Get all data types.
*/
    addPayloadAsync(dataType: string, workflowId: number, crossRef?: string): Observable<FormFieldItem[]> {
        if (crossRef != null && crossRef != undefined) {
            return this.http.post<FormFieldItem[]>(ENVIRONMENT.apiBaseUrl + 'Dataset/AddPayloadAsync/' + workflowId + '?datatypeName=' + dataType + "&crossReference=" + crossRef, HTTP_OPTIONS).pipe(
                map((data: FormFieldItem[]) => {
                    for (let item of data) {
                        item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName);
                    }
                    return data;
                }));
        } else {
            return this.http.post<FormFieldItem[]>(ENVIRONMENT.apiBaseUrl + 'Dataset/AddPayloadAsync/' + workflowId + '?datatypeName=' + dataType, HTTP_OPTIONS).pipe(
                map((data: FormFieldItem[]) => {
                    for (let item of data) {
                        item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName);
                    }
                    return data;
                }));
        }
    }


    /**
    * mark payload as isDeleted
    * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
    */
    public deletePayloadAsync(datasetId: number): Observable<any> {
        return this.http.delete<any>(ENVIRONMENT.apiBaseUrl + 'Dataset/DeleteAsync/' + datasetId, HTTP_OPTIONS);
    }


    /**
  * Get a dataset
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
    public getDataSetByIdAsync(datasetId: number): Observable<DatasetItem> {
        return this.http.get<DatasetItem>(ENVIRONMENT.apiBaseUrl + 'Dataset/GetByIdAsync/' + datasetId, HTTP_OPTIONS);
    }


}

