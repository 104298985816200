import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTypeItem, DataTypeItemClass } from 'app/_models/data-type.model';
import { DropDownListItem, DropDownListItemClass } from 'app/_models/dropdown-item.model';
import { DropDownStringItem, DropDownStringItemClass } from 'app/_models/dropdown-item-string.model';
import { XbrlTypeItem } from 'app/_models/xbrl-type.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { IWorkflowState } from 'app/_models/workflow-state.model';
import { XbrlParserService } from './xbrl-parser.service';
import { TaxNoticeNameItem, TaxNoticeNameItemClass } from 'app/_models/tax-notice-name.model';


const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

export enum EnumDataType {
  Xbrl,
  DataType,
  DataTypeForRescan,
  TaxDetermination,
  LegalEntities,
  CommuneAGS,
  CCI,
  AuditResults,
  AssessmentDetermination,
  LegalRemedyResult,
  LegalRemedyObjection,
  CorporateIncomeTaxCopyFor,
  VDN,
  DropDowns,
  Testcases,
  Export,
  TaxationBases,
  ReferenceYears,
  TypeOfParticipation,
  DocumentRateableValue,
  DocumentPropertyTaxBaseAssessment,
  DocumentCombiRateableValueAndPropertyTaxBaseAssessment,
  DocumentPropertyTax,
  DocumentTradeTaxLossCarryForward,
  DocumentTradeTaxAddCarryForward,
  DocumentTradeTaxBaseAssessment,
  DocumentTradeTaxBreakdown,
  DocumentTradeTax,
  DocumentCorporateIncomeTax,
  DocumentCorporateIncomeTaxAddCarryForward,
  DocumentCorporateIncomeTaxPrePayment,
  DocumentCorporateIncomeTaxLossCarryForward,
  DocumentCorporateIncomeTaxSeparateAssessmentP27,
  DocumentCorporateIncomeTaxSeparateAndUniformDetermination,
  DocumentCorporateIncomeTaxSaUControllingCompany,
  DocumentCorporateTaxEBITDA,
  DocumentCorporateTaxInterest,
  DocumentValueAddedTax,
  DocumentIncomeTax,
  DocumentChamberOfCommerceAndIndustry
}
export type EnumDataTypeStrings = keyof typeof EnumDataType;
@Injectable({
  providedIn: 'root'
})



export class ListsService {

  private _UrlXbrlTypes = ENVIRONMENT.apiBaseUrl + 'Data/GetTypes';
  private _UrlDataTypes = ENVIRONMENT.apiBaseUrl + 'Dataset/GetTypes';
  private _UrlDataTypesForRescan = ENVIRONMENT.apiBaseUrl + 'Dataset/GetTypes?forRescan=true';
  private _UrlTaxDetermination = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.taxDetermination';
  private _UrlLegalEntities = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/id.legalEntityKey';
  private _UrlCommuneAGS = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/id.communeAGS';
  private _UrlCCI = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/id.cciIdentifier';
  private _UrlAuditResults = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.auditResult';
  private _UrlAssessmentDetermination = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.assessmentDetermination';
  private _UrlLegalRemedyResult = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.legalRemedyResult';
  private _UrlLegalRemedyObjection = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.legalRemedyObjection';
  private _UrlCorporateIncomeTaxCopyFor = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.corporateIncomeTaxSaUControllingCompany.copyFor';
  private _UrlTestcases = ENVIRONMENT.maintenanceApiBaseUrl + 'TestData/GetAssessmentTypes';
  private _UrlVDN = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.corporateIncomeTax.vdn';
  private _UrlExport = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/system.dataExportType';
  private _UrlTaxationBases = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.corporateIncomeTax.taxationBases';

  private _UrlTypeOfParticipation = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/document.corporateIncomeTaxGuE.company.typeOfParticipationInteger';
  private _UrlReferenceYears = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownForCorporateIncomeTaxPrePayment/';

  private _UrlDocumentRateableValue = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentRateableValue';
  private _UrlDocumentPropertyTaxBaseAssessment = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentPropertyTaxBaseAssessment';
  private _UrlDocumentCombiRateableValueAndPropertyTaxBaseAssessment = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCombiRateableValueAndPropertyTaxBaseAssessment';
  private _UrlDocumentPropertyTax = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentPropertyTax';
  private _UrlDocumentTradeTaxLossCarryForward = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentTradeTaxLossCarryForward';
  private _UrlDocumentTradeTaxAddCarryForward = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentTradeTaxAddCarryForward';
  private _UrlDocumentTradeTaxBaseAssessment = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentTradeTaxBaseAssessment';
  private _UrlDocumentTradeTaxBreakdown = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentTradeTaxBreakdown';
  private _UrlDocumentTradeTax = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentTradeTax';
  private _UrlDocumentCorporateIncomeTax = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTax';
  private _UrlDocumentCorporateIncomeTaxAddCarryForward = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxAddCarryForward';
  private _UrlDocumentCorporateIncomeTaxPrePayment = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxPrePayment';
  private _UrlDocumentCorporateIncomeTaxLossCarryForward = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxLossCarryForward';
  private _UrlDocumentCorporateIncomeTaxSeparateAssessmentP27 = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxSeparateAssessmentP27';
  private _UrlDocumentCorporateIncomeTaxSeparateAndUniformDetermination = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxSeparateAndUniformDetermination';
  private _UrlDocumentCorporateIncomeTaxSaUControllingCompany = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateIncomeTaxSaUControllingCompany';
  private _UrlDocumentCorporateTaxEBITDA = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateTaxEBITDA';
  private _UrlDocumentCorporateTaxInterest = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentCorporateTaxInterest';
  private _UrlDocumentValueAddedTax = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentValueAddedTax';
  private _UrlDocumentIncomeTax = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentIncomeTax';
  private _UrlDocumentChamberOfCommerceAndIndustry = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/DocumentChamberOfCommerceAndIndustry';

  // lets group our cached urls in a Map, and use an enum for the keys
  // and add an optional timeout
  private cacheUrls = new Map<EnumDataType, { url: string, expiresin?: number }>();


  constructor(private http: HttpClient,
    private storageService: LocalStorageService,
    private xbrlParserService: XbrlParserService
  ) {

    // add our urls to the cache
    this.cacheUrls.set(EnumDataType.Xbrl, { url: this._UrlXbrlTypes, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DataType, { url: this._UrlDataTypes, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DataTypeForRescan, { url: this._UrlDataTypesForRescan, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.TaxDetermination, { url: this._UrlTaxDetermination, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.LegalEntities, { url: this._UrlLegalEntities, expiresin: 0.01 });
    this.cacheUrls.set(EnumDataType.CommuneAGS, { url: this._UrlCommuneAGS, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.CCI, { url: this._UrlCCI, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.AuditResults, { url: this._UrlAuditResults, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.AssessmentDetermination, { url: this._UrlAssessmentDetermination, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.LegalRemedyResult, { url: this._UrlLegalRemedyResult, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.LegalRemedyObjection, { url: this._UrlLegalRemedyObjection, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.CorporateIncomeTaxCopyFor, { url: this._UrlCorporateIncomeTaxCopyFor, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.Testcases, { url: this._UrlTestcases, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.VDN, { url: this._UrlVDN, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.Export, { url: this._UrlExport, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.TaxationBases, { url: this._UrlTaxationBases, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.TypeOfParticipation, { url: this._UrlTypeOfParticipation, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.ReferenceYears, { url: this._UrlReferenceYears, expiresin: 24 });

    this.cacheUrls.set(EnumDataType.DocumentRateableValue, { url: this._UrlDocumentRateableValue, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentPropertyTaxBaseAssessment, { url: this._UrlDocumentPropertyTaxBaseAssessment, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCombiRateableValueAndPropertyTaxBaseAssessment, { url: this._UrlDocumentCombiRateableValueAndPropertyTaxBaseAssessment, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentPropertyTax, { url: this._UrlDocumentPropertyTax, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentTradeTaxLossCarryForward, { url: this._UrlDocumentTradeTaxLossCarryForward, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentTradeTaxAddCarryForward, { url: this._UrlDocumentTradeTaxAddCarryForward, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentTradeTaxBaseAssessment, { url: this._UrlDocumentTradeTaxBaseAssessment, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentTradeTaxBreakdown, { url: this._UrlDocumentTradeTaxBreakdown, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentTradeTax, { url: this._UrlDocumentTradeTax, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTax, { url: this._UrlDocumentCorporateIncomeTax, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxAddCarryForward, { url: this._UrlDocumentCorporateIncomeTaxAddCarryForward, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxPrePayment, { url: this._UrlDocumentCorporateIncomeTaxPrePayment, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxLossCarryForward, { url: this._UrlDocumentCorporateIncomeTaxLossCarryForward, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxSeparateAssessmentP27, { url: this._UrlDocumentCorporateIncomeTaxSeparateAssessmentP27, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxSeparateAndUniformDetermination, { url: this._UrlDocumentCorporateIncomeTaxSeparateAndUniformDetermination, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateIncomeTaxSaUControllingCompany, { url: this._UrlDocumentCorporateIncomeTaxSaUControllingCompany, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateTaxEBITDA, { url: this._UrlDocumentCorporateTaxEBITDA, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentCorporateTaxInterest, { url: this._UrlDocumentCorporateTaxInterest, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentValueAddedTax, { url: this._UrlDocumentValueAddedTax, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentIncomeTax, { url: this._UrlDocumentIncomeTax, expiresin: 24 });
    this.cacheUrls.set(EnumDataType.DocumentChamberOfCommerceAndIndustry, { url: this._UrlDocumentChamberOfCommerceAndIndustry, expiresin: 24 });
  }

  //TODO: Generisch GetData und GetDataForList zusammenfassen

  private GetData(type: EnumDataType): Observable<DropDownStringItem[]> {
    // find in Cache by type
    const _cacheUrl = this.cacheUrls.get(type);

    if (!_cacheUrl) {
      // if not found, return an empty observable
      return of([]);
    }

    // get data from map, and replace id if found, by id passed if any 
    const url = _cacheUrl.url;

    // change type enum into a string
    const key: string = EnumDataType[type];
    const data = this.storageService.getCache(`${key}`);

    if (data) {
      // BONUS: use the debug operator to log out the result, why not? read below
      return of(data);
    } else {
      // get from http
      return this.http.get(url).pipe(
        map((response) => {
          // if (type == EnumDataType.Xbrl) { 
          //   response.forEach((item) => {
          //     item.xbrlName = this.xbrlParserService.xbrlUIParseToAPISingle(item.xbrlName);
          // });
          // }
          // map response to internal model then save in cache
          let _retdata = DropDownStringItemClass.NewInstances(<DropDownStringItem[]>response);

          // here, since we have enums, we can make a switch statement
          // to select different mappers

          // assign to localstorage with key and expires in hours if set
          this.storageService.setCache(`${key}`, _retdata, _cacheUrl.expiresin);

          return _retdata;
        })
      );
    }
  }

  private GetDataForList(type: EnumDataType): Observable<DropDownListItem[]> {
    // find in Cache by type
    const _cacheUrl = this.cacheUrls.get(type);

    if (!_cacheUrl) {
      // if not found, return an empty observable
      return of([]);
    }

    // get data from map, and replace id if found, by id passed if any 
    const url = _cacheUrl.url;

    // change type enum into a string
    const key: string = EnumDataType[type];
    const data = this.storageService.getCache(`${key}`);

    if (data) {
      // BONUS: use the debug operator to log out the result, why not? read below
      return of(data);
    } else {
      // get from http
      return this.http.get(url).pipe(
        map((response) => {
          // map response to internal model then save in cache
          let _retdata = DropDownListItemClass.NewInstances(<DropDownListItem[]>response);

          // here, since we have enums, we can make a switch statement
          // to select different mappers

          // assign to localstorage with key and expires in hours if set
          this.storageService.setCache(`${key}`, _retdata, _cacheUrl.expiresin);

          return _retdata;
        })
      );
    }
  }

  private GetDataForDataType(type: EnumDataType): Observable<DataTypeItem[]> {
    // find in Cache by type
    const _cacheUrl = this.cacheUrls.get(type);

    if (!_cacheUrl) {
      // if not found, return an empty observable
      return of([]);
    }

    // get data from map, and replace id if found, by id passed if any 
    const url = _cacheUrl.url;

    // change type enum into a string
    const key: string = EnumDataType[type];
    const data = this.storageService.getCache(`${key}`);

    if (data) {
      // BONUS: use the debug operator to log out the result, why not? read below
      return of(data);
    } else {
      // get from http
      return this.http.get(url).pipe(
        map((response) => {
          // map response to internal model then save in cache
          let _retdata = DataTypeItemClass.NewInstances(<DataTypeItem[]>response);

          // set labelDEWithName
          _retdata.forEach(element => {
            element.labelDEWithName = element.labelDE + ' (' + element.name + ')';
          });

          // here, since we have enums, we can make a switch statement
          // to select different mappers

          // assign to localstorage with key and expires in hours if set
          this.storageService.setCache(`${key}`, _retdata, _cacheUrl.expiresin);

          return _retdata;
        })
      );
    }
  }

  private GetDataForDocumentName(type: EnumDataType): Observable<TaxNoticeNameItem[]> {
    // find in Cache by type
    const _cacheUrl = this.cacheUrls.get(type);

    if (!_cacheUrl) {
      // if not found, return an empty observable
      return of([]);
    }

    // get data from map, and replace id if found, by id passed if any 
    const url = _cacheUrl.url;

    // change type enum into a string
    // console.log(key);
    const key: string = EnumDataType[type];

    const data = this.storageService.getCache(`${key}`);

    if (data) {
      // BONUS: use the debug operator to log out the result, why not? read below
      return of(data);
    } else {
      // get from http
      return this.http.get(url).pipe(
        map((response) => {
          // map response to internal model then save in cache
          let _retdata = TaxNoticeNameItemClass.NewInstances(<TaxNoticeNameItem[]>response);

          // set labelDEWithName
          // _retdata.forEach(element => {
          //   element.labelDEWithName = element.labelDE + ' (' + element.name + ')';
          // });

          // here, since we have enums, we can make a switch statement
          // to select different mappers

          // assign to localstorage with key and expires in hours if set
          this.storageService.setCache(`${key}`, _retdata, _cacheUrl.expiresin);

          return _retdata;
        })
      );
    }
  }


  private _xbrlTypesList: XbrlTypeItem[] = [];
  private _dropDownLists: XbrlTypeItem[] = [];
  private _dataTypeItemList: DataTypeItem[] = [];
  private _workflowStateList: IWorkflowState[] = [];
  private _workflowStateListForDeadline: IWorkflowState[] = [];
  private _taxNoticeItemOptions: EnumDataTypeStrings[] = ["DocumentRateableValue",
    "DocumentPropertyTaxBaseAssessment",
    "DocumentCombiRateableValueAndPropertyTaxBaseAssessment",
    "DocumentPropertyTax",
    "DocumentTradeTaxLossCarryForward",
    "DocumentTradeTaxAddCarryForward",
    "DocumentTradeTaxBaseAssessment",
    "DocumentTradeTaxBreakdown",
    "DocumentTradeTax",
    "DocumentCorporateIncomeTax",
    "DocumentCorporateIncomeTaxAddCarryForward",
    "DocumentCorporateIncomeTaxPrePayment",
    "DocumentCorporateIncomeTaxLossCarryForward",
    "DocumentCorporateIncomeTaxSeparateAssessmentP27",
    "DocumentCorporateIncomeTaxSeparateAndUniformDetermination",
    "DocumentCorporateIncomeTaxSaUControllingCompany",
    "DocumentCorporateTaxEBITDA",
    "DocumentCorporateTaxInterest",
    "DocumentValueAddedTax",
    "DocumentIncomeTax",
    "DocumentChamberOfCommerceAndIndustry"
  ];

  /**
  * Load initial data */
  load() {
    // XbrlTypes
    this.http.get<XbrlTypeItem[]>(ENVIRONMENT.apiBaseUrl + 'Data/GetTypes', HTTP_OPTIONS).toPromise().then((data: XbrlTypeItem[]) => {
      data.forEach((item) => {
        item.name = this.xbrlParserService.xbrlAPIParseToUISingle(item.name);
      });
      this._xbrlTypesList = data;
    });

    this.http.get<XbrlTypeItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetAvailableDropDownLists', HTTP_OPTIONS).toPromise().then((data: XbrlTypeItem[]) => {
      data.forEach((item) => {
        item.name = this.xbrlParserService.xbrlAPIParseToUISingle(item.name);
      });
      this._dropDownLists = data;
    });

    this.http.get<DataTypeItem[]>(ENVIRONMENT.apiBaseUrl + 'Dataset/GetTypes', HTTP_OPTIONS).toPromise().then((data: DataTypeItem[]) => {
      // set labelDEWithName
      data.forEach(element => {
        element.labelDEWithName = element.labelDE + ' (' + element.name + ')';
      });
      this._dataTypeItemList = data;
    });

    this.http.get<IWorkflowState[]>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetAllStatesSimple', HTTP_OPTIONS).toPromise().then((data: IWorkflowState[]) => {
      this._workflowStateList = data;
    });

    this.http.get<IWorkflowState[]>(ENVIRONMENT.apiBaseUrl + 'Workflow/GetAllStatesSimple?forDeadline=true', HTTP_OPTIONS).toPromise().then((data: IWorkflowState[]) => {
      this._workflowStateListForDeadline = data;
    });

    // this.GetDocumentNamesAll();

  }

  // NOT IN USE
  //   /**
  // * Load initial data */
  //   loadLegalEntities() {

  //     this.http.get<XbrlTypeItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetAvailableDropDownLists', HTTP_OPTIONS).toPromise().then((data: XbrlTypeItem[]) => {
  //       this._dropDownLists = data;
  //     });
  //   }


  /** List with all XBRL types */
  public get xbrlTypes(): XbrlTypeItem[] {
    return this._xbrlTypesList;
  }

  /** List with all XBRL types they are used as drop downs */
  public get dropdownLists(): XbrlTypeItem[] {
    return this._dropDownLists;
  }


  /** List with all datatype items */
  public get dataTypeItemList(): DataTypeItem[] {
    return this._dataTypeItemList;
  }

  /** List with all workflow state mappings (id -> display name (LabelDE)), means workflowType == 3 */
  public get workflowStateList(): IWorkflowState[] {
    return this._workflowStateList;
  }

  /** List with all workflow state mappings (id -> display name (LabelDE)) - only for Deadline, means workflowType == 1 */
  public get workflowStateListForDeadline(): IWorkflowState[] {
    return this._workflowStateListForDeadline;
  }

  /** List with available tax notice names */
  // public get taxNoticeNameItemOptions(): TaxNoticeNameItem[] {
  //   return this._taxNoticeNameItemOptions;
  // }


  /** List with all Datatypes */
  public GetDataTypes(): Observable<DataTypeItem[]> {
    return this.GetDataForDataType(EnumDataType.DataType);
  }


  /** List with all Datatypes for a rescan operation */
  public GetDataTypesForRescan(): Observable<DataTypeItem[]> {
    return this.GetDataForDataType(EnumDataType.DataTypeForRescan);
  }

  /** List for "Feststellung" */
  public GetTaxDeterminationList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.TaxDetermination)
  }

  /** List with all Legal entities "Steuerpflichtige" */
  public GetLegalEntitiesList(): Observable<DropDownStringItem[]> {
    return this.GetData(EnumDataType.LegalEntities);
  }

  public GetLegalEntitiesListNew(): Observable<DropDownStringItem[]> {
    return this.GetData(EnumDataType.LegalEntities).pipe(
      catchError(this.handleError<DropDownStringItem[]>('GetLegalEntitiesList', []))
    );
  }


  /** List with all ChamberOfCommerceAndIndustry "IHKs" */
  public GetChamberOfCommerceAndIndustryList(): Observable<DropDownStringItem[]> {
    return this.GetData(EnumDataType.CCI);
  }

  /** List with all communes */
  public GetCommuneAGSList(): Observable<DropDownStringItem[]> {
    return this.GetData(EnumDataType.CommuneAGS);
  }

  /** List with audit results "Tatbestand" */
  public GetAuditResultsList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.AuditResults);
  }

  // /** List for "Festsetzung" */
  public GetAssessmentDeterminationList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.AssessmentDetermination);
  }

  /** List for "Rechtsbehelf Ergebnis" */
  public GetLegalRemedyResultList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.LegalRemedyResult);
  }

  /** List for "Rechtsbehelf Einspruch" */
  public GetLegalRemedyObjectionList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.LegalRemedyObjection);
  }

  /** List for "Ausfertigung für" on KSt */
  public GetCorporateIncomeTaxCopyFor(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.CorporateIncomeTaxCopyFor);
  }

  /** List for "Vorbehalt der Nachprüfung, VdN */
  public GetVdNList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.VDN);
  }

  /** List with all Export types*/
  public GetExportTypeList(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.Export);
  }

  /** List with taxationbases "Besteuerungsgrundlagen */
  public GetTaxationBases(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.TaxationBases);
  }

  /** List with taxationbases "Besteuerungsgrundlagen */
  public GetTypesOfParticipation(): Observable<DropDownListItem[]> {
    return this.GetDataForList(EnumDataType.TypeOfParticipation);
  }

  /** List with taxationbases "Besteuerungsgrundlagen */
  public GetDocumentNames(name: EnumDataTypeStrings): Observable<TaxNoticeNameItem[]> {

    return this.GetDataForDocumentName(EnumDataType[name]);
  }

  /** List with taxationbases "Besteuerungsgrundlagen */
  // public GetDocumentNamesAll(): void {
  //   this._taxNoticeItemOptions.forEach((item) => {
  //     this.GetDataForDocumentName(EnumDataType[item])
  //   });
  // }

  // /** List with all testcases */
  // public GetTestcasesList(): Observable<AssessmentType[]> {
  //   return this._testcasesList;
  // }

  public ResetCacheLegalEntities(): void {
    this.storageService.removeCache(EnumDataType[EnumDataType.LegalEntities]);
  }

  public ResetCacheCommuneAGS(): void {
    this.storageService.removeCache(EnumDataType[EnumDataType.CommuneAGS]);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      console.error(error); // Log the error to the console or send it to a logging infrastructure  
      // Return an empty result to keep the app running  
      return of(result as T);
    };
  }

  /** List with all Legal entities "Steuerpflichtige" */
  public GetReferenceYears(id: number): Observable<DropDownStringItem[]> {
    let url = ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownForCorporateIncomeTaxPrePayment/' + id;
    return this.http.get(url).pipe(
      map((response) => {
        // map response to internal model then save in cache
        let _retdata = DropDownStringItemClass.NewInstances(<DropDownStringItem[]>response);



        return _retdata;
      })
    );
  }

  //   /**
  // * API: Set tax notices names based on dataType.
  // * 
  // */
  //   loadTaxNoticeNames(dataType: string): void {
  //     this.http.get<TaxNoticeNameItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/' + dataType, HTTP_OPTIONS).toPromise().then((data: TaxNoticeNameItem[]) => {
  //       this._taxNoticeNameItemOptions = data;
  //     });
  //   }

}


