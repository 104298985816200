import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { ValidationDeactivationTemporaryItem } from 'app/_models/validation-deactivation.model';


@Component({
  selector: 'app-stare-dropdown',
  templateUrl: './stare-dropdown.component.html',
  styleUrl: './stare-dropdown.component.scss',
  standalone: false
})

export class StareDropdownComponent {
  @Output() onFieldFocus = new EventEmitter();
  //Sends value to disable to doc-base for saving
  @Output() onReferenceDisable = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlName: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;

  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  //ex. Kassenzeicht und Steuernummer
  @Input() overrideLabel: string = '';
  @Input() dataNumberOptionsSource: DropDownListItem[] = [];
  @Input() isArchiv: boolean = false;
  public label: string = '';
  public class: string = '';

  public fieldClass: string = '';

  public filterable: boolean = true;


  public dataForDisplay: DropDownListItem[] = [];

  //public decimals: number = 0;
  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;


  public defaultItem: DropDownListItem = {
    labelEN: 'select item',
    labelDE: 'Bitte auswählen...',
    isDefault: false,
    isDeleted: false,
  };

  constructor(

    public stareInputFieldsService: StareInputFieldsService,

    private dataService: DataService,
    public tooltipService: TooltipService

  ) {

  }

  ngOnInit(): void {


    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    this.fieldClass = this.isReadOnly ? 'field-disabled' : 'field-enabled';
    // this.fillData();

    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        // The form has loaded successfully
        this.label = this.overrideLabel ? this.overrideLabel : this.stareInputFieldsService.getXbrlLabel(this.xbrlName);
        this.dataForDisplay = this.dataNumberOptionsSource;
      }
    });
    if (this.xbrlName === 'document_auditResult') {
      this.filterable = false;
    }

  }

  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }
  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }
  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }



  handleFilter(value: string): void {
    // this.dataOptions = this.dataOptionsSource.filter(
    //   (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
    // );
    this.dataForDisplay = this.dataNumberOptionsSource.filter(
      (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  /** Triggered when the form fields are clicked on and should be focused on
*/
  public onReferenceDisableReciever(activation: boolean): void {
    this.onReferenceDisable.emit({ datasetId: this.datasetId, xbrl: this.xbrlName, activation: activation } as ValidationDeactivationTemporaryItem);
  }
}
