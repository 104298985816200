<div class="stareTopic">
    {{ 'APP.SETTINGS.TESTDATA.TITLE' | translate }}
</div>


<form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm"
    style="font-family: 'PwC Helvetica Neue', sans-serif;">
    <kendo-formfield>
        <kendo-label [for]="documentDefinition"
            [text]="'APP.SETTINGS.TESTDATA.DOCUMENT_DEFINITION_DROPDOWN' | translate"></kendo-label>
        <kendo-dropdownlist #documentDefinition formControlName="documentDefinition" [data]="listDocumentDefinition"
            [defaultItem]="{ text: 'Testcase', value: null }" textField="title" valueField="id" [valuePrimitive]="true"
            required></kendo-dropdownlist>
        <kendo-formerror>{{ 'APP.SETTINGS.TESTDATA.TESTCASE_DROPDOWN' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label [for]="wbs" [text]="'APP.SETTINGS.TESTDATA.WBS_DROPDOWN' | translate"></kendo-label>
        <kendo-dropdownlist #wbs formControlName="wbs" [data]="listWBS" [defaultItem]="{ text: 'WBS', value: null }"
            textField="wbs" valueField="id" [valuePrimitive]="true" required></kendo-dropdownlist>
        <kendo-formerror>{{ 'APP.SETTINGS.TESTDATA.WBS_DROPDOWN' | translate }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
        <ng-container>
            <span class="k-checkbox-wrap">
                <input id="abbyy" type="checkbox" kendoCheckBox formControlName="abbyy" />
            </span>
            <label [labelClass]="false" class="k-checkbox-label" for="abbyy">{{
                'APP.SETTINGS.TESTDATA.TOGGLE_DESCRIPTION' | translate }}</label>
        </ng-container>
    </kendo-formfield>

</form>
<br>
<button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onClickSubmitButton()"
    [disabled]="buttonDisabled">
    {{ 'BUTTON.SUBMIT_TEST_DATA' | translate }}
</button>
<!-- 
    
    <div class="row">
        <ap-dropdown [list]='listDocumentDefinition' [selectType]="'single'"
            [title]="'APP.SETTINGS.TESTDATA.DOCUMENT_DEFINITION_DROPDOWN' | translate"
            [(ngModel)]="selectedDocumentDefinition" (onSelect)="onSelectDocuDef($event)" [required]="true">
            <ng-container *ngFor="let item of listDocumentDefinition; let i = index;">
                <ap-dropdown-list-item #dropdownListItem [item]="item">
                    <ng-template>
                        <span></span>
                    </ng-template>
                </ap-dropdown-list-item>
            </ng-container>
        </ap-dropdown>
    </div>
    <br />

    
    <div class="row">
        <ap-dropdown [list]='listWBS' [selectType]="'single'" [title]="'APP.SETTINGS.TESTDATA.WBS_DROPDOWN' | translate"
            [(ngModel)]="selectedWBS" (onSelect)="onSelectWBS($event)" [required]="true">
            <ng-container *ngFor="let item of listWBS; let i = index;">
                <ap-dropdown-list-item #dropdownListItem [item]="item">

                </ap-dropdown-list-item>
            </ng-container>
        </ap-dropdown>
    </div>
    <br />

    <div class="row">
        <div class="g-col-2 g-start-1">
            <ap-toggle (onChange)="onToggleChange($event)" [disabled]="switchDisabled">
                <span *ngIf="showSwitchLabel">{{ 'APP.SETTINGS.TESTDATA.ABBYY' | translate }}</span>
            </ap-toggle>
        </div>
        <br />
    </div>
    <div class="row">
        <div class="g-col-2 g-start-1">
            
            <br />
            <a routerLink="/tasks" kendoTooltip [title]="'APP.SETTINGS.TESTDATA.BACK_TO_TASKS_TOOLTIP' | translate">{{
                'APP.SETTINGS.TESTDATA.BACK_TO_TASKS' | translate }}</a>
        </div>
        <br />
    </div>
    <br />

    <div class="row">
        <div class="g-col-2 g-start-1">
            <ap-button [btnType]="'primary'" [disabled]='buttonDisabled' [label]="'BUTTON.SUBMIT_TEST_DATA' | translate"
                (onClick)="onClickSubmitButton($event)"></ap-button>
        </div>
        <br />
    </div> -->