<form [formGroup]="tradeTaxLossCarryForwardForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>

                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_cutOffDate"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_lossCarryForward" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="tradeTax_lossCarryForward"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="true"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_lossCarryForwardAmountLinkedToContinuation"
                        [datasetId]="headerDatasetId" [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="tradeTax_lossCarryForwardAmountLinkedToContinuation" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_tradeTax_lossCarryForwardPartnerAmountSum" [datasetId]="headerDatasetId"
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Mitunternehmende
                    </legend>
                </div>
            </div>

            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i">
                    <div class="row-for-payload">
                        <div class="row-for-checkbox">
                            <div class="column">
                                <app-stare-textbox (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_companyIdentifier" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    overrideLabel="Steuernummer">
                                </app-stare-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_tradeTax_lossCarryForwardPartnerAmount" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="tradeTax_lossCarryForwardPartnerAmount" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton [type]="'button'" fillMode="outline" themeColor="primary" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentTradeTaxLossCarryForwardPartnership'
                                        )
                                    ">
                                    Lösche Mitunternehmenden
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <button kendoButton [type]="'button'" themeColor="primary" (click)="
                            addPayload(
                                'DocumentTradeTaxLossCarryForwardPartnership'
                            )
                        ">
                        Neuen Mitunternehmender
                    </button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="tradeTaxLossCarryForwardForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="tradeTaxLossCarryForwardForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="tradeTaxLossCarryForwardForm1300" [auditResults]="auditResults" [workflowId]="workflowId"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 
<form [formGroup]="tradeTaxLossCarryForwardForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
						  { height: 0 },
        { height:0},
        { height: 0},
						{ height: 20 },
						{ height: 112 },
						{ height: 130},
                        { height: 20 },
						{ height: 112 },
						{ height: 112 },
                        { height: 112 },						
						{ height: 20 },
						{ height: 380},							
						{ height: 20 },	
						{ height: documentTradeTaxMitunternehmendePayloadLength},
						{ height: 50 },
						{ height: 20 },
		                { height: 90 },
		                { height: withoutReferenceValidationExplanationRowHeight},
                        { height: sdcFieldsRowHeight },
                        { height: sdcFieldsRowHeight },]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">{{getXbrlLabel('document_legalRemedyClientDecision')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="2">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_cutOffDate-'+headerDatasetId"
                            id="cutoffDateLabel">{{getXbrlLabel('document_cutOffDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['document_cutOffDate'].value" format="dd.MM.yyyy"
                            [id]="'document_cutOffDate-'+headerDatasetId" placeholder="Bitte auswählen..."
                            formControlName="document_cutOffDate" autocomplete="off" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_cutOffDate')">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_cutOffDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                    <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[     
                    { height: 112 },
                    { height: 120},   
                    { height: 112 },
                    { height: 0 },]" [cols]="[{ width: 400 }, { width: 430 }]">
                        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                <kendo-label [for]="'document_tradeTax_lossCarryForward-'+headerDatasetId"
                                    id="tradeTaxLabel">{{getXbrlLabel('document_tradeTax_lossCarryForward')}}</kendo-label>

                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_tradeTax_lossCarryForward-'+headerDatasetId"
                                    placeholder="{{getXbrlLabel('document_tradeTax_lossCarryForward')}}"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_tradeTax_lossCarryForward')"
                                    formControlName="document_tradeTax_lossCarryForward" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_tradeTax_lossCarryForward'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                <kendo-label [for]="'tradeTax_lossCarryForward-'+headerDatasetId"
                                    id="tradeTaxLabel">{{getXbrlLabel('tradeTax_lossCarryForward')}}</kendo-label>
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [id]="'tradeTax_lossCarryForward-'+headerDatasetId"
                                    [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'tradeTax_lossCarryForward')"
                                    placeholder="{{getXbrlLabel('tradeTax_lossCarryForward')}}"
                                    formControlName="tradeTax_lossCarryForward" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'tradeTax_lossCarryForward'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                            <kendo-formfield showHints="initial" showErrors="initial" class="part">
                                <kendo-label [for]="'document_hasThresholdLimit-'+headerDatasetId"
                                    id="effectiveDateRuleLabel">
                                    {{getXbrlLabel('document_hasThresholdLimit')}}
                                </kendo-label>
                                <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                                    style="margin-left: 5px ;margin-top: 8px ;"
                                    formControlName="document_hasThresholdLimit"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_hasThresholdLimit')"
                                    [id]="'document_hasThresholdLimit-'+headerDatasetId" />
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_hasThresholdLimit'"></app-validation-message-formcontrol>

                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                <kendo-label
                                    [for]="'document_tradeTax_lossCarryForwardPartnerAmountSum-'+headerDatasetId"
                                    id="tradeTaxLabel">{{getXbrlLabel('document_tradeTax_lossCarryForwardPartnerAmountSum')}}</kendo-label>

                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none"
                                    [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                    [id]="'document_tradeTax_lossCarryForwardPartnerAmountSum-'+headerDatasetId"
                                    placeholder="{{getXbrlLabel('document_tradeTax_lossCarryForwardPartnerAmountSum')}}"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_tradeTax_lossCarryForwardPartnerAmountSum')"
                                    formControlName="document_tradeTax_lossCarryForwardPartnerAmountSum"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_tradeTax_lossCarryForwardPartnerAmountSum'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                <kendo-label
                                    [for]="'document_tradeTax_lossCarryForwardAmountLinkedToContinuation-'+headerDatasetId"
                                    id="tradeTaxLabel">{{getXbrlLabel('document_tradeTax_lossCarryForwardAmountLinkedToContinuation')}}</kendo-label>

                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_tradeTax_lossCarryForwardAmountLinkedToContinuation-'+headerDatasetId"
                                    placeholder="{{getXbrlLabel('document_tradeTax_lossCarryForwardAmountLinkedToContinuation')}}"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_tradeTax_lossCarryForwardAmountLinkedToContinuation')"
                                    formControlName="document_tradeTax_lossCarryForwardAmountLinkedToContinuation"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_tradeTax_lossCarryForwardAmountLinkedToContinuation'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always" class="part">
                                <kendo-label
                                    [for]="'tradeTax_lossCarryForwardAmountLinkedToContinuation-'+headerDatasetId"
                                    id="tradeTaxLabel">{{getXbrlLabel('tradeTax_lossCarryForwardAmountLinkedToContinuation')}}</kendo-label>
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [tabindex]="-1"
                                    [id]="'tradeTax_lossCarryForwardAmountLinkedToContinuation-'+headerDatasetId"
                                    [readonly]="true" class="custom-fill-mode" fillMode="none" class="custom-fill-mode"
                                    fillMode="none"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'tradeTax_lossCarryForwardAmountLinkedToContinuation')"
                                    placeholder="{{getXbrlLabel('tradeTax_lossCarryForwardAmountLinkedToContinuation')}}"
                                    formControlName="tradeTax_lossCarryForwardAmountLinkedToContinuation"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'tradeTax_lossCarryForwardAmountLinkedToContinuation'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>

                    </kendo-gridlayout>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend payload-parent-header">
                        Mitunternehmende</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
												{ height: 110 }, 
												{ height: 110 },   
												{ height: 50 }]" [cols]="[{ width: 320 }, { width: 370 }]" class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label [for]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                                            type="text"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_companyIdentifier')"
                                            placeholder="Steuernummer" formControlName="id_companyIdentifier"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_lossCarryForwardPartnerAmount-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_tradeTax_lossCarryForwardPartnerAmount')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_tradeTax_lossCarryForwardPartnerAmount-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_lossCarryForwardPartnerAmount')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_lossCarryForwardPartnerAmount')}}"
                                            formControlName="document_tradeTax_lossCarryForwardPartnerAmount"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_lossCarryForwardPartnerAmount'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_lossCarryForwardPartnerAmount-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('tradeTax_lossCarryForwardPartnerAmount')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [readonly]="true"
                                            fillMode="none" class="custom-fill-mode" [tabindex]="-1"
                                            [id]="'tradeTax_lossCarryForwardPartnerAmount-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_lossCarryForwardPartnerAmount')"
                                            placeholder="{{getXbrlLabel('tradeTax_lossCarryForwardPartnerAmount')}}"
                                            formControlName="tradeTax_lossCarryForwardPartnerAmount" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_lossCarryForwardPartnerAmount'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>

                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentTradeTaxLossCarryForwardPartnership")'>
                                        Mitunternehmenden Löschen</button>
                                </kendo-gridlayout-item>
                            </kendo-gridlayout>
                        </div>
                    </div>

                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentTradeTaxLossCarryForwardPartnership")'>
                            Mitunternehmender hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="3"
                    *ngIf="tradeTaxLossCarryForwardForm1300.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->