import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { QualityCheckDto } from 'app/_models/quality-check.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiQualityCheckService {

  constructor(
    private http: HttpClient
  ) { }

  /**
  * Get Quality Check Results from the last run
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getFromLastRun() {

    return this.http.get<QualityCheckDto[]>(`${ENVIRONMENT.maintenanceApiBaseUrl}QualityCheck/GetFromLastRun`);
  }


  /**
   * Request an Update, Add a new Quality Check job
   */
  updateQualityCheck(): Observable<JobStatisticItem> {
    return this.http.post<JobStatisticItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'QualityCheck/UpdateQualityCheck', HTTP_OPTIONS);
  }

  /**
  * Get Quality Check Result.
  * @returns Observable
  */
  getQualityCheckResult() {
    return this.http.get<number>(`${ENVIRONMENT.maintenanceApiBaseUrl}QualityCheck/GetQualityCheckResult`);
  }

}