import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ENVIRONMENT } from '../../../environments/environment';
import { SDCUserForCreationItem, SDCUserForUpdateItem, SDCUserItem } from '../../_models/user.model';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiSDCUserService {

  constructor(
    private http: HttpClient
  ) { }

  /**
 * Add a new SDC user.
 * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
 * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
 */
  addSDCUser(items: SDCUserForCreationItem[]): Observable<SDCUserItem[]> {
    return this.http.post<SDCUserItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'SdcDefaultUser/AddOrUpdateAsync', items, HTTP_OPTIONS);
  }

  /**
   * Update an SDC user.
   * @param userId ID of the user.
   * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  updateSDCUser(items: SDCUserForUpdateItem[]): Observable<SDCUserItem[]> {
    //return this.http.post<SDCUserItem[]>(`${ENVIRONMENT.maintenanceApiBaseUrl}SdcDefaultUser/AddOrUpdateAsync/${userId}`, items, HTTP_OPTIONS);

    return this.http.post<SDCUserItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'SdcDefaultUser/AddOrUpdateAsync', items, HTTP_OPTIONS);
  }

  /**
   * Delete an SDC user.
   * @param userId ID of the user.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteSDCUser(userId: number): Observable<SDCUserItem> {
    return this.http.delete<SDCUserItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}SdcDefaultUser/DeleteAsync/${userId}`, HTTP_OPTIONS);
  }


  /**
   * Get all SDC users.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllSDCUsers(): Observable<SDCUserItem[]> {
    return this.http.get<SDCUserItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'SdcDefaultUser/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }


}