<div class="stareTopic">
    {{ 'APP.DOWNLOAD.TITLE' | translate }}
</div>


<br>
<br>

<div class="plainText" *ngIf="downloadSuccess">
    {{ 'APP.DOWNLOAD.NOTIFICATION.SUCCESS' | translate }}
</div>
<div *ngIf="!downloadSuccess">
    <span class="plainText" style="color: red">{{ 'APP.DOWNLOAD.NOTIFICATION.FAIL' | translate }}
        {{errorMessage}}</span><br><br>
    {{ 'APP.DOWNLOAD.NOTIFICATION.REASON' | translate }}
    <ul>
        <li>{{ 'APP.DOWNLOAD.NOTIFICATION.REASON_BODY1' | translate }}</li>
        <li>{{ 'APP.DOWNLOAD.NOTIFICATION.REASON_BODY2' | translate }}</li>
    </ul>
</div>
<br>
<br>
<br>
<br>
<span class="plainText" style="color: lightgrey">
    {{ 'APP.DOWNLOAD.SUPPURT.BODY' | translate }}<br>
    {{ 'APP.DOWNLOAD.SUPPURT.CLIENT' | translate }} {{requestClientEngagement}}<br>
    {{ 'APP.DOWNLOAD.SUPPURT.FILE_ID' | translate }} {{requestFileId}}
</span>