import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-income-tax-separate-assessment-p271300',
  templateUrl: './doc-corporate-income-tax-separate-assessment-p271300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxSeparateAssessmentP271300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxSeparateAssessmentP27Form1300;
  }


  //#region Form ======================================================================================================

  /** Körperschaftsteuer P27, Rechtsmittel-Check, State 1300 */
  public corporateIncomeTaxSeparateAssessmentP27Form1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),

    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    document_corporateIncomeTax_p165: new UntypedFormControl(""),
    document_corporateIncomeTaxP27_taxDepositAccount: new UntypedFormControl(""),
    document_corporateIncomeTaxP27_nominalCapitalConversionOfTaxReserves: new UntypedFormControl("",),
    document_corporateIncomeTaxP27_distributableNetProfit: new UntypedFormControl(""),
    document_corporateIncomeTaxP27_stockAccordingToSection27: new UntypedFormControl("",),
    document_corporateIncomeTaxP27_stockAccordingToSection28: new UntypedFormControl(""),
    corporateIncomeTaxP27_taxDepositAccount: new UntypedFormControl(""),
    corporateIncomeTaxP27_nominalCapitalConversionOfTaxReserves: new UntypedFormControl(""),
    corporateIncomeTaxP27_distributableNetProfit: new UntypedFormControl(""),
    corporateIncomeTaxP27_stockAccordingToSection27: new UntypedFormControl("",),
    corporateIncomeTaxP27_stockAccordingToSection28: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }


}
