<form [formGroup]="tradeTaxBreakdownForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>

                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_assessmentDetermination" [datasetId]="headerDatasetId"
                        [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="assessmentDetermination">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_assessmentYear"
                        [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="
                tradeTaxBreakdownForm1300.controls[
                    'document_assessmentDetermination'
                ].value == 2
            ">
            <div class="column">
                <app-stare-integer [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_tradeTax_yearBasedOn" [datasetId]="headerDatasetId"
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                </app-stare-integer>
            </div>
        </div>
        <div class="row-for-checkbox" *ngIf="workflowType == 11">
            <div class="column-for-checkbox">
                <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                </app-stare-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="column-for-header">
                <legend class="k-form-legend section-header">
                    Bewegungsdaten
                </legend>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_tradeTax_base"
                    [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                    [workflowType]="workflowType">
                </app-stare-numerical>
            </div>
            <div class="column">
                <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="tradeTax_base"
                    [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="true"
                    [workflowType]="workflowType">
                </app-stare-numerical>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_tradeTax_salaries"
                    [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false"
                    [workflowType]="workflowType">
                </app-stare-numerical>
            </div>
            <div class="column">
                <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="tradeTax_salaries"
                    [datasetId]="headerDatasetId" [form]="tradeTaxBreakdownForm1300" [isReadOnly]="true"
                    [workflowType]="workflowType">
                </app-stare-numerical>
            </div>
        </div>
        <div class="row">
            <div class="column-for-sub-header">
                <legend class="k-form-legend section-header">Zerlegung</legend>
            </div>
        </div>
        <div formArrayName="documentPayload" *ngFor="
                let payload of documentPayloadGetterOnlyControls;
                let i = index
            ">
            <div [formGroupName]="i">
                <div class="row-for-payload">
                    <div class="row">
                        <div class="column-for-header">
                            <button kendoButton rounded="none" style="margin-left: 707px; margin-top: -8px"
                                class="custom-rounded" fillMode="outline" [disabled]="true">
                                Gemeinde
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                                xbrlName="document_tradeTax_commune_name" [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType"
                                overrideLabel="Extrahierte Gemeinde">
                            </app-stare-textbox>
                        </div>
                        <div class="column">
                            <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_communeAGS"
                                [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                [dataOptionsSource]="communeAGS">
                            </app-stare-dropdown-string>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                                xbrlName="document_tradeTax_commune_salaries" [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                            </app-stare-numerical>
                        </div>
                        <div class="column">
                            <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                                xbrlName="tradeTax_commune_salaries" [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                            </app-stare-numerical>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                                xbrlName="document_tradeTax_commune_fixedShare" [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                            </app-stare-numerical>
                        </div>
                        <div class="column">
                            <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                                xbrlName="tradeTax_commune_fixedShare" [datasetId]="
                                    payload.controls['datasetId'].value
                                " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                            </app-stare-numerical>
                        </div>
                    </div>
                    <div class="row-for-payload-button">
                        <div class="column">
                            <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                [type]="'button'" (click)="
                                    deletePayload(
                                        documentPayloadGetter(),
                                        i,
                                        'DocumentTradeTaxBreakdownCommune'
                                    )
                                ">
                                Gemeinde löschen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <button kendoButton themeColor="primary" [type]="'button'"
                    (click)="addPayload('DocumentTradeTaxBreakdownCommune')">
                    Gemeinde hinzufügen
                </button>
            </div>
        </div>
        <!-- <div class="row">
            <div class="column-for-header">
                <legend class="k-form-legend section-header">
                    Prüfungsergebnis
                </legend>
            </div>
        </div>
        <div class="row-for-checkbox">
            <div class="column-for-checkbox">
                <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                </app-stare-checkbox>
            </div>
            <div class="column-for-text-area"
                *ngIf="tradeTaxBreakdownForm1300.controls['document_withoutReferenceValidation'].value==true">
                <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                </app-stare-textarea>
            </div>

        </div>
        <div class="row" *ngIf="workflowType==11">
            <div class="column-for-text-area ">
                <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                </app-stare-textarea>
            </div>
        </div>
        <div class="row-for-comment-button" *ngIf="workflowType==11">
            <div class="column">
                <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                    hinzufügen
                </kendo-splitbutton>
            </div>
        </div>
        <div class="row" *ngIf="workflowType==11">
            <div class="column-for-text-area ">
                <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                    xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                    [form]="tradeTaxBreakdownForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                </app-stare-textarea>
            </div>
        </div> -->
        <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
            [form]="tradeTaxBreakdownForm1300" [auditResults]="auditResults"
            [is1300]="true"></app-assessment-result-section>
    </fieldset>
    <br />
</form>
<!-- 

<form [formGroup]="tradeTaxBreakdownForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
          { height: 0 },
        { height:0},
        { height: 0},
        { height: 20 },
        { height: 112 },
        { height: 130 },
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 0 },
        { height: 20 },
        { height: 120 },
        { height: 120 },
        { height: 20 },
        { height: documentTradeTaxBreakdownPayloadLength },
        { height: 50 },
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight},
        { height: sdcFieldsRowHeight },
        { height: sdcFieldsRowHeight }]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">{{getXbrlLabel('document_legalRemedyClientDecision')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}

                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()" [enableMouseWheel]="false"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="2">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_assessmentDetermination-'+headerDatasetId"
                            id="assessmentDeterminationLabel">
                            {{getXbrlLabel('document_assessmentDetermination')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_assessmentDetermination-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_assessmentDetermination')"
                            [defaultItem]="defaultItem" formControlName="document_assessmentDetermination"
                            [data]="assessmentDetermination">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_assessmentDetermination'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_assessmentYear-'+headerDatasetId"
                            id="assessmentYearLabel">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [decimals]="0"
                            [maxlength]="4" [changeValueOnScroll]="false" format="##"
                            [id]="'id_assessmentYear-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_assessmentYear')"
                            placeholder="{{getXbrlLabel('id_assessmentYear')}}" formControlName="id_assessmentYear"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_tradeTax_base-'+headerDatasetId"
                            id="fixedShareLabel1">{{getXbrlLabel('document_tradeTax_base')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [id]="'document_tradeTax_base-'+headerDatasetId" [changeValueOnScroll]="false"
                            placeholder="GewSt. Zerlegung Document" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_tradeTax_base')"
                            formControlName="document_tradeTax_base" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_tradeTax_base'"></app-validation-message-formcontrol>
                    </kendo-formfield></kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="2">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'tradeTax_base-'+headerDatasetId"
                            id="fixedShareLabel2">{{getXbrlLabel('tradeTax_base')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [tabindex]="-1" [id]="'tradeTax_base-'+headerDatasetId" [changeValueOnScroll]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'tradeTax_base')"
                            placeholder="{{getXbrlLabel('tradeTax_base')}}" formControlName="tradeTax_base"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'tradeTax_base'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_tradeTax_salaries-'+headerDatasetId"
                            id="salariesLabel">{{getXbrlLabel('document_tradeTax_salaries')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [id]="'document_tradeTax_salaries-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('document_tradeTax_salaries')}}" [decimals]="2"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_tradeTax_salaries')"
                            formControlName="document_tradeTax_salaries" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_tradeTax_salaries'"></app-validation-message-formcontrol>
                    </kendo-formfield></kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="2">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'tradeTax_salaries-'+headerDatasetId"
                            id="salariesLabel">{{getXbrlLabel('tradeTax_salaries')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [tabindex]="-1" [id]="'tradeTax_salaries-'+headerDatasetId" [changeValueOnScroll]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'tradeTax_salaries')"
                            placeholder="{{getXbrlLabel('tradeTax_salaries')}}" formControlName="tradeTax_salaries"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'tradeTax_salaries'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend payload-parent-header">
                        Zerlegung</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 100 }, 
                                { height: 50 }]" [cols]="[{ width: 270 }, { width: 270 }, { width:100 }]"
                                class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_commune_name-'+payload.controls['datasetId'].value"
                                            id="{{'name'+i}}">Extrahierte
                                            {{getXbrlLabel('document_tradeTax_commune_name')}}</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'document_tradeTax_commune_name-'+payload.controls['datasetId'].value"
                                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_commune_name')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_commune_name')}}"
                                            formControlName="document_tradeTax_commune_name" autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_commune_name'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="2">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_communeAGS-'+payload.controls['datasetId'].value"
                                            id="agsLabel">{{getXbrlLabel('id_communeAGS')}}</kendo-label>
                                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'"
                                            [valueField]="'value'"
                                            [id]="'id_communeAGS-'+payload.controls['datasetId'].value"
                                            [virtual]="{itemHeight: 31}" [filterable]="true" [valuePrimitive]="true"
                                            [defaultItem]="defaultItem" formControlName="id_communeAGS"
                                            (filterChange)="handleAGSFilter($event)"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_communeAGS')"
                                            [data]="communeAGS"></kendo-dropdownlist>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_communeAGS'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>


                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_commune_salaries-'+payload.controls['datasetId'].value"
                                            id="{{'salariesLabel'+i}}">{{getXbrlLabel('document_tradeTax_commune_salaries')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_tradeTax_commune_salaries-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_commune_salaries')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_commune_salaries')}}"
                                            formControlName="document_tradeTax_commune_salaries" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_commune_salaries'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="2">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_commune_salaries-'+payload.controls['datasetId'].value"
                                            id="{{'salariesRefLabel'+i}}">{{getXbrlLabel('tradeTax_commune_salaries')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'tradeTax_commune_salaries-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_commune_salaries')"
                                            placeholder="{{getXbrlLabel('tradeTax_commune_salaries')}}"
                                            formControlName="tradeTax_commune_salaries" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_commune_salaries'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_tradeTax_commune_fixedShare-'+payload.controls['datasetId'].value"
                                            id="{{'fixedShareLabel'+i}}">{{getXbrlLabel('document_tradeTax_commune_fixedShare')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_tradeTax_commune_fixedShare-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_tradeTax_commune_fixedShare')"
                                            placeholder="{{getXbrlLabel('document_tradeTax_commune_fixedShare')}}"
                                            formControlName="document_tradeTax_commune_fixedShare" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_tradeTax_commune_fixedShare'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="2">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'tradeTax_commune_fixedShare-'+payload.controls['datasetId'].value"
                                            id="{{'fixedShareRefLabel'+i}}">{{getXbrlLabel('tradeTax_commune_fixedShare')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false"
                                            [id]="'tradeTax_commune_fixedShare-'+payload.controls['datasetId'].value"
                                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                                            [changeValueOnScroll]="false" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'tradeTax_commune_fixedShare')"
                                            placeholder="{{getXbrlLabel('tradeTax_commune_fixedShare')}}"
                                            formControlName="tradeTax_commune_fixedShare" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'tradeTax_commune_fixedShare'"></app-validation-message-formcontrol>
                                    </kendo-formfield></kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentTradeTaxBreakdownCommune")'>
                                        Gemeinde Löschen</button>
                                </kendo-gridlayout-item>

                            </kendo-gridlayout>
                        </div>
                    </div>

                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentTradeTaxBreakdownCommune")'>
                            Gemeinde hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="2">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="3"
                    *ngIf="tradeTaxBreakdownForm1300.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->