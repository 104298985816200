<form [formGroup]="propertyTaxForm" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-dropdown-notice-name [documentType]="datatypeName" [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationName"
                        [datasetId]="headerDatasetId" [form]="propertyTaxForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-dropdown-notice-name>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_companyIdentifier" [datasetId]="headerDatasetId" [form]="propertyTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType" overrideLabel="Kassenzeichen">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_notificationDate" [datasetId]="headerDatasetId" [form]="propertyTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <!-- <div [class]='(workflowType==11 && !(actualStateId==9000)) ? "column-for-with-plus" : "column"'> -->
                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="propertyTaxForm" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities"
                        overrideLabel="Zurechnung lt. Bescheid">
                    </app-stare-dropdown-string>
                </div>

                <!-- <div class="column-for-plus" *ngIf="workflowType==11 && !(actualStateId==9000)">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div> -->
                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKeyReference"
                        [datasetId]="headerDatasetId" [form]="propertyTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType" [dataOptionsSource]="legalEntities"
                        overrideLabel="Zurechnung lt. Referenz">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Wirtschaftliche Einheiten
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentPropertyTaxItem'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 571px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Wirtschaftliche Einheiten
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_companyIdentifier" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    overrideLabel="Einheitswertnummer">
                                </app-stare-textbox>
                            </div>
                            <div class="column">
                                <app-stare-dropdown-string [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_communeAGS" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    [dataOptionsSource]="communeAGS">
                                </app-stare-dropdown-string>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-payload-sub-header">
                                <legend class="k-form-legend section-header">
                                    Veranlagungsjahr
                                </legend>
                            </div>
                        </div>
                        <div formArrayName="documentNestedPayload" *ngFor="
                                let nestedPayload of documentNestedPayloadGetterOnlyControls(
                                    documentPayloadGetter(),
                                    payload.get('crossReference')?.value
                                );
                                let l = index
                            ">
                            <div *ngIf="
                                    nestedPayload.get('dataTypeName')?.value ==
                                    'DocumentPropertyTaxItemPayment'
                                " [formGroupName]="l">
                                <div class="row-for-sub-payload">
                                    <div class="row">
                                        <div class="column-for-header">
                                            <button kendoButton rounded="none" style="
                                                    margin-left: 430px;
                                                    margin-top: -25px;
                                                " class="custom-rounded" fillMode="outline" [disabled]="true">
                                                Veranlagungsjahr
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row-for-checkbox">
                                        <div class="column-for-sub-payload">
                                            <app-stare-integer [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="id_assessmentYear" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-integer>
                                        </div>
                                        <div class="column-for-checkbox" style="margin-top: 15px">
                                            <app-stare-checkbox [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_isTermination" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-checkbox>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_realEstate_propertyTax_value" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_realEstate_propertyTax_referenceValue"
                                                [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="true" (onReferenceDisable)="
                                                    onReferenceDisableReceiver(
                                                        $event
                                                    )
                                                " [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_realEstate_propertyTax_referenceValueBaseAmount"
                                                [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="true" (onReferenceDisable)="
                                                    onReferenceDisableReceiver(
                                                        $event
                                                    )
                                                " [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                        <div class="column-for-sub-payload">
                                            <app-stare-numerical [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                "
                                                xbrlName="document_realEstate_propertyTax_referenceValueCommuneRateFactor"
                                                [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="true" (onReferenceDisable)="
                                                    onReferenceDisableReceiver(
                                                        $event
                                                    )
                                                " [workflowType]="workflowType">
                                            </app-stare-numerical>
                                        </div>
                                    </div>
                                    <div class="row-for-checkbox">
                                        <div class="column-for-checkbox">
                                            <app-stare-checkbox [isArchiv]="
                                                    actualStateId == 9000
                                                " (onFieldFocus)="
                                                    onFieldFocusReceiver()
                                                " xbrlName="document_hasThresholdLimit" [datasetId]="
                                                    nestedPayload.controls[
                                                        'datasetId'
                                                    ].value
                                                " [form]="nestedPayload" [isReadOnly]="false"
                                                [workflowType]="workflowType">
                                            </app-stare-checkbox>
                                        </div>
                                    </div>

                                    <div class="row-for-payload-button">
                                        <div class="column">
                                            <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                                [disabled]="
                                                    (actualStateId == 9000 &&
                                                        !requestedByAdmin()) ||
                                                    (actualStateId == 9000 &&
                                                        requestedByAdmin() &&
                                                        !isUnlocked())
                                                " [type]="'button'" (click)="
                                                    deletePayload(
                                                        documentNestedPayloadGetter(
                                                            documentPayloadGetter(),
                                                            payload.get(
                                                                'crossReference'
                                                            )?.value
                                                        ),
                                                        l,
                                                        'DocumentPropertyTaxItemPayment'
                                                    )
                                                ">
                                                Veranlagungsjahr löschen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton themeColor="primary" [type]="'button'" [disabled]="
                                        (actualStateId == 9000 &&
                                            !requestedByAdmin()) ||
                                        (actualStateId == 9000 &&
                                            requestedByAdmin() &&
                                            !isUnlocked())
                                    " (click)="
                                        addPayload(
                                            'DocumentPropertyTaxItemPayment',
                                            payload.get('crossReference')?.value
                                        )
                                    ">
                                    Veranlagungsjahr hinzufügen
                                </button>
                            </div>
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" [disabled]="
                                        (actualStateId == 9000 &&
                                            !requestedByAdmin()) ||
                                        (actualStateId == 9000 &&
                                            requestedByAdmin() &&
                                            !isUnlocked())
                                    " (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentPropertyTaxItem'
                                        )
                                    ">
                                    Wirtschaftliche Einheit löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" [disabled]="
                            (actualStateId == 9000 && !requestedByAdmin()) ||
                            (actualStateId == 9000 &&
                                requestedByAdmin() &&
                                !isUnlocked())
                        " (click)="addPayload('DocumentPropertyTaxItem')">
                        Wirtschaftliche Einheit hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-checkbox" style="width: 400px">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_hasOtherRealEstateTaxes" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType"
                        overrideLabel="Enthält sonstige Grundbesitzabgaben">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_sumOfPropertyTaxValue" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="column-for-payload-sub-header">
                    <legend class="k-form-legend section-header">
                        Fälligkeit
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let l = index
                ">
                <div [formGroupName]="l" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentPropertyDueDatePayment'
                    ">
                    <div class="row-for-sub-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 503px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Fälligkeit
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column-for-sub-payload">
                                <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_realEstate_amountToPaidDate" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-datepicker>
                            </div>
                            <div class="column-for-sub-payload">
                                <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_realEstate_amountToPaid" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" [disabled]="
                                        (actualStateId == 9000 &&
                                            !requestedByAdmin()) ||
                                        (actualStateId == 9000 &&
                                            requestedByAdmin() &&
                                            !isUnlocked())
                                    " (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            l,
                                            'DocumentPropertyDueDatePayment'
                                        )
                                    ">
                                    Fälligkeit löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" [disabled]="
                            (actualStateId == 9000 && !requestedByAdmin()) ||
                            (actualStateId == 9000 &&
                                requestedByAdmin() &&
                                !isUnlocked())
                        " (click)="addPayload('DocumentPropertyDueDatePayment')">
                        Fälligkeit hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_sumOfAmountToPaid" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Fälligkeit in künftigen Jahren
                    </legend>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_futurePayment0215" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_futurePayment0515" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_futurePayment0815" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_futurePayment1115" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_futurePayment0701" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>

            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Mahngebühr / Säumniszuschlag
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-integer [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_assessmentYear" [datasetId]="headerDatasetId" [form]="propertyTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_reminderFeeAmountToPaid" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_reminderFeeAmountToPaidDate" [datasetId]="headerDatasetId"
                        [form]="propertyTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>

            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="propertyTaxForm" [auditResults]="auditResults" [workflowId]="workflowId"
                [is2000]="actualStateId == 2000" [isArchiv]="actualStateId == 9000"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 
                <form [formGroup]="propertyTaxForm" class="k-form k-form-md" style="padding-left: 8px;">
                    <fieldset class="fieldset">
                        <legend class="item-header">{{documentTypeLabelDE}} (ID: {{workflowId}},
                            {{actualStateLabelDE}})
                        </legend>
                        <div class="grid-layout-container">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 20 },
        { height: 20 },
        { height: documentPropertyTaxItemPayloadLength + documentPropertyTaxItemPaymentPayloadLength},
        { height: 50 },  
        { height: 90 },
        { height: 112 },					
        { height: 20 },
        { height: documentPropertyDueDatePaymentPayloadLength },
        { height: 50 },
        { height: 112 },
        { height: 20 },
        { height: 350 },
        { height: 20 },
        { height: 100},
        { height: 100},
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight},
        { height: sdcFieldsRowHeight },
        { height: sdcFieldsRowHeight+20},
        { height: sdcFieldsRowHeight/2 - 20},
        { height: 112 },
        { height: 20 },
        { height: 112 },
]" [cols]="[{ width: 250 }, { width: 250 }, { width:250 }]">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">
                                    <legend class="k-form-legend section-header">
                                        Bescheididentität
                                    </legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                                            id="companyIdentifierLabel">Kassenzeichen</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'id_companyIdentifier-'+headerDatasetId" type="text"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                                            placeholder="Kassenzeichen" formControlName="id_companyIdentifier"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                                            id="taxDeterminationLabel">Zurechnung
                                            lt.
                                            Bescheid</kendo-label>
                                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'"
                                            [valueField]="'value'" [id]="'id_legalEntityKey-'+headerDatasetId"
                                            [filterable]="true" [valuePrimitive]="true" [defaultItem]="defaultItem"
                                            formControlName="id_legalEntityKey"
                                            (filterChange)="handleLegalEntitiesFilter($event)"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                                            [data]="legalEntities"
                                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                                        <app-legalentity-add-button
                                            *ngIf="workflowType==11"></app-legalentity-add-button>
                                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="3" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label [for]="'id_legalEntityKeyReference-'+headerDatasetId"
                                            id="refKeyLabel">Zurechnung
                                            lt. Referenz</kendo-label>
                                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'"
                                            [valueField]="'value'" [id]="'id_legalEntityKeyReference-'+headerDatasetId"
                                            [filterable]="true" [valuePrimitive]="true" [defaultItem]="defaultItem"
                                            [tabindex]="-1" formControlName="id_legalEntityKeyReference"
                                            [readonly]="true" class="custom-fill-mode"
                                            (filterChange)="handleLegalEntitiesFilter($event)"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKeyReference')"
                                            [data]="legalEntities"></kendo-dropdownlist>
                                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                            [xbrlNameInput]="'id_legalEntityKeyReference'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="3" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                                            [value]="formGroup.controls['id_notificationDate'].value"
                                            format="dd.MM.yyyy"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                                            placeholder="{{getXbrlLabel('id_notificationDate')}}"
                                            formControlName="id_notificationDate" autocomplete="off">
                                            <kendo-datepicker-messages today="Heute"
                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                        </kendo-datepicker>
                                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>


                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">

                                    <legend class="k-form-legend section-header">
                                        Bewegungsdaten</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="3">
                                    <legend class="k-form-legend payload-parent-header">
                                        Wirtschaftliche Einheiten</legend>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">-->
<!--DocumentPropertyTaxItem Dynamic
                    containing DocumentPropertyTaxItemPayment Dynamic--><!--
<div formArrayName="documentPayload" *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
    <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentPropertyTaxItem'" [formGroupName]="i" class="payload">
        <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 110 }, 
                                { height: 20 },
                                { height: payload.get('documentNestedPayload')?.value.length * 510 },
                                { height: 50 }]" [cols]="[{ width: 270 }, { width: 370 }, { width: 70 }]">
            <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="1">
                <button kendoButton rounded="none" style="margin-left:-140px;" class="custom-rounded" fillMode="outline"
                    [disabled]="true">Wirtschaftliche
                    Einheiten</button>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                <kendo-formfield showHints="always" showErrors="always" class="part">
                    <kendo-label [for]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                        id="{{'id_companyIdentifier'+i}}">Einheitswertnummer
                    </kendo-label>
                    <kendo-textbox (focus)="onFocusInputXbrl()"
                        [id]="'id_companyIdentifier-'+payload.controls['datasetId'].value" type="text"
                        [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_companyIdentifier')"
                        placeholder="Einheitswertnummer" formControlName="id_companyIdentifier" autocomplete="off">
                    </kendo-textbox>
                    <app-validation-message-formcontrol [datasetIdInput]="payload.controls['datasetId'].value"
                        [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="2">
                <kendo-formfield showErrors="always" showHints="always" class="part">
                    <kendo-label [for]="'id_communeAGS-'+payload.controls['datasetId'].value"
                        id="agsLabel">{{getXbrlLabel('id_communeAGS')}}</kendo-label>
                    <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                        [id]="'id_communeAGS-'+payload.controls['datasetId'].value" [virtual]="{itemHeight: 31}"
                        [filterable]="true" [valuePrimitive]="true" [defaultItem]="defaultItem"
                        formControlName="id_communeAGS" (filterChange)="handleAGSFilter($event)"
                        [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_communeAGS')"
                        [data]="communeAGS"></kendo-dropdownlist>
                    <app-validation-message-formcontrol [datasetIdInput]="payload.controls['datasetId'].value"
                        [xbrlNameInput]="'id_communeAGS'"></app-validation-message-formcontrol>
                </kendo-formfield>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">

                <legend class="k-form-legend payload-parent-header">
                    Veranlagungsjahr</legend>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">-->
<!-- DocumentPropertyTaxItemPayment--><!--
                <div formArrayName="documentNestedPayload"
                    *ngFor="let nestedPayload of documentNestedPayloadGetterOnlyControls(documentPayloadGetter(), payload.get('crossReference')?.value); let l = index">
                    <div *ngIf="nestedPayload.get('dataTypeName')?.value == 'DocumentPropertyTaxItemPayment'"
                        [formGroupName]="l" class="payload">
                        <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                    { height: 100 }, { height: 100 },{ height: 100 },{ height: 90 }, { height: 50 }]"
                            [cols]="[{ width: 270 }, { width: 270 }]">
                            <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="1">
                                <button kendoButton rounded="none" style="margin-left:-101px;" class="custom-rounded"
                                    fillMode="outline" [disabled]="true">Veranlagungsjahr</button>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label [for]="'id_assessmentYear-'+nestedPayload.controls['datasetId'].value"
                                        id="id_assessmentYearLabel">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                    <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                        [changeValueOnScroll]="false" [decimals]="0" [maxlength]="4" format="##"
                                        [id]="'id_assessmentYear-'+nestedPayload.controls['datasetId'].value"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'id_assessmentYear')"
                                        placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                        formControlName="id_assessmentYear" autocomplete="off">
                                    </kendo-numerictextbox>
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">

                                    <kendo-label
                                        [for]="'document_isTermination-'+nestedPayload.controls['datasetId'].value"
                                        id="isTerminationLbl">
                                        {{getXbrlLabel('document_isTermination')}}
                                    </kendo-label>
                                    <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                                        style="margin-left: 5px ;margin-top: 8px ;"
                                        formControlName="document_isTermination"
                                        [id]="'document_isTermination-'+nestedPayload.controls['datasetId'].value" />
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_isTermination'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label
                                        [for]="'document_realEstate_propertyTax_value-'+nestedPayload.controls['datasetId'].value"
                                        id="{{'document_realEstate_propertyTax_value'+l}}">{{getXbrlLabel('document_realEstate_propertyTax_value')}}</kendo-label>
                                    <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                        [changeValueOnScroll]="false" [decimals]="2"
                                        [id]="'document_realEstate_propertyTax_value-'+nestedPayload.controls['datasetId'].value"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_realEstate_propertyTax_value')"
                                        placeholder="{{getXbrlLabel('document_realEstate_propertyTax_value')}}"
                                        formControlName="document_realEstate_propertyTax_value" autocomplete="off">
                                    </kendo-numerictextbox>
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_realEstate_propertyTax_value'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label
                                        [for]="'document_realEstate_propertyTax_referenceValue-'+nestedPayload.controls['datasetId'].value"
                                        id="{{'document_realEstate_propertyTax_referenceValue'+l}}">{{getXbrlLabel('document_realEstate_propertyTax_referenceValue')}}</kendo-label>
                                    <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                        [tabindex]="-1" [changeValueOnScroll]="false" [decimals]="2"
                                        [id]="'document_realEstate_propertyTax_referenceValue-'+nestedPayload.controls['datasetId'].value"
                                        [readonly]="true" class="custom-fill-mode" fillMode="none"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_realEstate_propertyTax_referenceValue')"
                                        placeholder="{{getXbrlLabel('document_realEstate_propertyTax_referenceValue')}}"
                                        formControlName="document_realEstate_propertyTax_referenceValue"
                                        autocomplete="off">
                                    </kendo-numerictextbox>
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_realEstate_propertyTax_referenceValue'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label
                                        [for]="'document_realEstate_propertyTax_referenceValueBaseAmount-'+nestedPayload.controls['datasetId'].value"
                                        id="{{'document_realEstate_propertyTax_referenceValueBaseAmount'+l}}">{{getXbrlLabel('document_realEstate_propertyTax_referenceValueBaseAmount')}}</kendo-label>
                                    <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                        [tabindex]="-1" [changeValueOnScroll]="false" [decimals]="2"
                                        [id]="'document_realEstate_propertyTax_referenceValueBaseAmount-'+nestedPayload.controls['datasetId'].value"
                                        [readonly]="true" class="custom-fill-mode" fillMode="none"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_realEstate_propertyTax_referenceValueBaseAmount')"
                                        placeholder="{{getXbrlLabel('document_realEstate_propertyTax_referenceValueBaseAmount')}}"
                                        formControlName="document_realEstate_propertyTax_referenceValueBaseAmount"
                                        autocomplete="off">
                                    </kendo-numerictextbox>
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_realEstate_propertyTax_referenceValueBaseAmount'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label
                                        [for]="'document_realEstate_propertyTax_referenceValueCommuneRateFactor-'+nestedPayload.controls['datasetId'].value"
                                        id="{{'document_realEstate_propertyTax_referenceValueCommuneRateFactor'+l}}">{{getXbrlLabel('document_realEstate_propertyTax_referenceValueCommuneRateFactor')}}</kendo-label>
                                    <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                        [changeValueOnScroll]="false" [tabindex]="-1"
                                        [id]="'document_realEstate_propertyTax_referenceValueCommuneRateFactor-'+nestedPayload.controls['datasetId'].value"
                                        [readonly]="true" class="custom-fill-mode" fillMode="none" format="p"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(nestedPayload.controls['datasetId'].value,'document_realEstate_propertyTax_referenceValueCommuneRateFactor')"
                                        placeholder="{{getXbrlLabel('document_realEstate_propertyTax_referenceValueCommuneRateFactor')}}"
                                        formControlName="document_realEstate_propertyTax_referenceValueCommuneRateFactor"
                                        autocomplete="off">
                                    </kendo-numerictextbox>
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_realEstate_propertyTax_referenceValue'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>
                            <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                <kendo-formfield showHints="initial" showErrors="initial" class="part">

                                    <kendo-label
                                        [for]="'document_hasThresholdLimit-'+nestedPayload.controls['datasetId'].value"
                                        id="{{'effectiveDateRuleLabel'+l}}">
                                        {{getXbrlLabel('document_hasThresholdLimit')}}
                                    </kendo-label>
                                    <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                                        style="margin-left: 5px ;margin-top: 8px ;"
                                        formControlName="document_hasThresholdLimit"
                                        [id]="'document_hasThresholdLimit-'+nestedPayload.controls['datasetId'].value" />
                                    <app-validation-message-formcontrol
                                        [datasetIdInput]="nestedPayload.controls['datasetId'].value"
                                        [xbrlNameInput]="'document_hasThresholdLimit'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>-->
<!--Delete for DocumentPropertyTaxItemPayment--><!--
                            <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="deletePayload(documentNestedPayloadGetter(documentPayloadGetter(), payload.get('crossReference')?.value),
                                                        l, 'DocumentPropertyTaxItemPayment')">Veranlagungsjahr
                                    löschen</button>
                            </kendo-gridlayout-item>

                        </kendo-gridlayout>
                    </div>
                </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                <div class="part">
                    <button kendoButton themeColor="primary" [type]="'button'"
                        (click)="addPayload('DocumentPropertyTaxItemPayment', payload.get('crossReference')?.value)">
                        Veranlagungsjahr hinzufügen</button>
                </div>
            </kendo-gridlayout-item>

            <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'" [type]="'button'"
                    (click)='deletePayload(documentPayloadGetter(), i, "DocumentPropertyTaxItem")'>
                    Wirtschaftliche Einheit löschen</button>
            </kendo-gridlayout-item>

        </kendo-gridlayout>
    </div>
</div>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="2">
    <div class="part">
        <button kendoButton themeColor="primary" [type]="'button'" (click)='addPayload("DocumentPropertyTaxItem")'>
            Wirtschaftliche Einheit hinzufügen</button>
    </div>
</kendo-gridlayout-item>


<kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="3">-->
<!--Enthält sonstige Grundbesitzabgaben--><!--
    <kendo-formfield showHints="initial" showErrors="initial" class="part">

        <kendo-label [for]="'document_realEstate_hasOtherRealEstateTaxes-'+headerDatasetId"
            id="document_realEstate_hasOtherRealEstateTaxesLabel">Enthält sonstige
            Grundbesitzabgaben
        </kendo-label>
        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            formControlName="document_realEstate_hasOtherRealEstateTaxes"
            [id]="'document_realEstate_hasOtherRealEstateTaxes-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_realEstate_hasOtherRealEstateTaxes'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">-->
<!--Summe Beträge Grundsteuer--><!--
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_realEstate_sumOfPropertyTaxValue-'+headerDatasetId"
            id="{{'document_realEstate_sumOfPropertyTaxValue'}}">
            {{getXbrlLabel('document_realEstate_sumOfPropertyTaxValue')}}
        </kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
            [id]="'document_realEstate_sumOfPropertyTaxValue-'+headerDatasetId" [tabindex]="-1"
            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode" fillMode="none"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_sumOfPropertyTaxValue')"
            placeholder="{{getXbrlLabel('document_realEstate_sumOfPropertyTaxValue')}}"
            formControlName="document_realEstate_sumOfPropertyTaxValue" autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_realEstate_sumOfPropertyTaxValue'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="3">

    <legend class="k-form-legend payload-parent-header">
        Fälligkeit</legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="3">
    <div formArrayName="documentPayload" *ngFor="let payload of documentPayloadGetterOnlyControls; let l = index">
        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentPropertyDueDatePayment'" [formGroupName]="l"
            class="payload">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                { height: 100 }, 
                                { height: 50 }]" [cols]="[{ width: 270 }, { width: 270 }]">
                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                    <button kendoButton rounded="none" style="margin-left:360px;" class="custom-rounded"
                        fillMode="outline" [disabled]="true">Fälligkeit</button>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_realEstate_amountToPaidDate-'+payload.controls['datasetId'].value"
                            id="{{'document_realEstate_amountToPaidDate'+l}}">{{getXbrlLabel('document_realEstate_amountToPaidDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="payload.controls['document_realEstate_amountToPaidDate'].value" format="dd.MM.yyyy"
                            [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_realEstate_amountToPaidDate')"
                            [id]="'document_realEstate_amountToPaidDate-'+payload.controls['datasetId'].value"
                            placeholder="{{getXbrlLabel('document_realEstate_amountToPaidDate')}}"
                            formControlName="document_realEstate_amountToPaidDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]="payload.controls['datasetId'].value"
                            [xbrlNameInput]="'document_realEstate_amountToPaidDate'"></app-validation-message-formcontrol>
                    </kendo-formfield></kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_realEstate_amountToPaid-'+payload.controls['datasetId'].value"
                            id="{{'document_realEstate_amountToPaidLabel'}}">
                            {{getXbrlLabel('document_realEstate_amountToPaid')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_realEstate_amountToPaid-'+payload.controls['datasetId'].value"
                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_realEstate_amountToPaid')"
                            placeholder="{{getXbrlLabel('document_realEstate_amountToPaid')}}"
                            formControlName="document_realEstate_amountToPaid" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]="payload.controls['datasetId'].value"
                            [xbrlNameInput]="'document_realEstate_amountToPaid'"></app-validation-message-formcontrol>
                    </kendo-formfield></kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'" [type]="'button'"
                        (click)='deletePayload(documentPayloadGetter(), l, "DocumentPropertyDueDatePayment")'>
                        Fälligkeit löschen</button>
                </kendo-gridlayout-item>

            </kendo-gridlayout>
        </div>
    </div>

</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="2">
    <div class="part">
        <button kendoButton themeColor="primary" [type]="'button'"
            (click)='addPayload("DocumentPropertyDueDatePayment")'>
            Fälligkeit hinzufügen</button>
    </div>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="2">-->
<!--Summe der Fälligkeiten--><!--
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_realEstate_sumOfAmountToPaid-'+headerDatasetId"
            id="{{'document_realEstate_sumOfAmountToPaidLabel'}}">
            {{getXbrlLabel('document_realEstate_sumOfAmountToPaid')}}
        </kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
            [id]="'document_realEstate_sumOfAmountToPaid-'+headerDatasetId" [tabindex]="-1"
            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode" fillMode="none"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_sumOfAmountToPaid')"
            placeholder="{{getXbrlLabel('document_realEstate_sumOfAmountToPaid')}}"
            formControlName="document_realEstate_sumOfAmountToPaid" autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_realEstate_sumOfAmountToPaid'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="3">
    <legend class="k-form-legend section-header">
        Fälligkeit in künftigen Jahren</legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="3">-->
<!--Fälligkeit in künftigen Jahren--><!--
    <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
                                    { height: 100 }, { height: 100 }, { height: 100 }]"
        [cols]="[{ width: 270 }, { width: 270 }]" class="payload">
        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_futurePayment0215-'+headerDatasetId"
                    id="{{'document_realEstate_futurePayment0215Label'}}">
                    {{getXbrlLabel('document_realEstate_futurePayment0215')}}
                </kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [id]="'document_realEstate_futurePayment0215-'+headerDatasetId" [changeValueOnScroll]="false"
                    [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_futurePayment0215')"
                    placeholder="{{getXbrlLabel('document_realEstate_futurePayment0215')}}"
                    formControlName="document_realEstate_futurePayment0215" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_futurePayment0215'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_futurePayment0515-'+headerDatasetId"
                    id="{{'document_realEstate_futurePayment0515Label'}}">
                    {{getXbrlLabel('document_realEstate_futurePayment0515')}}
                </kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [id]="'document_realEstate_futurePayment0515-'+headerDatasetId" [changeValueOnScroll]="false"
                    [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_futurePayment0515')"
                    placeholder="{{getXbrlLabel('document_realEstate_futurePayment0515')}}"
                    formControlName="document_realEstate_futurePayment0515" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_futurePayment0515'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_futurePayment0815-'+headerDatasetId"
                    id="{{'document_realEstate_futurePayment0815Label'}}">
                    {{getXbrlLabel('document_realEstate_futurePayment0815')}}
                </kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [id]="'document_realEstate_futurePayment0815-'+headerDatasetId" [changeValueOnScroll]="false"
                    [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_futurePayment0815')"
                    placeholder="{{getXbrlLabel('document_realEstate_futurePayment0815')}}"
                    formControlName="document_realEstate_futurePayment0815" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_futurePayment0815'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_futurePayment1115-'+headerDatasetId"
                    id="{{'document_realEstate_futurePayment1115Label'}}">
                    {{getXbrlLabel('document_realEstate_futurePayment1115')}}
                </kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [id]="'document_realEstate_futurePayment1115-'+headerDatasetId" [changeValueOnScroll]="false"
                    [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_futurePayment1115')"
                    placeholder="{{getXbrlLabel('document_realEstate_futurePayment1115')}}"
                    formControlName="document_realEstate_futurePayment1115" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_futurePayment1115'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
            <kendo-formfield showHints="always" showErrors="always" class="part">
                <kendo-label [for]="'document_realEstate_futurePayment0701-'+headerDatasetId"
                    id="{{'document_realEstate_futurePayment0701Label'}}">
                    {{getXbrlLabel('document_realEstate_futurePayment0701')}}
                </kendo-label>
                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                    [id]="'document_realEstate_futurePayment0701-'+headerDatasetId" [changeValueOnScroll]="false"
                    [decimals]="2"
                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_futurePayment0701')"
                    placeholder="{{getXbrlLabel('document_realEstate_futurePayment0701')}}"
                    formControlName="document_realEstate_futurePayment0701" autocomplete="off">
                </kendo-numerictextbox>
                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                    [xbrlNameInput]="'document_realEstate_futurePayment0701'"></app-validation-message-formcontrol>
            </kendo-formfield>
        </kendo-gridlayout-item>
    </kendo-gridlayout>
</kendo-gridlayout-item> <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="3">
    <legend class="k-form-legend section-header">
        Mahngebühr / Säumniszuschlag
    </legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="1">
    <kendo-formfield showErrors="always" showHints="always" class="part">
        <kendo-label [for]="'document_realEstate_reminderFeeAssessmentYear-'+headerDatasetId"
            id="reminderfeeyearLbl">{{getXbrlLabel('document_realEstate_reminderFeeAssessmentYear')}}</kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [decimals]="0" [maxlength]="4" format="##"
            [id]="'document_realEstate_reminderFeeAssessmentYear-'+headerDatasetId" [changeValueOnScroll]="false"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_reminderFeeAssessmentYear')"
            placeholder="{{getXbrlLabel('document_realEstate_reminderFeeAssessmentYear')}}"
            formControlName="document_realEstate_reminderFeeAssessmentYear" autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]="headerDatasetId"
            [xbrlNameInput]="'document_realEstate_reminderFeeAssessmentYear'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="1">
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_realEstate_reminderFeeAmountToPaid-'+headerDatasetId"
            id="reminderfeeLbl">{{getXbrlLabel('document_realEstate_reminderFeeAmountToPaid')}}</kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false" [decimals]="2"
            [id]="'document_realEstate_reminderFeeAmountToPaid-'+headerDatasetId" [changeValueOnScroll]="false"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_reminderFeeAmountToPaid')"
            placeholder="{{getXbrlLabel('document_realEstate_reminderFeeAmountToPaid')}}"
            formControlName="document_realEstate_reminderFeeAmountToPaid" autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_realEstate_reminderFeeAmountToPaid'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="18" [col]="2" [colSpan]="1">
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_realEstate_reminderFeeAmountToPaidDate-'+headerDatasetId"
            id="mahnDateLabel">{{getXbrlLabel('document_realEstate_reminderFeeAmountToPaidDate')}}</kendo-label>
        <kendo-datepicker (focus)="onFocusInputXbrl()"
            [value]="formGroup.controls['document_realEstate_reminderFeeAmountToPaidDate'].value" format="dd.MM.yyyy"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_realEstate_reminderFeeAmountToPaidDate')"
            [id]="'document_realEstate_reminderFeeAmountToPaidDate-'+headerDatasetId" [enableMouseWheel]="false"
            placeholder="{{getXbrlLabel('document_realEstate_reminderFeeAmountToPaidDate')}}"
            formControlName="document_realEstate_reminderFeeAmountToPaidDate" autocomplete="off">
            <kendo-datepicker-messages today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
        </kendo-datepicker>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_realEstate_reminderFeeAmountToPaidDate'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="3">
    <legend class="k-form-legend section-header">
        Prüfungsergebnis</legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="1">
    <kendo-formfield showHints="initial" showErrors="initial" class="part">

        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
            {{getXbrlLabel('document_withoutReferenceValidation')}}
        </kendo-label>
        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            (change)="changeExplanationRowHeight()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
            formControlName="document_withoutReferenceValidation"
            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="2"
    *ngIf="propertyTaxForm.controls['document_withoutReferenceValidation'].value==true">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
        <kendo-textarea [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
            (focus)="onFocusInputXbrl()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off" [rows]="2"
            resizable="vertical">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
            (focus)="onFocusInputXbrl()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="23" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
            (focus)="onFocusInputXbrl()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="24" [col]="1" [colSpan]="1" *ngIf="workflowType==11">
    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions">
        Kommentartext
        hinzufügen
    </kendo-splitbutton>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="25" [col]="1" [colSpan]="2">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document_auditResult-'+headerDatasetId"
            id="auditResultLabel">{{getXbrlLabel('document_auditResult')}}</kendo-label>
        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
            [id]="'document_auditResult-'+headerDatasetId" [valuePrimitive]="true"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_auditResult')"
            [defaultItem]="defaultItem" formControlName="document_auditResult" [data]="auditResults">
        </kendo-dropdownlist>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document_auditResult'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>



</kendo-gridlayout>
</div>

</fieldset>
<br>
</form> -->