import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ENVIRONMENT } from '../../environments/environment';
//import { AuthenticationService } from '../_services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        //private readonly authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        //const user = this.authenticationService.userValue;
        const token = localStorage.getItem('stare_token') || '';
        //const isLoggedIn = user?.token;
        const isApiUrl = request.url.startsWith(ENVIRONMENT.apiBaseUrl);
        const isApiMaintenanceUrl = request.url.startsWith(ENVIRONMENT.maintenanceApiBaseUrl);

        if (token.length > 2 && (isApiUrl || isApiMaintenanceUrl)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}