import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { DataService } from 'app/_services/StareApi/data.service';

@Component({
  selector: 'app-stare-checkbox',
  templateUrl: './stare-checkbox.component.html',
  styleUrl: './stare-checkbox.component.scss',
  standalone: false
})

export class StareCheckboxComponent {
  @Output() onFieldFocus = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlName: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;
  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  //ex. Kassenzeicht und Steuernummer
  @Input() overrideLabel: string = '';

  @Input() isArchiv: boolean = false;

  public label: string = '';
  public class: string = '';


  constructor(

    private dataService: DataService,
    public stareInputFieldsService: StareInputFieldsService

  ) {

  }

  ngOnInit(): void {
    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        // The form has loaded successfully
        this.label = this.overrideLabel ? this.overrideLabel : this.stareInputFieldsService.getXbrlLabel(this.xbrlName);
      }
    });


    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    // this.class = this.isReadOnly ? 'custom-fill-mode' : 'custom-base-mode';

  }

  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }

  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }

  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }


}
