<kendo-appbar positionMode="fixed" position="top"
	style=" background: white; box-shadow: 0px 0px 0px 0px; position: fixed; width: 100%;">
	<kendo-appbar-section>
		<kendo-avatar [imageSrc]="pwcLogo" rounded="none" width="50px" height="50px"></kendo-avatar>
	</kendo-appbar-section>

	<kendo-appbar-section>
		<span class="k-appbar-separator"></span>

		<h1 class="title">{{ 'APP.NAME' | translate }}</h1>

	</kendo-appbar-section>

	<kendo-appbar-spacer></kendo-appbar-spacer>

	<kendo-appbar-spacer></kendo-appbar-spacer>
	<kendo-appbar-section class="actions">
		<kendo-badge-container>
			<div class="component-container">
				<kendo-label for="clientList">
					<kendo-dropdownlist fillMode="solid" style=" width: 300px;" id="clientList"
						[data]="clientEngagemenList" textField="labelDE" valueField="value"
						(selectionChange)="onSelectItemClient($event)" [filterable]="true"
						(filterChange)="handleClientEngagementFilter($event)"
						[popupSettings]="{ height: 450, width: 350 }"
						[(ngModel)]="selectedClientEngagement"></kendo-dropdownlist>
				</kendo-label>
			</div>
		</kendo-badge-container>
		<span class="k-appbar-separator"></span>
	</kendo-appbar-section>

	<kendo-appbar-section class="actions">
		<kendo-badge-container>
			<div class="component-container">
				<kendo-dropdownbutton [data]="userDropDownList" rounded="large" fillMode="solid" themeColor="primary"
					size="large" textField="label">
					{{ firstname[0] }}{{ lastname[0] }}
				</kendo-dropdownbutton>
			</div>
		</kendo-badge-container>
	</kendo-appbar-section>
</kendo-appbar>

<kendo-drawer-container>
	<kendo-drawer #drawer size="large" [items]="navList" mode="push" [mini]="true" [(expanded)]="expanded"
		(select)="redirectNav($event)">
		<ng-template kendoDrawerItemTemplate let-item>
			<kendo-svg-icon class="menuItem" [icon]="item.svgIcon" size="large" kendoTooltip title="{{ item.text}}"
				position="right"></kendo-svg-icon>
		</ng-template>
	</kendo-drawer>
</kendo-drawer-container>

<!--Content modal for other components-->
<div kendoDialogContainer></div>
<div class="a-template-content a-bg-light p-4" style="top: 4.5em;">
	<router-outlet></router-outlet>
</div>


<!--Footer  
<div class="footer-wrapper flex-shrink-0 text-center a-bg-light">
	<footer class="a-footer" [innerHTML]="'APP.FOOTER.COPYRIGHT' | translate: {'year': footerCopyrightFrom}"></footer>
</div>
-->