import {
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import {
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DatasetItem } from 'app/_models/dataset.model';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
    selector: 'app-doc-trade-tax',
    templateUrl: './doc-trade-tax.component.html',
    styleUrls: ['../doc-base/doc-base.component.scss'],
    standalone: false
})
export class DocTradeTaxComponent
    extends DocBaseComponent
    implements AfterContentInit, AfterViewInit, AfterViewChecked {
    constructor(
        listsService: ListsService,

        apiUIViewerService: UIViewerService,

        private notificationService: WrapperNotificationService,
        apidataService: DataService,
        dateTimeService: DateTimeService,
        apiDatasetService: ApiDatasetService,
        apiWorkflowService: ApiWorkflowService
    ) {
        super(
            listsService,
            apiUIViewerService,
            apidataService,
            dateTimeService,
            apiDatasetService,
            apiWorkflowService
        );
        this._formGroup = this.tradeTaxForm;
    }

    minVZDate: Date = new Date(2000, 0, 1); // Default min date (can be adjusted)
    maxVZDate: Date = new Date(3000, 0, 1); // Default max date (can be adjusted)

    // public documentTradeTaxItemPayloadLength: number = 0;
    // public quarterlyPrepaymentRowHeight: number = 0;
    // public documentTradeTaxPaymentFromPrePaymentPayloadLength: number = 0;
    // public documentTradeTaxPaymentFromInterestPayloadLength: number = 0;
    // public documentTradeTaxPrePaymentPayloadLength: number = 0;
    // public documentTradeTaxInterestPayloadLength: number = 0;
    // public documentTradeTaxInterestRunPayloadLength: number = 0;

    //#region Form ======================================================================================================

    //Gewerbesteuer dynamisch
    public tradeTaxForm = new UntypedFormGroup({
        id_notificationDate: new UntypedFormControl(''), //Bescheiddatum
        id_communeAGS: new UntypedFormControl(''),
        id_companyIdentifier: new UntypedFormControl(''), //Steuernummer
        id_legalEntityKey: new UntypedFormControl(''), //Unternehmen
        document_tradeTax_fixedShare: new UntypedFormControl(''),
        document_tradeTax_salaries: new UntypedFormControl(''), //reference stichtag
        tradeTax_fixedShare: new UntypedFormControl(''), //reference
        tradeTax_salaries: new UntypedFormControl(''), //reference
        document_tradeTax_p165: new UntypedFormControl(''),
        document_auditResult: new UntypedFormControl(''),
        documentPayload: new UntypedFormArray([]),
        //  document_assessmentDetermination : new UntypedFormControl(""),

        document_hasThresholdLimit: new UntypedFormControl(''),
        document_withoutReferenceValidation: new UntypedFormControl(''),
        document_withoutReferenceValidationExplanation: new UntypedFormControl(
            ''
        ),
        document_mail_requestedDocuments: new UntypedFormControl(''),
        document_mail_reasonNotAccepted: new UntypedFormControl(''),
        document_tradeTax_reminderFeeAmountToPaid: new UntypedFormControl(''),
        document_tradeTax_reminderFeeAmountToPaidDate: new UntypedFormControl(
            ''
        ),
        document_tradeTax_reminderFeeAssessmentYear: new UntypedFormControl(''),
        document_missingBankAccountDetails: new UntypedFormControl(''),
    });

    // creates initial payloads for Gewerbesteuer: Übersicht der Einträge (Payload)
    //"DocumentTradeTaxItem" - Festsetzung
    createTradeTaxItemPayload(datasetId: number): UntypedFormGroup {
        const communePayload = new UntypedFormGroup({
            id_assessmentYear: new UntypedFormControl(''),
            document_tradeTax_amount: new UntypedFormControl(''),
            document_tradeTax_amount_referenceCalculated:
                new UntypedFormControl(''),
            document_tradeTax_paymentsMade: new UntypedFormControl(''),
            tradeTax_paymentsMade: new UntypedFormControl(''),
            document_tradeTax_paymentsToBeMade: new UntypedFormControl(''),
            document_tradeTax_paymentsToBeMade_reference:
                new UntypedFormControl(''),
            document_tradeTax_amountToPaid: new UntypedFormControl(''),
            document_tradeTax_amountToPaidDate: new UntypedFormControl(''),

            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl('DocumentTradeTaxItem', {
                initialValueIsDefault: true,
            }),
        });
        console.log('Adding TradeTaxItemPayload: ' + datasetId);
        return communePayload;
    }

    // Gewerbesteuer: Steuerliche Zahlungen Yinsen(Payload)
    //"DocumentTradeTaxPaymentFromInterest"
    createTradeTaxPaymentFromInterestPayload(
        datasetId: number,
        crossReference?: string
    ): UntypedFormGroup {
        const communePayload = new UntypedFormGroup({
            document_tradeTax_amountToPaidDate: new UntypedFormControl(''),
            document_tradeTax_amountToPaid: new UntypedFormControl(''),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl(
                'DocumentTradeTaxPaymentFromInterest',
                { initialValueIsDefault: true }
            ),
        });
        console.log('Adding FromInterestPayload: ' + datasetId);

        return communePayload;
    }
    // Gewerbesteuer: Zinslauf(Payload)
    //"DocumentTradeTaxInterestRun"
    createTradeTaxInterestRunPayload(
        datasetId: number,
        crossReference?: string
    ): UntypedFormGroup {
        const runPayload = new UntypedFormGroup({
            document_tradeTax_interest_beginDate: new UntypedFormControl(''),
            tradeTax_interest_beginDate: new UntypedFormControl(''),
            document_tradeTax_interest_endDate: new UntypedFormControl(''),
            tradeTax_interest_endDate: new UntypedFormControl(''),
            document_tradeTax_interest_rate: new UntypedFormControl(''),
            tradeTax_interest_rate: new UntypedFormControl(''),
            document_tradeTax_interest_amountMustBeInterest:
                new UntypedFormControl(''),
            tradeTax_interest_amountMustBeInterest: new UntypedFormControl(''),
            document_tradeTax_interest_value: new UntypedFormControl(''),
            tradeTax_interest_value: new UntypedFormControl(''),

            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl(
                'DocumentTradeTaxInterestRun',
                { initialValueIsDefault: true }
            ),
        });

        console.log('Adding InterestRunPayload: ' + datasetId);
        return runPayload;
    }
    // Gewerbesteuer: Steuerliche Zahlungen PrePayment (Payload)
    //"DocumentTradeTaxPaymentFromPrePayment"
    createTradeTaxPaymentFromPrePaymentPayload(
        datasetId: number,
        crossReference?: string
    ): UntypedFormGroup {
        const communePayload = new UntypedFormGroup({
            document_tradeTax_amountToPaidDate: new UntypedFormControl(''),
            document_tradeTax_amountToPaid: new UntypedFormControl(''),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl(
                'DocumentTradeTaxPaymentFromPrePayment',
                { initialValueIsDefault: true }
            ),
        });

        console.log('Adding FromPrePaymentPayload: ' + datasetId);
        return communePayload;
    }

    // Steuerliche Vorauszahlungen (Payload)
    //"DocumentTradeTaxPrePayment"
    createTradeTaxPrePaymentPayload(
        datasetId: number,
        crossReference?: string
    ): UntypedFormGroup {
        const prePaymentPayload = new UntypedFormGroup({
            id_assessmentYear: new UntypedFormControl(''),
            document_tradeTax_forSubsequentYears: new UntypedFormControl(''),
            document_tradeTax_amount: new UntypedFormControl(''),
            document_tradeTax_amount_referenceCalculated:
                new UntypedFormControl(''),
            document_tradeTax_paymentsMade: new UntypedFormControl(''),
            document_tradeTax_paymentsToBeMade_reference:
                new UntypedFormControl(''),
            tradeTax_paymentsMade: new UntypedFormControl(''),
            document_tradeTax_paymentsToBeMade: new UntypedFormControl(''),
            documentNestedPayload: new UntypedFormArray([]),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl('DocumentTradeTaxPrePayment', {
                initialValueIsDefault: true,
            }),
        });

        console.log('Adding prePaymentPayload: ' + datasetId);
        return prePaymentPayload;
    }

    // Gewerbesteuer: Zinsen (Payload)
    //"DocumentTradeTaxInterest"
    createTradeTaxInterestPayload(
        datasetId: number,
        crossReference?: string
    ): UntypedFormGroup {
        const interestPayload = new UntypedFormGroup({
            id_assessmentYear: new UntypedFormControl(''),
            document_tradeTax_interest_paymentsMade: new UntypedFormControl(''),
            document_tradeTax_interest_value: new UntypedFormControl(''),
            tradeTax_interest_paymentsMade: new UntypedFormControl(''),
            document_tradeTax_interest_paymentsToBeMade: new UntypedFormControl(
                ''
            ),
            tradeTax_interest_paymentsToBeMade: new UntypedFormControl(''),
            tradeTax_interest_valueNewLaw: new UntypedFormControl(''),
            tradeTax_interest_valueOldLaw: new UntypedFormControl(''),
            // document.tradeTax.interest.pastRuns : new UntypedFormControl(""),
            documentNestedPayload: new UntypedFormArray([]),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl('DocumentTradeTaxInterest', {
                initialValueIsDefault: true,
            }),
        });

        console.log('Adding interestPayload: ' + datasetId);
        console.log(datasetId);
        return interestPayload;
    }

    //#endregion

    //initializes the payload form array
    //switch case for diff datatypes
    addSingularPayload(payload: DatasetItem): void {
        const payloadArray = this.documentPayloadGetter();
        switch (payload.dataTypeName) {
            case 'DocumentTradeTaxItem': {
                payloadArray.push(this.createTradeTaxItemPayload(payload.id));
                console.info('Add' + payload.dataTypeName);
                // this.documentTradeTaxItemPayloadLength = this.documentTradeTaxItemPayloadLength + 709;

                break;
            }
            case 'DocumentTradeTaxPaymentFromPrePayment': {
                const newPayloadArray = this.documentNestedPayloadGetter(
                    payloadArray,
                    payload.crossReference
                );
                if (newPayloadArray) {
                    newPayloadArray.push(
                        this.createTradeTaxPaymentFromPrePaymentPayload(
                            payload.id,
                            payload.crossReference
                        )
                    );
                    console.info('Add ' + payload.dataTypeName);
                    // this.documentTradeTaxPaymentFromPrePaymentPayloadLength = this.documentTradeTaxPaymentFromPrePaymentPayloadLength + 210;
                }

                break;
            }
            case 'DocumentTradeTaxPaymentFromInterest': {
                const newPayloadArray = this.documentNestedPayloadGetter(
                    payloadArray,
                    payload.crossReference
                );
                if (newPayloadArray) {
                    newPayloadArray.push(
                        this.createTradeTaxPaymentFromInterestPayload(
                            payload.id,
                            payload.crossReference
                        )
                    );
                    console.info('Add ' + payload.dataTypeName);
                    // this.documentTradeTaxPaymentFromInterestPayloadLength = this.documentTradeTaxPaymentFromInterestPayloadLength + 200;
                }
                break;
            }
            case 'DocumentTradeTaxInterestRun': {
                const newPayloadArray = this.documentNestedPayloadGetter(
                    payloadArray,
                    payload.crossReference
                );
                if (newPayloadArray) {
                    newPayloadArray.push(
                        this.createTradeTaxInterestRunPayload(
                            payload.id,
                            payload.crossReference
                        )
                    );
                    console.info('Add ' + payload.dataTypeName);
                    // this.documentTradeTaxInterestRunPayloadLength = this.documentTradeTaxInterestRunPayloadLength + 655;
                }
                break;
            }
            case 'DocumentTradeTaxPrePayment': {
                // this.quarterlyPrepaymentRowHeight = 356;
                payloadArray.push(
                    this.createTradeTaxPrePaymentPayload(
                        payload.id,
                        payload.crossReference
                    )
                );
                console.info('Add' + payload.dataTypeName);
                // this.documentTradeTaxPrePaymentPayloadLength = this.documentTradeTaxPrePaymentPayloadLength + 635;

                break;
            }
            case 'DocumentTradeTaxInterest': {
                payloadArray.push(
                    this.createTradeTaxInterestPayload(
                        payload.id,
                        payload.crossReference
                    )
                );
                console.info('Add' + payload.dataTypeName);
                // this.documentTradeTaxInterestPayloadLength = this.documentTradeTaxInterestPayloadLength + 720;

                break;
            }
            default:
                console.error(
                    'Unbekannt payload type: ' + payload.dataTypeName
                );
        }
    }

    addQuarterPayload(
        dataType: string,
        payloadGroup: UntypedFormGroup,
        crossReference?: string
    ): void {
        const assesmentDate =
            this._formGroup.controls['id_notificationDate'].value;
        const newPayloadArrayForCheck = this.documentNestedPayloadGetter(
            this.documentPayloadGetter(),
            crossReference
        );
        const year = payloadGroup.controls['id_assessmentYear'].value;
        if (!year) {
            this.notificationService.showError('Das Veranlagungsjahr fehlt.');
            return;
        }
        if (!assesmentDate) {
            this.notificationService.showError('Bescheiddatum fehlt.');
            return;
        }
        const quarterArray = [
            new Date(year, 1, 15),
            new Date(year, 4, 15),
            new Date(year, 7, 15),
            new Date(year, 10, 15),
        ];
        this.checkIfQuarterNeeded(year, newPayloadArrayForCheck!);
        for (let i = 0; i <= 3; i++) {
            if (quarterArray[i] > assesmentDate && this.quarterArrayNeeded[i]) {
                this.addPayloadAsync(
                    dataType,
                    this.workflowId,
                    crossReference
                ).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        if (newDatasetID != null) {
                            if (newPayloadArrayForCheck) {
                                newPayloadArrayForCheck.push(
                                    this.createQuarterTradeTaxPaymentFromPrePaymentPayload(
                                        newDatasetID,
                                        quarterArray[i],
                                        crossReference
                                    )
                                );
                                this.documentPayloadDatasetIdArray.push(
                                    newDatasetID
                                );
                                // this.documentTradeTaxPaymentFromPrePaymentPayloadLength = this.documentTradeTaxPaymentFromPrePaymentPayloadLength + 210;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                    },
                });
            }
        }

        //sort needed here
        //this.sortNestedQuarterPayments(newPayloadArrayForCheck!);
        this.notificationService.showSuccess(
            'Alle fehlenden Quartalszahlungen erfolgreich erzeugt. Falls keine neue Zahlungen hinzugefügt wurden, sind alle erforderlichen Zahlungen schon vorhanden.'
        );
    }



    createQuarterTradeTaxPaymentFromPrePaymentPayload(
        datasetId: number,
        toPaidDate: Date,
        crossReference?: string
    ): UntypedFormGroup {
        const communePayload = new UntypedFormGroup({
            document_tradeTax_amountToPaidDate: new UntypedFormControl(
                toPaidDate,
                { initialValueIsDefault: true }
            ),
            document_tradeTax_amountToPaid: new UntypedFormControl(''),
            datasetId: new UntypedFormControl(datasetId, {
                initialValueIsDefault: true,
            }),
            crossReference: new UntypedFormControl(crossReference, {
                initialValueIsDefault: true,
            }),
            dataTypeName: new UntypedFormControl(
                'DocumentTradeTaxPaymentFromPrePayment',
                { initialValueIsDefault: true }
            ),
        });

        // communePayload.patchValue({
        //   'document_tradeTax_amountToPaidDate': toPaidDate
        // })

        console.log('Adding FromPrePaymentPayload: ' + datasetId);
        return communePayload;
    }

    quarterArrayNeeded = [true, true, true, true];

    checkIfQuarterNeeded(currentYear: number, payloadArray: UntypedFormArray): void {
        this.quarterArrayNeeded = [true, true, true, true];
        if (payloadArray) {
            for (let i = 0; i < payloadArray.length; i++) {
                const valueToCompare = (<FormGroup>payloadArray.at(i)).controls[
                    'document_tradeTax_amountToPaidDate'
                ]?.value;
                if (
                    valueToCompare &&
                    valueToCompare.getFullYear() == currentYear
                ) {
                    if (
                        valueToCompare.getMonth() == 0 ||
                        valueToCompare.getMonth() == 1 ||
                        valueToCompare.getMonth() == 2
                    ) {
                        this.quarterArrayNeeded[0] = false;
                    }
                    if (
                        valueToCompare.getMonth() == 3 ||
                        valueToCompare.getMonth() == 4 ||
                        valueToCompare.getMonth() == 5
                    ) {
                        this.quarterArrayNeeded[1] = false;
                    }
                    if (
                        valueToCompare.getMonth() == 6 ||
                        valueToCompare.getMonth() == 7 ||
                        valueToCompare.getMonth() == 8
                    ) {
                        this.quarterArrayNeeded[2] = false;
                    }
                    if (
                        valueToCompare.getMonth() == 9 ||
                        valueToCompare.getMonth() == 10 ||
                        valueToCompare.getMonth() == 11
                    ) {
                        this.quarterArrayNeeded[3] = false;
                    }
                }
            }
        }
    }


    //Adds a Payload object to the UntypedFormArray based on the dataType of the payload (used with add button, we dont have the datasetId yet)
    // not used obsolete?
    addPayload(dataType: string, crossReference?: string): void {
        const payloadArray = this.documentPayloadGetter();

        switch (dataType) {
            case 'DocumentTradeTaxItem': {
                this.addPayloadAsync(dataType, this.workflowId).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        if (newDatasetID != null) {
                            payloadArray.push(
                                this.createTradeTaxItemPayload(newDatasetID)
                            );
                            // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                            this.documentPayloadDatasetIdArray.push(
                                newDatasetID
                            );
                            // this.documentTradeTaxItemPayloadLength = this.documentTradeTaxItemPayloadLength + 709;
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            case 'DocumentTradeTaxPaymentFromInterest': {
                this.addPayloadAsync(
                    dataType,
                    this.workflowId,
                    crossReference
                ).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        if (newDatasetID != null) {
                            const newPayloadArray =
                                this.documentNestedPayloadGetter(
                                    payloadArray,
                                    crossReference
                                );
                            if (newPayloadArray) {
                                newPayloadArray.push(
                                    this.createTradeTaxPaymentFromInterestPayload(
                                        newDatasetID,
                                        crossReference
                                    )
                                );
                                // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                                this.documentPayloadDatasetIdArray.push(
                                    newDatasetID
                                );
                                // this.documentTradeTaxPaymentFromInterestPayloadLength = this.documentTradeTaxPaymentFromInterestPayloadLength + 200;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            case 'DocumentTradeTaxInterestRun': {
                this.addPayloadAsync(
                    dataType,
                    this.workflowId,
                    crossReference
                ).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        if (newDatasetID != null) {
                            const newPayloadArray =
                                this.documentNestedPayloadGetter(
                                    payloadArray,
                                    crossReference
                                );
                            if (newPayloadArray) {
                                newPayloadArray.push(
                                    this.createTradeTaxInterestRunPayload(
                                        newDatasetID,
                                        crossReference
                                    )
                                );
                                // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                                this.documentPayloadDatasetIdArray.push(
                                    newDatasetID
                                );
                                // this.documentTradeTaxInterestRunPayloadLength = this.documentTradeTaxInterestRunPayloadLength + 655;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            case 'DocumentTradeTaxPaymentFromPrePayment': {
                this.addPayloadAsync(
                    dataType,
                    this.workflowId,
                    crossReference
                ).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        console.log('crossReference onseparate add');
                        console.log(crossReference);
                        if (newDatasetID != null) {
                            const newPayloadArray =
                                this.documentNestedPayloadGetter(
                                    payloadArray,
                                    crossReference
                                );
                            if (newPayloadArray) {
                                newPayloadArray.push(
                                    this.createTradeTaxPaymentFromPrePaymentPayload(
                                        newDatasetID,
                                        crossReference
                                    )
                                );
                                // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                                this.documentPayloadDatasetIdArray.push(
                                    newDatasetID
                                );
                                // this.documentTradeTaxPaymentFromPrePaymentPayloadLength = this.documentTradeTaxPaymentFromPrePaymentPayloadLength + 210;
                            }
                        }
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            case 'DocumentTradeTaxPrePayment': {
                this.addPayloadAsync(dataType, this.workflowId).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        let newCrossRef;
                        this.getDataSetByIdAsync(newDatasetID).subscribe({
                            next: (dataset) => {
                                const response = dataset as DatasetItem;
                                newCrossRef = response.crossReference;
                                if (newDatasetID != null) {
                                    // this.quarterlyPrepaymentRowHeight = 356;
                                    payloadArray.push(
                                        this.createTradeTaxPrePaymentPayload(
                                            newDatasetID,
                                            newCrossRef
                                        )
                                    );
                                    // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                                    this.documentPayloadDatasetIdArray.push(
                                        newDatasetID
                                    );
                                    // this.documentTradeTaxPrePaymentPayloadLength = this.documentTradeTaxPrePaymentPayloadLength + 635;
                                }
                            },
                            error: (err: HttpErrorResponse) => {
                                // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                                console.error(err);
                            },
                        });
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            case 'DocumentTradeTaxInterest': {
                this.addPayloadAsync(
                    dataType,
                    this.workflowId,
                    crossReference
                ).subscribe({
                    next: (data) => {
                        const newPayload = data as FormFieldItem[];
                        const newDatasetID = newPayload[0].datasetId;
                        let newCrossRef;
                        this.getDataSetByIdAsync(newDatasetID).subscribe({
                            next: (dataset) => {
                                const response = dataset as DatasetItem;
                                newCrossRef = response.crossReference;
                                if (newDatasetID != null) {
                                    payloadArray.push(
                                        this.createTradeTaxInterestPayload(
                                            newDatasetID,
                                            newCrossRef
                                        )
                                    );
                                    // this.notificationService.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
                                    this.documentPayloadDatasetIdArray.push(
                                        newDatasetID
                                    );
                                    // this.documentTradeTaxInterestPayloadLength = this.documentTradeTaxInterestPayloadLength + 720;
                                }
                            },
                            error: (err: HttpErrorResponse) => {
                                // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                                console.error(err);
                            },
                        });
                    },
                    error: (err: HttpErrorResponse) => {
                        // this.notificationService.showError('Fehler bei Hinzufügen: ' + err.message)
                        console.error(err);
                    },
                });
                break;
            }
            default: {
                console.error(
                    'Unbekannt payload type for add payload: ' + dataType
                );
                break;
            }
        }
    }



    @ViewChildren('nestedPayloadDiv') nestedPayloadDivs!: QueryList<ElementRef>;

    ngAfterContentInit(): void {
        //console.log('ngAfterContentInit:', this.nestedPayloadDivs);
    }

    ngAfterViewInit(): void {
        /*    console.log('ngAfterViewInit:', this.nestedPayloadDivs);
       setTimeout(() => {
         this.sortDomElementsByDate();
       }, 0); */
    }

    ngAfterViewChecked(): void {
        //console.log('ngAfterViewChecked:', this.nestedPayloadDivs);
    }

    //not used-for now
    sortDomElementsByDate(): void {
        // Convert QueryList to array for easier manipulation
        const elementsArray = this.nestedPayloadDivs.toArray();
        if (this.nestedPayloadDivs.length === 0) {
            console.warn(
                'No elements found with the reference variable #nestedPayloadDiv'
            );
            return;
        }

        console.log('Captured elements:', elementsArray);

        // Sort elements based on the date value
        elementsArray.sort((a: ElementRef, b: ElementRef) => {
            const dateA = new Date(
                a.nativeElement.querySelector(
                    '[formControlName="document_tradeTax_amountToPaidDate"]'
                ).value
            );
            const dateB = new Date(
                b.nativeElement.querySelector(
                    '[formControlName="document_tradeTax_amountToPaidDate"]'
                ).value
            );
            return dateA.getTime() - dateB.getTime();
        });

        // Append sorted elements back to the parent container
        const parent = elementsArray[0].nativeElement.parentElement;
        elementsArray.forEach((el) => parent.appendChild(el.nativeElement));
    }
}
