<form [formGroup]="corporateIncomeTaxLossCarryForwardForm" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-dropdown-notice-name [documentType]="datatypeName" [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationName"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxLossCarryForwardForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-dropdown-notice-name>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_companyIdentifier" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>

                <div class="column">
                    <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="reference_companyIdentifier" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType" overrideLabel="Referenz: Steuernummer">
                    </app-stare-textbox>
                </div>
            </div>
            <div class="row">
                <!-- <div [class]='(workflowType==11 && !(actualStateId==9000)) ? "column-for-with-plus" : "column"'> -->
                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxLossCarryForwardForm"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>

                <!-- <div class="column-for-plus" *ngIf="workflowType==11 && !(actualStateId==9000)">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div> -->
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_notificationDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_cutOffDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-dropdown [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxLossCarryForwardForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxLossCarryForward_remaining_previousYearAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxLossCarryForwardForm"
                        [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxLossCarryForward_amount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxLossCarryForward_amount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxLossCarryForward_remaining_p31Amount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxLossCarryForwardForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxLossCarryForward_remaining_p31Amount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxLossCarryForwardForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Weitere Felder
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 660px;
                                        margin-top: -26px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Weitere Felder
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_name" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_value" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_referenceValue" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" [disabled]="
                                        (actualStateId == 9000 &&
                                            !requestedByAdmin()) ||
                                        (actualStateId == 9000 &&
                                            requestedByAdmin() &&
                                            !isUnlocked())
                                    " (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentDynamicFields'
                                        )
                                    ">
                                    Feld löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" [disabled]="
                            (actualStateId == 9000 && !requestedByAdmin()) ||
                            (actualStateId == 9000 &&
                                requestedByAdmin() &&
                                !isUnlocked())
                        " (click)="addPayload('DocumentDynamicFields')">
                        Feld hinzufügen
                    </button>
                </div>
            </div>

            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="corporateIncomeTaxLossCarryForwardForm" [auditResults]="auditResults" [workflowId]="workflowId"
                [is2000]="actualStateId == 2000" [isArchiv]="actualStateId == 9000"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- <form [formGroup]="corporateIncomeTaxLossCarryForwardForm" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
						{ height: 20 },
						{ height: 112 },
						{ height: 112 },
                        { height: 90 },
						{ height: 20 },
						{ height: 112 },
						{ height: 112 },
						{ height: 120 },
						{ height: 20 },
		                { height: 85 },
		                { height: withoutReferenceValidationExplanationRowHeight},
                        { height: sdcFieldsRowHeight },
                        { height: sdcFieldsRowHeight+20},
                        { height: sdcFieldsRowHeight/2 - 20},
						{ height: 112 }]" [cols]="[{ width: 400 }, { width: 400 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'reference_companyIdentifier-'+headerDatasetId"
                            id="refCompanyIdentifierLabel">Referenz: Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'reference_companyIdentifier-'+headerDatasetId" type="text" [readonly]="true"
                            [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'reference_companyIdentifier')"
                            class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'reference_companyIdentifier')"
                            placeholder="Referenz Steuernummer" formControlName="reference_companyIdentifier"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'reference_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'document_cutOffDate-'+headerDatasetId"
                            id="cutoffDateLabel">{{getXbrlLabel('document_cutOffDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['document_cutOffDate'].value" format="dd.MM.yyyy"
                            [id]="'document_cutOffDate-'+headerDatasetId" placeholder="Bitte auswählen..."
                            formControlName="document_cutOffDate" autocomplete="off" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_cutOffDate')">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_cutOffDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')}}"
                            formControlName="document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxLossCarryForward_remaining_previousYearAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxLossCarryForward_remaining_previousYearAmount-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2" [tabindex]="-1"
                            [id]="'corporateIncomeTaxLossCarryForward_remaining_previousYearAmount-'+headerDatasetId"
                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxLossCarryForward_remaining_previousYearAmount')}}"
                            formControlName="corporateIncomeTaxLossCarryForward_remaining_previousYearAmount"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxLossCarryForward_remaining_previousYearAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_corporateIncomeTaxLossCarryForward_amount-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_amount')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxLossCarryForward_amount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxLossCarryForward_amount')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_amount')}}"
                            formControlName="document_corporateIncomeTaxLossCarryForward_amount" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxLossCarryForward_amount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxLossCarryForward_amount-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxLossCarryForward_amount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2" [tabindex]="-1"
                            [id]="'corporateIncomeTaxLossCarryForward_amount-'+headerDatasetId" [readonly]="true"
                            class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxLossCarryForward_amount')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxLossCarryForward_amount')}}"
                            formControlName="corporateIncomeTaxLossCarryForward_amount" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxLossCarryForward_amount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxLossCarryForward_remaining_p31Amount-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_remaining_p31Amount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxLossCarryForward_remaining_p31Amount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxLossCarryForward_remaining_p31Amount')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxLossCarryForward_remaining_p31Amount')}}"
                            formControlName="document_corporateIncomeTaxLossCarryForward_remaining_p31Amount"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxLossCarryForward_remaining_p31Amount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxLossCarryForward_remaining_p31Amount-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxLossCarryForward_remaining_p31Amount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2" [tabindex]="-1"
                            [id]="'corporateIncomeTaxLossCarryForward_remaining_p31Amount-'+headerDatasetId"
                            [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxLossCarryForward_remaining_p31Amount')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxLossCarryForward_remaining_p31Amount')}}"
                            formControlName="corporateIncomeTaxLossCarryForward_remaining_p31Amount" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxLossCarryForward_remaining_p31Amount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="2"
                    *ngIf="corporateIncomeTaxLossCarryForwardForm.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1" *ngIf="workflowType==11">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions">
                        Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_auditResult-'+headerDatasetId"
                            id="auditResultLabel">{{getXbrlLabel('document_auditResult')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_auditResult-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_auditResult')"
                            [defaultItem]="defaultItem" formControlName="document_auditResult" [data]="auditResults">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_auditResult'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->