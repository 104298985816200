<form [formGroup]="deadlineSetForm" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]='headerDatasetId' [form]="deadlineSetForm" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_deadlineManagement_deadlineDate" [datasetId]='headerDatasetId'
                        [form]="deadlineSetForm" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_deadlineManagement_number" [datasetId]='headerDatasetId'
                        [form]="deadlineSetForm" [isRunningNumber]="true" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-numerical>
                </div>
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_deadlineManagement_withoutRegistration" [datasetId]='headerDatasetId'
                        [form]="deadlineSetForm" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]='headerDatasetId' [form]="deadlineSetForm" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
        </div>

    </fieldset>
    <br>
</form>
<!-- 
<form [formGroup]="deadlineSetForm" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
            { height: 20 },							
        { height: 90 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 150 }]" [cols]="[{ width: 320}, { width: 320 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            [value]="formGroup.controls['id_notificationDate'].value"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_deadlineManagement_deadlineDate-'+headerDatasetId"
                            id="deadlinelbl">{{getXbrlLabel('document_deadlineManagement_deadlineDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                            [id]="'document_deadlineManagement_deadlineDate-'+headerDatasetId"
                            [value]="formGroup.controls['document_deadlineManagement_deadlineDate'].value"
                            placeholder="Bitte auswählen..." formControlName="document_deadlineManagement_deadlineDate"
                            autocomplete="off" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_deadlineManagement_deadlineDate')">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_deadlineManagement_deadlineDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_deadlineManagement_number-'+headerDatasetId"
                            id="nummerLbl">{{getXbrlLabel('document_deadlineManagement_number')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="0" format="##################"
                            [min]="0" [spinners]="false" [id]="'document_deadlineManagement_number-'+headerDatasetId"
                            [changeValueOnScroll]="false"
                            placeholder="{{getXbrlLabel('document_deadlineManagement_number')}}"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_deadlineManagement_number')"
                            formControlName="document_deadlineManagement_number" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_deadlineManagement_number'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_deadlineManagement_withoutRegistration-'+headerDatasetId"
                            id="withoutRegLbl">
                            {{getXbrlLabel('document_deadlineManagement_withoutRegistration')}}
                        </kendo-label>
                        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_deadlineManagement_withoutRegistration')"
                            style="margin-left: 5px ;margin-top: 8px ;"
                            formControlName="document_deadlineManagement_withoutRegistration"
                            [id]="'document_deadlineManagement_withoutRegistration-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_deadlineManagement_withoutRegistration'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->