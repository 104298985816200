<form [formGroup]="chamberOfCommerceAndIndustryForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        overrideLabel="Begründung Rechtsbehelf für Mandant (extern):">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>

                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_cciIdentifier"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="chamberOfCommerceAndIndustryList">
                    </app-stare-dropdown-string>
                </div>
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_chamberOfCommerceAndIndustry_notificationNumber"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>
            </div>
            <div class="row">
                <div [class]="
                        workflowType === 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities"
                        overrideLabel="Zurechnung lt. Bescheid">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Beitragsberechnung
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentChamberOfCommerceAndIndustryCalculationOfContribution'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 613px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Beitragsberechnung
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_assessmentYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_sumYearOfContribution"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="chamberOfCommerceAndIndustry_sumYearOfContribution" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_sumAlreadyPaid" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="chamberOfCommerceAndIndustry_sumAlreadyPaid" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_basicAmount" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_apportionment" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_openClaim" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="chamberOfCommerceAndIndustry_openClaim" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentChamberOfCommerceAndIndustryCalculationOfContribution'
                                        )
                                    ">
                                    Löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" (click)="
                            addPayload(
                                'DocumentChamberOfCommerceAndIndustryCalculationOfContribution'
                            )
                        ">
                        Neuen hinzufügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Stand des Beitragskontos
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i" *ngIf="
                        payload.get('dataTypeName')?.value ==
                        'DocumentChamberOfCommerceAndIndustryDueDatePayment'
                    ">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 570px;
                                        margin-top: -25px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Stand des Beitragskontos
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-datepicker (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_amountToPaidDate" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-datepicker>
                            </div>
                            <div class="column">
                                <app-stare-integer (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_assessmentYear" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-integer>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_chamberOfCommerceAndIndustry_amountToPaid" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="chamberOfCommerceAndIndustry_amountToPaid" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentChamberOfCommerceAndIndustryDueDatePayment'
                                        )
                                    ">
                                    Löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" (click)="
                            addPayload(
                                'DocumentChamberOfCommerceAndIndustryDueDatePayment'
                            )
                        ">
                        Neuen Stand hinzufügen
                    </button>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>-->
            <!-- </div>
            <div class="row" *ngIf="rateableValueForm.controls['document_withoutReferenceValidation'].value==true"> --><!-- 
                <div class="column-for-text-area"
                    *ngIf="chamberOfCommerceAndIndustryForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="chamberOfCommerceAndIndustryForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="chamberOfCommerceAndIndustryForm1300" [auditResults]="auditResults" [workflowId]="workflowId"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 
<form [formGroup]="chamberOfCommerceAndIndustryForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 120 },
        { height: 130 },
        { height: 50 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 20 },
						{ height: 18 },
						{ height: documentIHKContributionPayloadLength},
						{ height: 50 },
						{ height: 18 },
						{ height: documentIHKDueDatePayloadLength},
						{ height: 50 },
						{ height: 20 },
		                { height: 90 },
		                { height: withoutReferenceValidationExplanationRowHeight}]"
                [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">

                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">{{getXbrlLabel('id_companyIdentifier')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text" placeholder="{{getXbrlLabel('id_companyIdentifier')}}"
                            formControlName="id_companyIdentifier" autocomplete="off"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label
                            [for]="'id_cciIdentifier-'+headerDatasetId">{{getXbrlLabel('id_cciIdentifier')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_cciIdentifier-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_cciIdentifier"
                            (filterChange)="handleChamperOfCommerceFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_cciIdentifier')"
                            [data]="chamberOfCommerceAndIndustryList"></kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_cciIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_chamberOfCommerceAndIndustry_notificationNumber-'+headerDatasetId"
                            id="companyIdentifierLabel">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_notificationNumber')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_chamberOfCommerceAndIndustry_notificationNumber-'+headerDatasetId"
                            type="text"
                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_notificationNumber')}}"
                            formControlName="document_chamberOfCommerceAndIndustry_notificationNumber"
                            autocomplete="off"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_chamberOfCommerceAndIndustry_notificationNumber')">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_notificationNumber'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="2">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId" id="taxDeterminationLabel">Zurechnung
                            lt.
                            Bescheid</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="2">

                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">

                    <legend class="k-form-legend payload-parent-header">
                        Beitragsberechnung </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentChamberOfCommerceAndIndustryCalculationOfContribution'"
                            [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 }, 
												{ height: 110 },  
												{ height: 50 }]" [cols]="[{ width: 320 }, { width: 370 }]" class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label [for]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                        <kendo-numerictextbox [spinners]="false" [decimals]="0" [maxlength]="4"
                                            [changeValueOnScroll]="false" format="##"
                                            [id]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            (focus)="onFocusInputXbrl()"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_assessmentYear')"
                                            placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                            formControlName="id_assessmentYear" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_sumYearOfContribution-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_sumYearOfContribution')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false"
                                            [id]="'document_chamberOfCommerceAndIndustry_sumYearOfContribution-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_sumYearOfContribution')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_sumYearOfContribution')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_sumYearOfContribution"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_sumYearOfContribution'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'chamberOfCommerceAndIndustry_sumYearOfContribution-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('chamberOfCommerceAndIndustry_sumYearOfContribution')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false"
                                            [id]="'chamberOfCommerceAndIndustry_sumYearOfContribution-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'chamberOfCommerceAndIndustry_sumYearOfContribution')"
                                            placeholder="{{getXbrlLabel('chamberOfCommerceAndIndustry_sumYearOfContribution')}}"
                                            formControlName="chamberOfCommerceAndIndustry_sumYearOfContribution"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'chamberOfCommerceAndIndustry_sumYearOfContribution'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always"><kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_sumAlreadyPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_sumAlreadyPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_sumAlreadyPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_sumAlreadyPaid')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_sumAlreadyPaid')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_sumAlreadyPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_sumAlreadyPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'chamberOfCommerceAndIndustry_sumAlreadyPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('chamberOfCommerceAndIndustry_sumAlreadyPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'chamberOfCommerceAndIndustry_sumAlreadyPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'chamberOfCommerceAndIndustry_sumAlreadyPaid')"
                                            placeholder="{{getXbrlLabel('chamberOfCommerceAndIndustry_sumAlreadyPaid')}}"
                                            formControlName="chamberOfCommerceAndIndustry_sumAlreadyPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'chamberOfCommerceAndIndustry_sumAlreadyPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_basicAmount-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_basicAmount')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_basicAmount-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_basicAmount')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_basicAmount')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_basicAmount"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_basicAmount'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_apportionment-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_apportionment')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_apportionment-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_apportionment')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_apportionment')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_apportionment"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_apportionment'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_openClaim-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_openClaim')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_openClaim-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_openClaim')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_openClaim')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_openClaim"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_openClaim'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'chamberOfCommerceAndIndustry_openClaim-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('chamberOfCommerceAndIndustry_openClaim')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [decimals]="2" [tabindex]="-1"
                                            [id]="'chamberOfCommerceAndIndustry_openClaim-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'chamberOfCommerceAndIndustry_openClaim')"
                                            placeholder="{{getXbrlLabel('chamberOfCommerceAndIndustry_openClaim')}}"
                                            formControlName="chamberOfCommerceAndIndustry_openClaim" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'chamberOfCommerceAndIndustry_openClaim'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <kendo-formfield showErrors="always" showHints="always" class="part">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome-'+payload.controls['datasetId'].value"
                                            id="yearLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                                            [decimals]="0" [maxlength]="4" [changeValueOnScroll]="false" format="##"
                                            [id]="'document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentChamberOfCommerceAndIndustryCalculationOfContribution")'>Löschen
                                    </button>
                                </kendo-gridlayout-item>
                            </kendo-gridlayout>
                        </div>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentChamberOfCommerceAndIndustryCalculationOfContribution")'>Neuen
                            hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend payload-parent-header">
                        Stand des Beitragskontos </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div *ngIf="payload.get('dataTypeName')?.value == 'DocumentChamberOfCommerceAndIndustryDueDatePayment'"
                            [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
												{ height: 110 }, 
												{ height: 110 },    
												{ height: 50 }]" [cols]="[{ width: 320 }, { width: 370 }]" class="payload">
                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_amountToPaidDate-'+payload.controls['datasetId'].value"
                                            id="dateLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_amountToPaidDate')}}</kendo-label>
                                        <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                                            [enableMouseWheel]="false"
                                            [value]="payload.controls['document_chamberOfCommerceAndIndustry_amountToPaidDate'].value"
                                            [id]="'document_chamberOfCommerceAndIndustry_amountToPaidDate-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_amountToPaidDate')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_amountToPaidDate')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_amountToPaidDate"
                                            autocomplete="off">
                                            <kendo-datepicker-messages today="Heute"
                                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                        </kendo-datepicker>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_amountToPaidDate'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label [for]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                                        <kendo-numerictextbox [spinners]="false" [decimals]="0" [maxlength]="4"
                                            [changeValueOnScroll]="false" format="##"
                                            [id]="'id_assessmentYear-'+payload.controls['datasetId'].value"
                                            (focus)="onFocusInputXbrl()"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_assessmentYear')"
                                            placeholder="{{getXbrlLabel('id_assessmentYear')}}"
                                            formControlName="id_assessmentYear" autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'document_chamberOfCommerceAndIndustry_amountToPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('document_chamberOfCommerceAndIndustry_amountToPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'document_chamberOfCommerceAndIndustry_amountToPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_chamberOfCommerceAndIndustry_amountToPaid')"
                                            placeholder="{{getXbrlLabel('document_chamberOfCommerceAndIndustry_amountToPaid')}}"
                                            formControlName="document_chamberOfCommerceAndIndustry_amountToPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_chamberOfCommerceAndIndustry_amountToPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'chamberOfCommerceAndIndustry_amountToPaid-'+payload.controls['datasetId'].value"
                                            id="yLbl">{{getXbrlLabel('chamberOfCommerceAndIndustry_amountToPaid')}}</kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [decimals]="2"
                                            [id]="'chamberOfCommerceAndIndustry_amountToPaid-'+payload.controls['datasetId'].value"
                                            [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [tabindex]="-1"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'chamberOfCommerceAndIndustry_amountToPaid')"
                                            placeholder="{{getXbrlLabel('chamberOfCommerceAndIndustry_amountToPaid')}}"
                                            formControlName="chamberOfCommerceAndIndustry_amountToPaid"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'chamberOfCommerceAndIndustry_amountToPaid'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentChamberOfCommerceAndIndustryDueDatePayment")'>Löschen
                                    </button>
                                </kendo-gridlayout-item>
                            </kendo-gridlayout>
                        </div>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentChamberOfCommerceAndIndustryDueDatePayment")'>Neuen
                            Contribution hinzufügen</button>
                    </div>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="2"
                    *ngIf="chamberOfCommerceAndIndustryForm1300.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->