<div class="stareTopic">
    {{ 'APP.SETTINGS.LEGAL_ITEMS.TITLE' | translate }}
</div>


<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="tableLegalEntitiesDataFiltered" scrollable="scrollable"
            [style.height]="'100%'" filterable="menu" [loading]="!finishedLoading"
            (dblclick)="editHandler(grid.activeRow)" (add)="addHandler()"
            [filter]="currentFilter || {logic: 'and', filters: []}"
            [selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings" [sortable]="true"
            [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [loading]="!finishedLoading" [sort]="currentSort" (sortChange)="sortChange($event)"
            (cellClick)="cellClickHandler($event)" [navigable]="true">

            <ng-template kendoGridToolbarTemplate>
                <kendo-textbox [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.SEARCH' | translate"
                    (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

                </button>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="{{ 'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.TITLE' | translate }}">
                </button>

                <button kendoButton (click)="loadLegalEntities()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>
                <!--
            <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

            </button>
-->
            </ng-template>

            <kendo-grid-messages [pagerPage]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.PAGER.PAGE' | translate"
                [pagerOf]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.PAGER.OF' | translate"
                [pagerItems]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.PAGER.ITEM' | translate"
                [pagerItemsPerPage]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.PAGER.TASK_PER_PAGE' | translate"
                [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
                [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
                [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
                [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>
            <kendo-grid-column field="name" [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate">

            </kendo-grid-column>
            <kendo-grid-column field="inScope" filter="boolean"
                [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.IN_SCOPE' | translate">

                <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.inScope ? "ja" : "nein" }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="wbsForDisplay" [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.WBS' | translate">
            </kendo-grid-column>
            <!--
        <kendo-grid-column field="clientContact"
            [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.CLIENT_CONTACT' | translate">
        </kendo-grid-column>
        <kendo-grid-column field="emailTemplate"
            [title]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.MAIL_TEMPLATE' | translate">
        </kendo-grid-column>
    -->
            <ng-template kendoGridNoRecordsTemplate>

                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>

            <!--
        <kendo-grid-excel fileName="LegalEntities.xlsx"></kendo-grid-excel>
-->
        </kendo-grid>
    </div>
</div>



<!--Modal Window-->
<kendo-dialog *ngIf="active" [width]="600" [height]="500" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>
    <div class="grid-layout-container">
        <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
            <kendo-gridlayout [gap]="{ rows: 6, cols: 15 }" [cols]="[{ width: 250 }, { width: 250 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                    <kendo-formfield>
                        <kendo-label [for]="name"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate"></kendo-label>
                        <kendo-textbox #name formControlName="name"
                            [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.NAME' | translate"
                            required></kendo-textbox>
                        <kendo-formerror>{{
                            'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.NAME.ERROR.REQUIRED' |
                            translate }}</kendo-formerror>
                    </kendo-formfield>

                    <kendo-formfield>
                        <kendo-label [for]="userCode"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.USERCODE' | translate"></kendo-label>
                        <kendo-textbox #userCode formControlName="userCode"
                            [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.USERCODE' | translate"></kendo-textbox>

                    </kendo-formfield>

                    <!--
                    <kendo-formfield>
                        <kendo-label [for]="clientContact"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.CLIENT_CONTACT' | translate"></kendo-label>
                        <kendo-textbox #clientContact formControlName="clientContact"
                            [placeholder]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.CLIENT_CONTACT' | translate"></kendo-textbox>

                    </kendo-formfield>
                    -->



                    <kendo-formfield>
                        <ng-container>
                            <div kendoTooltip position="right">
                                <span class="k-checkbox-wrap">
                                    <input id="inScope" type="checkbox" kendoCheckBox formControlName="inScope" />
                                </span>
                                <label [labelClass]="false" class="k-checkbox-label" for="inScope"
                                    [title]="'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.IN_SCOPE.TOOLTIP' | translate">
                                    {{
                                    'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.IN_SCOPE.LABEL' |
                                    translate }}</label>
                            </div>
                        </ng-container>
                    </kendo-formfield>

                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                    <kendo-formfield>
                        <kendo-label [for]="wbs"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.WBS' | translate"></kendo-label>
                        <kendo-multiselect #wbs formControlName="wbs" [itemDisabled]="itemDisabled" [data]="listWBS"
                            [checkboxes]="false" textField="labelDE" valueField="value" [autoClose]="false"
                            [(ngModel)]="listOfSelectedWBS">
                        </kendo-multiselect>

                    </kendo-formfield>

                    <!--
                    <kendo-formfield>
                        <kendo-label [for]="mailTemplate"
                            [text]="'APP.SETTINGS.LEGAL_ITEMS.TABLE.HEADER.MAIL_TEMPLATE' | translate"></kendo-label>
                        <kendo-dropdownlist #mailTemplate formControlName="mailTemplate" [data]="listMailTemplates"
                            [defaultItem]="{ text: 'Mail Template', value: null }" textField="mail" valueField="id"
                            [valuePrimitive]="true"></kendo-dropdownlist>
                        <kendo-formerror>{{
                            'APP.SETTINGS.LEGAL_ITEMS.MODAL.ADD_LEGAL_ITEM.MAIL_TEMPLATE.ERROR.REQUIRED' |
                            translate }}</kendo-formerror>
                    </kendo-formfield>
                -->
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </form>

    </div>
    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
            {{ 'BUTTON.DELETE' | translate }}
        </button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>