import { GridSettings } from "../_models/kendo-grid-settings.model";
import { Injectable } from "@angular/core";

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: object | null) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

@Injectable()
export class StatePersistingService {
    // want to delete all settings prior to this date (if update on 30.07.2024 set the lastUpdateDate to 29.07.2024) 
    // update here if changes on the settings are implemented
    public lastUpdateDateForSettings = new Date("2024-10-21T14:00:00Z");

    public get<T>(token: string): T {
        const settings = localStorage.getItem(token);
        return settings ? JSON.parse(settings) : settings;
    }

    public set<T>(token: string, gridConfig: GridSettings): void {
        localStorage.setItem(
            token,
            JSON.stringify(gridConfig, getCircularReplacer())
        );
    }

    public updateAll<T>(): void {
        this.updateSetting('tasksGridSettings');
        this.updateSetting('tasksAllGridSettings');
        this.updateSetting('archiveGridSettings');
        this.updateSetting('adminArchivGridSettings');

    }

    public updateSetting<T>(token: string): void {
        let currentSetting: GridSettings = this.get(token);
        if (currentSetting) {
            currentSetting.creationDate = new Date();
            currentSetting.state.skip = 0;
            this.set(token, currentSetting);
        } else {
            console.log("No Setting found ");
        }
    }

    public checkAndUpdateIfAvailable<T>(token: string, gridConfig: GridSettings): void {

        let currentSettings: GridSettings = this.get(token);
        if (currentSettings) {
            let testDate = new Date(currentSettings.creationDate);
            if (testDate < this.lastUpdateDateForSettings) {

                this.set(token, gridConfig);
            } else {
                //do nothing
            }
        } else {
            this.set(token, gridConfig);
        }

    }

    public clearAll<T>(): void {

        localStorage.removeItem('tasksGridSettings');
        localStorage.removeItem('tasksAllGridSettings');
        localStorage.removeItem('archiveGridSettings');

        localStorage.removeItem('adminArchivGridSettings');

    }

    public clearAllFilters<T>(): void {

        this.clearFilters('tasksGridSettings');
        this.clearFilters('tasksAllGridSettings');
        this.clearFilters('archiveGridSettings');

        this.clearFilters('adminArchivGridSettings');

    }

    public clearFilters<T>(token: string): void {

        let currentSetting: GridSettings = this.get(token);
        if (currentSetting) {
            currentSetting.creationDate = new Date();
            currentSetting.state.skip = 0;
            currentSetting.state.filter = {
                logic: 'and',
                filters: []
            };
            this.set(token, currentSetting);
        } else {
            console.log("No Setting found ");
        }

    }


}