import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-property-tax-base1400',
  templateUrl: './doc-property-tax-base1400.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocPropertyTaxBase1400Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.propertyTaxBaseAssessmentForm1400;
  }





  //#region Form ======================================================================================================


  /** Grundsteuermessbetrag, Rechtsbehelf, State 1400 und 1500*/
  public propertyTaxBaseAssessmentForm1400 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_legalRemedyResult: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_legalRemedyObjection: new UntypedFormControl(""),
  });
  //#endregion



  addSingularPayload(): void {
    //no payload 
  }



}
