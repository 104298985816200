import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobStatisticItem } from 'app/_models/job-statistic-item.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiJobsService {

  constructor(
    private http: HttpClient) { }


  /**
* Get all Log items.
* @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
*/
  getAllJobs(items: number): Observable<JobStatisticItem[]> {
    return this.http.get<JobStatisticItem[]>(ENVIRONMENT.apiBaseUrl + 'Job/GetAllAsync?count=' + items, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }


  /**
   * GetAllAsync?count=5000&messageFilter=test&jobIdentifier=a0c86c3c-b9e8-4c84-8768-de90da753146&jobCode=4'
   * GetAllAsync?count=5000&jobIdentifier=a0c86c3c-b9e8-4c84-8768-de90da753146&jobCode=4'
* Get all Log items.
* @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
*/
  getAllJobsWithFilter(items: number, code: number | null, id: string | null): Observable<JobStatisticItem[]> {
    if (code == null && id == null) {
      return this.getAllJobs(items);
    } else if (code != null && id == null) {
      return this.http.get<JobStatisticItem[]>(ENVIRONMENT.apiBaseUrl + 'Job/GetAllAsync?count=' + items + '&jobCode=' + code.toString(), HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    } else if (code == null && id != null) {
      return this.http.get<JobStatisticItem[]>(ENVIRONMENT.apiBaseUrl + 'Job/GetAllAsync?count=' + items + '&jobIdentifier=' + id, HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    } else {
      return this.http.get<JobStatisticItem[]>(ENVIRONMENT.apiBaseUrl + 'Job/GetAllAsync?count=' + items + '&jobIdentifier=' + id + '&jobCode=' + code!.toString(), HTTP_OPTIONS)
        .pipe(
          retry(1)
        )
    }
  }
}
