
import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DataService } from 'app/_services/StareApi/data.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { ListsService } from 'app/_services/lists.service';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-tax-ebitda1400',
  templateUrl: './doc-corporate-income-tax-ebitda1400.component.html',
  styleUrl: '../doc-base/doc-base.component.scss',
  standalone: false
})
export class DocCorporateTaxEbitda1400Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateTaxEbitdaForm1400;
  }




  //#region Form ======================================================================================================

  /** Körperschaftsteuer Ebitda, Rechtsbehelf, State 1400 und 1500*/
  public corporateTaxEbitdaForm1400 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    // id_assessmentYear: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_legalRemedyResult: new UntypedFormControl(""),
    document_legalRemedyObjection: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }



}
