import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { SortDescriptor } from "@progress/kendo-data-query";
import { WbsForCreationItem, WbsForUpdateItem, WbsItem } from '../_models/wbs.model';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CellClickEvent, ColumnMenuSettings, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { UserLocal } from 'app/_models/user.model';
import { ApiWbsService } from 'app/_services/StareApi/wbs.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-setting-wbs',
  templateUrl: './setting-wbs.component.html',
  styleUrls: ['./setting-wbs.component.scss'],
  standalone: false
})

export class SettingWbsComponent implements OnInit {

  public myuser: UserLocal = {} as UserLocal;
  /** Displayed (filtered) data */
  public tableData = [] as WbsItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as WbsItem[];
  /** API request is finished */
  public finishedLoading = false;


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  // =====================================================================

  constructor(private titleService: Title,
    public translateService: TranslateService,
    private apiWbsService: ApiWbsService,
    private notificationService: WrapperNotificationService,
    public tooltipService: TooltipService
  ) { }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.SETTINGS_WBS.TAB').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });

    const jsonStr = localStorage.getItem('user') || '';
    if (jsonStr.length > 0) {
      const jsonObj = JSON.parse(jsonStr);
      this.myuser = jsonObj as UserLocal;
    }

    this.loadData();
  }

  // == Resize the Grid ====================================================

  public pageHeight: number = window.innerHeight - 125;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  // == Load Grid Data ======================================================================

  loadData(): void {
    this.finishedLoading = false;

    this.apiWbsService.getAllWBS().subscribe({
      next: (res: WbsItem[]) => {
        this.tableData = res;
        this.tableDataOriginal = res;
        this.finishedLoading = true;
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });


  }
  // == Grid Settings =======================================================================================================================================


  // Pagination 

  public pageSize = 20;



  // Icon for excel export
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;



  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };

  // Sorting 
  public currentSort: SortDescriptor[] = [
    {
      field: "engagementManagerMail",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }


  // Excel Export 

  // public exportToExcel(grid: GridComponent): void {
  //   grid.saveAsExcel();
  // }

  // == Filter ======================================================================================================================================


  @ViewChild("grid", { static: false })
  grid!: GridComponent;


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {



      filter.filters.push({
        field: "id",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "wbs",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "engagementManagerMail",
        operator: "contains",
        value: value,
      });
      //}

    }

    this.currentFilter = filter;
  }


  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }


  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;

  // == Form ========================================================
  public editForm: FormGroup = new FormGroup({
    wbs: new FormControl("", Validators.required),
    sourceUrlPart: new FormControl(""),
    engagementManagerMail: new FormControl("", [Validators.required, Validators.pattern("^\\w+([-+.']\\w+)*@(pwc.de|pwc.com|pwcinternal.com)$")]),
  });

  // == Handlers ============================================================================
  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
  }

  public closeForm(): void {
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }


  // == CRUD Operations ===========================================================
  public onSave(e: PointerEvent): void {
    e.preventDefault();


    if (this.isNew) {

      // Map UserAccessForCreationItem from Form
      let itemForCreationItem = {} as WbsForCreationItem;

      itemForCreationItem = {
        wbs: this.editForm.value.wbs,
        sourceUrlPart: this.editForm.value.sourceUrlPart,
        engagementManagerMail: this.editForm.value.engagementManagerMail,
        job: 4, // Tax Assessment
      };

      //console.log(userAccessForCreationItem);

      // Send to API
      this.apiWbsService.addWBSAsync(itemForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Die WBS wurde erfolgreich angelegt und mit diesem Engagement verknüpft.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Daten konnten nicht verarbeitet werden. Bitte bspw. die E-Mail Adresse auf Richtigkeit prüfen.');
          } else if (err.status == 406) {
            this.notificationService.showError('Die WBS ist bereits mit einem Engagement verknüpft und kann nicht mehrfach vergeben werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }
    else {
      // Update
      let itemForUpdateItem = {} as WbsForUpdateItem;
      itemForUpdateItem = {
        wbs: this.editForm.value.wbs,
        sourceUrlPart: this.editForm.value.sourceUrlPart,
        engagementManagerMail: this.editForm.value.engagementManagerMail,
        job: 4, // Tax Assessment
      };

      //console.log(userForUpdateItem);

      this.apiWbsService.updateWBSAsync(this.thisId, itemForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Die WBS wurde erfolgreich aktualisiert.');
          this.loadData();
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Daten konnten nicht verarbeitet werden. Bitte bspw. die E-Mail Adresse auf Richtigkeit prüfen.');
          } else if (err.status == 406) {
            this.notificationService.showError('Die WBS ist bereits mit einem Engagement verknüpft und kann nicht mehrfach vergeben werden.');
          } else if (err.status == 409) {
            this.notificationService.showError('Die WBS ist nicht Teil dieses Engagements und kann deswegen nicht bearbeitet werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });


    }

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiWbsService.deleteWBSAsync(this.thisId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die WBS wurde erfolgreich gelöscht.');
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 409) {
          this.notificationService.showError('Der Anwender wurde nicht gefunden und konnte deswegen nicht gelöscht werden. Ggf. wurde er bereits zwischenzeitlich gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeForm();
  }


}
