<!-- <div class="a-panel" [hidden]="finishedLoading">
	<div class="a-p-20 text-center rounded">
		<span class="a-loading a-primary" style="width: 48px; height: 48px;"></span>
		<div class="a-mt-20 a-h6">{{ 'APP.ASSESSMENT_REVIEW.LOADING_TEXT' | translate }}</div>
	</div>
</div> -->

<!-- Css with right alignment -->
<!-- <kendo-contextmenu showOn="click">
	<kendo-menu-item text="" [svgIcon]="trashSVG"></kendo-menu-item> 
</kendo-contextmenu> -->

<!-- <div *ngIf="contextMenuVisible" [style.left.px]="contextMenuPosition.x" [style.top.px]="contextMenuPosition.y"
	class="context-menu">
	<ul>
		<li (click)="contextMenuAction('Action 1')">Neues Rechteckt</li>
		<li (click)="contextMenuAction('Action 2')">Löschen</li>
		<li (click)="contextMenuAction('Action 2')">Neue Zuordnung</li>
		<li (click)="contextMenuAction('Action 2')">Kendo Kontextmenü benutzen</li>
		<li>====</li>
		<li>Selected Text: {{ selectedText }}</li>
	</ul>
</div> -->

<div *ngIf="contextMenuVisible" [style.left.px]="contextMenuPosition.x" [style.top.px]="contextMenuPosition.y"
    class="context-menu">
    <ul>
        <li (click)="contextMenuAction()"><span>&times;</span></li>
        <li></li>
    </ul>
</div>

<div style="display: flex; justify-content: space-between">
    <div style="float: left">
        <!-- Some PDF functions -->
        <button kendoButton (click)="pdfZoomIn()" type="button" [svgIcon]="zoomInSVG" kendoTooltip
            title="{{ 'APP.ASSESSMENT_REVIEW.PDF_VIEWER.ZOOM_IN' | translate }}"></button>
        &nbsp;

        <button kendoButton (click)="pdfZoomOut()" type="button" [svgIcon]="zoomOutSVG" kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.ZOOM_OUT' | translate
            }}"></button>&nbsp;

        <button kendoButton (click)="pdfZoom100()" type="button" [svgIcon]="zoom100SVG" kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.STANDARD' | translate
            }}"></button>&nbsp;

        <button kendoButton (click)="pdfZoomBestFit()" [toggleable]="true" type="button" [svgIcon]="zoomBestFitSVG"
            kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.TO_PAGE_SIZE' | translate
            }}"></button>&nbsp;&nbsp;|&nbsp;&nbsp;
        <span class="plainText">{{ pageCountText }},
            <a *ngIf="fileSourceURL" href="{{ fileSourceURL }}" kendoTooltip title="Datei aus der Quelle (DMS) öffnen."
                target="_blank">{{ filename }}</a><span *ngIf="fileSourceURL.length == 0">{{ filename }}</span></span>
    </div>

    <div style="text-align: center">
        <span></span>
    </div>

    <div>
        <!--Buttons Validate | Abort, Save, Next stage -->

        <button kendoButton type="submit" (click)="onClickCancelButton()" [svgIcon]="backSVG" kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.BACK_TO_TASKS' | translate
            }}"></button>
        &nbsp;
        <button *ngIf="requestedByAdmin() && actualStateId == 9000" kendoButton type="submit"
            (click)="openWindowAdminEditConfirm()" [svgIcon]="selectedLockSVG" kendoTooltip title="{{
                'BUTTON.UNLOCK_ARCHIV' | translate
            }}"></button>
        &nbsp;&nbsp;|&nbsp;&nbsp;

        <button kendoButton type="submit" (click)="saveAndValidate()" #btnSave2 kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.SAVE_TOOLTIP' | translate
            }}">
            {{ "APP.ASSESSMENT_REVIEW.PDF_VIEWER.SAVE" | translate }}
        </button>
        &nbsp;
        <kendo-splitbutton class="plainText" (buttonClick)="onSplitButtonClick()" [data]="execWorkflowButtonData"
            #btnSplit textField="name" kendoTooltip title="{{
                'APP.ASSESSMENT_REVIEW.PDF_VIEWER.NEXT_STEP' | translate
            }}" [collision]="collision">
            {{ "BUTTON.CONTINUE" | translate }}
        </kendo-splitbutton>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <span class="k-icon k-font-icon k-i-information" style="font-size: 25px" kendoTooltip
            [title]="workflowJournalAsText" [tooltipTemplate]="templateJournal"></span>
        &nbsp;
        <button #anchorValidationMessages kendoButton type="submit" (click)="showValidationMessages()"
            [titleTemplate]="titleTemplateValidationMessages" [tooltipTemplate]="templateValidationMessages"
            kendoTooltip [tooltipWidth]="400" [themeColor]="validationMessagesBgColor"
            title="{{ validationMessagesToolTip }}">
            {{ validationMessagesCounts }}</button>&nbsp;
        <button #anchorReferenceMessages kendoButton type="submit" (click)="showReferenceMessages()"
            [titleTemplate]="titleTemplateReferenceMessages" [tooltipTemplate]="templateReferenceMessages" kendoTooltip
            [tooltipWidth]="400" [themeColor]="referenceMessagesBgColor" title="{{ referenceMessagesToolTip }}">
            {{ referenceMessagesCounts }}</button>&nbsp;
        <button #anchorPopupComment kendoButton type="submit" (click)="showComments()" [titleTemplate]="titleTemplate"
            [tooltipTemplate]="template" kendoTooltip [tooltipWidth]="400" themeColor="secondary"
            title="{{ validationMessagesToolTip }}">
            {{ commentCounts }}
        </button>
        &nbsp;
        <button *ngIf="isTradeTaxDocument" #anchorPopupTradeTax kendoButton type="submit" (click)="showTradeTax()"
            kendoTooltip [tooltipWidth]="400" themeColor="secondary" title="Gewerbesteuer Informationen">
            i
        </button>
    </div>
</div>

<ng-template #templateJournal let-anchor>
    <pre style="white-space: pre-wrap">{{ workflowJournalAsText }}</pre>
</ng-template>
<br />

<kendo-splitter>
    <kendo-splitter-pane id="pdf-layout-panel" size="50%" min="200px" (sizeChange)="pdfLayputSizeChange()"
        style="overflow-y: auto" [ngStyle]="{ 'overflow-y': 'auto', 'height.px': pageHeight }">
        <div style="width: 100%" #canvas id="pdf-wrapper"></div>
    </kendo-splitter-pane>

    <kendo-splitter-pane style="overflow-y: auto" [ngStyle]="{ 'overflow-y': 'auto', 'height.px': pageHeight }">
        <div class="k-overlay" *ngIf="isFormLoading"></div>
        <div class="grid-wrapper">
            <div [ngSwitch]="currentDocumentTypeAndStateId">
                <br />

                <div *ngSwitchCase="'DocumentRateableValue_1100'" class="k-doc-card">
                    <app-doc-rateable-value (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " [workflowTypeInput]="workflowType" (rectangleFieldList)="updateRectangleFields($event)"
                        [onButtonSaveClick]="eventSaved" [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-rateable-value>
                </div>
                <div *ngSwitchCase="'DocumentRateableValue_1300'" class="k-doc-card">
                    <app-doc-rateable-value1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-rateable-value1300>
                </div>
                <div *ngSwitchCase="'DocumentRateableValue_1400'" class="k-doc-card">
                    <app-doc-rateable-value1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-rateable-value1400>
                </div>

                <div *ngSwitchCase="'DocumentPropertyTaxBaseAssessment_1100'" class="k-doc-card">
                    <app-doc-property-tax-base (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " [workflowTypeInput]="workflowType" (rectangleFieldList)="updateRectangleFields($event)"
                        [onButtonSaveClick]="eventSaved" [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-property-tax-base>
                </div>
                <div *ngSwitchCase="'DocumentPropertyTaxBaseAssessment_1300'" class="k-doc-card">
                    <app-doc-property-tax-base1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-property-tax-base1300>
                </div>
                <div *ngSwitchCase="'DocumentPropertyTaxBaseAssessment_1400'" class="k-doc-card">
                    <app-doc-property-tax-base1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-property-tax-base1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCombiRateableValueAndPropertyTaxBaseAssessment_1100'
                    " class="k-doc-card">
                    <app-doc-combi-rateable-value-and-property-tax-base (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-combi-rateable-value-and-property-tax-base>
                </div>
                <div *ngSwitchCase="
                        'DocumentCombiRateableValueAndPropertyTaxBaseAssessment_1300'
                    " class="k-doc-card">
                    <app-doc-combi-rateable-value-and-property-tax-base1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-combi-rateable-value-and-property-tax-base1300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCombiRateableValueAndPropertyTaxBaseAssessment_1400'
                    " class="k-doc-card">
                    <app-doc-combi-rateable-value-and-property-tax-base1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-combi-rateable-value-and-property-tax-base1400>
                </div>

                <div *ngSwitchCase="'DocumentCorporateIncomeTax_1100'" class="k-doc-card">
                    <app-doc-corporate-income-tax (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax>
                </div>
                <div *ngSwitchCase="'DocumentCorporateIncomeTax_1300'" class="k-doc-card">
                    <app-doc-corporate-income-tax1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax1300>
                </div>
                <div *ngSwitchCase="'DocumentCorporateIncomeTax_1400'" class="k-doc-card">
                    <app-doc-corporate-income-tax1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAssessmentP27_1100'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-assessment-p27 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-assessment-p27>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAssessmentP27_1300'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-assessment-p271300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-assessment-p271300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAssessmentP27_1400'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-assessment-p271400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-assessment-p271400>
                </div>

                <div *ngSwitchCase="'DocumentCorporateIncomeTaxPrePayment_1100'" class="k-doc-card">
                    <app-doc-corporate-income-tax-pre-payment (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-pre-payment>
                </div>
                <div *ngSwitchCase="'DocumentCorporateIncomeTaxPrePayment_1300'" class="k-doc-card">
                    <app-doc-corporate-income-tax-pre-payment1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-pre-payment1300>
                </div>
                <div *ngSwitchCase="'DocumentCorporateIncomeTaxPrePayment_1400'" class="k-doc-card">
                    <app-doc-corporate-income-tax-pre-payment1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-pre-payment1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxLossCarryForward_1100'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-loss-carry-forward (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-loss-carry-forward>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxLossCarryForward_1300'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-loss-carry-forward1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-loss-carry-forward1300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxLossCarryForward_1400'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-loss-carry-forward1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-loss-carry-forward1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxAddCarryForward_1100'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-add-carry-forward (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-add-carry-forward>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxAddCarryForward_1300'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-add-carry-forward1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-add-carry-forward1300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxAddCarryForward_1400'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-add-carry-forward1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-add-carry-forward1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAndUniformDetermination_1100'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-and-uniform-determination
                        (buttonsDisable)="ButtonDisable($event)" (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-and-uniform-determination>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAndUniformDetermination_1300'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-and-uniform-determination1300
                        (buttonsDisable)="ButtonDisable($event)" (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-and-uniform-determination1300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSeparateAndUniformDetermination_1400'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-separate-and-uniform-determination1400
                        (buttonsDisable)="ButtonDisable($event)" (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-separate-and-uniform-determination1400>
                </div>

                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSaUControllingCompany_1100'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-sa-ucontrolling-company (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-sa-ucontrolling-company>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSaUControllingCompany_1300'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-sa-ucontrolling-company1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-sa-ucontrolling-company1300>
                </div>
                <div *ngSwitchCase="
                        'DocumentCorporateIncomeTaxSaUControllingCompany_1400'
                    " class="k-doc-card">
                    <app-doc-corporate-income-tax-sa-ucontrolling-company1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-income-tax-sa-ucontrolling-company1400>
                </div>

                <div *ngSwitchCase="'DocumentCorporateTaxEBITDA_1100'" class="k-doc-card">
                    <app-doc-corporate-tax-ebitda (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-tax-ebitda>
                </div>
                <div *ngSwitchCase="'DocumentCorporateTaxEBITDA_1300'" class="k-doc-card">
                    <app-doc-corporate-tax-ebitda1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-tax-ebitda1300>
                </div>
                <div *ngSwitchCase="'DocumentCorporateTaxEBITDA_1400'" class="k-doc-card">
                    <app-doc-corporate-tax-ebitda1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-tax-ebitda1400>
                </div>

                <div *ngSwitchCase="'DocumentTradeTaxBaseAssessment_1100'" class="k-doc-card">
                    <app-doc-trade-tax-base (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-base>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxBaseAssessment_1300'" class="k-doc-card">
                    <app-doc-trade-tax-base1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-base1300>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxBaseAssessment_1400'" class="k-doc-card">
                    <app-doc-trade-tax-base1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-base1400>
                </div>

                <div *ngSwitchCase="'DocumentTradeTaxLossCarryForward_1100'" class="k-doc-card">
                    <app-doc-trade-tax-loss-carry-forward (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-loss-carry-forward>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxLossCarryForward_1300'" class="k-doc-card">
                    <app-doc-trade-tax-loss-carry-forward1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-loss-carry-forward1300>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxLossCarryForward_1400'" class="k-doc-card">
                    <app-doc-trade-tax-loss-carry-forward1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-loss-carry-forward1400>
                </div>

                <div *ngSwitchCase="'DocumentTradeTaxBreakdown_1100'" class="k-doc-card">
                    <app-doc-trade-tax-breakdown (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-breakdown>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxBreakdown_1300'" class="k-doc-card">
                    <app-doc-trade-tax-breakdown1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-breakdown1300>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxBreakdown_1400'" class="k-doc-card">
                    <app-doc-trade-tax-breakdown1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-breakdown1400>
                </div>

                <div *ngSwitchCase="'DocumentTradeTax_1100'" class="k-doc-card">
                    <app-doc-trade-tax (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax>
                </div>
                <div *ngSwitchCase="'DocumentTradeTax_1300'" class="k-doc-card">
                    <app-doc-trade-tax1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)" [workflowTypeInput]="workflowType"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-trade-tax1300>
                </div>
                <div *ngSwitchCase="'DocumentTradeTax_1400'" class="k-doc-card">
                    <app-doc-trade-tax1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-trade-tax1400>
                </div>

                <div *ngSwitchCase="'DocumentPropertyTax_1100'" class="k-doc-card">
                    <app-doc-property-tax (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " [workflowTypeInput]="workflowType" (rectangleFieldList)="updateRectangleFields($event)"
                        [onButtonSaveClick]="eventSaved" [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-property-tax>
                </div>
                <div *ngSwitchCase="'DocumentPropertyTax_1300'" class="k-doc-card">
                    <app-doc-property-tax1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)" [workflowTypeInput]="workflowType"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-property-tax1300>
                </div>
                <div *ngSwitchCase="'DocumentPropertyTax_1400'" class="k-doc-card">
                    <app-doc-property-tax1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-property-tax1400>
                </div>
                <div *ngSwitchCase="'DocumentChamberOfCommerceAndIndustry_1100'" class="k-doc-card">
                    <app-doc-chamber-of-commerce-and-industry (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-chamber-of-commerce-and-industry>
                </div>
                <div *ngSwitchCase="'DocumentChamberOfCommerceAndIndustry_1300'" class="k-doc-card">
                    <app-doc-chamber-of-commerce-and-industry1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-chamber-of-commerce-and-industry1300>
                </div>
                <div *ngSwitchCase="'DocumentChamberOfCommerceAndIndustry_1400'" class="k-doc-card">
                    <app-doc-chamber-of-commerce-and-industry1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-chamber-of-commerce-and-industry1400>
                </div>

                <div *ngSwitchCase="'DocumentTradeTaxAddCarryForward_1100'" class="k-doc-card">
                    <app-doc-trade-tax-add-carry-forward (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-add-carry-forward>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxAddCarryForward_1300'" class="k-doc-card">
                    <app-doc-trade-tax-add-carry-forward1300 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-add-carry-forward1300>
                </div>
                <div *ngSwitchCase="'DocumentTradeTaxAddCarryForward_1400'" class="k-doc-card">
                    <app-doc-trade-tax-add-carry-forward1400 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId"
                        [acutalStateIdInput]="actualStateId"></app-doc-trade-tax-add-carry-forward1400>
                </div>

                <div *ngSwitchCase="'DocumentIncomeTax_1100'" class="k-doc-card">
                    <app-doc-income-tax (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-income-tax>
                </div>
                <div *ngSwitchCase="'DocumentValueAddedTax_1100'" class="k-doc-card">
                    <app-doc-value-added-tax (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-value-added-tax>
                </div>

                <div *ngSwitchCase="'DocumentCorporateTaxInterest_1100'" class="k-doc-card">
                    <app-doc-corporate-tax-interest (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [workflowTypeInput]="workflowType"
                        [acutalStateIdInput]="actualStateId"></app-doc-corporate-tax-interest>
                </div>

                <div *ngSwitchCase="'DocumentDeadline_1100'" class="k-doc-card">
                    <app-doc-deadline (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-deadline>
                </div>
                <div *ngSwitchCase="'DocumentDeadline_1200'" class="k-doc-card">
                    <app-doc-deadline1200 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-deadline1200>
                </div>
                <div *ngSwitchCase="'DocumentDeadline_3100'" class="k-doc-card">
                    <app-doc-deadline3100 (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-deadline3100>
                </div>

                <div *ngSwitchCase="'8000'" class="k-doc-card">
                    <app-doc-deletion (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-deletion>
                </div>

                <div *ngSwitchCase="'1000'" class="k-doc-card">
                    <app-doc-exception (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-exception>
                </div>

                <div *ngSwitchCase="'Undefine'" class="k-doc-card">
                    <app-doc-undefine (buttonsDisable)="ButtonDisable($event)"
                        (notificationMessage)="displayNotification($event)"
                        (savedSuccessfully)="savedSuccessfullyReceiver($event)" (formLoadedSuccessfully)="
                            formLoadedSuccessfullyReceiver()
                        " (validationMessageList)="
                            updateValidationMessagesBadget($event)
                        " (referenceMessageList)="
                            updateReferenceMessagesBadget($event)
                        " (rectangleFieldList)="updateRectangleFields($event)" [onButtonSaveClick]="eventSaved"
                        [workflowIdInput]="workflowId" [acutalStateIdInput]="actualStateId"></app-doc-undefine>
                </div>

                <div *ngSwitchDefault class="a-panel">
                    {{
                    "APP.ASSESSMENT_REVIEW.PDF_VIEWER.LOADING_PAGE"
                    | translate
                    }}
                    <br />
                    <br />
                    <span style="color: lightgray">
                        {{
                        "APP.ASSESSMENT_REVIEW.PDF_VIEWER.FIELDS"
                        | translate
                        }}
                        <strong>"{{ documentTypeNameLabelDE }}"</strong>
                        (Internal document type id:
                        {{ documentTypeName }})<br />
                        {{
                        "APP.ASSESSMENT_REVIEW.PDF_VIEWER.MODE" | translate
                        }}
                        - State-Id: {{ actualStateId }}).
                    </span>
                </div>
            </div>
            <div *ngIf="isFormLoading" class="k-i-loading"></div>
        </div>
    </kendo-splitter-pane>
</kendo-splitter>

<!-- Comment section -->
<ng-template #titleTemplate let-anchor>
    {{ "APP.TASKS.TABLE.HEADER.COMMENT" | translate }}
</ng-template>
<ng-template #template let-anchor>
    <pre style="white-space: pre-wrap">{{ commentToolTip }}</pre>
</ng-template>

<kendo-popup #popupComment *ngIf="showCommentPopup" [anchor]="btnSave2" style="width: 500px; right: 0px"
    (anchorViewportLeave)="closePopup()">
    <div class="content">
        <!-- User-defined content -->
        <kendo-chat [messages]="commentMessages" [style.width.px]="500" [user]="user" messageBoxType="textarea"
            (sendMessage)="sendMessage($event)">
            <ng-template kendoChatMessageTemplate let-message>
                <!-- Name and Time as HH:mm:ss -->
                <strong><small>{{ message.author.name }} -
                        {{ formatLocalTime(message.timestamp) }}</small></strong>
                <div [innerHTML]="message.text"></div>
            </ng-template>
        </kendo-chat>
    </div>
</kendo-popup>

<!-- End of comment section -->

<!-- Validation Messages section -->

<ng-template #titleTemplateValidationMessages let-anchor>
    {{ "APP.ASSESSMENT_REVIEW.PDF_VIEWER.VALIDATE_BADGE" | translate }}
</ng-template>
<ng-template #templateValidationMessages let-anchor>
    <pre style="white-space: pre-wrap">{{ validationMessagesToolTip }}</pre>
</ng-template>

<kendo-popup #popupValidationMessages *ngIf="showValidationMessagesPopup" [anchor]="btnSave2"
    style="width: 500px; right: 0px" (anchorViewportLeave)="closePopupValidationMessages()" [collision]="collision">
    <div class="content">
        <kendo-listview [data]="validationMessages" containerClass="k-d-flex k-flex-col k-flex-nowrap">
            <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-index="index" let-isFirst="isFirst"
                let-isLast="isLast">
                <div class="valMessages" [class.border-bottom]="!isLast">
                    <div *ngIf="dataItem.isError">
                        <span class="k-icon-lg k-icon k-font-icon k-color-error k-i-error"
                            style="color: red; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'error',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                    <div *ngIf="dataItem.isWarning">
                        <span title="Warnung" class="k-icon-lg k-icon k-font-icon k-color-info k-i-info"
                            style="color: orange; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'warning',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                    <div *ngIf="dataItem.isInfo">
                        <span title="Info" class="k-icon-lg k-icon k-font-icon k-color-info k-i-info"
                            style="color: green; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'info',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-listview>
    </div>
</kendo-popup>

<!-- End of Badget Validation Messages section -->

<!-- Reference Messages section -->

<ng-template #titleTemplateReferenceMessages let-anchor>
    {{ "APP.ASSESSMENT_REVIEW.PDF_VIEWER.REFERENCE_BADGE" | translate }}
</ng-template>
<ng-template #templateReferenceMessages let-anchor>
    <pre style="white-space: pre-wrap">{{ referenceMessagesToolTip }}</pre>
</ng-template>

<kendo-popup #popupReferenceMessages *ngIf="showReferenceMessagesPopup" [anchor]="btnSave2"
    style="width: 500px; right: 0px" (anchorViewportLeave)="closePopupReferenceMessages()">
    <div class="content">
        <kendo-listview [data]="referenceMessages" containerClass="k-d-flex k-flex-col k-flex-nowrap">
            <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-index="index" let-isFirst="isFirst"
                let-isLast="isLast">
                <div class="valMessages" [class.border-bottom]="!isLast">
                    <div *ngIf="dataItem.isError">
                        <span class="k-icon-lg k-icon k-font-icon k-color-error k-i-error"
                            style="color: red; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'error',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                    <div *ngIf="dataItem.isWarning">
                        <span title="Warnung" class="k-icon-lg k-icon k-font-icon k-color-info k-i-info"
                            style="color: orange; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'warning',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                    <div *ngIf="dataItem.isInfo">
                        <span title="Info" class="k-icon-lg k-icon k-font-icon k-color-info k-i-info"
                            style="color: green; float: left"></span>
                        <div class="valMessages-name" (click)="
                                setFieldFocusAfterMessageClick(
                                    dataItem.xbrlName,
                                    'info',
                                    dataItem.datasetId
                                )
                            ">
                            {{ dataItem.messageDE }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-listview>
    </div>
</kendo-popup>

<!-- End of Badget Validation Messages section -->

<!-- Trade Tax informations-->

<ng-template #toolTipGridTemplate let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<kendo-popup #popupTradeTax *ngIf="showTradeTaxPopup" [anchor]="btnSave2"
    style="width: 1010px; height: 600px; right: 0px" (anchorViewportLeave)="closePopupTradeTax()">
    <div class="content">
        <kendo-toolbar [size]="'small'">
            <kendo-toolbar-button text="Veranlagung" (click)="onTradeTaxPanel(2)"></kendo-toolbar-button>
            <kendo-toolbar-button text="Fälligkeiten" (click)="onTradeTaxPanel(1)"></kendo-toolbar-button>
            <kendo-toolbar-button text="Zinsen" (click)="onTradeTaxPanel(3)"></kendo-toolbar-button>
            <kendo-toolbar-button text="Zinsen berechnet" (click)="onTradeTaxPanel(4)"></kendo-toolbar-button>
            <kendo-toolbar-button text="Zinslauf alt" (click)="onTradeTaxPanel(5)"></kendo-toolbar-button>
            <kendo-toolbar-button text="Zinslauf neu" (click)="onTradeTaxPanel(6)"></kendo-toolbar-button>
            <kendo-toolbar-separator></kendo-toolbar-separator>
            <kendo-toolbar-dropdownbutton (itemClick)="onTradeTaxAssessmentYearsChange($event)" text="Filter für EHZ"
                [data]="tradeTaxAssessmentYears">
            </kendo-toolbar-dropdownbutton>
        </kendo-toolbar>

        <div *ngIf="tradeTaxPanelIdDisplay == 1" style="height: 400px">
            <kendo-grid [kendoGridBinding]="tradeTaxPayments" scrollable="scrollable" [style.height]="'100%'"
                [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true"
                [resizable]="true" [sortable]="true">
                <kendo-grid-messages [pagerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE' | translate
                    " [pagerOf]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF' | translate
                    " [pagerItems]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM' | translate
                    " [pagerItemsPerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                            | translate
                    " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                    [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate" [autosizeThisColumn]="
                        'TABLE_MENU.AUTOSIZE_THIS' | translate
                    " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                    [filterAfterOperator]="
                        'TABLE_MENU.AFTER_OPERATOR' | translate
                    " [filterAfterOrEqualOperator]="
                        'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                    " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                        'TABLE_MENU.BEFORE_OPERATOR' | translate
                    " [filterBeforeOrEqualOperator]="
                        'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                    " [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                    [filterClearButton]="'BUTTON.CLEAR' | translate"
                    [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                    [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                        'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                    " [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                    [filterFilterButton]="'BUTTON.FILTER' | translate"
                    [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                    [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                    [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                    [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                        'TABLE_MENU.IS_NOT_EMPTY' | translate
                    " [filterIsNotNullOperator]="
                        'TABLE_MENU.IS_NOT_NULL' | translate
                    " [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                    [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                    [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                    [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate" [filterNotContainsOperator]="
                        'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                    " [filterNotEqOperator]="
                        'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                    " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                        'TABLE_MENU.STARTS_WITH' | translate
                    " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                        'TABLE_MENU.SET_COLUMN_POSITION' | translate
                    " [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                    [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
                </kendo-grid-messages>

                <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a [href]="dataItem.sourceUrl" [target]="'_blank'">{{
                            transformDate(dataItem.notificationDate)
                            }}</a>
                    </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column> -->
                <kendo-grid-column field="tradeTaxAmount" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Neu veranlagte GewSt"></kendo-grid-column>
                <kendo-grid-column field="tradeTaxAmountToPaid" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Fälligkeit"></kendo-grid-column>
                <kendo-grid-column field="tradeTaxAmountToPaidDate" format="dd.MM.yyyy"
                    title="Fälligkeitsdatum"></kendo-grid-column>
                <kendo-grid-column field="paymentsToBeMade" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Änderungsbetrag"></kendo-grid-column>
                <kendo-grid-column field="paymentType" title="Type"></kendo-grid-column>

                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                </ng-template>

                <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
            </kendo-grid>
        </div>
        <div *ngIf="tradeTaxPanelIdDisplay == 2" style="height: 400px">
            <kendo-grid [kendoGridBinding]="tradeTaxMaturity" scrollable="scrollable" [style.height]="'100%'"
                [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true"
                [resizable]="true" [sortable]="true">
                <kendo-grid-messages [pagerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE' | translate
                    " [pagerOf]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF' | translate
                    " [pagerItems]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM' | translate
                    " [pagerItemsPerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                            | translate
                    " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                    [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate" [autosizeThisColumn]="
                        'TABLE_MENU.AUTOSIZE_THIS' | translate
                    " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                    [filterAfterOperator]="
                        'TABLE_MENU.AFTER_OPERATOR' | translate
                    " [filterAfterOrEqualOperator]="
                        'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                    " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                        'TABLE_MENU.BEFORE_OPERATOR' | translate
                    " [filterBeforeOrEqualOperator]="
                        'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                    " [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                    [filterClearButton]="'BUTTON.CLEAR' | translate"
                    [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                    [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                        'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                    " [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                    [filterFilterButton]="'BUTTON.FILTER' | translate"
                    [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                    [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                    [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                    [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                        'TABLE_MENU.IS_NOT_EMPTY' | translate
                    " [filterIsNotNullOperator]="
                        'TABLE_MENU.IS_NOT_NULL' | translate
                    " [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                    [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                    [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                    [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate" [filterNotContainsOperator]="
                        'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                    " [filterNotEqOperator]="
                        'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                    " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                        'TABLE_MENU.STARTS_WITH' | translate
                    " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                        'TABLE_MENU.SET_COLUMN_POSITION' | translate
                    " [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                    [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
                </kendo-grid-messages>

                <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a [href]="dataItem.sourceUrl" [target]="'_blank'">{{
                            transformDate(dataItem.notificationDate)
                            }}</a>
                    </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column> -->
                <kendo-grid-column field="tradeTaxAmount" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Neu veranlagte GewSt"></kendo-grid-column>
                <!-- <kendo-grid-column field="tradeTaxAmountToPaid" format="N2" [style]="{'text-align': 'right'}"
					[headerStyle]="{'text-align': 'right'}" title="Fälligkeit"></kendo-grid-column> -->
                <kendo-grid-column field="tradeTaxAmountToPaidDate" format="dd.MM.yyyy"
                    title="Fälligkeitsdatum"></kendo-grid-column>
                <kendo-grid-column field="paymentsToBeMade" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Änderungsbetrag"></kendo-grid-column>
                <kendo-grid-column field="paymentType" title="Type"></kendo-grid-column>

                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                </ng-template>

                <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
            </kendo-grid>
        </div>
        <div *ngIf="tradeTaxPanelIdDisplay == 3" style="height: 400px">
            <kendo-grid [kendoGridBinding]="tradeTaxInterests" scrollable="scrollable" [style.height]="'100%'"
                [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true"
                [resizable]="true" [sortable]="true">
                <kendo-grid-messages [pagerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE' | translate
                    " [pagerOf]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF' | translate
                    " [pagerItems]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM' | translate
                    " [pagerItemsPerPage]="
                        'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                            | translate
                    " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                    [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate" [autosizeThisColumn]="
                        'TABLE_MENU.AUTOSIZE_THIS' | translate
                    " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                    [filterAfterOperator]="
                        'TABLE_MENU.AFTER_OPERATOR' | translate
                    " [filterAfterOrEqualOperator]="
                        'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                    " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                        'TABLE_MENU.BEFORE_OPERATOR' | translate
                    " [filterBeforeOrEqualOperator]="
                        'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                    " [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                    [filterClearButton]="'BUTTON.CLEAR' | translate"
                    [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                    [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                        'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                    " [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                    [filterFilterButton]="'BUTTON.FILTER' | translate"
                    [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                    [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                    [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                    [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                        'TABLE_MENU.IS_NOT_EMPTY' | translate
                    " [filterIsNotNullOperator]="
                        'TABLE_MENU.IS_NOT_NULL' | translate
                    " [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                    [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                    [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                    [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate" [filterNotContainsOperator]="
                        'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                    " [filterNotEqOperator]="
                        'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                    " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                        'TABLE_MENU.STARTS_WITH' | translate
                    " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                        'TABLE_MENU.SET_COLUMN_POSITION' | translate
                    " [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                    [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
                </kendo-grid-messages>

                <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a [href]="dataItem.sourceUrl" [target]="'_blank'">{{
                            transformDate(dataItem.notificationDate)
                            }}</a>
                    </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column> -->
                <kendo-grid-column field="interestPaymentsMade" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Zinsen bisher"></kendo-grid-column>
                <kendo-grid-column field="interestAmount" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Zinsen neu"></kendo-grid-column>

                <kendo-grid-column field="interestPaymentsToBeMade" format="N2" [style]="{ 'text-align': 'right' }"
                    [headerStyle]="{ 'text-align': 'right' }" title="Änderungsbetrag"></kendo-grid-column>

                <ng-template kendoGridNoRecordsTemplate>
                    <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                </ng-template>

                <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
            </kendo-grid>
        </div>

        <div *ngIf="tradeTaxPanelIdDisplay == 4" style="height: 400px">
            <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
                (mouseover)="showTooltip($event)">
                <kendo-grid [kendoGridBinding]="tradeTaxInterestsCalculation" scrollable="scrollable"
                    [style.height]="'100%'" [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }"
                    [navigable]="true" [resizable]="true" [sortable]="true">
                    <kendo-grid-messages [pagerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE'
                                | translate
                        " [pagerOf]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF'
                                | translate
                        " [pagerItems]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM'
                                | translate
                        " [pagerItemsPerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                                | translate
                        " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                        [autosizeAllColumns]="
                            'TABLE_MENU.AUTOSIZE_ALL' | translate
                        " [autosizeThisColumn]="
                            'TABLE_MENU.AUTOSIZE_THIS' | translate
                        " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate"
                        [filter]="'TABLE_MENU.FILTER_TAB' | translate" [filterAfterOperator]="
                            'TABLE_MENU.AFTER_OPERATOR' | translate
                        " [filterAfterOrEqualOperator]="
                            'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                        " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                            'TABLE_MENU.BEFORE_OPERATOR' | translate
                        " [filterBeforeOrEqualOperator]="
                            'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                        " [filterBooleanAll]="
                            'TABLE_MENU.BOOLEAN_ALL' | translate
                        " [filterClearButton]="'BUTTON.CLEAR' | translate" [filterContainsOperator]="
                            'TABLE_MENU.CONTAINS' | translate
                        " [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                            'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                        " [filterEqOperator]="
                            'TABLE_MENU.EQUAL_OPERATOR' | translate
                        " [filterFilterButton]="'BUTTON.FILTER' | translate" [filterGteOperator]="
                            'TABLE_MENU.GTE_OPERATOR' | translate
                        " [filterGtOperator]="
                            'TABLE_MENU.GT_OPERATOR' | translate
                        " [filterIsEmptyOperator]="
                            'TABLE_MENU.IS_EMPTY' | translate
                        " [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                            'TABLE_MENU.IS_NOT_EMPTY' | translate
                        " [filterIsNotNullOperator]="
                            'TABLE_MENU.IS_NOT_NULL' | translate
                        " [filterIsNullOperator]="
                            'TABLE_MENU.IS_NULL' | translate
                        " [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate" [filterLteOperator]="
                            'TABLE_MENU.LTE_OPERATOR' | translate
                        " [filterLtOperator]="
                            'TABLE_MENU.LT_OPERATOR' | translate
                        " [filterNotContainsOperator]="
                            'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                        " [filterNotEqOperator]="
                            'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                        " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                            'TABLE_MENU.STARTS_WITH' | translate
                        " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                            'TABLE_MENU.SET_COLUMN_POSITION' | translate
                        " [sortAscending]="
                            'TABLE_MENU.SORT_ASCENDING' | translate
                        " [sortDescending]="
                            'TABLE_MENU.SORT_DESCENDING' | translate
                        ">
                    </kendo-grid-messages>

                    <!-- <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">

					<ng-template kendoGridCellTemplate let-dataItem>
						<a [href]="dataItem.sourceUrl"
							[target]="'_blank'">{{transformDate(dataItem.notificationDate)}}</a>
					</ng-template>

				</kendo-grid-column> -->
                    <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column>
                    <kendo-grid-column field="interestPrevious" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Bisher"></kendo-grid-column>

                    <kendo-grid-column field="interestOldLaw" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Altes Recht"></kendo-grid-column>
                    <kendo-grid-column field="interestPaymentsToBeMadeOldLaw" format="N2"
                        [style]="{ 'text-align': 'right' }" [headerStyle]="{ 'text-align': 'right' }"
                        title="Unterschied nach altem Recht"></kendo-grid-column>

                    <kendo-grid-column field="interestNewLaw" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Neues Recht"></kendo-grid-column>
                    <kendo-grid-column field="interestPaymentsToBeMadeNewLaw" format="N2"
                        [style]="{ 'text-align': 'right' }" [headerStyle]="{ 'text-align': 'right' }"
                        title="Unterschied nach neuen Recht"></kendo-grid-column>

                    <ng-template kendoGridNoRecordsTemplate>
                        <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                    </ng-template>

                    <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
                </kendo-grid>
            </div>
        </div>

        <div *ngIf="tradeTaxPanelIdDisplay == 5" style="height: 400px">
            <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
                (mouseover)="showTooltip($event)">
                <kendo-grid [kendoGridBinding]="interestRunsOldLaws" scrollable="scrollable" [style.height]="'100%'"
                    [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true"
                    [resizable]="true" [sortable]="true">
                    <kendo-grid-messages [pagerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE'
                                | translate
                        " [pagerOf]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF'
                                | translate
                        " [pagerItems]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM'
                                | translate
                        " [pagerItemsPerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                                | translate
                        " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                        [autosizeAllColumns]="
                            'TABLE_MENU.AUTOSIZE_ALL' | translate
                        " [autosizeThisColumn]="
                            'TABLE_MENU.AUTOSIZE_THIS' | translate
                        " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate"
                        [filter]="'TABLE_MENU.FILTER_TAB' | translate" [filterAfterOperator]="
                            'TABLE_MENU.AFTER_OPERATOR' | translate
                        " [filterAfterOrEqualOperator]="
                            'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                        " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                            'TABLE_MENU.BEFORE_OPERATOR' | translate
                        " [filterBeforeOrEqualOperator]="
                            'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                        " [filterBooleanAll]="
                            'TABLE_MENU.BOOLEAN_ALL' | translate
                        " [filterClearButton]="'BUTTON.CLEAR' | translate" [filterContainsOperator]="
                            'TABLE_MENU.CONTAINS' | translate
                        " [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                            'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                        " [filterEqOperator]="
                            'TABLE_MENU.EQUAL_OPERATOR' | translate
                        " [filterFilterButton]="'BUTTON.FILTER' | translate" [filterGteOperator]="
                            'TABLE_MENU.GTE_OPERATOR' | translate
                        " [filterGtOperator]="
                            'TABLE_MENU.GT_OPERATOR' | translate
                        " [filterIsEmptyOperator]="
                            'TABLE_MENU.IS_EMPTY' | translate
                        " [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                            'TABLE_MENU.IS_NOT_EMPTY' | translate
                        " [filterIsNotNullOperator]="
                            'TABLE_MENU.IS_NOT_NULL' | translate
                        " [filterIsNullOperator]="
                            'TABLE_MENU.IS_NULL' | translate
                        " [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate" [filterLteOperator]="
                            'TABLE_MENU.LTE_OPERATOR' | translate
                        " [filterLtOperator]="
                            'TABLE_MENU.LT_OPERATOR' | translate
                        " [filterNotContainsOperator]="
                            'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                        " [filterNotEqOperator]="
                            'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                        " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                            'TABLE_MENU.STARTS_WITH' | translate
                        " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                            'TABLE_MENU.SET_COLUMN_POSITION' | translate
                        " [sortAscending]="
                            'TABLE_MENU.SORT_ASCENDING' | translate
                        " [sortDescending]="
                            'TABLE_MENU.SORT_DESCENDING' | translate
                        ">
                    </kendo-grid-messages>

                    <!-- <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">

					<ng-template kendoGridCellTemplate let-dataItem>
						<a [href]="dataItem.sourceUrl"
							[target]="'_blank'">{{transformDate(dataItem.notificationDate)}}</a>
					</ng-template>

				</kendo-grid-column> -->
                    <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column>
                    <kendo-grid-column field="assessmentDocumentDate" format="dd.MM.yyyy"
                        title="Bescheiddatum"></kendo-grid-column>
                    <kendo-grid-column field="beginDate" format="dd.MM.yyyy" title="Start"></kendo-grid-column>
                    <kendo-grid-column field="endDate" format="dd.MM.yyyy" title="Ende"></kendo-grid-column>
                    <kendo-grid-column field="months" format="N0" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Monate"></kendo-grid-column>

                    <kendo-grid-column field="interestRate" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Rate"></kendo-grid-column>
                    <kendo-grid-column field="interestBearingAmount" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Betrag"></kendo-grid-column>
                    <kendo-grid-column field="interest" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Zinsen"></kendo-grid-column>
                    <kendo-grid-column field="type" title="Typ"></kendo-grid-column>

                    <ng-template kendoGridNoRecordsTemplate>
                        <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                    </ng-template>

                    <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
                </kendo-grid>
            </div>
        </div>

        <div *ngIf="tradeTaxPanelIdDisplay == 6" style="height: 400px">
            <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
                (mouseover)="showTooltip($event)">
                <kendo-grid [kendoGridBinding]="interestRunsNewLaws" scrollable="scrollable" [style.height]="'100%'"
                    [size]="'small'" [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true"
                    [resizable]="true" [sortable]="true">
                    <kendo-grid-messages [pagerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.PAGE'
                                | translate
                        " [pagerOf]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.OF'
                                | translate
                        " [pagerItems]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.ITEM'
                                | translate
                        " [pagerItemsPerPage]="
                            'APP.SETTINGS.SETTINGS_WBS.TABLE.PAGER.TASK_PER_PAGE'
                                | translate
                        " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                        [autosizeAllColumns]="
                            'TABLE_MENU.AUTOSIZE_ALL' | translate
                        " [autosizeThisColumn]="
                            'TABLE_MENU.AUTOSIZE_THIS' | translate
                        " [columns]="'TABLE_MENU.COLUMNS_TAB' | translate"
                        [filter]="'TABLE_MENU.FILTER_TAB' | translate" [filterAfterOperator]="
                            'TABLE_MENU.AFTER_OPERATOR' | translate
                        " [filterAfterOrEqualOperator]="
                            'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                        " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                            'TABLE_MENU.BEFORE_OPERATOR' | translate
                        " [filterBeforeOrEqualOperator]="
                            'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                        " [filterBooleanAll]="
                            'TABLE_MENU.BOOLEAN_ALL' | translate
                        " [filterClearButton]="'BUTTON.CLEAR' | translate" [filterContainsOperator]="
                            'TABLE_MENU.CONTAINS' | translate
                        " [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                            'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                        " [filterEqOperator]="
                            'TABLE_MENU.EQUAL_OPERATOR' | translate
                        " [filterFilterButton]="'BUTTON.FILTER' | translate" [filterGteOperator]="
                            'TABLE_MENU.GTE_OPERATOR' | translate
                        " [filterGtOperator]="
                            'TABLE_MENU.GT_OPERATOR' | translate
                        " [filterIsEmptyOperator]="
                            'TABLE_MENU.IS_EMPTY' | translate
                        " [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                            'TABLE_MENU.IS_NOT_EMPTY' | translate
                        " [filterIsNotNullOperator]="
                            'TABLE_MENU.IS_NOT_NULL' | translate
                        " [filterIsNullOperator]="
                            'TABLE_MENU.IS_NULL' | translate
                        " [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate" [filterLteOperator]="
                            'TABLE_MENU.LTE_OPERATOR' | translate
                        " [filterLtOperator]="
                            'TABLE_MENU.LT_OPERATOR' | translate
                        " [filterNotContainsOperator]="
                            'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                        " [filterNotEqOperator]="
                            'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                        " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                            'TABLE_MENU.STARTS_WITH' | translate
                        " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                            'TABLE_MENU.SET_COLUMN_POSITION' | translate
                        " [sortAscending]="
                            'TABLE_MENU.SORT_ASCENDING' | translate
                        " [sortDescending]="
                            'TABLE_MENU.SORT_DESCENDING' | translate
                        ">
                    </kendo-grid-messages>

                    <!-- <kendo-grid-column field="notificationDate" format="dd.MM.yyyy" title="Datum">

					<ng-template kendoGridCellTemplate let-dataItem>
						<a [href]="dataItem.sourceUrl"
							[target]="'_blank'">{{transformDate(dataItem.notificationDate)}}</a>
					</ng-template>

				</kendo-grid-column> -->
                    <kendo-grid-column field="assessmentYear" title="VZ"></kendo-grid-column>
                    <kendo-grid-column field="assessmentDocumentDate" format="dd.MM.yyyy"
                        title="Bescheiddatum"></kendo-grid-column>
                    <kendo-grid-column field="beginDate" format="dd.MM.yyyy" title="Start"></kendo-grid-column>
                    <kendo-grid-column field="endDate" format="dd.MM.yyyy" title="Ende"></kendo-grid-column>
                    <kendo-grid-column field="months" format="N0" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Monate"></kendo-grid-column>

                    <kendo-grid-column field="interestRate" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Rate"></kendo-grid-column>
                    <kendo-grid-column field="interestBearingAmount" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Betrag"></kendo-grid-column>
                    <kendo-grid-column field="interest" format="N2" [style]="{ 'text-align': 'right' }"
                        [headerStyle]="{ 'text-align': 'right' }" title="Zinsen"></kendo-grid-column>
                    <kendo-grid-column field="type" title="Typ"></kendo-grid-column>

                    <ng-template kendoGridNoRecordsTemplate>
                        <p>{{ "TABLE_MENU.NO_RECORD" | translate }}</p>
                    </ng-template>

                    <kendo-grid-excel fileName="GewSt-Veranlagungen.xlsx"></kendo-grid-excel>
                </kendo-grid>
            </div>
        </div>
    </div>
</kendo-popup>

<!-- End trade tax informations -->