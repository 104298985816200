export interface DataTypeItem {
    name: string;
    id: number;

    documentDefinitionName: string | null;
    labelEN: string;
    labelDE: string;
    labelShortDE: string;
    labelMiddleDE: string;
    workflowSettingAllowed: boolean;

    //not in the backend model
    labelDEWithName: string;

    //from backend model
    // isPayload: boolean;
    // isNestedPayload: boolean;
    // isDocument: boolean;
}

export class DataTypeItemClass {
    // map to IData
    public static NewInstance(data: DataTypeItem): DataTypeItem {
        // if (!data) {
        //     return null;
        // }
        return {
            id: data.id,
            name: data.name,
            documentDefinitionName: data.documentDefinitionName,
            labelEN: data.labelEN,
            labelDE: data.labelDE,
            labelShortDE: data.labelShortDE,
            labelMiddleDE: data.labelMiddleDE,
            workflowSettingAllowed: data.workflowSettingAllowed,
            labelDEWithName: data.labelDEWithName,

            // isPayload: data.isPayload,
            // isNestedPayload: data.isNestedPayload,
            // isDocument: data.isDocument
        };
    }
    public static NewInstances(data: DataTypeItem[]): DataTypeItem[] {
        return data.map(DataTypeItemClass.NewInstance);
    }
}