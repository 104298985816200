<div class="stareTopic">
	{{ 'APP.SETTINGS.MAIL_TEMPLATES.TITLE' | translate }}
</div>



<div>
	<ng-template #toolTipGridTemplate let-anchor>
		<span>{{ anchor.nativeElement.innerText }}</span>
	</ng-template>
	<div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
		(mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
		[ngStyle]="{'height.px': pageHeight}">
		<kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
			filterable="menu" [loading]="!finishedLoading" [sort]="currentSort" (sortChange)="sortChange($event)"
			(dblclick)="editHandler(grid.activeRow)" (cellClick)="cellClickHandler($event)" (add)="addHandler()"
			[selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
			[sortable]="true" [filter]="currentFilter || {logic: 'and', filters: []}">

			<ng-template kendoGridToolbarTemplate>

				<kendo-grid-spacer></kendo-grid-spacer>

				<button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
					title="{{ 'BUTTON.ADD_MAIL_TEMPLATE' | translate }}">
				</button>
				&nbsp;|&nbsp;

				<button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
					title="{{ 'BUTTON.UPDATE' | translate }}">
				</button>

				<button kendoGridExcelCommand type="button" [svgIcon]="excelSVG" kendoTooltip
					title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">
				</button>

			</ng-template>





			<kendo-grid-column field="title"
				title="{{ 'APP.SETTINGS.MAIL_TEMPLATES.TABLE.HEADER.NAME' | translate }}"></kendo-grid-column>
			<kendo-grid-column field="subject"
				title="{{ 'APP.SETTINGS.MAIL_TEMPLATES.TABLE.HEADER.SUBJECT' | translate }}"></kendo-grid-column>
			<kendo-grid-column field="body"
				title="{{ 'APP.SETTINGS.MAIL_TEMPLATES.TABLE.HEADER.BODY' | translate }}"></kendo-grid-column>




			<ng-template kendoGridNoRecordsTemplate>
				<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
			</ng-template>

			<kendo-grid-excel fileName="Templates.xlsx"></kendo-grid-excel>

		</kendo-grid>
	</div>
</div>


<kendo-dialog *ngIf="active" [width]="450" [height]="600" (close)="closeForm()">
	<kendo-dialog-titlebar *ngIf="isNew">
		{{'BUTTON.ADD' | translate }}
	</kendo-dialog-titlebar>

	<kendo-dialog-titlebar *ngIf="!isNew">
		{{'BUTTON.EDIT' | translate}}
	</kendo-dialog-titlebar>

	<form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
		<kendo-formfield>
			<kendo-label [for]="title"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.NAME.LABEL' | translate"></kendo-label>
			<kendo-textbox #title formControlName="title"
				[placeholder]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.NAME.LABEL' | translate"
				[readonly]="!isNew" required>
			</kendo-textbox>
			<kendo-formerror>{{'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.NAME.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="isDE"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.IS_GERMAN.LABEL' | translate"></kendo-label>
			<input type="checkbox" kendoCheckBox formControlName="isDE" #isDE />

		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="isFormal"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.IS_FORMAL.LABEL' | translate"></kendo-label>
			<input type="checkbox" kendoCheckBox formControlName="isFormal" #isFormal />

		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="isMixedAuditResult"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.IS_MIXED_AUDIT_RESULT.LABEL' | translate"></kendo-label>
			<input type="checkbox" kendoCheckBox formControlName="isMixedAuditResult" #isMixedAuditResult />
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="auditResult"
				id="auditResultLabel">{{'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.AUDIT_RESULT.LABEL' |
				translate}}</kendo-label>
			<kendo-dropdownlist [textField]="'labelDE'" [valueField]="'value'" #auditResult [valuePrimitive]="true"
				formControlName="auditResult" [data]="auditResults">
			</kendo-dropdownlist>
			<kendo-formerror>{{'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.AUDIT_RESULT.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="subject"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.SUBJECT.LABEL' | translate"></kendo-label>
			<kendo-textbox #subject formControlName="subject"
				[placeholder]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.SUBJECT.LABEL' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.SUBJECT.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="body"
				[text]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.BODY.LABEL' | translate"></kendo-label>
			<kendo-textbox #body formControlName="body"
				[placeholder]="'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.BODY.LABEL' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.SETTINGS.MAIL_TEMPLATES.MODAL.ADD_TEMPLATE.BODY.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>
	</form>

	<kendo-dialog-actions layout="start">
		<button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
			{{'BUTTON.DELETE' | translate}}
		</button>
		<button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
			{{'BUTTON.SAVE' | translate}}
		</button>
		<button kendoButton (click)="onCancel($event)">
			{{'BUTTON.CANCEL' | translate}}
		</button>
	</kendo-dialog-actions>
</kendo-dialog>