import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SettingOption } from 'app/_models/setting-option-item.model';
import { SVGIcon, arrowRightIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-administration',
	templateUrl: './administration.component.html',
	styleUrls: ['./administration.component.scss'],
	standalone: false
})
export class AdministrationComponent implements OnInit {


	public arrowRightIcon: SVGIcon = arrowRightIcon;
	public settingOptionsData = [] as SettingOption[];
	public layoutRows = Array(6).fill({ height: 120 });

	// ====================================================================

	constructor(
		private titleService: Title,
		public translateService: TranslateService
	) {
		this.getData();
		this.settingOptionsData.forEach(option => {

			// only if option.title not null and not empty
			if ((option.title) && (option.title !== '')) {

				this.translateService.get(option.title as string).subscribe((title: string) => {
					option.titleToDisplay = title;
				});
			}
			// only if option.tooltip not null and not empty
			if ((option.tooltip) && (option.tooltip !== '')) {

				this.translateService.get(option.tooltip as string).subscribe((tooltip: string) => {
					option.tooltipToDisplay = tooltip;
				});
			}
		})
	}

	ngOnInit(): void {
		this.translateService.get('APP.ADMINISTRATION.TITLE').subscribe((title: string) => {
			this.titleService.setTitle('STARE | ' + title);
		});
		document.getElementById("arrow-2")?.setAttribute('style', 'position: absolute;  left: 900;');
		document.getElementById("arrow-1")?.setAttribute('style', 'position: absolute;  left: 600;');

	}

	// == Load grid data ===================================================================================

	public getData(): void {
		this.settingOptionsData = [
			{
				id: 1,
				col: 1,
				title: "APP.ADMINISTRATION.SDC_USER_MANAGEMENT.TITLE",
				link: "/admin-sdc-users",
				tooltip: "APP.ADMINISTRATION.SDC_USER_MANAGEMENT.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 2,
				col: 1,
				title: "APP.ADMINISTRATION.IMPORT_COMMUNE.TITLE",
				link: "/setting-import",
				tooltip: "APP.ADMINISTRATION.IMPORT_COMMUNE.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 3,
				col: 1,
				title: "APP.ADMINISTRATION.CLIENT_ENGAGEMENT.TITLE",
				link: "/admin-clientengagement",
				tooltip: "APP.ADMINISTRATION.CLIENT_ENGAGEMENT.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 4,
				col: 1,
				title: "APP.ADMINISTRATION.COMMUNE.TITLE",
				link: "/admin-commune",
				tooltip: "APP.ADMINISTRATION.COMMUNE.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 5,
				col: 1,
				title: "APP.ADMINISTRATION.TAXOFFICE.TITLE",
				link: "/admin-taxoffice",
				tooltip: "APP.ADMINISTRATION.TAXOFFICE.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 6,
				col: 1,
				title: "APP.ADMINISTRATION.DASHBOARD.TITLE",
				link: "/admin-dashboard",
				tooltip: "APP.ADMINISTRATION.DASHBOARD.SUBTITLE",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 1,
				col: 2,
				title: "APP.ADMINISTRATION.JOBS.TITLE",
				link: "/admin-jobs",
				tooltip: "APP.ADMINISTRATION.JOBS.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 2,
				col: 2,
				title: "APP.ADMINISTRATION.OCR.TITLE",
				link: "/admin-ocrqueue",
				tooltip: "APP.ADMINISTRATION.OCR.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 3,
				col: 2,
				title: "APP.SETTINGS.MAIL_TEMPLATES.TITLE",
				link: "/setting-mail-templates",
				tooltip: "APP.SETTINGS.MAIL_TEMPLATES.SHORT_TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			},
			{
				id: 4,
				col: 2,
				title: "APP.ADMINISTRATION.EXPORT_SCOPE.TITLE",
				link: "/admin-export-scope",
				tooltip: "APP.ADMINISTRATION.EXPORT_SCOPE.SHORTTOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			}
			, {
				id: 5,
				col: 2,
				title: "APP.TASKS.TITLE",
				link: "/tasks-all",
				tooltip: "APP.TASKS.TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			}
			, {
				id: 6,
				col: 2,
				title: "APP.ARCHIVE.TITLE",
				link: "/admin-archive",
				tooltip: "APP.ARCHIVE.TOOLTIP",
				titleToDisplay: "",
				tooltipToDisplay: "",
				rightsToDisplay: true
			}

		];
	}

}
