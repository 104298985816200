import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ListsService } from 'app/_services/lists.service';
import { ApiAuthentificationService } from 'app/_services/StareApi/auth.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})


export class LogoutComponent implements OnInit {


    constructor(
        //private router: Router,
        private apiAuthentificationService: ApiAuthentificationService,
        public translateService: TranslateService,
        private listsService: ListsService,

    ) { }

    ngOnInit(): void {
        console.info("Logout")

        localStorage.setItem('stare_login_count', '0');
        // localStorage.removeItem('user');
        // localStorage.removeItem('stare_token');
        // localStorage.clear();

        this.listsService.ResetCacheLegalEntities();
        this.listsService.ResetCacheCommuneAGS();

        // this.userSubject.next(null);

        this.apiAuthentificationService.logout();
        localStorage.clear();

    }

    reloadApp(): void {
        window.location.href = ENVIRONMENT.primaryDomain + '/login';
        //window.location.reload();
        //this.router.navigate([`${ENVIRONMENT.apiBaseUrl}/Auth/Login`]);
    }

}
