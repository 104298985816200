import { Component, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { UserLocal } from '../_models/user.model';
import * as FileSaver from 'file-saver';
import { FileRestrictions, SelectEvent, ErrorEvent } from "@progress/kendo-angular-upload";
import { Title } from '@angular/platform-browser';
import { FileItem } from 'app/_models/file.model';
import { DataService } from 'app/_services/StareApi/data.service';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { EMPTY, Subscription } from 'rxjs';
import { ListsService } from 'app/_services/lists.service';
import * as closest_match from 'closest-match';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationMessageItem } from 'app/_models/validation-message.model';



@Component({
  selector: 'app-setting-import',
  templateUrl: './setting-import.component.html',
  styleUrls: ['./setting-import.component.scss'],
  standalone: false
})
export class SettingImportComponent implements OnInit {

  validationErrors: string[] = [];
  hasValidationErrors: boolean = false;
  validationErrorsXML: string[] = [];
  hasValidationErrorsXML: boolean = false;
  public legalEntities: DropDownStringItem[] = [];

  public legalEntitiesStrings: string[] = [];
  public legalEntitiesSource: DropDownStringItem[] = [];
  private updateSubscription: Subscription = EMPTY.subscribe();

  // ============================================================================

  constructor(
    public translateService: TranslateService,
    private apiDataService: DataService,
    private titleService: Title,
    private listsService: ListsService,
    private notificationService: WrapperNotificationService
  ) { }

  ngOnInit(): void {

    this.translateService.get('APP.SETTINGS.IMPORT.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadEntities();
    // to load the list of legal entities after adding legal entitty
    this.updateSubscription = this.apiDataService.update$.subscribe(() => {
      // console.log('update legal entities');
      this.loadEntities();
    });

  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }

  getUserMailAddress(): string {

    const jsonStr = localStorage.getItem('user') || '';
    const jsonObj = JSON.parse(jsonStr);
    const myuser = jsonObj as UserLocal;

    return myuser.mail;
  }


  uploadFailed(event: ErrorEvent): void {
    this.hasValidationErrors = true;
    this.validationErrors = [];
    if (event.response instanceof HttpErrorResponse) {
      const messageItems = event.response.error;
      messageItems.map((item: ValidationMessageItem) => { this.validationErrors.push(item.messageDE) });
      this.notificationService.showError('Der Import konnte nicht durchgeführt werden. Details werden im unteren Bereich angezeigt.');
    }
  }

  uploadSuccess(): void {
    this.hasValidationErrors = false;
    this.notificationService.showSuccess('Der Import wurde erfolgreich durchgeführt.');
  }

  uploadFailedXML(event: ErrorEvent): void {
    this.hasValidationErrorsXML = true;
    this.validationErrorsXML = [];
    if (event.response instanceof HttpErrorResponse) {
      const messageItems = event.response.error;
      messageItems.map((item: ValidationMessageItem) => { this.validationErrorsXML.push(item.messageDE) });
      this.notificationService.showError('Der Import konnte nicht durchgeführt werden. Details werden im unteren Bereich angezeigt.');
    }
  }

  uploadSuccessXML(): void {
    this.hasValidationErrorsXML = false;
    this.notificationService.showSuccess('Der Import wurde erfolgreich durchgeführt.');
  }

  valueLegalEntityChange(event: DropDownStringItem): void {
    this.legalItemForAPI = event;
    this.uploadSaveUrlForXML = ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync/' + event.value;
  }


  // customUploader = {

  //   maxFileSize: 10 * 1024 * 1024,
  //   autoUpload: false,
  //   authToken: 'Bearer ' + localStorage.getItem('stare_token') || '',
  //   url: ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync'
  // };



  getTemplateFile(): void {
    console.info("getTemplateFile");
    this.apiDataService.getBulkImportTemplateFileAsync().subscribe({
      next: (res: FileItem) => {

        const fileAsBlob = this.base64toBlob(res.contentAsBase64!, res.mimeType!);

        FileSaver.saveAs(fileAsBlob, res.filename);

        // const blob = new Blob([res.csvContent], { type: 'text/csv' });

        // FileSaver.saveAs(blob, res.filename);

        // var downloadURL = window.URL.createObjectURL(blob);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = "Template_for_STARE_Import.csv";
        // link.click();
      }

    })

  }

  // == Kendo Upload ==============================================

  // maxFileSize: 10 * 1024 * 1024,
  // autoUpload: false,
  // authToken: 'Bearer ' + localStorage.getItem('stare_token') || '',

  uploadSaveUrl = ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync'; // should represent an actual API endpoint
  // uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  myRestrictions: FileRestrictions = {
    allowedExtensions: [".csv"],
    maxFileSize: 10 * 1024 * 1024,
  };

  uploadSaveUrlForXML = ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync/'; // should represent an actual API endpoint
  // uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint

  myRestrictionsForXML: FileRestrictions = {
    allowedExtensions: [".xml"],
    maxFileSize: 10 * 1024 * 1024,
  };

  /**
  * Convert base64 to blob
  * @author https://gist.github.com/Marceloalves6/d1f590437f7fe45ac6832e5177c84be6
  */
  private base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  // == Handling XML Upload ==================================================================================

  isLoading: boolean = false;
  fileSelected: boolean = false;
  currentFile: File = {} as File;
  parser = new DOMParser();

  legalItemForAPI: DropDownStringItem = {} as DropDownStringItem;



  onSelectXMLFile(event: SelectEvent): void {
    this.isLoading = true;
    // this.currentFile = event.files[0];
    if (event.files[0].extension === ".xml") {
      this.currentFile = event.files[0].rawFile ? event.files[0].rawFile : {} as File;
      // const closest_match = require("closest-match");

      this.currentFile.text().then((text: string) => {
        const xmlDoc = this.parser.parseFromString(text, "text/xml");
        // let output = xmlDoc.getElementsByTagName('Daten')[0].getElementsByTagName('Gesellschaft Bezeichnung="Name der Gesellschaft"')[0]
        this.defaultItem = this.legalEntities.find((item) => item.labelDE === (closest_match.closestMatch(xmlDoc.getElementsByTagName('Gesellschaft')[0].textContent!, this.legalEntitiesStrings))) ?? this.legalEntities[0];
        this.legalEntities = this.legalEntities.filter(x => x != this.defaultItem);
        this.legalItemForAPI = this.defaultItem;
        this.uploadSaveUrlForXML = ENVIRONMENT.apiBaseUrl + 'Data/BulkImportAsync/' + this.legalItemForAPI.value;
      });

      this.fileSelected = true;
    }
    this.isLoading = false;
    console.info("onSelectXMLFile");

  }

  onRemoveXMLFile(): void {
    console.info("Remove");
    this.fileSelected = false;
  }

  onValueChangeXMLFile(): void {
    console.info("onValueChangeXMLFile");
    this.isLoading = false;

  }

  uploadXMLFile(): void {
    console.info("onValueChangeXMLFile");

    this.isLoading = false;
  }



  public defaultItem: DropDownStringItem = {
    labelEN: 'select item',
    labelDE: 'Bitte auswählen...',
    isDefault: false,
    isDeleted: false,
  };



  handleFilter(value: string): void {
    this.legalEntities = this.legalEntitiesSource.filter(
      (s) => s.labelDE.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }



  getlegalentityReferenceNamefromGuid(guid: string): string {
    const legalentityName = this.legalEntities.find(
      (item) => item.value == guid
    );
    if (legalentityName) {
      return legalentityName?.labelDE;
    } else {
      return '';
    }
  }

  public loadEntities(): void {
    this.listsService.GetLegalEntitiesList().subscribe((data) => {
      // Order data by labelDE. Ignore case and german umlauts
      data.sort((a, b) =>
        a.labelDE.localeCompare(b.labelDE, 'de', {
          sensitivity: 'base',
        })
      );

      data.forEach((item: DropDownStringItem) => {
        if (item.isDeleted) {
          item.labelDE = item.labelDE + ' (gelöscht)';
        }
      });


      this.legalEntitiesSource = data as DropDownStringItem[];
      this.legalEntities = this.legalEntitiesSource.slice();
      this.legalEntitiesStrings = this.legalEntities.map((item) => item.labelDE);
      // this.defaultItem = this.legalEntities[0];
    });


  }
}
