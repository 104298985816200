import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AssessmentType } from 'app/_models/assessment-type.model';
import { TestWorkflowForCreation, TestWorkflowItem } from 'app/_models/test-workflow.model';
import { retry } from 'rxjs/operators';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiTestDataService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Add a new test workflow entity.
   * @param item Test Workflow item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  addExampleWorkflow(item: TestWorkflowForCreation): Observable<TestWorkflowItem> {
    return this.http.post<TestWorkflowItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'TestData/AddTestcaseWorkflow?testCaseId=' +
      item.assessmentTypeId + '&withOcrResult=' +
      item.withOcrResult + '&wbsId=' +
      item.wbsId, HTTP_OPTIONS);
  }


  /**
   * Get all assessment Types.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAssessmentTypes(): Observable<AssessmentType[]> {
    return this.http.get<AssessmentType[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'TestData/GetAssessmentTypes', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

}