<form [formGroup]="form">
    <kendo-formfield showHints="always" showErrors="always">
        <!-- <kendo-floatinglabel [text]="label"> [class]="class" -->
        <div>
            <!-- (click)="stareInputFieldsService.handleUnselect()" -->
            <!-- <input (focus)="onFocusInputXbrl()" style="margin-top: -1px ; " type="checkbox" kendoCheckBox
                [id]="xbrlName+'-'+datasetId" [formControlName]="xbrlName" />
            <kendo-label style="margin-left: 5px ; color: #646262">{{label}}</kendo-label> -->
            <kendo-label style="margin-left: 5px ; padding-bottom: 15px ; color: #646262">{{labelTrue}}</kendo-label>
            &nbsp;
            <kendo-switch (valueChange)="onChange($event)" offLabel=" " onLabel=" " (focus)="onFocusInputXbrl()"
                [id]="xbrlName+'-'+datasetId"
                [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)"
                style=" padding-bottom: 5px ;" [checked]="currentValue"></kendo-switch>
            &nbsp;
            <kendo-label style="margin-left: 5px ; padding-bottom: 15px ; color: #646262">{{labelFalse}}</kendo-label>
            <div style="height: 10px; ">&nbsp;</div>
            <!-- </kendo-floatinglabel> -->
            <div [id]="'wrapper'+datasetId+'-'+xbrlName"
                style="padding-left: 10px; padding-right: 10px; margin-top: -5px"
                (click)="stareInputFieldsService.handleUnselect()" [class]="class"><app-validation-message-formcontrol
                    [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol> </div>
        </div>
    </kendo-formfield>
</form>