
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataTypeItem } from 'app/_models/data-type.model';

import {
  DialogRef, DialogContentBase
} from "@progress/kendo-angular-dialog";
import { ListsService } from 'app/_services/lists.service';


@Component({
  selector: 'archive-reopen-dialog',
  templateUrl: './archive-reopen-dialog.component.html',
  styleUrls: ['./archive-reopen-dialog.component.scss'],
  standalone: false
})
export class ArchiveReopenDialogComponent extends DialogContentBase implements OnInit {

  public commentValue: string = '';
  public types: DataTypeItem[] = [];

  @Output() public submitClick: EventEmitter<void> = new EventEmitter();

  constructor(
    public dialog: DialogRef,
    private listsService: ListsService
  ) { super(dialog); }

  ngOnInit(): void {

  }

  /** Return the german label of a xbrl name */
  public getXbrlLabel(xbrlName: string): string {
    const xbrlType = this.listsService.xbrlTypes.find(x => x.name == xbrlName);
    if (xbrlType) {
      return xbrlType.labelDE ?? 'Empty labelDE: ' + xbrlType.name;
    }
    return 'Not found: ' + xbrlName;
  }
  submit(): void {
    this.submitClick.emit();
    this.dialog.close();
  }


}
