import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileItem } from 'app/_models/file.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiFileMaintenanceService {


  constructor(
    private http: HttpClient) { }


  /**
   * Get all File items.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllFiles(): Observable<FileItem[]> {
    return this.http.get<FileItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'File/GetAllFilesAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

}
