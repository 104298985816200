export const ENVIRONMENT = {
	production: false,
	apiBaseUrl: 'https://api.taxprocessingflow.dev.pwc.de/',
	maintenanceApiBaseUrl: 'https://maintenance.taxprocessingflow.dev.pwc.de/',
	cookieDomain: '.taxprocessingflow.dev.pwc.de',
	primaryDomain: 'https://taxprocessingflow.dev.pwc.de',
	authTokenCookieName: 'STARE_Authorization_Token',
	authStateCookieName: 'STARE_Authorization_State',
	envBadge: 'dev',
	appInsightsConnectionString: 'InstrumentationKey=9cb132c1-98c4-47b1-a3d7-216d5c0f8129;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=7757edf7-f18b-4f4b-b03f-d1f9517f53e0',
	hangfireUrl: 'https://api.taxprocessingflow.dev.pwc.de/hangfire',
};
