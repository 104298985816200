<div class="stareTopic">
	{{ 'APP.TASKS.TITLE' | translate }}
</div>


<div [ngStyle]="{'height.px': pageHeight}">
	<kendo-grid id="grid" #grid [kendoGridBinding]="tableTasksDataFiltered" scrollable="scrollable"
		[style.height]="'100%'" filterable="menu" [filter]="gridSettings.state.filter || {logic: 'and', filters: []}"
		[columnMenu]="menuSettings" [loading]="!finishedLoading" [pageSize]="gridSettings.state.take || 10"
		[skip]="gridSettings.state.skip || 0" [pageable]="{
			buttonCount: 7,
			info: true,
			type: 'numeric',
			pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'}" [sortable]="sortSettings" [sort]="gridSettings.state.sort || []"
		[selectable]="{ checkboxOnly: false, mode: 'single' }" [reorderable]="true" [resizable]="true"
		[size]="smallSize" (dataStateChange)="dataStateChange($event, grid)" (dblclick)="openFile(grid.activeRow)"
		(cellClick)="cellClickHandler($event)" (columnReorder)="saveGridSettings(grid)"
		(columnResize)="saveGridSettings(grid)" (columnVisibilityChange)="saveGridSettings(grid)" [navigable]="true"
		[rowClass]="rowClass" (pageChange)="pageChange($event)">
		<ng-template kendoGridToolbarTemplate>

			<kendo-textbox [placeholder]="'APP.TASKS.SEARCHALL' | translate" (valueChange)="onFilter($event, grid)"
				[(ngModel)]="currentFilterVal" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
			<button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
				title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

			</button>
			<kendo-grid-spacer></kendo-grid-spacer>

			<div kendoTooltip position="right" filter="button" [titleTemplate]="titleTemplateCheck"
				[tooltipTemplate]="templateCheck" [tooltipWidth]="400" [hidden]="!currentUser?.isAdmin">
				<ng-template #titleTemplateCheck let-anchor>
					{{ qualityCheckTooltip }}
				</ng-template>
				<button kendoButton (click)="goToQualityCheck()" [svgIcon]="qualityCheckButtonSVG"
					[themeColor]="qualityCheckButtonColor" type="button" rounded=full>
				</button>
				<ng-template #templateCheck let-anchor>
					<pre>{{ 'BUTTON.QUALITY_CHECK' | translate }}</pre>
				</ng-template>

			</div>
			<!--
			<kendo-label text="{{ 'BUTTON.TOGGLE_TASKS' | translate }}">
				<kendo-switch [(ngModel)]="showHiddenTasks" (valueChange)="onTasksToggleChange()"></kendo-switch>
			</kendo-label>
			-->
			<button kendoButton (click)="openFileUploadModal()" type="button" [svgIcon]="uploadSVG" kendoTooltip
				title="{{ 'APP.TASKS.MODAL.FILE_UPLOAD.TITLE' | translate }}">

			</button>
			&nbsp;|&nbsp;


			<button kendoButton (click)="removeSettings()" type="button" [svgIcon]="settingsClearSVG" kendoTooltip
				title="{{ 'BUTTON.RESET_TABLE' | translate }}">

			</button>


			<button kendoButton (click)="loadTasks()" type="button" [svgIcon]="reloadSVG" kendoTooltip
				title="{{ 'BUTTON.UPDATE' | translate }}">

			</button>
			<!--
			<button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
				title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

			</button>
			
			
			 <button kendoButton (click)="resetTableSettings($event)">
			{{ 'BUTTON.RESET_TABLE' | translate }}
		</button> -->
		</ng-template>
		<!--Check box nt in use (need e-mail management first)
	<kendo-grid-checkbox-column [width]="45" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }"
		[resizable]="false" [columnMenu]="false" [showSelectAll]="true"></kendo-grid-checkbox-column>
		-->
		<kendo-grid-messages [pagerPage]="'APP.TASKS.TABLE.PAGER.PAGE' | translate"
			[pagerOf]="'APP.TASKS.TABLE.PAGER.OF' | translate" [pagerItems]="'APP.TASKS.TABLE.PAGER.ITEM' | translate"
			[pagerItemsPerPage]="'APP.TASKS.TABLE.PAGER.TASK_PER_PAGE' | translate"
			[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
			[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
			[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
			[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
			[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
			[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
			[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
			[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
			[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
			[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate" [filterClearButton]="'BUTTON.CLEAR' | translate"
			[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
			[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
			[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
			[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
			[filterFilterButton]="'BUTTON.FILTER' | translate"
			[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
			[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
			[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
			[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
			[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
			[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
			[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate" [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
			[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
			[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
			[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
			[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
			[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
			[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
			[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
			[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
			[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
			[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
		</kendo-grid-messages>

		<kendo-grid-column *ngFor="let col of gridSettings.columnsConfig" [field]="col.field"
			[title]="col.titleToDisplay || ''" [filter]="col.filter || 'text'" [filterable]="col.filterable"
			[sortable]="col.sortable" [format]="col.format" [hidden]="col.hidden || false" [minResizableWidth]="150"
			[width]="col.width || 150">
			<ng-template kendoGridCellTemplate let-dataItem let-displayComments *ngIf="(col.field == 'comment')">
				<div *ngIf="dataItem.hasComments">
					<div kendoTooltip position="right" filter="span" [titleTemplate]="titleTemplate"
						[tooltipTemplate]="template" [tooltipWidth]="400">
						<ng-template #titleTemplate let-anchor>
							{{ 'APP.TASKS.TABLE.HEADER.COMMENT' | translate }}
						</ng-template>
						<!-- <span style="font-size: small;">{{ dataItem.commentsCount }}x&nbsp;</span> -->
						<ng-template #template let-anchor>
							<div [innerHTML]="dataItem.workflowCommentAsString"></div>
						</ng-template>
						<span class="k-icon k-font-icon k-i-comment"></span>

					</div>
				</div>
			</ng-template>
		</kendo-grid-column>
		<ng-template kendoGridNoRecordsTemplate>

			<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
		</ng-template><!--
		 
		
		<kendo-grid-excel fileName="Aufgaben.xlsx" [fetchData]="allData"></kendo-grid-excel>
-->
	</kendo-grid>
</div>

<kendo-dialog *ngIf="active" [width]="600" [height]="450" (close)="closeForm()">
	<kendo-dialog-titlebar>
		{{'BUTTON.ADD' | translate }}
	</kendo-dialog-titlebar>
	<kendo-label for="selectedWBS">
		<span class="plainText">
			WBS
		</span>
		<br>
		<kendo-dropdownlist fillMode="solid" style=" width: 300px;" id="selectedWBS" [data]="wbsList" textField="wbs"
			valueField="id" (selectionChange)="onSelectWbsItem($event)" [(ngModel)]="selectedWBS"></kendo-dropdownlist>
	</kendo-label>
	<kendo-upload [saveUrl]="uploadSaveUrl" saveMethod="POST" [withCredentials]="true" [restrictions]="myRestrictions"
		[autoUpload]="false" [multiple]="false" [disabled]="noWBSSelected" (error)="uploadFailed($event)"
		(success)="uploadSuccess()">
		<kendo-upload-messages [dropFilesHere]="'UPLOADER_MENU.DROP_FILES' | translate"
			[select]="'UPLOADER_MENU.SELECT' | translate" [uploadSelectedFiles]="'UPLOADER_MENU.UPLOAD' | translate"
			[clearSelectedFiles]="'UPLOADER_MENU.CLEAR' | translate"
			[invalidFileExtension]="'UPLOADER_MENU.INVALID_TYPE' | translate"
			[fileStatusFailed]="'UPLOADER_MENU.STATUS_FAILED' | translate"
			[fileStatusUploaded]="'UPLOADER_MENU.STATUS_UPLOADED' | translate"
			[headerStatusUploaded]="'UPLOADER_MENU.HEADER_STATUS_UPLOADED' | translate">
		</kendo-upload-messages>
	</kendo-upload>
	<div class="plainText">
		{{ 'APP.TASKS.MODAL.FILE_UPLOAD.DMS_MESSAGE' | translate }}
	</div>

</kendo-dialog>