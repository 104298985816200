import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-rateable-value',
  templateUrl: './doc-rateable-value.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocRateableValueComponent extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.rateableValueForm;
  }




  //#region Form ======================================================================================================


  public rateableValueForm = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    document_taxDetermination: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    realEstate_rateableValue: new UntypedFormControl(""), //referenz
    document_realEstate_rateableValue: new UntypedFormControl(""),
    document_withoutEffectiveDateRule: new UntypedFormControl(""),
    document_hasThresholdLimit: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""),
    realEstate_effectiveDate: new UntypedFormControl(""), //referenz stichtag
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    id_legalEntityKeyReference: new UntypedFormControl(""),//referenz legalentity
    document_isLeaseHold: new UntypedFormControl(""),
    id_communeAGS: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_missingBankAccountDetails: new UntypedFormControl(""),

    id_notificationName: new UntypedFormControl(""),

  });


  //#endregion



  addSingularPayload(): void {
    //no payload 
  }


}
