<div class="stareTopic">
	{{ 'APP.ADMINISTRATION.COMMUNE.TITLE' | translate }}
</div>

<div>
	<ng-template #toolTipGridTemplate let-anchor>
		<span>{{ anchor.nativeElement.innerText }}</span>
	</ng-template>
	<div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
		(mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
		[ngStyle]="{'height.px': pageHeight}">
		<kendo-grid #grid [kendoGridBinding]="tableDataOriginal" scrollable="scrollable" [style.height]="'100%'"
			filterable="menu" [loading]="!finishedLoading" (dblclick)="editHandler(grid.activeRow)"
			(cellClick)="cellClickHandler($event)" [sort]="currentSort" (sortChange)="sortChange($event)"
			(add)="addHandler()" [filter]="currentFilter || {logic: 'and', filters: []}"
			[selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
			[pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true">

			<ng-template kendoGridToolbarTemplate>
				<kendo-textbox [placeholder]="'APP.ADMINISTRATION.COMMUNE.TABLE.SEARCH' | translate"
					(valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
				<button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
					title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

				</button>
				<kendo-grid-spacer></kendo-grid-spacer>

				<button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
					title="{{ 'BUTTON.ADD' | translate }}">
				</button>
				&nbsp;|&nbsp;



				<button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
					title="{{ 'BUTTON.UPDATE' | translate }}">
				</button>
				<button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
					title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

				</button>

			</ng-template>

			<kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.COMMUNE.TABLE.PAGER.PAGE' | translate"
				[pagerOf]="'APP.ADMINISTRATION.COMMUNE.TABLE.PAGER.OF' | translate"
				[pagerItems]="'APP.ADMINISTRATION.COMMUNE.TABLE.PAGER.ITEM' | translate"
				[pagerItemsPerPage]="'APP.ADMINISTRATION.COMMUNE.TABLE.PAGER.TASK_PER_PAGE' | translate"
				[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
				[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
				[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
				[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
				[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
				[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
				[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
				[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
				[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
				[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
				[filterClearButton]="'BUTTON.CLEAR' | translate"
				[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
				[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
				[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
				[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
				[filterFilterButton]="'BUTTON.FILTER' | translate"
				[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
				[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
				[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
				[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
				[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
				[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
				[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
				[filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
				[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
				[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
				[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
				[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
				[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
				[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
				[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
				[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
				[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
				[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
			</kendo-grid-messages>



			<kendo-grid-column field="id" [title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.ID' | translate"
				[hidden]="true"></kendo-grid-column>
			<kendo-grid-column field="name"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.NAME' | translate"></kendo-grid-column>
			<kendo-grid-column field="nameEN" [title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.NAME_EN' | translate"
				[hidden]="true"></kendo-grid-column>
			<kendo-grid-column field="textBlockIssuerDE"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.ISSUER_DE' | translate"
				[hidden]="true"></kendo-grid-column>
			<kendo-grid-column field="textBlockIssuerEN"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.ISSUER_EN' | translate"
				[hidden]="true"></kendo-grid-column>
			<kendo-grid-column field="ags"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.AGS' | translate"></kendo-grid-column>
			<kendo-grid-column field="postalCode"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.POSTAL_CODE' | translate"></kendo-grid-column>
			<kendo-grid-column field="city"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.CITY' | translate"></kendo-grid-column>
			<kendo-grid-column field="federalStateForDisplay"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.STATE' | translate"></kendo-grid-column>
			<kendo-grid-column field="countryForDisplay"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.COUNTRY' | translate"></kendo-grid-column>
			<kendo-grid-column field="workflowList" [filterable]="false"
				[title]="'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.TAXRATE' | translate">
				<ng-template kendoGridCellTemplate let-dataItem let-displayTaxRate>

					<div kendoTooltip position="right" filter="span" [titleTemplate]="titleTemplate"
						[tooltipTemplate]="template" [tooltipWidth]="400">
						<ng-template #titleTemplate let-anchor>
							<!-- {{ 'APP.ADMINISTRATION.COMMUNE.TABLE.HEADER.TAXRATE' | translate }} -->
						</ng-template>
						<!-- <span style="font-size: small;">{{ dataItem.taxRateCount }}x&nbsp;</span> -->
						<ng-template #template let-anchor>
							<!-- <pre>{{ dataItem.displayTaxRate }}</pre> -->
						</ng-template>
						<!-- <span class="k-icon k-font-icon k-i-file-txt k-i-txt"></span> -->
						<!-- for inline eddit of tax rate-->
						<!-- [disabled]="dataItem.taxRateCount==0 ? true : false" -->
						<button kendoButton [svgIcon]="editSVG" size="small" type="button" style="margin-left: 10px;"
							(click)="editTaxRateHandler(dataItem)" kendoTooltip
							title="{{ 'BUTTON.EDIT' | translate }}"></button>
						<button kendoButton [svgIcon]="addSVG" size="small" type="button" style="margin-left: 10px;"
							(click)="addTaxRateHandler(dataItem)" kendoTooltip
							title="{{ 'BUTTON.ADD' | translate }}"></button>
					</div>
				</ng-template></kendo-grid-column>


			<ng-template kendoGridNoRecordsTemplate>

				<p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
			</ng-template>

			<kendo-grid-excel fileName="Gemeinden.xlsx" [fetchData]="allData"></kendo-grid-excel>

		</kendo-grid>
	</div>
</div>


<kendo-dialog *ngIf="active" [width]="450" [height]="600" (close)="closeForm()">
	<kendo-dialog-titlebar *ngIf="isNew">
		{{'BUTTON.ADD' | translate }}
	</kendo-dialog-titlebar>

	<kendo-dialog-titlebar *ngIf="!isNew">
		{{'BUTTON.EDIT' | translate}}
	</kendo-dialog-titlebar>

	<form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
		<kendo-formfield>
			<kendo-label [for]="name" [text]="'APP.ADMINISTRATION.COMMUNE.MODAL.NAME.LABEL' | translate"></kendo-label>
			<kendo-textbox #name formControlName="name"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.NAME.PLACEHOLDER' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.NAME.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="nameEN"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.NAME_EN.LABEL' | translate"></kendo-label>
			<kendo-textbox #nameEN formControlName="nameEN"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.NAME_EN.PLACEHOLDER' | translate"></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.NAME_EN.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="ags" [text]="'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #ags formControlName="ags" [min]="0" [max]="99999999" [format]="'#'" [decimals]="0"
				[spinners]="false" [placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.PLACEHOLDER' | translate"
				[readonly]="!isNew" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="postalCode"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.POSTAL_CODE.LABEL' | translate"></kendo-label>
			<kendo-textbox #postalCode formControlName="postalCode" [maxlength]="5"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.POSTAL_CODE.PLACEHOLDER' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.POSTAL_CODE.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="textBlockIssuerDE"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_DE.LABEL' | translate"></kendo-label>
			<kendo-textbox #textBlockIssuerDE formControlName="textBlockIssuerDE"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_DE.PLACEHOLDER' | translate"></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_DE.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="textBlockIssuerEN"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_EN.LABEL' | translate"></kendo-label>
			<kendo-textbox #textBlockIssuerEN formControlName="textBlockIssuerEN"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_EN.PLACEHOLDER' | translate"></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.ISSUER_EN.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>



		<kendo-formfield>
			<kendo-label [for]="city" [text]="'APP.ADMINISTRATION.COMMUNE.MODAL.CITY.LABEL' | translate"></kendo-label>
			<kendo-textbox #city formControlName="city"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.CITY.PLACEHOLDER' | translate"
				required></kendo-textbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.CITY.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="federalState"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.STATE.LABEL' | translate"></kendo-label>

			<kendo-dropdownlist #federalState [popupSettings]="{ width: 400, height: 100 }"
				[data]="federalStateSourceData" textField="name" valueField="id" [valuePrimitive]="true"
				formControlName="federalState" required>
			</kendo-dropdownlist>

			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.STATE.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>
		<kendo-formfield>
			<kendo-label [for]="country"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.COUNTRY.LABEL' | translate"></kendo-label>
			<kendo-dropdownlist #country [popupSettings]="{ width: 400, height: 100 }" [data]="countrySourceData"
				textField="name" valueField="id" [valuePrimitive]="true" formControlName="country" required>
			</kendo-dropdownlist>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.COUNTRY.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

	</form>

	<kendo-dialog-actions layout="start">
		<button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
			{{ 'BUTTON.DELETE' | translate }}
		</button>
		<button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
			{{ 'BUTTON.SAVE' | translate }}
		</button>
		<button kendoButton (click)="onCancel($event)">
			{{ 'BUTTON.CANCEL' | translate }}
		</button>
	</kendo-dialog-actions>
</kendo-dialog>


<!--Dialog for TaxRate display-->
<kendo-dialog *ngIf="activeTaxRateDisplay" [width]="1000" [height]="1000" (close)="closeTaxRateDisplay()">
	<kendo-dialog-titlebar>
		{{'BUTTON.EDIT' | translate }}
	</kendo-dialog-titlebar>
	<kendo-grid #gridTaxRate [kendoGridBinding]="taxRateDataForSelectedCommune" scrollable="scrollable"
		[style.height]="'100%'" [filterable]="true" [loading]="!finishedLoading"
		(dblclick)="updateTaxRateHandler(gridTaxRate.activeRow)" (cellClick)="cellClickHandler($event)"
		[filter]="currentTaxRateFilter || {logic: 'and', filters: []}"
		[selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
		[pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [pageSize]="pageSize" [sortable]="true" [sort]="currentTaxSort" (sortChange)="sortTaxChange($event)">
		<ng-template kendoGridToolbarTemplate>

			<button kendoButton (click)="addTaxRateHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
				title="{{ 'BUTTON.ADD' | translate }}">
			</button>

		</ng-template>
		<kendo-grid-messages [pagerPage]="'APP.ADMINISTRATION.RATE.TABLE.PAGER.PAGE' | translate"
			[pagerOf]="'APP.ADMINISTRATION.RATE.TABLE.PAGER.OF' | translate"
			[pagerItems]="'APP.ADMINISTRATION.RATE.TABLE.PAGER.ITEM' | translate"
			[pagerItemsPerPage]="'APP.ADMINISTRATION.RATE.TABLE.PAGER.TASK_PER_PAGE' | translate"
			[columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
			[autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
			[autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
			[columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
			[filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
			[filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
			[filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
			[filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
			[filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
			[filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate" [filterClearButton]="'BUTTON.CLEAR' | translate"
			[filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
			[filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
			[filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
			[filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
			[filterFilterButton]="'BUTTON.FILTER' | translate"
			[filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
			[filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
			[filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
			[filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
			[filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
			[filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
			[filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate" [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
			[filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
			[filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
			[filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
			[filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
			[filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
			[filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
			[noRecords]="'TABLE_MENU.NO_RECORD' | translate"
			[setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
			[sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
			[sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
		</kendo-grid-messages>
		<kendo-grid-column field="ags"
			[title]="'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.LABEL' | translate"></kendo-grid-column>
		<kendo-grid-column field="year"
			[title]="'APP.ADMINISTRATION.COMMUNE.MODAL.YEAR.LABEL' | translate"></kendo-grid-column>
		<kendo-grid-column field="propertyTaxA"
			[title]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_A.LABEL' | translate"></kendo-grid-column>
		<kendo-grid-column field="propertyTaxB"
			[title]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_B.LABEL' | translate"></kendo-grid-column>
		<kendo-grid-column field="tradeRate"
			[title]="'APP.ADMINISTRATION.COMMUNE.MODAL.TRADE_RATE.LABEL' | translate"></kendo-grid-column>


	</kendo-grid>


	<!-- for inline edit of tax rate-->
	<!-- <kendo-dialog-actions layout="start">
		<button kendoButton (click)="onCancelTaxRateDisplay($event)">
			{{ 'BUTTON.CLOSE' | translate }}
		</button>
	</kendo-dialog-actions> -->
</kendo-dialog>



<!--Dialog for inline edit TaxRate-->
<kendo-dialog *ngIf="activeTaxRate" [width]="450" [height]="600" (close)="closeTaxRateForm()">
	<kendo-dialog-titlebar>
		{{'BUTTON.EDIT' | translate }}
	</kendo-dialog-titlebar>

	<form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editTaxRateForm">

		<kendo-formfield>
			<kendo-label [for]="ags" [text]="'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #ags formControlName="ags" [min]="0" [max]="99999999" [format]="'#'" [decimals]="0"
				[spinners]="false" [placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.PLACEHOLDER' | translate"
				[tabindex]="-1" [readonly]="true" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.AGS.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="year" [text]="'APP.ADMINISTRATION.COMMUNE.MODAL.YEAR.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #year formControlName="year" [min]="0" [max]="99999999" [format]="'#'" [decimals]="0"
				[spinners]="false" [placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.YEAR.PLACEHOLDER' | translate"
				required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.YEAR.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="propertyTaxA"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_A.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #propertyTaxA formControlName="propertyTaxA" [min]="0" [max]="99999999" [format]="'#'"
				[decimals]="0" [spinners]="false"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_A.PLACEHOLDER' | translate" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_A.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="propertyTaxB"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_B.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #propertyTaxB formControlName="propertyTaxB" [min]="0" [max]="99999999" [format]="'#'"
				[decimals]="0" [spinners]="false"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_B.PLACEHOLDER' | translate" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.TAX_B.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

		<kendo-formfield>
			<kendo-label [for]="tradeRate"
				[text]="'APP.ADMINISTRATION.COMMUNE.MODAL.TRADE_RATE.LABEL' | translate"></kendo-label>
			<kendo-numerictextbox #tradeRate formControlName="tradeRate" [min]="0" [max]="99999999" [format]="'#'"
				[decimals]="0" [spinners]="false"
				[placeholder]="'APP.ADMINISTRATION.COMMUNE.MODAL.TRADE_RATE.PLACEHOLDER' | translate" required>
			</kendo-numerictextbox>
			<kendo-formerror>{{'APP.ADMINISTRATION.COMMUNE.MODAL.TRADE_RATE.ERROR.REQUIRED' |
				translate}}</kendo-formerror>
		</kendo-formfield>

	</form>


	<!-- for inline eddit of tax rate-->
	<kendo-dialog-actions layout="start">
		<button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDeleteTaxRate($event)">
			{{ 'BUTTON.DELETE' | translate }}
		</button>
		<button kendoButton themeColor="primary" [disabled]="!editTaxRateForm.valid" (click)="onSaveTaxRate($event)">
			{{ 'BUTTON.SAVE' | translate }}
		</button>
		<button kendoButton (click)="onCancelTaxRate($event)">
			{{ 'BUTTON.CANCEL' | translate }}
		</button>
	</kendo-dialog-actions>
</kendo-dialog>