<form [formGroup]="valueAddedTaxForm" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <app-stare-toggle-dropdown-notice-name [isArchiv]="actualStateId == 9000"
                (onFieldFocus)="onFieldFocusReceiver()" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="valueAddedTypeOptions"
                xbrlNameToggle="document_notificationType" xbrlNameDropdown="id_notificationName"
                [documentType]="datatypeName">
            </app-stare-toggle-dropdown-notice-name>
            <!-- <div class="row">
                <div class="column-for-text-area">
                    <app-stare-radio-button [isArchiv]="actualStateId==9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_notificationType"
                        [datasetId]='headerDatasetId' [form]="valueAddedTaxForm" [isReadOnly]="false"
                        [workflowType]='workflowType' [dataOptionsSource]="valueAddedTypeOptions">
                    </app-stare-radio-button>
                </div>
            </div>
            <div class="row" *ngIf="valueAddedTaxForm.controls['document_notificationType'].value == 1">
                <div class="column-for-text-area">
                    <app-stare-dropdown-notice-name [documentType]="datatypeName" [isArchiv]="actualStateId==9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationName"
                        [datasetId]='headerDatasetId' [form]="valueAddedTaxForm" [isReadOnly]="false"
                        [workflowType]='workflowType' [selection]="1">
                    </app-stare-dropdown-notice-name>
                </div>
            </div>
            <div class="row" *ngIf="valueAddedTaxForm.controls['document_notificationType'].value != 1">
                <div class="column-for-text-area">
                    <app-stare-dropdown-notice-name [documentType]="datatypeName" [isArchiv]="actualStateId==9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationName"
                        [datasetId]='headerDatasetId' [form]="valueAddedTaxForm" [isReadOnly]="false"
                        [workflowType]='workflowType' [selection]="2">
                    </app-stare-dropdown-notice-name>
                </div>
            </div> -->
            <div class="row">
                <div class="column">
                    <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_companyIdentifier" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>

                <div class="column">
                    <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="reference_companyIdentifier" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType" overrideLabel="Referenz: Steuernummer">
                    </app-stare-textbox>
                </div>
            </div>
            <div class="row">
                <!-- <div [class]="
                        workflowType == 11 && !(actualStateId == 9000)
                            ? 'column-for-with-plus'
                            : 'column'
                    "> -->
                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="valueAddedTaxForm" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>

                <!-- <div class="column-for-plus" *ngIf="workflowType == 11 && !(actualStateId == 9000)">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div> -->
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_notificationDate" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
                <div class="column">
                    <app-stare-integer [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_assessmentYear" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <!-- <div class="column">
                    <app-stare-dropdown [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_taxationBases" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="taxbaseOptions">
                    </app-stare-dropdown>
                </div> -->
                <!-- </div>
            <div class="row-for-checkbox" *ngIf="workflowType==11"> -->
                <div class="column-for-checkbox" *ngIf="workflowType == 11">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header" style="width: 1030px">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <!-- TABLE ROWS: 9 ROWS and 5 COLUMNS-->
            <div class="row" style="padding-top: 25px">
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Umsatzsteuer
                </div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Verspätungszuschlag
                </div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Zinsen
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Festsetzung (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_delay_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_interest_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>

            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Festsetzung (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_interest_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Anzurechnend (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Anzurechnend (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Bereits gezahlt (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_delay_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_interest_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Bereits gezahlt (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_delay_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_interest_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Fälligkeit (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_toPaid" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_delay_toPaid" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_interest_toPaid" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Fälligkeit (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_toPaid" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_delay_toPaid" [datasetId]="headerDatasetId" [form]="valueAddedTaxForm"
                        [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="valueAddeddTax_interest_toPaid" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_dueDate" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_valueAddeddTax_sum_toPaid" [datasetId]="headerDatasetId"
                        [form]="valueAddedTaxForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Weitere Felder
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 660px;
                                        margin-top: -26px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Weitere Felder
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-textbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_name" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_value" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="document_dynamic_referenceValue" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>

                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" [disabled]="
                                        (actualStateId == 9000 &&
                                            !requestedByAdmin()) ||
                                        (actualStateId == 9000 &&
                                            requestedByAdmin() &&
                                            !isUnlocked())
                                    " (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentDynamicFields'
                                        )
                                    ">
                                    Feld löschen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" [disabled]="
                            (actualStateId == 9000 && !requestedByAdmin()) ||
                            (actualStateId == 9000 &&
                                requestedByAdmin() &&
                                !isUnlocked())
                        " (click)="addPayload('DocumentDynamicFields')">
                        Feld hinzufügen
                    </button>
                </div>
            </div>
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="valueAddedTaxForm" [auditResults]="auditResults" [workflowId]="workflowId"
                [is2000]="actualStateId == 2000" [isArchiv]="actualStateId == 9000"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>