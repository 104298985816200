import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserAccessItem, UserAccessForCreationItem } from 'app/_models/user-access.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiUserAccessService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Get all user access items
   */
  getAllAccessItems(): Observable<UserAccessItem[]> {
    return this.http.get<UserAccessItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserAccessManagement/GetAllAsync', HTTP_OPTIONS);
  }

  /**
   * Add (or update) user access items
   */
  getAddAccessItems(item: UserAccessForCreationItem): Observable<UserAccessItem> {
    return this.http.post<UserAccessItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserAccessManagement/AddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Delete a user access items
   */
  getDeleteAccessItems(id: number): Observable<UserAccessItem> {
    return this.http.delete<UserAccessItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserAccessManagement/DeleteAsync/' + id, HTTP_OPTIONS);
  }
  /**
    * Get all user access items
    */
  getAccessItemsByMail(mail: string): Observable<UserAccessItem[]> {
    return this.http.get<UserAccessItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'UserAccessManagement/GetByMailAsync?mail=' + mail, HTTP_OPTIONS);
  }



}