import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { ReleaseNotesModel } from 'app/_models/release-note.model';
import { ApiMaintenanceService } from 'app/_services/StareApiMaintenance/maintenance.service';



@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./faq.component.scss'],
  standalone: false
})
export class FaqComponent implements OnInit {



  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiMaintenanceService: ApiMaintenanceService
  ) { }

  public releaseNotesText: string = '';


  ngOnInit(): void {
    this.translateService.get('APP.FAQ.TAB').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });


    this.receivedReleaseNotes()
  }


  public tableAssessmentTypes: { taxtype: string, assessmenttype: string, iteration: string }[] = [{
    taxtype: "Immobiliensteuer",
    assessmenttype: "Einheitswert",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Immobiliensteuer",
    assessmenttype: "Grundsteuermessbetrag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Immobiliensteuer",
    assessmenttype: "Kombination Einheitswert und Grundsteuermessbetrag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Immobiliensteuer",
    assessmenttype: "Grundsteuer (alt)",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Gewerbesteuer",
    assessmenttype: "Gewerbesteuerverlustvortrag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Gewerbesteuer",
    assessmenttype: "Gewerbesteuermessbetrag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Gewerbesteuer",
    assessmenttype: "Gewerbesteuerzerlegung",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Gewerbesteuer",
    assessmenttype: "Gewerbesteuer",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Körperschaftsteuer",
    assessmenttype: "Bescheid für KSt & Solidaritätszuschlag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Körperschaftsteuer",
    assessmenttype: "Vorauszahlungsbescheid über KSt & Solidaritätszuschlag",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Körperschaftsteuer",
    assessmenttype: "Bescheid über gesonderte Feststellung von Besteuerungsgrundlagen nach § 27 Abs. 2 KStG und § 28 Abs. 1",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Körperschaftsteuer",
    assessmenttype: "Bescheid über die gesonderte Feststellung des verbleibenden Verlustvortrags zur Körperschaftsteuer",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Körperschaftsteuer",
    assessmenttype: "Bescheid über die gesonderte Feststellung des verbleibenden Zuwendungsvortrags",
    iteration: "STARE 2.0"
  },
  {
    taxtype: "Feststellungen bei Organschaftverhältnissen",
    assessmenttype: "Bescheid für 20xx über die gesonderte und einheitliche Feststellung des dem Organträger zuzurechnenden Einkommens der Organgesellschaft und damit zusammenhängender anderer Besteuerungsgrundlagen nach § 14 Abs. 5 KStG - Ausfertigung für die Organgesellschaft",
    iteration: "STARE 2.1"
  },
  {
    taxtype: "Gesonderte und einheitliche Feststellungen bei Personengesellschaften (GuE)",
    assessmenttype: "Bescheid über die gesonderte und einheitliche Feststellung von Besteuerungsgrundlagen und des verrechenbaren Verlustes",
    iteration: "STARE 2.1"
  },
  {
    taxtype: "IHK",
    assessmenttype: "IHK Beitragsbescheid ",
    iteration: "STARE 2.1"
  },
  {
    taxtype: "Strom- und Energiesteuer",
    assessmenttype: "Strom- und Energiesteuer Entlastungsantrag 1 ",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Strom- und Energiesteuer",
    assessmenttype: "Strom- und Energiesteuer Entlastungsantrag 2 ",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Strom- und Energiesteuer",
    assessmenttype: "Strom- und Energiesteuer Steueranmeldung ",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Feststellungserklärung ",
    assessmenttype: "n.a.",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Immobiliensteuer ",
    assessmenttype: "Grundsteuer (neu)",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Umsatzsteuer",
    assessmenttype: "n.a.",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Nach 01.01.2024",
    assessmenttype: "Feststellungserklärung",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Nach 01.01.2024",
    assessmenttype: "Grundsteuer (neu)",
    iteration: "STARE 2.2"
  },
  {
    taxtype: "Nach 01.01.2024",
    assessmenttype: "Umsatzsteuer",
    iteration: "STARE 2.2"
  }

  ]



  public tableFeatures: { feature: string, iteration: string }[] = [{
    feature: "Neue Benutzeroberfläche",
    iteration: "STARE 2.0"
  }, {
    feature: "Konfiguration der Einstellungen",
    iteration: "STARE 2.0"
  },
  {
    feature: "Verwaltung der Administration",
    iteration: "STARE 2.0"
  },
  {
    feature: "Bescheid Archiv",
    iteration: "STARE 2.0"
  },
  {
    feature: "Aufgabenübersicht",
    iteration: "STARE 2.0"
  },
  {
    feature: "Cloud Infrastruktur",
    iteration: "STARE 2.0"
  },
  {
    feature: "DMS Schnittstelle",
    iteration: "STARE 2.0"
  },
  {
    feature: "Anbindung an Azure Data Lake 2.0",
    iteration: "STARE 2.0"
  },
  {
    feature: "Benachrichtigung bei Aufgaben",
    iteration: "STARE 2.0"
  },
  {
    feature: "Power BI Dashboard mit den Prüfungsergebnissen der Bescheidprüfung",
    iteration: "STARE 2.0"
  },
  {
    feature: "Sicherer Datentransfer und Datenschutz",
    iteration: "STARE 2.0"
  },
  {
    feature: "Deutsche & Englische Sprachausgabe",
    iteration: "STARE 2.0"
  },

  {
    feature: "Kommentarfunktion",
    iteration: "STARE 2.0"
  },
  {
    feature: "Validierungs-Benachrichtigungen",
    iteration: "STARE 2.0"
  },
  {
    feature: "PDF Zoombar",
    iteration: "STARE 2.0"
  },
  {
    feature: "KPI Dashboard",
    iteration: "STARE 2.0"
  },
  {
    feature: "Regelmäßige Testings im Backend",
    iteration: "STARE 2.0"
  },
  {
    feature: "DATEV Schnittstelle",
    iteration: "STARE 2.1"
  },
  {
    feature: "Unternehmen selber verwalten",
    iteration: "STARE 2.1"
  },
  {
    feature: "Aktualisierungsmöglichkeit  der Referenzdaten",
    iteration: "STARE 2.1"
  },
  {
    feature: "E-Mail Generator zur Mandantenkommunikation",
    iteration: "STARE 2.1"
  },
  {
    feature: "E-Mail Generierung für fehlende Informationen",
    iteration: "STARE 2.1"
  },
  {
    feature: "Selbstverwaltung des Mandanten durch den Benutzer",
    iteration: "STARE 2.1"
  },
  {
    feature: "Gemeinden selber importieren",
    iteration: "STARE 2.1"
  },
  {
    feature: "Hebesätze selber importieren",
    iteration: "STARE 2.1"
  },
  {
    feature: "Tabellen Suchen",
    iteration: "STARE 2.1"
  },
  {
    feature: "Bescheid Suche",
    iteration: "STARE 2.1"
  },

  ]


  private receivedReleaseNotes(): void {

    this.apiMaintenanceService.getReleaseNotes().subscribe((data: ReleaseNotesModel) => {
      if (data.htmlText != null) {
        this.releaseNotesText = data.htmlText
      }
      else {
        this.releaseNotesText = 'Keine Release Notes vorhanden.'
      }

    });

  }

  goYoda(): void {

    window.open("https://yoda.pwc.de/pages/stare/apps/content/willkommen", "_blank");

  }

}