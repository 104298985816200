import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from 'environments/environment';

import { map, retry } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { DropDownListItem } from 'app/_models/dropdown-item.model';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { MyTaskItem } from 'app/_models/task.model';
import { FormFieldItem } from 'app/_models/form-field.model';
import { XbrlParserService } from '../xbrl-parser.service';
import { WorkflowJournalForUI } from 'app/_models/workflow-journal.model';
import { ITradeTaxInfoDto } from 'app/_models/trade-tax-info.model';
import { TaxNoticeNameItem } from 'app/_models/tax-notice-name.model';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class UIViewerService {

  public taxNumList: number[] = [1, 2, 4, 8, 16, 32];   //TODO: Wozu ist das gut?

  constructor(private http: HttpClient,
    private xbrlParserService: XbrlParserService,
  ) { }
  enumToBitValues(enumValue: object) {
    return Object.keys(enumValue).map(Number).filter(Boolean);
  }

  formValueToNumber(selection: AbstractControl<any, any>[]): string {
    let sum: number = 0;
    selection.forEach(element => {
      sum += element.value;               //TODO Achtung, Wert könnte technisch auch ein String sein!

    });
    return sum.toString();
  }
  numberToFormValue(val: number, taxdetList: DropDownListItem[]) {
    let values = this.taxNumList.map((b) => (val & b));
    let selectvalues: DropDownListItem[] = [];
    values.forEach(element => {
      let findData = taxdetList.find(x => x.value == element);
      if (findData != undefined)
        selectvalues.push(findData);
    });
    return selectvalues;
    //let sum = s.reduce((sum: any, p: any) => sum + p, 0);
  }
  /**
  * Get the list of entity types.
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getDropdownListItems(xbrlname: string): Observable<DropDownListItem[]> {
    xbrlname = this.xbrlParserService.xbrlUIParseToAPISingle(xbrlname);

    return this.http.get<DropDownListItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/' + xbrlname, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }
  /**
  * Get the list of entity types value type string.
  * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
  */
  getDropdownListStringItems(xbrlname: string): Observable<DropDownStringItem[]> {
    xbrlname = this.xbrlParserService.xbrlUIParseToAPISingle(xbrlname);

    return this.http.get<DropDownStringItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetDropDownList/' + xbrlname, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
* Get All Finished Tasks
* @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
*/
  getAllTasksFinishedWithHidden() {
    //return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksAsync?withHiddenWorkflows=true&onlyFinishedWorkflows=true`);
    return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksAsync?withHiddenWorkflows=true&onlyFinishedWorkflows=true`);
  }

  /**
* Get All Finished Tasks
* @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
*/
  getAllTasksWithHidden() {
    //return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksAsync?withHiddenWorkflows=true&onlyFinishedWorkflows=true`);
    return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksAsync?withHiddenWorkflows=true&onlyFinishedWorkflows=false`);
  }
  /**
* Get all Tasks.
* @returns Observable
*/
  getAllTasks() {
    return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksAsync`);
  }

  /**
* Get all Tasks.
* @returns Observable
* GetMyTasksOverallAsync?withHiddenWorkflows=false&onlyFinishedWorkflows=false
*/
  getAllTasksOverall(withHidden: boolean, onlyFinished: boolean) {
    return this.http.get<MyTaskItem[]>(`${ENVIRONMENT.apiBaseUrl}UIViewer/GetMyTasksOverallAsync?withHiddenWorkflows=` + withHidden + `&onlyFinishedWorkflows=` + onlyFinished);
  }

  /**
* Delivers a list of data values (with regions) to be displayed in the UI.
*/
  public getMyFields(workflowId: number, acutalStateId: number): Observable<FormFieldItem[]> {
    return this.http.get<FormFieldItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetMyFieldsAsync/' + workflowId + '/' + acutalStateId, HTTP_OPTIONS).pipe(
      map((data: FormFieldItem[]) => {
        for (let item of data) {
          item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName);
        }
        return data;
      }));
  }

  /**
* Get the journal of a workflow.
* @param workflowId Requested workflow ID
* @returns 
*/
  getJournalByWorkflowId(workflowId: number): Observable<WorkflowJournalForUI[]> {
    return this.http.get<WorkflowJournalForUI[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetWorkflowJourneyForUI/' + workflowId, HTTP_OPTIONS);
  }

  /**
 * API: Get all trade tax infos.
 */
  getAllTradeTaxInfo(workflowId: number): Observable<ITradeTaxInfoDto> {
    return this.http.get<ITradeTaxInfoDto>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTradeTaxInformation/' + workflowId + '?onlyFinished=true', HTTP_OPTIONS);
  }


  //   /**
  // * API: Get tax notices names based on dataType.
  // * UIViewer/GetTaxNoticeNames/DocumentRateableValue
  // */
  //   getTaxNoticeNames(dataType: string): Observable<TaxNoticeNameItem[]> {
  //     return this.http.get<TaxNoticeNameItem[]>(ENVIRONMENT.apiBaseUrl + 'UIViewer/GetTaxNoticeNames/' + dataType, HTTP_OPTIONS);
  //   }
}

