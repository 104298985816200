<div class="stareTopic">
    {{ 'APP.SETTINGS.EXPORT.TITLE' | translate }}
</div>


<span class="plainText">{{ 'APP.SETTINGS.EXPORT.BODY1' | translate }} <strong>{{
        getUser()?.mail
        }}</strong>
    {{ 'APP.SETTINGS.EXPORT.BODY2' | translate }}</span>

<br>
<br>

<kendo-dropdownbutton [data]="exportTypeList" themeColor="primary" textField="labelDE" valueField="id"
    (itemClick)="performDataExport($event)">
    Export ausw&auml;hlen und beauftragen
</kendo-dropdownbutton>
<!-- <br><br>
<kendo-dropdownlist #exportDropDown [data]="exportTypeList" (selectionChange)="onSelectExportType($event)"
    [defaultItem]="{ text: 'Auswahl ...', value: null }" textField="labelDE" valueField="id" [valuePrimitive]="true"
    required [popupSettings]="{
        width: 650,
        height: 150
      }" style="width: 350px;"></kendo-dropdownlist>

<ap-button #htmlBtnExport [label]="'BUTTON.EXPORT_DATA' | translate" [compact]="true"
    (onClick)="performDataExport(null)"></ap-button> -->

<br>
<br>
<br>
<br>


<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"
        [ngStyle]="{'height.px': pageHeight}">
        <kendo-grid #grid [kendoGridBinding]="attachmentsNodes" scrollable="scrollable" [style.height]="'100%'"
            filterable="menu" [sort]="currentSort" (sortChange)="sortChange($event)" [sortable]="true"
            [columnMenu]="menuSettings" [filter]="currentFilter || {logic: 'and', filters: []}"
            [loading]="!finishedLoading" (cellClick)="cellClickHandler($event)" [sortable]="true" [navigable]="true"
            [reorderable]="true" [resizable]="true" [pageable]="{
            buttonCount: 7,
         info: true,
         type: 'numeric',
          pageSizes: [20, 30, 50],
         previousNext: true,
         position: 'bottom'
       }" [pageSize]="pageSize">
            <ng-template kendoGridToolbarTemplate>
                <kendo-textbox [placeholder]="'APP.SETTINGS.EXPORT.SEARCH' | translate" (valueChange)="onFilter($event)"
                    [style.width.px]="300" [clearButton]="true"></kendo-textbox>
                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">
                </button>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="forceStartExport()" type="button" [svgIcon]="startExportSVG" kendoTooltip
                    title="Übermittelt die aktuellen Export-Dateien von STARE zum Datalake sofort. Sollte kein Export vorhanden sein, muss zuerst einer erstellt werden.">
                </button>
                &nbsp;|&nbsp;

                <button kendoButton (click)="loadExportList()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}">
                </button>

            </ng-template>
            <kendo-grid-messages [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
                [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
                [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
                [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
                [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
                [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
                [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
                [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
                [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
                [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
                [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
                [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
                [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
                [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"></kendo-grid-messages>
            <kendo-grid-column field="fileId" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.ID' | translate "></kendo-grid-column>
            <kendo-grid-column field="filename" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.NAME' | translate "></kendo-grid-column>
            <kendo-grid-column field="createdDate" format="dd.MM.yyyy" filter="date" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.CREATED_DATE' | translate "></kendo-grid-column>
            <kendo-grid-column field="pageCount" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.PAGES_COUNT' | translate "></kendo-grid-column>
            <kendo-grid-column field="filesize" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.SIZE' | translate "></kendo-grid-column>
            <kendo-grid-column field="sourceUrl" [hidden]="false"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.URL' | translate "></kendo-grid-column>

            <kendo-grid-column field="mailForSendingItemId" [hidden]="true"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.MAIL_ITEM_ID' | translate "></kendo-grid-column>
            <kendo-grid-column field="clientMailItemId" [hidden]="true"
                [title]=" 'APP.SETTINGS.EXPORT.TABLE.HEADER.MAIL_FOR_SEND' | translate "></kendo-grid-column>


            <kendo-grid-column [width]="100" [columnMenu]="false">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                    <button kendoButton type="button" [svgIcon]="downloadSVG" (click)="onClickEvent(grid.activeRow)">
                    </button>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>

                <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
            </ng-template>
        </kendo-grid>
    </div>
</div>
<!-- <kendo-listview [data]="attachmentsNodesTest" containerClass="k-d-flex k-flex-col k-flex-nowrap">
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" (onClick)="onClickEvent($event)">
        <span class="k-icon k-font-icon k-i-comment"></span>

        <span class="plainText"> {{ dataItem.title }} </span>

        <button kendoButton (click)="onClickEvent(dataItem)" fillMode="flat" type="button" size="small">
            <span class="plainText">{{ 'BUTTON.DOWNLOAD' | translate }}</span>
        </button>
    </ng-template>
</kendo-listview> -->
<!-- <ap-tree [data]="attachmentsNodes" (onClick)="onClickEvent($event)">
</ap-tree> -->