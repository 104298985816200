import {
    Component,
    OnInit,
    HostListener,
    ViewChild,

    ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
    ColumnMenuSettings,
    GridComponent,
    GridDataResult,
    SelectableSettings,
    SelectionEvent,
} from '@progress/kendo-angular-grid';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkflowItem } from 'app/_models/workflow.model';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';
import { ApiClientMailingService } from 'app/_services/StareApi/client-mailing.service';
import { DateTimeService } from 'app/_services/date-time.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { TooltipService } from 'app/_services/tooltip.service';
import { ListsService } from 'app/_services/lists.service';
import { DropDownStringItem } from 'app/_models/dropdown-item-string.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnsentMailSelectionItem } from 'app/_models/unsent-mail-selection-item.model';

@Component({
    selector: 'app-mail-selection',
    templateUrl: './mail-selection.component.html',
    styleUrls: ['./mail-selection.component.scss'],
    standalone: false
})
export class MailSelectionComponent implements OnInit {
    public unsentMails$: Observable<WorkflowItem[]> = of([]);
    public legalentities$: Observable<DropDownStringItem[]> = of([]);

    public selectedWorkflowIds: number[] = [];
    /** API request is finished */
    public finishedLoading = false;
    public skip = 0;
    public gridData: UnsentMailSelectionItem[] = [];
    public legalEntities: DropDownStringItem[] = [];
    public legalEntitiesSource: DropDownStringItem[] = [];

    public selectedLegalEntityKey: string | null = null;

    // == ToolTips ===========================================================
    @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;

    // =================================================================
    constructor(
        private translateService: TranslateService,
        private titleService: Title,
        private listsService: ListsService,
        private apiClientMailingService: ApiClientMailingService,
        private notificationService: WrapperNotificationService,
        private dateTimeService: DateTimeService,
        public tooltipService: TooltipService,
        private cdr: ChangeDetectorRef
    ) {
        this.loadData();
    }

    ngOnInit(): void {
        this.translateService
            .get('APP.MAILING.TITLE')
            .subscribe((title: string) => {
                this.titleService.setTitle('STARE | ' + title);
            });

        this.legalentities$ = this.listsService.GetLegalEntitiesListNew();
        this.listsService.GetLegalEntitiesList().subscribe((data) => {
            this.legalEntitiesSource = data as DropDownStringItem[];
            this.legalEntities = this.legalEntitiesSource.slice();
        });
        this.loadData();
    }

    // == Resizing the grid ==========================================================

    public pageHeight: number = window.innerHeight - 125;
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.pageHeight = window.innerHeight - 125;
        // console.log(this.pageHeight);
    }

    // == Load Grid Data ===================================================================

    loadData(): void {
        this.finishedLoading = false;
        /* this.unsentMails$ = this.apiClientMailingService.getAllUnsent().pipe(
            tap(() => (this.finishedLoading = true)),
            finalize(() => (this.finishedLoading = true))
        ); */
        this.apiClientMailingService.getAllUnsent().subscribe({
            next: (res: WorkflowItem[]) => {
                this.gridData = res.map((item) => {
                    // Create a new MailItem object
                    const mailItem = {} as UnsentMailSelectionItem;
                    // Copy properties that exist in both WorkflowItem and MailItem
                    Object.assign(mailItem, item);
                    return mailItem;
                });

                this.gridData.forEach((item: UnsentMailSelectionItem) => {
                    if (item.documentHeader?.documentDate) {
                        item.documentHeader.documentDate =
                            this.dateTimeService.convertUTCTimeToLocalTime(
                                item.documentHeader?.documentDate
                            )!;
                    }
                    if (item.documentHeader?.legalEntityKey) {
                        item.legalEntityKeyForDisplay =
                            this.getlegalentityReferenceNameforDisplay(
                                item.documentHeader?.legalEntityKey
                            );
                    }
                });

                //this.gridData = res;

                this.finishedLoading = true;
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
                this.finishedLoading = true;
            },
        });
    }

    // == Grid Settings ====================================================================

    public selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'multiple',
        drag: false,
    };

    // Pagination
    public pageSize = 20;

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.loadData();

        // Optionally, clear the selection when paging
        // this.mySelection = [];
    }

    //Settings for individual columns
    public menuSettings: ColumnMenuSettings = {
        lock: false,
        stick: false,
        view: 'tabbed',
        filter: true,
    };

    // Sorting
    public currentSort: SortDescriptor[] = [
        {
            field: 'documentHeader.documentDate',
            dir: 'desc',
        },
    ];

    public sortChange(sort: SortDescriptor[]): void {
        this.currentSort = sort;
        // this.loadData();
    }

    // Filtering

    public currentFilter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [],
    };

    // == CRUD Operations ==========================================================

    createMail(): void {
        if (!this.shouldEnableButton()) {
            //extra check
            return;
        }
        if (this.selectedWorkflowIds.length > 0) {
            //extra check
            this.finishedLoading = false;
            this.apiClientMailingService
                .postCreateMail(this.selectedWorkflowIds)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess(
                            ' Für die ausgewählten Bescheide wird die E-Mail erstellt und ist in Kürze in der Liste der Aufgaben verfügbar.'
                        );
                        this.loadData();
                        this.finishedLoading = true;
                    },
                    error: (err: HttpErrorResponse) => {
                        console.error(err);
                        if (err.status === 409) {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Alle Steuerpflichtige müssen gleich sein.`
                            );
                        } else if (err.status === 406) {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Eine E-Mail wird im Hintergrund gerade vorbereitet. Bitte in einigen Minuten erneut versuchen bzw. die Liste der Aufgaben prüfen.`
                            );
                        } else if (err.status === 412) {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Es wurden 'Nur Digitalisieren' Bescheide mit ausgewählt.`
                            );
                        } else if (err.status === 416) {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Eine E-Mail (zu mind. einem ausgewählten Bescheid) wurde bereits versendet.`
                            );
                        } else {
                            this.notificationService.showError(
                                `Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Nachricht: ${err.message}`
                            );
                        }

                        this.finishedLoading = true;
                    },
                    complete: () => {
                        // Nothing to do here
                        this.finishedLoading = true;
                    },
                });
        } else
            this.notificationService.showError(
                `Es sind keine Bescheide ausgewählt.`
            );
    }

    // == Helpers ================================================================================

    @ViewChild("grid", { static: false })
    grid!: GridComponent;

    getDateForDisplay(date: Date | null): Date | null {
        return this.dateTimeService.convertUTCTimeToLocalTime(date)!;
    }

    getlegalentityNameforDisplayObservable(guid: string): Observable<string> {
        return this.legalentities$.pipe(
            map((entities: DropDownStringItem[]) => {
                const entity = entities.find((entity) => entity.value === guid);
                return entity ? entity.labelDE : 'not found';
            })
        );
    }
    getlegalentityReferenceNameforDisplay(guid: string): string {
        const legalentityName = this.legalEntities.find(
            (item) => item.value === guid
        );
        if (legalentityName) {
            return legalentityName.labelDE;
        } else {
            return 'Unknown Entity';
        }
    }

    public onCheckboxChange(event: SelectionEvent): void {
        // Trigger change detection manually
        this.cdr.detectChanges();
        if (event?.selectedRows)
            if (event.selectedRows.length > 0) {
                const selectedRow = event.selectedRows[0];
                this.selectedLegalEntityKey =
                    selectedRow.dataItem.documentHeader.legalEntityKey;
            } else {
                //check if other selections exist
                if (this.selectedWorkflowIds.length == 0)
                    this.selectedLegalEntityKey = null;
            }
    }

    public rowCallback = (context: { dataItem: UnsentMailSelectionItem, index: number }): { [key: string]: boolean } => {
        return {
            'k-disabled': this.isRowDisabled(context.dataItem),
        };
    };

    public isRowDisabled(rowData: UnsentMailSelectionItem): boolean {
        return (
            this.selectedLegalEntityKey !== null &&
            rowData.documentHeader.legalEntityKey !==
            this.selectedLegalEntityKey
        );
    }

    // Function to retrieve selected rows and check if keys are the same  for email erstellen button
    shouldEnableButton(): boolean {
        // If no rows are selected, button should be disabled
        if (this.selectedWorkflowIds.length === 0) {
            return false;
        }
        // If only one row is selected, enable the button
        else if (this.selectedWorkflowIds.length === 1) {
            return true;
        } else {
            if (this.grid) {
                // Safely access the grid data using optional chaining

                const data1 = (<GridDataResult>this.grid.data)?.data;
                // Check if data1 is an array and not empty
                if (Array.isArray(data1) && data1.length) {
                    // Filter the grid data based on selected IDs
                    const selectedRows = data1.filter((row: UnsentMailSelectionItem) =>
                        this.selectedWorkflowIds.includes(row.id)
                    );
                    // If more than one row is selected, check if all keys are the same
                    const firstKey =
                        selectedRows[0].documentHeader.legalEntityKey;
                    return selectedRows.every(
                        (row: UnsentMailSelectionItem) =>
                            row.documentHeader.legalEntityKey === firstKey
                    );
                } else {
                    console.log('Grid data is not an array or is empty');
                    return false;
                }
            } else return false;
        }
    }
}
