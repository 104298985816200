import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { CommuneForCreation, CommuneItem, CommuneForUpdate } from 'app/_models/commune.model';
import { CommuneTaxRateItem, CommuneTaxRateForUpdate } from 'app/_models/commune-tax-rate.model';
// import 'rxjs/add/observable/of';

//const USER_KEY = 'stare-user';
const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({ providedIn: 'root' })
export class ApiCommuneService {

  constructor(
    private http: HttpClient
  ) { }

  /**
  * Add a new commune.
  * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
  * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
  */
  addCommune(item: CommuneForCreation): Observable<CommuneItem> {
    return this.http.post<CommuneItem>(ENVIRONMENT.maintenanceApiBaseUrl + 'Commune/AddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Update a commune.
   * @param communeId ID of the commune.
   * @param item Commune item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  updateCommune(communeId: number, item: CommuneForUpdate): Observable<CommuneItem> {
    return this.http.put<CommuneItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}Commune/UpdateAsync?communeId=${communeId}`, item, HTTP_OPTIONS);
  }


  /**
   * Delete a user.
   * @param communeId ID of the commune.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteCommune(communeId: number): Observable<CommuneItem> {
    return this.http.delete<CommuneItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}Commune/DeleteAsync/${communeId}`, HTTP_OPTIONS);
  }


  /**
   * Get all communes.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllCommunes(): Observable<CommuneItem[]> {
    return this.http.get<CommuneItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'Commune/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
   * Get all CommuneTaxRateItem.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllTaxRates(): Observable<CommuneTaxRateItem[]> {
    return this.http.get<CommuneTaxRateItem[]>(ENVIRONMENT.maintenanceApiBaseUrl + 'Commune/GetAllTaxRatesAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
 * Get CommuneTaxRateItem for a specific Commune.
 * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
 */
  getTaxRatesByCommuneIdAsync(communeId: number): Observable<CommuneItem> {
    return this.http.get<CommuneItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}Commune/GetTaxRatesByCommuneIdAsync/${communeId}`, HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }

  /**
   * Update a TaxRate.
   * 
   * @param item TaxRates item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  updateTaxRateAsync(item: CommuneTaxRateForUpdate): Observable<CommuneItem> {
    return this.http.put<CommuneItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}Commune/UpdateTaxRateAsync/`, item, HTTP_OPTIONS);
  }

  /**
 * Delete a TaxRate.
 * @param taxRateId ID of the TaxRate.
 * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
 */
  deleteTaxRate(taxRateId: number): Observable<CommuneTaxRateItem> {
    return this.http.delete<CommuneTaxRateItem>(`${ENVIRONMENT.maintenanceApiBaseUrl}Commune/DeleteTaxRatesByIdAsync/${taxRateId}`, HTTP_OPTIONS);
  }

}