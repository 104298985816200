import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TasksComponent } from './tasks/tasks.component';
import { AssessmentReviewComponent } from './assessment-review/assessment-review.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdministrationComponent } from './administration/administration.component';
import { ArchiveComponent } from './archive/archive.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingWbsComponent } from './setting-wbs/setting-wbs.component';
import { SettingWorkflowComponent } from './setting-workflow/setting-workflow.component';
import { SettingLegalentitiesComponent } from './setting-legalentities/setting-legalentities.component';
import { SettingImportComponent } from './setting-import/setting-import.component';
import { SettingExportComponent } from './setting-export/setting-export.component';
import { SettingTestdataComponent } from './setting-testdata/setting-testdata.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { UserpreferencesComponent } from './userpreferences/userpreferences.component';

import { UserManagementComponent } from './user-management/user-management.component';
// import { TaxRealEstateReferenceComponent } from './reference-data/tax-real-estate-reference/tax-real-estate-reference.component';
// import { TaxTradeReferenceComponent } from './reference-data/tax-trade-reference/tax-trade-reference.component';
// import { CorporateIncomeTaxReferenceComponent } from './reference-data/corporate-income-tax-reference/corporate-income-tax-reference.component';

import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DrawerItem } from "@progress/kendo-angular-layout";

import { AuthGuard } from './_guards/auth.guard';
import { AdminGuard } from './_guards/admin.guard';
import { DownloadComponent } from './download/download.component';
import { AdminImportCommuneComponent } from './admin-import-commune/admin-import-commune.component';
import { AdminImportRateComponent } from './admin-import-rate/admin-import-rate.component';
import { FaqComponent } from './faq/faq.component';
import { AdminUseraccessComponent } from './admin-useraccess/admin-useraccess.component';
import { SettingsMailTemplatesComponent } from './settings-mail-templates/settings-mail-templates.component';
import { AdminClientEngagementComponent } from './admin-clientengagement/admin-clientengagement.component';
import { chartLineStackedMarkersIcon, documentManagerIcon, envelopIcon, folderOpenIcon, gearIcon, gearsIcon, questionCircleIcon, userIcon } from '@progress/kendo-svg-icons';
import { AdminJobsComponent } from './admin-jobs/admin-jobs.component';
import { AdminOcrqueueComponent } from './admin-ocrqueue/admin-ocrqueue.component';
import { SettingsLogComponent } from './settings-log/settings-log.component';
import { AdminCommuneComponent } from './admin-commune/admin-commune.component';
// import { ReferenceDataComponent } from './reference-data/reference-data.component';
// import { ChamberOfCommerceReferenceComponent } from './reference-data/chamber-of-commerce-reference/chamber-of-commerce-reference.component';
import { SettingDatevmetadatenComponent } from './setting-datevmetadaten/setting-datevmetadaten.component';
import { QualityCheckComponent } from './quality-check/quality-check.component';
import { SettingMailmetadataComponent } from './setting-mailmetadata/setting-mailmetadata.component';
import { MailSelectionComponent } from './mail-selection/mail-selection.component';
import { MailEditorComponent } from './mail-editor/mail-editor.component';
import { AdminTaxOfficeComponent } from './admin-taxoffice/admin-taxoffice.component';
import { AdminExportScopeComponent } from './admin-export-scope/admin-export-scope.component';
import { AdminSdcUsersComponent } from './admin-sdc-users/admin-sdc-users.component';
import { UserAdminGuard } from './_guards/user-admin.guard';
import { SettingSentMailComponent } from './setting-sent-mail/setting-sent-mail.component';
import { MailEditorViewComponent } from './_components/mail-editor-view/mail-editor-view.component';
import { TasksAllComponent } from './tasks-all/tasks-all.component';
import { SettingsCommuneComponent } from './settings-commune/settings-commune.component';
import { RatesAdminGuard } from './_guards/rates-admin.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminArchiveComponent } from './admin-archive/admin-archive.component';

export const naviRoutes: Routes = [
	{
		path: '',
		canActivateChild: [AuthGuard],
		children: [
			{ path: '', redirectTo: '/tasks', pathMatch: 'full' },
			{ path: 'tasks', component: TasksComponent },
			{ path: 'assessment-review', component: AssessmentReviewComponent },
			{ path: 'quality-check', component: QualityCheckComponent },
			{ path: 'dashboard', component: DashboardComponent },
			{ path: 'archive', component: ArchiveComponent },
			{ path: 'mail-selection', component: MailSelectionComponent },
			{ path: 'mail-editor', component: MailEditorComponent },
			{ path: 'mail-editor-view', component: MailEditorViewComponent },


			// Referenzdaten
			// { path: 'reference-data', component: ReferenceDataComponent },
			// { path: 'tax-real-estate-reference', component: TaxRealEstateReferenceComponent },
			// { path: 'tax-trade-reference', component: TaxTradeReferenceComponent },
			// { path: 'chamber-of-commerce-reference', component: ChamberOfCommerceReferenceComponent },
			// { path: 'corporate-income-tax-reference', component: CorporateIncomeTaxReferenceComponent },

			{ path: 'faq', component: FaqComponent },

			// Settings section
			{ path: 'settings', component: SettingsComponent },
			{ path: 'setting-wbs', component: SettingWbsComponent },
			{ path: 'setting-workflow', component: SettingWorkflowComponent },
			{ path: 'setting-legalentities', component: SettingLegalentitiesComponent },
			{ path: 'setting-import', component: SettingImportComponent },
			{ path: 'setting-export', component: SettingExportComponent },
			{ path: 'setting-testdata', component: SettingTestdataComponent },
			{ path: 'setting-mail-templates', component: SettingsMailTemplatesComponent },
			{ path: 'setting-datevmetadaten', component: SettingDatevmetadatenComponent },
			{ path: 'setting-log', component: SettingsLogComponent },
			{ path: 'setting-mailmetadata', component: SettingMailmetadataComponent },
			{ path: 'mailmetadata-clientdetails', component: SettingMailmetadataComponent },
			{ path: 'userpreferences', component: UserpreferencesComponent },
			{ path: 'setting-sent-mail', component: SettingSentMailComponent },
			{ path: 'setting-commune', component: SettingsCommuneComponent, canActivate: [RatesAdminGuard] },


			// User Management
			{ path: 'user-management', component: UserManagementComponent, canActivate: [UserAdminGuard] },
			{ path: 'admin-users', component: AdminUsersComponent, canActivate: [UserAdminGuard] },
			{ path: 'admin-useraccess', component: AdminUseraccessComponent, canActivate: [UserAdminGuard] },

			// Admin section
			{ path: 'administration', component: AdministrationComponent, canActivate: [AdminGuard] },
			{ path: 'admin-clientengagement', component: AdminClientEngagementComponent, canActivate: [AdminGuard] },
			{ path: 'admin-import-commune', component: AdminImportCommuneComponent, canActivate: [AdminGuard] },
			{ path: 'admin-import-rate', component: AdminImportRateComponent, canActivate: [AdminGuard] },
			{ path: 'admin-jobs', component: AdminJobsComponent, canActivate: [AdminGuard] },
			{ path: 'admin-sdc-users', component: AdminSdcUsersComponent, canActivate: [AdminGuard] },

			{ path: 'tasks-all', component: TasksAllComponent, canActivate: [AdminGuard] },
			{ path: 'admin-taxoffice', component: AdminTaxOfficeComponent, canActivate: [AdminGuard] },
			{ path: 'admin-ocrqueue', component: AdminOcrqueueComponent, canActivate: [AdminGuard] },
			{ path: 'admin-export-scope', component: AdminExportScopeComponent, canActivate: [AdminGuard] },
			{ path: 'admin-commune', component: AdminCommuneComponent, canActivate: [AdminGuard] },

			{ path: 'admin-archive', component: AdminArchiveComponent, canActivate: [AdminGuard] },
			{ path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard] },

		]
	},

	// Static helpers
	{ path: 'login', component: LoginComponent },
	{ path: 'logout', component: LogoutComponent },
	{ path: 'access-denied', component: AccessDeniedComponent },
	{ path: 'download', component: DownloadComponent },

	// Wildcard for everything else
	{ path: '**', component: PageNotFoundComponent }
];

//with admit and automaticaly with user management
export const naviListItemsDe: DrawerItem[] = [
	// Index 0 is the default
	{
		text: 'Aufgaben',
		svgIcon: documentManagerIcon,
	},

	// Index 1
	{
		text: 'Auswahl der Bescheide zur E-Mailerstellung',
		svgIcon: envelopIcon,

	},

	// Index 2
	{
		text: 'Dashboard',
		svgIcon: chartLineStackedMarkersIcon,

	},

	// Index 3
	{
		text: 'Archiv',
		svgIcon: folderOpenIcon,
	},

	// // Index 4
	// {
	// 	text: 'Referenzdaten',
	// 	svgIcon: bookIcon,
	// 	//disabled: true
	// },

	// Index 5
	{
		text: 'Einstellungen',
		svgIcon: gearIcon,
	},

	// Index 6
	{
		text: 'Hilfe',
		svgIcon: questionCircleIcon,

	},

	// Index 7
	{
		text: 'Nutzerverwaltung',
		svgIcon: userIcon,
		//disabled: true,

	},
	// Index 8
	{
		text: 'Administration',
		svgIcon: gearsIcon,
		//disabled: true,

	},
];

//with admit and automaticaly with user management
export const naviListItemsEng: DrawerItem[] = [
	// Index 0 is the default
	{
		text: 'Tasks',
		svgIcon: documentManagerIcon,
	},
	// Index 1
	{
		text: 'Prepare Email',
		svgIcon: envelopIcon,

	},
	// Index 2
	{
		text: 'Dashboard',
		svgIcon: chartLineStackedMarkersIcon,
	},

	// Index 3
	{
		text: 'Archive',
		svgIcon: folderOpenIcon,
	},

	// // Index 4
	// {
	// 	text: 'Reference data',
	// 	svgIcon: bookIcon,
	// 	//disabled: true
	// },

	// Index 5
	{
		text: 'Settings',
		svgIcon: gearIcon,
	},

	// Index 6
	{
		text: 'Help',
		svgIcon: questionCircleIcon,

	},

	// Index 7
	{
		text: 'User management',
		svgIcon: userIcon,
		//disabled: true,

	},

	// Index 8
	{
		text: 'Administration',
		svgIcon: gearsIcon,
		//disabled: true,

	},
];


// NavList if not Admin
export const naviListItemsNoAdminDe: DrawerItem[] = [
	// Index 0 is the default
	{
		text: 'Aufgaben',
		svgIcon: documentManagerIcon,
	},
	// Index 1
	{
		text: 'Bescheid Auswahl für Email',
		svgIcon: envelopIcon,

	},

	// Index 2
	{
		text: 'Dashboard',
		svgIcon: chartLineStackedMarkersIcon,

	},

	// Index 3
	{
		text: 'Archiv',
		svgIcon: folderOpenIcon,
	},
	// // // Index 4
	// {
	// 	text: 'Referenzdaten',
	// 	svgIcon: bookIcon,
	// 	//disabled: true
	// },

	// Index 5
	{
		text: 'Einstellungen',
		svgIcon: gearIcon,
	},

	// Index 6
	{
		text: 'Hilfe',
		svgIcon: questionCircleIcon,

	},

	{
		text: 'Nutzerverwaltung',
		svgIcon: userIcon,
		//disabled: true,

	},


];


export const naviListItemsNoAdminEng: DrawerItem[] = [
	// Index 0 is the default
	{
		text: 'Tasks',
		svgIcon: documentManagerIcon,
	},
	// Index 1
	{
		text: 'Prepare Email',
		svgIcon: envelopIcon,

	},
	// Index 2
	{
		text: 'Dashboard',
		svgIcon: chartLineStackedMarkersIcon,
	},

	// Index 3
	{
		text: 'Archive',
		svgIcon: folderOpenIcon,
	},
	// // // Index 4
	// {
	// 	text: 'Reference data',
	// 	svgIcon: bookIcon,
	// 	//disabled: true
	// },

	// Index 5
	{
		text: 'Settings',
		svgIcon: gearIcon,
	},

	// Index 6
	{
		text: 'Help',
		svgIcon: questionCircleIcon,

	},
	{
		text: 'User management',
		svgIcon: userIcon,
		//disabled: true,

	},

];

/**
 * Mapping of index in naviListItems to route dms engagement
 */
export const naviListMappingRoute = new Map<number, string>([
	[0, '../tasks'],

	// [1, '../mail-selection'],
	[1, '../dashboard'],
	[2, '../archive'],
	// [3, '../reference-data'],
	[3, '../settings'],
	[4, '../faq'],

	[5, '../user-management'],
	[6, '../administration']

]);

/**
 * Mapping of route to index in naviListItems dms engagement
 */
export const naviListMappingPath = new Map<string, number>([
	['tasks', 0],

	['assessment-review', 0],
	['mail-editor', 0],
	['dashboard', 1],
	['archive', 2],
	['archive-viewer', 2],

	// ['reference-data', 3],
	// ['tax-real-estate-reference', 3],
	// ['tax-trade-reference', 3],
	// ['corporate-income-tax-reference', 3],
	// ['chamber-of-commerce-reference', 3],

	['settings', 3],
	['setting-wbs', 3],
	['setting-workflow', 3],
	['setting-legalentities', 3],

	['setting-import', 3],
	['setting-export', 3],
	['setting-testdata', 3],
	['setting-datevmetadaten', 3],
	['setting-mail-templates', 5],
	['setting-mailmetadata', 3],
	['setting-log', 3],
	['userpreferences', 3],
	['setting-mail-sent', 3],
	['setting-commune', 3],

	['faq', 4],
	// ['mail-selection', 1],
	['administration', 6],
	['user-management', 5],
	['admin-users', 5],
	['tasks-all', 5],
	['admin-useraccess', 5],
	['admin-import-commune', 6],
	['admin-clientengagement', 6],
	['admin-import-rate', 6],

	['admin-taxoffice', 6],
	['admin-jobs', 6],
	['admin-ocrqueue', 6],


	['admin-archive', 6],
	['admin-dashboard', 6],
	['admin-commune', 6]
]);

/**
 * Mapping of index in naviListItems to route sdc engagement
 */
export const naviListMappingRouteSDC = new Map<number, string>([
	[0, '../tasks'],

	[1, '../mail-selection'],
	[2, '../dashboard'],
	[3, '../archive'],
	// [3, '../reference-data'],
	[4, '../settings'],
	[5, '../faq'],
	[6, '../user-management'],
	[7, '../administration']
]);

/**
 * Mapping of route to index in naviListItems sdc engagement
 */
export const naviListMappingPathSDC = new Map<string, number>([
	['tasks', 0],
	['assessment-review', 0],
	['mail-editor', 0],
	['dashboard', 2],
	['archive', 3],
	['archive-viewer', 3],

	// ['reference-data', 3],
	// ['tax-real-estate-reference', 3],
	// ['tax-trade-reference', 3],
	// ['corporate-income-tax-reference', 3],
	// ['chamber-of-commerce-reference', 3],

	['settings', 4],
	['setting-wbs', 4],
	['setting-workflow', 4],
	['setting-legalentities', 4],

	['setting-import', 4],
	['setting-export', 4],
	['setting-testdata', 4],
	['setting-datevmetadaten', 4],
	['setting-mail-templates', 6],
	['setting-mailmetadata', 4],
	['setting-log', 4],
	['userpreferences', 4],
	['setting-sent-mail', 4],
	['setting-commune', 4],

	['faq', 5],
	['mail-selection', 1],
	['administration', 7],


	['user-management', 6],
	['admin-users', 6],
	['admin-useraccess', 6],
	['admin-import-commune', 7],
	['admin-clientengagement', 7],
	['admin-import-rate', 7],

	['tasks-all', 7],
	['admin-taxoffice', 7],
	['admin-jobs', 7],
	['admin-ocrqueue', 7],
	['admin-archive', 7],
	['admin-dashboard', 7],
	['admin-commune', 7]
]);



@NgModule({
	imports: [RouterModule.forRoot(naviRoutes)],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class AppRoutingModule {

}
