import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListsService } from '../lists.service';
import { ENVIRONMENT } from 'environments/environment';
import { UserLocal } from 'app/_models/user.model';
import { ClientEngagementForList } from 'app/_models/client-engagement.model';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';

const HTTP_OPTIONS2 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

export interface UserToken {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiAuthentificationService {


  private _user: UserLocal = {} as UserLocal;
  public isAuthenticated: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private listsService: ListsService,
    private notificationService: NotificationService) { }

  public redirectClientEngagementKey: string = '';
  public redirectUrl: string = '';
  public userToken: string = '';

  public setRedirectKey(key: string) {
    this.redirectClientEngagementKey = key;
  }
  public getRedirectKey(): string {
    let key = localStorage.getItem('redirectKey') ?? '';
    if (this.redirectClientEngagementKey == '')
      return key;
    else return this.redirectClientEngagementKey;
  }
  public setRedirectURL(url: string) {
    this.redirectUrl = url;
  }
  public getRedirectURL(): string {
    return this.redirectUrl
  }
  public setToken(token: string) {
    this.userToken = token;
  }
  public getToken(): string {
    return this.userToken
  }


  async changeClientandRedirect(): Promise<boolean> {


    //console.log("Login ausführen");
    //window.location.href = ENVIRONMENT.apiBaseUrl + 'Auth/Login';

    if (this.getRedirectKey() != '') {

      this.listsService.ResetCacheLegalEntities();
      this.listsService.ResetCacheCommuneAGS();

      // See Login-Component also
      const data = await this.getNewToken(this.getRedirectKey())
        .then(
          zz => {

            localStorage.setItem('stare_token', zz.token);

            // Get User-Info
            this.getSessionInfoObservable().subscribe(data => {

              let _user = {} as UserLocal;
              _user.clientEngagementKey = data.clientEngagementKey;
              _user.clientEngagements = data.clientEngagements;
              _user.firstname = data.firstname;
              _user.tenantSource = data.tenantSource;
              _user.lastname = data.lastname;
              _user.userId = data.userId;
              _user.isAdmin = data.isAdmin;
              _user.canReopenWorkflows = data.canReopenWorkflows;
              _user.canManageOwnUser = data.canManageOwnUser;
              _user.canManageCommuneTaxRates = data.canManageCommuneTaxRates;
              _user.mail = data.mail;

              // console.info(_user);

              localStorage.setItem('user', JSON.stringify(_user));
              let redirectTo = localStorage.getItem('redirectTo') ?? '';

              if (redirectTo == '') {
                redirectTo = this.getRedirectURL();

              }

              if (redirectTo != '') {
                console.log(' change client successful, redirecting to given url');
                window.location.href = redirectTo;
                this.resetRedirection();
              }

              else {
                this.router.navigate(['/tasks']);
              }	// with reload
              return true;
            });
          }
        ).catch((error) => {
          console.error('Promise rejected with error: ' + error);
          return false;
        });

      if (!data) {
        return false;

      }
      else {
        return true;
      }
    }
    else {
      return true;

    }

  }



  /**
   * This regex pattern aims to match URLs that start with optional protocols (http://, https://, or ftp://),
   * followed by a domain name, domain extension, and various characters that form the path, query, or fragment part of
   * the URL, as well as allowing `%` as a valid character (for encoded characters).
   *
   * https://regexr.com/7q3qi
   */
  isValidUrl(value: string): boolean {
    const URL_PATTERN: RegExp = /^https:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;
    //const url_pattern2: RegExp = /^https:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;
    return URL_PATTERN.test(value);
  }

  clientKeyExistsInList(key: string, list: ClientEngagementForList[]): boolean {

    let selectedClientEngagement = list.find(f => f.clientEngagementKey == key);
    return (selectedClientEngagement != undefined) ? true : false;

  }


  /**
*clears redirectURl and key from local storage and token service
*/
  resetRedirection() {
    localStorage.removeItem('redirectTo');
    localStorage.removeItem('redirectKey');
    this.setRedirectKey('');
    this.setRedirectURL('');

  }

  setRedirection(url: string, key: string) {
    this.setRedirectURL(url);
    localStorage.setItem('redirectTo', url);
    this.setRedirectKey(key);
    localStorage.setItem('redirectKey', key);
  }

  /**
  * Auth/SetClientEngagement Api call accepts the client engagement Key and returns a string as User Token
  */
  getNewToken(clientEngagementKey: string): Promise<UserToken> {
    return this.http.post<UserToken>(ENVIRONMENT.apiBaseUrl + 'Auth/SetClientEngagement/' + clientEngagementKey, HTTP_OPTIONS2).toPromise();
  }

  /**
   * Returns UserLocal Item Promise
   */
  getSessionInfo(): Promise<UserLocal> {
    return this.http.get<UserLocal>(ENVIRONMENT.apiBaseUrl + 'Auth/GetSessionInfoAsync', HTTP_OPTIONS2).toPromise();
  }
  /**
 * Returns UserLocal Item Observable
 */
  getSessionInfoObservable(): Observable<UserLocal> {
    return this.http.get<UserLocal>(ENVIRONMENT.apiBaseUrl + 'Auth/GetSessionInfoAsync', HTTP_OPTIONS2);
  }

  /**
   * Auth/VerifyRequest?guid with  string parameter and returns a string as User Token
   */
  verifyRequest(anc: string): Promise<UserToken> {
    return this.http.get<UserToken>(ENVIRONMENT.apiBaseUrl + 'Auth/VerifyRequest?guid=' + anc, HTTP_OPTIONS2).toPromise();
  }
  /**
 * logout
 */
  logout(): Promise<UserToken> {
    return this.http.post<any>(ENVIRONMENT.apiBaseUrl + 'Auth/Logout', HTTP_OPTIONS2).toPromise();
  }
}

