<form [formGroup]="corporateIncomeTaxForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300"
                        overrideLabel="Begründung Rechtsbehelf für Mandant (extern):" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_assessmentYear"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_taxationBases" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataNumberOptionsSource]="taxbaseOptions">
                    </app-stare-dropdown>
                </div>
                <!-- </div>
            <div class="row-for-checkbox" *ngIf="workflowType==11"> -->
                <div class="column-for-checkbox" *ngIf="workflowType == 11">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header" style="width: 1030px">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <!-- TABLE ROWS: 9 ROWS and 5 COLUMNS-->
            <div class="row" style="padding-top: 25px">
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Körperschaftsteuer
                </div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Solidaritätszuschlag
                </div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Verspätungszuschlag
                </div>
                <div class="column-for-soli-table" style="font-size: 16px; font-weight: bold">
                    Zinsen
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Festsetzung (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_solidarity_establishedAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_delay_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>

            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Festsetzung (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_solidarity_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_establishedAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Anzurechnend (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_solidarity_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Anzurechnend (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_solidarity_chargeableAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Bereits gezahlt (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_solidarity_alreadyPaidAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_delay_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Bereits gezahlt (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_solidarity_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_delay_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_alreadyPaidAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">Fälligkeit (in €)</div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_solidarity_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_delay_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-header">
                    Referenz: Fälligkeit (in €)
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()" xbrlName="corporateIncomeTax_toPaid"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxForm1300" [isReadOnly]="true"
                        [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_solidarity_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_delay_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_dueDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column-for-soli-table">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_sum_toPaid" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column-for-soli-table"></div>
                <div class="column-for-soli-table"></div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Besteuerungsgrundlagen
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncome_taxableNetProfitLoss" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_taxableNetProfitLoss" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncome_taxableIncome" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_taxableIncome" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_remainingCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_remainingCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interestCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interestCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_ebitdaCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_ebitdaCarryForward" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_foreignTaxesIncluding12AStG" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_foreignTaxesIncluding12AStG" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Berechnung der Zinsen
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_beginDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_beginDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_endDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_endDate" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>
            <!--  Task 25959 -->
            <!-- <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_rate" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isPercent]="true" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_rate" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isPercent]="true" [isReadOnly]="true"
                        [workflowType]='workflowType'>
                    </app-stare-numerical>
                </div>
            </div> -->
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_bearingAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_bearingAmount" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_interest_calculated" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTax_interest_calculated" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div> -->
            <!-- </div>
            <div class="row" *ngIf="corporateIncomeTaxForm1300.controls['document_withoutReferenceValidation'].value==true"> -->
            <!-- <div class="column-for-text-area"
                    *ngIf="corporateIncomeTaxForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxForm1300" [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <!-- <div class="row-for-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="corporateIncomeTaxForm1300" [auditResults]="auditResults"
                [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- <form [formGroup]="corporateIncomeTaxForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 120 },
        { height: 130 }, 
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 25 },
        { height: 1112},						
        { height: 20 },
        { height: 112 },
        { height: 112 },	
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 112 },							
        { height: 20 },
        { height: 112 },
        { height: 112 },	
        { height: 112 },
        { height: 112 },
        { height: 112 },				
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight},
        { height: sdcFieldsRowHeight },
        { height: sdcFieldsRowHeight }]" [cols]="[{ width: 270 }, { width: 270 }, { width:170 }]">

                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_assessmentYear-'+headerDatasetId"
                            id="yearLbl">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [decimals]="0"
                            [maxlength]="4" [changeValueOnScroll]="false" format="##"
                            [id]="'id_assessmentYear-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_assessmentYear')"
                            placeholder="{{getXbrlLabel('id_assessmentYear')}}" formControlName="id_assessmentYear"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label
                            [for]="'id_legalEntityKey-'+headerDatasetId">{{getXbrlLabel('id_legalEntityKey')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_taxationBases-'+headerDatasetId"
                            id="ebitdaLabel">{{getXbrlLabel('document_corporateIncomeTax_taxationBases')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [readonly]="true" class="custom-fill-mode"
                            fillMode="none" [tabindex]="-1"
                            [id]="'document_corporateIncomeTax_taxationBases-'+headerDatasetId" [valuePrimitive]="true"
                            [textField]="'labelDE'" [valueField]="'value'" [defaultItem]="defaultItem"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_taxationBases')"
                            formControlName="document_corporateIncomeTax_taxationBases" [data]="taxbaseOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_taxationBases'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="2">

                    <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[                  
                    { height: 15 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 },
                    { height: 112 }]"
                        [cols]="[{ width: 130 }, { width: 170 }, { width:170 },{ width: 170}, { width: 170 }]">


                        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                            <kendo-label id="rateableValueLabel"></kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="1" [col]="2" [colSpan]="1">
                            <kendo-label style="font-size: 16px; font-weight: bold;"
                                id="rateableValueLabel">Körperschaftsteuer</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="1" [col]="3" [colSpan]="1">
                            <kendo-label style="font-size: 15px; font-weight: bold;"
                                id="rateableValueLabel">Solidaritätszuschlag</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="1" [col]="4" [colSpan]="1">
                            <kendo-label style="font-size: 16px; font-weight: bold;"
                                id="rateableValueLabel">Verspätungszuschlag.</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="1" [col]="5" [colSpan]="1">
                            <kendo-label style="font-size: 16px; font-weight: bold;"
                                id="rateableValueLabel">Zinsen</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Festsetzung (in
                                €)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [id]="'document_corporateIncomeTax_establishedAmount-'+headerDatasetId"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_establishedAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_establishedAmount')}}"
                                    formControlName="document_corporateIncomeTax_establishedAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [id]="'document_corporateIncomeTax_solidarity_establishedAmount-'+headerDatasetId"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_solidarity_establishedAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_solidarity_establishedAmount')}}"
                                    formControlName="document_corporateIncomeTax_solidarity_establishedAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_solidarity_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="4" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [id]="'document_corporateIncomeTax_delay_establishedAmount-'+headerDatasetId"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_delay_establishedAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_delay_establishedAmount')}}"
                                    formControlName="document_corporateIncomeTax_delay_establishedAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_delay_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="2" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [id]="'document_corporateIncomeTax_interest_establishedAmount-'+headerDatasetId"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_establishedAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_establishedAmount')}}"
                                    formControlName="document_corporateIncomeTax_interest_establishedAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_interest_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Referenz: Festsetzung (in
                                €)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [readonly]="true" [changeValueOnScroll]="false" [tabindex]="-1"
                                    [id]="'corporateIncomeTax_establishedAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_establishedAmount')"
                                    class="custom-fill-mode" fillMode="none" [decimals]="2"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_establishedAmount')}}"
                                    formControlName="corporateIncomeTax_establishedAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [decimals]="2" [tabindex]="-1"
                                    [id]="'corporateIncomeTax_solidarity_establishedAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_solidarity_establishedAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_solidarity_establishedAmount')}}"
                                    formControlName="corporateIncomeTax_solidarity_establishedAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_solidarity_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="3" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [id]="'corporateIncomeTax_interest_establishedAmount-'+headerDatasetId"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [tabindex]="-1"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_establishedAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_interest_establishedAmount')}}"
                                    formControlName="corporateIncomeTax_interest_establishedAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_interest_establishedAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                            <kendo-label id="rateableValueLabel">
                                Anzurechnend (in €)
                            </kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_chargeableAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_chargeableAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_chargeableAmount')}}"
                                    formControlName="document_corporateIncomeTax_chargeableAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_chargeableAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="4" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_solidarity_chargeableAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_solidarity_chargeableAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_solidarity_chargeableAmount')}}"
                                    formControlName="document_corporateIncomeTax_solidarity_chargeableAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_solidarity_chargeableAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">
                                Referenz: Anzurechnend (in €)
                            </kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [readonly]="true" [changeValueOnScroll]="false" class="custom-fill-mode"
                                    [tabindex]="-1" fillMode="none"
                                    [id]="'corporateIncomeTax_chargeableAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_chargeableAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_chargeableAmount')}}"
                                    formControlName="corporateIncomeTax_chargeableAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_chargeableAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="5" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [decimals]="2" [tabindex]="-1"
                                    [id]="'corporateIncomeTax_solidarity_chargeableAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_solidarity_chargeableAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_solidarity_chargeableAmount')}}"
                                    formControlName="corporateIncomeTax_solidarity_chargeableAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_solidarity_chargeableAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Bereits gezahlt (in
                                €)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_alreadyPaidAmount')}}"
                                    formControlName="document_corporateIncomeTax_alreadyPaidAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="6" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_solidarity_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_solidarity_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_solidarity_alreadyPaidAmount')}}"
                                    formControlName="document_corporateIncomeTax_solidarity_alreadyPaidAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_solidarity_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="6" [col]="4" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_delay_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_delay_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_delay_alreadyPaidAmount')}}"
                                    formControlName="document_corporateIncomeTax_delay_alreadyPaidAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_delay_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="6" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_interest_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_alreadyPaidAmount')}}"
                                    formControlName="document_corporateIncomeTax_interest_alreadyPaidAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_interest_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Referenz: Bereits gezahlt
                                (€)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [readonly]="true" [changeValueOnScroll]="false" class="custom-fill-mode"
                                    [decimals]="2" fillMode="none" [tabindex]="-1"
                                    id="corporateIncomeTax_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_alreadyPaidAmount')}}"
                                    formControlName="corporateIncomeTax_alreadyPaidAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="7" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [tabindex]="-1"
                                    [id]="'corporateIncomeTax_solidarity_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_solidarity_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_solidarity_alreadyPaidAmount')}}"
                                    formControlName="corporateIncomeTax_solidarity_alreadyPaidAmount"
                                    autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_solidarity_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="7" [col]="4" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    [tabindex]="-1" fillMode="none"
                                    [id]="'corporateIncomeTax_delay_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_delay_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_delay_alreadyPaidAmount')}}"
                                    formControlName="corporateIncomeTax_delay_alreadyPaidAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_delay_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="7" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [tabindex]="-1"
                                    [id]="'corporateIncomeTax_interest_alreadyPaidAmount-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_alreadyPaidAmount')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_interest_alreadyPaidAmount')}}"
                                    formControlName="corporateIncomeTax_interest_alreadyPaidAmount" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_interest_alreadyPaidAmount'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Fälligkeit (in
                                €)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_toPaid')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_toPaid')}}"
                                    formControlName="document_corporateIncomeTax_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="8" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_solidarity_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_solidarity_toPaid')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_solidarity_toPaid')}}"
                                    formControlName="document_corporateIncomeTax_solidarity_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_solidarity_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="8" [col]="4" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_delay_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_delay_toPaid')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_delay_toPaid')}}"
                                    formControlName="document_corporateIncomeTax_delay_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_delay_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="8" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_interest_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_toPaid')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_toPaid')}}"
                                    formControlName="document_corporateIncomeTax_interest_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_interest_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1" style="padding-top: 12px;">
                            <kendo-label id="rateableValueLabel">Referenz: Fälligkeit (in
                                €)</kendo-label>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    fillMode="none" [id]="'corporateIncomeTax_toPaid-'+headerDatasetId" [tabindex]="-1"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_toPaid')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_toPaid')}}"
                                    formControlName="corporateIncomeTax_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="9" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    [tabindex]="-1" fillMode="none"
                                    [id]="'corporateIncomeTax_solidarity_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_solidarity_toPaid')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_solidarity_toPaid')}}"
                                    formControlName="corporateIncomeTax_solidarity_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_solidarity_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="9" [col]="4" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    [tabindex]="-1" fillMode="none"
                                    [id]="'corporateIncomeTax_delay_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_delay_toPaid')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_delay_toPaid')}}"
                                    formControlName="corporateIncomeTax_delay_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_delay_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="9" [col]="5" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [readonly]="true" class="custom-fill-mode"
                                    [tabindex]="-1" fillMode="none"
                                    [id]="'corporateIncomeTax_interest_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_toPaid')"
                                    placeholder="{{getXbrlLabel('corporateIncomeTax_interest_toPaid')}}"
                                    formControlName="corporateIncomeTax_interest_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'corporateIncomeTax_interest_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>

                        <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-label [for]="'document_corporateIncomeTax_dueDate-'+headerDatasetId"
                                    id="lbl">{{getXbrlLabel('document_corporateIncomeTax_dueDate')}}</kendo-label>
                                <kendo-datepicker (focus)="onFocusInputXbrl()"
                                    [value]="formGroup.controls['document_corporateIncomeTax_dueDate'].value"
                                    format="dd.MM.yyyy" [enableMouseWheel]="false"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_dueDate')"
                                    [id]="'document_corporateIncomeTax_dueDate-'+headerDatasetId"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_dueDate')}}"
                                    formControlName="document_corporateIncomeTax_dueDate" autocomplete="off">
                                    <kendo-datepicker-messages today="Heute"
                                        toggle="Kalendar umschalten"></kendo-datepicker-messages>
                                </kendo-datepicker>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_dueDate'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [row]="10" [col]="3" [colSpan]="1">
                            <kendo-formfield showHints="always" showErrors="always">
                                <kendo-label [for]="'document_corporateIncomeTax_sum_toPaid-'+headerDatasetId" id="lbl">
                                    {{getXbrlLabel('document_corporateIncomeTax_sum_toPaid')}}</kendo-label>
                                <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                                    [changeValueOnScroll]="false" [decimals]="2"
                                    [id]="'document_corporateIncomeTax_sum_toPaid-'+headerDatasetId"
                                    [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_sum_toPaid')"
                                    placeholder="{{getXbrlLabel('document_corporateIncomeTax_sum_toPaid')}}"
                                    formControlName="document_corporateIncomeTax_sum_toPaid" autocomplete="off">
                                </kendo-numerictextbox>
                                <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                    [xbrlNameInput]="'document_corporateIncomeTax_sum_toPaid'"></app-validation-message-formcontrol>
                            </kendo-formfield>
                        </kendo-gridlayout-item>

                    </kendo-gridlayout>

                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Besteuerungsgrundlagen</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_corporateIncome_taxableNetProfitLoss-'+headerDatasetId"
                            id="taxableNetProfitLossLbl">{{getXbrlLabel('document_corporateIncome_taxableNetProfitLoss')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncome_taxableNetProfitLoss-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncome_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('document_corporateIncome_taxableNetProfitLoss')}}"
                            formControlName="document_corporateIncome_taxableNetProfitLoss" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncome_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTax_taxableNetProfitLoss-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_taxableNetProfitLoss')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTax_taxableNetProfitLoss-'+headerDatasetId" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_taxableNetProfitLoss')}}"
                            formControlName="corporateIncomeTax_taxableNetProfitLoss" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncome_taxableIncome-'+headerDatasetId">{{getXbrlLabel('document_corporateIncome_taxableIncome')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncome_taxableIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncome_taxableIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncome_taxableIncome')}}"
                            formControlName="document_corporateIncome_taxableIncome" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncome_taxableIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="13" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_taxableIncome-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_taxableIncome')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [id]="'corporateIncomeTax_taxableIncome-'+headerDatasetId"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_taxableIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_taxableIncome')}}"
                            formControlName="corporateIncomeTax_taxableIncome" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_taxableIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_remainingCarryForward-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_remainingCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_remainingCarryForward-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_remainingCarryForward')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_remainingCarryForward')}}"
                            formControlName="document_corporateIncomeTax_remainingCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_remainingCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_remainingCarryForward-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_remainingCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [id]="'corporateIncomeTax_remainingCarryForward-'+headerDatasetId" [readonly]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_remainingCarryForward')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_remainingCarryForward')}}"
                            formControlName="corporateIncomeTax_remainingCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_remainingCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_interestCarryForward-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_interestCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_interestCarryForward-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interestCarryForward')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interestCarryForward')}}"
                            formControlName="document_corporateIncomeTax_interestCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interestCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_interestCarryForward-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_interestCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [id]="'corporateIncomeTax_interestCarryForward-'+headerDatasetId" [readonly]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interestCarryForward')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interestCarryForward')}}"
                            formControlName="corporateIncomeTax_interestCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interestCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_ebitdaCarryForward-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_ebitdaCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_ebitdaCarryForward-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_ebitdaCarryForward')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_ebitdaCarryForward')}}"
                            formControlName="document_corporateIncomeTax_ebitdaCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_ebitdaCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_ebitdaCarryForward-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_ebitdaCarryForward')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [id]="'corporateIncomeTax_ebitdaCarryForward-'+headerDatasetId" [readonly]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_ebitdaCarryForward')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_ebitdaCarryForward')}}"
                            formControlName="corporateIncomeTax_ebitdaCarryForward" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_ebitdaCarryForward'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_foreignTaxesIncluding12AStG-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_foreignTaxesIncluding12AStG')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_foreignTaxesIncluding12AStG-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_foreignTaxesIncluding12AStG')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_foreignTaxesIncluding12AStG')}}"
                            formControlName="document_corporateIncomeTax_foreignTaxesIncluding12AStG"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_foreignTaxesIncluding12AStG'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_foreignTaxesIncluding12AStG-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_foreignTaxesIncluding12AStG')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [id]="'corporateIncomeTax_foreignTaxesIncluding12AStG-'+headerDatasetId" [readonly]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_foreignTaxesIncluding12AStG')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_foreignTaxesIncluding12AStG')}}"
                            formControlName="corporateIncomeTax_foreignTaxesIncluding12AStG" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_foreignTaxesIncluding12AStG'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="2">
                    <legend class="k-form-legend section-header">
                        Berechnung der Zinsen</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_interest_beginDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('document_corporateIncomeTax_interest_beginDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['document_corporateIncomeTax_interest_beginDate'].value"
                            format="dd.MM.yyyy" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_beginDate')"
                            [id]="'document_corporateIncomeTax_interest_beginDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_beginDate')}}"
                            formControlName="document_corporateIncomeTax_interest_beginDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interest_beginDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'corporateIncomeTax_interest_beginDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('corporateIncomeTax_interest_beginDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()" [readonly]="true" [tabindex]="-1"
                            [value]="formGroup.controls['corporateIncomeTax_interest_beginDate'].value"
                            format="dd.MM.yyyy" [enableMouseWheel]="false" class="custom-fill-mode"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_beginDate')"
                            [id]="'corporateIncomeTax_interest_beginDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interest_beginDate')}}"
                            formControlName="corporateIncomeTax_interest_beginDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interest_beginDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_interest_endDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('document_corporateIncomeTax_interest_endDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['document_corporateIncomeTax_interest_endDate'].value"
                            format="dd.MM.yyyy" [enableMouseWheel]="false"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_endDate')"
                            [id]="'document_corporateIncomeTax_interest_endDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_endDate')}}"
                            formControlName="document_corporateIncomeTax_interest_endDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interest_endDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'corporateIncomeTax_interest_endDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('corporateIncomeTax_interest_endDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()" [readonly]="true" [tabindex]="-1"
                            [value]="formGroup.controls['corporateIncomeTax_interest_endDate'].value"
                            format="dd.MM.yyyy" [enableMouseWheel]="false" class="custom-fill-mode"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_endDate')"
                            [id]="'corporateIncomeTax_interest_endDate-'+headerDatasetId"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interest_endDate')}}"
                            formControlName="corporateIncomeTax_interest_endDate" autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interest_beginDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_interest_rate-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_interest_rate')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false"
                            [changeValueOnScroll]="false" [format]="'n2'" [decimals]="2"
                            [id]="'document_corporateIncomeTax_interest_rate-'+headerDatasetId" type="decimal"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_rate')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_rate')}}"
                            formControlName="document_corporateIncomeTax_interest_rate" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interest_rate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_interest_rate-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_interest_rate')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [decimals]="0" [spinners]="false"
                            [maxlength]="3" [changeValueOnScroll]="false" format="##" class="custom-fill-mode"
                            [tabindex]="-1" fillMode="none" [id]="'corporateIncomeTax_interest_rate-'+headerDatasetId"
                            [readonly]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_rate')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interest_rate')}}"
                            formControlName="corporateIncomeTax_interest_rate" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interest_rate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_interest_bearingAmount-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_interest_bearingAmount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_interest_bearingAmount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_bearingAmount')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_bearingAmount')}}"
                            formControlName="document_corporateIncomeTax_interest_bearingAmount" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interest_bearingAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_interest_bearingAmount-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_interest_bearingAmount')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTax_interest_bearingAmount-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_bearingAmount')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interest_bearingAmount')}}"
                            formControlName="corporateIncomeTax_interest_bearingAmount" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interest_bearingAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="23" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'document_corporateIncomeTax_interest_calculated-'+headerDatasetId">{{getXbrlLabel('document_corporateIncomeTax_interest_calculated')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTax_interest_calculated-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_interest_calculated')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTax_interest_calculated')}}"
                            formControlName="document_corporateIncomeTax_interest_calculated" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_interest_calculated'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="23" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label
                            [for]="'corporateIncomeTax_interest_calculated-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTax_interest_calculated')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" class="custom-fill-mode" fillMode="none"
                            [id]="'corporateIncomeTax_interest_calculated-'+headerDatasetId" [readonly]="true"
                            [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTax_interest_calculated')"
                            placeholder="{{getXbrlLabel('corporateIncomeTax_interest_calculated')}}"
                            formControlName="corporateIncomeTax_interest_calculated" autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTax_interest_calculated'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="24" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="25" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="26" [col]="1" [colSpan]="2"
                    *ngIf="formGroup.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="27" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="28" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->