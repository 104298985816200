<div class="stareTopic">
    {{ 'APP.FAQ.TITLE' | translate }}
</div>

<h2 class="plainText">{{ 'APP.FAQ.SECTIONS.GENERAL.STARE.QUESTION' | translate }}</h2>
<p class="plainText">{{ 'APP.FAQ.SECTIONS.GENERAL.STARE.ANSWER' | translate }}</p>

<h2 class="plainText">{{ 'APP.FAQ.SECTIONS.GENERAL.ABOUT.QUESTION' | translate }}</h2>
<p class="plainText">{{ 'APP.FAQ.SECTIONS.GENERAL.ABOUT.ANSWER' | translate }}</p>





<div class="plainText" id="releaseNotes" [innerHTML]="releaseNotesText"></div>




<!-- <div class="grid-layout-container">
    <kendo-gridlayout [gap]="{ rows: 11, cols: 10 }" [rows]="[
      { height: 30 },
      { height: 70 },
      { height: 130 },
      { height: 30 },
      { height: 30 },
      { height: 1300 },
      { height: 100 },
      { height: 30 },
      { height: 1400 },
      { height: 30 },
      { height: 200 },

    ]" [cols]="[{ width: 50 }, { width: 50 }, { width:50 }]">
        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="4">
            <div class="k-text-uppercase k-font-weight-bold">
                {{ 'APP.FAQ.SECTIONS.GENERAL.TITLE' | translate }}
            </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="4">
            <h2>{{ 'APP.FAQ.SECTIONS.GENERAL.STARE.QUESTION' | translate }}</h2>
            <p>{{ 'APP.FAQ.SECTIONS.GENERAL.STARE.ANSWER' | translate }}</p>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="4">
            <h2>{{ 'APP.FAQ.SECTIONS.GENERAL.ABOUT.QUESTION' | translate }}</h2>
            <p>{{ 'APP.FAQ.SECTIONS.GENERAL.ABOUT.ANSWER' | translate }}</p>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="4">
            <div class="k-text-uppercase k-font-weight-bold">
                {{ 'APP.FAQ.SECTIONS.QUESTIONS.TITLE' | translate }}
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="4">
            <h2>{{ 'APP.FAQ.SECTIONS.QUESTIONS.ASSESSMENT_TYPES.QUESTION' | translate }}</h2>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="4">
            <br>
            <kendo-grid [kendoGridBinding]="tableAssessmentTypes" scrollable="none">
                <kendo-grid-column field="taxtype"
                    [title]=" 'APP.FAQ.SECTIONS.QUESTIONS.ASSESSMENT_TYPES.ANSWER.TAX_TYPE.TITLE' | translate "
                    [hidden]="false"></kendo-grid-column>
                <kendo-grid-column field="assessmenttype"
                    [title]=" 'APP.FAQ.SECTIONS.QUESTIONS.ASSESSMENT_TYPES.ANSWER.DOCUMENT_TYPE.TITLE' | translate "
                    [hidden]="false"></kendo-grid-column>
                <kendo-grid-column field="iteration"
                    [title]=" 'APP.FAQ.SECTIONS.QUESTIONS.ASSESSMENT_TYPES.ANSWER.ITERATION.TITLE' | translate "
                    [hidden]="false"></kendo-grid-column>

            </kendo-grid>

        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="4">
            <br>
            <h2>{{ 'APP.FAQ.SECTIONS.QUESTIONS.UPLOAD.QUESTION' | translate }}</h2>
            <p>{{ 'APP.FAQ.SECTIONS.QUESTIONS.UPLOAD.ANSWER' | translate }}</p>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="4">
            <div class=" k-text-uppercase k-font-weight-bold">
                {{ 'APP.FAQ.SECTIONS.FEATURES.TITLE' | translate }}
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="4">
            <br>
            <kendo-grid [kendoGridBinding]="tableFeatures" scrollable="none">
                <kendo-grid-column field="feature" title="Feature" [hidden]="false"></kendo-grid-column>

                <kendo-grid-column field="iteration" title="Iteration" [hidden]="false"></kendo-grid-column>

            </kendo-grid>

        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="4">
            <br>
            <div class="k-text-uppercase k-font-weight-bold">
                {{ 'APP.FAQ.SECTIONS.SUPPORT.TITLE' | translate }}
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item [row]="11" [col]="2" [colSpan]="4">

            <p>{{ 'APP.FAQ.SECTIONS.SUPPORT.BODY1' | translate }}</p>
            <p>{{ 'APP.FAQ.SECTIONS.SUPPORT.BODY2' | translate }}</p>
            <br>
            <ap-button [btnType]="'primary'" [label]="'BUTTON.YODA' | translate" (onClick)="goYoda()"></ap-button>
        </kendo-gridlayout-item>

    </kendo-gridlayout>
</div> -->