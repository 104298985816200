<form [formGroup]="corporateIncomeTaxSaUControllingCompanyForm1300" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Kommentare
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()" xbrlName="document_comment"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_legalRemedyClientDecision" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        overrideLabel="Begründung Rechtsbehelf für Mandant (extern):" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType" overrideLabel="Steuernummer">
                    </app-stare-textbox>
                </div>
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationDate"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-integer (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_assessmentYear"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-integer>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_addressee"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>

                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_copyFor"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataNumberOptionsSource]="
                            corporateIncomeTaxCopyForOptions
                        ">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row">
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>
                <div class="column" *ngIf="workflowType != 11">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>
            </div>
            <div class="row">
                <div [class]="
                        workflowType == 11 ? 'column-for-with-plus' : 'column'
                    ">
                    <app-stare-dropdown-string (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="legalEntities">
                    </app-stare-dropdown-string>
                </div>
                <div class="column-for-plus" *ngIf="workflowType == 11">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-textbox>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-dropdown (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTax_vdn" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataNumberOptionsSource]="vdnOptions">
                    </app-stare-dropdown>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Festgestellte Beträge
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Steueranrechung
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-sub-header">
                    <legend class="k-form-legend section-header">
                        Steuerpflichtige ausländische Einkünfte
                    </legend>
                </div>
            </div>
            <div formArrayName="documentPayload" *ngFor="
                    let payload of documentPayloadGetterOnlyControls;
                    let i = index
                ">
                <div [formGroupName]="i">
                    <div class="row-for-payload">
                        <div class="row">
                            <div class="column-for-header">
                                <button kendoButton rounded="none" style="
                                        margin-left: 703px;
                                        margin-top: -26px;
                                    " class="custom-rounded" fillMode="outline" [disabled]="true">
                                    Einkünfte
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-textbox (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    " xbrlName="id_companyIdentifier" [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType"
                                    overrideLabel="Name Staat/ Spezial-Investmentfond">
                                </app-stare-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="false" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                            <div class="column">
                                <app-stare-numerical (onFieldFocus)="
                                        onFieldFocusReceiver()
                                    "
                                    xbrlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt"
                                    [datasetId]="
                                        payload.controls['datasetId'].value
                                    " [form]="payload" [isReadOnly]="true" [workflowType]="workflowType">
                                </app-stare-numerical>
                            </div>
                        </div>
                        <div class="row-for-payload-button">
                            <div class="column">
                                <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                    [type]="'button'" (click)="
                                        deletePayload(
                                            documentPayloadGetter(),
                                            i,
                                            'DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome'
                                        )
                                    ">
                                    Lösche Einkünfte
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button kendoButton themeColor="primary" [type]="'button'" (click)="
                            addPayload(
                                'DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome'
                            )
                        ">
                        Einkünfte einfügen
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Feststellungen zu Mehr- und Minderabführungen
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Feststellungsgrundlagen
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome"
                        [datasetId]="headerDatasetId" [form]="corporateIncomeTaxSaUControllingCompanyForm1300"
                        [isReadOnly]="true" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <!-- <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
                <div class="column-for-text-area"
                    *ngIf="corporateIncomeTaxSaUControllingCompanyForm1300.controls['document_withoutReferenceValidation'].value==true">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutReferenceValidationExplanation" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_requestedDocuments" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div>
            <div class="row-for-comment-button" *ngIf="workflowType==11">
                <div class="column">
                    <kendo-splitbutton kendoButton themeColor="primary" [data]="commentOptions"> Kommentartext
                        hinzufügen
                    </kendo-splitbutton>
                </div>
            </div>
            <div class="row" *ngIf="workflowType==11">
                <div class="column-for-text-area ">
                    <app-stare-textarea (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_mail_reasonNotAccepted" [datasetId]='headerDatasetId'
                        [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [isReadOnly]="false"
                        [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>
            </div> -->
            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="corporateIncomeTaxSaUControllingCompanyForm1300" [auditResults]="auditResults"
                [workflowId]="workflowId" [is1300]="true"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>

<!-- 


<form [formGroup]="corporateIncomeTaxSaUControllingCompanyForm1300" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 120 },
        { height: 130 },
        { height: 50 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
                        { height: 90 },
						{ height: 20 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 130 },
						{ height: 20 },
						{ height: 112 },
						{ height: 162 },
						{ height: 20 },
						{ height: documentCITSaUTaxForeignIncomePayloadLength },
						{ height: 50 },
						{ height: 20 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 112 },
						{ height: 20 },
						{ height: 112 },
						{ height: 112 },
						{ height: 20 },
		                { height: 90 },
		                { height: withoutReferenceValidationExplanationRowHeight},
                        { height: sdcFieldsRowHeight },
                        { height: sdcFieldsRowHeight },]" [cols]="[{ width: 300}, { width: 360}, { width:50}]">

                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">

                    <legend class="k-form-legend section-header">
                        Kommentare</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_comment-'+headerDatasetId"
                            id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="'document_comment-'+headerDatasetId"
                            type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                            placeholder="{{getXbrlLabel('document_comment')}}" formControlName="document_comment"
                            autocomplete="off" [rows]="3" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="3">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_legalRemedyClientDecision-'+headerDatasetId"
                            id="document_legalRemedyClientDecisionLabel">Begründung Rechtsbehelf für
                            Mandant (extern):</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_legalRemedyClientDecision-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_legalRemedyClientDecision')"
                            placeholder="{{getXbrlLabel('document_legalRemedyClientDecision')}}"
                            formControlName="document_legalRemedyClientDecision" autocomplete="off" [rows]="3"
                            resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_legalRemedyClientDecision'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Steuernummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Steuernummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'id_notificationDate-'+headerDatasetId"
                            id="notificationDateLabel">{{getXbrlLabel('id_notificationDate')}}</kendo-label>
                        <kendo-datepicker (focus)="onFocusInputXbrl()"
                            [value]="formGroup.controls['id_notificationDate'].value" format="dd.MM.yyyy"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_notificationDate')"
                            [id]="'id_notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
                            placeholder="{{getXbrlLabel('id_notificationDate')}}" formControlName="id_notificationDate"
                            autocomplete="off">
                            <kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_notificationDate'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                    <kendo-formfield showErrors="always" showHints="always" class="part">
                        <kendo-label [for]="'id_legalEntityKey-'+headerDatasetId"
                            id="taxDeterminationLabel">{{getXbrlLabel('id_legalEntityKey')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'id_legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" formControlName="id_legalEntityKey"
                            (filterChange)="handleLegalEntitiesFilter($event)"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_legalEntityKey')"
                            [data]="legalEntities"
                            [style]="workflowType === 11 ? { 'width': '300px' } : {}"></kendo-dropdownlist>
                        <app-legalentity-add-button></app-legalentity-add-button>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_legalEntityKey'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_assessmentYear-'+headerDatasetId"
                            id="assessmentYearLabel">{{getXbrlLabel('id_assessmentYear')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" [spinners]="false" [decimals]="0"
                            [maxlength]="4" [changeValueOnScroll]="false" format="##"
                            [id]="'id_assessmentYear-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_assessmentYear')"
                            placeholder="{{getXbrlLabel('id_assessmentYear')}}" formControlName="id_assessmentYear"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_assessmentYear'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_addressee-'+headerDatasetId"
                            id="addressLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_addressee')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_addressee-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_addressee')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_addressee')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_addressee"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_addressee'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'document_corporateIncomeTaxSaUControllingCompany_copyFor-'+headerDatasetId"
                            id="copyForLabel">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_copyFor')}}</kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_copyFor-'+headerDatasetId"
                            [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_copyFor')"
                            [defaultItem]="defaultItem"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_copyFor"
                            [data]="corporateIncomeTaxCopyForOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_copyFor'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name-'+headerDatasetId"
                            id="organgesellshaftLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_name'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="8" [col]="2" [colSpan]="1" *ngIf="workflowType!=11">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_name-'+headerDatasetId"
                            id="refOrgangesellshaftLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controlledCompany_name')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_name-'+headerDatasetId"
                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_controlledCompany_name')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controlledCompany_name')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_controlledCompany_name"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_name'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber-'+headerDatasetId"
                            id="OrgGsStLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="9" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber-'+headerDatasetId"
                            id="RefOrgGsStLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber-'+headerDatasetId"
                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_controlledCompany_taxNumber'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name-'+headerDatasetId"
                            id="organträgerLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_name'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="10" [col]="2" [colSpan]="1" *ngIf="workflowType!=11">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_name-'+headerDatasetId"
                            id="refOrganträgerLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controllingCompany_name')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_name-'+headerDatasetId"
                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_controllingCompany_name')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controllingCompany_name')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_controllingCompany_name"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_name'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber-'+headerDatasetId"
                            id="organträgerStLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="11" [col]="2" [colSpan]="1" *ngIf="workflowType!=11">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber-'+headerDatasetId"
                            id="reforganträgerStLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')}}</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()"
                            [id]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber-'+headerDatasetId"
                            type="text" [readonly]="true" class="custom-fill-mode" fillMode="none" [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber"
                            autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_controllingCompany_taxNumber'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">
                        <kendo-label [for]="'document_corporateIncomeTax_vdn-'+headerDatasetId"
                            id="{{'document_corporateIncomeTax_vdnLabel'}}">
                            {{getXbrlLabel('document_corporateIncomeTax_vdn')}}
                        </kendo-label>
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
                            [id]="'document_corporateIncomeTax_vdn-'+headerDatasetId" [valuePrimitive]="true"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTax_vdn')"
                            [defaultItem]="defaultItem" formControlName="document_corporateIncomeTax_vdn"
                            [data]="vdnOptions">
                        </kendo-dropdownlist>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTax_vdn'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Festgestellte Beträge</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany-'+headerDatasetId"
                            id="fest1tLbl">{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="14" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany-'+headerDatasetId"
                            id="fest1refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_incomeAttributableToControllingCompany'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="15" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt-'+headerDatasetId"
                            id="fest1refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenKSt'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="16" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_addedToIncomeWhenESt'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="17" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_totalIncomeOfMaximumAmount'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="18" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_positiveIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="19" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreePartOfIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="20" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [tabindex]="-1" [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [changeValueOnScroll]="false"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestExpenses'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="21" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [tabindex]="-1" [readonly]="true" class="custom-fill-mode" fillMode="none"
                            [changeValueOnScroll]="false"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_interestIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="22" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation-'+headerDatasetId"
                            [tabindex]="-1"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_depreciation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="23" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="23" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompany'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="24" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="24" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_consideredDonationDeduction'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="25" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="25" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxFreeForeignIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="26" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="26" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_establishedAmount_taxableIncomeOfControlledCompanyItself'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="27" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Steueranrechung </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="28" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="28" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableIncomeTax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="29" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="29" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxCredit_chargeableSolidaritySurchargeOnIncomeTax'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="30" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Steuerpflichtige ausländische Einkünfte </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="31" [col]="1" [colSpan]="2">
                    <div formArrayName="documentPayload"
                        *ngFor="let payload of documentPayloadGetterOnlyControls; let i = index">
                        <div [formGroupName]="i">
                            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
												{ height: 100 }, 
												{ height: 150 }, 
												{ height: 150 },  
												{ height: 100 }, 
												{ height: 150 }, 
												{ height: 150 },
												{ height: 50 }]" [cols]="[{ width: 370 }, { width: 430 }]" class="payload">

                                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always">
                                        <kendo-label
                                            [for]="'id_companyIdentifier-'+payload.controls['datasetId'].value">Steuernummer</kendo-label>
                                        <kendo-textbox (focus)="onFocusInputXbrl()"
                                            [id]="'id_companyIdentifier-'+payload.controls['datasetId'].value"
                                            type="text"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'id_companyIdentifier')"
                                            placeholder="Steuernummer" formControlName="id_companyIdentifier"
                                            autocomplete="off">
                                        </kendo-textbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')}}"
                                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt')}}"
                                            formControlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGKSt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')}}"
                                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="3" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt')}}"
                                            formControlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignIncomeAccordingTo34cAbs1EStGESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')}}"
                                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt')}}"
                                            formControlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1ESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')}}"
                                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="5" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt')}}"
                                            formControlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGKSt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')}}
                                        </kendo-label>
                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [changeValueOnScroll]="false" [decimals]="2"
                                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')"
                                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')}}"
                                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="6" [col]="2" [colSpan]="1">
                                    <kendo-formfield showHints="always" showErrors="always" class="part">
                                        <kendo-label
                                            [for]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value">
                                            {{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')}}
                                        </kendo-label>

                                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal"
                                            [spinners]="false" [readonly]="true" class="custom-fill-mode"
                                            fillMode="none" [changeValueOnScroll]="false" [tabindex]="-1"
                                            [id]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt-'+payload.controls['datasetId'].value"
                                            [ngClass]="assignClassByDatasetIDandXbrlName(payload.controls['datasetId'].value,'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')"
                                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt')}}"
                                            formControlName="corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt"
                                            autocomplete="off">
                                        </kendo-numerictextbox>
                                        <app-validation-message-formcontrol
                                            [datasetIdInput]="payload.controls['datasetId'].value"
                                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxForeignIncome_foreignTaxesAccordingTo34cAbs1EStGESt'"></app-validation-message-formcontrol>
                                    </kendo-formfield>
                                </kendo-gridlayout-item>
                                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
                                    <button kendoButton fillMode="outline" themeColor="primary" [size]="'small'"
                                        [type]="'button'"
                                        (click)='deletePayload(documentPayloadGetter(), i,"DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome")'>Lösche
                                        Einkünfte</button>
                                </kendo-gridlayout-item>

                            </kendo-gridlayout>
                        </div>
                    </div>

                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="32" [col]="1" [colSpan]="2">
                    <div class="part">
                        <button kendoButton themeColor="primary" [type]="'button'"
                            (click)='addPayload("DocumentCorporateIncomeTaxSaUControllingCompanyTaxableForeignIncome")'>Einkünfte
                            einfügen</button>
                    </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="33" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Feststellungen zu Mehr- und Minderabführungen </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="34" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="34" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="35" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="35" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_additionalPaymentDueToPreOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="36" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="36" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="37" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="37" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime-'+headerDatasetId">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')}}</kendo-label>

                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_shortfallPaymentDueToPreOrganicTime'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="38" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Feststellungsgrundlagen</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="39" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="39" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_taxableNetProfitLoss'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="40" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome-'+headerDatasetId">
                            {{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')}}
                        </kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [changeValueOnScroll]="false" [decimals]="2"
                            [id]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')"
                            placeholder="{{getXbrlLabel('document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')}}"
                            formControlName="document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="40" [col]="2" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label
                            [for]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome-'+headerDatasetId"
                            id="fest2refLbl">{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')}}</kendo-label>
                        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
                            [readonly]="true" class="custom-fill-mode" fillMode="none" [changeValueOnScroll]="false"
                            [tabindex]="-1"
                            [id]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome-'+headerDatasetId"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')"
                            placeholder="{{getXbrlLabel('corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome')}}"
                            formControlName="corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome"
                            autocomplete="off">
                        </kendo-numerictextbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'corporateIncomeTaxSaUControllingCompany_taxBasisDetermination_totalIncome'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="41" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Prüfungsergebnis</legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="42" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="initial" showErrors="initial" class="part">

                        <kendo-label [for]="'document_withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
                            {{getXbrlLabel('document_withoutReferenceValidation')}}
                        </kendo-label>
                        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox
                            style="margin-left: 5px ;margin-top: 8px ;" (change)="changeExplanationRowHeight()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidation')"
                            formControlName="document_withoutReferenceValidation"
                            [id]="'document_withoutReferenceValidation-'+headerDatasetId" />
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidation'"></app-validation-message-formcontrol>

                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="43" [col]="1" [colSpan]="2"
                    *ngIf="formGroup.controls['document_withoutReferenceValidation'].value==true">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_withoutReferenceValidationExplanation-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}</kendo-label>
                        <kendo-textarea (focus)="onFocusInputXbrl()"
                            [id]="'document_withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_withoutReferenceValidationExplanation')"
                            placeholder="{{getXbrlLabel('document_withoutReferenceValidationExplanation')}}"
                            formControlName="document_withoutReferenceValidationExplanation" autocomplete="off"
                            [rows]="2" resizable="vertical">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>

                <kendo-gridlayout-item [row]="44" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_requestedDocuments-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_requestedDocuments')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_requestedDocuments-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_requestedDocuments')"
                            placeholder="{{getXbrlLabel('document_mail_requestedDocuments')}}" resizable="none"
                            formControlName="document_mail_requestedDocuments" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_requestedDocuments'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="45" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

                    <kendo-formfield showHints="always" showErrors="always" class="part">
                        <kendo-label [for]="'document_mail_reasonNotAccepted-'+headerDatasetId"
                            id="docLabel">{{getXbrlLabel('document_mail_reasonNotAccepted')}}</kendo-label>
                        <kendo-textarea [id]="'document_mail_reasonNotAccepted-'+headerDatasetId" type="string"
                            (focus)="onFocusInputXbrl()"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_mail_reasonNotAccepted')"
                            placeholder="{{getXbrlLabel('document_mail_reasonNotAccepted')}}" resizable="none"
                            formControlName="document_mail_reasonNotAccepted" autocomplete="off" [rows]="3">
                        </kendo-textarea>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'document_mail_reasonNotAccepted'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>

    </fieldset>
    <br>
</form> -->