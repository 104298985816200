import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailTemplateForCreationItem, MailTemplateItem, MailTemplateForUpdateItem } from 'app/_models/mail-template.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiMailTemplateService {

  constructor(
    private http: HttpClient) { }

  /**
 * Add a new Mail template.
 * @param item User item as an object. See the Swagger documentation for more information about schema and example values.
 * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
*/
  addMailTemplate(item: MailTemplateForCreationItem): Observable<MailTemplateItem> {
    return this.http.post<MailTemplateItem>(ENVIRONMENT.apiBaseUrl + 'MailTemplate/AddAsync', item, HTTP_OPTIONS);
  }

  /**
   * Update a Template.
   * @param templateId ID of the template.
   * @param item  item as an object. See the Swagger documentation for more information about schema and example values.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
  */
  updateMailTemplate(templateId: number, item: MailTemplateForUpdateItem): Observable<MailTemplateItem> {
    return this.http.put<MailTemplateItem>(`${ENVIRONMENT.apiBaseUrl}MailTemplate/UpdateAsync/${templateId}`, item, HTTP_OPTIONS);
  }

  /**
   * Delete a template.
   * @param templateId ID of the template.
   * @returns API response as a promise. See the Swagger documentation for more information about schema and example values.
   */
  deleteMailTemplate(templateId: number): Observable<MailTemplateItem> {
    return this.http.delete<MailTemplateItem>(`${ENVIRONMENT.apiBaseUrl}MailTemplate/DeleteAsync/${templateId}`, HTTP_OPTIONS);
  }


  /**
   * Get all templates.
   * @returns API response as an observable object. See the Swagger documentation for more information about schema and example values.
   */
  getAllMailTemplates(): Observable<MailTemplateItem[]> {
    return this.http.get<MailTemplateItem[]>(ENVIRONMENT.apiBaseUrl + 'MailTemplate/GetAllAsync', HTTP_OPTIONS)
      .pipe(
        retry(1)
      )
  }


}
