
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import {
  DialogRef, DialogContentBase
} from "@progress/kendo-angular-dialog";


@Component({
  selector: 'admin-archive-edit-dialog',
  templateUrl: './admin-archive-edit-dialog.component.html',
  styleUrls: ['./admin-archive-edit-dialog.component.scss'],
  standalone: false
})
export class AdminArchiveEditDialogComponent extends DialogContentBase implements OnInit {


  @Output() public submitClick: EventEmitter<void> = new EventEmitter();

  constructor(
    public dialog: DialogRef,

  ) { super(dialog); }

  ngOnInit(): void {

  }



  submit(): void {

    this.submitClick.emit();
    this.dialog.close();
  }


}
