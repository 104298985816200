<div class="stareTopic">
    {{ 'APP.SETTINGS.SETTINGS_WORKFLOW.TITLE' | translate }}
</div>



<div [ngStyle]="{'height.px': pageHeight}">
    <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'" filterable="menu"
        [loading]="!finishedLoading" (cellClick)="cellClickHandler($event)" (dblclick)="editHandler(grid.activeRow)"
        (add)="addHandler()" [resizable]="true" [filter]="currentFilter || {logic: 'and', filters: []}"
        [selectable]="{ checkboxOnly: false, mode: 'single' }" [columnMenu]="menuSettings" [navigable]="true"
        [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [sort]="currentSort" (sortChange)="sortChange($event)" [pageSize]="pageSize" [sortable]="true">

        <ng-template kendoGridToolbarTemplate>
            <kendo-textbox [placeholder]=" 'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.SEARCH' | translate "
                (valueChange)="onFilter($event)" [style.width.px]="300" [clearButton]="true"></kendo-textbox>
            <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                title="{{ 'BUTTON.CLEAR_FILTER' | translate }}">

            </button>
            <kendo-grid-spacer></kendo-grid-spacer>
            <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                title="{{ 'BUTTON.ADD' | translate }}">
            </button>

            <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                title="{{ 'BUTTON.UPDATE' | translate }}">
            </button>
            <!--
            <button kendoButton (click)="exportToExcel(grid)" [svgIcon]="excelSVG" kendoTooltip
                title="{{ 'BUTTON.EXPORT_EXCEL' | translate }}">

            </button>
-->
        </ng-template>



        <kendo-grid-messages [pagerPage]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.PAGER.PAGE' | translate"
            [pagerOf]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.PAGER.OF' | translate"
            [pagerItems]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.PAGER.ITEM' | translate"
            [pagerItemsPerPage]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.PAGER.TASK_PER_PAGE' | translate"
            [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
            [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
            [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
            [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
            [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate"
            [filterAfterOrEqualOperator]="'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate"
            [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate"
            [filterBeforeOperator]="'TABLE_MENU.BEFORE_OPERATOR' | translate"
            [filterBeforeOrEqualOperator]="'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate"
            [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate" [filterClearButton]="'BUTTON.CLEAR' | translate"
            [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
            [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate"
            [filterEndsWithOperator]="'TABLE_MENU.ENDS_WITH_OPERATOR' | translate"
            [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
            [filterFilterButton]="'BUTTON.FILTER' | translate"
            [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
            [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
            [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
            [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate"
            [filterIsNotEmptyOperator]="'TABLE_MENU.IS_NOT_EMPTY' | translate"
            [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
            [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate" [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
            [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
            [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate"
            [filterNotContainsOperator]="'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate"
            [filterNotEqOperator]="'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate"
            [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate"
            [filterStartsWithOperator]="'TABLE_MENU.STARTS_WITH' | translate"
            [noRecords]="'TABLE_MENU.NO_RECORD' | translate"
            [setColumnPosition]="'TABLE_MENU.SET_COLUMN_POSITION' | translate"
            [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
            [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
        </kendo-grid-messages>

        <kendo-grid-column field="wbsCustomer.wbs"
            [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.WBS' | translate"></kendo-grid-column>
        <kendo-grid-column field="dataTypeNameDE"
            [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.TYPE_OF_TAX_ASSESSMENT' | translate"></kendo-grid-column>
        <kendo-grid-column field="title"
            [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.TITLE' | translate"></kendo-grid-column>


        <ng-template kendoGridNoRecordsTemplate>

            <p *ngIf="finishedLoading">{{ 'TABLE_MENU.NO_RECORD' | translate }}</p>
        </ng-template>

        <!--
        <kendo-grid-excel fileName="WBS-Einstellungen.xlsx"></kendo-grid-excel>
-->
    </kendo-grid>
</div>


<kendo-dialog *ngIf="active" [width]="600" [height]="750" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{'BUTTON.ADD' | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
        <kendo-formfield>
            <kendo-label [for]="title"
                [text]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.TITLE' | translate"></kendo-label>
            <kendo-textbox #title formControlName="title"
                [placeholder]="'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.TITLE' | translate"
                required></kendo-textbox>
            <kendo-formerror>{{
                'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.TITLE_FIELD.ERROR.REQUIRED' | translate
                }}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="wbsId" text="WBS"></kendo-label>
            <kendo-dropdownlist #wbsId formControlName="wbsId" [data]="wbsList" (selectionChange)="onSelectWbs($event)"
                [defaultItem]="{ text: 'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.WBS' | translate, value: null }"
                textField="wbs" valueField="id" [valuePrimitive]="true" required></kendo-dropdownlist>
            <kendo-formerror>{{ 'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.WBS.ERROR.REQUIRED' |
                translate }}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
            <kendo-label [for]="datatypeName" text="Bescheidart"></kendo-label>
            <kendo-dropdownlist #datatypeName formControlName="datatypeName" [data]="dataTypeDropDownList"
                [defaultItem]="{ label: 'APP.SETTINGS.SETTINGS_WORKFLOW.TABLE.HEADER.TYPE_OF_TAX_ASSESSMENT' | translate, value: null }"
                textField="label" valueField="value" [valuePrimitive]="true"
                [itemDisabled]="itemDisabled"></kendo-dropdownlist>
            <kendo-formerror>{{
                'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.TYPE_OF_TAX_ASSESSMENT.ERROR.REQUIRED' |
                translate }}</kendo-formerror>
        </kendo-formfield>


        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="sendTaskMail" type="checkbox" kendoCheckBox formControlName="sendTaskMail" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="sendTaskMail" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.SEND_TASK_MAIL.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.SEND_TASK_MAIL.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="onlyDigitize" type="checkbox" kendoCheckBox formControlName="onlyDigitize" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="onlyDigitize" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.ONLY_DIGITIZE.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.ONLY_DIGITIZE.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield>
        <!-- <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="editorsAllowed" type="checkbox" kendoCheckBox formControlName="editorsAllowed" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="editorsAllowed" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.EDITORS_ALLOWED.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.EDITORS_ALLOWED.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield> -->
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="withDeadlineManagement" type="checkbox" kendoCheckBox
                        formControlName="withDeadlineManagement" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="withDeadlineManagement" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.WITH_DEADLINE_MANAGEMENT.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.WITH_DEADLINE_MANAGEMENT.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield>
        <!-- <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="legalEntityFromReferenceData" type="checkbox" kendoCheckBox
                        formControlName="legalEntityFromReferenceData" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="legalEntityFromReferenceData" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.LEGAL_ENTITY_FROM_REFERENCE.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.LEGAL_ENTITY_FROM_REFERENCE.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield> -->
        <!-- <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="legalEntityFromWbsForCorporateIncomeTax" type="checkbox" kendoCheckBox
                        formControlName="legalEntityFromWbsForCorporateIncomeTax" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="legalEntityFromWbsForCorporateIncomeTax"
                    kendoTooltip
                    [title]="'Für die autom. Zuordnung ist ein Mapping zwischen Steuerpflichtigen und der WBS notwendig. Der Steuerpflichtige wird nicht aus dem Bescheid ermittelt.'">Für
                    KSt-Bescheide: Steuerpflichtige anhand der WBS direkt
                    zugeordnet.</label>
            </ng-container>
        </kendo-formfield> -->
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="addCalculatedValues" type="checkbox" kendoCheckBox
                        formControlName="addCalculatedValues" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="addCalculatedValues" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.ADD_CALCULATED_VALUE.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.ADD_CALCULATED_VALUE.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="automaticProcessing" type="checkbox" kendoCheckBox
                        formControlName="automaticProcessing" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="automaticProcessing" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.AUTOMATIC_PROCESSING.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.AUTOMATIC_PROCESSING.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield>

        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <kendo-numerictextbox type="decimal" [spinners]="false" id="percentageToReview" format="# '%'"
                        [disabled]="!editForm.get('automaticProcessing')?.value" [changeValueOnScroll]="false"
                        [decimals]="0" placeholder="Prozent" [min]="0" [max]="100" formControlName="percentageToReview"
                        autocomplete="off" [style.width.px]="70">
                    </kendo-numerictextbox>

                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="percentageToReview" kendoTooltip
                    [title]="'Anteil der Bescheide, die immer durch den Review sollen'">Anteil der Bescheide, die immer
                    durch den Review sollen</label>
            </ng-container>
        </kendo-formfield>

        <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="sendSignOutMail" type="checkbox" kendoCheckBox formControlName="sendSignOutMail" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="sendSignOutMail" kendoTooltip
                    [title]="'Versenden einer E-Mail an die eingetragende E-Mail Adresse, wodurch der Empfänger gebeten wird den Bescheid aus dem Fristmanagement herauszunehmen (Fristaustragung).'">Mail
                    nach Bescheidprüfung</label>
            </ng-container>
        </kendo-formfield>
        <kendo-formfield>
            <ng-container>
                <kendo-textbox #sendSignOutMailAddress formControlName="sendSignOutMailAddress"
                    [placeholder]="'E-Mail Adresse Fristaustragung'"></kendo-textbox>


            </ng-container>
        </kendo-formfield>


        <!-- <kendo-formfield>
            <ng-container>
                <span class="k-checkbox-wrap">
                    <input id="createClientMail" type="checkbox" kendoCheckBox formControlName="createClientMail" />
                </span>
                <label [labelClass]="false" class="k-checkbox-label" for="createClientMail" kendoTooltip
                    [title]="'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.CREATE_CLIENT_NAME.TOOLTIP' | translate">{{'APP.SETTINGS.SETTINGS_WORKFLOW.MODAL.UPDATE_WORKFLOW_SETTINGS.CREATE_CLIENT_NAME.TITLE'
                    | translate}}</label>
            </ng-container>
        </kendo-formfield> -->
    </form>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [hidden]="!myuser.isAdmin" [disabled]="isNew"
            (click)="onDelete($event)">
            {{ 'BUTTON.DELETE' | translate }}
        </button>
        <button kendoButton themeColor="primary" [hidden]="!myuser.isAdmin" [disabled]="!editForm.valid"
            (click)="onSave($event, false)">
            {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button kendoButton themeColor="primary" [hidden]="!myuser.isAdmin"
            [disabled]="!editForm.get('automaticProcessing')?.value" (click)="onSave($event, true)">
            Schwellenwerte
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>






<kendo-dialog *ngIf="showThresholdDialog" [width]="1500" [height]="750" (close)="closeThresholdDialog()">
    <kendo-dialog-titlebar *ngIf="isNew">
        Schwellenwerte und Wesentlichkeitsgrenzen
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{'BUTTON.EDIT' | translate}}
    </kendo-dialog-titlebar>

    <ng-template #toolTipInnerGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip #tooltip="kendoTooltip" showOn="none" [tooltipTemplate]="toolTipInnerGridTemplate"
        position="bottom" filter=".k-grid td" (mouseover)="this.tooltipService.showTooltipInner($event, tooltip)">
        <kendo-grid #gridThreshold [kendoGridBinding]="thresholdList" [height]="600" [pageable]="{
			   buttonCount: 7,
			info: true,
			type: 'numeric',
			 pageSizes: [20, 30, 50],
			previousNext: true,
			position: 'bottom'
		  }" [sortable]="true" [resizable]="true" [navigable]="true" [pageSize]="pageSize"
            [kendoGridReactiveEditing]="createFormGroup">

            <ng-template kendoGridToolbarTemplate>
                <button kendoGridAddCommand>Neuer Eintrag</button>
            </ng-template>
            <kendo-grid-column field="dataTypeName" title="Datatype">

                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <kendo-dropdownlist kendoDropDownFilter [popupSettings]="{ width: 700, height: 200 }"
                        [data]="listservice.dataTypeItemList" textField="labelDEWithName" valueField="name"
                        [valuePrimitive]="true" [formControl]="formGroup.get('dataTypeName')">
                    </kendo-dropdownlist>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    {{ getDataType(dataItem.dataTypeName) }}
                </ng-template>

            </kendo-grid-column>

            <kendo-grid-column field="xbrlName" title="Xbrl">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">

                    <kendo-dropdownlist kendoDropDownFilter [popupSettings]="{ width: 1250, height: 200 }"
                        [data]="listservice.xbrlTypes" textField="labelForDisplay" valueField="name"
                        [valuePrimitive]="true" [formControl]="formGroup.get('xbrlName')">

                    </kendo-dropdownlist>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    {{ getXBRL(dataItem.xbrlName) }}
                </ng-template>

            </kendo-grid-column>


            <kendo-grid-column field="thresholdValue" editor="numeric" format="c" title="Wert"></kendo-grid-column>

            <kendo-grid-column field="typeOfThreshold" title="Typ">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <kendo-dropdownlist #dropdownlist kendoDropDownFilter [popupSettings]="{ width: 400, height: 100 }"
                        [data]="typeOfThresholdList" textField="name" valueField="id" [valuePrimitive]="true"
                        [formControl]="formGroup.get('typeOfThreshold')">
                    </kendo-dropdownlist>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    {{ getThresholdType(dataItem.typeOfThreshold) }}
                </ng-template>

            </kendo-grid-column>

            <kendo-grid-command-column title="Aktion" [width]="300">
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                    <button kendoGridEditCommand [primary]="true">Bearbeiten</button>
                    <button kendoGridRemoveCommand>Entfernen</button>
                    <button kendoGridSaveCommand [disabled]="formGroup.invalid">
                        {{ isNew ? "Anlegen" : "Update" }}
                    </button>
                    <button kendoGridCancelCommand>
                        {{ isNew ? "Verwerfen" : "Abbrechen" }}
                    </button>
                </ng-template>
            </kendo-grid-command-column>

        </kendo-grid>
    </div>

    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [hidden]="!myuser.isAdmin" (click)="onSaveThresholds()">
            {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button kendoButton (click)="closeThresholdDialog()">
            {{ 'BUTTON.CANCEL' | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>