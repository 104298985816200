import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';
@Component({
  selector: 'app-doc-property-tax-base1300',
  templateUrl: './doc-property-tax-base1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocPropertyTaxBase1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.propertyTaxBaseAssessmentForm1300;
  }




  //#region Form ======================================================================================================

  /** Grundsteuermessbetrag, Rechtsmittel-Check, State 1300 */
  public propertyTaxBaseAssessmentForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_withoutEffectiveDateRule: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""),
    realEstate_effectiveDate: new UntypedFormControl(""), //reference stichtag
    realEstate_base: new UntypedFormControl(""), //reference 
    document_realEstate_base: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),
    document_hasThresholdLimit: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    id_legalEntityKeyReference: new UntypedFormControl(""), //referenz legalentity
    document_isLeaseHold: new UntypedFormControl(""),
    id_communeAGS: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_missingBankAccountDetails: new UntypedFormControl(""),
  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }



}
