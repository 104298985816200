<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td"
        (mouseover)="this.tooltipService.showTooltipHeader($event,this.tooltipDir)"><kendo-grid #clientcontactgrid
            [data]="clientdetails" scrollable="none" [loading]="!finishedLoading" (remove)="onChildDelete($event)"
            (edit)="editClientContactHandler($event)" (add)="addClientContactHandler()" [navigable]="true"
            kendoGridFocusable>

            <ng-template kendoGridToolbarTemplate>


                <button kendoButton (click)="addClientContactHandler()" type="button" kendoTooltip
                    title="Neues Ansprechpartner hinzufügen.">
                    {{'BUTTON.ADD_CONTACT' | translate}}
                </button>


            </ng-template>
            <kendo-grid-column field="mailAddress" [autoSize]="true" [width]="300"
                [title]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.MAIL_ADDRESS.LABEL' | translate">
            </kendo-grid-column>
            <kendo-grid-column field="firstname"
                [title]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.FIRST_NAME.LABEL' | translate">
            </kendo-grid-column>
            <kendo-grid-column field="lastname"
                [title]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LAST_NAME.LABEL' | translate">
            </kendo-grid-column>
            <kendo-grid-column field="useInCc" [title]="'In Cc'"></kendo-grid-column>
            <kendo-grid-command-column title="Aktion" [width]="220">
                <ng-template kendoGridCellTemplate let-isNew="isChildNew">
                    <button kendoGridEditCommand> {{'BUTTON.EDIT' | translate}}</button>
                    <button kendoGridRemoveCommand> {{'BUTTON.DELETE' | translate}}</button>
                </ng-template>
            </kendo-grid-command-column>
            <ng-template kendoGridNoRecordsTemplate>

                <p>{{ 'APP.SETTINGS.MAILING_METADATA.TABLE.NO_RECORDS_CHILD' | translate }}</p>
            </ng-template>
        </kendo-grid>
    </div>
    <kendo-dialog *ngIf="childActive" [width]="700" [height]="550" (close)="closeChildForm()">
        <kendo-dialog-titlebar *ngIf="isChildNew">
            {{'BUTTON.ADD' | translate }}
        </kendo-dialog-titlebar>

        <kendo-dialog-titlebar *ngIf="!isChildNew">
            {{'BUTTON.EDIT' | translate}}
        </kendo-dialog-titlebar>

        <form class="k-form k-form-md k-form-vertical" [formGroup]="editChildForm">


            <div class="form-row">
                <kendo-formfield>
                    <kendo-label [for]="mailAddress"
                        [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.MAIL_ADDRESS.LABEL' | translate"></kendo-label>
                    <kendo-textbox #mailAddress formControlName="mailAddress" required></kendo-textbox>
                    <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.MAIL_ADDRESS.ERROR.REQUIRED' |
                        translate}}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="useInCc" [text]="'In Cc (Kopie an) setzen'"></kendo-label>
                    <ng-container>
                        <span class="k-checkbox-wrap">
                            <input id="useInCc" type="checkbox" kendoCheckBox formControlName="useInCc" #useInCc />
                        </span>
                        <label [labelClass]="false" class="k-checkbox-label" for="useInCc" kendoTooltip
                            [title]="'Diesen Eintrag in das Feld Cc setzen.'"></label>
                    </ng-container>
                </kendo-formfield>
            </div>
            <div class="form-row">
                <kendo-formfield>
                    <kendo-label [for]="gender"
                        [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.GENDER.LABEL' | translate"></kendo-label>

                    <kendo-dropdownlist [data]="genderList" [valuePrimitive]="true" formControlName="gender" #gender
                        [textField]="'labelDE'" [valueField]="'value'"></kendo-dropdownlist>
                    <kendo-formerror>{{
                        'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LANGUAGE.ERROR.REQUIRED' | translate
                        }}</kendo-formerror>
                    <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.GENDER.ERROR.REQUIRED' |
                        translate}}</kendo-formerror>
                </kendo-formfield>
            </div>
            <div class="form-row">
                <kendo-formfield>
                    <kendo-label [for]="firstname"
                        [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.FIRST_NAME.LABEL' | translate"></kendo-label>
                    <kendo-textbox #firstname formControlName="firstname"></kendo-textbox>
                    <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.FIRST_NAME.ERROR.REQUIRED' |
                        translate}}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="lastname"
                        [text]="'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LAST_NAME.LABEL' | translate"></kendo-label>
                    <kendo-textbox #lastname formControlName="lastname"></kendo-textbox>
                    <kendo-formerror>{{'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.LAST_NAME.ERROR.REQUIRED' |
                        translate}}</kendo-formerror>
                </kendo-formfield>
            </div>


            <div style="color: red;" *ngIf="editChildForm.errors?.invalidNames"><br>
                Vorname und Nachname müssen leer sein, wenn das Geschlecht nicht gesetzt ist.
            </div>
            <div style="color: red;" *ngIf="editChildForm.errors?.emptyNames"><br>
                Vorname oder Nachname müssen angegeben sein, wenn das Geschlecht gesetzt ist.
            </div>
            <div style="color: red;" *ngIf="editChildForm.errors?.invalidGender"><br>
                Geschlecht muss gesetzt sein, wenn Vorname oder Nachname angegeben sind.
            </div>

        </form>

        <kendo-dialog-actions layout="start">
            <button kendoButton themeColor="primary" [disabled]="!editChildForm.valid" (click)="onSave($event)">
                {{'BUTTON.SAVE' | translate}}
            </button>
            <button kendoButton (click)="onCancel($event)">
                {{'BUTTON.CANCEL' | translate}}
            </button>
        </kendo-dialog-actions>
    </kendo-dialog>