import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { ColumnMenuSettings, GridComponent, GridSize } from '@progress/kendo-angular-grid';
import { SVGIcon, arrowRotateCwIcon, checkIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';

import { SortDescriptor } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { LogItem } from 'app/_models/log-item.model';
import { FileItem } from 'app/_models/file.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiClientEngagementMaintenanceService } from 'app/_services/StareApiMaintenance/client-engagement-maintenance.service';
import { ApiFileMaintenanceService } from 'app/_services/StareApiMaintenance/file-maintenance.service';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-settings-log',
  templateUrl: './settings-log.component.html',
  styleUrls: ['./settings-log.component.scss'],
  standalone: false
})
export class SettingsLogComponent {

  public smallSize: GridSize = "small";
  /** Displayed (filtered) data */
  public tableData = [] as LogItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as LogItem[];
  /** API request is finished */
  public finishedLoading = false;


  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public tooltipDir!: TooltipDirective;
  // ===================================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private dateTimeService: DateTimeService,
    private apiFileMaintenanceService: ApiFileMaintenanceService,
    private apiClientEngagementMaintenanceService: ApiClientEngagementMaintenanceService,
    public tooltipService: TooltipService,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.LOG.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

  }

  // == Resize Grid ==========================================================================

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }

  public pageHeight: number = window.innerHeight - 125;

  // == Item Change handling ================================================================
  //number of values returned from the backend
  //5000 as default
  public items = 5000;

  public changeItemNumber(value: number): void {
    this.items = value;
  }

  saveItemNumber(): void {
    this.loadData();
  }

  // == Load Grid Data ==========================================================================

  loadData(): void {
    this.finishedLoading = false;

    // this.apiClientEngagementMaintenanceService.getAllLogs(this.items,).subscribe({
    this.apiClientEngagementMaintenanceService.getAllLogsWithFilter(this.items, this.messageFilter, this.idFilter).subscribe({
      next: (res: LogItem[]) => {

        res.forEach((item: LogItem) => {
          if (item.createdDate) {
            item.createdDate = this.dateTimeService.convertUTCTimeToLocalTime(item.createdDate)!;
          }
        });

        // Get all files
        this.apiFileMaintenanceService.getAllFiles().subscribe({
          next: (resFiles: FileItem[]) => {

            // Add file name to log item
            res.forEach((logItem: LogItem) => {
              resFiles.forEach((fileItem: FileItem) => {
                if (logItem.fileId == fileItem.fileId) {
                  logItem.fileName = fileItem.name;
                }
              });
            });

            this.tableData = res;
            this.tableDataOriginal = res;
            this.finishedLoading = true;
          },
          error: (err: HttpErrorResponse) => {
            console.error(err);
            this.finishedLoading = true;
          }
        });


      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });


  }

  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 50;



  // Icons
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  public saveItemsSVG: SVGIcon = checkIcon;


  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed'
  };

  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    //this.loadData();
  }

  // Escel Export 
  // public exportToExcel(grid: GridComponent): void {
  //   grid.saveAsExcel();
  // }

  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;
  public messageFilter: string | null = null;
  public idFilter: number | null = null;

  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }



  public filterChange(filter: CompositeFilterDescriptor, column: string): void {
    this.currentFilter.filters.push(filter);
    if (column == "workflowId") {
      this.idFilter = filter.filters.values().next().value.value;
    }
    if (column == "message") {
      this.messageFilter = filter.filters.values().next().value.value;
    }
    this.loadData();
  }
  /** Filter all displayed columns */


  public onFilterID(value: string): void {
    this.idFilter = Number(value);

  }

  public onFilterMessage(value: string): void {
    this.messageFilter = value;

  }

  saveFilter(): void {

    this.loadData();
  }
  removeFilter(): void {
    // this.currentFilter = {
    //   logic: "or",
    //   filters: [],
    // }
    this.messageFilter = null;
    this.idFilter = null;
    this.loadData();
  }

  // == Format =============================

  private findFirstPointOutsideQuotes(message: string): number {
    let inSingleQuote = false;
    let inDoubleQuote = false;

    for (let i = 0; i < message.length; i++) {
      const char = message[i];
      if (char === "'" && !inDoubleQuote) {
        inSingleQuote = !inSingleQuote;
      } else if (char === '"' && !inSingleQuote) {
        inDoubleQuote = !inDoubleQuote;
      } else if (char === '.' && !inSingleQuote && !inDoubleQuote) {
        return i;
      }
    }

    return -1; // Kein Punkt außerhalb von Anführungszeichen gefunden  
  }


  /** Split a message into two elements (bevor and after a dot) */
  public formatMessage(message: string, part: number): string {
    const index = this.findFirstPointOutsideQuotes(message);

    // No point found outside of quotes. Return the whole message (but only if part == 1)
    if (index === -1) {
      if (part == 1) {
        return message;
      }
      return "";
    }

    const beforeDot = message.substring(0, index + 1);    // Part == 1
    const afterDot = message.substring(index + 1);        // Part == 2

    if (part == 1) {
      return beforeDot;
    } else {
      return afterDot;
    }

  }




}




