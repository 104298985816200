import { Component } from '@angular/core';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-corporate-income-tax-add-carry-forward',
  templateUrl: './doc-corporate-income-tax-add-carry-forward.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocCorporateIncomeTaxAddCarryForwardComponent extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,
    dateTimeService: DateTimeService,
    apiDatasetService: ApiDatasetService,
    apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.corporateIncomeTaxAddCarryForwardForm;
  }






  //#region Form ======================================================================================================
  public corporateIncomeTaxAddCarryForwardForm = new UntypedFormGroup({
    id_notificationDate: new UntypedFormControl(""),
    id_companyIdentifier: new UntypedFormControl(""),
    reference_companyIdentifier: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    document_cutOffDate: new UntypedFormControl(""), //Stichtag
    document_corporateIncomeTax_vdn: new UntypedFormControl(""),
    document_corporateIncomeTaxAddCarryForward_amount: new UntypedFormControl(""),
    document_corporateIncomeTaxAddCarryForward_amountPreviousYear: new UntypedFormControl(""),
    document_corporateIncomeTaxAddCarryForward_amountCurrentYear: new UntypedFormControl(""),
    document_corporateIncomeTaxAddCarryForward_amountDeducted: new UntypedFormControl(""),

    corporateIncomeTaxAddCarryForward_amount: new UntypedFormControl(""),
    corporateIncomeTaxAddCarryForward_amountPreviousYear: new UntypedFormControl(""),
    corporateIncomeTaxAddCarryForward_amountCurrentYear: new UntypedFormControl(""),
    corporateIncomeTaxAddCarryForward_amountDeducted: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_mail_requestedDocuments: new UntypedFormControl(""),
    document_mail_reasonNotAccepted: new UntypedFormControl(""),
    document_missingBankAccountDetails: new UntypedFormControl(""),
    id_notificationName: new UntypedFormControl(""),

  });

  //#endregion



  addSingularPayload(): void {
    //no payload 
  }


}
