
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  // Overloaded method declarations
  convertUTCTimeToLocalTime(dateInUTC: Date | null): Date | null;
  convertUTCTimeToLocalTime(dateInUTC: Date): Date;


  // Method implementation
  convertUTCTimeToLocalTime(dateInUTC: Date | null): Date | null {

    if (!dateInUTC) {
      return null;
    }

    var convertdLocalTime = new Date(dateInUTC);
    var hourOffset = convertdLocalTime.getTimezoneOffset() / 60;
    convertdLocalTime.setHours(convertdLocalTime.getHours() - hourOffset);
    return convertdLocalTime;
  }




}
