<form [formGroup]="rateableValueForm" class="k-form k-form-md" style="padding-left: 8px">
    <fieldset class="fieldset">
        <legend class="item-header">
            &nbsp;{{ documentTypeLabelDE }} (ID: {{ workflowId }},
            {{ actualStateLabelDE }})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-dropdown-notice-name [documentType]="datatypeName" [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_notificationName"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm" [isReadOnly]="false"
                        [workflowType]="workflowType">
                    </app-stare-dropdown-notice-name>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_companyIdentifier"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm" [isReadOnly]="false"
                        [workflowType]="workflowType" [isArchiv]="actualStateId == 9000"
                        overrideLabel="Einheitswertnummer">
                    </app-stare-textbox>
                </div>

                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_communeAGS" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm" [isReadOnly]="false" [workflowType]="workflowType"
                        [dataOptionsSource]="communeAGS">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " xbrlName="id_notificationDate" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
            </div>

            <div class="row">
                <!-- <div [class]='(workflowType==11 && !(actualStateId==9000)) ? "column-for-with-plus" : "column"'> -->
                <div class="column">
                    <app-stare-dropdown-string [isArchiv]="actualStateId == 9000"
                        (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id_legalEntityKey"
                        [datasetId]="headerDatasetId" [form]="rateableValueForm" [isReadOnly]="false"
                        [workflowType]="workflowType" [dataOptionsSource]="legalEntities" overrideLabel="Zurechnung
                        lt.
                        Bescheid">
                    </app-stare-dropdown-string>
                </div>

                <!-- <div class="column-for-plus" *ngIf="workflowType==11 && !(actualStateId==9000)">
                    <app-legalentity-add-button></app-legalentity-add-button>
                </div> -->
                <div class="column">
                    <app-stare-dropdown-string (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="id_legalEntityKeyReference" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="true" [workflowType]="workflowType" [dataOptionsSource]="legalEntities"
                        overrideLabel="Zurechnung
                        lt. Referenz">
                    </app-stare-dropdown-string>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_cutOffDate" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-datepicker>
                </div>
                <div class="column">
                    <app-stare-datepicker [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="realEstate_effectiveDate" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="true" [workflowType]="workflowType" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        ">
                    </app-stare-datepicker>
                </div>

                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_withoutEffectiveDateRule" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="column">
                    <app-stare-multiselect [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_taxDetermination" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="false" [workflowType]="workflowType" [dataOptionsSource]="taxdetList">
                    </app-stare-multiselect>
                </div>
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_isLeaseHold" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row-for-checkbox" *ngIf="workflowType == 11">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_missingBankAccountDetails" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bewegungsdaten
                    </legend>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_realEstate_rateableValue" [datasetId]="headerDatasetId"
                        [form]="rateableValueForm" [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
                <div class="column">
                    <app-stare-numerical [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="realEstate_rateableValue" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="true" (onReferenceDisable)="
                            onReferenceDisableReceiver($event)
                        " [workflowType]="workflowType">
                    </app-stare-numerical>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId == 9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_hasThresholdLimit" [datasetId]="headerDatasetId" [form]="rateableValueForm"
                        [isReadOnly]="false" [workflowType]="workflowType">
                    </app-stare-checkbox>
                </div>
            </div>

            <app-assessment-result-section [datasetId]="headerDatasetId" [workflowType]="workflowType"
                [form]="rateableValueForm" [auditResults]="auditResults" [workflowId]="workflowId"
                [is2000]="actualStateId == 2000" [isArchiv]="actualStateId == 9000"></app-assessment-result-section>
        </div>
    </fieldset>
    <br />
</form>
<!--
<form [formGroup]="rateableValueForm" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <kendo-gridlayout [gap]="{ rows: 8, cols: 8}" [rows]="[
        { height: 20 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 112 },
        { height: 20 },
        { height: 112 },
        { height: 90 },						
        { height: 20 },
		{ height: 90 },
		{ height: withoutReferenceValidationExplanationRowHeight},
        { height: sdcFieldsRowHeight },
        { height: sdcFieldsRowHeight+20},
                        { height: sdcFieldsRowHeight/2 - 20},
        { height: 112 }]" [cols]="[{ width: 250 }, { width: 250 }, { width:250 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <app-stare-textbox (onFieldFocus)="onFieldFocusReceiver()" xbrlName="id.companyIdentifier"
                        [datasetId]='headerDatasetId' [form]="rateableValueForm" [isReadOnly]="true"
                        [workflowType]='workflowType' overrideLabel="Einheitswertnummer">
                    </app-stare-textbox>
                </kendo-gridlayout-item>
-->
<!--
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                    <kendo-formfield showHints="always" showErrors="always">
                        <kendo-label [for]="'id_companyIdentifier-'+headerDatasetId"
                            id="companyIdentifierLabel">Einheitswertnummer</kendo-label>
                        <kendo-textbox (focus)="onFocusInputXbrl()" [id]="'id_companyIdentifier-'+headerDatasetId"
                            type="text"
                            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id_companyIdentifier')"
                            placeholder="Einheitswertnummer" formControlName="id_companyIdentifier" autocomplete="off">
                        </kendo-textbox>
                        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                            [xbrlNameInput]="'id_companyIdentifier'"></app-validation-message-formcontrol>
                    </kendo-formfield>
                </kendo-gridlayout-item>
                -->
<!--
<kendo-gridlayout-item [row]="2" [col]="2" [colSpan]="1">
    <kendo-formfield showErrors="always" showHints="always" class="part">
        <kendo-label [for]="'id.communeAGS-'+headerDatasetId"
            id="agsLabel">{{getXbrlLabel('id.communeAGS')}}</kendo-label>
        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
            [id]="'id.communeAGS-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
            [defaultItem]="defaultItem" formControlName="id.communeAGS" [virtual]="{itemHeight: 31}"
            (filterChange)="handleAGSFilter($event)"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id.communeAGS')"
            [data]="communeAGS"></kendo-dropdownlist>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'id.communeAGS'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
    <kendo-formfield showErrors="always" showHints="always" class="part">
        <kendo-label [for]="'id.legalEntityKey-'+headerDatasetId" id="taxDeterminationLabel">Zurechnung
            lt.
            Bescheid</kendo-label>
        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
            [id]="'id.legalEntityKey-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
            [defaultItem]="defaultItem" formControlName="id.legalEntityKey"
            (filterChange)="handleLegalEntitiesFilter($event)"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id.legalEntityKey')"
            [data]="legalEntities"></kendo-dropdownlist>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'id.legalEntityKey'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="3" [col]="3" [colSpan]="1">
    <kendo-formfield showErrors="always" showHints="always" class="part">
        <kendo-label [for]="'id.legalEntityKeyReference-'+headerDatasetId" id="refKeyLabel">Zurechnung
            lt. Referenz</kendo-label>
        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
            [id]="'id.legalEntityKeyReference-'+headerDatasetId" [filterable]="true" [valuePrimitive]="true"
            [defaultItem]="defaultItem" [tabindex]="-1" formControlName="id.legalEntityKeyReference" [readonly]="true"
            class="custom-fill-mode" (filterChange)="handleLegalEntitiesFilter($event)"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id.legalEntityKeyReference')"
            [data]="legalEntities"></kendo-dropdownlist>

        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'id.legalEntityKeyReference'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="2" [col]="3" [colSpan]="1">
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'id.notificationDate-'+headerDatasetId"
            id="notificationDateLabel">{{getXbrlLabel('id.notificationDate')}}</kendo-label>
        <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'id.notificationDate')"
            [id]="'id.notificationDate-'+headerDatasetId" [enableMouseWheel]="false"
            [value]="formGroup.controls['id.notificationDate'].value"
            placeholder="{{getXbrlLabel('id.notificationDate')}}" formControlName="id.notificationDate"
            autocomplete="off">
            <kendo-datepicker-messages today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
        </kendo-datepicker>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'id.notificationDate'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="1">
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.cutOffDate-'+headerDatasetId"
            id="cutoffDateLabel">{{getXbrlLabel('document.cutOffDate')}}</kendo-label>
        <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy" [id]="'document.cutOffDate-'+headerDatasetId"
            placeholder="Bitte auswählen..." formControlName="document.cutOffDate" autocomplete="off"
            [enableMouseWheel]="false" [value]="formGroup.controls['document.cutOffDate'].value"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.cutOffDate')">
            <kendo-datepicker-messages today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
        </kendo-datepicker>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.cutOffDate'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="4" [col]="2" [colSpan]="1">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'realEstate.effectiveDate-'+headerDatasetId"
            id="effectiveDateLabel">{{getXbrlLabel('realEstate.effectiveDate')}}</kendo-label>
        <kendo-datepicker (focus)="onFocusInputXbrl()" format="dd.MM.yyyy" [readonly]="true" [tabindex]="-1"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'realEstate.effectiveDate')"
            [id]="'realEstate.effectiveDate-'+headerDatasetId" class="custom-fill-mode"
            [value]="formGroup.controls['realEstate.effectiveDate'].value"
            placeholder="{{getXbrlLabel('realEstate.effectiveDate')}}" formControlName="realEstate.effectiveDate"
            autocomplete="off"></kendo-datepicker>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'realEstate.effectiveDate'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="4" [col]="3" [colSpan]="1">
    <kendo-formfield class="part">

        <kendo-label [for]="'document.withoutEffectiveDateRule-'+headerDatasetId" id="effectiveDateRuleLabel">
            {{getXbrlLabel('document.withoutEffectiveDateRule')}}
        </kendo-label>
        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            formControlName="document.withoutEffectiveDateRule"
            [id]="'document.withoutEffectiveDateRule-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.withoutEffectiveDateRule'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="2">
    <kendo-formfield showErrors="always" showHints="always" class="part">
        <kendo-label [for]="'document.taxDetermination-'+headerDatasetId"
            id="taxDeterminationLabel">{{getXbrlLabel('document.taxDetermination')}}</kendo-label>
        <kendo-multiselect (focus)="onFocusInputXbrl()" formControlName="document.taxDetermination" [data]="taxdetList"
            [textField]="'labelDE'" [valueField]="'value'"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.taxDetermination')"
            placeholder="Bitte auswählen..." [id]="'document.taxDetermination-'+headerDatasetId"></kendo-multiselect>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.taxDetermination'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="5" [col]="3" [colSpan]="1">
    <kendo-formfield class="part">

        <kendo-label [for]="'document.isLeaseHold-'+headerDatasetId" id="effectiveDateRuleLabel">
            {{getXbrlLabel('document.isLeaseHold')}}
        </kendo-label>
        <input type="checkbox" (focus)="onFocusInputXbrl()" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            formControlName="document.isLeaseHold" [id]="'document.isLeaseHold-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.isLeaseHold'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="3">

    <legend class="k-form-legend section-header">
        Bewegungsdaten</legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="1">
    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.realEstate.rateableValue-'+headerDatasetId"
            id="rateableValueLabel">{{getXbrlLabel('document.realEstate.rateableValue')}}</kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false"
            [changeValueOnScroll]="false" [id]="'document.realEstate.rateableValue-'+headerDatasetId"
            placeholder="{{getXbrlLabel('document.realEstate.rateableValue')}}" [decimals]="2"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.realEstate.rateableValue')"
            formControlName="document.realEstate.rateableValue" autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.realEstate.rateableValue'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="7" [col]="2" [colSpan]="1">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'realEstate.rateableValue-'+headerDatasetId"
            id="rateableValueLabel">{{getXbrlLabel('realEstate.rateableValue')}}</kendo-label>
        <kendo-numerictextbox (focus)="onFocusInputXbrl()" type="decimal" [spinners]="false" [readonly]="true"
            class="custom-fill-mode" [tabindex]="-1" [id]="'realEstate.rateableValue-'+headerDatasetId"
            [changeValueOnScroll]="false" placeholder="{{getXbrlLabel('realEstate.rateableValue')}}"
            formControlName="realEstate.rateableValue"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'realEstate.rateableValue')"
            autocomplete="off">
        </kendo-numerictextbox>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'realEstate.rateableValue'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="1">
    <kendo-formfield showHints="initial" showErrors="initial" class="part">

        <kendo-label [for]="'document.hasThresholdLimit-'+headerDatasetId" id="effectiveDateRuleLabel">
            {{getXbrlLabel('document.hasThresholdLimit')}}
        </kendo-label>
        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            formControlName="document.hasThresholdLimit" [id]="'document.hasThresholdLimit-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.hasThresholdLimit'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="3">
    <legend class="k-form-legend section-header">
        Prüfungsergebnis</legend>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="2">
    <kendo-formfield showHints="initial" showErrors="initial" class="part">

        <kendo-label [for]="'document.withoutReferenceValidation-'+headerDatasetId" id="checkboxLabel">
            {{getXbrlLabel('document.withoutReferenceValidation')}}
        </kendo-label>
        <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox style="margin-left: 5px ;margin-top: 8px ;"
            (change)="changeExplanationRowHeight()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.withoutReferenceValidation')"
            formControlName="document.withoutReferenceValidation"
            [id]="'document.withoutReferenceValidation-'+headerDatasetId" />
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.withoutReferenceValidation'"></app-validation-message-formcontrol>

    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="3"
    *ngIf="rateableValueForm.controls['document.withoutReferenceValidation'].value==true">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.withoutReferenceValidationExplanation-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document.withoutReferenceValidationExplanation')}}</kendo-label>
        <kendo-textarea (focus)="onFocusInputXbrl()"
            [id]="'document.withoutReferenceValidationExplanation-'+headerDatasetId" type="string"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.withoutReferenceValidationExplanation')"
            placeholder="{{getXbrlLabel('document.withoutReferenceValidationExplanation')}}"
            formControlName="document.withoutReferenceValidationExplanation" autocomplete="off" [rows]="2"
            resizable="vertical">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.withoutReferenceValidationExplanation'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.mail.requestedDocuments-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document.mail.requestedDocuments')}}</kendo-label>
        <kendo-textarea [id]="'document.mail.requestedDocuments-'+headerDatasetId" type="string"
            (focus)="onFocusInputXbrl()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.mail.requestedDocuments')"
            placeholder="{{getXbrlLabel('document.mail.requestedDocuments')}}" resizable="none"
            formControlName="document.mail.requestedDocuments" autocomplete="off" [rows]="3">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.mail.requestedDocuments'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="13" [col]="1" [colSpan]="3" *ngIf="workflowType==11">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.mail.reasonNotAccepted-'+headerDatasetId"
            id="docLabel">{{getXbrlLabel('document.mail.reasonNotAccepted')}}</kendo-label>
        <kendo-textarea [id]="'document.mail.reasonNotAccepted-'+headerDatasetId" type="string"
            (focus)="onFocusInputXbrl()"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.mail.reasonNotAccepted')"
            placeholder="{{getXbrlLabel('document.mail.reasonNotAccepted')}}" resizable="none"
            formControlName="document.mail.reasonNotAccepted" autocomplete="off" [rows]="3">
        </kendo-textarea>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.mail.reasonNotAccepted'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
<kendo-gridlayout-item [row]="14" [col]="1" [colSpan]="2">

    <kendo-formfield showHints="always" showErrors="always" class="part">
        <kendo-label [for]="'document.auditResult-'+headerDatasetId"
            id="auditResultLabel">{{getXbrlLabel('document.auditResult')}}</kendo-label>
        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [textField]="'labelDE'" [valueField]="'value'"
            [id]="'document.auditResult-'+headerDatasetId" [valuePrimitive]="true"
            [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document.auditResult')"
            [defaultItem]="defaultItem" formControlName="document.auditResult" [data]="auditResults">
        </kendo-dropdownlist>
        <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
            [xbrlNameInput]="'document.auditResult'"></app-validation-message-formcontrol>
    </kendo-formfield>
</kendo-gridlayout-item>
</kendo-gridlayout>
</div>

</fieldset>
<br>
</form>
-->