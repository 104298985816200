<!-- 
<form [formGroup]="form">

    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                #toolTipGridTemplate let-anchor>
                <span>{{ label }}</span>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">

                <kendo-dropdownlist (focus)="onFocusInputXbrl()" [filterable]="true" [valuePrimitive]="true"
                    [defaultItem]="defaultItem" [id]="xbrlName+'-'+datasetId" [textField]="'labelDE'"
                    [valueField]="'value'" [formControlName]="xbrlName" [readonly]="isReadOnly" [class]="fieldClass"
                    [virtual]="{itemHeight: 31}" (filterChange)="handleFilter($event)" [data]="dataForDisplay"
                    [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                    [inert]="(isReadOnly || isArchiv) || (isReadOnly && isArchiv)">
                    --> <!-- 
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <ng-template #toolTipDropdownTemplate let-anchor>
                            <span>{{ anchor.labelDE }}</span>
                        </ng-template>
                        <div class="templateForDropdown" kendoTooltip [tooltipTemplate]="toolTipDropdownTemplate"
                            filter="dropdownItem"
                            (mouseover)="this.tooltipService.showTooltipDropdown($event,this.tooltipDirDropdown, dataItem.labelDE)">
                            <span class="dropdownItem">{{ dataItem.labelDE }}</span>
                        </div>
                    </ng-template> -->

<!--
                </kendo-dropdownlist>
            </div>
        </kendo-floatinglabel>

        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">
            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>
    </kendo-formfield>

</form> -->

<div class="row">
    <div
        [class]='((isReadOnly && !(stareInputFieldsService.isBlocked(xbrlName)) ) || (workflowType==11 && !isReadOnly  && !isArchiv && stareInputFieldsService.isAllowedToAdd(xbrlName))) ? "column-for-with-plus" : "column"'>

        <form [formGroup]="form">

            <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

                <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                        #toolTipGridTemplate let-anchor>
                        <span>{{ label }}</span>
                    </ng-template>
                    <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                        (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">
                        <!-- [virtual]="{itemHeight: 31}" -->
                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [filterable]="true" [valuePrimitive]="true"
                            [defaultItem]="defaultItem" [id]="xbrlName+'-'+datasetId" [textField]="'labelDE'"
                            [valueField]="'value'" [formControlName]="xbrlName" [readonly]="isReadOnly"
                            [class]="fieldClass" (filterChange)="handleFilter($event)" [data]="dataForDisplay"
                            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                            [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)">
                            <!-- 
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <ng-template #toolTipDropdownTemplate let-anchor>
                            <span>{{ anchor.labelDE }}</span>
                        </ng-template>
                        <div class="templateForDropdown" kendoTooltip [tooltipTemplate]="toolTipDropdownTemplate"
                            filter="dropdownItem"
                            (mouseover)="this.tooltipService.showTooltipDropdown($event,this.tooltipDirDropdown, dataItem.labelDE)">
                            <span class="dropdownItem">{{ dataItem.labelDE }}</span>
                        </div>
                    </ng-template> -->


                        </kendo-dropdownlist>
                    </div>
                </kendo-floatinglabel>

                <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class"
                    style="padding-left: 10px; padding-right: 10px;">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
                </div>
            </kendo-formfield>

        </form>
    </div>
    <div class="column-for-plus" *ngIf="isReadOnly && !(stareInputFieldsService.isBlocked(xbrlName))  ">
        <app-reference-disable-button (onReferenceDisable)="onReferenceDisableReciever($event)"
            [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlName"
            [disabled]="isArchiv"></app-reference-disable-button>
    </div>
    <div class="column-for-plus"
        *ngIf="workflowType==11 && !isReadOnly  && !isArchiv && stareInputFieldsService.isAllowedToAdd(xbrlName)">
        <app-legalentity-add-button></app-legalentity-add-button>

    </div>

</div>