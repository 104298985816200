import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StareInputFieldsService } from 'app/_services/stare-input-fields.service';
import { UntypedFormGroup } from '@angular/forms';
import { DataService } from 'app/_services/StareApi/data.service';
import { DropDownListItem } from 'app/_models/dropdown-item.model';

@Component({
    selector: 'app-stare-radio-button',
    templateUrl: './stare-radio-button.component.html',
    styleUrl: './stare-radio-button.component.scss',
    standalone: false
})

export class StareRadioButtonComponent {
  @Output() onFieldFocus = new EventEmitter();


  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() xbrlName: string = '';
  @Input() datasetId: number = -1;
  @Input() isReadOnly: boolean = false;
  //(DMS vs. SDC Workflow)
  @Input() workflowType: number = -1;
  //ex. Kassenzeicht und Steuernummer
  // @Input() overrideLabel: string = '';

  @Input() dataOptionsSource: DropDownListItem[] = [];

  @Input() isArchiv: boolean = false;

  public labelTrue: string = '';
  public labelFalse: string = '';
  public class: string = '';
  public currentValue: boolean = false;

  public dataforDisplay: DropDownListItem[] = [];

  constructor(

    private dataService: DataService,
    public stareInputFieldsService: StareInputFieldsService

  ) {

  }

  ngOnInit(): void {
    this.dataService.formLoaded$.subscribe(formLoaded => {
      if (formLoaded) {
        // The form has loaded successfully
        this.dataforDisplay = this.dataOptionsSource;
        this.labelTrue = this.dataforDisplay[0].labelDE;
        this.labelFalse = this.dataforDisplay[1].labelDE;
        this.currentValue = this.form.controls[this.xbrlName].value == 1 ? false : true;
        // if (this.dataForDisplay.length == 1) {
        //   this.defaultItem = this.dataForDisplay[0];
        // }
      }
    });


    this.class = this.isReadOnly ? 'shrink-border custom-fill-mode' : 'shrink-border custom-base-mode';
    // this.class = this.isReadOnly ? 'custom-fill-mode' : 'custom-base-mode';

  }

  /** User click on input field --> highlight PDF red rectangle */
  public onFocusInputXbrl(): void {
    //console.log(this.xbrlName);
    //console.log(this.datasetId);
    this.onFieldFocus.emit(document.activeElement);
  }

  public requestedByAdmin(): boolean {
    return this.dataService.requestedByAdmin();
  }
  public isUnlocked(): boolean {
    return this.dataService.GetAdminArchivUnlockFlag();
  }

  public onChange(value: boolean): void {
    // console.log(value);
    if (value == false) {
      // console.log(this.dataforDisplay[0].labelDE);
      this.form.controls[this.xbrlName].setValue(this.dataforDisplay[0].value);
    } else {
      // console.log(this.dataforDisplay[1].labelDE);
      this.form.controls[this.xbrlName].setValue(this.dataforDisplay[1].value);
    }
  }
}
