<div class="card-row">
    <div class="card-column">

        <div class="component-container">
            {{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE1' |
            translate }} <br />
            <ul>
                <li>{{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE2' |
                    translate }} </li>
                <li>{{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE3' |
                    translate }} </li>
                <li>{{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE4' |
                    translate }} </li>
                <li>{{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE5' |
                    translate }} </li>
                <li>{{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE6' |
                    translate }} </li>
            </ul>
            <br />
            {{ 'APP.ASSESSMENT_REVIEW.REOPEN.MESSAGE7' |
            translate }}
        </div>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="dialog.close()">{{ 'BUTTON.CANCEL' | translate }}</button>
        <button kendoButton (click)="submit()" themeColor="primary"> {{ 'APP.ASSESSMENT_REVIEW.REOPEN.TITLE' |
            translate }}
        </button>
    </kendo-dialog-actions>
</div>