import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DataService } from 'app/_services/StareApi/data.service';
import { ListsService } from 'app/_services/lists.service';
import { UIViewerService } from 'app/_services/StareApi/ui-viewer.service';
import { DocBaseComponent } from '../doc-base/doc-base.component';
import { DatasetItem } from 'app/_models/dataset.model';
import { FormFieldItem } from 'app/_models/form-field.model';
import { DateTimeService } from 'app/_services/date-time.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowService } from 'app/_services/StareApi/workflow.service';

@Component({
  selector: 'app-doc-chamber-of-commerce-and-industry1300',
  templateUrl: './doc-chamber-of-commerce-and-industry1300.component.html',
  styleUrls: ['../doc-base/doc-base.component.scss'],
  standalone: false
})
export class DocChamberOfCommerceAndIndustry1300Component extends DocBaseComponent {

  constructor(
    listsService: ListsService,

    apiUIViewerService: UIViewerService,
    apidataService: DataService,

    dateTimeService: DateTimeService, apiDatasetService: ApiDatasetService, apiWorkflowService: ApiWorkflowService

  ) {
    super(listsService, apiUIViewerService, apidataService, dateTimeService, apiDatasetService, apiWorkflowService);
    this._formGroup = this.chamberOfCommerceAndIndustryForm1300;
  }


  // public documentIHKContributionPayloadLength: number = 0;
  // public documentIHKDueDatePayloadLength: number = 0;

  //#region Form ======================================================================================================

  /** IHK, Rechtsmittel-Check, State 1300 */
  public chamberOfCommerceAndIndustryForm1300 = new UntypedFormGroup({
    id_companyIdentifier: new UntypedFormControl(""),
    id_notificationDate: new UntypedFormControl(""),
    id_cciIdentifier: new UntypedFormControl(""),
    document_legalRemedyClientDecision: new UntypedFormControl(""),
    document_comment: new UntypedFormControl(""),
    id_legalEntityKey: new UntypedFormControl(""),
    documentPayload: new UntypedFormArray([]),
    document_chamberOfCommerceAndIndustry_notificationNumber: new UntypedFormControl(""),
    document_withoutReferenceValidation: new UntypedFormControl(""),
    document_withoutReferenceValidationExplanation: new UntypedFormControl(""),
    document_auditResult: new UntypedFormControl(""),

    document_missingBankAccountDetails: new UntypedFormControl(""),
  });


  /** creates initial payloads for IHK: Beitragsberechnung auf Jahresebene (Payload): "DocumentChamberOfCommerceAndIndustryCalculationOfContribution" */
  createIHKContributionPayload(datasetId: number): UntypedFormGroup {

    const contributionPayload = new UntypedFormGroup({
      id_assessmentYear: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_sumYearOfContribution: new UntypedFormControl(""),
      chamberOfCommerceAndIndustry_sumYearOfContribution: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_sumAlreadyPaid: new UntypedFormControl(""),
      chamberOfCommerceAndIndustry_sumAlreadyPaid: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_basicAmount: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_apportionment: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_basicAmountAlreadyPaid: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_apportionmentAlreadyPaid: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_openClaim: new UntypedFormControl(""), //reference to be added and one more document field afer this field
      chamberOfCommerceAndIndustry_openClaim: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_yearAssessmentBasisTradeIncome: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentChamberOfCommerceAndIndustryCalculationOfContribution', { initialValueIsDefault: true }),

    });

    console.log("Adding non empty payload");
    console.log(datasetId);
    return contributionPayload;
  }



  /** creates initial payloads for  IHK: Fälligkeiten(Paylod): "DocumentChamberOfCommerceAndIndustryDueDatePayment" */
  createIHKDueDatePaymentPayload(datasetId: number): UntypedFormGroup {

    const duePayload = new UntypedFormGroup({
      document_chamberOfCommerceAndIndustry_amountToPaidDate: new UntypedFormControl(""),
      id_assessmentYear: new UntypedFormControl(""),
      document_chamberOfCommerceAndIndustry_amountToPaid: new UntypedFormControl(""),
      chamberOfCommerceAndIndustry_amountToPaid: new UntypedFormControl(""),
      datasetId: new UntypedFormControl(datasetId, { initialValueIsDefault: true }),
      dataTypeName: new UntypedFormControl('DocumentChamberOfCommerceAndIndustryDueDatePayment', { initialValueIsDefault: true }),
    });

    console.log("Adding non empty payload");
    console.log(datasetId);
    return duePayload;
  }

  //#endregion

  //initializes the payload form array
  //switch case for diff datatypes
  addSingularPayload(payload: DatasetItem): void {

    const payloadArray = this.documentPayloadGetter();

    switch (payload.dataTypeName) {

      case "DocumentChamberOfCommerceAndIndustryCalculationOfContribution": {
        payloadArray.push(this.createIHKContributionPayload(payload.id));
        console.info("Add singular contribution" + payload.dataTypeName);
        // this.documentIHKContributionPayloadLength = this.documentIHKContributionPayloadLength + 920;
        break;
      }
      case "DocumentChamberOfCommerceAndIndustryDueDatePayment": {
        payloadArray.push(this.createIHKDueDatePaymentPayload(payload.id));
        console.info("Add singular paymaent" + payload.dataTypeName);
        // this.documentIHKDueDatePayloadLength = this.documentIHKDueDatePayloadLength + 340;
        break;
      }
      default: {
        console.error('Unbekannt payload type for add payload');
        break;
      }
    }
  }


  addPayload(dataType: string): void {

    switch (dataType) {
      case "DocumentChamberOfCommerceAndIndustryCalculationOfContribution": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
          next: (data) => {
            console.log("Adding contribution");
            const newPayload = data as FormFieldItem[];
            const newDatasetID = newPayload[0].datasetId;
            if (newDatasetID != null) {
              this.documentPayloadGetter().push(this.createIHKContributionPayload(newDatasetID));
              //this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
              this.documentPayloadDatasetIdArray.push(newDatasetID);
              // this.documentIHKContributionPayloadLength = this.documentIHKContributionPayloadLength + 920;
            }
          },
          error: (err: HttpErrorResponse) => {
            //this.showError('Fehler bei Hinzufügen: ' + err.message)
            console.error(err);
          }

        });
        break;
      }

      case "DocumentChamberOfCommerceAndIndustryDueDatePayment": {
        this.addPayloadAsync(dataType, this.workflowId).subscribe({
          next: (data) => {
            console.log("Adding payment");
            const newPayload = data as FormFieldItem[];
            const newDatasetID = newPayload[0].datasetId;
            if (newDatasetID != null) {
              this.documentPayloadGetter().push(this.createIHKDueDatePaymentPayload(newDatasetID));
              //this.showSuccess('Neuen Eintrag eingefügt (Interne Id: ' + newDatasetID + ').');
              this.documentPayloadDatasetIdArray.push(newDatasetID);
              // this.documentIHKDueDatePayloadLength = this.documentIHKDueDatePayloadLength + 340;
            }
          },
          error: (err: HttpErrorResponse) => {
            //this.showError('Fehler bei Hinzufügen: ' + err.message)
            console.error(err);
          }

        });
        break;
      }
      default:
        console.error('Unbekannt payload type for add payload');
        break;
    }

  }



}
