<!--[tabindex]="-1" for read only?-->

<!-- <form [formGroup]="form">

    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-label style="margin-left: 5px ; margin-bottom: -5px; color: #646262"
            *ngIf="isWithoutLabel==false">{{label}}</kendo-label>
        <kendo-datepicker placeholder="" (focus)="onFocusInputXbrl()" format="dd.MM.yyyy" [enableMouseWheel]="false"
            [id]="xbrlName+'-'+datasetId" [formControlName]="xbrlName" [readonly]="isReadOnly" [class]="fieldClass"
            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
            [value]="form.controls[xbrlName].value" autocomplete="off" [inert]="isReadOnly"><kendo-datepicker-messages
                today="Heute" toggle="Kalendar umschalten"></kendo-datepicker-messages>
        </kendo-datepicker>

        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">
            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>

    </kendo-formfield>

</form> -->

<div class="row">
    <!-- <div
        [class]='(isReadOnly && !isArchiv && !(stareInputFieldsService.isBlocked(xbrlName)) && workflowType==11 ) ? "column-for-with-plus" : "column"'> -->
    <!-- <form [formGroup]="form"> -->
    <div [class]="
            isReadOnly &&
            !stareInputFieldsService.isBlocked(xbrlName)
                ? 'column-for-with-plus'
                : 'column'
        ">
        <form [formGroup]="form">
            <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">
                <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                        #toolTipGridTemplate let-anchor>
                        <span>{{ label }}</span>
                    </ng-template>
                    <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div" (mouseover)="
                            this.tooltipService.showTooltipForm(
                                $event,
                                this.tooltipDir,
                                label
                            )
                        ">
                        <kendo-datepicker placeholder="" (focus)="onFocusInputXbrl()" format="dd.MM.yyyy"
                            [enableMouseWheel]="false" [id]="xbrlName + '-' + datasetId" [formControlName]="xbrlName"
                            [readonly]="isReadOnly" [class]="fieldClass" [ngClass]="
                                stareInputFieldsService.assignClassByDatasetIDandXbrlName(
                                    datasetId,
                                    xbrlName
                                )
                            " [value]="form.controls[xbrlName].value" autocomplete="off" [min]="min" [max]="max"
                            [inert]="
                                isReadOnly ||
                                (isArchiv && !requestedByAdmin()) ||
                                (isArchiv &&
                                    requestedByAdmin() &&
                                    !isUnlocked()) ||
                                (isReadOnly && isArchiv)
                            "><kendo-datepicker-messages today="Heute"
                                toggle="Kalendar umschalten"></kendo-datepicker-messages>
                        </kendo-datepicker>
                    </div>
                </kendo-floatinglabel>

                <div [id]="'wrapper' + datasetId + '-' + xbrlName" [class]="class"
                    style="padding-left: 10px; padding-right: 10px">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
                </div>
            </kendo-formfield>
        </form>
    </div>
    <!-- <div class="column-for-plus"
        *ngIf="isReadOnly && !(stareInputFieldsService.isBlocked(xbrlName)) && !isArchiv && workflowType==11 "> -->
    <div class="column-for-plus" *ngIf="
            isReadOnly &&
            !stareInputFieldsService.isBlocked(xbrlName) 
        ">
        <app-reference-disable-button (onReferenceDisable)="onReferenceDisableReciever($event)"
            [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlName"
            [disabled]="isArchiv"></app-reference-disable-button>
    </div>
</div>