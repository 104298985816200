<div class="row">
    <form [formGroup]="form">
        <kendo-formfield showHints="always" showErrors="always">
            <!-- <kendo-floatinglabel [text]="label"> [class]="class" -->
            <div>
                <!-- (click)="stareInputFieldsService.handleUnselect()" -->
                <!-- <input (focus)="onFocusInputXbrl()" style="margin-top: -1px ; " type="checkbox" kendoCheckBox
                [id]="xbrlName+'-'+datasetId" [formControlName]="xbrlName" />
            <kendo-label style="margin-left: 5px ; color: #646262">{{label}}</kendo-label> -->
                <kendo-label
                    style="margin-left: 5px ; padding-bottom: 15px ; color: #646262">{{labelTrue}}</kendo-label>
                &nbsp;
                <kendo-switch (valueChange)="onChange($event)" offLabel=" " onLabel=" " (focus)="onFocusInputXbrl()"
                    [id]="xbrlNameToggle+'-'+datasetId"
                    [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)"
                    style=" padding-bottom: 5px ;" [checked]="currentValue"></kendo-switch>
                &nbsp;
                <kendo-label
                    style="margin-left: 5px ; padding-bottom: 15px ; color: #646262">{{labelFalse}}</kendo-label>
                <div style="height: 10px; ">&nbsp;</div>
                <!-- </kendo-floatinglabel> -->
                <div [id]="'wrapper'+datasetId+'-'+xbrlNameToggle"
                    style="padding-left: 10px; padding-right: 10px; margin-top: -5px"
                    (click)="stareInputFieldsService.handleUnselect()" [class]="classToggle">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlNameToggle"></app-validation-message-formcontrol>
                </div>
            </div>
        </kendo-formfield>
    </form>
</div>
<div class="row">
    <div
        [class]='(isReadOnly  && !(stareInputFieldsService.isBlocked(xbrlNameDropdown))) ? "column-for-with-plus" : (xbrlNameDropdown == "id_notificationName") ? "column-for-text-area" : "column"'>

        <form [formGroup]="form">

            <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

                <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                        #toolTipGridTemplate let-anchor>
                        <span>{{ label }}</span>
                    </ng-template>
                    <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                        (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">

                        <kendo-dropdownlist (focus)="onFocusInputXbrl()" [filterable]="filterable"
                            [valuePrimitive]="true" [defaultItem]="defaultItem" [id]="xbrlNameDropdown+'-'+datasetId"
                            [textField]="'nameDE'" [valueField]="'id'" [formControlName]="xbrlNameDropdown"
                            [readonly]="isReadOnly" [class]="fieldClass" [data]="dataForDisplay"
                            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlNameDropdown)"
                            [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)">

                            <ng-template kendoDropDownListNoDataTemplate>

                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                </kendo-floatinglabel>

                <div [id]="'wrapper'+datasetId+'-'+xbrlNameDropdown" [class]="class"
                    style="padding-left: 10px; padding-right: 10px;">
                    <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                        [xbrlNameInput]="xbrlNameDropdown"></app-validation-message-formcontrol>
                </div>
            </kendo-formfield>

        </form>
    </div>
    <div class="column-for-plus" *ngIf="isReadOnly && !(stareInputFieldsService.isBlocked(xbrlNameDropdown))   ">
        <app-reference-disable-button (onReferenceDisable)="onReferenceDisableReciever($event)"
            [datasetIdInput]="datasetId" [xbrlNameInput]="xbrlNameDropdown"
            [disabled]="isArchiv"></app-reference-disable-button>
    </div>

</div>