
export interface TaxNoticeNameItem {
	id: number;
	baseName: string;
	isDefault: boolean;
	nameDE: string;
	nameEN: string;
	isDeleted: boolean;

	withTaxationBase: boolean;
	withAnnex: boolean;
	withSeparationDivisions: boolean;
	isControllingCompany: boolean;
	isControlledCompany: boolean;
	isKStVV_P8: boolean;
	isKStVZ_AssessmentYear: boolean;
	isGewSTMB_WithAddCarryForward: boolean;
	isPrePayment: boolean;
	isUSt_AsNotification: boolean;
	isUSt_Interest: boolean;
	isUSt_Delayed: boolean;

}

export class TaxNoticeNameItemClass {
	// map to IData
	public static NewInstance(data: TaxNoticeNameItem): TaxNoticeNameItem {
		// if (!data) {
		//     return null;
		// }
		return {
			id: data.id,
			baseName: data.baseName,
			isDefault: data.isDefault,
			nameDE: data.nameDE,
			nameEN: data.nameEN,
			isDeleted: data.isDeleted,
			withTaxationBase: data.withTaxationBase,
			withAnnex: data.withAnnex,
			withSeparationDivisions: data.withSeparationDivisions,
			isControllingCompany: data.isControllingCompany,
			isControlledCompany: data.isControlledCompany,
			isKStVV_P8: data.isKStVV_P8,
			isKStVZ_AssessmentYear: data.isKStVZ_AssessmentYear,
			isGewSTMB_WithAddCarryForward: data.isGewSTMB_WithAddCarryForward,
			isPrePayment: data.isPrePayment,
			isUSt_AsNotification: data.isUSt_AsNotification,
			isUSt_Interest: data.isUSt_Interest,
			isUSt_Delayed: data.isUSt_Delayed,


		};
	}
	public static NewInstances(data: TaxNoticeNameItem[]): TaxNoticeNameItem[] {
		return data.map(TaxNoticeNameItemClass.NewInstance);
	}
}