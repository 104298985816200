<form [formGroup]="deletionForm" class="k-form k-form-md" style="padding-left: 8px;">
    <fieldset class="fieldset">
        <legend class="item-header">&nbsp;{{documentTypeLabelDE}} (ID: {{workflowId}},
            {{actualStateLabelDE}})&nbsp;
        </legend>
        <div class="grid-layout-container">
            <div class="row">
                <div class="column-for-header">
                    <legend class="k-form-legend section-header">
                        Bescheididentität
                    </legend>
                </div>
            </div>
            <div class="row-for-checkbox">
                <div class="column-for-checkbox">
                    <app-stare-checkbox [isArchiv]="actualStateId==9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_shouldDeleted" [datasetId]='headerDatasetId' [form]="deletionForm"
                        [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="column-for-text-area">
                    <app-stare-textarea [isArchiv]="actualStateId==9000" (onFieldFocus)="onFieldFocusReceiver()"
                        xbrlName="document_comment" [datasetId]='headerDatasetId' [form]="deletionForm"
                        [isReadOnly]="false" [workflowType]='workflowType'>
                    </app-stare-textarea>
                </div>

            </div>
        </div>

    </fieldset>
    <br>
</form>
<!-- 
            <form [formGroup]="deletionForm" class="k-form k-form-md" style="padding-left: 8px;">
                <fieldset class="fieldset">
                    <legend class="item-header">{{documentTypeLabelDE}} (ID: {{workflowId}},
                        {{actualStateLabelDE}})
                    </legend>
                    <div class="grid-layout-container">
                        <kendo-gridlayout [gap]="{ rows: 9, cols: 8}" [rows]="[
        { height: 20 },
        { height: 112 },
        { height: 150 }]" [cols]="[{ width: 270 }, { width: 270 }]">
                            <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="3">
                                <legend class="k-form-legend section-header">
                                    Bescheididentität
                                </legend>
                            </kendo-gridlayout-item>

                            <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
                                <kendo-formfield showHints="initial" showErrors="initial" class="part">

                                    <kendo-label [for]="'document_shouldDeleted-'+headerDatasetId"
                                        id="shouldDeletedLabel">
                                        {{getXbrlLabel('document_shouldDeleted')}}
                                    </kendo-label>
                                    <input (focus)="onFocusInputXbrl()" type="checkbox" kendoCheckBox
                                        [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_shouldDeleted')"
                                        style="margin-left: 5px ;margin-top: 8px ;"
                                        formControlName="document_shouldDeleted"
                                        [id]="'document_shouldDeleted-'+headerDatasetId" />
                                    <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                        [xbrlNameInput]="'document_shouldDeleted'"></app-validation-message-formcontrol>

                                </kendo-formfield>
                            </kendo-gridlayout-item>

                            <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">

                                <kendo-formfield showHints="always" showErrors="always" class="part">
                                    <kendo-label [for]="'document_comment-'+headerDatasetId"
                                        id="documentCommentLabel">{{getXbrlLabel('document_comment')}}</kendo-label>
                                    <kendo-textarea (focus)="onFocusInputXbrl()"
                                        [id]="'ocument.comment-'+headerDatasetId" type="string"
                                        [ngClass]="assignClassByDatasetIDandXbrlName(headerDatasetId,'document_comment')"
                                        placeholder="{{getXbrlLabel('document_comment')}}"
                                        formControlName="document_comment" autocomplete="off" [rows]="3"
                                        resizable="vertical">
                                    </kendo-textarea>
                                    <app-validation-message-formcontrol [datasetIdInput]='headerDatasetId'
                                        [xbrlNameInput]="'document_comment'"></app-validation-message-formcontrol>
                                </kendo-formfield>
                            </kendo-gridlayout-item>



                        </kendo-gridlayout>
                    </div>

                </fieldset>
                <br>
            </form> -->