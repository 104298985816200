import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { WbsSettingItem, WbsSettingForCreationItem, WbsSettingForUpdateItem, IThresholdForCreationDto, IThresholdForCreationDtoList } from '../_models/wbs-setting.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { SortDescriptor } from "@progress/kendo-data-query";
import { WbsItem } from '../_models/wbs.model';
import { DataTypeItem } from '../_models/data-type.model';
import { WrapperNotificationService } from '../_services/wrapper-notification.service';

import { SVGIcon, arrowRotateCwIcon, fileExcelIcon, filterClearIcon, plusIcon } from '@progress/kendo-svg-icons';
import { CellClickEvent, ColumnMenuSettings, CreateFormGroupArgs, GridComponent, NavigationRow } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DropDownStringWithDisabledItem } from 'app/_models/dropdown-item-string.model';
import { UserLocal } from 'app/_models/user.model';
import { ListsService } from 'app/_services/lists.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ApiWbsService } from 'app/_services/StareApi/wbs.service';
import { ApiDatasetService } from 'app/_services/StareApi/dataset.service';
import { ApiWorkflowSettingsService } from 'app/_services/StareApi/workflow-settings.service';
import { TooltipService } from 'app/_services/tooltip.service';


@Component({
  selector: 'app-setting-workflow',
  templateUrl: './setting-workflow.component.html',
  styleUrls: ['./setting-workflow.component.scss'],
  standalone: false
})
export class SettingWorkflowComponent implements OnInit {

  public myuser: UserLocal = {} as UserLocal;
  public dataTypeDropDownList = [] as DropDownStringWithDisabledItem[];
  /** Displayed (filtered) data */
  public tableData = [] as WbsSettingItem[];
  /** Original data from API request */
  public tableDataOriginal = [] as WbsSettingItem[];
  /** API request is finished */
  public finishedLoading = false;
  public checkboxValueAutomaticProcessing: boolean = false;
  public sendSignOutMailDefault: boolean = false;
  public wbsList = [] as WbsItem[];
  public dataTypeList = [] as DataTypeItem[];
  public wbsSettingList = [] as WbsSettingItem[];



  // == ToolTips ===========================================================
  @ViewChild(TooltipDirective) public kendoTooltip!: TooltipDirective;

  // ===============================================================================

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private apiWbsService: ApiWbsService,
    private apiWorkflowSettingsService: ApiWorkflowSettingsService,

    private apiDatasetService: ApiDatasetService,
    private notificationService: WrapperNotificationService,
    private formBuilder: FormBuilder,
    public listservice: ListsService,
    public tooltipService: TooltipService
  ) {

    this.createFormGroup = this.createFormGroup.bind(this);

    // Changes on checkbox
    this.editForm.get('sendSignOutMail')!.valueChanges.subscribe((checked) => {
      const textbox = this.editForm.get('sendSignOutMailAddress');
      if (checked) {
        textbox!.enable();
      } else {
        textbox!.disable();
      }
    });

    this.editForm.get('automaticProcessing')!.valueChanges.subscribe((checked) => {
      const textbox2 = this.editForm.get('percentageToReview');
      if (checked) {
        textbox2!.enable();
      } else {
        textbox2!.disable();
      }

      const textbox3 = this.editForm.get('addCalculatedValues');
      if (checked) {
        textbox3!.setValue(true);
      }
    });

    this.editForm.get('withDeadlineManagement')!.valueChanges.subscribe((checked) => {
      if (checked) {
        this.editForm.get('sendSignOutMail')!.setValue(false);
      }
    });


  }

  ngOnInit(): void {
    this.translateService.get('APP.SETTINGS.SETTINGS_WORKFLOW.TITLE').subscribe((title: string) => {
      this.titleService.setTitle('STARE | ' + title);
    });
    this.loadData();

    const jsonStr = localStorage.getItem('user') || '';
    if (jsonStr.length > 0) {
      const jsonObj = JSON.parse(jsonStr);
      this.myuser = jsonObj as UserLocal;
    }

    this.listservice.load();

    //console.log(this.listservice.dataTypeItemList);

    // copy data from this.listservice.dataTypeItemList to local variable this.dataTypeList



    // // Load Data
    // this.dataTypeList = this.listservice.dataTypeItemList;
    // this.xbrlNameList = this.listservice.xbrlTypes;
  }

  // == Resize Grid ======================================================
  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.pageHeight = window.innerHeight - 125;

  }
  public pageHeight: number = window.innerHeight - 125;


  // == Load Grid Data ================================================================


  loadData(): void {
    this.finishedLoading = false;
    this.apiDatasetService.getAllDataTypes().subscribe({
      next: (data: DataTypeItem[]) => {
        this.dataTypeList = data;

        // Order by labelMiddleDE
        this.dataTypeList.sort((a, b) => (a.labelMiddleDE.toLocaleLowerCase() > b.labelMiddleDE.toLocaleLowerCase()) ? 1 : -1);

        // Convert (Map) data to dataTypeDropDownList
        this.dataTypeDropDownList = data.map((x) => <DropDownStringWithDisabledItem><unknown>{
          value: x.name,
          label: x.labelMiddleDE,
          isDefault: false,
          isDisabled: false
        });

        this.apiWorkflowSettingsService.getAllWbsSetttings().subscribe({
          next: (data: WbsSettingItem[]) => {

            data.forEach((item) => {
              item.dataTypeNameDE = this.dataTypeList.find((dataType) => dataType.name == item.dataTypeName)?.labelMiddleDE as string;
              item.percentageToReview = item.percentageToReview != null ? item.percentageToReview * 100 : null;

            });



            this.tableDataOriginal = data.filter((x) => x.isDeleted === false);
            this.tableData = this.tableDataOriginal;
            this.wbsSettingList = this.tableDataOriginal;
            this.finishedLoading = true;
          },
          error: (err: HttpErrorResponse) => {
            console.error(err);
            this.finishedLoading = true;
          }
        })
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.finishedLoading = true;
      }
    });



    this.apiWbsService.getAllWBS().subscribe((data: WbsItem[]) => {
      this.wbsList = data;

      // Order by WBS Code
      this.wbsList.sort((a, b) => (a.wbs.toLocaleLowerCase() > b.wbs.toLocaleLowerCase()) ? 1 : -1);
    });

  }


  // == Grid Settings =======================================================================================================================================

  // Pagination 

  public pageSize = 20;



  // Icons 
  public excelSVG: SVGIcon = fileExcelIcon;
  public reloadSVG: SVGIcon = arrowRotateCwIcon;
  public addSVG: SVGIcon = plusIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;



  //Settings for individual columns 
  public menuSettings: ColumnMenuSettings = {
    lock: false,
    stick: false,
    view: 'tabbed',
    filter: true
  };
  // Sorting
  public currentSort: SortDescriptor[] = [
    {
      field: "wbsCustomer.wbs",
      dir: "asc",
    },
  ];

  public sortChange(sort: SortDescriptor[]): void {
    this.currentSort = sort;
    // this.loadData();
  }

  // Excel Export
  // public exportToExcel(grid: GridComponent): void {
  //   grid.saveAsExcel();
  // }


  // == Filter ======================================================================================================================================

  @ViewChild("grid", { static: false })
  grid!: GridComponent;


  public currentFilter: CompositeFilterDescriptor = {
    logic: "or",
    filters: [],
  }

  /** Filter all displayed columns */
  public onFilter(value: string): void {

    const filter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [],
    }

    // Check if value is empty
    if (value == "") {
      filter.filters = [];
    } else {

      // Get all displayed columns from kendo grid


      filter.filters.push({
        field: "wbsCustomer.wbs",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "dataTypeNameDE",
        operator: "contains",
        value: value,
      });
      filter.filters.push({
        field: "title",
        operator: "contains",
        value: value,
      });

    }

    this.currentFilter = filter;
  }



  removeFilter(): void {
    this.currentFilter = {
      logic: "or",
      filters: [],
    }
  }


  // == Modal ======================================================================================================================================

  /** Show Modal-Dialog. */
  public active: boolean = false;
  /** New DataItem will be added. */
  public isNew: boolean = false;
  /** This DataItem ID is showing in Modal-Dialog. */
  public thisId: number = -1;

  // == Forms ==========================================================================================
  public editForm: FormGroup = new FormGroup({
    title: new FormControl("", Validators.required),
    wbsId: new FormControl("", Validators.required),
    datatypeName: new FormControl(),
    onlyDigitize: new FormControl(false),
    sendTaskMail: new FormControl(false),
    editorsAllowed: new FormControl(false),
    withDeadlineManagement: new FormControl(false),
    legalEntityFromReferenceData: new FormControl(false),
    //legalEntityFromWbsForCorporateIncomeTax: new FormControl(false),  NOT IN USE
    automaticProcessing: new FormControl(false),
    addCalculatedValues: new FormControl(true),
    percentageToReview: new FormControl(null),
    createClientMail: new FormControl(false),
    sendSignOutMail: new FormControl(false),
    sendSignOutMailAddress: new FormControl('', Validators.email),
  });

  // == Handlers ============================================================================
  public editHandler(args: NavigationRow): void {
    if (args?.dataItem) {
      this.editForm.reset(args.dataItem);
      this.isNew = false;
      this.active = true;
      this.thisId = args.dataItem.id;
      this.updateDropDownInForm();

      this.editForm.get('wbsId')?.disable();
      this.editForm.get('datatypeName')?.disable();
      this.editForm.get('datatypeName')?.setValue(args.dataItem.dataTypeName);

      if (this.editForm.get('withDeadlineManagement')?.value) {
        this.editForm.get('sendSignOutMail')?.setValue(false);
      }
    }
  }

  public cellClickHandler(args: CellClickEvent): void {
    args.sender.focus();
  }

  public addHandler(): void {

    // Reset form fields
    this.editForm.reset();
    this.isNew = true;
    this.active = true;
    this.thisId = 0;
    this.editForm.get('wbsId')?.enable();
    this.editForm.get('datatypeName')?.enable();

    // Set default values
    this.editForm.get('addCalculatedValues')?.setValue(true);
    this.updateDropDownInForm();
  }

  public closeForm(): void {
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  // == CRUD Operations =========================================================================================

  public onSave(e: PointerEvent, openThresholdDialog: boolean): void {
    e.preventDefault();

    console.log(this.editForm);

    if (this.isNew) {

      // Map UserAccessForCreationItem from Form
      let itemForCreationItem = {} as WbsSettingForCreationItem;

      itemForCreationItem = {
        title: this.editForm.value.title,
        wbsId: this.editForm.value.wbsId,
        dataTypeName: this.editForm.get('datatypeName')?.value,
        onlyDigitize: this.editForm.value.onlyDigitize ? true : false,
        sendTaskMail: this.editForm.value.sendTaskMail ? true : false,
        editorsAllowed: this.editForm.value.editorsAllowed ? true : false,
        withDeadlineManagement: this.editForm.value.withDeadlineManagement ? true : false,
        legalEntityFromReferenceData: this.editForm.value.legalEntityFromReferenceData ? true : false,
        //legalEntityFromWbsForCorporateIncomeTax: this.editForm.value.legalEntityFromWbsForCorporateIncomeTax ? true : false,   NOT IN USE
        automaticProcessing: this.editForm.value.automaticProcessing ? true : false,
        addCalculatedValues: this.editForm.value.addCalculatedValues ? true : false,
        createClientMail: this.editForm.value.createClientMail ? true : false,
        percentageToReview: this.editForm.value.percentageToReview ? this.editForm.value.percentageToReview / 100 : null,
        sendSignOutMail: this.editForm.value.sendSignOutMail ? true : false,
        sendSignOutMailAddress: this.editForm.value.sendSignOutMailAddress ? this.editForm.value.sendSignOutMailAddress : null,
      };
      console.log(this.editForm);
      console.log(itemForCreationItem);

      // Send to API
      this.apiWorkflowSettingsService.addWbsSetting(itemForCreationItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Die Einstellungen wurden erfolgreich hinzugefügt.');

          this.loadData();

          if (openThresholdDialog) {
            const wbsItem = this.wbsSettingList.find((x) => x.id == this.thisId,);
            if (wbsItem) {
              this.openThresholdDialog(wbsItem!);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Daten konnten nicht verarbeitet werden. Bitte die Schreibweisen überprüfen.');
          } else if (err.status == 406) {
            this.notificationService.showError('Die Einstellungen (WBS + Bescheidart) sind bereits vorhanden und können nicht noch einmal hinzugefügt werden.');
          } else if (err.status == 410) {
            this.notificationService.showError('Die Einstellungen zur Aktualisierung konnten im System nicht gefunden werden. Ggf. wurden sie bereits zwischenzeitlich gelöscht.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });

    }
    else {
      // Update
      let itemForUpdateItem = {} as WbsSettingForUpdateItem;

      itemForUpdateItem = {
        title: this.editForm.value.title,
        onlyDigitize: this.editForm.value.onlyDigitize ? true : false,
        sendTaskMail: this.editForm.value.sendTaskMail ? true : false,
        editorsAllowed: this.editForm.value.editorsAllowed ? true : false,
        withDeadlineManagement: this.editForm.value.withDeadlineManagement ? true : false,
        legalEntityFromReferenceData: this.editForm.value.legalEntityFromReferenceData ? true : false,
        // legalEntityFromWbsForCorporateIncomeTax: this.editForm.value.legalEntityFromWbsForCorporateIncomeTax ? true : false,     NOT IN USE
        automaticProcessing: this.editForm.value.automaticProcessing ? true : false,
        addCalculatedValues: this.editForm.value.addCalculatedValues ? true : false,
        createClientMail: this.editForm.value.createClientMail ? true : false,
        percentageToReview: this.editForm.value.percentageToReview ? this.editForm.value.percentageToReview / 100 : null,
        sendSignOutMail: this.editForm.value.sendSignOutMail ? true : false,
        sendSignOutMailAddress: this.editForm.value.sendSignOutMailAddress ? this.editForm.value.sendSignOutMailAddress : null,
      };

      console.log(itemForUpdateItem);

      this.apiWorkflowSettingsService.updateWbsSetting(this.thisId, itemForUpdateItem).subscribe({
        next: () => {
          this.notificationService.showSuccess('Die Einstellungen wurden erfolgreich aktualisiert.');
          this.loadData();

          if (openThresholdDialog) {
            const wbsItem = this.wbsSettingList.find((x) => x.id == this.thisId,);
            if (wbsItem) {
              this.openThresholdDialog(wbsItem!);
            }
          }



        },
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status == 400) {
            this.notificationService.showError('Die Daten konnten nicht verarbeitet werden. Bitte die Schreibweisen überprüfen.');
          } else if (err.status == 406) {
            this.notificationService.showError('Die Einstellungen (WBS + Bescheidart) sind bereits vorhanden und können nicht noch einmal hinzugefügt werden.');
          } else {
            this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
          }
        },
        complete: () => {
          // Nothing to do here
        }
      });


    }

    this.closeForm();
  }


  public onDelete(e: PointerEvent): void {
    e.preventDefault();

    if (this.thisId == 0) {
      return;
    }

    this.apiWorkflowSettingsService.deleteWbsSetting(this.thisId).subscribe({
      next: (res: WbsSettingItem) => {
        console.log("deleted");
        console.log(res);
        this.notificationService.showSuccess('Die Einstellungen wurden erfolgreich gelöscht.');
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        if (err.status == 410) {
          this.notificationService.showError('Die Einstellungen zur Löschung konnten im System nicht gefunden werden. Ggf. wurden sie zwischenzeitlich bereits gelöscht.');
        } else {
          this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
        }
      },
      complete: () => {
        // Nothing to do here
      }
    });

    this.closeForm();
  }


  // == Form Helpers ===========================================================

  onSelectWbs(event: WbsItem): void {
    this.updateDropDownInForm(event.id);

    console.log(event);

    // this.existsWbsAndDataTypeCombination();

  }

  updateDropDownInForm(wbsId: number | null = null): void {

    if (wbsId == null) {
      return;
    }

    console.log(wbsId);

    // Enable all DropDown-Items in Control dataTypeDropDownList
    const dataTypeListFromDropDown = this.dataTypeDropDownList;

    dataTypeListFromDropDown.forEach((item) => {
      item.isDisabled = false;
    });

    // Disable all DropDown-Items in Control dataTypeDropDownList, which are not allowed for the selected WBS
    dataTypeListFromDropDown.forEach((item) => {
      if (item.value == '') {
        if (this.wbsSettingList.find(x => x.wbsId == wbsId && !x.dataTypeName)) {
          item.isDisabled = true;
          console.log(item.value + " is disabled for WBS " + wbsId + " because it is already used by another WBS Setting. 1");
        }
      }
      else {
        if (this.wbsSettingList.find(x => x.wbsId == wbsId && x.dataTypeName == item.value)) {
          item.isDisabled = true;
          console.log(item.value + " is disabled for WBS " + wbsId + " because it is already used by another WBS Setting. 2");
        }
      }

    });

    this.dataTypeDropDownList = dataTypeListFromDropDown;


    console.log(this.dataTypeDropDownList);
  }

  public itemDisabled(itemArgs: { dataItem: DropDownStringWithDisabledItem; index: number; }): boolean {
    //console.log(itemArgs.dataItem);
    return itemArgs.dataItem.isDisabled;
  }

  // == Data Type List ============================================================================================

  // /** Load and filling DropDown 'datatype' and select the default item
  //  * @remarks The dataTypeList must be loaded before calling this function
  //  */
  // fillDataTypeList(selectName: string | null): void {

  //   if (this.dataTypeList == null) {
  //     console.error("Data Types not loaded!");
  //     return;
  //   }

  //   let list = this.dataTypeList;


  //   list.sort((a, b) => (a.labelDE.toLocaleLowerCase() > b.labelDE.toLocaleLowerCase()) ? 1 : -1);

  //   // Filter: Only items with Workflow-Settings are allowed
  //   list = list.filter((x) => x.workflowSettingAllowed === true);

  //   // this.dataTypeDropDownList = list.map((x) => <APDropDownItem><unknown>{
  //   //   value: x.name,
  //   //   label: x.labelDE,
  //   //   disabled: false
  //   // });

  //   // Add default item
  //   //this.dataTypeDropDownList.unshift({ value: '', label: '<beliebig>', disabled: false });

  //   // Find first element in list1
  //   const item = this.dataTypeDropDownList.find(d => d.isDisabled === false);

  //   if (item) {
  //     this.editForm.get('datatypeName')!.setValue(item.value);
  //   }

  //   // if (selectName) {
  //   //   item = this.dataTypeDropDownList.find(d => d.value === selectName);
  //   //   if (item) {
  //   //     this.selectedDataTypeItem = item;
  //   //   }
  //   //   else {
  //   //     console.error("Data Type '" + selectName + "' not found!");
  //   //   }
  //   // }
  // }


  // == Threshold Dialog ===========================================================

  public thresholdList = [] as IThresholdForCreationDto[];
  // public dataTypeNameList = [] as DataTypeItem[];
  // public xbrlNameList = [] as XbrlTypeItem[];
  public typeOfThresholdList: { id: number, name: string }[] = [{ id: 1, name: 'Wesentlichkeit' }, {
    id: 2, name: 'Schwellenwert'
  }];

  public getDataType(id: string): string | undefined {
    return this.listservice.dataTypeItemList.find((x) => x.name === id)?.labelDEWithName;
  }

  public getThresholdType(id: number): string | undefined {
    return this.typeOfThresholdList.find((x) => x.id === id)?.name;
  }

  public getXBRL(xbrl: string): string | undefined {
    return xbrl + " (" + this.listservice.xbrlTypes.find((x) => x.name === xbrl)?.labelDE + ")";
  }

  public setLabelForDisplay(): void {
    this.listservice.xbrlTypes.forEach(item => {
      if (item.labelDE) {
        item.labelForDisplay = item.name + " (" + item.labelDE + ")";
      } else {
        item.labelForDisplay = item.name;
      }

    });

  }

  public showThresholdDialog: boolean = false;

  public openThresholdDialog(wbsSetting: WbsSettingItem): void {


    this.setLabelForDisplay();


    // Map wbsSetting.thresholdItems to thresholdList
    this.thresholdList = wbsSetting.thresholdItems.map((x) => <IThresholdForCreationDto>{
      dataTypeName: x.dataTypeName,
      xbrlName: x.xbrlName,
      thresholdValue: x.thresholdValue,
      typeOfThreshold: x.typeOfThreshold
    });

    console.log("ThresholdList")
    console.log(this.thresholdList);


    this.showThresholdDialog = true;
  }


  // public addThreshold(): void {
  //   // Get datatypeName from variable XY
  //   this.dataTypeNameList.find((x) => x.name == this.editForm.get('datatypeName')?.value)?.labelMiddleDE;
  // }


  public closeThresholdDialog(): void {
    this.showThresholdDialog = false;
    this.active = false;
  }



  public formGroup: FormGroup = this.formBuilder.group({
    dataTypeName: "",
    xbrlName: "",
    thresholdValue: 0,
    typeOfThreshold: 1,
  });



  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    this.formGroup.reset();
    const item = args.isNew ? new IThresholdForCreationDto() : args.dataItem;

    this.formBuilder.group({
      dataTypeName: [item.dataTypeName, Validators.required],
      xbrlName: [item.xbrlName, Validators.required],
      thresholdValue: [item.thresholdValue, Validators.required],
      typeOfThreshold: [
        item.typeOfThreshold,
        Validators.compose([
          Validators.required,
          Validators.pattern("^[1-2]"),
        ]),
      ]
    });

    return this.formGroup;
  }

  public onSaveThresholds(): void {
    console.log(this.thresholdList);

    if (this.thisId == 0) {
      return;
    }

    const list = {} as IThresholdForCreationDtoList;
    list.thresholds = this.thresholdList;

    this.apiWorkflowSettingsService.addOrUpdateThresholds(this.thisId, list).subscribe({
      next: () => {
        this.notificationService.showSuccess('Die Schwellenwerte wurden erfolgreich gespeichert.');
        this.closeThresholdDialog();
        this.loadData();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.notificationService.showError(`Es ist ein Fehler aufgetreten: Statuscode: ${err.status}, Text: ${err.message}`);
      },
      complete: () => {
        // Nothing to do here
      }
    });


  }




  // public onClosePopup(event: any, dropdownlist: DropDownListComponent) {
  //   event.preventDefault();
  //   // Close the list if the component is no longer focused
  // }

}
