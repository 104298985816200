import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class AuthGuard {


	constructor() { }

	canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		// Alles deaktiviert, da derzeit das Backend die Prüfung vornimmt. Wenn ein 404 zurück kommt, dann geht es auf die Logout Page

		return true;

	}

}
