import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IThresholdForCreationDtoList, WbsSettingForCreationItem, WbsSettingItem, WbsSettingForUpdateItem } from 'app/_models/wbs-setting.model';
import { ENVIRONMENT } from 'environments/environment';
import { Observable } from 'rxjs';
import { XbrlParserService } from '../xbrl-parser.service';
import { map } from 'rxjs/operators';

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiWorkflowSettingsService {

  constructor(
    private http: HttpClient,
    private xbrlParserService: XbrlParserService) { }

  /**
  * API: Add or Update Thresholds
  */
  addOrUpdateThresholds(itemId: number, list: IThresholdForCreationDtoList): Observable<any> {
    list.thresholds.forEach((item) => {
      item.xbrlName = this.xbrlParserService.xbrlUIParseToAPISingle(item.xbrlName);
    });
    return this.http.post(ENVIRONMENT.apiBaseUrl + 'WorkflowSettings/AddOrUpdateThresholdsAsync/' + itemId, list, HTTP_OPTIONS);
  }


  /**
   * API: Add a new wbs setting
   */
  addWbsSetting(item: WbsSettingForCreationItem): Observable<WbsSettingItem> {
    return this.http.post<WbsSettingItem>(ENVIRONMENT.apiBaseUrl + 'WorkflowSettings/AddAsync', item, HTTP_OPTIONS).pipe(
      map((data: WbsSettingItem) => {
        for (let item of data.thresholdItems) {
          item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
        }
        return data;
      }));
  }

  /**
   * API: Update a wbs setting.
   */
  updateWbsSetting(itemId: number, item: WbsSettingForUpdateItem): Observable<WbsSettingItem> {
    return this.http.put<WbsSettingItem>(`${ENVIRONMENT.apiBaseUrl}WorkflowSettings/UpdateAsync/${itemId}`, item, HTTP_OPTIONS).pipe(
      map((data: WbsSettingItem) => {
        for (let item of data.thresholdItems) {
          item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
        }
        return data;
      }));
  }

  /**
   * API: Delete a wbs setting.
   */
  deleteWbsSetting(itemId: number): Observable<WbsSettingItem> {
    return this.http.delete<WbsSettingItem>(ENVIRONMENT.apiBaseUrl + 'WorkflowSettings/DeleteAsync/' + itemId, HTTP_OPTIONS).pipe(
      map((data: WbsSettingItem) => {
        for (let item of data.thresholdItems) {
          item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
        }
        return data;
      }));
  }

  /**
  * API: Get all wbs settings.
  */
  getAllWbsSetttings(): Observable<WbsSettingItem[]> {
    return this.http.get<WbsSettingItem[]>(ENVIRONMENT.apiBaseUrl + 'WorkflowSettings/GetAllAsync', HTTP_OPTIONS).pipe(
      map((data: WbsSettingItem[]) => {
        for (let setting of data) {
          for (let item of setting.thresholdItems) {
            item.xbrlName = this.xbrlParserService.xbrlAPIParseToUISingle(item.xbrlName!);
          }
        }
        return data;
      }));
  }

}
