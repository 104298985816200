<div class="stareTopic">
    {{ 'APP.USER_PREFERENCES.TITLE' | translate }}
</div>
<br>

<div class="grid-layout-container">
    <kendo-gridlayout [gap]="{ rows: 6, cols: 10 }" [rows]="[
      { height: 100 },
      { height: 100 },
      { height: 100 }
    ]" [cols]="[{ width: 470 }]">
        <!-- Language Checkbox -->
        <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
            <span class="settingTitle">{{ 'APP.SETTINGS.LANGUAGE.TITLE' | translate }}</span>
            <br /><br />
            <kendo-checkbox [(ngModel)]="isLanguageGerman" #languageCheckbox
                (ngModelChange)="toggleGerman($event)"></kendo-checkbox>
            <kendo-label class="k-checkbox-label" [for]="languageCheckbox"
                text="{{ 'APP.SETTINGS.LANGUAGE.TOGGLE.GERMAN' | translate }}"></kendo-label>
        </kendo-gridlayout-item>

        <!-- Hidden Tasks Checkbox -->
        <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="1">
            <span class="settingTitle">{{ 'APP.SETTINGS.HIDDEN_TASKS.TITLE' | translate }}</span>
            <br /><br />
            <kendo-checkbox [(ngModel)]="activeHiddenTasks" #hiddenTasksCheckbox
                (ngModelChange)="toggleHiddenTasks($event)"></kendo-checkbox>
            <kendo-label class="k-checkbox-label" [for]="hiddenTasksCheckbox"
                text="{{ 'APP.SETTINGS.HIDDEN_TASKS.TOGGLE.ENABLE' | translate }}"></kendo-label>
        </kendo-gridlayout-item>

        <!-- Save Button -->
        <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="1">
            <button kendoButton (click)="saveSettings()">Save Settings</button>
        </kendo-gridlayout-item>
    </kendo-gridlayout>
</div>