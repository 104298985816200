import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons';
import { LegalEntityForUpdateItem, LegalEntityItem } from 'app/_models/legal-entity.model';
import { UserLocal } from 'app/_models/user.model';
import { WbsLegalEntityItem, WbsLegalEntityForCreation } from 'app/_models/wbs-legal-entity-item.model';
import { ListsService } from 'app/_services/lists.service';
import { DataService } from 'app/_services/StareApi/data.service';
import { ApiLegalentityService } from 'app/_services/StareApi/legalentity.service';
import { WrapperNotificationService } from 'app/_services/wrapper-notification.service';
import { LegalentityAddDialogComponent } from 'app/modal/legalentity-add-dialog/legalentity-add-dialog.component';

@Component({
  selector: 'app-legalentity-add-button',
  templateUrl: './legalentity-add-button.component.html',
  styleUrl: './legalentity-add-button.component.scss',
  standalone: false
})
export class LegalentityAddButtonComponent {


  public addSVG: SVGIcon = plusIcon;

  constructor(
    private dialogService: DialogService,
    private notificationService: WrapperNotificationService,
    private listsService: ListsService,
    private apiLegalEntityService: ApiLegalentityService,
    private apiDataSErvice: DataService


  ) { }

  //list of wbs codes that are already assigned to some legal entity
  listOfAssignedWBS = [] as WbsLegalEntityItem[];
  //list of wbs codes that are selected for new assignment
  wbsForAssign: WbsLegalEntityForCreation[] = [] as WbsLegalEntityForCreation[];
  //list of wbs codes that need to be deleted
  connectionsToDelete = [] as WbsLegalEntityItem[];

  //list of wbs codes that are selected in current update/create session
  listOfSelectedWBS = [] as Array<{ text: string; value: number; itemDisabled: boolean }>;
  public thisLegalEntityKey: string = "";
  private _user: UserLocal = {} as UserLocal;

  // == Handlers ===========================================================
  public addHandler(): void {
    this.openWindow();
  }

  openWindow(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Unternehmen Einzufügen",
      content: LegalentityAddDialogComponent
    });

    dialog.content.instance.submitClick.subscribe({
      next: (data: LegalEntityForUpdateItem) => {


        this.apiLegalEntityService.addLegalEntity(data).subscribe({
          next: (res: LegalEntityItem) => {
            this.thisLegalEntityKey = res.legalEntityKey;
            this.notificationService.showSuccess(`Unternehmen ${data.name} hinzugefügt.`);
            this.listsService.ResetCacheLegalEntities();
            this.apiDataSErvice.triggerUpdate();

          },
          error: (err: HttpErrorResponse) => {
            console.error(err);

            if (err.status == 400) {
              this.notificationService.showError('Das Unternehmen existiert bereits und konnte nicht (erneut) angelegt werden.');
            }

            if (err.status == 403) {
              this.notificationService.showError('Die Rechte zum Hinzufügen sind nicht ausreichend.');
            }
          },
          complete: () => {

          }
        });

      }
    });
  }
}
