<!-- <form [formGroup]="form">
    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-label style="margin-left: 5px ; margin-bottom: -5px; color: #646262"
            *ngIf="isWithoutLabel==false">{{label}}</kendo-label>

        <kendo-textarea (focus)="onFocusInputXbrl()" [id]="xbrlName+'-'+datasetId" type="string"
            [formControlName]="xbrlName" autocomplete="off" [class]="fieldClass" [readonly]="isReadOnly" [rows]="3"
            resizable="vertical"
            [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
            [inert]="isReadOnly">
        </kendo-textarea>

        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">

            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>
    </kendo-formfield>

</form> -->
<form [formGroup]="form">
    <kendo-formfield showHints="always" showErrors="always" (click)="stareInputFieldsService.handleUnselect()">

        <kendo-floatinglabel [text]="label" class="k-empty k-floating-label-container k-focus"><ng-template
                #toolTipGridTemplate let-anchor>
                <span>{{ label }}</span>
            </ng-template>
            <div kendoTooltip [tooltipTemplate]="toolTipGridTemplate" filter="div"
                (mouseover)="this.tooltipService.showTooltipForm($event,this.tooltipDir, label)">

                <kendo-textarea (focus)="onFocusInputXbrl()" [id]="xbrlName+'-'+datasetId" type="string"
                    [formControlName]="xbrlName" autocomplete="off" [class]="fieldClass" [readonly]="isReadOnly"
                    [rows]="3" resizable="vertical"
                    [ngClass]="stareInputFieldsService.assignClassByDatasetIDandXbrlName(datasetId, xbrlName)"
                    [inert]="(isReadOnly || (isArchiv && !requestedByAdmin()) || (isArchiv && requestedByAdmin() && !isUnlocked())) || (isReadOnly && isArchiv)">
                </kendo-textarea>
            </div>
        </kendo-floatinglabel>
        <div [id]="'wrapper'+datasetId+'-'+xbrlName" [class]="class" style="padding-left: 10px; padding-right: 10px;">

            <app-validation-message-formcontrol [datasetIdInput]="datasetId"
                [xbrlNameInput]="xbrlName"></app-validation-message-formcontrol>
        </div>
    </kendo-formfield>

</form>