<div class="row">
    <div class="column-for-header">
        <legend class="k-form-legend section-header">Prüfungsergebnis</legend>
    </div>
</div>
<div class="row-for-checkbox">
    <div class="column-for-checkbox">
        <app-stare-checkbox [isArchiv]="isArchiv" (onFieldFocus)="onFieldFocusReceiver()"
            xbrlName="document_withoutReferenceValidation" [datasetId]="datasetId" [form]="form" [isReadOnly]="is2000"
            [workflowType]="workflowType">
        </app-stare-checkbox>
    </div>
    <div class="column-for-text-area" style="width: 540px" *ngIf="
            form.controls['document_withoutReferenceValidation'].value == true
        ">
        <app-stare-textarea [isArchiv]="isArchiv" (onFieldFocus)="onFieldFocusReceiver()"
            xbrlName="document_withoutReferenceValidationExplanation" [datasetId]="datasetId" [form]="form"
            [isReadOnly]="is2000" [workflowType]="workflowType">
        </app-stare-textarea>
    </div>
</div>
<div class="row" *ngIf="workflowType == 11">
    <div class="column-for-text-area">
        <app-stare-textarea [isArchiv]="isArchiv" (onFieldFocus)="onFieldFocusReceiver()"
            xbrlName="document_mail_requestedDocuments" [datasetId]="datasetId" [form]="form" [isReadOnly]="is2000"
            [workflowType]="workflowType">
        </app-stare-textarea>
    </div>
</div>
<div class="row-for-comment-button" *ngIf="workflowType == 11">
    <div class="column">
        <kendo-dropdownlist [data]="comments" [textField]="'text'" [valueField]="'value'"
            [disabled]="is2000 || isArchiv" (valueChange)="onDropdownSelectChange($event)"
            [defaultItem]="defaultComment" style="border: none; color: #646262; width: 340px"
            [popupSettings]="{ width: '750px', popupClass: 'custom-dropdown-popup' }">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div *ngIf="dataItem.value == null">
                    <small style="display: block; color: grey">{{
                        dataItem.text
                        }}</small>
                </div>
                <div *ngIf="dataItem.value != null">
                    <strong>{{ dataItem.text }}</strong>
                    <small style="display: block; color: grey">{{
                        dataItem.value
                        }}</small>
                </div>
            </ng-template>
        </kendo-dropdownlist>
    </div>
</div>

<div class="row" *ngIf="workflowType == 11">
    <div class="column-for-text-area">
        <app-stare-textarea [isArchiv]="isArchiv" (onFieldFocus)="onFieldFocusReceiver()"
            xbrlName="document_mail_reasonNotAccepted" [datasetId]="datasetId" [form]="form" [isReadOnly]="is2000"
            [workflowType]="workflowType">
        </app-stare-textarea>
    </div>
</div>

<!-- <div class="row" *ngIf="is1300==false"> -->
<div class="row">
    <div class="column">
        <app-stare-dropdown [isArchiv]="isArchiv" (onFieldFocus)="onFieldFocusReceiver()"
            xbrlName="document_auditResult" [datasetId]="datasetId" [form]="form" [isReadOnly]="is2000"
            [workflowType]="workflowType" [dataNumberOptionsSource]="auditResults">
        </app-stare-dropdown>
    </div>
</div>

<!-- Attachments for SDC -->
<!-- Button click - add new -->
<!-- Double click - open update modal (not in use) -->
<!-- Click on bin - delete attachment -->
<!-- Click on arrow - open attachment in a new tab -->
<div class="row-for-payload-button" *ngIf="workflowType == 11">
    <div class="column">
        <button kendoButton themeColor="primary" [type]="'button'" (click)="openFileUploadModal()" [disabled]="
                is2000 ||
                (isArchiv && !requestedByAdmin()) ||
                (isArchiv && requestedByAdmin() && !isUnlocked())
            ">
            Upload Anhänge (für Prüfprotokoll)
        </button>
    </div>
</div>
<!-- (dblclick)="editHandler(grid.activeRow)" -->
<div class="row" *ngIf="workflowType == 11 && attachments.length != 0">
    <kendo-formfield showHints="always" showErrors="always" orientation="horizontal">
        <kendo-grid #grid [kendoGridBinding]="attachments" [loading]="!finishedLoading" scrollable="none"
            [hideHeader]="true" (cellClick)="cellClickHandler($event)"
            [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true">
            <kendo-grid-messages [filter]="'TABLE_MENU.FILTER_TAB' | translate" [noRecords]="
                    'APP.SETTINGS.MAILING_METADATA.MODAL.ADD_TEMPLATE.NO_ATTACHMENTS.LABEL'
                        | translate
                ">
            </kendo-grid-messages>
            <kendo-grid-column field="name" [width]="575"
                [title]="'APP.SETTINGS.LOG.TABLE.HEADER.FILENAME' | translate"></kendo-grid-column>
            <kendo-grid-command-column title="delete" [width]="220">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <button kendoGridEditCommand class="k-primary" [svgIcon]="filterClearSVG"></button> -->
                    <button kendoButton (click)="deleteAttachment(dataItem)" type="button" kendoTooltip
                        title="{{ 'BUTTON.DELETE' | translate }}" [svgIcon]="trashIcon" [disabled]="
                            is2000 ||
                            (isArchiv && !requestedByAdmin()) ||
                            (isArchiv && requestedByAdmin() && !isUnlocked())
                        "></button>
                    <button kendoButton (click)="openInNewTab(dataItem)" type="button" kendoTooltip
                        title="{{ 'BUTTON.OPEN' | translate }}" [svgIcon]="hyperlinkOpenIcon"></button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </kendo-formfield>
</div>
<div class="row">
    <div class="column">&nbsp;</div>
</div>

<!-- MODALS -->

<!-- Add Attachment -->
<kendo-dialog *ngIf="active" [width]="600" [height]="450" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{ "BUTTON.ADD" | translate }}
    </kendo-dialog-titlebar>
    <!-- For now witout name, without booleans (filled in .ts file), later fields here as well -->
    <!-- [disabled]="noBooleansSet" FOR LATER -->
    <kendo-upload [saveUrl]="uploadSaveUrl" saveMethod="POST" [withCredentials]="true" [restrictions]="myRestrictions"
        [autoUpload]="false" [multiple]="false" (error)="uploadFailed($event)" (success)="uploadSuccess()"
        (select)="onFileSelect($event)">
        <kendo-upload-messages [dropFilesHere]="'UPLOADER_MENU.DROP_FILES' | translate"
            [select]="'UPLOADER_MENU.SELECT' | translate" [uploadSelectedFiles]="'UPLOADER_MENU.UPLOAD' | translate"
            [clearSelectedFiles]="'UPLOADER_MENU.CLEAR' | translate"
            [invalidFileExtension]="'UPLOADER_MENU.INVALID_TYPE' | translate"
            [fileStatusFailed]="'UPLOADER_MENU.STATUS_FAILED' | translate"
            [fileStatusUploaded]="'UPLOADER_MENU.STATUS_UPLOADED' | translate" [headerStatusUploaded]="
                'UPLOADER_MENU.HEADER_STATUS_UPLOADED' | translate
            ">
        </kendo-upload-messages>
    </kendo-upload>
</kendo-dialog>

<!-- Update Attachment -->
<!-- For now disabled as we dont need the update (have the same parameters) -->
<kendo-dialog *ngIf="updateActive" [width]="600" [height]="450" (close)="closeUpdateForm()">
    <kendo-dialog-titlebar>
        {{ "BUTTON.UPDATE" | translate }}
    </kendo-dialog-titlebar>

    <div class="grid-layout-container">
        <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
            <kendo-gridlayout [gap]="{ rows: 6, cols: 15 }" [cols]="[{ width: 250 }, { width: 250 }]">
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="1">
                    <kendo-formfield>
                        <kendo-label [for]="name" [text]="
                                'APP.ASSESSMENT_REVIEW.ATTACHMENTS.NAME'
                                    | translate
                            "></kendo-label>
                        <kendo-textbox #name formControlName="name" [placeholder]="
                                'APP.ASSESSMENT_REVIEW.ATTACHMENTS.NAME'
                                    | translate
                            " required></kendo-textbox>
                    </kendo-formfield>

                    <kendo-formfield>
                        <ng-container>
                            <div kendoTooltip position="right">
                                <span class="k-checkbox-wrap">
                                    <input id="addToAuditProtocol" type="checkbox" kendoCheckBox
                                        formControlName="addToAuditProtocol" />
                                </span>
                                <label [labelClass]="false" class="k-checkbox-label" for="inScope" [title]="
                                        'APP.ASSESSMENT_REVIEW.ATTACHMENTS.IS_PROTOCOL'
                                            | translate
                                    ">
                                    {{
                                    "APP.ASSESSMENT_REVIEW.ATTACHMENTS.IS_PROTOCOL"
                                    | translate
                                    }}</label>
                            </div>
                        </ng-container>
                    </kendo-formfield>

                    <kendo-formfield>
                        <ng-container>
                            <div kendoTooltip position="right">
                                <span class="k-checkbox-wrap">
                                    <input id="addToNotificationListing" type="checkbox" kendoCheckBox
                                        formControlName="addToNotificationListing" />
                                </span>
                                <label [labelClass]="false" class="k-checkbox-label" for="inScope" [title]="
                                        'APP.ASSESSMENT_REVIEW.ATTACHMENTS.IS_NOTIFICATION'
                                            | translate
                                    ">
                                    {{
                                    "APP.ASSESSMENT_REVIEW.ATTACHMENTS.IS_NOTIFICATION"
                                    | translate
                                    }}</label>
                            </div>
                        </ng-container>
                    </kendo-formfield>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </form>
    </div>
    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ "BUTTON.SAVE" | translate }}
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{ "BUTTON.CANCEL" | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>