
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DataTypeItem } from 'app/_models/data-type.model';

import { ITypeChange } from './ITypeChange';
import {
  DialogRef, DialogContentBase
} from "@progress/kendo-angular-dialog";
import { ListsService } from 'app/_services/lists.service';


@Component({
  selector: 'app-type-select',
  templateUrl: './type-select.component.html',
  styleUrls: ['./type-select.component.scss'],
  standalone: false
})
export class TypeSelectComponent extends DialogContentBase implements OnInit {

  public selectedValue: DataTypeItem = {
    id: 0, name: 'Please select', labelShortDE: 'NA', labelMiddleDE: 'Bitte auswählen', labelDEWithName: '',
    labelDE: '', labelEN: '', documentDefinitionName: '', workflowSettingAllowed: false
  };
  public types: DataTypeItem[] = [];
  public typesFromResults: DataTypeItem[] = [];

  @Output() public submitClick: EventEmitter<ITypeChange> = new EventEmitter();

  // ==================================================================================

  constructor(
    public dialog: DialogRef,
    private listsService: ListsService
  ) { super(dialog); }

  ngOnInit(): void {

    this.listsService.GetDataTypesForRescan().subscribe(
      (data) => {
        const resultData = data as DataTypeItem[];

        // Sort resultData by labelShortDE
        resultData.sort((a, b) => (a.labelMiddleDE > b.labelMiddleDE) ? 1 : -1);

        this.types = resultData;
        this.typesFromResults = resultData;
      }
    );

  }

  // Type Kendo Checkbox
  @ViewChild('chkReScan', { static: false }) chkReScan!: ElementRef<HTMLInputElement>;


  submit(): void {
    const dataToSend: ITypeChange = { datatypeitem: this.selectedValue, withRescan: this.chkReScan.nativeElement.checked };
    this.submitClick.emit(dataToSend);
    this.dialog.close();
  }

  public handleFilter(value: string): void {
    this.types = this.typesFromResults.filter(
      (s) => `${s.labelMiddleDE} + " (" + ${s.labelMiddleDE} + ")`.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  // // Add this method in your component
  // ngModelChange(event: any): void {
  //   console.log('Selected value changed:', this.selectedValue);
  // }


}
