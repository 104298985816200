<div class="stareTopic">
    {{ "APP.SETTINGS.DATEV_METADATEN.TITLE" | translate }}
</div>

<div>
    <ng-template #toolTipGridTemplate let-anchor>
        <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div kendoTooltip showOn="none" [tooltipTemplate]="toolTipGridTemplate" filter=".k-grid td" (mouseover)="
            this.tooltipService.showTooltipHeader($event, this.tooltipDir)
        " [ngStyle]="{ 'height.px': pageHeight }">
        <kendo-grid #grid [kendoGridBinding]="tableData" scrollable="scrollable" [style.height]="'100%'"
            filterable="menu" [loading]="!finishedLoading" [sort]="currentSort" (sortChange)="sortChange($event)"
            [filter]="currentFilter || { logic: 'and', filters: [] }" (cellClick)="cellClickHandler($event)"
            (add)="addHandler()" (dblclick)="editHandler(grid.activeRow)"
            [selectable]="{ checkboxOnly: false, mode: 'single' }" [navigable]="true" [columnMenu]="menuSettings"
            [pageable]="{
                buttonCount: 7,
                info: true,
                type: 'numeric',
                pageSizes: [20, 30, 50],
                previousNext: true,
                position: 'bottom'
            }" [pageSize]="pageSize" [sortable]="true">
            <ng-template kendoGridToolbarTemplate>
                <kendo-grid-spacer></kendo-grid-spacer>
                <button kendoButton (click)="getReferenceData()" type="button" [svgIcon]="getDataSVG" kendoTooltip
                    title="{{ 'BUTTON.LOAD_DATEV_REFERENCE' | translate }}"></button>
                <button kendoButton (click)="addHandler()" type="button" [svgIcon]="addSVG" kendoTooltip
                    title="{{ 'BUTTON.ADD' | translate }}"></button>
                &nbsp;|&nbsp;

                <button kendoButton (click)="removeFilter()" type="button" [svgIcon]="filterClearSVG" kendoTooltip
                    title="{{ 'BUTTON.CLEAR_FILTER' | translate }}"></button>

                <button kendoButton (click)="loadData()" type="button" [svgIcon]="reloadSVG" kendoTooltip
                    title="{{ 'BUTTON.UPDATE' | translate }}"></button>
            </ng-template>

            <kendo-grid-messages [pagerPage]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.PAGER.PAGE' | translate
                " [pagerOf]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.PAGER.OF' | translate
                " [pagerItems]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.PAGER.ITEM' | translate
                " [pagerItemsPerPage]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.PAGER.TASK_PER_PAGE'
                        | translate
                " [columnsApply]="'BUTTON.APPLY' | translate" [columnsReset]="'BUTTON.DISCARD' | translate"
                [autosizeAllColumns]="'TABLE_MENU.AUTOSIZE_ALL' | translate"
                [autosizeThisColumn]="'TABLE_MENU.AUTOSIZE_THIS' | translate"
                [columns]="'TABLE_MENU.COLUMNS_TAB' | translate" [filter]="'TABLE_MENU.FILTER_TAB' | translate"
                [filterAfterOperator]="'TABLE_MENU.AFTER_OPERATOR' | translate" [filterAfterOrEqualOperator]="
                    'TABLE_MENU.AFTER_OR_EQUAL_OPERATOR' | translate
                " [filterAndLogic]="'TABLE_MENU.AND_LOGIC' | translate" [filterBeforeOperator]="
                    'TABLE_MENU.BEFORE_OPERATOR' | translate
                " [filterBeforeOrEqualOperator]="
                    'TABLE_MENU.BEFORE_OR_EQUAL_OPERATOR' | translate
                " [filterBooleanAll]="'TABLE_MENU.BOOLEAN_ALL' | translate"
                [filterClearButton]="'BUTTON.CLEAR' | translate"
                [filterContainsOperator]="'TABLE_MENU.CONTAINS' | translate"
                [filterDateToday]="'TABLE_MENU.DATE_TODAY' | translate" [filterEndsWithOperator]="
                    'TABLE_MENU.ENDS_WITH_OPERATOR' | translate
                " [filterEqOperator]="'TABLE_MENU.EQUAL_OPERATOR' | translate"
                [filterFilterButton]="'BUTTON.FILTER' | translate"
                [filterGteOperator]="'TABLE_MENU.GTE_OPERATOR' | translate"
                [filterGtOperator]="'TABLE_MENU.GT_OPERATOR' | translate"
                [filterIsEmptyOperator]="'TABLE_MENU.IS_EMPTY' | translate"
                [filterIsFalse]="'TABLE_MENU.IS_FALSE' | translate" [filterIsNotEmptyOperator]="
                    'TABLE_MENU.IS_NOT_EMPTY' | translate
                " [filterIsNotNullOperator]="'TABLE_MENU.IS_NOT_NULL' | translate"
                [filterIsNullOperator]="'TABLE_MENU.IS_NULL' | translate"
                [filterIsTrue]="'TABLE_MENU.IS_TRUE' | translate"
                [filterLteOperator]="'TABLE_MENU.LTE_OPERATOR' | translate"
                [filterLtOperator]="'TABLE_MENU.LT_OPERATOR' | translate" [filterNotContainsOperator]="
                    'TABLE_MENU.NOT_CONTAINED_OPERATOR' | translate
                " [filterNotEqOperator]="
                    'TABLE_MENU.NOT_EQUAL_OPERATOR' | translate
                " [filterOrLogic]="'TABLE_MENU.OR_LOGIC' | translate" [filterStartsWithOperator]="
                    'TABLE_MENU.STARTS_WITH' | translate
                " [noRecords]="'TABLE_MENU.NO_RECORD' | translate" [setColumnPosition]="
                    'TABLE_MENU.SET_COLUMN_POSITION' | translate
                " [sortAscending]="'TABLE_MENU.SORT_ASCENDING' | translate"
                [sortDescending]="'TABLE_MENU.SORT_DESCENDING' | translate">
            </kendo-grid-messages>

            <kendo-grid-column field="id" title="ID" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="legalEntityKeyForDisplay" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.LEGAL_ENTITY'
                        | translate
                "></kendo-grid-column>
            <!--    <kendo-grid-column field="legalEntityKey"
                [title]=" 'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.LEGAL_ENTITY' | translate ">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ getlegalentityReferenceNameforDisplay(dataItem.legalEntityKey)}}
                </ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column field="auditnumber" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.AUDIT_NUMBER'
                        | translate
                "></kendo-grid-column>
            <kendo-grid-column field="clientnumber" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.CLIENT_NUMBER'
                        | translate
                "></kendo-grid-column>
            <kendo-grid-column field="basenumber" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.BASE_NUMBER'
                        | translate
                "></kendo-grid-column>
            <kendo-grid-column field="assessmentYearFrom" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.ASSESSMENT_YEAR'
                        | translate
                "></kendo-grid-column>
            <kendo-grid-column field="instance" [title]="
                    'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.INSTANCE'
                        | translate
                "></kendo-grid-column>
            <kendo-grid-column field="lastImportDate" filter="date" format="dd.MM.yyyy, HH:mm:ss" [title]="
                'APP.SETTINGS.DATEV_METADATEN.TABLE.HEADER.IMPORT_DATE'
                    | translate
            "></kendo-grid-column>

            <ng-template kendoGridNoRecordsTemplate>
                <p *ngIf="finishedLoading">
                    {{ "TABLE_MENU.NO_RECORD" | translate }}
                </p>
            </ng-template>

            <kendo-grid-excel fileName="DatevMetadaten.xlsx"></kendo-grid-excel>
        </kendo-grid>
    </div>
</div>

<ng-template #templateYear let-anchor>
    <pre style="white-space: pre-wrap">{{
        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.ASSESSMENT_YEAR.TOOLTIP"
            | translate
    }}</pre>
</ng-template>
<ng-template #templatePath let-anchor>
    <pre style="white-space: pre-wrap">{{
        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.DATA_PATH.TOOLTIP"
            | translate
    }}</pre>
</ng-template>
<!--Modal Window-->
<kendo-dialog *ngIf="active" [width]="700" [height]="500" (close)="closeForm()">
    <kendo-dialog-titlebar *ngIf="isNew">
        {{ "BUTTON.ADD" | translate }}
    </kendo-dialog-titlebar>

    <kendo-dialog-titlebar *ngIf="!isNew">
        {{ "BUTTON.EDIT" | translate }}
    </kendo-dialog-titlebar>
    <div class="grid-layout-container">
        <form novalidate class="k-form k-form-md k-form-vertical" [formGroup]="editForm">
            <kendo-formfield>
                <kendo-label [for]="legalEntityKey" [text]="
                        'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.LEGAL_ENTITY.LABEL'
                            | translate
                    "></kendo-label>
                <kendo-dropdownlist [valuePrimitive]="true" #legalEntityKey formControlName="legalEntityKey"
                    placeholder="legalEntityKey" [textField]="'labelDE'" [valueField]="'value'" required
                    [data]="legalEntities">
                </kendo-dropdownlist>
                <kendo-formerror>{{
                    "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.LEGAL_ENTITY.ERROR.REQUIRED"
                    | translate
                    }}</kendo-formerror>
            </kendo-formfield>

            <!-- Container for clientnumber and auditnumber -->
            <div class="form-row">
                <kendo-formfield>
                    <kendo-label [for]="auditnumber" [text]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.AUDIT_NUMBER.LABEL'
                                | translate
                        "></kendo-label>
                    <kendo-numerictextbox [spinners]="false" [changeValueOnScroll]="false" [decimals]="0" #auditnumber
                        formControlName="auditnumber" [maxlength]="10" [max]="2147483647" [min]="1" [placeholder]="''"
                        format="##" required>
                    </kendo-numerictextbox>
                    <kendo-formerror>{{
                        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.AUDIT_NUMBER.ERROR.REQUIRED"
                        | translate
                        }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="clientnumber" [text]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.CLIENT_NUMBER.LABEL'
                                | translate
                        "></kendo-label>
                    <kendo-numerictextbox [spinners]="false" [changeValueOnScroll]="false" [max]="2147483647" [min]="1"
                        format="##" [decimals]="0" [maxlength]="10" #clientnumber formControlName="clientnumber"
                        [placeholder]="''" required>
                    </kendo-numerictextbox>
                    <kendo-formerror>{{
                        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.CLIENT_NUMBER.ERROR.REQUIRED"
                        | translate
                        }}</kendo-formerror>
                </kendo-formfield>

                <kendo-formfield>
                    <kendo-label [for]="basenumber" [text]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.BASE_NUMBER.LABEL'
                                | translate
                        "></kendo-label>
                    <kendo-numerictextbox [spinners]="false" [changeValueOnScroll]="false" [max]="99999" [min]="0"
                        format="##" [decimals]="0" [maxlength]="10" #basenumber formControlName="basenumber"
                        [placeholder]="''">
                    </kendo-numerictextbox>
                </kendo-formfield>
            </div>

            <!-- Container for basenumber and instance -->
            <div class="form-row">
                <kendo-formfield>
                    <kendo-label [for]="assessmentYearFrom" [text]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.ASSESSMENT_YEAR.LABEL'
                                | translate
                        ">
                        <span class="k-icon k-font-icon k-i-information" style="
                                font-size: 20px;
                                padding-left: 5px;
                                margin-top: 3px;
                            " kendoTooltip [title]="
                                'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.ASSESSMENT_YEAR.TOOLTIP'
                                    | translate
                            " [tooltipTemplate]="templateYear"></span></kendo-label>

                    <kendo-numerictextbox #assessmentYearFrom formControlName="assessmentYearFrom" [spinners]="false"
                        [decimals]="0" [maxlength]="4" [changeValueOnScroll]="false" format="##" [min]="1980"
                        [max]="2100" required>
                    </kendo-numerictextbox>
                    <kendo-formerror>{{
                        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.ASSESSMENT_YEAR.ERROR.REQUIRED"
                        | translate
                        }}</kendo-formerror>
                </kendo-formfield>

                <kendo-formfield>
                    <kendo-label [for]="instance" [text]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.INSTANCE.LABEL'
                                | translate
                        "></kendo-label>
                    <kendo-dropdownlist [data]="instanceList" [valuePrimitive]="true" #instance
                        formControlName="instance" [textField]="'labelDE'" [valueField]="'value'" required>
                    </kendo-dropdownlist>
                    <kendo-formerror>{{
                        "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.INSTANCE.ERROR.REQUIRED"
                        | translate
                        }}</kendo-formerror>
                </kendo-formfield>
            </div>

            <kendo-formfield>
                <kendo-label [for]="datapath" [text]="
                        'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.DATA_PATH.LABEL'
                            | translate
                    "><span class="k-icon k-font-icon k-i-information" style="
                            font-size: 20px;
                            padding-left: 5px;
                            margin-top: 3px;
                        " kendoTooltip [title]="
                            'APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.DATA_PATH.TOOLTIP'
                                | translate
                        " [tooltipTemplate]="templatePath"></span></kendo-label>
                <kendo-textbox #datapath formControlName="datapath" [placeholder]="''"></kendo-textbox>
                <kendo-formerror>{{
                    "APP.SETTINGS.DATEV_METADATEN.MODAL.ADD_DATEV.DATA_PATH.ERROR.REQUIRED"
                    | translate
                    }}</kendo-formerror>
            </kendo-formfield>
        </form>
    </div>
    <kendo-dialog-actions layout="start">
        <button kendoButton themeColor="primary" [disabled]="isNew" (click)="onDelete($event)">
            {{ "BUTTON.DELETE" | translate }}
        </button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">
            {{ "BUTTON.SAVE" | translate }}
        </button>
        <button kendoButton (click)="onCancel($event)">
            {{ "BUTTON.CANCEL" | translate }}
        </button>
    </kendo-dialog-actions>
</kendo-dialog>